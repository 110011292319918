import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import qs from 'query-string';
import type { IRouteMetadata } from '../../../../Route';

export type QueryParameters = {
  page: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;

  /**
   * Parse the given raw query string or partially-parsed react-router-dom queryParams object.
   */
  export function parse(
    search: string | { [K in keyof QueryParameters]?: string } = ''
  ): QueryParameters {
    const rawParsed =
      typeof search === 'string' ? qs.parse(search) : (search ?? {});
    const parsed: any = { ...rawParsed };

    return parsed as QueryParameters;
  }
}

/**
 * Generated from Jersey path "/retailer/settings/stores"
 *
 * Product Area: RETAILER_STORE_MANAGEMENT
 */
export const route = '/retailer/settings/stores';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/retailer/settings/stores',
  productArea: 'RETAILER_STORE_MANAGEMENT',
  pod: 'FAIRE_POD_UPMARKET_TOOLING',
  pillar: 'FAIRE_PILLAR_RETAILER_TOOLS_AND_PROGRAMS',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path(queryParams?: Partial<QueryParameters>) {
  const compiledPath = toPath({});
  const queryString = queryParams ? qs.stringify(queryParams) : '';
  return `${compiledPath}${queryString ? `?${queryString}` : ''}`;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (queryParams?: Partial<QueryParameters>): string => {
  return faireBaseUrl(3000) + path(queryParams);
};

export default path;
