import { IItemInventoryResponse } from "@faire/web-api--source/faire/carts/IItemInventoryResponse";

export const getCartProductQuantitiesMap = (
  items: IItemInventoryResponse.IInventoryItem[]
): Record<string, number> => {
  const itemQuantitiesMap: Record<string, number> = {};

  items.forEach((item) => {
    if (!item?.product_token) {
      return;
    }
    itemQuantitiesMap[item.product_token] =
      (itemQuantitiesMap[item.product_token] ?? 0) + (item.quantity ?? 0);
  });

  return itemQuantitiesMap;
};

export const getCartProductOptionQuantitiesMap = (
  items: IItemInventoryResponse.IInventoryItem[]
): Record<string, number> => {
  const itemQuantitiesMap: Record<string, number> = {};

  items.forEach((item) => {
    if (!item?.product_option_token) {
      return;
    }
    itemQuantitiesMap[item.product_option_token] =
      (itemQuantitiesMap[item.product_option_token] ?? 0) +
      (item.quantity ?? 0);
  });

  return itemQuantitiesMap;
};
