import type { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";

export const THREE_LETTER_PRIMARY_COUNTRY_CODES: {
  [K in Extract<
    keyof typeof ISO3166Alpha3,
    | "USA"
    | "CAN"
    | "GBR"
    | "FRA"
    | "DEU"
    | "NLD"
    | "ITA"
    | "ESP"
    | "BEL"
    | "AUT"
    | "LUX"
    | "IRL"
    | "NOR"
    | "CHE"
    | "FIN"
    | "SWE"
    | "DNK"
    | "PRT"
    | "AUS"
  >]: K;
} = {
  USA: "USA",
  CAN: "CAN",
  GBR: "GBR",
  FRA: "FRA",
  DEU: "DEU",
  NLD: "NLD",
  ITA: "ITA",
  ESP: "ESP",
  // T2
  BEL: "BEL",
  AUT: "AUT",
  LUX: "LUX",
  IRL: "IRL",
  // T3
  NOR: "NOR",
  CHE: "CHE",
  FIN: "FIN",
  SWE: "SWE",
  DNK: "DNK",
  // Portugal
  PRT: "PRT",
  // Australia
  AUS: "AUS",
} as const;
