import { reaction } from "mobx";
import { DependencyList, useMemo } from "react";
import { useSyncExternalStore } from "use-sync-external-store/shim";

// Indicates that the observerFunction passed to useObservable hasn't been run yet.
// Differentiated from `undefined` or `null` because the observerFunction could return
// those values.
const NOT_INITIALIZED = Symbol("NOT_INITIALIZED");

/**
 * Use this hooks to access observables from within a function component
 * such that any changes to those observables with cause the function component
 * to re-render.
 *
 * Wrapping function components in the mobx observer higher order component will
 * not work if that component uses React hooks.
 *
 * @example
 *
 * ```
 * import { useObservable } from "@faire/web--source/common/hooks/useObservable";
 *
 * import { UserStore } from "@app/stores/UserStore";
 * import * as React from "react";
 *
 * const HelloUser: React.FC = () => {
 *   // de-referencing an observable within the provided function
 *   // will cause HelloUser to re-render when the observable changes
 *   const user = useObservable(() => UserStore.get().user);
 *
 *   if (!user) {
 *     return <Typography>Loading...</Typography>
 *   }
 *
 *   return <Typography>Hello {user.name}!</Typography>
 * }
 * ```
 *
 * @param observerFunction A function that de-references observables that should trigger a re-render when changed
 * @param deps List of dependencies, which tell useObservable when to re-setup reaction, if a non-observer dependency has changed.
 * @returns What was returned by the provided `observerFunction`
 *
 * @note Only the initial value of observerFunction is used. Any changes to the function will be ignored.
 */
export const useObservable = <T>(
  observerFunction: () => T,
  deps: DependencyList = [],
  equals?: (a: T, b: T) => boolean
): T => {
  const watcher = useMemo(
    () => {
      let snapshot: T | symbol = NOT_INITIALIZED;
      return {
        subscribe: (callback: () => void) => {
          return reaction(
            () => {
              const result = observerFunction();
              if (!equals || !equals(snapshot as T, result)) {
                snapshot = result;
              }
              return result;
            },
            callback,
            // We do not trigger `fireImmediately` because React will always call getSnapshot() after subscribing.
            equals == null ? undefined : { equals }
          );
        },
        getSnapshot: (): T => {
          // React depends on getSnapshot() returning a snapshot. If getSnapshot() is called twice in a row, it must return the
          // same value (as determined by Object.is).
          if (snapshot === NOT_INITIALIZED) {
            snapshot = observerFunction();
          }
          return snapshot as T;
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );

  return useSyncExternalStore(
    watcher.subscribe,
    watcher.getSnapshot,
    watcher.getSnapshot
  );
};
