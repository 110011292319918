export namespace IFreeShippingStatusRequest {
  /**
   * Return an instance of IFreeShippingStatusRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IFreeShippingStatusRequest>
  ): IFreeShippingStatusRequest =>
    ({
      brand_tokens: [],
      ...(partial ?? {}),
    }) as IFreeShippingStatusRequest;
}

export interface IFreeShippingStatusRequest {
  /**
   * @protobufOption (.faire.common.tokenType) = BRAND
   */
  brand_tokens: Array<string>;
}
