import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'ads-adblock_detector_user_blocked_dom-view';

/**
 * Fire the event when the user blocks an ad being served by blocking the dom
 */
export function trackAdsAdblockDetectorUserBlockedDomView(
  userToken: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: { userToken },
  });
}
