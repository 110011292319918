import {
  getLocation,
  getLocationOrThrow,
} from "@faire/web--source/common/globals/getLocation";
import {
  faireBaseUrlMatcher,
  LocalApp,
  LocalAppPort,
  LOCAL_PORT as LOCAL_PORT_MATCHER,
} from "@faire/web--source/common/routes/faireBaseUrlMatcher";
import {
  EnvValues,
  getForEnv,
} from "@faire/web--source/common/user-agent/getForEnv";
import { isLocal } from "@faire/web--source/common/user-agent/isLocal";
import { isPrerelease } from "@faire/web--source/common/user-agent/isPrerelease";
import { isProduction } from "@faire/web--source/common/user-agent/isProduction";
import { isStaging } from "@faire/web--source/common/user-agent/isStaging";

export const LOCAL_PORT = LOCAL_PORT_MATCHER;

export function faireBaseUrl(port?: LocalAppPort): string;
export function faireBaseUrl(app?: LocalApp): string;

export function faireBaseUrl(
  nameOrLocalhostPort?: LocalApp | LocalAppPort
): string {
  if (isProduction()) {
    return faireBaseUrlMatcher("production", nameOrLocalhostPort);
  } else if (isStaging()) {
    return faireBaseUrlMatcher("staging", nameOrLocalhostPort);
  } else if (isPrerelease()) {
    return faireBaseUrlMatcher("prerelease", nameOrLocalhostPort);
  } else if (isLocal() && nameOrLocalhostPort) {
    return faireBaseUrlMatcher("local", nameOrLocalhostPort);
  }
  return getLocation()?.origin ?? "";
}

const HOST_SUFFIX_BY_ENV: EnvValues<string | null> = {
  defaultValue: null,
  production: "faire.com",
  staging: "faire-stage.com",
  local: "localhost",
} as const;

export const isValidFaireUrl = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url, getLocationOrThrow().href);
    const domainSuffix = getDomainSuffix(parsedUrl.hostname);

    const validHostSuffix =
      getForEnv(HOST_SUFFIX_BY_ENV) ??
      getDomainSuffix(getLocationOrThrow().hostname);
    return domainSuffix === validHostSuffix;
  } catch {
    return false;
  }
};

const getDomainSuffix = (hostname: string) => {
  // Because Faire can be served on subdomains (subdomain.faire.com),
  //  we need to take the last 2 segments of the host name (faire.com)
  //  and check if its valid.
  const hostnameSegments = hostname.split(".");
  return hostnameSegments
    .slice(Math.max(hostnameSegments.length - 2, 0))
    .join(".");
};
