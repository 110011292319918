export enum UserRole {
  ALL = 'ALL',
  GUEST_RETAILER = 'GUEST_RETAILER',
  RETAILER = 'RETAILER',
  MAKER = 'MAKER',
  MAKER_EDITOR = 'MAKER_EDITOR',
  /**
   * @deprecated
   */
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  RISK_OPS = 'RISK_OPS',
  WAREHOUSE_WORKER = 'WAREHOUSE_WORKER',
  ADMIN = 'ADMIN',
  /**
   * An admin can manage (create or modify) all users
   * A user with MANAGE_USER role can manage users with a subset of permissions
   */
  MANAGE_USER = 'MANAGE_USER',
  /**
   * An admin with this role can modify payouts for other users, and modify payouts when impersonating
   */
  MANAGE_PAYOUT = 'MANAGE_PAYOUT',
  CONSUMER = 'CONSUMER',
  /**
   * An admin with this role can edit the product taxonomy and category trees
   */
  TAXONOMY_EDITOR = 'TAXONOMY_EDITOR',
  /**
   * An admin with this role can publish brand announcements (notification + email)
   * @deprecated
   */
  BRAND_ANNOUNCEMENT_PUBLISHER = 'BRAND_ANNOUNCEMENT_PUBLISHER',
  /**
   * Users with this role can populate neighborhood retailer's catalogs
   */
  RETAILER_EDITOR = 'RETAILER_EDITOR',
  /**
   * This permission is ordinarily not given to a user, but is added to sessions from by a CI tool.
   * Used for managing deployments and such.
   */
  CI_TOOL = 'CI_TOOL',
  /**
   * Admins with this role can manage frontend deployments
   */
  FRONTEND_OWNER = 'FRONTEND_OWNER',
  /**
   * Admins with this role can use PrivacyAPI to delete user and deactivate user
   */
  DELETE_USER = 'DELETE_USER',
  /**
   * Access to the DatabaseExplorerApi
   */
  DATABASE_EXPLORER = 'DATABASE_EXPLORER',
  DEVELOPER = 'DEVELOPER',
  /**
   * Additional permissions for a warehouse worker in a supervisor role.
   */
  WAREHOUSE_SUPERVISOR = 'WAREHOUSE_SUPERVISOR',
  /**
   * Email reviewers with this role have the permission to view or audit email templates in admin
   */
  EMAIL_VIEWER = 'EMAIL_VIEWER',
  /**
   * Users with this role have the permission to grant top shop statuses for brands in admin
   */
  TOP_SHOP_ADMIN = 'TOP_SHOP_ADMIN',
  /**
   * Enables more tooling on the Product Review Queue for actioning on counterfeit products
   */
  COUNTERFEIT_QUEUE_OWNER = 'COUNTERFEIT_QUEUE_OWNER',
  /**
   * Grants access to translations service for 3rd party translation consultants
   */
  TRANSLATOR = 'TRANSLATOR',
  /**
   * Given to MAKER_EDITORS (BPOs) to allow them to view the contents of the pre-activation fraud review queue
   */
  PRE_ACTIVATION_FRAUD_QUEUE_VIEW_ONLY = 'PRE_ACTIVATION_FRAUD_QUEUE_VIEW_ONLY',
  /**
   * Users with this role can add or delete showroom brands
   */
  SHOWROOM_ADMIN = 'SHOWROOM_ADMIN',
  /**
   * Grant access to T&S and Quality team to bulk deactivate brands that have fraud/TOS violation issues
   */
  MAKER_OPS = 'MAKER_OPS',
  /**
   * Grants access to the nudge hub action inventory admin tool
   */
  NUDGE_HUB_ACTION_INVENTORY_ADMIN = 'NUDGE_HUB_ACTION_INVENTORY_ADMIN',
  /**
   * Grants access to ACH files
   */
  ACH_OPS = 'ACH_OPS',
  /**
   * Grants access to add new category or edit collections on featured categories admin page
   */
  FEATURED_CATEGORY_ADMIN = 'FEATURED_CATEGORY_ADMIN',
  /**
   * Grants access to add new category, edit collections, edit category headers/tiles, and delete category access
   * on featured categories admin page
   */
  FEATURED_CATEGORY_SUPER_ADMIN = 'FEATURED_CATEGORY_SUPER_ADMIN',
  /**
   * A user created from a external lead form advertisement.
   */
  POTENTIAL_SIGN_UP_LEAD = 'POTENTIAL_SIGN_UP_LEAD',
  /**
   * Grants access to the queue of products listed on other platforms to be initially reviewed for pricing parity
   */
  INITIAL_REVIEWER_OF_CROSS_PLATFORM_PRICING = 'INITIAL_REVIEWER_OF_CROSS_PLATFORM_PRICING',
  /**
   * Grants access to the queue of products listed on brands' direct sites to be initially reviewed for pricing parity
   */
  INITIAL_REVIEWER_OF_DIRECT_PRICING = 'INITIAL_REVIEWER_OF_DIRECT_PRICING',
  /**
   * Grants access to the queue of products listed on other platforms to be QA'd for pricing parity
   */
  QA_REVIEWER_OF_CROSS_PLATFORM_PRICING = 'QA_REVIEWER_OF_CROSS_PLATFORM_PRICING',
  /**
   * Grants access to the queue of products listed on brands' direct sites to be QA'd for pricing parity
   */
  QA_REVIEWER_OF_DIRECT_PRICING = 'QA_REVIEWER_OF_DIRECT_PRICING',
  /**
   * Users who should be assigned to review a Hugo catalogs build queue item
   */
  HUGO_QUEUE_REVIEWER = 'HUGO_QUEUE_REVIEWER',
  /**
   * Users who should be assigned to review a TaskUs catalogs build queue item
   */
  TASKUS_QUEUE_REVIEWER = 'TASKUS_QUEUE_REVIEWER',
  /**
   * Third party service accounts that require delegated access to the API
   */
  SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
  /**
   * Admins with this role can manage tax and compliance information
   */
  TAX_TEAM = 'TAX_TEAM',
}
