import {
  emitDatadogAction,
  emitDatadogTiming,
} from "@faire/web--source/common/datadog/rum";
import { TrackedEvent } from "@faire/web-api--source/types";

import { getTraceTiming } from "../../pete/TraceEventUtils";

export const datadogInterceptor = (params: TrackedEvent) => {
  if (!params.realTimeTracking) {
    return;
  }

  try {
    const context = {
      faire: {
        pete: params.parameters,
      },
    };
    emitDatadogAction(`faire.pete.${params.eventKey}`, context);

    if (params.sourceEventKey) {
      const timing = getTraceTiming(
        params.sourceEventKey,
        params.sourceEventMarker
      );
      emitDatadogTiming(`faire.pete.${params.eventKey}`, timing);
    }
  } catch (_) {
    /* ignore */
  }
};
