import {
  AUTO_IMAGE_CROP_APPAREL_TAG_PREFIX,
  AUTO_IMAGE_CROP_SQUARE_TAG_PREFIX,
} from "@faire/web--source/common/images/cropUtils/autoImageCropTagPrefix";

import {
  MANUAL_IMAGE_CROP_APPAREL_TAG_PREFIX,
  MANUAL_IMAGE_CROP_TAG_PREFIX,
} from "./manualImageCropTagPrefix";

/**
 * Returns an array of image tags with any crop tags removed.
 * This is used to reset/remove the image crop from an image.
 */
export const removeImageCropTag = (
  tags: string[] = [],
  removedTags?: string[]
) => {
  if (removedTags && removedTags.length > 0) {
    return tags.filter(
      (tag) => !removedTags?.find((removedTag) => tag.startsWith(removedTag))
    );
  }
  return tags.filter(
    (tag) =>
      !tag.startsWith(MANUAL_IMAGE_CROP_TAG_PREFIX) &&
      !tag.startsWith(AUTO_IMAGE_CROP_APPAREL_TAG_PREFIX) &&
      !tag.startsWith(AUTO_IMAGE_CROP_SQUARE_TAG_PREFIX) &&
      !tag.startsWith(MANUAL_IMAGE_CROP_APPAREL_TAG_PREFIX)
  );
};
