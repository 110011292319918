import { ISO3166Alpha3 } from '../../indigofair/iso3166/ISO3166Alpha3';
import { Language } from '../../indigofair/languages/Language';

export namespace ILocaleKey {
  /**
   * Return an instance of ILocaleKey with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<ILocaleKey>): ILocaleKey =>
    ({
      ...(partial ?? {}),
    }) as ILocaleKey;
}

export interface ILocaleKey {
  country: keyof typeof ISO3166Alpha3 | undefined;
  /**
   * Language codes are ISO 639-3
   */
  language: keyof typeof Language | undefined;
}
