"use client";

import React from "react";

import { createSVGIcon } from "../util";

export const Google = createSVGIcon(
  <>
    <path
      d="M19.7875 10.225C19.7875 9.56671 19.7292 8.94171 19.6292 8.33337H10.2125V12.0917H15.6042C15.3625 13.325 14.6542 14.3667 13.6042 15.075V17.575H16.8208C18.7042 15.8334 19.7875 13.2667 19.7875 10.225Z"
      fill="#4285F4"
    />
    <path
      d="M10.2125 20C12.9125 20 15.1708 19.1 16.8208 17.575L13.6042 15.075C12.7042 15.675 11.5625 16.0417 10.2125 16.0417C7.60417 16.0417 5.39584 14.2833 4.60417 11.9083H1.28751V14.4833C2.92917 17.75 6.30417 20 10.2125 20Z"
      fill="#34A853"
    />
    <path
      d="M4.60416 11.9084C4.39583 11.3084 4.28749 10.6667 4.28749 10.0001C4.28749 9.33339 4.40416 8.69172 4.60416 8.09172V5.51672H1.28749C0.60416 6.86672 0.212494 8.38339 0.212494 10.0001C0.212494 11.6167 0.60416 13.1334 1.28749 14.4834L4.60416 11.9084Z"
      fill="#FBBC05"
    />
    <path
      d="M10.2125 3.95833C11.6875 3.95833 13.0042 4.46667 14.0458 5.45834L16.8958 2.60833C15.1708 0.991668 12.9125 0 10.2125 0C6.30417 0 2.92917 2.25 1.28751 5.51667L4.60417 8.09167C5.39584 5.71667 7.60417 3.95833 10.2125 3.95833Z"
      fill="#EA4335"
    />
  </>,
  "Google",
  (strictLocalize) => ({
    viewBox: "0 0 20 20",
    fill: "none",
    titleAccess: strictLocalize({
      defaultMessage: "Google logo",
      description: { text: "The label for Google's company logo icon" },
    }),
  })
);
