import omit from "lodash/omit";

import { StrictLocalizeFunction } from "../localization";
import { LocalizationStore } from "../localization/LocalizationStore";

/**
 * Return an object from ISO code to localized names, in localized order.
 *
 * We create a function that wraps this so we can change what is returned by
 * localize in a node environment where locale may change. This is useful
 * for the web-email-server, where we cannot hard-refresh when changing locales.
 */
export const getIsoToCountryNames = (
  strictLocalize: StrictLocalizeFunction
) => {
  const ISO_TO_COUNTRY_NAME_UNSORTED = {
    AFG: strictLocalize({
      defaultMessage: "Afghanistan",
      description: { text: "Country name" },
    }),
    ALA: strictLocalize({
      defaultMessage: "Åland Islands",
      description: { text: "Country name" },
    }),
    ALB: strictLocalize({
      defaultMessage: "Albania",
      description: { text: "Country name" },
    }),
    DZA: strictLocalize({
      defaultMessage: "Algeria",
      description: { text: "Country name" },
    }),
    ASM: strictLocalize({
      defaultMessage: "American Samoa",
      description: { text: "Country name" },
    }),
    AND: strictLocalize({
      defaultMessage: "Andorra",
      description: { text: "Country name" },
    }),
    AGO: strictLocalize({
      defaultMessage: "Angola",
      description: { text: "Country name" },
    }),
    AIA: strictLocalize({
      defaultMessage: "Anguilla",
      description: { text: "Country name" },
    }),
    ATA: strictLocalize({
      defaultMessage: "Antarctica",
      description: { text: "Country name" },
    }),
    ATG: strictLocalize({
      defaultMessage: "Antigua and Barbuda",
      description: { text: "Country name" },
    }),
    ARG: strictLocalize({
      defaultMessage: "Argentina",
      description: { text: "Country name" },
    }),
    ARM: strictLocalize({
      defaultMessage: "Armenia",
      description: { text: "Country name" },
    }),
    ABW: strictLocalize({
      defaultMessage: "Aruba",
      description: { text: "Country name" },
    }),
    AUS: strictLocalize({
      defaultMessage: "Australia",
      description: { text: "Country name" },
    }),
    AUT: strictLocalize({
      defaultMessage: "Austria",
      description: { text: "Country name" },
    }),
    AZE: strictLocalize({
      defaultMessage: "Azerbaijan",
      description: { text: "Country name" },
    }),
    BHS: strictLocalize({
      defaultMessage: "Bahamas",
      description: { text: "Country name" },
    }),
    BHR: strictLocalize({
      defaultMessage: "Bahrain",
      description: { text: "Country name" },
    }),
    BGD: strictLocalize({
      defaultMessage: "Bangladesh",
      description: { text: "Country name" },
    }),
    BRB: strictLocalize({
      defaultMessage: "Barbados",
      description: { text: "Country name" },
    }),
    BLR: strictLocalize({
      defaultMessage: "Belarus",
      description: { text: "Country name" },
    }),
    BEL: strictLocalize({
      defaultMessage: "Belgium",
      description: { text: "Country name" },
    }),
    BLZ: strictLocalize({
      defaultMessage: "Belize",
      description: { text: "Country name" },
    }),
    BEN: strictLocalize({
      defaultMessage: "Benin",
      description: { text: "Country name" },
    }),
    BMU: strictLocalize({
      defaultMessage: "Bermuda",
      description: { text: "Country name" },
    }),
    BTN: strictLocalize({
      defaultMessage: "Bhutan",
      description: { text: "Country name" },
    }),
    BOL: strictLocalize({
      defaultMessage: "Bolivia",
      description: { text: "Country name" },
    }),
    BES: strictLocalize({
      defaultMessage: "Bonaire, Saint Eustatius and Saba",
      description: { text: "Country name" },
    }),
    BIH: strictLocalize({
      defaultMessage: "Bosnia and Herzegovina",
      description: { text: "Country name" },
    }),
    BWA: strictLocalize({
      defaultMessage: "Botswana",
      description: { text: "Country name" },
    }),
    BVT: strictLocalize({
      defaultMessage: "Bouvet Island",
      description: { text: "Country name" },
    }),
    BRA: strictLocalize({
      defaultMessage: "Brazil",
      description: { text: "Country name" },
    }),
    VGB: strictLocalize({
      defaultMessage: "British Virgin Islands",
      description: { text: "Country name" },
    }),
    IOT: strictLocalize({
      defaultMessage: "British Indian Ocean Territory",
      description: { text: "Country name" },
    }),
    BRN: strictLocalize({
      defaultMessage: "Brunei Darussalam",
      description: { text: "Country name" },
    }),
    BGR: strictLocalize({
      defaultMessage: "Bulgaria",
      description: { text: "Country name" },
    }),
    BFA: strictLocalize({
      defaultMessage: "Burkina Faso",
      description: { text: "Country name" },
    }),
    BDI: strictLocalize({
      defaultMessage: "Burundi",
      description: { text: "Country name" },
    }),
    KHM: strictLocalize({
      defaultMessage: "Cambodia",
      description: { text: "Country name" },
    }),
    CMR: strictLocalize({
      defaultMessage: "Cameroon",
      description: { text: "Country name" },
    }),
    CAN: strictLocalize({
      defaultMessage: "Canada",
      description: { text: "Country name" },
    }),
    CPV: strictLocalize({
      defaultMessage: "Cape Verde",
      description: { text: "Country name" },
    }),
    CYM: strictLocalize({
      defaultMessage: "Cayman Islands",
      description: { text: "Country name" },
    }),
    CAF: strictLocalize({
      defaultMessage: "Central African Republic",
      description: { text: "Country name" },
    }),
    TCD: strictLocalize({
      defaultMessage: "Chad",
      description: { text: "Country name" },
    }),
    CHL: strictLocalize({
      defaultMessage: "Chile",
      description: { text: "Country name" },
    }),
    CHN: strictLocalize({
      defaultMessage: "China",
      description: { text: "Country name" },
    }),
    HKG: strictLocalize({
      defaultMessage: "Hong Kong",
      description: { text: "Country name" },
    }),
    MAC: strictLocalize({
      defaultMessage: "Macao",
      description: { text: "Country name" },
    }),
    CXR: strictLocalize({
      defaultMessage: "Christmas Island",
      description: { text: "Country name" },
    }),
    CCK: strictLocalize({
      defaultMessage: "Cocos (Keeling) Islands",
      description: { text: "Country name" },
    }),
    COL: strictLocalize({
      defaultMessage: "Colombia",
      description: { text: "Country name" },
    }),
    COM: strictLocalize({
      defaultMessage: "Comoros",
      description: { text: "Country name" },
    }),
    COG: strictLocalize({
      defaultMessage: "Republic of the Congo",
      description: { text: "Country name" },
    }),
    COD: strictLocalize({
      defaultMessage: "Democratic Republic of the Congo",
      description: { text: "Country name" },
    }),
    COK: strictLocalize({
      defaultMessage: "Cook Islands",
      description: { text: "Country name" },
    }),
    CRI: strictLocalize({
      defaultMessage: "Costa Rica",
      description: { text: "Country name" },
    }),
    CIV: strictLocalize({
      defaultMessage: "Côte d’Ivoire",
      description: { text: "Country name" },
    }),
    HRV: strictLocalize({
      defaultMessage: "Croatia",
      description: { text: "Country name" },
    }),
    CUB: strictLocalize({
      defaultMessage: "Cuba",
      description: { text: "Country name" },
    }),
    CUW: strictLocalize({
      defaultMessage: "Curaçao",
      description: { text: "Country name" },
    }),
    CYP: strictLocalize({
      defaultMessage: "Cyprus",
      description: { text: "Country name" },
    }),
    CZE: strictLocalize({
      defaultMessage: "Czech Republic",
      description: { text: "Country name" },
    }),
    DNK: strictLocalize({
      defaultMessage: "Denmark",
      description: { text: "Country name" },
    }),
    DJI: strictLocalize({
      defaultMessage: "Djibouti",
      description: { text: "Country name" },
    }),
    DMA: strictLocalize({
      defaultMessage: "Dominica",
      description: { text: "Country name" },
    }),
    DOM: strictLocalize({
      defaultMessage: "Dominican Republic",
      description: { text: "Country name" },
    }),
    ECU: strictLocalize({
      defaultMessage: "Ecuador",
      description: { text: "Country name" },
    }),
    EGY: strictLocalize({
      defaultMessage: "Egypt",
      description: { text: "Country name" },
    }),
    SLV: strictLocalize({
      defaultMessage: "El Salvador",
      description: { text: "Country name" },
    }),
    GNQ: strictLocalize({
      defaultMessage: "Equatorial Guinea",
      description: { text: "Country name" },
    }),
    ERI: strictLocalize({
      defaultMessage: "Eritrea",
      description: { text: "Country name" },
    }),
    EST: strictLocalize({
      defaultMessage: "Estonia",
      description: { text: "Country name" },
    }),
    ETH: strictLocalize({
      defaultMessage: "Ethiopia",
      description: { text: "Country name" },
    }),
    FLK: strictLocalize({
      defaultMessage: "Falkland Islands",
      description: { text: "Country name" },
    }),
    FRO: strictLocalize({
      defaultMessage: "Faroe Islands",
      description: { text: "Country name" },
    }),
    FJI: strictLocalize({
      defaultMessage: "Fiji",
      description: { text: "Country name" },
    }),
    FIN: strictLocalize({
      defaultMessage: "Finland",
      description: { text: "Country name" },
    }),
    FRA: strictLocalize({
      defaultMessage: "France",
      description: { text: "Country name" },
    }),
    GUF: strictLocalize({
      defaultMessage: "French Guiana",
      description: { text: "Country name" },
    }),
    PYF: strictLocalize({
      defaultMessage: "French Polynesia",
      description: { text: "Country name" },
    }),
    ATF: strictLocalize({
      defaultMessage: "French Southern Territories",
      description: { text: "Country name" },
    }),
    GAB: strictLocalize({
      defaultMessage: "Gabon",
      description: { text: "Country name" },
    }),
    GMB: strictLocalize({
      defaultMessage: "Gambia",
      description: { text: "Country name" },
    }),
    GEO: strictLocalize({
      defaultMessage: "Georgia",
      description: { text: "Country name" },
    }),
    DEU: strictLocalize({
      defaultMessage: "Germany",
      description: { text: "Country name" },
    }),
    GHA: strictLocalize({
      defaultMessage: "Ghana",
      description: { text: "Country name" },
    }),
    GIB: strictLocalize({
      defaultMessage: "Gibraltar",
      description: { text: "Country name" },
    }),
    GRC: strictLocalize({
      defaultMessage: "Greece",
      description: { text: "Country name" },
    }),
    GRL: strictLocalize({
      defaultMessage: "Greenland",
      description: { text: "Country name" },
    }),
    GRD: strictLocalize({
      defaultMessage: "Grenada",
      description: { text: "Country name" },
    }),
    GLP: strictLocalize({
      defaultMessage: "Guadeloupe",
      description: { text: "Country name" },
    }),
    GUM: strictLocalize({
      defaultMessage: "Guam",
      description: { text: "Country name" },
    }),
    GTM: strictLocalize({
      defaultMessage: "Guatemala",
      description: { text: "Country name" },
    }),
    GGY: strictLocalize({
      defaultMessage: "Guernsey",
      description: { text: "Country name" },
    }),
    GIN: strictLocalize({
      defaultMessage: "Guinea",
      description: { text: "Country name" },
    }),
    GNB: strictLocalize({
      defaultMessage: "Guinea-Bissau",
      description: { text: "Country name" },
    }),
    GUY: strictLocalize({
      defaultMessage: "Guyana",
      description: { text: "Country name" },
    }),
    HTI: strictLocalize({
      defaultMessage: "Haiti",
      description: { text: "Country name" },
    }),
    HMD: strictLocalize({
      defaultMessage: "Heard and Mcdonald Islands",
      description: { text: "Country name" },
    }),
    VAT: strictLocalize({
      defaultMessage: "Vatican City",
      description: { text: "Country name" },
    }),
    HND: strictLocalize({
      defaultMessage: "Honduras",
      description: { text: "Country name" },
    }),
    HUN: strictLocalize({
      defaultMessage: "Hungary",
      description: { text: "Country name" },
    }),
    ISL: strictLocalize({
      defaultMessage: "Iceland",
      description: { text: "Country name" },
    }),
    IND: strictLocalize({
      defaultMessage: "India",
      description: { text: "Country name" },
    }),
    IDN: strictLocalize({
      defaultMessage: "Indonesia",
      description: { text: "Country name" },
    }),
    IRN: strictLocalize({
      defaultMessage: "Iran, Islamic Republic of",
      description: { text: "Country name" },
    }),
    IRQ: strictLocalize({
      defaultMessage: "Iraq",
      description: { text: "Country name" },
    }),
    IRL: strictLocalize({
      defaultMessage: "Ireland",
      description: { text: "Country name" },
    }),
    IMN: strictLocalize({
      defaultMessage: "Isle of Man",
      description: { text: "Country name" },
    }),
    ISR: strictLocalize({
      defaultMessage: "Israel",
      description: { text: "Country name" },
    }),
    ITA: strictLocalize({
      defaultMessage: "Italy",
      description: { text: "Country name" },
    }),
    JAM: strictLocalize({
      defaultMessage: "Jamaica",
      description: { text: "Country name" },
    }),
    JPN: strictLocalize({
      defaultMessage: "Japan",
      description: { text: "Country name" },
    }),
    JEY: strictLocalize({
      defaultMessage: "Jersey",
      description: { text: "Country name" },
    }),
    JOR: strictLocalize({
      defaultMessage: "Jordan",
      description: { text: "Country name" },
    }),
    KAZ: strictLocalize({
      defaultMessage: "Kazakhstan",
      description: { text: "Country name" },
    }),
    KEN: strictLocalize({
      defaultMessage: "Kenya",
      description: { text: "Country name" },
    }),
    KIR: strictLocalize({
      defaultMessage: "Kiribati",
      description: { text: "Country name" },
    }),
    PRK: strictLocalize({
      defaultMessage: "North Korea",
      description: { text: "Country name" },
    }),
    KOR: strictLocalize({
      defaultMessage: "South Korea",
      description: { text: "Country name" },
    }),
    XKX: strictLocalize({
      defaultMessage: "Kosovo",
      description: { text: "Country name" },
    }),
    KWT: strictLocalize({
      defaultMessage: "Kuwait",
      description: { text: "Country name" },
    }),
    KGZ: strictLocalize({
      defaultMessage: "Kyrgyzstan",
      description: { text: "Country name" },
    }),
    LAO: strictLocalize({
      defaultMessage: "Laos",
      description: { text: "Country name" },
    }),
    LVA: strictLocalize({
      defaultMessage: "Latvia",
      description: { text: "Country name" },
    }),
    LBN: strictLocalize({
      defaultMessage: "Lebanon",
      description: { text: "Country name" },
    }),
    LSO: strictLocalize({
      defaultMessage: "Lesotho",
      description: { text: "Country name" },
    }),
    LBR: strictLocalize({
      defaultMessage: "Liberia",
      description: { text: "Country name" },
    }),
    LBY: strictLocalize({
      defaultMessage: "Libya",
      description: { text: "Country name" },
    }),
    LIE: strictLocalize({
      defaultMessage: "Liechtenstein",
      description: { text: "Country name" },
    }),
    LTU: strictLocalize({
      defaultMessage: "Lithuania",
      description: { text: "Country name" },
    }),
    LUX: strictLocalize({
      defaultMessage: "Luxembourg",
      description: { text: "Country name" },
    }),
    MKD: strictLocalize({
      defaultMessage: "Macedonia, Republic",
      description: { text: "Country name" },
    }),
    MDG: strictLocalize({
      defaultMessage: "Madagascar",
      description: { text: "Country name" },
    }),
    MWI: strictLocalize({
      defaultMessage: "Malawi",
      description: { text: "Country name" },
    }),
    MYS: strictLocalize({
      defaultMessage: "Malaysia",
      description: { text: "Country name" },
    }),
    MDV: strictLocalize({
      defaultMessage: "Maldives",
      description: { text: "Country name" },
    }),
    MLI: strictLocalize({
      defaultMessage: "Mali",
      description: { text: "Country name" },
    }),
    MLT: strictLocalize({
      defaultMessage: "Malta",
      description: { text: "Country name" },
    }),
    MHL: strictLocalize({
      defaultMessage: "Marshall Islands",
      description: { text: "Country name" },
    }),
    MTQ: strictLocalize({
      defaultMessage: "Martinique",
      description: { text: "Country name" },
    }),
    MRT: strictLocalize({
      defaultMessage: "Mauritania",
      description: { text: "Country name" },
    }),
    MUS: strictLocalize({
      defaultMessage: "Mauritius",
      description: { text: "Country name" },
    }),
    MYT: strictLocalize({
      defaultMessage: "Mayotte",
      description: { text: "Country name" },
    }),
    MEX: strictLocalize({
      defaultMessage: "Mexico",
      description: { text: "Country name" },
    }),
    FSM: strictLocalize({
      defaultMessage: "Micronesia, Federated States",
      description: { text: "Country name" },
    }),
    MDA: strictLocalize({
      defaultMessage: "Moldova",
      description: { text: "Country name" },
    }),
    MCO: strictLocalize({
      defaultMessage: "Monaco",
      description: { text: "Country name" },
    }),
    MNG: strictLocalize({
      defaultMessage: "Mongolia",
      description: { text: "Country name" },
    }),
    MNE: strictLocalize({
      defaultMessage: "Montenegro",
      description: { text: "Country name" },
    }),
    MSR: strictLocalize({
      defaultMessage: "Montserrat",
      description: { text: "Country name" },
    }),
    MAR: strictLocalize({
      defaultMessage: "Morocco",
      description: { text: "Country name" },
    }),
    MOZ: strictLocalize({
      defaultMessage: "Mozambique",
      description: { text: "Country name" },
    }),
    MMR: strictLocalize({
      defaultMessage: "Myanmar",
      description: { text: "Country name" },
    }),
    NAM: strictLocalize({
      defaultMessage: "Namibia",
      description: { text: "Country name" },
    }),
    NRU: strictLocalize({
      defaultMessage: "Nauru",
      description: { text: "Country name" },
    }),
    NPL: strictLocalize({
      defaultMessage: "Nepal",
      description: { text: "Country name" },
    }),
    NLD: strictLocalize({
      defaultMessage: "Netherlands",
      description: { text: "Country name" },
    }),
    ANT: strictLocalize({
      defaultMessage: "Netherlands Antilles",
      description: { text: "Country name" },
    }),
    NCL: strictLocalize({
      defaultMessage: "New Caledonia",
      description: { text: "Country name" },
    }),
    NZL: strictLocalize({
      defaultMessage: "New Zealand",
      description: { text: "Country name" },
    }),
    NIC: strictLocalize({
      defaultMessage: "Nicaragua",
      description: { text: "Country name" },
    }),
    NER: strictLocalize({
      defaultMessage: "Niger",
      description: { text: "Country name" },
    }),
    NGA: strictLocalize({
      defaultMessage: "Nigeria",
      description: { text: "Country name" },
    }),
    NIU: strictLocalize({
      defaultMessage: "Niue",
      description: { text: "Country name" },
    }),
    NFK: strictLocalize({
      defaultMessage: "Norfolk Island",
      description: { text: "Country name" },
    }),
    MNP: strictLocalize({
      defaultMessage: "Northern Mariana Islands",
      description: { text: "Country name" },
    }),
    NOR: strictLocalize({
      defaultMessage: "Norway",
      description: { text: "Country name" },
    }),
    OMN: strictLocalize({
      defaultMessage: "Oman",
      description: { text: "Country name" },
    }),
    PAK: strictLocalize({
      defaultMessage: "Pakistan",
      description: { text: "Country name" },
    }),
    PLW: strictLocalize({
      defaultMessage: "Palau",
      description: { text: "Country name" },
    }),
    PSE: strictLocalize({
      defaultMessage: "Palestine",
      description: { text: "Country name" },
    }),
    PAN: strictLocalize({
      defaultMessage: "Panama",
      description: { text: "Country name" },
    }),
    PNG: strictLocalize({
      defaultMessage: "Papua New Guinea",
      description: { text: "Country name" },
    }),
    PRY: strictLocalize({
      defaultMessage: "Paraguay",
      description: { text: "Country name" },
    }),
    PER: strictLocalize({
      defaultMessage: "Peru",
      description: { text: "Country name" },
    }),
    PHL: strictLocalize({
      defaultMessage: "Philippines",
      description: { text: "Country name" },
    }),
    PCN: strictLocalize({
      defaultMessage: "Pitcairn",
      description: { text: "Country name" },
    }),
    POL: strictLocalize({
      defaultMessage: "Poland",
      description: { text: "Country name" },
    }),
    PRT: strictLocalize({
      defaultMessage: "Portugal",
      description: { text: "Country name" },
    }),
    PRI: strictLocalize({
      defaultMessage: "Puerto Rico",
      description: { text: "Country name" },
    }),
    QAT: strictLocalize({
      defaultMessage: "Qatar",
      description: { text: "Country name" },
    }),
    REU: strictLocalize({
      defaultMessage: "Réunion",
      description: { text: "Country name" },
    }),
    ROU: strictLocalize({
      defaultMessage: "Romania",
      description: { text: "Country name" },
    }),
    RUS: strictLocalize({
      defaultMessage: "Russia",
      description: { text: "Country name" },
    }),
    RWA: strictLocalize({
      defaultMessage: "Rwanda",
      description: { text: "Country name" },
    }),
    BLM: strictLocalize({
      defaultMessage: "Saint-Barthélemy",
      description: { text: "Country name" },
    }),
    SHN: strictLocalize({
      defaultMessage: "Saint Helena",
      description: { text: "Country name" },
    }),
    KNA: strictLocalize({
      defaultMessage: "Saint Kitts and Nevis",
      description: { text: "Country name" },
    }),
    LCA: strictLocalize({
      defaultMessage: "Saint Lucia",
      description: { text: "Country name" },
    }),
    MAF: strictLocalize({
      defaultMessage: "Saint-Martin",
      description: { text: "Country name" },
    }),
    SPM: strictLocalize({
      defaultMessage: "Saint Pierre and Miquelon",
      description: { text: "Country name" },
    }),
    VCT: strictLocalize({
      defaultMessage: "Saint Vincent and Grenadines",
      description: { text: "Country name" },
    }),
    WSM: strictLocalize({
      defaultMessage: "Samoa",
      description: { text: "Country name" },
    }),
    SMR: strictLocalize({
      defaultMessage: "San Marino",
      description: { text: "Country name" },
    }),
    STP: strictLocalize({
      defaultMessage: "Sao Tome and Principe",
      description: { text: "Country name" },
    }),
    SAU: strictLocalize({
      defaultMessage: "Saudi Arabia",
      description: { text: "Country name" },
    }),
    SEN: strictLocalize({
      defaultMessage: "Senegal",
      description: { text: "Country name" },
    }),
    SRB: strictLocalize({
      defaultMessage: "Serbia",
      description: { text: "Country name" },
    }),
    SYC: strictLocalize({
      defaultMessage: "Seychelles",
      description: { text: "Country name" },
    }),
    SLE: strictLocalize({
      defaultMessage: "Sierra Leone",
      description: { text: "Country name" },
    }),
    SGP: strictLocalize({
      defaultMessage: "Singapore",
      description: { text: "Country name" },
    }),
    SXM: strictLocalize({
      defaultMessage: "Sint Maarten",
      description: { text: "Country name" },
    }),
    SVK: strictLocalize({
      defaultMessage: "Slovakia",
      description: { text: "Country name" },
    }),
    SVN: strictLocalize({
      defaultMessage: "Slovenia",
      description: { text: "Country name" },
    }),
    SLB: strictLocalize({
      defaultMessage: "Solomon Islands",
      description: { text: "Country name" },
    }),
    SOM: strictLocalize({
      defaultMessage: "Somalia",
      description: { text: "Country name" },
    }),
    ZAF: strictLocalize({
      defaultMessage: "South Africa",
      description: { text: "Country name" },
    }),
    SGS: strictLocalize({
      defaultMessage: "South Georgia and the South Sandwich Islands",
      description: { text: "Country name" },
    }),
    SSD: strictLocalize({
      defaultMessage: "South Sudan",
      description: { text: "Country name" },
    }),
    ESP: strictLocalize({
      defaultMessage: "Spain",
      description: { text: "Country name" },
    }),
    LKA: strictLocalize({
      defaultMessage: "Sri Lanka",
      description: { text: "Country name" },
    }),
    SDN: strictLocalize({
      defaultMessage: "Sudan",
      description: { text: "Country name" },
    }),
    SUR: strictLocalize({
      defaultMessage: "Suriname",
      description: { text: "Country name" },
    }),
    SJM: strictLocalize({
      defaultMessage: "Svalbard and Jan Mayen Islands",
      description: { text: "Country name" },
    }),
    SWZ: strictLocalize({
      defaultMessage: "Eswatini",
      description: { text: "Country name" },
    }),
    SWE: strictLocalize({
      defaultMessage: "Sweden",
      description: { text: "Country name" },
    }),
    CHE: strictLocalize({
      defaultMessage: "Switzerland",
      description: { text: "Country name" },
    }),
    SYR: strictLocalize({
      defaultMessage: "Syria",
      description: { text: "Country name" },
    }),
    TWN: strictLocalize({
      defaultMessage: "Taiwan",
      description: { text: "Country name" },
    }),
    TJK: strictLocalize({
      defaultMessage: "Tajikistan",
      description: { text: "Country name" },
    }),
    TZA: strictLocalize({
      defaultMessage: "Tanzania, United Republic of",
      description: { text: "Country name" },
    }),
    THA: strictLocalize({
      defaultMessage: "Thailand",
      description: { text: "Country name" },
    }),
    TLS: strictLocalize({
      defaultMessage: "Timor-Leste",
      description: { text: "Country name" },
    }),
    TGO: strictLocalize({
      defaultMessage: "Togo",
      description: { text: "Country name" },
    }),
    TKL: strictLocalize({
      defaultMessage: "Tokelau",
      description: { text: "Country name" },
    }),
    TON: strictLocalize({
      defaultMessage: "Tonga",
      description: { text: "Country name" },
    }),
    TTO: strictLocalize({
      defaultMessage: "Trinidad and Tobago",
      description: { text: "Country name" },
    }),
    TUN: strictLocalize({
      defaultMessage: "Tunisia",
      description: { text: "Country name" },
    }),
    TUR: strictLocalize({
      defaultMessage: "Turkey",
      description: { text: "Country name" },
    }),
    TKM: strictLocalize({
      defaultMessage: "Turkmenistan",
      description: { text: "Country name" },
    }),
    TCA: strictLocalize({
      defaultMessage: "Turks and Caicos Islands",
      description: { text: "Country name" },
    }),
    TUV: strictLocalize({
      defaultMessage: "Tuvalu",
      description: { text: "Country name" },
    }),
    UGA: strictLocalize({
      defaultMessage: "Uganda",
      description: { text: "Country name" },
    }),
    UKR: strictLocalize({
      defaultMessage: "Ukraine",
      description: { text: "Country name" },
    }),
    ARE: strictLocalize({
      defaultMessage: "United Arab Emirates",
      description: { text: "Country name" },
    }),
    GBR: strictLocalize({
      defaultMessage: "United Kingdom",
      description: { text: "Country name" },
    }),
    USA: strictLocalize({
      defaultMessage: "United States of America",
      description: { text: "Country name" },
    }),
    UMI: strictLocalize({
      defaultMessage: "US Minor Outlying Islands",
      description: { text: "Country name" },
    }),
    URY: strictLocalize({
      defaultMessage: "Uruguay",
      description: { text: "Country name" },
    }),
    UZB: strictLocalize({
      defaultMessage: "Uzbekistan",
      description: { text: "Country name" },
    }),
    VUT: strictLocalize({
      defaultMessage: "Vanuatu",
      description: { text: "Country name" },
    }),
    VEN: strictLocalize({
      defaultMessage: "Venezuela",
      description: { text: "Country name" },
    }),
    VNM: strictLocalize({
      defaultMessage: "Vietnam",
      description: { text: "Country name" },
    }),
    VIR: strictLocalize({
      defaultMessage: "Virgin Islands",
      description: { text: "Country name" },
    }),
    WLF: strictLocalize({
      defaultMessage: "Wallis and Futuna Islands",
      description: { text: "Country name" },
    }),
    ESH: strictLocalize({
      defaultMessage: "Western Sahara",
      description: { text: "Country name" },
    }),
    YEM: strictLocalize({
      defaultMessage: "Yemen",
      description: { text: "Country name" },
    }),
    ZMB: strictLocalize({
      defaultMessage: "Zambia",
      description: { text: "Country name" },
    }),
    ZWE: strictLocalize({
      defaultMessage: "Zimbabwe",
      description: { text: "Country name" },
    }),
  };
  const ISO_TO_COUNTRY_NAME = Object.fromEntries(
    Object.entries(ISO_TO_COUNTRY_NAME_UNSORTED).sort(([, a], [, b]) =>
      a.localeCompare(b, LocalizationStore.get().locale)
    )
  ) as typeof ISO_TO_COUNTRY_NAME_UNSORTED;

  return omit(ISO_TO_COUNTRY_NAME, ["CUB", "PRK", "SYR", "IRN"]);
};

export const getIsoToCountryNamesSorted = (
  strictLocalize: StrictLocalizeFunction
) => {
  return getIsoToCountryNames(strictLocalize);
};

export const ISO_TO_COUNTRY_NAME_WITHOUT_SANCTIONS = (
  strictLocalize: StrictLocalizeFunction
) => getIsoToCountryNames(strictLocalize);
