import {
  useQuery,
  getQueryKey,
  getQueryFn,
} from "@faire/web-api--source/api/v2/product/productToken/get-hooks";
import { QueryClient } from "@tanstack/react-query";

import { readBundledData } from "@faire/retailer/lib/bundledData";

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

export const useLeanProductQuery = (
  productToken: string | undefined,
  options?: Parameters<typeof useQuery>[1]
) => {
  return useQuery(productToken ?? "", {
    initialData: () => {
      const prefetched = readBundledData("product_page", { keep: true });
      if (productToken === prefetched?.product?.token) {
        return prefetched;
      }
      return undefined;
    },
    enabled: !!productToken,
    staleTime: FIVE_MINUTES_IN_MS,
    refetchOnMount: false,
    ...options,
  });
};

export const prefetchLeanProduct = (
  queryClient: QueryClient,
  productToken: string
) => {
  queryClient.prefetchQuery({
    queryKey: getQueryKey(productToken),
    queryFn: getQueryFn(productToken),
  });
};
