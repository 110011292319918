import { IRecordClientEventRequest } from '../../indigofair/data/IRecordClientEventRequest';

export namespace IRecordClientEventsRequest {
  /**
   * Return an instance of IRecordClientEventsRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IRecordClientEventsRequest>
  ): IRecordClientEventsRequest =>
    ({
      events: [],
      ...(partial ?? {}),
    }) as IRecordClientEventsRequest;
}

export interface IRecordClientEventsRequest {
  events: Array<IRecordClientEventRequest>;
  is_validation: boolean | undefined;
}
