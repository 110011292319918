import get from "lodash/get";

export type GlobalLookup = <T>(prop: string) => T | undefined;

const check = (it: unknown) => {
  // Math is known to exist as a global in every environment.
  return it && (it as { Math: unknown }).Math === Math && it;
};

// IE11 doesn't support globalThis.
const globalObject =
  check(typeof globalThis === "object" && globalThis) ||
  // eslint-disable-next-line @faire-web/no-restricted-globals
  check(typeof window === "object" && window) ||
  check(typeof self === "object" && self) ||
  check(typeof global === "object" && global) ||
  Function("return this")();

export const defaultLookup: GlobalLookup = <T>(prop: string): T | undefined => {
  return get(globalObject, prop);
};

let lookupOverride: GlobalLookup | undefined;

export const setGlobalLookup = (
  lookup: (<T>(prop: string) => T | undefined) | undefined
) => {
  lookupOverride = lookup;
};

export const getGlobalLookup = (): GlobalLookup => {
  return lookupOverride ?? defaultLookup;
};
