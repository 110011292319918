import { SettingConst } from '../../types';

/**
 * URL for publicly available Open Telemetry collector.
 */
export const OPEN_TELEMETRY_COLLECTOR_URL =
  'OPEN_TELEMETRY_COLLECTOR_URL' as SettingConst<
    'OPEN_TELEMETRY_COLLECTOR_URL',
    string,
    'RETAILER'
  >;
