import { Path } from "@faire/web--source/common/Path";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * Hook version of getCurrentPath. The main difference is that this hook uses
 * useLocation instead of window.location to build the path. This ensures alignment
 * between other react-router hooks/components and this hook.
 *
 * @returns {Path} The current path using the react-router location object.
 */
export const useCurrentPath = (): Path => {
  const location = useLocation();
  return useMemo(() => new Path(location), [location]);
};
