import React from "react";

// eslint-disable-next-line import/no-unassigned-import
import "./native-styles.css";
import { Props, Typography } from "./Typography";

export const TooltipTypography = React.forwardRef<HTMLElement, Props>(
  (props, ref) => {
    return <Typography {...props} ref={ref} className="f_t_tooltip" />;
  }
);
