import {
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_BY_LANGUAGE,
  DEFAULT_LOCALE_KEY,
} from "@faire/web--source/common/consts/DEFAULT_LOCALE";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { LocalizationCacheStore } from "@faire/web--source/common/localization/LocalizationCacheStore";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";
import { createIntl } from "@formatjs/intl/lib/src/create-intl";
import { IntlConfig, IntlShape } from "@formatjs/intl/lib/src/types";
import { MessageFormatElement } from "intl-messageformat-parser/no-parser";

import { createFormatMessage } from "./createFormatMessage";
import { buildLocale } from "./utils";

/**
 * This store exposes the `localeKey` and `currencyFormatLocale` global properties.
 *
 * @deprecated use `useLocalization` from `@faire/web--source/common/localization/useLocalization` instead
 */
export class LocalizationStore {
  static get = singletonGetter(LocalizationStore);

  private _intl!: IntlShape<string>;

  // Used in a node context when we do not have a window object
  private _currencyFormatLocale?: string;

  // The CLI needs this to create users for some reason
  private _shouldPassThrough = false;

  private _localeKey: ILocaleKey | undefined;

  constructor() {
    this._localeKey = getGlobalProperty<ILocaleKey>("localeKey");
    const locale = buildLocale(this.localeKey ?? DEFAULT_LOCALE_KEY);
    this.readCachedIntlOrSetIfUndefined(locale, {
      // We set defaultLocale as locale since we inline translations so falling back to default
      // is the same as falling back to our chosen locale
      defaultLocale: locale,
    });
    this.initSpecialModes();
  }

  readCachedIntlOrSetIfUndefined = (
    locale: string,
    config: Partial<IntlConfig<string>> = {}
  ) => {
    const cachedIntl = LocalizationCacheStore.get().getCachedIntl(locale);
    if (cachedIntl) {
      this._intl = cachedIntl.intl;
    } else {
      this._intl = createIntl(
        {
          locale,
          defaultLocale: DEFAULT_LOCALE,
          messages: {},
          ...config,
        },
        LocalizationCacheStore.get().cache
      );
      LocalizationCacheStore.get().setCachedIntl(locale, this._intl);
    }
  };

  initSpecialModes = () => {
    if (
      this.localeKey?.language === Language.STRING_ID ||
      this.localeKey?.language === Language.GIBBERISH
    ) {
      const formatMessage = createFormatMessage(
        this.localeKey,
        this._intl.formatMessage
      );
      this._intl = {
        ...this._intl,
        formatMessage,
      };
    }
  };

  enableGibberishMode = () => {
    this._localeKey = DEFAULT_LOCALE_BY_LANGUAGE[Language.GIBBERISH];
    const locale = buildLocale(this.localeKey ?? DEFAULT_LOCALE_KEY);
    this.initializeIntl({ locale, messages: {} });
    this.initSpecialModes();
  };

  // We need to recreate the intl object in order to load in new messages
  initializeIntl = (args: {
    locale: string;
    currencyFormatLocale?: string;
    messages: Record<string, MessageFormatElement[]>; // We require everything must be AST
  }) => {
    this._currencyFormatLocale = args.currencyFormatLocale;
    this.readCachedIntlOrSetIfUndefined(args.locale, {
      messages: args.messages,
    });
  };

  get intl(): IntlShape<string> {
    return this._intl;
  }

  get localeKey(): ILocaleKey | undefined {
    return this._localeKey;
  }

  get locale(): string {
    return this._intl.locale;
  }

  get messages() {
    return this._intl.messages as
      | Record<string, string>
      | Record<string, MessageFormatElement[]>;
  }

  /**
   * Translate the given message-resolution function in a context that uses the
   * given locale (by temporarily replacing the global Locale context).
   */
  translate = (
    message: () => string,
    locale: string,
    messages: Record<string, string> | Record<string, MessageFormatElement[]>
  ) => {
    const currentIntl = this._intl;
    let resolved: string | undefined;
    try {
      this.readCachedIntlOrSetIfUndefined(locale, {
        messages,
      });
      resolved = message();
    } finally {
      this._intl = currentIntl;
    }
    return resolved;
  };

  setPassThrough = () => {
    this._shouldPassThrough = true;
    // In the event that we want to bypass localization altogether, this
    // will likely only be applicable in node environments where we want
    // to ignore formatjs + dynamic ID generation.
    this._intl = {
      ...this._intl,
      formatMessage: (descriptor: any): string => {
        return descriptor.defaultMessage;
      },
    };
  };

  get currencyFormatLocale(): string {
    return (
      this._currencyFormatLocale ??
      getGlobalProperty("currencyFormatLocale") ??
      this.locale
    );
  }

  get intlForCurrency() {
    // If currency locale is different than page locale, we need to create
    // a different intl object to use for formatting currencies
    if (this.locale !== this.currencyFormatLocale) {
      return createIntl({
        locale: this.currencyFormatLocale,
        messages: {},
      });
    }
    return this._intl;
  }

  get shouldPassThrough() {
    return this._shouldPassThrough;
  }
}
