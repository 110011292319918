import { getWindow } from "@faire/web--source/common/globals/getWindow";

export const stubImage = (url?: string, srcset?: string, sizes?: string) => {
  const ImageConstructor = getWindow()?.Image;
  if (ImageConstructor && url) {
    const img = new ImageConstructor();
    img.src = url;
    if (srcset) {
      img.srcset = srcset;
    }
    if (sizes) {
      img.sizes = sizes;
    }
  }
};
