export type PerformanceEvent = {
  name: "mainJsExecuted" | "htmlStarted";
};

// A key name that is used to mark the start of the document in web-retailer HTML template.
const DOCUMENT_STARTED_KEY_NAME = "docStart";

export function getKeyForEvent(event: PerformanceEvent) {
  if (event.name === "htmlStarted") {
    return DOCUMENT_STARTED_KEY_NAME;
  }
  return `performance-breakdown-${event.name}`;
}
