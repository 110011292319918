export namespace IListMessagesRequestV3 {
  /**
   * Return an instance of IListMessagesRequestV3 with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IListMessagesRequestV3>
  ): IListMessagesRequestV3 =>
    ({
      ...(partial ?? {}),
    }) as IListMessagesRequestV3;
}

export interface IListMessagesRequestV3 {
  conversation_token: string | undefined;
  /**
   * Query for searching messages.
   */
  query: string | undefined;
  /**
   * The maximum number of results to return. This is capped at 20 by the server.
   */
  max_results: number | undefined;
  /**
   * Not to be used directly by clients. Will be automatically set in response's nextRequest.
   * TODO(PD): What exactly is this? Should this just be another conversation token?
   */
  pagination_token: string | undefined;
}
