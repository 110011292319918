import { SettingConst } from '../../types';

/**
 * When enabled, the messenger fab is removed from retailer portal for unverified retailers
 */
export const REMOVE_MESSENGER_FAB_FOR_UNVERIFIED_RETAILERS_241112 =
  'REMOVE_MESSENGER_FAB_FOR_UNVERIFIED_RETAILERS_241112' as SettingConst<
    'REMOVE_MESSENGER_FAB_FOR_UNVERIFIED_RETAILERS_241112',
    boolean,
    'RETAILER'
  >;
