import { fontRamp } from "@faire/web--source/slate/Typography/Themes";
import type {
  TypographyTags,
  TypographyTheme,
  TypographyVariants,
} from "@faire/web--source/slate/Typography/Types";

// TODO: import this from faire/web once it's exported from a server compatible file
export const variantTagMap: { [key in TypographyVariants]: TypographyTags } = {
  displayXLSerifRegular: "h1",
  displayLSerifRegular: "h2",
  displayMSerifRegular: "h3",
  displaySSansSemiBold: "h4",
  displaySSansRegular: "h4",
  displaySSerifRegular: "h4",
  pageHeaderSerifRegular: "h4",
  sectionHeaderSansMedium: "h5",
  subheadingSansRegular: "h5",
  subheadingSansMedium: "h5",
  paragraphSansRegular: "p",
  paragraphSansMedium: "p",
  labelSansRegular: "p",
  labelSansMedium: "p",
};

/**
 * pure function that formats fontRamp's styles from kebab-case to camelCase
 */
const formatFontRamp = () => {
  const result = {} as TypographyTheme["fonts"];
  for (const [_key, value] of Object.entries(fontRamp.fonts)) {
    const key = _key as TypographyVariants;
    const style: Record<string, unknown> = {};
    for (const [styleKey, styleValue] of Object.entries(value)) {
      const newStyleKey = styleKey.replace(/-([a-z])/g, function (g) {
        return g[1]!.toUpperCase();
      });
      style[newStyleKey] = styleValue;
    }
    result[key] = style;
  }
  return result;
};

export const FONT_RAMP = formatFontRamp();
