import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import {
  isLoggingSettingUsageEnabled,
  trackSettingsAndExperiment,
} from "@faire/web--source/common/serialized-setting-tracker/SerializedSettingTracker";
import { IFrontendSetting } from "@faire/web--source/common/settings/declarations/IFrontendSetting";
import { SettingConst } from "@faire/web-api--source/types";
import isEqual from "lodash/isEqual";

/**
 * Getter logic shared between the hook implementation and the legacy implementation of setting retrieval.
 */
export function sharedGetSetting(
  settingOrKey: SettingConst | IFrontendSetting,
  serializedValues: { [key: string]: unknown } | undefined,
  overrideSettingValue: unknown,
  defaultValue?: unknown
): unknown {
  let key: string;
  let serializedSettingValue: unknown;
  if (typeof settingOrKey === "string") {
    key = settingOrKey;
    serializedSettingValue = serializedValues?.[settingOrKey];
  } else {
    key = settingOrKey.name;
    serializedSettingValue = serializedValues?.[settingOrKey.name];
  }

  const surfacedSettingValue =
    overrideSettingValue ?? serializedSettingValue ?? defaultValue;

  if (isLoggingSettingUsageEnabled()) {
    trackSettingsAndExperiment([key], {
      settingValue: surfacedSettingValue,
    });
  }

  if (
    getGlobalProperty("envName") !== "production" &&
    getGlobalProperty("needsHydration", false) &&
    overrideSettingValue !== undefined &&
    !isEqual(overrideSettingValue, serializedSettingValue ?? defaultValue)
  ) {
    // We provide a mechanism to override settings in development,
    // but we want to make sure that the same settings are used
    // for the server render as on the client, to prevent mismatches
    // (React error #418 and #425).
    throw new Error(
      `Expected ${key} to be ${JSON.stringify(
        serializedSettingValue ?? defaultValue
      )} (server value) but it was overriden to be ${JSON.stringify(
        overrideSettingValue
      )}. This is because something in localStorage.PublicSettingsOverrides was not ` +
        "passed to X-IF-SETTINGS. If you have disabled the extention, consider clearing " +
        "localStroage."
    );
  }
  return surfacedSettingValue;
}
