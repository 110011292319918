import { SettingConst } from '../../types';

/**
 * If enabled, display retrieval and ranking debug info in product tiles
 */
export const SHOULD_DISPLAY_RETRIEVAL_AND_RANKING_DEBUG_INFO =
  'SHOULD_DISPLAY_RETRIEVAL_AND_RANKING_DEBUG_INFO' as SettingConst<
    'SHOULD_DISPLAY_RETRIEVAL_AND_RANKING_DEBUG_INFO',
    boolean,
    'RETAILER' | 'LOGGED_IN_RETAILER' | 'ADMIN' | 'RETAILER_HEAD_TAGS'
  >;
