import some from "lodash/some";
import qs from "query-string";
import { matchPath } from "react-router-dom";

import { faireDirectPortalPaths } from "./faireDirectPortalPaths";

export const isValidFaireDirectPath = (
  path: string,
  faireDirectBrandToken: string
): boolean => {
  const { url: pathWithoutQueryParams } = qs.parseUrl(path);

  return some(
    faireDirectPortalPaths(faireDirectBrandToken),
    (validFaireDirectPath) =>
      matchPath(pathWithoutQueryParams, {
        path: validFaireDirectPath,
        exact: true,
      })
  );
};
