import { Storage } from "@faire/web--source/common/Storage";

export const APP_DOWNLOAD_NUDGE_DISMISS_INFO =
  "retailer_mobile-app-download-nudge-dismiss-info";
export const MAXIMUM_DISMISS_COUNT_PER_DAY = 3;
export const CLEAR_DISMISS_INTERVAL_DAYS = 1;

export const useGetNudgeDismissInfo = () => {
  const dismissInfo = JSON.parse(
    Storage.local.getItem(APP_DOWNLOAD_NUDGE_DISMISS_INFO) ?? "{}"
  );
  const shouldDismissBeCleared =
    Number(dismissInfo?.clearDismissOn) <= Date.now();
  const dismissCount = dismissInfo?.dismissCount ?? 0;
  const isDismissOverLimit = dismissCount >= MAXIMUM_DISMISS_COUNT_PER_DAY;

  if (shouldDismissBeCleared) {
    Storage.local.removeItem(APP_DOWNLOAD_NUDGE_DISMISS_INFO);
  }

  return {
    isDismissOverLimit,
    dismissCount,
    clearDismissOn: dismissInfo?.clearDismissOn,
  };
};
