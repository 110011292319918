export namespace IRetailerLinkCohortEnum {
  export enum RetailerLinkCohort {
    RETAILER_LINK_COHORT_UNKNOWN = 'RETAILER_LINK_COHORT_UNKNOWN',
    /**
     * Retailers who have never linked their POS or have churned after the new experience launched
     */
    NEW = 'NEW',
    /**
     * Retailers who unlinked their POS before the new experience launched
     */
    CHURNED = 'CHURNED',
    /**
     * Linked retailers who haven't linked items since the new experience launched
     */
    INACTIVE = 'INACTIVE',
    /**
     * Linked retailers who have linked items since the new experience launched
     */
    ACTIVE = 'ACTIVE',
  }

  /**
   * Return an instance of IRetailerLinkCohortEnum with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IRetailerLinkCohortEnum>
  ): IRetailerLinkCohortEnum =>
    ({
      ...(partial ?? {}),
    }) as IRetailerLinkCohortEnum;
}

export interface IRetailerLinkCohortEnum {}
