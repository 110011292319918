export enum PromotionalEventType {
  PROMOTIONAL_EVENT_TYPE_UNKNOWN = 'PROMOTIONAL_EVENT_TYPE_UNKNOWN',
  /**
   * @deprecated
   * @protobufOption (eventDefaultPromoCode) = "SUMMERMARKET20"
   * @protobufOption (eventGroup) = MARKET
   * @protobufOption (eventWithDiscountMatchType) = "FAIRE_SUMMER_MARKET_2020"
   */
  FAIRE_SUMMER_MARKET_2020 = 'FAIRE_SUMMER_MARKET_2020',
  /**
   * @deprecated
   * @protobufOption (eventDefaultPromoCode) = "WINTERMARKET21"
   * @protobufOption (eventGroup) = MARKET
   * @protobufOption (eventWithDiscountMatchType) = "FAIRE_WINTER_MARKET_2021"
   */
  FAIRE_WINTER_MARKET_2021 = 'FAIRE_WINTER_MARKET_2021',
  /**
   * @deprecated
   * @protobufOption (eventDefaultPromoCode) = "SUMMERMARKET21"
   * @protobufOption (eventGroup) = MARKET
   * @protobufOption (eventWithDiscountMatchType) = "FAIRE_SUMMER_MARKET_2021"
   */
  FAIRE_SUMMER_MARKET_2021 = 'FAIRE_SUMMER_MARKET_2021',
  /**
   * @deprecated
   * @protobufOption (eventGroup) = FASHION_WEEK
   */
  FAIRE_FASHION_WEEK_2021 = 'FAIRE_FASHION_WEEK_2021',
  /**
   * @deprecated
   * @protobufOption (eventDefaultPromoCode) = "WINTERMARKET22"
   * @protobufOption (eventGroup) = MARKET
   * @protobufOption (eventWithDiscountMatchType) = "FAIRE_WINTER_MARKET_2022"
   */
  FAIRE_WINTER_MARKET_2022 = 'FAIRE_WINTER_MARKET_2022',
  /**
   * @deprecated
   * @protobufOption (eventGroup) = FASHION_WEEK
   * @protobufOption (eventWithDiscountMatchType) = "FAIRE_FASHION_WEEK_DISCOUNT_2022"
   */
  FAIRE_FASHION_WEEK_2022 = 'FAIRE_FASHION_WEEK_2022',
  /**
   * @deprecated
   * @protobufOption (eventDefaultPromoCode) = "SUMMERMARKET2022"
   * @protobufOption (eventGroup) = MARKET
   * @protobufOption (eventWithDiscountMatchType) = "FAIRE_SUMMER_MARKET_2022"
   */
  FAIRE_SUMMER_MARKET_2022 = 'FAIRE_SUMMER_MARKET_2022',
  /**
   * @deprecated
   * @protobufOption (eventGroup) = FASHION_WEEK
   */
  FAIRE_FASHION_WEEK_V3 = 'FAIRE_FASHION_WEEK_V3',
  /**
   * @deprecated
   * @protobufOption (eventDefaultPromoCode) = "WINTERMARKET23"
   * @protobufOption (eventGroup) = MARKET
   * @protobufOption (eventWithDiscountMatchType) = "FAIRE_WINTER_MARKET_2023"
   */
  FAIRE_WINTER_MARKET_2023 = 'FAIRE_WINTER_MARKET_2023',
  /**
   * @deprecated
   * @protobufOption (eventDefaultPromoCode) = "FAIREMARKET23"
   * @protobufOption (eventGroup) = MARKET
   * @protobufOption (eventWithDiscountMatchType) = "FAIRE_MARKET_JULY_2023"
   */
  FAIRE_MARKET_JULY_2023 = 'FAIRE_MARKET_JULY_2023',
  /**
   * @deprecated
   * @protobufOption (eventDefaultPromoCode) = "FAIREMARKET24"
   * @protobufOption (eventGroup) = MARKET
   * @protobufOption (eventWithDiscountMatchType) = "FAIRE_MARKET_JANUARY_2024"
   */
  FAIRE_MARKET_JANUARY_2024 = 'FAIRE_MARKET_JANUARY_2024',
  /**
   * @deprecated
   * @protobufOption (eventDefaultPromoCode) = "FAIREMARKET_JUL24"
   * @protobufOption (eventGroup) = MARKET
   * @protobufOption (eventWithDiscountMatchType) = "FAIRE_MARKET_JULY_2024"
   */
  FAIRE_MARKET_JULY_2024 = 'FAIRE_MARKET_JULY_2024',
  /**
   * @protobufOption (eventDefaultPromoCode) = "FAIREMARKET_JAN25"
   * @protobufOption (eventGroup) = MARKET
   * @protobufOption (eventIsFeaturedEvent) = true
   * @protobufOption (eventWithDiscountMatchType) = "FAIRE_MARKET_JANUARY_2025"
   */
  FAIRE_MARKET_JANUARY_2025 = 'FAIRE_MARKET_JANUARY_2025',
}
