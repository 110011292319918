import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'faire_direct-shoppable_landing_page_modal_close-click';

/**
 * Tracking of closing of the modal on the Shoppable Faire Direct Landing Page
 */
export function trackFaireDirectShoppableLandingPageModalCloseClick(): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
  });
}
