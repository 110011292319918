import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";
import invert from "lodash/invert";
import mapValues from "lodash/mapValues";

import { DEFAULT_LOCALE_BY_LANGUAGE } from "./DEFAULT_LOCALE";
import { getTwoLetterLanguageCode } from "./THREE_TO_TWO_LETTER_LANGUAGE_CODE";

const LANGUAGE_TO_COUNTRY = mapValues(
  DEFAULT_LOCALE_BY_LANGUAGE,
  (locale) => locale.country
);
const COUNTRY_TO_LANGUAGE = invert(LANGUAGE_TO_COUNTRY);
const THREE_LETTER_COUNTRY_TO_DEFAULT_TWO_LETTER_LANGUAGE = mapValues(
  COUNTRY_TO_LANGUAGE,
  (language) => getTwoLetterLanguageCode(language)
);

export function getDefaultTwoLetterLanguageFromCountry(
  key: keyof typeof ISO3166Alpha3
) {
  return THREE_LETTER_COUNTRY_TO_DEFAULT_TWO_LETTER_LANGUAGE[key];
}
