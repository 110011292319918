import { getGlobalProperty } from "./getGlobalProperty";

export const getWindow = (): (Window & typeof globalThis) | undefined =>
  getGlobalProperty<Window & typeof globalThis>("window");

export const getWindowOrThrow = (): Window & typeof globalThis => {
  const window = getWindow();
  if (!window) {
    throw new Error("Could not find global window");
  }
  return window;
};
