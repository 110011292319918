export enum FreeShippingReason {
  FIRST_ORDER_FREE_SHIPPING = 'FIRST_ORDER_FREE_SHIPPING',
  /**
   * @deprecated
   */
  FIRST_ORDER_FREE_SHIPPING_PERIOD = 'FIRST_ORDER_FREE_SHIPPING_PERIOD',
  FIRST_APP_ORDER_FREE_SHIPPING = 'FIRST_APP_ORDER_FREE_SHIPPING',
  EMPLOYEE_GRANTED_FREE_SHIPPING = 'EMPLOYEE_GRANTED_FREE_SHIPPING',
  FAIRE_DIRECT_FREE_SHIPPING = 'FAIRE_DIRECT_FREE_SHIPPING',
  SELECT_FREE_SHIPPING = 'SELECT_FREE_SHIPPING',
  NEXT_ORDER_FREE_SHIPPING_INTEGRATION_LINKED = 'NEXT_ORDER_FREE_SHIPPING_INTEGRATION_LINKED',
  /**
   * @deprecated
   */
  UK_WELCOME_FREE_SHIPPING = 'UK_WELCOME_FREE_SHIPPING',
  /**
   * @deprecated
   */
  NLD_WELCOME_FREE_SHIPPING = 'NLD_WELCOME_FREE_SHIPPING',
  /**
   * @deprecated
   */
  UK_EU_BRANDS_FREE_SHIPPING = 'UK_EU_BRANDS_FREE_SHIPPING',
  INTERNATIONAL_WELCOME_FREE_SHIPPING = 'INTERNATIONAL_WELCOME_FREE_SHIPPING',
  /**
   * @deprecated
   */
  ANZ_FIRST_DOMESTIC_ORDER_FREE_SHIPPING = 'ANZ_FIRST_DOMESTIC_ORDER_FREE_SHIPPING',
  NEW_ARRIVALS_FREE_SHIPPING = 'NEW_ARRIVALS_FREE_SHIPPING',
}
