import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { parseToBoolean } from "@faire/web-api--source/parseUtils";

interface IEnvironmentStore<T extends { [P in keyof T]: string }> {
  get: <K extends keyof T>(key: K) => string;
  getOrDefault: <K extends keyof T>(key: K, defaultValue: string) => string;
}

/**
 * Factory for provisioning an EnvironmentStore with known keys
 * @example
 * ```
 * interface MyEnvironment { foo: string }
 * export const EnvironmentStore = EnvironmentFactory<MyEnvironment>();
 * ```
 */
export const EnvironmentFactory = <T extends { [P in keyof T]: string }>() =>
  class EnvironmentStore implements IEnvironmentStore<T> {
    static readonly get = singletonGetter(EnvironmentStore);

    readonly get = <K extends keyof T>(key: K): string => {
      const value = process.env[`${String(key)}`];
      if (typeof value === "undefined") {
        throw new Error(`${String(key)} not set in environment vars`);
      }
      return value;
    };

    readonly getOrDefault = <K extends keyof T, V = string>(
      key: K,
      defaultValue: V
    ): string | V => {
      const value = process.env[`${String(key)}`];
      if (typeof value === "undefined") {
        return defaultValue;
      }
      return value;
    };

    readonly getBoolean = <K extends keyof T>(
      key: K,
      defaultValue: "false" | "true" = "false"
    ): boolean => {
      return parseToBoolean(this.getOrDefault(key, defaultValue));
    };
  };
