import { getTwoLetterLanguageCode } from "@faire/web--source/common/consts/THREE_TO_TWO_LETTER_LANGUAGE_CODE";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { convertLocaleToCountryLocaleString } from "@faire/web--source/common/localization/languageWithCountryOptions";
import { LocalizationStore } from "@faire/web--source/common/localization/LocalizationStore";
import { prependLocaleToUrl } from "@faire/web--source/ui/routing/util";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";

/**
 * @deprecated Use `prependLocaleToUrl` and `RoutingContext` instead.
 */
export const localizePath = (path: string): string => {
  const locale = LocalizationStore.get().locale;
  const canonicalLocale = getTwoLetterLanguageCode(Language.ENG).toLowerCase();
  const localeCountryUrlPrefix = getGlobalProperty<string>(
    "localeCountryUrlPrefix"
  );
  const availableLanguageSelectorLocales = (
    getGlobalProperty<ILocaleKey[]>("availableLanguageSelectorLocales") ?? []
  ).map((iLocaleKey) => convertLocaleToCountryLocaleString(iLocaleKey));

  return prependLocaleToUrl(path, {
    locale,
    canonicalLocale,
    localeCountryUrlPrefix,
    allowedRobotLocales: availableLanguageSelectorLocales,
  });
};
