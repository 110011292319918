const DEFAULT_ELLIPSIS = "[...]";

/**
 * Truncates a given string to a specified length and appends an ellipsis string if the
 * original string exceeds that length.
 */
export const truncate = (
  path: string,
  limit: number,
  ellipsis: string = DEFAULT_ELLIPSIS
): string => {
  if (ellipsis.length > limit) {
    throw new Error("Ellipsis length must be less than or equal to limit");
  }
  if (path.length <= limit) {
    return path;
  } else {
    return path.substring(0, limit - ellipsis.length) + ellipsis;
  }
};
