import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'ads-adblock_detector_fire_for_all-view';

/**
 * Fire the event for all users, to establish everyone who visited that day.
 */
export function trackAdsAdblockDetectorFireForAllView(
  userToken: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: { userToken },
  });
}
