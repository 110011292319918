import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { logError } from "@faire/web--source/common/logging";
import { IPixelContents } from "@faire/web-api--source/indigofair/data/IPixelContents";
import { IPixelCustomDataParameters } from "@faire/web-api--source/indigofair/data/IPixelCustomDataParameters";
import { TrackedEvent } from "@faire/web-api--source/types";

export const googleTagManagerInterceptor = (params: TrackedEvent) => {
  if (params.facebookPixelEvent) {
    try {
      emitGoogleTagManagerEvent(
        params.facebookPixelEvent,
        params.pixelCustomDataParams,
        { eventID: params.eventId }
      );
    } catch (_) {
      /* ignore */
    }
  }
};

// Docs: https://developers.google.com/tag-platform/tag-manager/datalayer
interface GoogleTagManagerEvent {
  push: (event: { event: string } & { [key: string]: any }) => void;
}

export const emitGoogleTagManagerEvent = (
  event: string,
  vars: Partial<IPixelCustomDataParameters> | undefined,
  context: { eventID: string }
) => {
  const dataLayer = getGlobalProperty<GoogleTagManagerEvent>("dataLayer");

  if (!dataLayer) {
    if (getGlobalProperty("envName") === "production") {
      logError(`dataLayer not initialized`);
    }
    return;
  }

  const contents = vars?.contents?.map((content: IPixelContents) => {
    return {
      contentId: content.id,
      price: content.item_price,
      quantity: content.quantity,
    };
  });
  const data = {
    value: vars?.cart_value ?? vars?.value,
    currency: vars?.currency,
    conversionId: context.eventID,
    contents: contents,
  };

  dataLayer.push({ event, ...data });
};
