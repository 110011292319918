import { request } from '../../../../..';
import { IGetConversationResponseV3 } from '../../../../../faire/messenger/IGetConversationResponseV3';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../../types';

export const path = (brandToken: string) => {
  return `/api/v3/messenger/brand-conversation/${brandToken}`;
};

export const build = (
  brandToken: string,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(brandToken),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v3/messenger/brand-conversation/:brand_token';

/**
 * Product Area: MESSENGER_INFRASTRUCTURE
 */
export function execute(
  brandToken: string
): Promise<IGetConversationResponseV3>;
export function execute(
  brandToken: string,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetConversationResponseV3>>;
export function execute(
  brandToken: string,
  options?: EndpointOptions
): Promise<IGetConversationResponseV3>;
export function execute(
  brandToken: string,
  options?: EndpointOptions
): Promise<
  IGetConversationResponseV3 | SuccessResponse<IGetConversationResponseV3>
> {
  const { route, ...requestOptions } = build(brandToken, options);
  return request<IGetConversationResponseV3>(requestOptions);
}
execute[PATH] = 'api/v3/messenger/brand-conversation/brandToken/get';
export default execute;
