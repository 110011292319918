import { initFaireGlobal } from "@faire/web--source/common/globals/initFaireGlobal";
import { setGlobalLookup } from "@faire/web--source/common/globals/lookup";
import { trackMainJsExecuted } from "@faire/web--source/common/performance/TrackPerformanceBreakdown/trackMainJsExecuted";
import { setSingletonGetter } from "@faire/web--source/common/singletons/getter";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { useStaticRendering } from "mobx-react-lite";

import { maybeUse } from "../reactInternals";
import { scopedSingletonGetter } from "../scopedSingletonGetter";
import { SSRRequestLocalStorage } from "../SSRRequestLocalStorage";

initFaireGlobal(); // https://fairewholesale.atlassian.net/browse/FD-170002

// We need to set our request-specific singletonGetter before initializing web-retailer.
//
// TODO(jocelyn) In the future, we should consider making this the default implementation of singletonGetter,
// since this implementation works so long as the global lookup is initialized.
// https://fairewholesale.atlassian.net/browse/FD-170017
// eslint-disable-next-line @faire/ssr-no-restricted-globals
if (typeof window === "undefined") {
  // These imports are not compatible with request-scoped globals
  // and need to be initialized before we set the global lookup,
  // below.
  // eslint-disable-next-line import/no-unassigned-import
  require("@faire/web--source/common/currency/CurrencyStore"); // https://fairewholesale.atlassian.net/browse/FD-169253
  // eslint-disable-next-line import/no-unassigned-import
  require("@faire/web--source/common/currencyConfig"); // https://fairewholesale.atlassian.net/browse/FD-169253

  setSingletonGetter(scopedSingletonGetter); // https://fairewholesale.atlassian.net/browse/FD-170017
  // Not a hook.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useStaticRendering(true); // TODO: remove mobx :)

  // TODO: Move this to @faire/web. https://fairewholesale.atlassian.net/browse/FD-170017
  setGlobalLookup((prop) => {
    const rls = maybeUse(SSRRequestLocalStorage);

    if (!rls) {
      if (prop?.startsWith("faire")) {
        // HACK: allow for faire globals to be accessed outside of React.
        // Currently necessary for app initialization.
        //
        // We use cloneDeep to prevent the global from being mutated.
        //
        // https://fairewholesale.atlassian.net/browse/FD-170002
        return cloneDeep(get(global, prop));
      }
      if (
        prop === "window" || // https://fairewholesale.atlassian.net/browse/FD-170006
        prop === "isSSR" || // https://fairewholesale.atlassian.net/browse/FD-170007
        prop === "estimatedViewportType" || // https://fairewholesale.atlassian.net/browse/FD-170008
        prop === "isMainThread" // https://fairewholesale.atlassian.net/browse/FD-170010
      ) {
        // HACK: compatibility with legacy SSR.
        return undefined;
      }
      if (prop === "localeKey") {
        // HACK: compatibility with legacy SSR.
        // Note that this will break i18n whenever used.
        // https://fairewholesale.atlassian.net/browse/FD-170013
        return { language: "ENG" };
      }

      const stack = new Error().stack;
      const stackLines = stack?.split("\n");
      const getGlobalPropertyLineIdx = stackLines?.findIndex((item) =>
        item?.includes("getGlobalProperty")
      );

      // This merrits a clear error message because otherwise it's
      // super confusing to debug.
      // eslint-disable-next-line no-console
      console.error(
        `Invalid request for the global variable "${prop}" outside of a React render function ${
          getGlobalPropertyLineIdx == null
            ? "somewhere (try debugging locally or using source maps)."
            : // the line starts with "at"
              stackLines?.[getGlobalPropertyLineIdx + 1]?.trim()
        }\nThe full stack trace follows.\n\n${stack}`
      );
      throw new Error("Cannot get request-local storage outside of React.");
    }

    return get(rls.global, prop);
  });
} else {
  // jest has a valid window object, so the top level check would pass in jest,
  // but we don't want to run this code in jest. Running this in jest would
  // fail noTopLevelRequestData test.
  // eslint-disable-next-line @faire/ssr-no-restricted-globals
  if (window?.envName !== "test") {
    trackMainJsExecuted();
  }
}
