import { ENABLE_PETE_OPEN_TELEMETRY_TRACES_240218 } from "@faire/web-api--source/indigofair/settings/ENABLE_PETE_OPEN_TELEMETRY_TRACES_240218";
import { OPEN_TELEMETRY_COLLECTOR_URL } from "@faire/web-api--source/indigofair/settings/OPEN_TELEMETRY_COLLECTOR_URL";

import {
  getSettingValueAndAssign,
  getSettingValueWithoutAssignment,
} from "./__internal__/getSetting";

export const getSettingOpenTelemetryCollectorUrl = (): string =>
  getSettingValueWithoutAssignment(
    OPEN_TELEMETRY_COLLECTOR_URL,
    "https://otlp-http.otel-collector-public.api.faire-stage.com"
  );

export const getSettingEnablePeteOpenTelemetryTraces = (): boolean =>
  getSettingValueAndAssign(ENABLE_PETE_OPEN_TELEMETRY_TRACES_240218, false);
