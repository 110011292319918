import { getWindow } from "@faire/web--source/common/globals/getWindow";

import { getGlobalProperty } from "./getGlobalProperty";

export const getNavigator = (): Navigator | undefined =>
  getGlobalProperty<Navigator>("navigator") ?? getWindow()?.["navigator"];

export const getNavigatorOrThrow = (): Navigator => {
  const navigator = getNavigator();
  if (!navigator) {
    throw new Error("navigator not found");
  }
  return navigator;
};
