import { Storage } from "@faire/web--source/common/Storage";
import { isNotUndefined } from "@faire/web--source/common/typescriptUtils";
import { QueryParameters as GetCartQueryParams } from "@faire/web-api--source/api/v2/carts/cartToken/get";
import fetchCart from "@faire/web-api--source/api/v2/carts/cartToken/get";
import {
  useInvalidateQuery as useInvalidateCartQuery,
  useInvalidateInfiniteQuery as useInvalidateInviniteCartQuery,
  getInfiniteQueryKey as getCartInfiniteQueryKey,
} from "@faire/web-api--source/api/v2/carts/cartToken/get-hooks";
import { BrandCartsSortBy } from "@faire/web-api--source/faire/carts/BrandCartsSortBy";
import { ICartV2 } from "@faire/web-api--source/faire/carts/ICartV2";
import { route as cartRoute } from "@faire/web-api--source/routes/cart";
import {
  keepPreviousData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import flatMap from "lodash/flatMap";
import { useCallback, useMemo } from "react";

import { useInvalidateServerCart } from "@faire/retailer/app/(app)/retailer/[locale]/(pagesWithModal)/(defaultHeader)/cart/ServerCart/useInvalidateServerCart";
import { useZustandLocation } from "@faire/retailer/app/_lib/routing/useZustandLocation";
import { getCartToken } from "@faire/retailer/serialized-data/getCartToken";
import { useSettingCartRSC } from "@faire/retailer/settings/useSettingCartRSC";

import {
  ACTIVE_CART_PAGE_SEARCH_QUERIES_STORAGE_KEY,
  SAVED_CART_PAGE_SEARCH_QUERIES_STORAGE_KEY,
} from "./consts";
import { useCartPageSearchQuery } from "./useCartPageSearchQuery";
import { useFilterKeys } from "./useFilterKeys";

const VISIBLE_WINDOW_SIZE = 8;
export const SUBSEQUENT_PAGE_SIZE = 20;
export const INITIAL_PAGE_SIZE = SUBSEQUENT_PAGE_SIZE + VISIBLE_WINDOW_SIZE;

type BuildQueryParamsArg = {
  savedForLater: boolean;
  cursor?: string;
  query?: string;
};

export const useCartQueryParams = () => {
  const filterKeys = useFilterKeys();
  return useCallback(
    ({ savedForLater, cursor, query }: BuildQueryParamsArg) => {
      const max_results = cursor ? SUBSEQUENT_PAGE_SIZE : INITIAL_PAGE_SIZE;
      return GetCartQueryParams.build({
        filter_brand_carts_by_keys: filterKeys ?? [],
        saved_for_later: savedForLater,
        brand_tokens: [],
        exclude_alcohol_disclaimer: false,
        sort_brand_carts_by: BrandCartsSortBy.UPDATED_AT,
        cursor,
        query,
        max_results,
      });
    },
    [filterKeys]
  );
};

const useCart = (savedForLater: boolean) => {
  const buildQueryParams = useCartQueryParams();
  const query = useCartPageSearchQuery();

  const paginatedQueryResult = useInfiniteQuery({
    queryKey: getCartInfiniteQueryKey(
      getCartToken(),
      buildQueryParams({ savedForLater, query })
    ),
    queryFn: ({ pageParam }: { pageParam: string | undefined }) => {
      const params = buildQueryParams({
        savedForLater,
        cursor: pageParam,
        query,
      });
      return fetchCart(getCartToken(), params);
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage.cursor,
    refetchOnMount: false,
    placeholderData: keepPreviousData,
  });

  const data = useMemo(() => {
    const pages = paginatedQueryResult.data?.pages;
    return ICartV2.build({
      brand_carts: flatMap(pages, (page) => page.brand_carts).filter(
        isNotUndefined
      ),
      filter_sections: flatMap(pages, (page) => page.filter_sections).filter(
        isNotUndefined
      ),
      cart_stats: pages?.[0]?.cart_stats,
      eori_checkout_content: pages?.[0]?.eori_checkout_content,
      cursor: pages?.[pages.length - 1]?.cursor,
    });
  }, [paginatedQueryResult.data?.pages]);

  return { ...paginatedQueryResult, data };
};

export const useActiveCart = () => useCart(false);
export const useSavedCart = () => useCart(true);

type InvalidateCartArg = {
  savedForLater: boolean;
  query: string | undefined;
};

export const useInvalidateCart = () => {
  const location = useZustandLocation((state) => state.pathname);
  const buildQueryParams = useCartQueryParams();
  const invalidateCartQuery = useInvalidateCartQuery();
  const invalidateInfiniteCartQuery = useInvalidateInviniteCartQuery();
  const searchQuery = useCartPageSearchQuery();
  const isCartRSC = useSettingCartRSC();

  const query = useMemo(() => {
    return location.pathname === cartRoute ? searchQuery : undefined;
  }, [location.pathname, searchQuery]);

  const invalidateCartRequest = useCallback(
    async ({ savedForLater, query }: InvalidateCartArg) => {
      const args = [
        getCartToken(),
        buildQueryParams({ savedForLater, query }),
        undefined,
        { refetchType: "all" },
      ] as const;
      await Promise.all([
        invalidateInfiniteCartQuery(...args),
        invalidateCartQuery(...args),
      ]);
    },
    [buildQueryParams, invalidateCartQuery, invalidateInfiniteCartQuery]
  );

  const { invalidateActiveServerCart, invalidateSavedServerCart } =
    useInvalidateServerCart();

  const invalidateCart = useCallback(
    async (savedForLater: boolean) => {
      const promises: Promise<void>[] = [];
      if (isCartRSC) {
        if (savedForLater) {
          promises.push(invalidateSavedServerCart());
        } else {
          promises.push(invalidateActiveServerCart());
        }
      }
      const queries = getQueriesFromStorage(savedForLater);
      if (queries.length > 0) {
        queries.forEach((q) => {
          promises.push(invalidateCartRequest({ savedForLater, query: q }));
        });
        const key = savedForLater
          ? SAVED_CART_PAGE_SEARCH_QUERIES_STORAGE_KEY
          : ACTIVE_CART_PAGE_SEARCH_QUERIES_STORAGE_KEY;
        // All search queries have been dealt with
        Storage.session.removeItem(key);
      } else {
        promises.push(invalidateCartRequest({ savedForLater, query }));
      }
      await Promise.all(promises);
    },
    [
      invalidateActiveServerCart,
      invalidateCartRequest,
      invalidateSavedServerCart,
      isCartRSC,
      query,
    ]
  );

  const invalidateActiveCart = useCallback(async () => {
    await invalidateCart(false);
  }, [invalidateCart]);

  const invalidateSavedCart = useCallback(async () => {
    await invalidateCart(true);
  }, [invalidateCart]);

  const invalidateEntireCart = useCallback(
    () => Promise.all([invalidateActiveCart(), invalidateSavedCart()]),
    [invalidateActiveCart, invalidateSavedCart]
  );

  return {
    invalidateActiveCart,
    invalidateSavedCart,
    invalidateEntireCart,
  };
};

const getQueriesFromStorage = (savedForLater: boolean): string[] => {
  const key = savedForLater
    ? SAVED_CART_PAGE_SEARCH_QUERIES_STORAGE_KEY
    : ACTIVE_CART_PAGE_SEARCH_QUERIES_STORAGE_KEY;
  const queries = Storage.session.getItem(key);
  return queries ? JSON.parse(queries) : [];
};

export const usePrefetchCart = () => {
  const queryClient = useQueryClient();
  const buildQueryParams = useCartQueryParams();

  const prefetchCart = useCallback(
    (savedForLater: boolean) => {
      const cartToken = getCartToken();
      const queryParams = buildQueryParams({ savedForLater });
      const queryFn = () => fetchCart(cartToken, queryParams);

      /**
       * We don't have a `usePrefetchInfiniteQuery` hook yet, so we need to
       * use the `prefetchQuery` method directly from the queryClient.
       */
      return queryClient.prefetchInfiniteQuery({
        queryKey: getCartInfiniteQueryKey(cartToken, queryParams),
        queryFn,
        initialPageParam: undefined,
      });
    },
    [buildQueryParams, queryClient]
  );

  const prefetchActiveCart = useCallback(
    () => prefetchCart(false),
    [prefetchCart]
  );
  const prefetchSavedCart = useCallback(
    () => prefetchCart(true),
    [prefetchCart]
  );

  return { prefetchActiveCart, prefetchSavedCart };
};
