import { IRequestPaginationData } from '../../../indigofair/data/IRequestPaginationData';

export namespace IGetBuyItAgainProductsRequest {
  /**
   * Return an instance of IGetBuyItAgainProductsRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IGetBuyItAgainProductsRequest>
  ): IGetBuyItAgainProductsRequest =>
    ({
      filter_keys: [],
      highlighted_product_tokens: [],
      ...(partial ?? {}),
    }) as IGetBuyItAgainProductsRequest;
}

export interface IGetBuyItAgainProductsRequest {
  brand_token: string | undefined;
  query: string | undefined;
  /**
   * Deprecated, use paginationData instead.
   * @deprecated
   */
  page_number: number | undefined;
  /**
   * Deprecated, use paginationData instead.
   * @deprecated
   */
  page_size: number | undefined;
  filter_keys: Array<string>;
  /**
   * If true, the request is from brand page buy it again carousel
   */
  from_buy_it_again_carousel: boolean | undefined;
  pagination_data: IRequestPaginationData | undefined;
  highlighted_product_tokens: Array<string>;
}
