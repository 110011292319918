import { THREE_LETTER_PRIMARY_COUNTRY_CODES } from "@faire/web--source/common/consts/THREE_LETTER_PRIMARY_COUNTRY_CODES";
import { CurrencyUnit } from "@faire/web-api--source/faire/currency/CurrencyUnit";
import { IFaireMoney } from "@faire/web-api--source/indigofair/data/IFaireMoney";
import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";
import { useCallback } from "react";

import {
  CurrencyStore,
  IFormatCentsConfiguration,
  useCurrencyStore,
} from "./currency/CurrencyStore";
import { localNum, useLocalNum } from "./localization";

export interface IFormatMoneyProps {
  money: IFaireMoney;
  /**
   * Rounding style for the dollar amount.
   * Defaults to no rounding, i.e. `RoundingStrategy.NONE`
   */
  roundingStrategy?: RoundingStrategy;

  /**
   * If true, displays only a whole-dollar amount if the cents
   * are exactly zero. If you wish to round all numbers, use the
   * `roundingStrategy` attribute instead.
   */
  omitCentsIfZero?: boolean;

  /**
   * If true will not display the $ sign in front of the string
   */
  omitCurrency?: boolean;

  /**
   * If true will not automatically try to
   * convert the currency to the preferred currency
   */
  disableAutoConvertCurrency?: boolean;

  /**
   * If true will shorten currency i.e. 125k vs 125,000
   */
  compact?: boolean;
}

/**
 * Rounding strategy to use when intending to display whole numbers
 * of dollars only.
 *
 * If you only want to omit the cents when they're zero, use the
 * `omitCentsIfZero` attribute instead.
 */
export enum RoundingStrategy {
  /**
   * Amounts are not rounded.
   * This is the default behaviour.
   */
  NONE,
  /**
   * Rounds to the nearest dollar.
   */
  ROUND,
  /**
   * Drops the cents from the amount, rounding down (flooring) to
   * the nearest dollar.
   */
  DROP_CENTS,
  /**
   * Drops the cents from the amount, rounding up to the nearest
   * whole dollar.
   */
  DROP_CENTS_ROUND_UP,
  /**
   * Rounds down to the nearest hundredth
   */
  ROUND_HUNDREDS_DOWN,
}

const ROUND_CASES = new Set([
  RoundingStrategy.ROUND,
  RoundingStrategy.DROP_CENTS,
  RoundingStrategy.DROP_CENTS_ROUND_UP,
  RoundingStrategy.ROUND_HUNDREDS_DOWN,
]);

/**
 * gets the converted cents from Faire Money object
 * @param money is the IFaireMoney object that needs to have their cents converted
 * @deprecated use `useGetLocalCurrency` instead.
 */
const getLocalCurrency = (
  money: IFaireMoney,
  nativeConfiguration?: IFormatCentsConfiguration
): IFaireMoney => {
  const configuration =
    nativeConfiguration ?? CurrencyStore.get().configuration;
  if (money.currency === configuration.selectedCurrency) {
    return money;
  }
  const rawCents = money.amount_cents ?? 0;
  const rawRate = configuration.currencyConversionRate?.rate ?? 1;
  let convertedCents = rawCents;

  // Only convert if currency and conversion rate both exist
  if (
    money.currency === configuration.currencyConversionRate?.from_currency &&
    configuration.selectedCurrency ===
      configuration.currencyConversionRate?.to_currency
  ) {
    convertedCents = rawRate * rawCents;
  } else if (
    money.currency === configuration.currencyConversionRate?.to_currency &&
    configuration.selectedCurrency ===
      configuration.currencyConversionRate?.from_currency
  ) {
    convertedCents = (1 / rawRate) * rawCents;
  } else {
    return money;
  }
  return IFaireMoney.build({
    amount_cents: convertedCents,
    currency: configuration.selectedCurrency,
  });
};

const useGetLocalCurrency = () => {
  const { configuration } = useCurrencyStore(["configuration"]);
  const getLocalCurrencyHook: typeof getLocalCurrency = useCallback(
    (money) => getLocalCurrency(money, configuration),
    [configuration]
  );
  return { getLocalCurrency: getLocalCurrencyHook };
};

export const getDollars = (
  cents: number,
  roundingStrategy: RoundingStrategy = RoundingStrategy.NONE
) => {
  const hadCents = cents % 100 !== 0;
  const dollars = cents / 100.0;
  if (typeof roundingStrategy === "string") {
    // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
    roundingStrategy = RoundingStrategy[roundingStrategy as string];
  }
  switch (roundingStrategy) {
    case RoundingStrategy.DROP_CENTS:
      return Math.floor(dollars);
    case RoundingStrategy.DROP_CENTS_ROUND_UP:
      if (!hadCents) {
        return dollars;
      }
      return Math.floor(dollars) + 1;
    case RoundingStrategy.ROUND:
      return Math.round(dollars);
    case RoundingStrategy.ROUND_HUNDREDS_DOWN:
      return Math.floor(dollars / 100) * 100;
    case RoundingStrategy.NONE:
    default:
      return dollars;
  }
};

// Temporary solution, these are currencies which do not have a subunit (or cents equivalent)
const NO_MINOR_UNIT_CURRENCIES = new Set(["JPY"]);

/**
 * @deprecated use `useFormatMoney` instead.
 */
export const formatMoney = (
  {
    money,
    roundingStrategy = RoundingStrategy.NONE,
    omitCentsIfZero = false,
    omitCurrency = false,
    disableAutoConvertCurrency = false,
    compact = false,
  }: IFormatMoneyProps,
  nativeLocalNum?: typeof localNum,
  nativeGetLocalCurrency?: typeof getLocalCurrency
) => {
  const maybeNativeGetLocalCurrency =
    nativeGetLocalCurrency ?? getLocalCurrency;
  const workingMoney = disableAutoConvertCurrency
    ? money
    : maybeNativeGetLocalCurrency(money);

  // Intl formatting does not work without a currency, any value being formatted without a currency
  // is therefore assumed to be USD. We should seek out these places and fix them to remove this assumption.
  if (!workingMoney.currency) {
    workingMoney.currency = "USD";
  }

  const cents = workingMoney.amount_cents ?? 0;
  const originalHasCents = cents % 100 !== 0;
  const noMinorUnits = NO_MINOR_UNIT_CURRENCIES.has(workingMoney.currency);

  const dollars = noMinorUnits ? cents : getDollars(cents, roundingStrategy);
  const compactNoDecimals = compact && dollars % 1000 === 0;

  const decimalPlaces =
    noMinorUnits ||
    compactNoDecimals ||
    ROUND_CASES.has(roundingStrategy) ||
    (!originalHasCents && omitCentsIfZero)
      ? 0
      : 2;

  const maybeNativeLocalNum = nativeLocalNum ?? localNum;

  return maybeNativeLocalNum(dollars, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    currency: workingMoney.currency,
    style: omitCurrency ? undefined : "currency",
    notation: compact ? "compact" : undefined,
    compactDisplay: compact ? "short" : undefined,
  })!;
};

export const useFormatMoney = () => {
  const { localNum } = useLocalNum();
  const { getLocalCurrency } = useGetLocalCurrency();
  const formatMoneyHook = useCallback(
    (props: IFormatMoneyProps) =>
      formatMoney(props, localNum, getLocalCurrency),
    [localNum, getLocalCurrency]
  );
  return { formatMoney: formatMoneyHook };
};

/**
 * Accepts a nullable IFaireMoney and returns that object if not null, an IFaireMoney
 * with amount_cents = 0 otherwise. Helper for calling <Money> or formatMoney() with
 * a nullable IFaireMoney.
 *
 * @param money – the nullable IFaireMoney object
 * @param currency – the currency to be used on the fallback IFaireMoney
 */
export const moneyOrZero = (
  money: IFaireMoney | undefined,
  currency?: IFaireMoney["currency"]
): IFaireMoney => money ?? IFaireMoney.build({ amount_cents: 0, currency });

export const parseCents = (str: string): number => {
  const cleanedString = str.replace(/[^0-9.-]/g, "");
  if (cleanedString.length === 0) {
    return 0;
  }
  const parsedNumber = parseFloat(cleanedString);
  return isNaN(parsedNumber) ? 0 : Math.round(parsedNumber * 100);
};

/**
 * gets the currency symbol from Faire Money object
 * @param money is the IFaireMoney object that needs to have their currency converted
 * @deprecated use `useGetCurrencySymbol` instead.
 */
export const getCurrencySymbol = (
  money: IFaireMoney,
  nativeGetCurrencySymbolFromCurrencyUnit?: typeof getCurrencySymbolFromCurrencyUnit
) => {
  if (!money.currency) {
    return "";
  }
  const maybeNativeGetCurrencySymbolFromCurrencyUnit =
    nativeGetCurrencySymbolFromCurrencyUnit ??
    getCurrencySymbolFromCurrencyUnit;

  return maybeNativeGetCurrencySymbolFromCurrencyUnit(money.currency);
};

export const useGetCurrencySymbol = () => {
  const { getCurrencySymbolFromCurrencyUnit } =
    useGetCurrencySymbolFromCurrencyUnit();
  const getCurrencySymbolHook = useCallback(
    (money: IFaireMoney) =>
      getCurrencySymbol(money, getCurrencySymbolFromCurrencyUnit),
    [getCurrencySymbolFromCurrencyUnit]
  );
  return { getCurrencySymbol: getCurrencySymbolHook };
};

/**
 * converts a currency unit (such as USD) to a currency symbol ($)
 * @param currencyUnit is the currency unit string to convert
 * @deprecated use `useGetCurrencySymbolFromCurrencyUnit` instead
 */
export const getCurrencySymbolFromCurrencyUnit = (
  currencyUnit: string,
  nativeLocalNum?: typeof localNum
) => {
  const maybeNativeLocalNum = nativeLocalNum ?? localNum;
  const localizedMoney = maybeNativeLocalNum(0, {
    currency: currencyUnit,
    style: "currency",
  });

  if (localizedMoney === undefined) {
    return "";
  }

  // eslint-disable-next-line no-irregular-whitespace
  return localizedMoney.replace(/[\d.,   ]/g, "");
};

export const useGetCurrencySymbolFromCurrencyUnit = () => {
  const { localNum } = useLocalNum();
  const getCurrencySymbolFromCurrencyUnitHook = useCallback(
    (currencyUnit: string) =>
      getCurrencySymbolFromCurrencyUnit(currencyUnit, localNum),
    [localNum]
  );
  return {
    getCurrencySymbolFromCurrencyUnit: getCurrencySymbolFromCurrencyUnitHook,
  };
};

/**
 * gets the amount cents from Faire Money object after converting to local currency
 * the only use case for this is to be used in sumAfterConversion function in MoneyMath.ts
 * might not need to import this function in other places
 * @param money is the IFaireMoney object that needs to be converted and have their amount_cents returned
 * @deprecated use `useGetCentsInLocalCurrency` instead.
 */
export const getCentsInLocalCurrency = (
  money: IFaireMoney,
  nativeGetLocalCurrency?: typeof getLocalCurrency
) => {
  const maybeNativeGetLocalCurrency =
    nativeGetLocalCurrency ?? getLocalCurrency;
  const workingMoney = maybeNativeGetLocalCurrency(money);

  if (!workingMoney.currency) {
    workingMoney.currency = "USD";
  }

  return workingMoney.amount_cents ?? 0;
};

export const useGetCentsInLocalCurrency = () => {
  const { getLocalCurrency } = useGetLocalCurrency();
  const getCentsInLocalCurrencyHook = useCallback(
    (money: IFaireMoney) => getCentsInLocalCurrency(money, getLocalCurrency),
    [getLocalCurrency]
  );
  return { getCentsInLocalCurrency: getCentsInLocalCurrencyHook };
};

/**
 * This function will consume a country and provide its currency.
 * If the country is not a primary country, it will use USD by default.
 *
 * You can use the `getSessionCountry` function to get the locale country:
 *
 *     const currency = selectLocalCurrency(getSessionCountry());
 */
export const selectLocalCurrency = (
  country: keyof typeof ISO3166Alpha3
): CurrencyUnit => {
  //TODO(juliette.li@faire.com): Add Denmark currency DKK
  switch (country) {
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.FRA:
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.DEU:
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.NLD:
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.BEL:
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.AUT:
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.LUX:
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.FIN:
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.PRT:
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.IRL:
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.ESP:
      return CurrencyUnit.EUR;
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.USA:
      return CurrencyUnit.USD;
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.CAN:
      return CurrencyUnit.CAD;
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.GBR:
      return CurrencyUnit.GBP;
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.NOR:
      return CurrencyUnit.NOK;
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.CHE:
      return CurrencyUnit.CHF;
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.SWE:
      return CurrencyUnit.SEK;
    case THREE_LETTER_PRIMARY_COUNTRY_CODES.AUS:
      return CurrencyUnit.AUD;
    default:
      // default to USD as the default currency
      return CurrencyUnit.USD;
  }
};
