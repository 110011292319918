"use client";

import { UnobservedIntlProvider } from "../../common/localization/IntlProviderBase";

/**
 * We re-export in order to prevent having to install react-intl in consuming
 * repositories. react-intl works using the context api, therefore having
 * multiple copies of it at once is problematic.
 */

export const IntlProvider = UnobservedIntlProvider;
