/**
 * TODO(shawn.morel) refactor redacted field logic from backend to be used in new services
 */
export enum RetailerLinkState {
  RETAILER_LINK_STATE_UNKNOWN = 'RETAILER_LINK_STATE_UNKNOWN',
  RETAILER_LINK_UNLINKED = 'RETAILER_LINK_UNLINKED',
  RETAILER_LINK_LINKING = 'RETAILER_LINK_LINKING',
  RETAILER_LINK_LINKED = 'RETAILER_LINK_LINKED',
  RETAILER_LINK_DRAFT = 'RETAILER_LINK_DRAFT',
}
