import { isLocal } from "./isLocal";
import { isProduction } from "./isProduction";
import { isStaging } from "./isStaging";

export type EnvValues<T> = {
  defaultValue: T;
  production?: T;
  staging?: T;
  local?: T;
};

/**
 * Helper function for performing a succinct switch statement for the current env.
 *
 * @example
 * ```
 * const nameWithEnv = getForEnv({
 *   defaultValue: "Faire",
 *   local: "Faire (Localhost)",
 *   staging: "Faire (Staging)",
 *   production: "Faire (Production)"
 * });
 * ```
 */
export const getForEnv = <T>({
  defaultValue,
  production,
  staging,
  local,
}: EnvValues<T>): T => {
  if (production && isProduction()) {
    return production;
  }
  if (staging && isStaging()) {
    return staging;
  }
  if (local && isLocal()) {
    return local;
  }
  return defaultValue;
};
