import { StateOrProvince } from '../../indigofair/data/StateOrProvince';
import { ISO3166Alpha3 } from '../../indigofair/iso3166/ISO3166Alpha3';

export namespace IAddress {
  export enum Source {
    ADMIN = 'ADMIN',
    USER = 'USER',
    GOOGLE_PLACES = 'GOOGLE_PLACES',
    YELP = 'YELP',
    FOURQUARE = 'FOURQUARE',
    SHOPIFY_STORE_LOCATOR = 'SHOPIFY_STORE_LOCATOR',
  }

  export enum UspsType {
    RESIDENTIAL = 'RESIDENTIAL',
    COMMERCIAL = 'COMMERCIAL',
    MIXED = 'MIXED',
  }

  /**
   * Redacted copy of IAddress (i.e. without redacted fields).
   */
  export type Redacted = Omit<IAddress, 'active' | 'source'>;

  /**
   * Return an instance of IAddress with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IAddress>): IAddress =>
    ({
      ...(partial ?? {}),
    }) as IAddress;
}

export interface IAddress {
  token: string | undefined;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  active: boolean | undefined; // redacted
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  source: keyof typeof IAddress.Source | undefined; // redacted
  name: string | undefined;
  address1: string | undefined;
  address2: string | undefined;
  postal_code: string | undefined;
  city: string | undefined;
  /**
   * Country Alpha-3 code (USA, CAN, etc.).
   */
  country: string | undefined;
  /**
   * State code (CA, NY, WY, etc.). The server will attempt to parse the long state name as well.
   */
  state: string | undefined;
  state_or_province: keyof typeof StateOrProvince | undefined;
  instructions: string | undefined;
  /**
   * The phone number where the recipient of the shipment should be reached at.
   */
  phone_number: string | undefined;
  /**
   * Whether this is a residential address, not always set.
   */
  usps_type: keyof typeof IAddress.UspsType | undefined;
  /**
   * Not always set.
   */
  latitude: number | undefined;
  longitude: number | undefined;
  /**
   * Address time zone ID
   */
  time_zone: string | undefined;
  created_at: number | undefined;
  easy_post_address_id: string | undefined;
  full_formatted_address: string | undefined;
  short_formatted_address: string | undefined;
  /**
   * Address with address1, address2, city, state, and postal code formatted in a single line
   */
  single_line_formatted_address: string | undefined;
  /**
   * use RegionInformation
   * @deprecated
   */
  country_calling_code: string | undefined;
  iso3_country_code: keyof typeof ISO3166Alpha3 | undefined;
  h3_index_cell: string | undefined;
}
