import { DEFAULT_LOCALE_KEY } from "@faire/web--source/common/consts/DEFAULT_LOCALE";
import { THREE_TO_FULL_LANGUAGE_NAME } from "@faire/web--source/common/consts/THREE_TO_FULL_LANGUAGE_NAME";
import { THREE_TO_TWO_LETTER_LANGUAGE_CODE } from "@faire/web--source/common/consts/THREE_TO_TWO_LETTER_LANGUAGE_CODE";
import { TWO_TO_THREE_LETTER_COUNTRY_ISO_CODE } from "@faire/web--source/common/consts/TWO_TO_THREE_LETTER_COUNTRY_ISO_CODE";
import { getNavigator } from "@faire/web--source/common/globals/getNavigator";
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { isLanguageDuplicatedInLocales } from "@faire/web--source/common/localization/languageOptions";
import { localeKeyToFullLanguageName } from "@faire/web--source/common/localization/localeKeyToFullLanguageName";
import { localeKeyToLocaleString } from "@faire/web--source/common/localization/localeKeyToLocaleString";
import { logError } from "@faire/web--source/common/logging";
import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";
import find from "lodash/find";
import invert from "lodash/invert";

import { getSettingRetailerAvailableLocales } from "@faire/retailer/settings/getSettingRetailerAvailableLocales";

const getLocaleKeyLabel = (localeKey: ILocaleKey): string => {
  if (localeKey.language === undefined) {
    logError(new Error("Cannot get label of undefined language"));
    return "";
  }

  return isLanguageDuplicatedInLocales(
    Language[localeKey.language],
    getSettingRetailerAvailableLocales()
  )
    ? localeKeyToFullLanguageName(localeKey)
    : // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      THREE_TO_FULL_LANGUAGE_NAME[localeKey.language];
};

export const communicationLocaleKeysOptions = (): {
  label: string;
  value: string;
  disabled?: boolean;
}[] =>
  getSettingRetailerAvailableLocales().map((localeKey: ILocaleKey) => ({
    label: getLocaleKeyLabel(localeKey),
    value: localeKeyToLocaleString({ localeKey, dashFormat: false }),
  }));

const TWO_TO_THREE_LETTER_LANGUAGE_CODE = invert(
  THREE_TO_TWO_LETTER_LANGUAGE_CODE
);

export const getBrowserDefaultLanguage = (): keyof typeof Language => {
  let defaultLanguage = getWindow()?.navigator?.language?.toUpperCase() ?? "";
  if (defaultLanguage.includes("-")) {
    defaultLanguage = defaultLanguage.split("-")[0] ?? "";
  }
  return TWO_TO_THREE_LETTER_LANGUAGE_CODE[
    defaultLanguage
  ] as keyof typeof Language;
};

/**
 * Attempts to parse the string to ILocaleKey. Assumes the string is in standard
 * locale format, e.g. "fr", "fr-FR", or "fr_FR".
 * @param locale the locale string in standard locale format
 * @returns the locale key or DEFAULT_LOCALE_KEY if parsing was not successful
 * @deprecated use localeStringToLocaleKey in @faire/web/common instead
 */
export const getLocaleKeyFromLocaleString = (locale: string): ILocaleKey => {
  let defaultLanguage = locale.toUpperCase();
  let defaultCountry, splittedDefaultLanguage, splittedDefaultCountry;

  if (defaultLanguage.includes("-")) {
    [splittedDefaultLanguage, splittedDefaultCountry] =
      defaultLanguage.split("-");
  } else if (defaultLanguage.includes("_")) {
    [splittedDefaultLanguage, splittedDefaultCountry] =
      defaultLanguage.split("_");
  }

  if (splittedDefaultCountry) {
    defaultCountry = splittedDefaultCountry;
  }
  if (splittedDefaultLanguage) {
    defaultLanguage = splittedDefaultLanguage;
  }

  const threeLetterLanguage =
    TWO_TO_THREE_LETTER_LANGUAGE_CODE[defaultLanguage];
  const language = threeLetterLanguage
    ? Language[threeLetterLanguage as keyof typeof Language]
    : Language.ENG;
  const country = defaultCountry
    ? ISO3166Alpha3[
        TWO_TO_THREE_LETTER_COUNTRY_ISO_CODE[
          defaultCountry as keyof typeof TWO_TO_THREE_LETTER_COUNTRY_ISO_CODE
        ]
      ]
    : undefined;

  for (const localeKey of getSettingRetailerAvailableLocales()) {
    if (localeKey.language === language && localeKey.country === country) {
      // the locale matches a supported locale
      return { ...localeKey };
    }
  }

  if (language in Language) {
    return {
      language: language,
      country: undefined,
    };
  }

  return DEFAULT_LOCALE_KEY;
};

/**
 * Returns the locale key associated with the browser's default language.
 * This assumes the browser returns its language in on of the standard format, i.e.
 * "fr", "fr-FR", or "fr_FR".
 * @returns the ILocaleKey representation of the browser locale, or DEFAULT_LOCALE_KEY
 *  if it cannot determine the correct form
 */
export const getBrowserDefaultLocaleKey = (): ILocaleKey =>
  getLocaleKeyFromLocaleString(getNavigator()?.language ?? "");

/**
 * If the locale key passed in does not exist in RETAILER_AVAILABLE_LOCALES setting,
 * this returns the first supported locale key with matching language, otherwise it
 * returns the matching available locale key.
 *
 * e.g. localeKey={ language: "ITA" } -> returns { language: "ITA", country: "ITA" }
 *
 * @param localeKey the locale key to infer country for
 * @returns locale key with matching language from retailer available locales, else DEFAULT_LOCALE_KEY
 */
export const getLocaleKeyWithInferredCountry = (
  localeKey: ILocaleKey
): ILocaleKey => {
  const matchingLocaleInAvailableLocales = find(
    getSettingRetailerAvailableLocales(),
    ILocaleKey.build({
      language: localeKey.language,
      country: localeKey.country,
    })
  );

  if (matchingLocaleInAvailableLocales) {
    return matchingLocaleInAvailableLocales;
  }

  for (const availableLocale of getSettingRetailerAvailableLocales()) {
    if (availableLocale.language === localeKey.language) {
      return availableLocale;
    }
  }

  return DEFAULT_LOCALE_KEY;
};
