import { debounceIfPossible } from "@faire/web--source/common/globals/debounceIfPossible";
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { useIsSSR } from "@faire/web--source/common/server-side-rendering/isSSR";
import { getEstimatedViewportType } from "@faire/web--source/common/user-agent/getEstimatedViewportType";
import { createContextStore } from "@faire/web--source/ui/hooks/ContextStore";
import { useState } from "@faire/web--source/ui/hooks/useState";
import React, { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useSyncExternalStore } from "use-sync-external-store/shim";

import { isTouchDevice as checkIsTouchDevice } from "../../common/isTouchDevice";

export const getWindowWidth = () => getWindow()?.innerWidth ?? 0;

export const getWindowHeight = () => getWindow()?.innerHeight ?? 0;

const { Provider, useStore } = createContextStore<{
  width: number;
  height: number;
  isTouchDevice: boolean;
  isWindowFocused: boolean;
  isOnline: boolean;
}>(
  {
    width: 0,
    height: 0,
    isTouchDevice: false,
    isWindowFocused: false,
    isOnline: false,
  },
  { name: "ObservableWindowStore" }
);

/**
 * This hook should be instantiated once at the root of the application.
 * Instantiating multiple times may cause performance issues.
 */
export const ObservableWindowStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // Why are we using `useState` here? See: https://fairewholesale.atlassian.net/browse/FD-128466
  const [isSSR] = useState<boolean>(useIsSSR());

  return (
    <ProviderWithInitialState isSSR={isSSR}>
      {children}
    </ProviderWithInitialState>
  );
};

const ProviderWithInitialState = React.memo(
  ({ children, isSSR }: { children: React.ReactNode; isSSR: boolean }) => {
    const [width, setWidth] = useState<number>(
      getEstimatedViewportType().width
    );
    const [height, setHeight] = useState<number>(
      getEstimatedViewportType().height
    );

    // accessing getWindowWidth() causes animation jank
    // so we only read that in LayoutEffect
    useLayoutEffect(() => {
      if (isSSR) {
        const { width, height } = getEstimatedViewportType();
        setWidth(width);
        setHeight(height);
      } else {
        setWidth(getWindowWidth());
        setHeight(getWindowHeight());
      }
    }, [isSSR]);

    const isTouchDevice = isSSR ? false : checkIsTouchDevice();
    const isWindowFocused = true;
    const isOnline = true;

    return (
      <Provider
        value={{
          width,
          height,
          isTouchDevice,
          isWindowFocused,
          isOnline,
        }}
      >
        <InitializeObservableWindowStore />
        {children}
      </Provider>
    );
  }
);

/**
 * Set up event handlers to update the store.
 * This also corrects the SSR viewport mismatch as a post-hydration step.
 */
const InitializeObservableWindowStore = () => {
  const [_, setStore] = useStore([]);

  const setDimensions = useCallback(() => {
    setStore(() => ({
      width: getWindowWidth(),
      height: getWindowHeight(),
    }));
  }, [setStore]);

  const handleResize = useMemo(
    () => debounceIfPossible(setDimensions, 200),
    [setDimensions]
  );

  const handleTouch = useCallback(
    () => setStore({ isTouchDevice: true }),
    [setStore]
  );
  const handleFocus = useCallback(
    () => setStore({ isWindowFocused: true }),
    [setStore]
  );
  const handleBlur = useCallback(
    () => setStore({ isWindowFocused: false }),
    [setStore]
  );
  const handleOnline = useCallback(
    () => setStore({ isOnline: true }),
    [setStore]
  );
  const handleOffline = useCallback(
    () => setStore({ isOnline: false }),
    [setStore]
  );

  useEffect(() => {
    setStore(() => ({
      width: getWindowWidth(),
      height: getWindowHeight(),
      isTouchDevice: checkIsTouchDevice(),
    }));
  }, [setStore]);

  useEffect(() => {
    getWindow()?.addEventListener("resize", handleResize, false);
    getWindow()?.addEventListener("touchstart", handleTouch);
    getWindow()?.addEventListener("focus", handleFocus);
    getWindow()?.addEventListener("blur", handleBlur);
    getWindow()?.addEventListener("online", handleOnline);
    getWindow()?.addEventListener("offline", handleOffline);
    return () => {
      getWindow()?.removeEventListener("resize", handleResize, false);
      getWindow()?.removeEventListener("touchstart", handleTouch);
      getWindow()?.removeEventListener("focus", handleFocus, false);
      getWindow()?.removeEventListener("blur", handleBlur, false);
      getWindow()?.removeEventListener("online", handleOnline, false);
      getWindow()?.removeEventListener("offline", handleOffline, false);
    };
  }, [
    handleBlur,
    handleFocus,
    handleOffline,
    handleOnline,
    handleResize,
    handleTouch,
  ]);
  return null;
};

/**
 * Make sure you wrap the root of app with ObservableWindowStoreProvider before using this hook.
 * Otherwise, you will receive an error.
 */
export const useObservableWindow = () => {
  const [clientObservableWindow] = useStore([
    "width",
    "height",
    "isTouchDevice",
    "isWindowFocused",
    "isOnline",
  ]);

  const defaultServerObservableWindow = useMemo(getDefaultServerWindow, []);

  // client viewport correction effect can happen prior to the delayed hydration of boundary components
  // when the delayed hydration reads the store, we should return the default SSR value
  // when hydration completes, useSyncExternalStore will re-render the component with the correct value
  return useSyncExternalStore(
    () => () => {},
    () => clientObservableWindow,
    () => defaultServerObservableWindow
  );
};

const getDefaultServerWindow = () => {
  const { width, height } = getEstimatedViewportType();
  return {
    width,
    height,
    isTouchDevice: false,
    isWindowFocused: true,
    isOnline: true,
  };
};
