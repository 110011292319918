import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import qs from 'query-string';
import type { IRouteMetadata } from '../../../Route';

export type QueryParameters = {
  signUp: string | undefined;
  email: string | undefined;
  brand: string | undefined;
  brandInvoice: string | undefined;
  brandName: string | undefined;
  brandSubdomain: string | undefined;
  fdb: string | undefined;
  source: string | undefined;
  name: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  product: string | undefined;
  video: string | undefined;
  phone_number: string | undefined;
  googlePlaceId: string | undefined;
  countryIso3: string | undefined;
  storeName: string | undefined;
  creation_year: string | undefined;
  storeOpeningDate: string | undefined;
  linkShopifyStore: string | undefined;
  leaseSigned: string | undefined;
  widgetToken: string | undefined;
  autoload: string | undefined;
  fromFdMessageBrandName: string | undefined;
  ifuit: string | undefined;
  leadToken: string | undefined;
  leadSecret: string | undefined;
  ref: string | undefined;
  wsi: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;

  /**
   * Parse the given raw query string or partially-parsed react-router-dom queryParams object.
   */
  export function parse(
    search: string | { [K in keyof QueryParameters]?: string } = ''
  ): QueryParameters {
    const rawParsed =
      typeof search === 'string' ? qs.parse(search) : (search ?? {});
    const parsed: any = { ...rawParsed };

    return parsed as QueryParameters;
  }
}

/**
 * Generated from Jersey path "/welcome/r"
 *
 * Product Area: SIGNUP
 */
export const route = '/welcome/r';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/welcome/r',
  productArea: 'SIGNUP',
  pod: 'FAIRE_POD_RETAILER_ACTIVATION',
  pillar: 'FAIRE_PILLAR_RETAILER_GROWTH',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path(queryParams?: Partial<QueryParameters>) {
  const compiledPath = toPath({});
  const queryString = queryParams ? qs.stringify(queryParams) : '';
  return `${compiledPath}${queryString ? `?${queryString}` : ''}`;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (queryParams?: Partial<QueryParameters>): string => {
  return faireBaseUrl(3000) + path(queryParams);
};

export default path;
