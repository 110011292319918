"use client";

import { Core, Decorative } from "@faire/design-tokens";

import Color from "../Color";
import { eventsThemeColors, getEventTheme } from "../Theme/eventsThemeColors";

import { ButtonVariantTheme, IButtonTheme } from "./types";

const REGULAR_BUTTON_HEIGHT = 48;
const SMALL_BUTTON_HEIGHT = 40;

const calculateButtonHeight = (small?: boolean) => {
  return `${small ? SMALL_BUTTON_HEIGHT : REGULAR_BUTTON_HEIGHT}px`;
};

export const primaryDestructiveStyle: ButtonVariantTheme = {
  base: {
    color: Core.text.critical,
    "background-color": Core.surface.critical,
    border: `1px solid ${Core.surface.critical}`,
  },
  loading: {
    color: Core.icon.critical,
    "background-color": Core.surface.critical,
    border: `1px solid ${Core.surface.critical}`,
  },
  hover: {
    color: Core.text.inverse,
    "background-color": Color.siren,
    border: `1px solid ${Color.siren}`,
  },
  disabled: {
    color: Core.text.disabled,
    "background-color": Core.surface.disabled,
    border: `1px solid ${Core.border.disabled}`,
  },
  active: {
    color: Core.text.inverse,
    "background-color": Color.siren,
    border: `1px solid ${Color.siren}`,
  },
  focus: {
    color: Core.text.critical,
  },
};

export const secondaryDestructiveStyle: ButtonVariantTheme = {
  base: {
    color: Core.text.critical,
    "background-color": Core.surface.inverse,
    border: `1px solid ${Core.border.critical}`,
  },
  loading: {
    color: Core.icon.critical,
    "background-color": Core.surface.inverse,
    border: `1px solid ${Core.border.critical}`,
  },
  hover: {
    color: Core.text.critical,
    "background-color": Core.surface.critical,
    border: `1px solid ${Core.border.critical}`,
  },
  disabled: {
    color: Core.text.disabled,
    "background-color": Core.surface.inverse,
    border: `1px solid ${Core.border.disabled}`,
  },
  active: {
    color: Core.text.critical,
    "background-color": Core.surface.critical,
    border: `1px solid ${Core.border.critical}`,
  },
};

export const tertiaryDestructiveStyle: ButtonVariantTheme = {
  base: {
    color: Core.text.critical,
    "text-decoration-color": Core.text.critical,
  },
  loading: {
    color: Core.icon.critical,
  },
  hover: {
    color: Decorative.shade.red,
    "text-decoration-color": Decorative.shade.red,
  },
  disabled: {
    color: Core.text.disabled,
    "text-decoration-color": Core.text.disabled,
  },
  active: {
    color: Decorative.shade.red,
    "text-decoration-color": Decorative.shade.red,
  },
  focus: {
    color: Core.text.critical,
    "text-decoration-color": Core.text.critical,
  },
};

const primaryStyle: ButtonVariantTheme = {
  base: ({ $small, $destructive }) => ({
    height: calculateButtonHeight($small),
    padding: "0 23px", // 24px padding - 1px border
    border: `1px solid ${Core.border.default}`,
    "background-color": Core.surface.default,
    color: Core.text.inverse,
    ...($destructive ? primaryDestructiveStyle.base : {}),
  }),
  loading: ({ $destructive }) => ({
    "background-color": Core.surface.enabled,
    color: Core.text.inverse,
    "border-color": Core.surface.enabled,
    ...($destructive ? primaryDestructiveStyle.loading : {}),
  }),
  hover: ({ $destructive }) => ({
    "background-color": Core.surface.hover,
    "border-color": Core.border.hover,
    color: Core.text.inverse,
    ...($destructive ? primaryDestructiveStyle.hover : {}),
  }),
  disabled: ({ $destructive }) => ({
    "background-color": Core.surface.disabled,
    "border-color": Core.border.disabled,
    color: Core.text.disabled,
    ...($destructive ? primaryDestructiveStyle.disabled : {}),
  }),
  active: ({ $destructive }) => ({
    color: Core.text.inverse,
    ...($destructive ? primaryDestructiveStyle.active : {}),
  }),
  focus: ({ $destructive }) => ({
    color: Core.text.inverse,
    ...($destructive ? primaryDestructiveStyle.focus : {}),
  }),
};

const secondaryStyle: ButtonVariantTheme = {
  base: ({ $small, $destructive }) => ({
    height: calculateButtonHeight($small),
    padding: "0 23px", // 24px padding - 1px border
    border: `1px solid ${Core.border.default}`,
    "background-color": Core.surface.inverse,
    color: Core.text.default,
    ...($destructive ? secondaryDestructiveStyle.base : {}),
  }),
  loading: ({ $destructive }) => ({
    color: Core.text.default,
    ...($destructive ? secondaryDestructiveStyle.loading : {}),
  }),
  hover: ({ $destructive }) => ({
    "background-color": Core.surface.hover,
    color: Core.text.inverse,
    ...($destructive ? secondaryDestructiveStyle.hover : {}),
  }),
  disabled: ({ $destructive }) => ({
    color: Core.text.disabled,
    "background-color": Core.surface.inverse,
    border: `1px solid ${Core.border.subdued}`,
    ...($destructive ? secondaryDestructiveStyle.disabled : {}),
  }),
  active: ({ $destructive }) => ({
    ...($destructive ? secondaryDestructiveStyle.active : {}),
  }),
};

const tertiaryStyle: ButtonVariantTheme = {
  base: ({ $destructive }) => ({
    display: "inline-flex",
    "background-color": "transparent",
    "text-decoration": "underline",
    "text-decoration-color": Core.text.default,
    color: Core.text.default,
    ...($destructive ? tertiaryDestructiveStyle.base : {}),
  }),
  loading: ({ $destructive }) => ({
    color: Core.icon.default,
    ...($destructive ? tertiaryDestructiveStyle.loading : {}),
  }),
  hover: ({ $destructive }) => ({
    color: Core.text.hover,
    "text-decoration": "underline",
    "text-decoration-color": Core.text.hover,
    ...($destructive ? tertiaryDestructiveStyle.hover : {}),
  }),
  disabled: ({ $destructive }) => ({
    color: Core.text.disabled,
    "text-decoration-color": Core.text.disabled,
    ...($destructive ? tertiaryDestructiveStyle.disabled : {}),
  }),
  active: ({ $destructive }) => ({
    ...($destructive ? tertiaryDestructiveStyle.active : {}),
  }),
  focus: ({ $destructive }) => ({
    "text-decoration": "underline",
    "text-decoration-color": Core.text.default,
    ...($destructive ? tertiaryDestructiveStyle.focus : {}),
  }),
};

export const buttonTheme: IButtonTheme = {
  primary: primaryStyle,
  secondary: secondaryStyle,
  tertiary: tertiaryStyle,
};

const primaryStyleEventsTheme: ButtonVariantTheme = {
  ...buttonTheme.primary,
  base: ({ $small, $destructive }) => ({
    height: calculateButtonHeight($small),
    padding: "0 23px", // 24px padding - 1px border
    "background-color": getEventTheme("faireMarket").primaryDark,
    color: getEventTheme("faireMarket").promoTextInverse,
    ...($destructive ? primaryDestructiveStyle.base : {}),
  }),
};

const secondaryStyleEventsTheme: ButtonVariantTheme = {
  ...buttonTheme.secondary,
  base: ({ $small, $destructive }) => ({
    height: calculateButtonHeight($small),
    padding: "0 23px", // 24px padding - 1px border
    border: "unset",
    color: getEventTheme("faireMarket").promoText,
    "background-color": Core.surface.inverse,
    ...($destructive ? secondaryDestructiveStyle.base : {}),
  }),
};

const tertiaryStyleEventsTheme: ButtonVariantTheme = {
  base: ({ $destructive }) => ({
    display: "inline-flex",
    "background-color": "transparent",
    "text-decoration": "underline",
    "text-decoration-color": eventsThemeColors.faireMarket.primaryDark,
    color: eventsThemeColors.faireMarket.primaryDark,
    ...($destructive ? tertiaryDestructiveStyle.base : {}),
  }),
  loading: ({ $destructive }) => ({
    color: Core.text.inverse,
    ...($destructive ? tertiaryDestructiveStyle.loading : {}),
  }),
  hover: ({ $destructive }) => ({
    color: "#5262C7", // TODO: rename
    "text-decoration": "underline",
    "text-decoration-color": "#5262C7", // TODO: rename
    ...($destructive ? tertiaryDestructiveStyle.hover : {}),
  }),
  disabled: ({ $destructive }) => ({
    color: Core.text.disabled,
    "text-decoration-color": Core.surface.disabled,
    ...($destructive ? tertiaryDestructiveStyle.disabled : {}),
  }),
  active: ({ $destructive }) => ({
    color: eventsThemeColors.faireMarket.primaryDark,
    "text-decoration-color": eventsThemeColors.faireMarket.primaryDark,
    ...($destructive ? tertiaryDestructiveStyle.active : {}),
  }),
  focus: ({ $destructive }) => ({
    "text-decoration": "underline",
    "text-decoration-color": eventsThemeColors.faireMarket.primaryDark,
    ...($destructive ? tertiaryDestructiveStyle.focus : {}),
  }),
};

export const buttonEventsTheme: IButtonTheme = {
  primary: primaryStyleEventsTheme,
  secondary: secondaryStyleEventsTheme,
  tertiary: tertiaryStyleEventsTheme,
};
