import { createIntl } from "react-intl";

import { StrictLocalizeFunction } from "../../localization/localize";

/**
 * Mocks the strictLocalize function. Returns the defaultMessage passed in the descriptor.
 */
export const mockStrictLocalize: StrictLocalizeFunction = (
  descriptor,
  values,
  opts
) =>
  createIntl({ locale: "en" }).formatMessage(descriptor as any, values, opts);
