import { request } from '../../../..';
import { IDisplayPasswordModalResponse } from '../../../../indigofair/data/IDisplayPasswordModalResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../types';

export const path = () => {
  return `/api/v2/users/display-password-modal`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/users/display-password-modal';

/**
 * Product Area: USERS
 */
export function execute(): Promise<IDisplayPasswordModalResponse>;
export function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IDisplayPasswordModalResponse>>;
export function execute(
  options?: EndpointOptions
): Promise<IDisplayPasswordModalResponse>;
export function execute(
  options?: EndpointOptions
): Promise<
  IDisplayPasswordModalResponse | SuccessResponse<IDisplayPasswordModalResponse>
> {
  const { route, ...requestOptions } = build(options);
  return request<IDisplayPasswordModalResponse>(requestOptions);
}
execute[PATH] = 'api/v2/users/display-password-modal/get';
export default execute;
