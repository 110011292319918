import { SettingConst } from '../../types';

/**
 * start time of UK_EU_BRANDS_FREE_SHIPPING
 */
export const UK_EU_BRANDS_FREE_SHIPPING_START_MILLIS =
  'UK_EU_BRANDS_FREE_SHIPPING_START_MILLIS' as SettingConst<
    'UK_EU_BRANDS_FREE_SHIPPING_START_MILLIS',
    number,
    'LOGGED_IN_RETAILER'
  >;
