export namespace IApiError {
  /**
   * Return an instance of IApiError with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IApiError>): IApiError =>
    ({
      entity_tokens: [],
      ...(partial ?? {}),
    }) as IApiError;
}

/**
 * Caution: this proto may get base64 encoded as an HTTP header which has a size limit.
 * Update com.faire.misk.web.MiskErrorResponse.grpcResponse if you add a potentially large field (repeated or string)!
 */
export interface IApiError {
  status_code: number | undefined;
  status_type: string | undefined;
  /**
   * TODO (Stas): remove this when FE supports localizedMessage
   * @deprecated
   */
  message: string | undefined;
  field: string | undefined;
  entity_token: string | undefined;
  /**
   * For mobile only, set this boolean if we are returning an error that updating the app will fix
   */
  can_update_app: boolean | undefined;
  entity_tokens: Array<string>;
  /**
   * Identifies the error so FE can distinguish errors. Usually looks like PlaidServiceException.FAILED_TO_LINK
   */
  service_error_code: string | undefined;
  /**
   * Localized error message that FE should surface to the user.
   */
  localized_message: string | undefined;
  debug_message: string | undefined;
  /**
   * This has be deprecated in favor of localizedTitle
   * TODO(noah): delete this after confirming FE does not use it
   * @deprecated
   */
  title: string | undefined;
  /**
   * Localized error title that FE should surface to the user. Some errors do
   * not need titles, and in that case this will not be set. FE should only
   * display a title if this field has been set.
   */
  localized_title: string | undefined;
}
