import { request } from '../..';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../types';

export const path = () => {
  return `/app-status/should-edge-cache`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    responseType: 'text',
    ignoreDuringPrerender: true,
    ignoreForRobots: true,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/app-status/should-edge-cache';

/**
 * Product Area: INFRA
 * Ignored (not called) during prerender sessions.
 * Ignored (not called) during bot sessions.
 */
export function execute(): Promise<string>;
export function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<string>>;
export function execute(options?: EndpointOptions): Promise<string>;
export function execute(
  options?: EndpointOptions
): Promise<string | SuccessResponse<string>> {
  const { route, ...requestOptions } = build(options);
  return request<string>(requestOptions);
}
execute[PATH] = 'app-status/should-edge-cache/get';
export default execute;
