import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";

import { getInitialLoadResourceEntries } from "./resourceTiming";

const MAX_RESOURCE_ENTRY_BUFFER_SIZE = 250;

export class ResourceTimingCollector {
  static get = singletonGetter(ResourceTimingCollector);

  private resourceTimings: PerformanceResourceTiming[] = [];

  private observer: PerformanceObserver | undefined = undefined;

  initialize = () => {
    if (this.observer) {
      return;
    }

    const PerformanceObserver = getWindow()?.PerformanceObserver;
    if (PerformanceObserver) {
      this.observer = new PerformanceObserver((entries) => {
        this.resourceTimings.push(
          ...(entries.getEntries() as PerformanceResourceTiming[])
        );

        const bufferOversizeCount =
          this.resourceTimings.length - MAX_RESOURCE_ENTRY_BUFFER_SIZE;
        if (bufferOversizeCount > 0) {
          this.resourceTimings.splice(0, bufferOversizeCount);
        }
      });
      this.observer.observe({ entryTypes: ["resource"] });
    }
  };

  getResourceEntries = (
    timeOfLastRouteChange: number
  ): PerformanceResourceTiming[] | undefined => {
    if (timeOfLastRouteChange === 0) {
      return getInitialLoadResourceEntries();
    } else if (this.observer) {
      return this.resourceTimings.filter(
        (entry) => entry.startTime >= timeOfLastRouteChange
      );
    } else {
      // Use undefined to indicate that PerformanceObserver is not supported.
      return undefined;
    }
  };
}
