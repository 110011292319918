import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import React, { PropsWithChildren, useEffect } from "react";

/**
 * Singleton to bridge RQ and view-states/stores.
 *
 * Under tests. DO NOT USE THIS. If you plan on using this, please talk to @tiba first.
 */
export class ReactQueryClientStore__DO_NOT_USE {
  static get = singletonGetter(ReactQueryClientStore__DO_NOT_USE);

  setClient = (client: QueryClient) => {
    this._client = client;
  };

  /**
   * ReactQuery client getter. Throws if not set, which will happen if you try to use this
   * before the React tree is rendered (for instance, app initializers should not use this store).
   *
   * If you're using this, please talk to @tiba first.
   */
  get client(): QueryClient {
    if (!this._client) {
      throw new Error("ReactQueryClientStore: client not set");
    }

    return this._client;
  }

  private _client?: QueryClient;
}

/**
 * Small component to configure the query client in ReactQueryClientStore.
 *
 * Once we have concluded the tests, we will merge this into QueryClientProvider
 * from web/common/reactQuery
 */
export const ReactQueryClientStoreProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const client = useQueryClient();

  useEffect(() => {
    ReactQueryClientStore__DO_NOT_USE.get().setClient(client);
  }, [client]);

  return <>{children}</>;
};
