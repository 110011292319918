export namespace ISearchRequestReferrer {
  export enum SearchRequestReferrerType {
    SEARCH_REQUEST_REFERRER_TYPE_UNKNOWN = 'SEARCH_REQUEST_REFERRER_TYPE_UNKNOWN',
    /**
     * No referrer.
     */
    NONE = 'NONE',
    /**
     * Avoid using this and consider adding a new value for new types of suggestions. This is a catchall just in case
     */
    SUGGESTIONS_ANY = 'SUGGESTIONS_ANY',
    SUGGESTIONS_SEARCH_QUERIES = 'SUGGESTIONS_SEARCH_QUERIES',
    SUGGESTIONS_CATEGORY_QUERIES = 'SUGGESTIONS_CATEGORY_QUERIES',
    SUGGESTIONS_CATEGORIES = 'SUGGESTIONS_CATEGORIES',
    SUGGESTIONS_BRANDS = 'SUGGESTIONS_BRANDS',
    SUGGESTIONS_TRENDING_SEARCHES = 'SUGGESTIONS_TRENDING_SEARCHES',
    SUGGESTIONS_RECENTLY_VIEWED_PRODUCTS = 'SUGGESTIONS_RECENTLY_VIEWED_PRODUCTS',
    SUGGESTIONS_RECENT_SEARCHES = 'SUGGESTIONS_RECENT_SEARCHES',
    SUGGESTIONS_SEARCH_RECOMMENDATIONS = 'SUGGESTIONS_SEARCH_RECOMMENDATIONS',
    SUGGESTIONS_APPAREL_CATEGORIES = 'SUGGESTIONS_APPAREL_CATEGORIES',
    SUGGESTIONS_ORDERED_BRANDS = 'SUGGESTIONS_ORDERED_BRANDS',
    SUGGESTIONS_AUTOCOMPLETE_CATEGORIES = 'SUGGESTIONS_AUTOCOMPLETE_CATEGORIES',
    HOMEPAGE_ANY = 'HOMEPAGE_ANY',
    HOMEPAGE_TRENDING_SEARCHES = 'HOMEPAGE_TRENDING_SEARCHES',
    HOMEPAGE_TRENDING_ON_FAIRE = 'HOMEPAGE_TRENDING_ON_FAIRE',
    HOMEPAGE_RECOMMENDED_SEARCHES = 'HOMEPAGE_RECOMMENDED_SEARCHES',
    PRODUCT_SEARCH_RELATED_SEARCHES = 'PRODUCT_SEARCH_RELATED_SEARCHES',
    SUGGESTIONS_RECENT_SEARCHES_WITH_FILTERS = 'SUGGESTIONS_RECENT_SEARCHES_WITH_FILTERS',
    SUGGESTIONS_SUGGESTED_SEARCHES_WITH_FILTERS = 'SUGGESTIONS_SUGGESTED_SEARCHES_WITH_FILTERS',
    HOMEPAGE_RECENT_SEARCHES = 'HOMEPAGE_RECENT_SEARCHES',
    HOMEPAGE_NEW_PRODUCTS_FROM_RECENT_SEARCH = 'HOMEPAGE_NEW_PRODUCTS_FROM_RECENT_SEARCH',
    PRODUCT_SEARCH_FALLBACK_PRODUCTS = 'PRODUCT_SEARCH_FALLBACK_PRODUCTS',
    HOMEPAGE_CATEGORIES_FOR_YOU = 'HOMEPAGE_CATEGORIES_FOR_YOU',
    HOMEPAGE_MARKETING_BANNER = 'HOMEPAGE_MARKETING_BANNER',
    CATEGORY_HUB_SUGGESTIONS = 'CATEGORY_HUB_SUGGESTIONS',
    SUGGESTIONS_SEARCH_INLINE_BANNER = 'SUGGESTIONS_SEARCH_INLINE_BANNER',
    /**
     * @deprecated
     */
    SUGGESTIONS_SUGGESTION_BANNER = 'SUGGESTIONS_SUGGESTION_BANNER',
    PRODUCT_SEARCH_REFINED_SEARCHES = 'PRODUCT_SEARCH_REFINED_SEARCHES',
    SUGGESTIONS_SUGGESTED_NAVIGATIONS = 'SUGGESTIONS_SUGGESTED_NAVIGATIONS',
    SUGGESTIONS_BRAND_PAGE_SEARCH_EMPTY_STATE = 'SUGGESTIONS_BRAND_PAGE_SEARCH_EMPTY_STATE',
    SUGGESTIONS_BRAND_PAGE_SEARCH_AUTOCOMPLETE = 'SUGGESTIONS_BRAND_PAGE_SEARCH_AUTOCOMPLETE',
  }

  /**
   * Return an instance of ISearchRequestReferrer with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<ISearchRequestReferrer>
  ): ISearchRequestReferrer =>
    ({
      ...(partial ?? {}),
    }) as ISearchRequestReferrer;
}

export interface ISearchRequestReferrer {
  referrer_type:
    | keyof typeof ISearchRequestReferrer.SearchRequestReferrerType
    | undefined;
  referrer_request_id: string | undefined;
}
