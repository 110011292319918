import { request } from '../../..';
import { IGetCategoriesResponse } from '../../../indigofair/data/IGetCategoriesResponse';
import { UserGroup } from '../../../indigofair/data/UserGroup';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';
import qs from 'query-string';

export type QueryParameters = {
  userGroup: keyof typeof UserGroup | undefined;
  hashCode: string | undefined;
  locale: string | undefined;
  country: string | undefined;
  cacheBust: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/v3/categories${queryString}`;
};

export const build = (
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v3/categories';

/**
 *   Gets the categories for the given treeType and userGroup, in the given locale.
 *   This endpoint is for logged-out users only.
 * Product Area: NAVIGATION_TREE_UX
 */
export function execute(
  queryParams?: QueryParameters
): Promise<IGetCategoriesResponse>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetCategoriesResponse>>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IGetCategoriesResponse>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IGetCategoriesResponse | SuccessResponse<IGetCategoriesResponse>> {
  const { route, ...requestOptions } = build(queryParams, options);
  return request<IGetCategoriesResponse>(requestOptions);
}
execute[PATH] = 'api/v3/categories/get';
export default execute;
