"use client";

import { Button } from "@faire/web--source/slate/Button/Button";
import { trackCxFoundationsErrorboundaryClick } from "@faire/web-api--source/events/cxFoundations/click/errorboundary";
import support from "@faire/web-api--source/routes/support";
import isEmpty from "lodash/isEmpty";
import React, { ReactNode, type JSX } from "react";

import { useStrictLocalization } from "../common/localization";

interface IProps {
  loggedOut?: boolean;
  children?: ReactNode;
  brandOrderToken?: string;
  onClick: () => void;
}

export const ContactFormLink: React.FC<IProps> = ({
  children,
  onClick,
  loggedOut,
  brandOrderToken,
}) => {
  const { strictLocalize } = useStrictLocalization();
  return (
    <Button
      variant="tertiary"
      linkTo={support({
        contact: "true",
        loggedOut: loggedOut ? "true" : undefined,
        brand_order_token: brandOrderToken,
      })}
      onClick={onClick}
      target="_blank"
      rel="noreferrer"
      tertiaryColor="inherit"
      style={{ font: "inherit" }}
    >
      {!isEmpty(children)
        ? children
        : strictLocalize({
            defaultMessage: "contact us",
            description: {
              text: "Faire's help center contact form, users contact Faire by filling out this form and submit issues or their requests",
            },
          })}
    </Button>
  );
};

export const ContactFormLinkFormatting = (chunk: JSX.Element): JSX.Element => (
  <ContactFormLink onClick={trackCxFoundationsErrorboundaryClick}>
    {chunk}
  </ContactFormLink>
);
