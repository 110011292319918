import { IRetailerPosDisabledSettings } from "@faire/web-api--source/indigofair/retailers/pos/IRetailerPosDisabledSettings";
import { IRetailerPosTypeEnum } from "@faire/web-api--source/indigofair/retailers/pos/IRetailerPosTypeEnum";

const { MISSING_CAPABILITIES, DELETED_LOCATION } =
  IRetailerPosDisabledSettings.PosDisabledSettingReason;
export { MISSING_CAPABILITIES, DELETED_LOCATION };

const { SHOPIFY, SQUARE, CLOVER } = IRetailerPosTypeEnum.RetailerPosType;
export { SHOPIFY, SQUARE, CLOVER };

export const POS_ARTICLE_ID = "360037248712";
export const FAIRE_SELL_WHOLESALE_APP_LINK =
  "https://apps.shopify.com/faire-sell-wholesale";

export const SUGGESTED_MATCHES_KEY = "posSuggestedMatches";
export const LINKING_ITEMS_KEY = "posLinkingItems";
export const LINKED_ITEMS_KEY = "posLinkedItems";
export const UNLINKED_ITEMS_KEY = "posUnlinkedItems";
export const ARCHIVED_ITEMS_KEY = "posArchivedItems";
export const ORDERS_LIST_KEY = "posOrdersList";
export const ORDER_DETAILS_KEY = "posOrderDetails";
export const ORDER_MANAGER_KEY = "posOrderManager";
export const ORDER_SUMMARY_KEY = "posOrderSummary";
export const INFORMATION_BANNER_KEY = "posInformationBanner";

export const LIST_LIMIT_SIZE = 1000;

export enum PAGE {
  POST_CHECKOUT = "post_checkout",
  ORDER_DETAILS = "order_details",
  ORDERS_PAGE = "orders_page",
  BUY_IT_AGAIN = "buy_it_again",
  UNKKNOWN = "unknown",
}
