"use client";

import { getWindowOrThrow } from "@faire/web--source/common/globals/getWindow";
import React, { createContext, useContext, useEffect } from "react";
import { useSyncExternalStore } from "use-sync-external-store/shim";

import {
  StoreMetadata,
  StoreSelector,
  ALL_FIELDS_MARKER,
  AllFieldsMarker,
} from "./types";
import { useStoreMetadata } from "./useStoreMetadata";

type ProviderProps<S extends object> = {
  children: React.ReactNode;
  value?: StoreSelector<S>;
};

export type ContextStoreType<S extends object> = {
  Provider: React.FC<ProviderProps<S>>;
  useStore: {
    <T extends readonly (keyof S)[]>(
      fields: T
    ): [Pick<S, T[number]>, (selector: StoreSelector<S>) => void];
    (
      fields: AllFieldsMarker
    ): [Pick<S, keyof S>, (selector: StoreSelector<S>) => void];
  };
};

type CreateContextStoreOptions = {
  /**
   * Used to identify the context store in console warnings.
   */
  name?: string;
};

/**
 * Create a Context Store to manage frontend state. A Context Store is an object that contains
 *   - a useStore hook that's used to consume state in the context
 *   - a propless Provider component that provides store subscription data to useStore consumers.
 *
 * More info: https://dev.faire.team/frontend/pattern/ContextStores
 *
 * @param initialStore the initial store state object
 * @returns a ContextStoreType object containing the Provider component and the useStore hook.
 */
export const createContextStore = <S extends object>(
  initialStore: S,
  options?: CreateContextStoreOptions
): ContextStoreType<S> => {
  const StoreMetadataContext = createContext<StoreMetadata<S> | undefined>(
    undefined
  );

  const Provider: React.FC<ProviderProps<S>> = ({ children, value }) => {
    return (
      <StoreMetadataContext.Provider
        value={useStoreMetadata(
          value
            ? {
                ...initialStore,
                ...(typeof value === "function" ? value(initialStore) : value),
              }
            : { ...initialStore }
        )}
      >
        {children}
      </StoreMetadataContext.Provider>
    );
  };

  function useStore<T extends readonly (keyof S)[]>(
    fields: T
  ): [Pick<S, T[number]>, (selector: StoreSelector<S>) => void];
  function useStore(
    fields: AllFieldsMarker
  ): [Pick<S, keyof S>, (selector: StoreSelector<S>) => void];
  function useStore<T extends readonly (keyof S)[] | AllFieldsMarker>(
    fields: T
  ): [
    T extends readonly (keyof S)[] ? Pick<S, T[number]> : Pick<S, keyof S>,
    (selector: StoreSelector<S>) => void,
  ] {
    const store = useContext(StoreMetadataContext);

    useEffect(() => {
      if (
        !store &&
        getWindowOrThrow().location.hostname.includes("localhost") &&
        process?.env?.NODE_ENV !== "test"
      ) {
        const name = options?.name || "ContextStore";
        const fieldsArrayString =
          fields !== ALL_FIELDS_MARKER
            ? `["${fields.join('", "')}"]`
            : "ALL_FIELDS";
        // Warn if the consumer is accessing the store without a Provider higher up in the tree
        // eslint-disable-next-line no-console
        console.warn(
          `${name} consumer of fields ${fieldsArrayString} could not find a Provider higher up in the tree.`
        );
      }
    }, [fields, store]);

    const data = useSyncExternalStore(
      (callback) => {
        if (store) {
          return store.subscribe(callback, fields);
        }
        return () => {};
      },
      () => (store ? store.get() : initialStore),
      () => (store ? store.get() : initialStore)
    );

    return [
      data as T extends readonly (keyof S)[]
        ? Pick<S, T[number]>
        : Pick<S, keyof S>,
      store ? store.set : () => {},
    ];
  }

  return {
    Provider,
    useStore,
  };
};
