import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'sessions-viewport_retailer_page_view-view';

/**
 * Log viewport as user views a page on the retailer web app
 */
export function trackSessionsViewportRetailerPageViewView(
  Path: string,
  Width2: string,
  Height3: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: { Path, Width2, Height3 },
  });
}
