"use client";

import { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { RoutingContext } from "../routing/RoutingContext";

interface IQueryParam {
  [key: string]: unknown;
}

function useReactRouterSearchParams() {
  const location = useLocation();
  return location.search;
}

/**
 * Hook utility to access query-params
 * @param parsingFunction Parameters.parse function provided by web-api/routes
 * @example
 * ```ts
 * import { Parameters as BulkUploaderQueryParams } from "@faire/web-api--source/routes/brand-portal/my-shop/products/bulk-edit";
 *
 * function BulkEditor({ token }) {
 *   const queryParams = useQueryParams(BulkUploaderQueryParams.parse);
 *
 *   return (
 *     <div>
 *       Show Columns: {queryParams.columns?.join(', ')}
 *     </div>
 *   );
 * }
 * ```
 */
export const useQueryParams = <T extends IQueryParam>(
  parsingFunction: (search?: string) => T
): T => {
  const { useSearchParams = useReactRouterSearchParams } =
    useContext(RoutingContext);
  const search = useSearchParams();
  return useMemo(() => parsingFunction(search), [search, parsingFunction]);
};
