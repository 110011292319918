import { isNotNull } from "@faire/web--source/common/typescriptUtils";
import { matchPath } from "react-router-dom";

import { removeLocaleFromUrl } from "./util";

export const matchPathWithLocale = (
  pathWithLocal: string,
  route: string
): boolean => {
  return isNotNull(
    matchPath(removeLocaleFromUrl(pathWithLocal), { path: route })
  );
};
