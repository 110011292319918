import { request } from '../../../../..';
import { ISearchBrandLeanProductTilesResponse } from '../../../../../indigofair/data/ISearchBrandLeanProductTilesResponse';
import { ISearchMakerProductsRequest } from '../../../../../indigofair/data/ISearchMakerProductsRequest';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../../types';
import qs from 'query-string';

export type QueryParameters = {
  clientSize: keyof typeof ISearchMakerProductsRequest.ClientSize | undefined;
  locale: string | undefined;
  timezone: string | undefined;
  orderedProductTokens: Array<string> | undefined;
  page: number | undefined;
  pageSize: number | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (brandToken: string, queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/v2/search/${brandToken}/featured-products${queryString}`;
};

export const build = (
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(brandToken),
    method: 'GET',
    trackClientSideLatency: true,
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/search/:brand_token/featured-products';

/**
 * Product Area: LOGGED_OUT_RETAILERS
 */
export function execute(
  brandToken: string,
  queryParams?: QueryParameters
): Promise<ISearchBrandLeanProductTilesResponse>;
export function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ISearchBrandLeanProductTilesResponse>>;
export function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ISearchBrandLeanProductTilesResponse>;
export function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  | ISearchBrandLeanProductTilesResponse
  | SuccessResponse<ISearchBrandLeanProductTilesResponse>
> {
  const { route, ...requestOptions } = build(brandToken, queryParams, options);
  return request<ISearchBrandLeanProductTilesResponse>(requestOptions);
}
execute[PATH] = 'api/v2/search/brandToken/featured-products/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters
): Promise<ISearchBrandLeanProductTilesResponse>;
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ISearchBrandLeanProductTilesResponse>>;
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ISearchBrandLeanProductTilesResponse>;
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  | ISearchBrandLeanProductTilesResponse
  | SuccessResponse<ISearchBrandLeanProductTilesResponse>
> {
  const { route, ...requestOptions } = build(brandToken, queryParams, options);
  return request<ISearchBrandLeanProductTilesResponse>(requestOptions);
}
