import { delay } from "@faire/web--source/common/delay";
import {
  getWindow,
  getWindowOrThrow,
} from "@faire/web--source/common/globals/getWindow";
import { RouteMatcher } from "@faire/web--source/common/routes/RouteMatcher";
import { Storage } from "@faire/web--source/common/Storage";
import { removeLocaleFromUrl } from "@faire/web--source/ui/routing/util";
import { route as brandRoute } from "@faire/web-api--source/routes/brand/brandToken";
import { route as c1Route } from "@faire/web-api--source/routes/category/c1CategoryUrlId";
import { route as c1BrandValueRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/brand-value/brandValue";
import { route as c2Route } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId";
import { route as c2BrandValueRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/brand-value/brandValue";
import { route as c3Route } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId";
import { route as c3BrandValueRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId/brand-value/brandValue";
import { route as discoverRoute } from "@faire/web-api--source/routes/discover/searchTerm";
import { route as exploreRoute } from "@faire/web-api--source/routes/explore/searchTerm";
import { route as collectionRoute } from "@faire/web-api--source/routes/product-based-collection/product_based_collection_token";
import { route as productRoute } from "@faire/web-api--source/routes/product/productToken";
import { route as homeRoute } from "@faire/web-api--source/routes/root";
import { route as searchRoute } from "@faire/web-api--source/routes/search";
import { route as suppliersC1Route } from "@faire/web-api--source/routes/suppliers/c1SuppliersUrlId";
import { route as suppliersC1BrandValueRoute } from "@faire/web-api--source/routes/suppliers/c1SuppliersUrlId/brand-value/brandValue";
import { route as suppliersC2Route } from "@faire/web-api--source/routes/suppliers/c1SuppliersUrlId/subcategory/c2SuppliersUrlId";
import { route as suppliersC3Route } from "@faire/web-api--source/routes/suppliers/c1SuppliersUrlId/subcategory/c2SuppliersUrlId/c3SuppliersUrlId";

import {
  notifySignupSnackbarEvent,
  SignUpSnackbarEvent,
} from "@faire/retailer/components/LoggedOutMobileSignUpSnackbar/useSignupSnackbarStateMachine";
import { isLoggedInRetailerOrBrandPreview } from "@faire/retailer/lib/isLoggedInRetailer";
import { isComingFromFacebookOrInstagram } from "@faire/retailer/lib/navigation-origin/isComingFromFacebookOrInstagram";
import {
  popSignUp,
  SignUpElement,
  SignUpType,
} from "@faire/retailer/lib/popSignUp";
import { AppHistory } from "@faire/retailer/packages/core/services/AppHistory";
import { getIsProduction } from "@faire/retailer/serialized-data/getEnvName";
import { getIsRobot } from "@faire/retailer/serialized-data/getIsRobot";
import {
  assignSettingSecondPageAutoModal,
  getSettingSecondPageAutoModal,
} from "@faire/retailer/settings/getSettingSecondPageAutoModal";
import { AppModalStore } from "@faire/retailer/stores/ui/AppModalStore";

import { HAS_CLOSED_SIGNUP_MODAL } from "../components/AppSignUp/state/useHandleModalState";
import { getFaireDirectBrandToken } from "../serialized-data/getFaireDirectBrandToken";

let popSignUpCount: number = 2;
let ignoreNextScroll: boolean = false;
let hasNavigated: boolean = true;

const autoSignUpOnScroll = () => {
  // we only want to react to the scroll event if the user has first navigated to a different page
  if (
    !ignoreNextScroll &&
    !isSamePathname(
      AppHistory.get().location?.pathname,
      AppHistory.get().previousLocation()?.pathname
    )
  ) {
    autoSignUp();
  }
  ignoreNextScroll = false;
};

export const initializeAutoSignUp = async () => {
  getWindow()?.addEventListener("scroll", autoSignUpOnScroll);

  AppHistory.get().history.listen((newLocation) => {
    if (
      !isSamePathname(newLocation.pathname, AppHistory.get().location?.pathname)
    ) {
      hasNavigated = true;

      // this is to handle cases where a user has scrolled partway down the page, and then navigates
      //   this causes a scroll event (to top of page) which in turn triggers the autoSignUp
      //   we want to ignore this scroll event
      if (getWindow()?.scrollY !== 0) {
        ignoreNextScroll = true;
      }
    }
  });

  // If we've scrolled before page load (i.e., while browsing SSR content),
  // we want to behave as if debouncedAutoSignUp() was called.
  const lastScrollBeforePageLoad = getWindow()?.mostRecentScrollTime;
  if (lastScrollBeforePageLoad) {
    if (lastScrollBeforePageLoad === getWindow()?.mostRecentScrollTime) {
      await delay(0);
      autoSignUp();
    }
  }
};

export const autoSignUp = () => {
  if (shouldPopSignUp()) {
    notifySignupSnackbarEvent(SignUpSnackbarEvent.AUTO_SIGNUP_OPENED);

    if (popSignUpCount > 0) {
      popSignUpCount--;
      hasNavigated = false;
    }

    if (getSettingSecondPageAutoModal() && popSignUpCount === 0) {
      getWindowOrThrow().removeEventListener("scroll", autoSignUpOnScroll);
    }

    popSignUp(AppHistory.get().history, SignUpType.AUTO, () => {}, {
      element: SignUpElement.AUTO_POPUP,
      sourceId: "auto_signup",
    });
  }
};

const shouldPopSignUp = () => {
  const matcher = new RouteMatcher(whitelist);
  const isFaireDirectActiveInLoggedOut = !!getFaireDirectBrandToken();
  const canShowSecondPageModal = popSignUpCount > 0 && hasNavigated;
  const hasSignupModalClosed = getSettingSecondPageAutoModal()
    ? !canShowSecondPageModal
    : Storage.session.getItem(HAS_CLOSED_SIGNUP_MODAL);

  // attempting to see modal for the second time
  if (
    popSignUpCount === 1 &&
    hasNavigated &&
    matcher.match(AppHistory.get().location?.pathname ?? "")
  ) {
    // if user has already navigated to a different page, we can assign the setting
    assignSettingSecondPageAutoModal();

    // if control group, we don't want to show the modal again
    if (!getSettingSecondPageAutoModal()) {
      getWindowOrThrow().removeEventListener("scroll", autoSignUpOnScroll);
    }
  }

  return (
    getIsProduction() &&
    !isLoggedInRetailerOrBrandPreview() &&
    !isComingFromFacebookOrInstagram() &&
    !isFaireDirectActiveInLoggedOut &&
    !AppModalStore.get().isModalShowing &&
    matcher.match(AppHistory.get().location?.pathname ?? "") &&
    !hasSignupModalClosed &&
    !getIsRobot()
  );
};

const whitelist = [
  homeRoute,
  brandRoute,
  productRoute,
  collectionRoute,
  searchRoute,
  discoverRoute,
  exploreRoute,
  c1Route,
  c1BrandValueRoute,
  c2Route,
  c2BrandValueRoute,
  c3Route,
  c3BrandValueRoute,
  suppliersC3Route,
  suppliersC1Route,
  suppliersC1BrandValueRoute,
  suppliersC2Route,
];

const isSamePathname = (
  p1: string | undefined,
  p2: string | undefined
): boolean => {
  if (p1 === undefined || p2 === undefined) {
    return p1 === p2;
  }
  return (
    decodeURI(removeLocaleFromUrl(p1)) === decodeURI(removeLocaleFromUrl(p2))
  );
};

export const resetAutoSignUpState = () => {
  popSignUpCount = 2;
  ignoreNextScroll = false;
};
