"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const Primary: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_WINTER_MARKET_2023]
      .decorative5;
  return (
    <SVGIcon
      width="240"
      height="150"
      viewBox="0 0 240 150"
      fill="none"
      {...rest}
    >
      <path
        d="M53.2634 145.333C53.2634 144.615 52.7066 124.107 61.067 104.974C69.4273 85.8412 81.3519 77.3287 95.7593 70.3277C105.259 65.7114 128.536 63.9463 141.917 68.1966C147.046 69.8236 155.834 73.1407 161.987 80.9225C168.141 88.7043 176.471 97.2175 184.295 129.051C185.961 135.753 186.786 142.973 186.74 150H239C239.008 137.676 237.533 125.423 234.626 113.664C220.709 56.9086 205.797 41.7738 194.862 27.9003C183.927 14.0268 168.22 8.09899 159.075 5.19816C135.217 -2.36923 104.941 -2.14083 79.5472 8.98185C57.5617 18.6112 29.8425 36.7289 14.9311 70.7821C0.0196659 104.835 1.01376 141.411 1.01376 142.672C1.01376 142.672 1.01376 146.103 1.01376 150H53.2534C53.2633 147.616 53.2634 145.333 53.2634 145.333Z"
        fill={iconColor}
      />
    </SVGIcon>
  );
};
