import { logWarning } from "@faire/web--source/common/logging";
import { isPrerender } from "@faire/web--source/common/user-agent/isPrerender";
import { Link } from "@faire/web--source/ui/LinkAnchor";
import { hasLocale } from "@faire/web--source/ui/routing/util";
import trackClick from "@faire/web-api--source/api/click-track/post";
import { IClickInfo } from "@faire/web-api--source/indigofair/data/IClickInfo";
import React, { PropsWithChildren, useCallback, useContext } from "react";
import { LinkProps } from "react-router-dom";

import { isLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";

export interface ITrackedLinkContextData {
  type: IClickInfo.Type;
  requestId?: string;
  containerName: string;
}

export const TrackedLinkContext = React.createContext<
  ITrackedLinkContextData | undefined
>(undefined);

export namespace TrackedLink {
  export interface IProps extends Omit<LinkProps, "to"> {
    token: string | undefined;
    to: string | undefined;
  }
}

export const TrackedLink = (props: PropsWithChildren<TrackedLink.IProps>) => {
  const { to, children, ...linkProps } = props;
  const clickInfo = useContext(TrackedLinkContext);

  const handleClick: LinkProps["onClick"] = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (clickInfo !== undefined) {
        trackClick(
          IClickInfo.build({
            token: props.token,
            type: clickInfo.type,
            container_name: clickInfo.containerName,
            request_id: clickInfo.requestId || "",
          })
        );
      } else {
        logWarning(
          new Error(
            "TrackedLink component clicked outside of TrackedLinkContext.Provider!"
          )
        );
      }

      if (props.onClick) {
        props.onClick(event);
      }
    },
    [clickInfo, props]
  );

  if (to === undefined) {
    return <>{children}</>;
  }

  if (clickInfo === undefined) {
    return (
      <Link data-test-id="tracked-link-no-info" to={to} {...linkProps}>
        {children}
      </Link>
    );
  }

  /**
   * Skip prepending the locale to path if it is a logged-out product click
   * to prevent random scrolling to the top if the path does not link to a new page.
   * prerender should still get locale prepended to path.
   */
  const shouldSkipPrependLocale: boolean =
    !isLoggedInRetailer() &&
    !isPrerender() &&
    clickInfo?.type === IClickInfo.Type.PRODUCT
      ? !hasLocale(to)
      : false;
  return (
    <Link
      to={to}
      skipPrependLocale={shouldSkipPrependLocale}
      {...linkProps}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

export const withTrackedLink = <T,>(
  Comp: React.FC<T & ITrackedLinkContextData>
): React.FC<Omit<T, keyof ITrackedLinkContextData>> =>
  function (props) {
    return (
      <TrackedLinkContext.Consumer>
        {(value) => <Comp {...value!} {...(props as T)} />}
      </TrackedLinkContext.Consumer>
    );
  };
