import { StrictLocalizeFunction } from "@faire/web--source/common/localization";
import { useCallback } from "react";

import { defaultSSOErrorMessage } from "@faire/retailer/components/SocialSignOnButtons/errorMessages";

import { useSSOContextStore } from "./SSOContextStore";

export const useSSOErrorStore = () => {
  const [{ errorMessage, strictLocalize }, setStore] = useSSOContextStore([
    "errorMessage",
    "strictLocalize",
  ]);

  const setErrorMessage = useCallback(
    (message: string) => {
      setStore({
        errorMessage: message,
      });
    },
    [setStore]
  );

  // This store is linked to the SSOContextStore and is used to manage the error message state.
  // We need to pass the strict localization function in at a later point because it is initialized prior to localization provider.
  const setStrictLocalize = useCallback(
    (strictLocalize: StrictLocalizeFunction) => {
      setStore({
        strictLocalize,
      });
    },
    [setStore]
  );

  const setLocalizedErrorMessage = useCallback(() => {
    if (strictLocalize) {
      setErrorMessage(defaultSSOErrorMessage(strictLocalize));
    }
  }, [setErrorMessage, strictLocalize]);

  const resetErrorMessage = useCallback(() => {
    setStore({
      errorMessage: "",
    });
  }, [setStore]);

  return {
    errorMessage,
    strictLocalize,
    setErrorMessage,
    setLocalizedErrorMessage,
    resetErrorMessage,
    setStrictLocalize,
  };
};
