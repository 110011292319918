export namespace IImage {
  /**
   * This type allows us to distinguish between image types in all contexts
   * where that might be ambiguous. For example, when a product has multiple types of images
   * attached to it.
   */
  export enum Type {
    PRODUCT = 'PRODUCT',
    PACKAGING = 'PACKAGING',
    LIFESTYLE = 'LIFESTYLE',
    DEFAULT_BRAND = 'DEFAULT_BRAND',
    /**
     * A squared image for a brand with a light color pallete.
     */
    LIGHT_TILE = 'LIGHT_TILE',
    /**
     * A squared image for a brand with a medium color pallete.
     */
    MEDIUM_TILE = 'MEDIUM_TILE',
    /**
     * A squared image for a brand with a dark color pallete.
     */
    DARK_TILE = 'DARK_TILE',
    /**
     * A landing page image for brand partnerships with a light color palette.
     */
    LIGHT_WHOLESALE_PORTAL_LANDING = 'LIGHT_WHOLESALE_PORTAL_LANDING',
    /**
     * A landing page image for brand partnerships with a dark color palette.
     */
    DARK_WHOLESALE_PORTAL_LANDING = 'DARK_WHOLESALE_PORTAL_LANDING',
    /**
     * A signup page image for brand partnerships with a light color palette.
     */
    LIGHT_WHOLESALE_PORTAL_SIGNUP = 'LIGHT_WHOLESALE_PORTAL_SIGNUP',
    /**
     * A signup page image for brand partnerships with a dark color palette.
     */
    DARK_WHOLESALE_PORTAL_SIGNUP = 'DARK_WHOLESALE_PORTAL_SIGNUP',
    /**
     * Can be the hero image for a displayable entity. Each entity can have at most one Hero image.
     */
    HERO = 'HERO',
    MOBILE = 'MOBILE',
    DESKTOP = 'DESKTOP',
    TABLET = 'TABLET',
    XLARGE_DESKTOP = 'XLARGE_DESKTOP',
    /**
     * Used to show that the type of this image is represented as a tag
     */
    TAGGED = 'TAGGED',
  }

  export namespace ICropParameters {
    export enum FormFactor {
      FORM_FACTOR_UNKNOWN = 'FORM_FACTOR_UNKNOWN',
      SQUARE_TILE = 'SQUARE_TILE',
      APPAREL_RECTANGULAR_TILE = 'APPAREL_RECTANGULAR_TILE',
      BANNER_IMAGE = 'BANNER_IMAGE',
      PROFILE_IMAGE = 'PROFILE_IMAGE',
    }

    export enum Source {
      SOURCE_UNKNOWN = 'SOURCE_UNKNOWN',
      BRAND_USER_SPECIFIED = 'BRAND_USER_SPECIFIED',
      ADMIN_USER_SPECIFIED = 'ADMIN_USER_SPECIFIED',
      AUTO_GENERATED_DATA_SCIENCE = 'AUTO_GENERATED_DATA_SCIENCE',
    }

    /**
     * Return an instance of ICropParameters with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<ICropParameters>
    ): ICropParameters =>
      ({
        ...(partial ?? {}),
      }) as ICropParameters;
  }

  export interface ICropParameters {
    /**
     * The offset from the top left of the corner the crop starts in, moving right
     */
    x: number | undefined;
    /**
     * The offset from the top left of the corner the crop starts in, moving down
     */
    y: number | undefined;
    /**
     * The total width of the cropped image, starting from x and continuing right
     */
    width: number | undefined;
    /**
     * The total height of the cropped image, starting from y and continuing downwards
     */
    height: number | undefined;
    /**
     * The form factor, or shape, for which this crop has been defined for
     */
    form_factor: keyof typeof IImage.ICropParameters.FormFactor | undefined;
    /**
     * Where this crop data originates from
     */
    source: keyof typeof IImage.ICropParameters.Source | undefined;
  }

  /**
   * Return an instance of IImage with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IImage>): IImage =>
    ({
      tags: [],
      crop_parameters: [],
      ...(partial ?? {}),
    }) as IImage;
}

export interface IImage {
  /**
   * @protobufOption (faire.common.diffIgnore) = ALWAYS
   */
  token: string | undefined;
  width: number | undefined;
  height: number | undefined;
  sequence: number | undefined;
  url: string | undefined;
  type: keyof typeof IImage.Type | undefined;
  /**
   * A distinct unordered list of tags associated with this image
   * @protobufOption (faire.common.diffAs) = UNORDERED
   */
  tags: Array<string>;
  /**
   * Alternate text for an image if the image cannot be displayed
   */
  alt_text: string | undefined;
  /**
   * All configured crop data for various form factors defined for the image.
   */
  crop_parameters: Array<IImage.ICropParameters>;
}
