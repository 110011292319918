import brandMessages from "@faire/web-api--source/routes/brand-portal/messages";
import { route as messagesRoute } from "@faire/web-api--source/routes/messages";
import insiderMembership from "@faire/web-api--source/routes/retailer/settings/insider-membership";
import paymentMethods from "@faire/web-api--source/routes/retailer/settings/payment-methods";
import insiderMembershipInfo from "@faire/web-api--source/routes/user/insider/info";
import isUndefined from "lodash/isUndefined";
import qs from "query-string";
import { match, matchPath } from "react-router-dom";

import { RetailerStore } from "@faire/retailer/stores/domain/RetailerStore";
import { UserStore } from "@faire/retailer/stores/domain/UserStore";

export type RedirectRule = (pathname: string, query: string) => string | null;

export const isPathMatch = (
  pathname: string,
  pathToMatch: string,
  exact: boolean = true
): match | null => {
  return matchPath(pathname, {
    path: pathToMatch,
    exact,
  });
};

// Cannot use parse since it is not deterministic
export const getQueryParts = (query: string): string[] => {
  let processedQuery = query.toLowerCase();
  if (processedQuery[0] === "?") {
    processedQuery = processedQuery.slice(1);
  }
  return processedQuery.split("&");
};

/**
 * @deprecated
 *
 * Redirect rules are an old pattern for matching location path changes
 * and redirecting to a new location when that happens. Many of the below redirects
 * are no longer needed and should be removed.
 *
 * All new redirects should use the standard react-router-dom <Redirect /> component
 * in order to redirect old paths to new ones.
 */
export const redirectRules: RedirectRule[] = [
  (pathname: string, query: string) => {
    const matchedPath = isPathMatch(pathname, "/user/account/payments");
    if (matchedPath !== null) {
      return `${paymentMethods()}${query ?? ""}`;
    }
    return null;
  },

  (pathname: string) => {
    const matchedPath = isPathMatch(pathname, "/user/boom");
    if (matchedPath !== null) {
      return insiderMembership();
    }
    return null;
  },

  (pathname: string) => {
    const matchedPath = isPathMatch(pathname, "/user/boom/info");
    if (matchedPath !== null) {
      return insiderMembershipInfo();
    }
    return null;
  },

  (pathname: string) => {
    const matchedPath = isPathMatch(pathname, messagesRoute);
    if (
      matchedPath !== null &&
      isUndefined(RetailerStore.get().retailer) &&
      !isUndefined(UserStore.get().user)
    ) {
      return brandMessages();
    }
    return null;
  },

  (pathname: string) => {
    const matchedPath =
      isPathMatch(pathname, "/brand-onboarding", false) ||
      (isPathMatch(pathname, "/:locale/brand-onboarding", false) &&
        !isPathMatch(pathname, "/brand-portal/brand-onboarding", false));

    if (matchedPath) {
      return "/brand-portal/brand-onboarding";
    }
    return null;
  },

  (pathname: string, query: string) => {
    const matchedPath =
      isPathMatch(pathname, "/brand/:brandToken") ||
      isPathMatch(pathname, "/retailer/:retailerToken/brand/:brandToken");
    const queryObj = qs.parse(query);

    if (matchedPath && queryObj.fromC) {
      return `${pathname}?${qs.stringify({
        ...queryObj,
        fromC: undefined,
        sortingHint: true,
      })}`;
    }
    return null;
  },
];
