"use client";

import { useIsSSR } from "@faire/web--source/common/server-side-rendering/isSSR";
import { Storage } from "@faire/web--source/common/Storage";
import React, { useMemo } from "react";
// eslint-disable-next-line @faire/no-theme-provider-from-styled-components
import {
  DefaultTheme as StyledComponentsDefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components";

import { DefaultTheme } from "./DefaultTheme";
import { IHighVisState, ITheme } from "./Theme";

export const themeNames = ["Faire"] as const;

const themes: { [key in ThemeName]: ITheme } = {
  Faire: DefaultTheme,
} as const;

type ThemeName = (typeof themeNames)[number];

export const ThemeProvider: React.FC<{
  children?: React.ReactNode;
  theme: ThemeName | StyledComponentsDefaultTheme;
}> = ({ theme, children }) => {
  const isSSR = useIsSSR();
  const highVisTypography =
    !isSSR && Storage.local.getItem(`HIGH_VIS_SLATE_TYPOGRAPAHY`) === "true";
  const highVisLayout =
    !isSSR && Storage.local.getItem(`HIGH_VIS_SLATE_LAYOUT`) === "true";
  const highVisComponent =
    !isSSR && Storage.local.getItem(`HIGH_VIS_SLATE_COMPONENT`) === "true";
  return useMemo(() => {
    const highVisState =
      highVisTypography || highVisLayout || highVisComponent
        ? {
            typography: highVisTypography,
            layout: highVisLayout,
            component: highVisComponent,
          }
        : undefined;

    return (
      <StyledComponentsThemeProvider theme={resolveTheme(theme, highVisState)}>
        {children}
      </StyledComponentsThemeProvider>
    );
  }, [children, highVisComponent, highVisLayout, highVisTypography, theme]);
};

const resolveTheme = (
  theme: ThemeName | StyledComponentsDefaultTheme,
  highVisState: IHighVisState | undefined
): ITheme => {
  let resolvedTheme: ITheme;
  if (typeof theme === "string") {
    resolvedTheme = getTheme(theme as ThemeName);
  } else {
    resolvedTheme = theme;
  }
  if (highVisState) {
    return {
      ...resolvedTheme,
      highVis: highVisState,
    };
  }
  return resolvedTheme;
};

export const getTheme = (themeName: ThemeName): ITheme => {
  return themes[themeName] ?? DefaultTheme;
};
