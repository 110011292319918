import { getWindow } from "@faire/web--source/common/globals/getWindow";

import { getGlobalProperty } from "./getGlobalProperty";

export const getDocument = (): Document | undefined =>
  getGlobalProperty<Document>("document") ?? getWindow()?.["document"];

export const getDocumentOrThrow = (): Document => {
  const document = getDocument();
  if (!document) {
    throw new Error("document not found");
  }
  return document;
};
