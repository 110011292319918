import { COOKIE_CURRENCY_OVERRIDE } from "@faire/web--source/common/consts/COOKIE_CURRENCY_OVERRIDE";
import { STRING_ID_MODE_COOKIE } from "@faire/web--source/common/consts/IF_SHOW_TEXT_VARIANT_ID";
import { Currency } from "@faire/web--source/common/currency/Currency";
import { GIBBERISH_TEXT_MODE_COOKIE } from "@faire/web--source/common/localization";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import Cookies from "js-cookie";

import { COOKIE_LOCALE_OVERRIDE } from "./consts/COOKIE_LOCALE_OVERRIDE";
import { SHOP_PREVIEW_OVERRIDE_KEY } from "./consts/SHOP_PREVIEW_OVERRIDE_KEY";

export type Cookies = {
  [COOKIE_LOCALE_OVERRIDE]: Language | string;
  [SHOP_PREVIEW_OVERRIDE_KEY]: Language | string;
  [SHOP_PREVIEW_OVERRIDE_KEY]: Language | string;
  [STRING_ID_MODE_COOKIE]: boolean;
  [GIBBERISH_TEXT_MODE_COOKIE]: boolean;
  [COOKIE_CURRENCY_OVERRIDE]: Currency;
};

// According to the GDPR’s definition of persistent cookies, set for 12 months
const COOKIE_EXPIRES_IN_DAYS = 365;

export const toggleCookie = <K extends keyof Cookies>(
  key: K,
  value: Cookies[K] | undefined
) => {
  if (value) {
    setCookies(key, value);
  } else {
    removeCookie(key);
  }
};

export const setCookies = <K extends keyof Cookies>(
  key: K,
  value: Cookies[K]
) => {
  Cookies.set(key, value as string, { expires: COOKIE_EXPIRES_IN_DAYS });
};

export const getCookies = <K extends keyof Cookies>(
  key: K
): Cookies[K] | undefined => Cookies.get(key) as Cookies[K];

export const removeCookie = <K extends keyof Cookies>(key: K) =>
  Cookies.remove(key);
