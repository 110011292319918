export namespace IRetailerPosTypeEnum {
  export enum RetailerPosType {
    SQUARE = 'SQUARE',
    SHOPIFY = 'SHOPIFY',
    CLOVER = 'CLOVER',
    QUICKBOOKS = 'QUICKBOOKS',
  }

  /**
   * Return an instance of IRetailerPosTypeEnum with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IRetailerPosTypeEnum>
  ): IRetailerPosTypeEnum =>
    ({
      ...(partial ?? {}),
    }) as IRetailerPosTypeEnum;
}

export interface IRetailerPosTypeEnum {}
