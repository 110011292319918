/**
 * Not intended for external use.
 * A constant that can be passed to `useStore` to indicate that the consumer
 * wishes to subscribe to, and be able to read, every field of the context
 * store and not just those fields that are explicitly listed in an array.
 * This isn't meant to be used directly; use `useAllFieldsOfContextStore` instead.
 */
export const ALL_FIELDS_MARKER = Symbol("ALL_FIELDS_MARKER");
export type AllFieldsMarker = typeof ALL_FIELDS_MARKER;

export type StoreSelector<S extends object> =
  | Partial<S>
  | ((prevState: S) => Partial<S>);

export type StoreMetadata<S extends object> = {
  get: <T extends readonly (keyof S)[]>() => Pick<S, T[number]>;
  set: (selector: StoreSelector<S>) => void;
  subscribe: (
    callback: () => void,
    fields: readonly (keyof S)[] | AllFieldsMarker
  ) => () => void;
};

export type UseStoreHook<
  S extends object,
  T extends readonly (keyof S)[] | AllFieldsMarker
> = (
  fields: T
) => [
  T extends readonly (keyof S)[] ? Pick<S, T[number]> : Pick<S, keyof S>,
  (selector: StoreSelector<S>) => void
];
