import { request } from '../../../..';
import { IListMessagesRequestV3 } from '../../../../faire/messenger/IListMessagesRequestV3';
import { IListMessagesResponseV3 } from '../../../../faire/messenger/IListMessagesResponseV3';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../types';

export const path = () => {
  return `/api/v3/messenger/list-messages`;
};

export const build = (
  data: IListMessagesRequestV3,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v3/messenger/list-messages';

/**
 * Product Area: MESSENGER_INFRASTRUCTURE
 */
export function execute(
  data: IListMessagesRequestV3
): Promise<IListMessagesResponseV3>;
export function execute(
  data: IListMessagesRequestV3,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IListMessagesResponseV3>>;
export function execute(
  data: IListMessagesRequestV3,
  options?: EndpointOptions
): Promise<IListMessagesResponseV3>;
export function execute(
  data: IListMessagesRequestV3,
  options?: EndpointOptions
): Promise<IListMessagesResponseV3 | SuccessResponse<IListMessagesResponseV3>> {
  const { route, ...requestOptions } = build(data, options);
  return request<IListMessagesResponseV3>(requestOptions);
}
execute[PATH] = 'api/v3/messenger/list-messages/post';
export default execute;
