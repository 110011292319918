import { getLocation } from "./globals/getLocation";

export const splitHostname = (
  hostname: string
):
  | { domain: string; type: string; subdomain: string | undefined }
  | undefined => {
  const urlParts = /([a-z-0-9]{2,63}).([a-z.]{2,5})$/.exec(hostname);
  if (!urlParts) {
    return;
  }
  const [, domain, type] = urlParts;
  const subdomain =
    hostname === "localhost"
      ? undefined
      : hostname.replace(`${domain}.${type}`, "").slice(0, -1);
  return {
    domain: domain ?? "",
    type: type ?? "",
    subdomain: !subdomain ? undefined : subdomain,
  };
};

/**
 * This function is used to mock the current origin on unit tests
 * @returns The current value for location.origin
 */
export const getLocationOrigin = () => getLocation()?.origin;
