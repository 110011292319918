"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const HalfMoon: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_SUMMER_MARKET_2022]
      .decorative7;
  return (
    <SVGIcon
      width="240"
      height="121"
      viewBox="0 0 240 121"
      fill="none"
      {...rest}
    >
      <path
        d="M0 121C0 54.1665 53.7188 0 120 0C186.281 0 240 54.1665 240 121H0Z"
        fill={iconColor}
      />
    </SVGIcon>
  );
};
