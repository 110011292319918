import { getNavigator } from "@faire/web--source/common/globals/getNavigator";

export const isFacebookBrowser = (): boolean =>
  !!getNavigator()?.userAgent.includes("FBAN") ||
  !!getNavigator()?.userAgent.includes("FBIOS") ||
  !!getNavigator()?.userAgent.includes("FB_IAB");

const isInstagramBrowser = (): boolean =>
  !!getNavigator()?.userAgent.includes("Instagram");

export const isSocialBrowser = () =>
  isFacebookBrowser() || isInstagramBrowser();
