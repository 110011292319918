import { FrontendSettingName } from "@faire/web--source/common/settings/declarations/ISettingSchema";
import { Storage } from "@faire/web--source/common/Storage";
import { SettingConst } from "@faire/web-api--source/types";

import { isWindowUndefined } from "./server-side-rendering/isWindowUndefined";

export const SETTING_OVERRIDES_LOCALSTORAGE_KEY = "PublicSettingsOverrides";

export const getSettingOverrideHeader = (): string | undefined => {
  const settingsOverrides = getOverrides();
  if (settingsOverrides) {
    const obj: Record<string, string | boolean | number> = {};
    const keys = Object.keys(settingsOverrides);
    if (keys.length) {
      keys.forEach((k) => {
        let val: any = settingsOverrides[k];
        if (typeof val === "string") {
          // This might be a string representing some other type, so
          // try parse it first to ensure it's not double-stringified.
          if (/[Tt]rue|[Ff]alse/.test(val)) {
            val = Boolean(val);
          } else if (/(\d|\.)+]/.test(val)) {
            val = parseFloat(val);
          } else {
            try {
              val = JSON.parse(val);
            } catch {
              // Fall through
            }
          }
        }
        obj[k] = JSON.stringify(val);
      });
      // X-IF-SETTING is a Map<string, string> where key is setting name
      // and value string is the JSON stringified value.
      return JSON.stringify(obj);
    }
  }
  return undefined;
};

let _overrides: Record<string, boolean | string | number> | undefined;

export const getOverrides = ():
  | Record<string, boolean | string | number>
  | undefined => {
  if (!_overrides) {
    if (isWindowUndefined()) {
      _overrides = {};
    } else {
      const overrides = Storage.local.getItem(
        SETTING_OVERRIDES_LOCALSTORAGE_KEY
      );
      if (overrides) {
        _overrides = JSON.parse(overrides);
      }
    }
  }
  return _overrides;
};

export const getOverride = (setting: SettingConst | FrontendSettingName) => {
  const overrides = getOverrides();
  if (overrides) {
    return overrides[setting];
  }
  return undefined;
};

export const clearOverrides = () => {
  _overrides = undefined;
  Storage.local.removeItem(SETTING_OVERRIDES_LOCALSTORAGE_KEY);
};

export const setOverride = (setting: string, value: any) => {
  const overrides = Storage.local.getItem(SETTING_OVERRIDES_LOCALSTORAGE_KEY);
  const settings = overrides ? JSON.parse(overrides) : {};
  if (value === undefined) {
    delete settings[setting];
  } else {
    settings[setting] = value;
  }

  _overrides = settings;
  Storage.local.setItem(
    SETTING_OVERRIDES_LOCALSTORAGE_KEY,
    JSON.stringify(settings)
  );
};
