/**
 * Please see [["images/optimizeImages"]]
 *
 * @deprecated
 * @packageDocumentation
 */
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";

import {
  IOptimizeImageOptions,
  optimizeImageUrl as importedOptimizeImageUrl,
} from "../images/optimizeImages";

/**
 * @hidden
 * @trackfunction
 * @deprecated import optimizeImageUrl from "images/optimizeImages" instead
 * Helper method only for hard coded urls @see optimizeImageUrl
 */
export namespace optimizeImageUrl {
  /** @deprecated use the original */
  export type IConfigProps = IOptimizeImageOptions;
}
/**
 * @hidden
 * @trackfunction
 */
export const optimizeImageUrl = (
  image: Partial<IImage>,
  isSSR: boolean,
  options: IOptimizeImageOptions & {
    /** @deprecated has no effect in fastly */
    gravity?: string;
  } = {}
): string | undefined => importedOptimizeImageUrl(image, isSSR, options);

export {
  setImageDPR,
  isTransformableImageUrl,
  optimizeProductImageUrl,
} from "../images/optimizeImages";

const optimizeImageUrlDefaultEmptyString = (
  isSSR: boolean,
  imgUrl?: string,
  options?: IOptimizeImageOptions & { gravity?: string }
): string =>
  imgUrl
    ? optimizeImageUrl({ url: imgUrl, tags: [] }, isSSR, options) || ""
    : "";

/**
 * @hidden
 * @trackfunction
 * @deprecated use optimizeImageUrl
 */
export const optimizeImageUrlString = (
  url: string,
  isSSR: boolean,
  options: IOptimizeImageOptions & { gravity?: string } = {}
) => optimizeImageUrl({ url, tags: [] }, isSSR, options);

/**
 * @hidden
 * @trackfunction
 * @deprecated use optimizeImageUrl
 */
export const opt = (isSSR: boolean, imgUrl?: string): string =>
  optimizeImageUrlDefaultEmptyString(isSSR, imgUrl);

/**
 * @hidden
 * @trackfunction
 * @deprecated use optimizeImageUrlFillSquare
 */
export const optFillSquare =
  (dimension: number) =>
  (isSSR: boolean, imgUrl?: string): string =>
    optimizeImageUrlDefaultEmptyString(isSSR, imgUrl, {
      width: dimension,
      height: dimension,
      resizeMethod: "fill",
      gravity: "auto",
    });

/**
 * @hidden
 * @trackfunction
 * @deprecated use optimizeImageUrlWidth
 */
export const optWidth =
  (width: number) =>
  (isSSR: boolean, imgUrl?: string): string =>
    optimizeImageUrlDefaultEmptyString(isSSR, imgUrl, {
      width,
    });

/**
 * @hidden
 * @trackfunction
 * @deprecated use optimizeImageUrlHeight
 */
export const optHeight =
  (height: number) =>
  (isSSR: boolean, imgUrl?: string): string =>
    optimizeImageUrlDefaultEmptyString(isSSR, imgUrl, {
      height,
    });
