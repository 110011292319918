export namespace DarkReadsEndpoints {
  export type POST = {
    apiBuyItAgainProductData: '/api/buy-it-again/product-data';
    apiDraftOrderretailerTokenGetRetailerDraftOrders: '/api/draft-order/:retailer_token/get-retailer-draft-orders';
    apiLayoutBrandPage: '/api/layout/brand-page';
    apiLayoutCategoryPage: '/api/layout/category-page';
    apiLayoutDiscoverPage: '/api/layout/discover-page';
    apiLayoutProductPage: '/api/layout/product-page';
    apiLayoutSearchPage: '/api/layout/search-page';
    apiLayoutSuppliersPage: '/api/layout/suppliers-page';
    apiRetailerFindBusinessAddress: '/api/retailer/find-business-address';
    apiRetailerretailerTokenRetailerRestrictions: '/api/retailer/:retailerToken/retailer-restrictions';
    apiRetailerretailerTokenFreeShippingStatus: '/api/retailer/:retailer_token/free-shipping-status';
    apiUserStateLookup: '/api/user/state-lookup';
    apiV2AddressesValidatePhoneNumber: '/api/v2/addresses/validate-phone-number';
    apiV2CartscartTokenCheckoutSummary: '/api/v2/carts/:cart_token/checkout-summary';
    apiV2CartscartTokenCheckoutSummaryPrecompute: '/api/v2/carts/:cart_token/checkout-summary/precompute';
    apiV2CartscartTokenSummary: '/api/v2/carts/:cart_token/summary';
    apiV2LayoutEnrichComponents: '/api/v2/layout/enrich-components';
    apiV2LayoutFilteredComponents: '/api/v2/layout/filtered-components';
    apiV2LayoutFilters: '/api/v2/layout/filters';
    apiV2ProductsSearchQuickAddProductTiles: '/api/v2/products/search/quick-add-product-tiles';
    apiV2CartscartTokenPaginated: '/api/v2/carts/:cartToken/paginated';
    apiV3LayoutFilters: '/api/v3/layout/filters';
    apiV3LayoutFilteredComponents: '/api/v3/layout/filtered-components';
    apiV2SearchProductsFromBrand: '/api/v2/search/products/from-brand';
    apiV2SearchSuggestions: '/api/v2/search/suggestions';
    apiV2SearchProductsCatNav: '/api/v2/search/products/cat-nav';
    apiV3SearchProducts: '/api/v3/search/products';
    apiV2SearchProductsCatNavFilters: '/api/v2/search/products/cat-nav/filters';
    apiV3SearchProductsFilters: '/api/v3/search/products/filters';
    apiV2SearchProductsFromBrandFilters: '/api/v2/search/products/from-brand/filters';
  };
  export type GET = {
    apiBrandIntegrationsbrandTokenApiAccessTokens: '/api/brand-integrations/:brand_token/api-access-tokens';
    apiBrandReferralsRetailerretailerTokenNominalReward: '/api/brand-referrals/retailer/:retailerToken/nominal-reward';
    apiBrandReferralsRetailerretailerTokenNonViewedReferralRewardCount: '/api/brand-referrals/retailer/:retailerToken/non-viewed-referral-reward-count';
    apiBrandViewbrandToken: '/api/brand-view/:brand_token';
    apiBrandReviewsbrandTokenSummary: '/api/brand/reviews/:brand_token/summary';
    apiBrandbrandToken: '/api/brand/:brand_token';
    apiBrandbrandTokenOnboardingInformation: '/api/brand/:brand_token/onboarding-information';
    apiBrandbrandTokenProductCollections: '/api/brand/:brand_token/product-collections';
    apiBrandbrandTokenPublishedProductCollectionMetadata: '/api/brand/:brand_token/published-product-collection-metadata';
    apiBrandbrandTokenShipmentPreferences: '/api/brand/:brand_token/shipment-preferences';
    apiDraftOrderV2RetailerDraftOrders: '/api/draft-order/v2/retailer-draft-orders';
    apiFirstOrderIncentivesFirstOrderIncentiveData: '/api/first-order-incentives/first-order-incentive-data';
    apiLayoutExplorePagefocusKeyword: '/api/layout/explore-page/:focus_keyword';
    apiMembershipEligibleInsiderMembershipDetails: '/api/membership/eligible-insider-membership-details';
    apiMembershipV2RetailerTokenAvailable: '/api/membership/v2/:retailer_token/available';
    apiMembershipcustomerTokenAvailable: '/api/membership/:customer_token/available';
    apiOrderorderToken: '/api/order/:order_token';
    apiProductSubscribedretailerTokenBackInStock: '/api/product/subscribed/:retailer_token/back-in-stock';
    apiProductVideosproductTokenForProduct: '/api/product/videos/:product_token/for-product';
    apiProductproductToken: '/api/product/:product_token';
    apiProductproductTokenBrandReviewsSummary: '/api/product/:product_token/brand-reviews-summary';
    apiProductproductTokenProductPromoMessages: '/api/product/:product_token/product-promo-messages';
    apiRecommendationsorderTokenProductsFromSimilarBrands: '/api/recommendations/:order_token/products-from-similar-brands';
    apiRetailerFindAddress: '/api/retailer/find-address';
    apiRetailerFindBusinesses: '/api/retailer/find-businesses';
    apiRetailerFindCountryState: '/api/retailer/find-country-state';
    apiRetailerIdvRequest: '/api/retailer/idv-request';
    apiRetailerInvoicesInfo: '/api/retailer/invoices-info';
    apiRetailerNonBrickAndMortarInitialTermsTier: '/api/retailer/non-brick-and-mortar-initial-terms-tier';
    apiRetailerRequiredIdentityVerificationFields: '/api/retailer/required-identity-verification-fields';
    apiRetailerShowAmexCreditBanner: '/api/retailer/show-amex-credit-banner';
    apiRetailerV2FindBusinesses: '/api/retailer/v2/find-businesses';
    apiRetailerV2IdvRequest: '/api/retailer/v2/idv-request';
    apiRetailerretailerToken: '/api/retailer/:retailer_token';
    apiRetailerretailerTokenCreditSummary: '/api/retailer/:retailer_token/credit-summary';
    apiRetailerretailerTokenEventSubscriptions: '/api/retailer/:retailer_token/event-subscriptions';
    apiRetailerretailerTokenInsiderSavingSummary: '/api/retailer/:retailer_token/insider-saving-summary';
    apiRetailerretailerTokenZipCodeProtectedBrands: '/api/retailer/:retailer_token/zip-code-protected-brands';
    apiSeoBrandExploreTermsbrandToken: '/api/seo/brand-explore-terms/:brand_token';
    apiSeoBrandTermsbrandToken: '/api/seo/brand-terms/:brand_token';
    apiSeoProductRelatedSearchesproductToken: '/api/seo/product-related-searches/:product_token';
    apiSeoPromotedBrands: '/api/seo/promoted-brands';
    apiSeoPromotedCategories: '/api/seo/promoted-categories';
    apiStyleQuiz: '/api/style-quiz';
    apiUser: '/api/user';
    apiUserAddresses: '/api/user/addresses';
    apiUserAnnualInventoryVolumeRanges: '/api/user/annual-inventory-volume-ranges';
    apiUserIncomeRanges: '/api/user/income-ranges';
    apiUserMilestonesuserTokenList: '/api/user/milestones/:user_token/list';
    apiUserSession: '/api/user/session';
    apiUserSessionEditingAsBrandToken: '/api/user/session/editing-as-brand-token';
    apiUseruserTokenLoginToken: '/api/user/:user_token/login-token';
    apiV1Orders: '/api/v1/orders';
    apiV1Products: '/api/v1/products';
    apiV2AddressesRegions: '/api/v2/addresses/regions';
    apiV2AddressesSchemas: '/api/v2/addresses/schemas';
    apiV2BrandsPromotionalEventCurrent: '/api/v2/brands/promotional-event/current';
    apiV2BrandsPromotionalEventFeatured: '/api/v2/brands/promotional-event/featured';
    apiV2CartscartTokenCheckoutSummary: '/api/v2/carts/:cart_token/checkout-summary';
    apiV2CartscartTokenSummary: '/api/v2/carts/:cart_token/summary';
    apiV2LayoutBrandPage: '/api/v2/layout/brand-page';
    apiV2LayoutBrowse: '/api/v2/layout/browse';
    apiV2LayoutDigestPage: '/api/v2/layout/digest-page';
    apiV2LayoutHome: '/api/v2/layout/home';
    apiV2LayoutProductPage: '/api/v2/layout/product-page';
    apiV2LayoutSearchPage: '/api/v2/layout/search-page';
    apiV2ProductproductToken: '/api/v2/product/:product_token';
    apiV2ProductproductTokenMoreFromBrand: '/api/v2/product/:product_token/more-from-brand';
    apiV3LayoutBrowsenavigationPathplacement: '/api/v3/layout/browse/:navigationPath/:placement';
    apiV2CartscartTokenBrands: '/api/v2/carts/:cartToken/brands';
    apiV2CartscartToken: '/api/v2/carts/:cartToken';
    apiV2CartscartTokenItemInventory: '/api/v2/carts/:cartToken/item-inventory';
    apiV2CartscartTokenBrandsWithTotals: '/api/v2/carts/:cartToken/brands-with-totals';
    apiV2CartscartTokenAbandonedBrandCartNudge: '/api/v2/carts/:cartToken/abandoned-brand-cart-nudge';
    apiV2SearchBrands: '/api/v2/search/brands';
    apiV2SearchbrandTokenFeaturedProducts: '/api/v2/search/:brand_token/featured-products';
  };
}

export const darkReadRoutes: {
  GET: ReadonlyArray<DarkReadsEndpoints.GET[keyof DarkReadsEndpoints.GET]>;
  POST: ReadonlyArray<DarkReadsEndpoints.POST[keyof DarkReadsEndpoints.POST]>;
} = {
  GET: [
    '/api/brand-integrations/:brand_token/api-access-tokens',
    '/api/brand-referrals/retailer/:retailerToken/nominal-reward',
    '/api/brand-referrals/retailer/:retailerToken/non-viewed-referral-reward-count',
    '/api/brand-view/:brand_token',
    '/api/brand/reviews/:brand_token/summary',
    '/api/brand/:brand_token',
    '/api/brand/:brand_token/onboarding-information',
    '/api/brand/:brand_token/product-collections',
    '/api/brand/:brand_token/published-product-collection-metadata',
    '/api/brand/:brand_token/shipment-preferences',
    '/api/draft-order/v2/retailer-draft-orders',
    '/api/first-order-incentives/first-order-incentive-data',
    '/api/layout/explore-page/:focus_keyword',
    '/api/membership/eligible-insider-membership-details',
    '/api/membership/v2/:retailer_token/available',
    '/api/membership/:customer_token/available',
    '/api/order/:order_token',
    '/api/product/subscribed/:retailer_token/back-in-stock',
    '/api/product/videos/:product_token/for-product',
    '/api/product/:product_token',
    '/api/product/:product_token/brand-reviews-summary',
    '/api/product/:product_token/product-promo-messages',
    '/api/recommendations/:order_token/products-from-similar-brands',
    '/api/retailer/find-address',
    '/api/retailer/find-businesses',
    '/api/retailer/find-country-state',
    '/api/retailer/idv-request',
    '/api/retailer/invoices-info',
    '/api/retailer/non-brick-and-mortar-initial-terms-tier',
    '/api/retailer/required-identity-verification-fields',
    '/api/retailer/show-amex-credit-banner',
    '/api/retailer/v2/find-businesses',
    '/api/retailer/v2/idv-request',
    '/api/retailer/:retailer_token',
    '/api/retailer/:retailer_token/credit-summary',
    '/api/retailer/:retailer_token/event-subscriptions',
    '/api/retailer/:retailer_token/insider-saving-summary',
    '/api/retailer/:retailer_token/zip-code-protected-brands',
    '/api/seo/brand-explore-terms/:brand_token',
    '/api/seo/brand-terms/:brand_token',
    '/api/seo/product-related-searches/:product_token',
    '/api/seo/promoted-brands',
    '/api/seo/promoted-categories',
    '/api/style-quiz',
    '/api/user',
    '/api/user/addresses',
    '/api/user/annual-inventory-volume-ranges',
    '/api/user/income-ranges',
    '/api/user/milestones/:user_token/list',
    '/api/user/session',
    '/api/user/session/editing-as-brand-token',
    '/api/user/:user_token/login-token',
    '/api/v1/orders',
    '/api/v1/products',
    '/api/v2/addresses/regions',
    '/api/v2/addresses/schemas',
    '/api/v2/brands/promotional-event/current',
    '/api/v2/brands/promotional-event/featured',
    '/api/v2/carts/:cart_token/checkout-summary',
    '/api/v2/carts/:cart_token/summary',
    '/api/v2/layout/brand-page',
    '/api/v2/layout/browse',
    '/api/v2/layout/digest-page',
    '/api/v2/layout/home',
    '/api/v2/layout/product-page',
    '/api/v2/layout/search-page',
    '/api/v2/product/:product_token',
    '/api/v2/product/:product_token/more-from-brand',
    '/api/v3/layout/browse/:navigationPath/:placement',
    '/api/v2/carts/:cartToken/brands',
    '/api/v2/carts/:cartToken',
    '/api/v2/carts/:cartToken/item-inventory',
    '/api/v2/carts/:cartToken/brands-with-totals',
    '/api/v2/carts/:cartToken/abandoned-brand-cart-nudge',
    '/api/v2/search/brands',
    '/api/v2/search/:brand_token/featured-products',
  ],
  POST: [
    '/api/buy-it-again/product-data',
    '/api/draft-order/:retailer_token/get-retailer-draft-orders',
    '/api/layout/brand-page',
    '/api/layout/category-page',
    '/api/layout/discover-page',
    '/api/layout/product-page',
    '/api/layout/search-page',
    '/api/layout/suppliers-page',
    '/api/retailer/find-business-address',
    '/api/retailer/:retailerToken/retailer-restrictions',
    '/api/retailer/:retailer_token/free-shipping-status',
    '/api/user/state-lookup',
    '/api/v2/addresses/validate-phone-number',
    '/api/v2/carts/:cart_token/checkout-summary',
    '/api/v2/carts/:cart_token/checkout-summary/precompute',
    '/api/v2/carts/:cart_token/summary',
    '/api/v2/layout/enrich-components',
    '/api/v2/layout/filtered-components',
    '/api/v2/layout/filters',
    '/api/v2/products/search/quick-add-product-tiles',
    '/api/v2/carts/:cartToken/paginated',
    '/api/v3/layout/filters',
    '/api/v3/layout/filtered-components',
    '/api/v2/search/products/from-brand',
    '/api/v2/search/suggestions',
    '/api/v2/search/products/cat-nav',
    '/api/v3/search/products',
    '/api/v2/search/products/cat-nav/filters',
    '/api/v3/search/products/filters',
    '/api/v2/search/products/from-brand/filters',
  ],
} as const;

export namespace DarkReadsOverrideLocaleHeaderEndpoints {
  export type POST = {
    apiBuyItAgainProductData: '/api/buy-it-again/product-data';
    apiV2ProductsSearchQuickAddProductTiles: '/api/v2/products/search/quick-add-product-tiles';
  };
  export type GET = {
    apiRecommendationsorderTokenProductsFromSimilarBrands: '/api/recommendations/:order_token/products-from-similar-brands';
  };
}

export const darkReadOverrideLocaleHeaderRoutes: {
  GET: ReadonlyArray<
    DarkReadsOverrideLocaleHeaderEndpoints.GET[keyof DarkReadsOverrideLocaleHeaderEndpoints.GET]
  >;
  POST: ReadonlyArray<
    DarkReadsOverrideLocaleHeaderEndpoints.POST[keyof DarkReadsOverrideLocaleHeaderEndpoints.POST]
  >;
} = {
  GET: ['/api/recommendations/:order_token/products-from-similar-brands'],
  POST: [
    '/api/buy-it-again/product-data',
    '/api/v2/products/search/quick-add-product-tiles',
  ],
} as const;
