import { ITimestamp } from '../../indigofair/rfc3339/ITimestamp';

export namespace ITimestampRange {
  /**
   * Return an instance of ITimestampRange with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<ITimestampRange>): ITimestampRange =>
    ({
      ...(partial ?? {}),
    }) as ITimestampRange;
}

/**
 * Inclusive Timestamp range.
 * If one boundary is not specified, it is considered all-inclusive (e.g. no startAt means
 * there is no minimum timestamp). At least one bound must be specified.
 */
export interface ITimestampRange {
  start_at: ITimestamp | null | undefined;
  end_at: ITimestamp | null | undefined;
}
