import { IUserEvent } from '../../indigofair/data/IUserEvent';

export namespace IPublishUserEventsRequest {
  /**
   * Return an instance of IPublishUserEventsRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IPublishUserEventsRequest>
  ): IPublishUserEventsRequest =>
    ({
      events: [],
      ...(partial ?? {}),
    }) as IPublishUserEventsRequest;
}

/**
 * TODO(Sean Benoit): Move this to inalytics_service.proto once /api/v2/events is out of backend
 */
export interface IPublishUserEventsRequest {
  events: Array<IUserEvent>;
  is_validation: boolean | undefined;
}
