import {
  createMediaQueryString,
  MIN_WIDTH_BREAKPOINTS,
  MinWidthBreakpoints,
} from "@faire/web--source/common/media";

export interface IMedia2Strings {
  minWidthBreakpoints: MinWidthBreakpoints;
  mobile: string;
  tablet: string;
  desktop: string;
  xlarge: string;
  xxlarge: string;
  tabletAndAbove: string;
  tabletAndBelow: string;
  desktopAndAbove: string;
  xLargeAndAbove: string;
  canHover: string;
}

export class Media2Strings implements IMedia2Strings {
  constructor(
    public readonly minWidthBreakpoints: MinWidthBreakpoints = MIN_WIDTH_BREAKPOINTS
  ) {}

  get responsiveBreakpoints() {
    return {
      mobile: { max: `${this.minWidthBreakpoints.TABLET - 1}px` },
      tablet: {
        min: `${this.minWidthBreakpoints.TABLET}px`,
        max: `${this.minWidthBreakpoints.DESKTOP - 1}px`,
      },
      desktop: {
        min: `${this.minWidthBreakpoints.DESKTOP}px`,
        max: `${this.minWidthBreakpoints.XLARGE - 1}px`,
      },
      xlarge: {
        min: `${this.minWidthBreakpoints.XLARGE}px`,
        max: `${this.minWidthBreakpoints.XXLARGE - 1}px`,
      },
      xxlarge: { min: `${this.minWidthBreakpoints.XXLARGE}px` },
      tabletAndBelow: {
        max: `${this.minWidthBreakpoints.DESKTOP - 1}px`,
      },
      tabletAndAbove: {
        min: `${this.minWidthBreakpoints.TABLET}px`,
      },
      desktopAndAbove: {
        min: `${this.minWidthBreakpoints.DESKTOP}px`,
      },
      xLargeAndAbove: {
        min: `${this.minWidthBreakpoints.XLARGE}px`,
      },
    } as const;
  }

  get mobile() {
    return createMediaQueryString(this.responsiveBreakpoints.mobile);
  }

  get tablet() {
    return createMediaQueryString(this.responsiveBreakpoints.tablet);
  }

  get desktop() {
    return createMediaQueryString(this.responsiveBreakpoints.desktop);
  }

  get xlarge() {
    return createMediaQueryString(this.responsiveBreakpoints.xlarge);
  }

  get xxlarge() {
    return createMediaQueryString(this.responsiveBreakpoints.xxlarge);
  }

  get tabletAndAbove() {
    return createMediaQueryString(this.responsiveBreakpoints.tabletAndAbove);
  }

  get tabletAndBelow() {
    return createMediaQueryString(this.responsiveBreakpoints.tabletAndBelow);
  }

  get desktopAndAbove() {
    return createMediaQueryString(this.responsiveBreakpoints.desktopAndAbove);
  }

  get xLargeAndAbove() {
    return createMediaQueryString(this.responsiveBreakpoints.xLargeAndAbove);
  }

  readonly canHover = `@media (hover: hover)`;
}
