import { Core } from "@faire/design-tokens";
import {
  EventColors,
  EventColorKey,
} from "@faire/web--source/ui/Events/EventColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";

export interface IEventsThemeColors {
  primaryDark: (typeof EventColors)[EventColorKey];
  primaryLight: (typeof EventColors)[EventColorKey];
  secondary: (typeof EventColors)[EventColorKey];
  badgeIcon: (typeof EventColors)[EventColorKey];
  promoText: (typeof EventColors)[EventColorKey];
  promoTextLight?: (typeof EventColors)[EventColorKey];
  promoTextInverse?: (typeof EventColors)[EventColorKey];
  lightBackground: (typeof EventColors)[EventColorKey];
  badgeBackground: (typeof EventColors)[EventColorKey];
  decorative1?: (typeof EventColors)[EventColorKey];
  decorative2?: (typeof EventColors)[EventColorKey];
  decorative3?: (typeof EventColors)[EventColorKey];
  decorative4?: (typeof EventColors)[EventColorKey];
  decorative5?: (typeof EventColors)[EventColorKey];
  decorative6?: (typeof EventColors)[EventColorKey];
  decorative7?: (typeof EventColors)[EventColorKey];
  decorative8?: (typeof EventColors)[EventColorKey];
}

type eventKeys = "faireMarket" | "faireFashionWeek" | PromotionalEventType;

type IEventsThemeColorsMap = {
  [event in eventKeys]?: IEventsThemeColors;
} & {
  /*
   * The following are legacy event types
   * We wanted to make the keys optional to avoid type issues going forward
   * Adding the legacy ones here to avoid current deprecated usages from throwing type errors
   * DO NOT ADD ANY MORE HERE
   */
  faireMarket: IEventsThemeColors;
  faireFashionWeek: IEventsThemeColors;
  [PromotionalEventType.PROMOTIONAL_EVENT_TYPE_UNKNOWN]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_MARKET_JANUARY_2024]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_MARKET_JULY_2023]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_WINTER_MARKET_2023]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_SUMMER_MARKET_2022]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_WINTER_MARKET_2022]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_FASHION_WEEK_V3]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_FASHION_WEEK_2022]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_FASHION_WEEK_2021]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_SUMMER_MARKET_2021]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_WINTER_MARKET_2021]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_SUMMER_MARKET_2020]: IEventsThemeColors;
  [PromotionalEventType.FAIRE_MARKET_JULY_2023]: IEventsThemeColors;
};

/**
 * @deprecated use getEventTheme to ensure a default fallback in case theme hasn't been defined
 */
export const eventsThemeColors: IEventsThemeColorsMap = {
  faireMarket: {
    primaryDark: EventColors.corePrimary_fsm24,
    primaryLight: EventColors.corePrimaryLight_fsm24,
    secondary: EventColors.coreSecondary_fsm24,
    badgeIcon: EventColors.coreBadgeIcon_fsm24,
    promoText: EventColors.textPrimary_fsm24,
    promoTextLight: EventColors.textPrimaryLight_fsm24,
    promoTextInverse: EventColors.textInverse_fsm24,
    lightBackground: EventColors.backgroundPrimary_fsm24,
    badgeBackground: EventColors.backgroundLight_fsm24,
    decorative1: EventColors.decorative1_fsm24,
    decorative2: EventColors.decorative2_fsm24,
    decorative3: EventColors.decorative3_fsm24,
    decorative4: EventColors.decorative4_fsm24,
    decorative5: EventColors.decorative5_fsm24,
    decorative6: EventColors.decorative6_fsm24,
  },
  faireFashionWeek: {
    primaryDark: Core.text.enabled,
    primaryLight: Core.text.inverse,
    secondary: EventColors.firefly,
    badgeIcon: Core.surface.enabled,
    promoText: Core.text.enabled,
    lightBackground: EventColors.electric,
    badgeBackground: EventColors.electric,
    decorative1: EventColors.electric,
    decorative2: EventColors.oat,
    decorative3: EventColors.clay,
    decorative4: EventColors.cedarGreen,
  },

  [PromotionalEventType.PROMOTIONAL_EVENT_TYPE_UNKNOWN]: {
    primaryDark: EventColors.plum_fwm22,
    primaryLight: EventColors.sugarPlum_fwm22,
    secondary: EventColors.jade_fwm22,
    badgeIcon: EventColors.richJade_fwm22,
    promoText: EventColors.jungle_fwm22,
    promoTextLight: EventColors.jungleLight_fwm22,
    lightBackground: EventColors.seaglass_fwm22,
    badgeBackground: Core.surface.inverse,
  },

  [PromotionalEventType.FAIRE_MARKET_JANUARY_2025]: {
    primaryDark: EventColors.corePrimary_fwm25,
    primaryLight: EventColors.corePrimaryLight_fwm25,
    secondary: EventColors.coreSecondary_fwm25,
    badgeIcon: EventColors.coreBadgeIcon_fwm25,
    promoText: EventColors.textPrimary_fwm25,
    promoTextLight: EventColors.textPrimaryLight_fwm25,
    promoTextInverse: EventColors.textInverse_fwm25,
    lightBackground: EventColors.backgroundPrimary_fwm25,
    badgeBackground: EventColors.backgroundLight_fwm25,
    decorative1: EventColors.decorative1_fwm25,
    decorative2: EventColors.decorative2_fwm25,
    decorative3: EventColors.decorative3_fwm25,
    decorative4: EventColors.decorative4_fwm25,
    decorative5: EventColors.decorative5_fwm25,
    decorative6: EventColors.decorative6_fwm25,
  },

  [PromotionalEventType.FAIRE_MARKET_JULY_2024]: {
    primaryDark: EventColors.corePrimary_fsm24,
    primaryLight: EventColors.corePrimaryLight_fsm24,
    secondary: EventColors.coreSecondary_fsm24,
    badgeIcon: EventColors.coreBadgeIcon_fsm24,
    promoText: EventColors.textPrimary_fsm24,
    promoTextLight: EventColors.textPrimaryLight_fsm24,
    promoTextInverse: EventColors.textInverse_fsm24,
    lightBackground: EventColors.backgroundPrimary_fsm24,
    badgeBackground: EventColors.backgroundLight_fsm24,
    decorative1: EventColors.decorative1_fsm24,
    decorative2: EventColors.decorative2_fsm24,
    decorative3: EventColors.decorative3_fsm24,
    decorative4: EventColors.decorative4_fsm24,
    decorative5: EventColors.decorative5_fsm24,
    decorative6: EventColors.decorative6_fsm24,
  },

  /**
   * Types below are unused but needed to ensure
   * PromotionalEventType as keys are not undefined
   */
  [PromotionalEventType.FAIRE_MARKET_JANUARY_2024]: {
    primaryDark: EventColors.corePrimary_fwm24,
    primaryLight: EventColors.corePrimaryLight_fwm24,
    secondary: EventColors.coreSecondary_fwm24,
    badgeIcon: EventColors.coreBadgeIcon_fwm24,
    promoText: EventColors.textPrimary_fwm24,
    promoTextLight: EventColors.textPrimaryLight_fwm24,
    promoTextInverse: EventColors.textInverse_fwm24,
    lightBackground: EventColors.backgroundPrimary_fwm24,
    badgeBackground: EventColors.backgroundLight_fwm24,
    decorative1: EventColors.decorative1_fwm24,
    decorative2: EventColors.decorative2_fwm24,
    decorative3: EventColors.decorative3_fwm24,
    decorative4: EventColors.decorative4_fwm24,
    decorative5: EventColors.decorative5_fwm24,
    decorative6: EventColors.decorative6_fwm24,
  },
  [PromotionalEventType.FAIRE_MARKET_JULY_2023]: {
    primaryDark: EventColors.corePrimary_fsm23,
    primaryLight: EventColors.corePrimaryLight_fsm23,
    secondary: EventColors.coreSecondary_fsm23,
    badgeIcon: EventColors.coreBadgeIcon_fsm23,
    promoText: EventColors.textPrimary_fsm23,
    promoTextLight: EventColors.textPrimaryLight_fsm23,
    promoTextInverse: EventColors.textInverse_fsm23,
    lightBackground: EventColors.backgroundPrimary_fsm23,
    badgeBackground: EventColors.backgroundLight_fsm23,
    decorative1: EventColors.decorative1_fsm23,
    decorative2: EventColors.decorative2_fsm23,
    decorative3: EventColors.decorative3_fsm23,
    decorative4: EventColors.decorative4_fsm23,
    decorative5: EventColors.decorative5_fsm23,
    decorative6: EventColors.decorative6_fsm23,
  },
  [PromotionalEventType.FAIRE_WINTER_MARKET_2023]: {
    primaryDark: EventColors.corePrimary_fwm23,
    primaryLight: EventColors.corePrimaryLight_fwm23,
    secondary: EventColors.coreSecondary_fwm23,
    badgeIcon: EventColors.coreBadgeIcon_fwm23,
    promoText: EventColors.textPrimary_fwm23,
    promoTextLight: EventColors.textPrimaryLight_fwm23,
    promoTextInverse: EventColors.textInverse_fwm23,
    lightBackground: EventColors.backgroundPrimary_fwm23,
    badgeBackground: EventColors.backgroundLight_fwm23,
    decorative1: EventColors.decorative1_fwm23,
    decorative2: EventColors.decorative2_fwm23,
    decorative3: EventColors.decorative3_fwm23,
    decorative4: EventColors.decorative4_fwm23,
    decorative5: EventColors.decorative5_fwm23,
    decorative6: EventColors.decorative6_fwm23,
  },
  [PromotionalEventType.FAIRE_SUMMER_MARKET_2022]: {
    primaryDark: EventColors.optimist_fsm22,
    primaryLight: EventColors.papaya_fsm22,
    secondary: EventColors.sunny_fsm22,
    badgeIcon: EventColors.superSunny_fsm22,
    promoText: EventColors.promoText_fsm22,
    promoTextLight: EventColors.promoTextLight_fsm22,
    lightBackground: EventColors.whiteSand_fsm22,
    badgeBackground: Core.surface.inverse,
    decorative1: EventColors.green_fsm22,
    decorative2: EventColors.jade_fsm22,
    decorative3: EventColors.cocoa_fsm22,
    decorative4: EventColors.ocean_fsm22,
    decorative5: EventColors.salmon_fsm22,
    decorative6: EventColors.cream_fsm22,
    decorative7: EventColors.rośe_fsm22,
    decorative8: EventColors.sky_fsm22,
  },
  [PromotionalEventType.FAIRE_WINTER_MARKET_2022]: {
    primaryDark: EventColors.plum_fwm22,
    primaryLight: EventColors.sugarPlum_fwm22,
    secondary: EventColors.jade_fwm22,
    badgeIcon: EventColors.richJade_fwm22,
    promoText: EventColors.jungle_fwm22,
    promoTextLight: EventColors.jungleLight_fwm22,
    lightBackground: EventColors.seaglass_fwm22,
    badgeBackground: Core.surface.inverse,
    decorative1: EventColors.rouge_fwm22,
    decorative2: EventColors.camel_fwm22,
    decorative3: EventColors.mauve_fwm22,
    decorative4: EventColors.cocoa_fwm22,
    decorative5: EventColors.ballet_fwm22,
    decorative6: EventColors.glacier_fwm22,
  },
  [PromotionalEventType.FAIRE_FASHION_WEEK_V3]: {
    primaryDark: Core.text.enabled,
    primaryLight: Core.text.default,
    secondary: EventColors.firefly,
    badgeIcon: Core.surface.enabled,
    promoText: Core.text.enabled,
    lightBackground: EventColors.periwinkle_light,
    badgeBackground: EventColors.perwinkle,
    decorative1: EventColors.aura_FFWV1,
    decorative2: EventColors.meadow,
  },
  [PromotionalEventType.FAIRE_FASHION_WEEK_2022]: {
    primaryDark: EventColors.plum_fwm22,
    primaryLight: EventColors.sugarPlum_fwm22,
    secondary: EventColors.jade_fwm22,
    badgeIcon: EventColors.richJade_fwm22,
    promoText: EventColors.jungle_fwm22,
    promoTextLight: EventColors.jungleLight_fwm22,
    lightBackground: EventColors.seaglass_fwm22,
    badgeBackground: Core.surface.inverse,
  },
  [PromotionalEventType.FAIRE_FASHION_WEEK_2021]: {
    primaryDark: EventColors.plum_fwm22,
    primaryLight: EventColors.sugarPlum_fwm22,
    secondary: EventColors.jade_fwm22,
    badgeIcon: EventColors.richJade_fwm22,
    promoText: EventColors.jungle_fwm22,
    promoTextLight: EventColors.jungleLight_fwm22,
    lightBackground: EventColors.seaglass_fwm22,
    badgeBackground: Core.surface.inverse,
  },
  [PromotionalEventType.FAIRE_SUMMER_MARKET_2021]: {
    primaryDark: EventColors.iris_fsm21,
    primaryLight: EventColors.sugarPlum_fwm22,
    secondary: EventColors.jade_fwm22,
    badgeIcon: EventColors.lilac_fsm21,
    promoText: EventColors.jungle_fwm22,
    promoTextLight: EventColors.jungleLight_fwm22,
    lightBackground: EventColors.seaglass_fwm22,
    badgeBackground: Core.surface.inverse,
  },
  [PromotionalEventType.FAIRE_WINTER_MARKET_2021]: {
    primaryDark: EventColors.pine_fwm21,
    primaryLight: EventColors.sugarPlum_fwm22,
    secondary: EventColors.jade_fwm22,
    badgeIcon: EventColors.badgeBlue_fwm21,
    promoText: EventColors.jungle_fwm22,
    promoTextLight: EventColors.jungleLight_fwm22,
    lightBackground: EventColors.seaglass_fwm22,
    badgeBackground: Core.surface.inverse,
  },
  [PromotionalEventType.FAIRE_SUMMER_MARKET_2020]: {
    primaryDark: EventColors.corePrimary_fsm20,
    primaryLight: EventColors.sugarPlum_fwm22,
    secondary: EventColors.jade_fwm22,
    badgeIcon: EventColors.coreBadgeIcon_fsm20,
    promoText: EventColors.jungle_fwm22,
    promoTextLight: EventColors.jungleLight_fwm22,
    lightBackground: EventColors.seaglass_fwm22,
    badgeBackground: Core.surface.inverse,
  },
};

export const getEventTheme = (key: eventKeys): IEventsThemeColors => {
  return eventsThemeColors[key] ?? eventsThemeColors.faireMarket;
};
