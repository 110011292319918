/**
 * Since this file is used in Vanilla Extract CSS, please limit the
 * dependency tree to only the bare minimum.
 */

export const SpacerMultipliers = {
  "0": 0,
  "1/4x": 0.25,
  "1/2x": 0.5,
  "3/4x": 0.75,
  "1x": 1,
  "2x": 2,
  "3x": 3,
  "4x": 4,
  "5x": 5,
  "6x": 6,
  "7x": 7,
  "8x": 8,
  "9x": 9,
  "10x": 10,
  "-1x": -1,
  "-2x": -2,
  "-3x": -3,
  "-4x": -4,
  "-5x": -5,
  "-6x": -6,
  "-7x": -7,
  "-8x": -8,
  "-9x": -9,
  "-10x": -10,
} as const;

export type SpacerMultipliersKey = keyof typeof SpacerMultipliers;

export type SpacerValueOrKey = number | SpacerMultipliersKey;

/**
 * Vanilla extract doesn't support Styled-Components theme provider.
 * Will hard code the default theme's spacing value here.
 *
 * We aren't actively using theme provider in our codebase, and we don't
 * change spacing value often. So, this should be fine.
 */
const SPACING = 8;

export const getSpacingValue = (valueOrKey: SpacerValueOrKey): number => {
  if (valueOrKey in SpacerMultipliers) {
    return SpacerMultipliers[valueOrKey as SpacerMultipliersKey] * SPACING;
  }
  return valueOrKey as number;
};

/**
 * Returns spacing in px. This function takes up to four params,
 * returning a string composed by those.
 *
 * @example
 * getSpacing("2x", 0, "2x", "3x")
 * // => "16px 0px 16px 24px"
 *
 * @param valueOrKey number or spacer value
 */
export const getSpacing = (
  valueOrKey1: SpacerValueOrKey,
  valueOrKey2?: SpacerValueOrKey,
  valueOrKey3?: SpacerValueOrKey,
  valueOrKey4?: SpacerValueOrKey
): string => {
  const pixelsOrNothing = (value?: SpacerValueOrKey): string =>
    value !== undefined ? ` ${getSpacingValue(value)}px` : "";
  const value1 = pixelsOrNothing(valueOrKey1).trim();
  const value2 = pixelsOrNothing(valueOrKey2);
  const value3 = pixelsOrNothing(valueOrKey3);
  const value4 = pixelsOrNothing(valueOrKey4);
  return `${value1}${value2}${value3}${value4}`;
};
