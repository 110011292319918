export namespace IAppPromotionBannerLinks {
  /**
   * Return an instance of IAppPromotionBannerLinks with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IAppPromotionBannerLinks>
  ): IAppPromotionBannerLinks =>
    ({
      ...(partial ?? {}),
    }) as IAppPromotionBannerLinks;
}

export interface IAppPromotionBannerLinks {
  app_store_link: string | undefined;
  play_store_link: string | undefined;
  ios_deep_link: string | undefined;
  android_deep_link: string | undefined;
}
