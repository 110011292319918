import { createContextStore } from "@faire/web--source/ui/hooks/ContextStore";

type AppSnackbarStore = {
  productTitle: string | undefined;
};

export const { useStore: useAppSnackbarStore, Provider: AppSnackbarProvider } =
  createContextStore<AppSnackbarStore>({
    productTitle: undefined,
  });
