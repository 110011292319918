import { request } from '../../../..';
import { IGetSearchProductsInfoResponse } from '../../../../indigofair/data/IGetSearchProductsInfoResponse';
import { IQueryProductsRequest } from '../../../../indigofair/data/IQueryProductsRequest';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../types';

export const path = () => {
  return `/api/v3/search/products`;
};

export const build = (
  data: IQueryProductsRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    trackClientSideLatency: true,
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v3/search/products';

/**
 * Product Area: SEARCH
 */
export function execute(
  data: IQueryProductsRequest
): Promise<IGetSearchProductsInfoResponse>;
export function execute(
  data: IQueryProductsRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetSearchProductsInfoResponse>>;
export function execute(
  data: IQueryProductsRequest,
  options?: EndpointOptions
): Promise<IGetSearchProductsInfoResponse>;
export function execute(
  data: IQueryProductsRequest,
  options?: EndpointOptions
): Promise<
  | IGetSearchProductsInfoResponse
  | SuccessResponse<IGetSearchProductsInfoResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<IGetSearchProductsInfoResponse>(requestOptions);
}
execute[PATH] = 'api/v3/search/products/post';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  data: IQueryProductsRequest
): Promise<IGetSearchProductsInfoResponse>;
export function darkRead(
  data: IQueryProductsRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetSearchProductsInfoResponse>>;
export function darkRead(
  data: IQueryProductsRequest,
  options?: EndpointOptions
): Promise<IGetSearchProductsInfoResponse>;
export function darkRead(
  data: IQueryProductsRequest,
  options?: EndpointOptions
): Promise<
  | IGetSearchProductsInfoResponse
  | SuccessResponse<IGetSearchProductsInfoResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<IGetSearchProductsInfoResponse>(requestOptions);
}
