import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import type { IRouteMetadata } from '../../../../Route';

/**
 * Generated from Jersey path "/retailer/{retailerToken}/cart"
 *
 * @deprecated Use /cart
 * Product Area: CART
 */
export const route = '/retailer/:retailerToken/cart';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/retailer/{retailerToken}/cart',
  productArea: 'CART',
  pod: 'FAIRE_POD_RETAILER_ORDER_PLACEMENT',
  pillar: 'FAIRE_PILLAR_RETAILER_TOOLS_AND_PROGRAMS',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

/**
 * @deprecated Use /cart
 */
export function path(retailerToken: string) {
  const compiledPath = toPath({
    retailerToken,
  });
  return compiledPath;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (retailerToken: string): string => {
  return faireBaseUrl(3000) + path(retailerToken);
};

export type PathParameters = {
  retailerToken: string;
};

export default path;
