import { getLocation } from "@faire/web--source/common/globals/getLocation";
import { getWindow } from "@faire/web--source/common/globals/getWindow";

type GroupName =
  | "faire-app-script"
  | "faire-app-asset"
  | "faire-api-request"
  | "no-timing-info"
  | "other";

interface ResourceSummary {
  encoded: number;
  decoded: number;
  transfer: number;
  count: number;
}

export const getGroupName = (
  url: URL,
  entry: PerformanceResourceTiming
): GroupName => {
  const location = getLocation();

  if (entry.requestStart === 0 && entry.responseStart === 0) {
    return "no-timing-info";
  } else if (isAppStaticCdn(url)) {
    if (
      url.pathname.startsWith("/static/js/") ||
      url.pathname.startsWith("/_next/static/chunks/")
    ) {
      return "faire-app-script";
    } else {
      return "faire-app-asset";
    }
  } else if (url.host === location?.host && url.pathname.startsWith("/api/")) {
    return "faire-api-request";
  } else {
    return "other";
  }
};

function isAppStaticCdn(url: URL): boolean {
  if (url.host === "cdn.faire.com" || url.host === "localhost:3000") {
    return (
      url.pathname.startsWith("/static/") ||
      url.pathname.startsWith("/_next/static/")
    );
  }
  return false;
}

export const collectInfoByGroup = (
  entries: PerformanceResourceTiming[] | undefined
): Record<string, ResourceSummary> => {
  if (!entries) {
    return {};
  }

  const groups = new Map<GroupName, ResourceSummary>();

  for (const entry of entries) {
    if (
      entry == null ||
      entry.name == null ||
      entry.decodedBodySize == null ||
      entry.encodedBodySize == null ||
      entry.transferSize == null
    ) {
      continue;
    }

    const url = new URL(entry.name);
    const groupName = getGroupName(url, entry);

    let group = groups.get(groupName);
    if (group == null) {
      group = {
        encoded: 0,
        decoded: 0,
        transfer: 0,
        count: 0,
      };
      groups.set(groupName, group);
    }

    group.decoded += entry.decodedBodySize;
    group.encoded += entry.encodedBodySize;
    group.transfer += entry.transferSize;
    group.count += 1;
  }

  return Object.fromEntries(groups.entries());
};

export const getInitialLoadResourceEntries = ():
  | PerformanceResourceTiming[]
  | undefined =>
  getWindow()?.performance?.getEntriesByType?.(
    "resource"
  ) as PerformanceResourceTiming[];

const roundToKb = (value: number): number => Math.round(value / 1024);

const createArraySummary = (summary: ResourceSummary | undefined) => {
  if (summary) {
    return [
      roundToKb(summary.encoded),
      roundToKb(summary.decoded),
      roundToKb(summary.transfer),
      summary.count,
    ];
  } else {
    return undefined;
  }
};

export const getPageWeightInfo = (entries: PerformanceResourceTiming[]) => {
  const groups = collectInfoByGroup(entries);
  return {
    appAsset: createArraySummary(groups["faire-app-asset"]),
    appScript: createArraySummary(groups["faire-app-script"]),
    apiRequest: createArraySummary(groups["faire-api-request"]),
  };
};
