import { logError } from "@faire/web--source/common/logging";
import { ILoginRequest } from "@faire/web-api--source/indigofair/data/ILoginRequest";

export const hCaptchaQueryKey = (widgetID: string | undefined) => [
  "hcaptcha-token",
  widgetID,
];

export const logHCaptchaError = (
  error: unknown,
  type: ILoginRequest.IHCaptchaData.HCaptchaError
) =>
  logError(error, {
    tags: { source: "hCaptcha", type },
  });
