"use client";

import styled from "styled-components";

interface IFormFields {
  $template: string[];
  $rowGap?: number;
  $columnGap?: number;
}

/**
 * @trackcomponent
 */
export const FormFields = styled.div<IFormFields>`
  display: grid;
  grid-template-areas: "${(props) => props.$template.join('"\n"')}";
  grid-gap: 30px 10px;
  grid-row-gap: ${(props) => props.$rowGap || 30}px;
  grid-column-gap: ${(props) => props.$columnGap || 10}px;
`;
