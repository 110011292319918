import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'frontend_apis-client_side_error-error';

/**
 * Since DD RUM samples errors, we use this PETE event to record all errors.
 */
export function trackFrontendApisClientSideErrorError(
  Error: string,
  Source2: string,
  Path3: string = '',
  Processing_Server4: string = ''
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: true,
    parameters: { Error, Source2, Path3, Processing_Server4 },
  });
}
