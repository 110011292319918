import { useRef, useCallback } from "react";

import {
  StoreMetadata,
  StoreSelector,
  AllFieldsMarker,
  ALL_FIELDS_MARKER,
} from "./types";

export const useStoreMetadata = <S extends object>(
  initialStore: S
): StoreMetadata<S> => {
  const store = useRef(initialStore);
  const subscribers = useRef<Map<keyof S, Set<() => void>>>(new Map());
  const globalSubscribers = useRef<Set<() => void>>(new Set());

  const get = useCallback(() => store.current, []);

  const set = useCallback((selector: StoreSelector<S>) => {
    const partialStore =
      typeof selector === "function" ? selector(store.current) : selector;

    const changedFields = (Object.keys(partialStore) as (keyof S)[]).filter(
      (field) => store.current[field] !== partialStore[field]
    );

    if (changedFields.length === 0) {
      return;
    }

    store.current = Object.assign({}, store.current, partialStore);

    // Notify consumers that use the changed fields
    changedFields.forEach((field) => {
      const fieldSubscribers = subscribers.current.get(field);
      if (fieldSubscribers) {
        fieldSubscribers.forEach((callback) => callback());
      }
    });

    globalSubscribers.current.forEach((callback) => callback());
  }, []);

  const subscribe = useCallback(
    (callback: () => void, fields: readonly (keyof S)[] | AllFieldsMarker) => {
      if (fields !== ALL_FIELDS_MARKER) {
        // Add the callback to the subscribers for each field
        fields.forEach((field) => {
          const fieldSubscribers = subscribers.current.get(field);
          if (fieldSubscribers) {
            fieldSubscribers.add(callback);
          } else {
            subscribers.current.set(field, new Set([callback]));
          }
        });
      } else {
        globalSubscribers.current.add(callback);
      }

      // Return a function to unsubscribe from all fields
      return () => {
        if (fields !== ALL_FIELDS_MARKER) {
          fields.forEach((field) => {
            const fieldSubscribers = subscribers.current.get(field);
            if (fieldSubscribers) {
              const deleted = fieldSubscribers.delete(callback);
              if (!deleted) {
                // eslint-disable-next-line no-console
                console.warn(
                  `useStore with fields ${fields} could not unsubscribe from field ${String(
                    field
                  )}`
                );
              }
            }
          });
        } else {
          globalSubscribers.current.delete(callback);
        }
      };
    },
    []
  );

  return {
    get,
    set,
    subscribe,
  };
};
