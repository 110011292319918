import { IOptimizeImageOptions } from "../optimizeImages";

import { MANUAL_IMAGE_CROP_TAG_PREFIX } from "./manualImageCropTagPrefix";

/**
 * Returns the image crop object from an array of image tags
 * Otherwise returns undefined.
 */
export const extractImageCropFromTag = (tags?: string[]) => {
  if (!tags) {
    return;
  }
  let imageCropTag: IOptimizeImageOptions["crop"];
  const manualCropTag = tags.find((tag) =>
    tag.startsWith(MANUAL_IMAGE_CROP_TAG_PREFIX)
  );
  if (manualCropTag) {
    imageCropTag = JSON.parse(
      manualCropTag.replace(MANUAL_IMAGE_CROP_TAG_PREFIX, "")
    );
  }
  return imageCropTag;
};
