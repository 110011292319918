import { IFaireMoney } from '../../indigofair/data/IFaireMoney';
import { IImage } from '../../indigofair/data/IImage';
import { IOffer } from '../../indigofair/data/IOffer';
import { IStyleQuiz } from '../../indigofair/data/IStyleQuiz';

export namespace IFDMarketplaceDiscountData {
  /**
   * Return an instance of IFDMarketplaceDiscountData with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IFDMarketplaceDiscountData>
  ): IFDMarketplaceDiscountData =>
    ({
      in_eligible_brand_tokens: [],
      ...(partial ?? {}),
    }) as IFDMarketplaceDiscountData;
}

export interface IFDMarketplaceDiscountData {
  is_retailer_eligible: boolean | undefined;
  expires_at: number | undefined;
  offer_name: keyof typeof IOffer.RetailerOfferName | undefined;
  discount_bps: number | undefined;
  max_discount_amount: IFaireMoney | undefined;
  /**
   * @protobufOption (.faire.common.tokenType) = BRAND
   */
  in_eligible_brand_tokens: Array<string>;
  modal_image_desktop: IImage | undefined;
  modal_image_mobile: IImage | undefined;
  store_type_for_modal_text:
    | keyof typeof IStyleQuiz.StyleQuizStoreType
    | undefined;
}
