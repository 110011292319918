import React from "react";

import { Flex, IFlexProps } from "./Flex";

/**
 * @trackcomponent
 */
export const DesktopRowMobileColumn = React.forwardRef<
  HTMLDivElement,
  IFlexProps
>((props, ref) => {
  return (
    <Flex
      {...props}
      ref={ref}
      direction={{ mobileAndAbove: "column", tabletAndAbove: "row" }}
    />
  );
});
