import { BrandMarketingEmailsPreference } from '../../indigofair/data/BrandMarketingEmailsPreference';
import { IFaireDirectSignupAttributionRequest } from '../../indigofair/data/IFaireDirectSignupAttributionRequest';
import { IFaireMoney } from '../../indigofair/data/IFaireMoney';
import { ILaunchWithFaireSignUpDetails } from '../../indigofair/data/ILaunchWithFaireSignUpDetails';
import { IMatchedBusiness } from '../../indigofair/data/IMatchedBusiness';
import { IPreSignUpActivity } from '../../indigofair/data/IPreSignUpActivity';
import { IResellerCertificate } from '../../indigofair/data/IResellerCertificate';
import { IRetailer } from '../../indigofair/data/IRetailer';
import { ISignUpAttribution } from '../../indigofair/data/ISignUpAttribution';
import { ISignUpReportedSalesMethod } from '../../indigofair/data/ISignUpReportedSalesMethod';
import { ISSOToken } from '../../indigofair/data/ISSOToken';
import { IStyleQuiz } from '../../indigofair/data/IStyleQuiz';
import { IUser } from '../../indigofair/data/IUser';
import { IBusinessIdentifier } from '../../indigofair/idv/IBusinessIdentifier';

export namespace ISignUpRequest {
  export namespace IAnnualSales {
    /**
     * Return an instance of IAnnualSales with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (partial?: Partial<IAnnualSales>): IAnnualSales =>
      ({
        ...(partial ?? {}),
      }) as IAnnualSales;
  }

  /**
   * The estimated annual sales for a retailer in dollars.
   */
  export interface IAnnualSales {
    /**
     * @deprecated
     */
    min_dollars: number | undefined;
    /**
     * @deprecated
     */
    max_dollars: number | undefined;
    min_amount: IFaireMoney | undefined;
    max_amount: IFaireMoney | undefined;
  }

  export namespace IAnnualInventoryVolume {
    /**
     * Return an instance of IAnnualInventoryVolume with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<IAnnualInventoryVolume>
    ): IAnnualInventoryVolume =>
      ({
        ...(partial ?? {}),
      }) as IAnnualInventoryVolume;
  }

  export interface IAnnualInventoryVolume {
    min_amount: IFaireMoney | undefined;
    max_amount: IFaireMoney | undefined;
  }

  /**
   * A user's preference for a certain type of email, such as marketing emails.
   */
  export enum EmailPreference {
    /**
     * We should send emails of a certain type to the user.
     */
    OPTED_IN = 'OPTED_IN',
    /**
     * We should not send emails of a certain type to the user.
     */
    OPTED_OUT = 'OPTED_OUT',
    /**
     * The user has not decided on a preference, we should prompt them at a later time.
     */
    UNDECIDED = 'UNDECIDED',
  }

  /**
   * A user's preference for receiving a certain type of text messages (sms)
   */
  export enum SmsPreference {
    SMS_PREFERENCE_UNKNOWN = 'SMS_PREFERENCE_UNKNOWN',
    SMS_PREFERENCE_OPTED_IN = 'SMS_PREFERENCE_OPTED_IN',
    SMS_PREFERENCE_OPTED_OUT = 'SMS_PREFERENCE_OPTED_OUT',
    SMS_PREFERENCE_UNDECIDED = 'SMS_PREFERENCE_UNDECIDED',
  }

  /**
   * A user's preference for their business platform
   */
  export enum BusinessPlatform {
    BUSINESS_PLATFORM_UNKNOWN = 'BUSINESS_PLATFORM_UNKNOWN',
    BRICK_AND_MORTAR = 'BRICK_AND_MORTAR',
    ONLINE_ONLY = 'ONLINE_ONLY',
    POP_UP = 'POP_UP',
    OTHER_PLATFORM = 'OTHER_PLATFORM',
  }

  export enum ShopifyBusinessPlatform {
    SHOPIFY_BUSINESS_PLATFORM_UNKNOWN = 'SHOPIFY_BUSINESS_PLATFORM_UNKNOWN',
    ONLINE_STORE = 'ONLINE_STORE',
    IN_PERSON = 'IN_PERSON',
    SOCIAL_MEDIA = 'SOCIAL_MEDIA',
    OTHER = 'OTHER',
  }

  export enum NumPhysicalStoresDescriptionOption {
    NUM_PHYSICAL_STORES_DESCRIPTION_OPTION_UNKNOWN = 'NUM_PHYSICAL_STORES_DESCRIPTION_OPTION_UNKNOWN',
    ONE = 'ONE',
    TWO = 'TWO',
    THREE = 'THREE',
    FOUR_OR_MORE = 'FOUR_OR_MORE',
  }

  /**
   * Redacted copy of ISignUpRequest (i.e. without redacted fields).
   */
  export type Redacted = Omit<ISignUpRequest, 'sso_token'>;

  /**
   * Return an instance of ISignUpRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<ISignUpRequest>): ISignUpRequest =>
    ({
      attributions: [],
      sales_methods: [],
      pre_sign_up_activities: [],
      business_platforms: [],
      ...(partial ?? {}),
    }) as ISignUpRequest;
}

export interface ISignUpRequest {
  user: IUser | undefined;
  matched_business: IMatchedBusiness | undefined;
  business_type: string | undefined;
  website_url: string | undefined;
  annual_sales: ISignUpRequest.IAnnualSales | undefined;
  reported_creation_year: number | undefined;
  reported_creation_time:
    | keyof typeof IRetailer.ReportedCreationTime
    | undefined;
  marketing_email_preference:
    | keyof typeof ISignUpRequest.EmailPreference
    | undefined;
  postal_code: string | undefined;
  attributions: Array<ISignUpAttribution>;
  /**
   * The widget token this sign up came from
   */
  widget_token: string | undefined;
  /**
   * The country of this retailer, in ISO-3
   */
  country: string | undefined;
  brand_marketing_email_preference:
    | keyof typeof BrandMarketingEmailsPreference
    | undefined;
  sales_methods: Array<ISignUpReportedSalesMethod>;
  /**
   * @deprecated
   */
  reseller_id: string | undefined;
  reseller_certificate: IResellerCertificate | undefined;
  store_opening_month: number | undefined;
  launch_with_faire_sign_up_details: ILaunchWithFaireSignUpDetails | undefined;
  annual_inventory_volume: ISignUpRequest.IAnnualInventoryVolume | undefined;
  style_quiz: IStyleQuiz | undefined;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  sso_token: ISSOToken | undefined; // redacted
  marketing_sms_preference:
    | keyof typeof ISignUpRequest.SmsPreference
    | undefined;
  pre_sign_up_activities: Array<IPreSignUpActivity>;
  business_platforms: Array<string>;
  legal_business_name: string | undefined;
  num_physical_stores_description:
    | keyof typeof ISignUpRequest.NumPhysicalStoresDescriptionOption
    | undefined;
  faire_direct_signup_attribution:
    | IFaireDirectSignupAttributionRequest
    | undefined;
  faire_direct_brand_alias: string | undefined;
  business_identifier: IBusinessIdentifier | undefined;
}
