export namespace IListRetailerAddressesRequest {
  export enum AddressFilter {
    ADDRESS_FILTER_UNKNOWN = 'ADDRESS_FILTER_UNKNOWN',
    /**
     * Not associated with a store or payment method.
     */
    UNASSIGNED_ADDRESSES = 'UNASSIGNED_ADDRESSES',
  }

  /**
   * Return an instance of IListRetailerAddressesRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IListRetailerAddressesRequest>
  ): IListRetailerAddressesRequest =>
    ({
      address_filters: [],
      ...(partial ?? {}),
    }) as IListRetailerAddressesRequest;
}

export interface IListRetailerAddressesRequest {
  /**
   * When this is empty, will return all shipping addresses regardless of their association.
   */
  address_filters: Array<
    keyof typeof IListRetailerAddressesRequest.AddressFilter
  >;
}
