import { setRequestHandler } from "@faire/web-api--source";
import { RequestOptions } from "@faire/web-api--source/types";

type Config = {
  requestHandler: (config: RequestOptions) => Promise<any>;
};

export const configure = (options: Config) => {
  setRequestHandler(options.requestHandler);
};
