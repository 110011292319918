import { returnFuncIfServer } from "@faire/web--source/common/globals/returnFuncIfServer";
import debounce from "lodash/debounce";

export function debounceIfPossible<T extends (...args: any) => any>(
  func: T,
  wait?: number,
  options?: Parameters<typeof debounce>[2]
): ReturnType<typeof debounce> | T {
  return returnFuncIfServer(func) || debounce(func, wait, options);
}
