import { getWindowOrThrow } from "@faire/web--source/common/globals/getWindow";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import type { History } from "history";

export class HistoryTiming {
  static get = singletonGetter(HistoryTiming);

  private _timeOfLastRouteChange: number = 0;

  initialize = (history: History) => {
    history.listen(() => {
      this._timeOfLastRouteChange = Math.round(
        getWindowOrThrow().performance.now()
      );
    });
  };

  get timeOfLastRouteChange() {
    return this._timeOfLastRouteChange;
  }

  get isInitialLoad() {
    return this._timeOfLastRouteChange === 0;
  }
}
