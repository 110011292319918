"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const Sun: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_SUMMER_MARKET_2022].badgeIcon;
  return (
    <SVGIcon
      width="123"
      height="123"
      viewBox="0 0 123 123"
      fill="none"
      {...rest}
    >
      <path
        d="M123 57.0574H78.051L116.989 34.5829L112.547 26.9171L73.6084 49.3916L96.0829 10.4533L88.3881 6.01062L65.9427 44.949V0H57.0574V44.949L34.5829 6.01062L26.8881 10.4533L49.3626 49.3916L10.4533 26.9171L6.01062 34.5829L44.92 57.0574H0V65.9426H44.92L6.01062 88.4171L10.4533 96.1119L49.3626 73.6374L26.8881 112.547L34.5829 116.989L57.0574 78.08V123H65.9427V78.08L88.3881 116.989L96.0829 112.547L73.6084 73.6374L112.547 96.1119L116.989 88.4171L78.051 65.9426H123V57.0574Z"
        fill={iconColor}
      />
    </SVGIcon>
  );
};
