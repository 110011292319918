import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey =
  'pricing-opening_order_incentives_view_offer_banner-view';

/**
 * Track when retailer views the Offer Banner for Opening Order Incentives
 */
export function trackPricingOpeningOrderIncentivesViewOfferBannerView(): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: true,
  });
}
