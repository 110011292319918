import { SettingConst } from '../../types';

/**
 * Whether to evaluate hCaptcha scores on frontend and backend. Should not block requests, even if enabled.
 */
export const ENABLE_HCAPTCHA_INTEGRATION_221129 =
  'ENABLE_HCAPTCHA_INTEGRATION_221129' as SettingConst<
    'ENABLE_HCAPTCHA_INTEGRATION_221129',
    boolean,
    'RETAILER'
  >;
