import { FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_ELIGIBLE_ROUTES } from "@faire/web-api--source/indigofair/settings/FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_ELIGIBLE_ROUTES";
import { route as c1CategoryRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId";
import { route as discoverRoute } from "@faire/web-api--source/routes/discover/searchTerm";
import { route as exploreRoute } from "@faire/web-api--source/routes/explore/searchTerm";
import { route as searchRoute } from "@faire/web-api--source/routes/search";
import { route as c1SuppliersRoute } from "@faire/web-api--source/routes/suppliers/c1SuppliersUrlId";

import { useSetting } from "./__internal__/useSetting";

const DEFAULT_ELIGIBLE_ROUTES = [
  c1SuppliersRoute,
  c1CategoryRoute,
  searchRoute,
  discoverRoute,
  exploreRoute,
];

export const useSettingFaireDirectRecurringMarketplaceEligibleRoutes =
  (): string[] =>
    useSetting(
      FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_ELIGIBLE_ROUTES,
      DEFAULT_ELIGIBLE_ROUTES
    );
