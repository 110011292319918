import { ITimestamp } from '../../indigofair/rfc3339/ITimestamp';

export namespace IPreSignUpActivity {
  export enum PreSignUpActivityType {
    PRE_SIGN_UP_ACTIVITY_TYPE_UNKNOWN = 'PRE_SIGN_UP_ACTIVITY_TYPE_UNKNOWN',
    LANDING = 'LANDING',
    SIGNUP = 'SIGNUP',
  }

  /**
   * Return an instance of IPreSignUpActivity with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IPreSignUpActivity>
  ): IPreSignUpActivity =>
    ({
      ...(partial ?? {}),
    }) as IPreSignUpActivity;
}

export interface IPreSignUpActivity {
  type: keyof typeof IPreSignUpActivity.PreSignUpActivityType | undefined;
  product_token: string | undefined;
  brand_token: string | undefined;
  search_term: string | undefined;
  category_path_identifier: string | undefined;
  created_at: ITimestamp | undefined;
}
