import { SECOND_PAGE_AUTO_MODAL_091824 } from "@faire/web-api--source/indigofair/settings/SECOND_PAGE_AUTO_MODAL_091824";

import {
  assignSetting,
  getSettingValueWithoutAssignment,
} from "@faire/retailer/settings/__internal__/getSetting";

export const getSettingSecondPageAutoModal = (): boolean =>
  getSettingValueWithoutAssignment(SECOND_PAGE_AUTO_MODAL_091824, false);

export const assignSettingSecondPageAutoModal = () =>
  assignSetting(SECOND_PAGE_AUTO_MODAL_091824);
