import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { IRetailerLaunchWithFaire as IOpenWithFaire } from "@faire/web-api--source/indigofair/data/IRetailerLaunchWithFaire";

import { useRetailerStore } from "@faire/retailer/stores/domain/RetailerStore";

export const useOpenWithFaireApplicationStatus = () => {
  const { retailer } = useRetailerStore(["retailer"]);
  const launchWithFaire = retailer?.launch_with_faire;
  const status = launchWithFaire?.status;

  const isEligible = status === IOpenWithFaire.Status.ELIGIBLE;
  const hasApplicationInProgress =
    status === IOpenWithFaire.Status.APPLICATION_IN_PROGRESS;
  const isOnHold =
    status === IOpenWithFaire.Status.ON_HOLD ||
    status === IOpenWithFaire.Status.ON_HOLD_NO_LEASE;

  const isNotEligibleForOwF =
    status === undefined || status === IOpenWithFaire.Status.NOT_ELIGIBLE;

  const showOwFR2RCopyToNotEligible =
    isNotEligibleForOwF && getGlobalProperty("owfReferralCodeV2") !== undefined;

  return {
    status,
    isOnHold,
    isEligible,
    isNotEligibleForOwF,
    showOwFR2RCopyToNotEligible,
    applicationType: launchWithFaire?.application_type,
    appliedAt: launchWithFaire?.applied_at,
    canApply: isEligible || hasApplicationInProgress,
    hasPendingOwfUwRequest: !!launchWithFaire?.has_pending_owf_uw_request,
    hasApplicationInProgress,
    hasApplicationPendingReview: status === IOpenWithFaire.Status.APPLIED,
    hasApplied: status === IOpenWithFaire.Status.APPLIED || isOnHold,
    isAccepted: status === IOpenWithFaire.Status.ACCEPTED,
    isOwFExpired: status === IOpenWithFaire.Status.EXPIRED,
    isRejectedForOwF: status === IOpenWithFaire.Status.REJECTED,
  };
};
