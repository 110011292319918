import { request } from '../..';
import { IGetPosLocationsForRetailerResponse } from '../../indigofair/retailers/pos/IGetPosLocationsForRetailerResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../types';

export const path = () => {
  return `/retailers-pos-api/pos-locations`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/retailers-pos-api/pos-locations';

/**
 * Product Area: RETAILER_POS_INTEGRATIONS
 */
export function execute(): Promise<IGetPosLocationsForRetailerResponse>;
export function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetPosLocationsForRetailerResponse>>;
export function execute(
  options?: EndpointOptions
): Promise<IGetPosLocationsForRetailerResponse>;
export function execute(
  options?: EndpointOptions
): Promise<
  | IGetPosLocationsForRetailerResponse
  | SuccessResponse<IGetPosLocationsForRetailerResponse>
> {
  const { route, ...requestOptions } = build(options);
  return request<IGetPosLocationsForRetailerResponse>(requestOptions);
}
execute[PATH] = 'retailers-pos-api/pos-locations/get';
export default execute;
