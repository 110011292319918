import { request } from '../../..';
import { IFDMarketplaceDiscountData } from '../../../indigofair/data/IFDMarketplaceDiscountData';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';

export const path = () => {
  return `/api/faire-direct-marketplace-discount/discount-data`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/faire-direct-marketplace-discount/discount-data';

/**
 * Product Area: FAIRE_DIRECT
 */
export function execute(): Promise<IFDMarketplaceDiscountData>;
export function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IFDMarketplaceDiscountData>>;
export function execute(
  options?: EndpointOptions
): Promise<IFDMarketplaceDiscountData>;
export function execute(
  options?: EndpointOptions
): Promise<
  IFDMarketplaceDiscountData | SuccessResponse<IFDMarketplaceDiscountData>
> {
  const { route, ...requestOptions } = build(options);
  return request<IFDMarketplaceDiscountData>(requestOptions);
}
execute[PATH] = 'api/faire-direct-marketplace-discount/discount-data/get';
export default execute;
