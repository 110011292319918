import { TypographyTheme, TypographyVariant } from "./Types";

const generateTypographyVariant = (
  fontFamily: TypographyVariant["font-family"],
  fontWeight: TypographyVariant["font-weight"],
  fontSize: TypographyVariant["font-size"],
  lineHeight: TypographyVariant["line-height"],
  letterSpacing: TypographyVariant["letter-spacing"]
) => {
  return {
    "font-family": fontFamily,
    "font-weight": fontWeight,
    "font-size": fontSize,
    "line-height": lineHeight,
    "letter-spacing": letterSpacing,
  };
};

const serif = "nantes, georgia, serif";
const sans = "graphik, helvetica, sans-serif";

const fonts: TypographyTheme["fonts"] = {
  displayXLSerifRegular: generateTypographyVariant(
    serif,
    400,
    "72px",
    "86px",
    0
  ),
  displayLSerifRegular: generateTypographyVariant(
    serif,
    400,
    "52px",
    "64px",
    0
  ),
  displayMSerifRegular: generateTypographyVariant(
    serif,
    400,
    "38px",
    "50px",
    0
  ),
  displaySSerifRegular: generateTypographyVariant(
    serif,
    400,
    "30px",
    "38px",
    0
  ),
  displaySSansRegular: generateTypographyVariant(
    sans,
    200,
    "30px",
    "38px",
    "0.15px"
  ),
  displaySSansSemiBold: generateTypographyVariant(
    sans,
    700,
    "30px",
    "38px",
    "0.15px"
  ),
  pageHeaderSerifRegular: generateTypographyVariant(
    serif,
    400,
    "22px",
    "32px",
    0
  ),
  sectionHeaderSansMedium: generateTypographyVariant(
    sans,
    400,
    "22px",
    "32px",
    "0.15px"
  ),
  subheadingSansMedium: generateTypographyVariant(
    sans,
    500,
    "18px",
    "26px",
    "0.15px"
  ),
  subheadingSansRegular: generateTypographyVariant(
    sans,
    200,
    "18px",
    "26px",
    "0.15px"
  ),
  paragraphSansMedium: generateTypographyVariant(
    sans,
    500,
    "14px",
    "20px",
    "0.15px"
  ),
  paragraphSansRegular: generateTypographyVariant(
    sans,
    200,
    "14px",
    "20px",
    "0.15px"
  ),
  labelSansRegular: generateTypographyVariant(
    sans,
    200,
    "12px",
    "16px",
    "0.15px"
  ),
  labelSansMedium: generateTypographyVariant(
    sans,
    500,
    "12px",
    "16px",
    "0.15px"
  ),
};

export const fontRamp: TypographyTheme = {
  fonts,
};
