import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";
import { RETAILER_AVAILABLE_LOCALES } from "@faire/web-api--source/indigofair/settings/RETAILER_AVAILABLE_LOCALES";

import { getSettingValueWithoutAssignment } from "@faire/retailer/settings/__internal__/getSetting";

export const getSettingRetailerAvailableLocales = (): ILocaleKey[] =>
  getSettingValueWithoutAssignment(RETAILER_AVAILABLE_LOCALES, [
    { language: Language.ENG, country: undefined },
  ]);
