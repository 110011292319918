import { ISearchMakerProductsRequest } from '../../indigofair/data/ISearchMakerProductsRequest';

export namespace ISearchBrandProductsFiltersRequest {
  /**
   * Return an instance of ISearchBrandProductsFiltersRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<ISearchBrandProductsFiltersRequest>
  ): ISearchBrandProductsFiltersRequest =>
    ({
      filter_keys: [],
      ...(partial ?? {}),
    }) as ISearchBrandProductsFiltersRequest;
}

export interface ISearchBrandProductsFiltersRequest {
  brand_token: string | undefined;
  query: string | undefined;
  filter_keys: Array<string>;
  client_size: keyof typeof ISearchMakerProductsRequest.ClientSize | undefined;
}
