import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import qs from 'query-string';
import type { IRouteMetadata } from '../../../Route';

export type QueryParameters = {
  fdb: string | undefined;
  name: string | undefined;
  phone_number: string | undefined;
  googlePlaceId: string | undefined;
  countryIso3: string | undefined;
  storeName: string | undefined;
  filters: string | undefined;
  sampleInvite: string | undefined;
  email: string | undefined;
  signIn: string | undefined;
  signUp: string | undefined;
  redirectTo: string | undefined;
  wsi: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;

  /**
   * Parse the given raw query string or partially-parsed react-router-dom queryParams object.
   */
  export function parse(
    search: string | { [K in keyof QueryParameters]?: string } = ''
  ): QueryParameters {
    const rawParsed =
      typeof search === 'string' ? qs.parse(search) : (search ?? {});
    const parsed: any = { ...rawParsed };

    return parsed as QueryParameters;
  }
}

/**
 * Generated from Jersey path "/rep/{salesRepCode}"
 *
 * Product Area: SALES_REPS
 */
export const route = '/rep/:salesRepCode';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/rep/{salesRepCode}',
  productArea: 'SALES_REPS',
  pod: 'FAIRE_POD_FAIRE_DIRECT_GROWTH',
  pillar: 'FAIRE_PILLAR_RETAILER_GROWTH',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path(
  salesRepCode: string,
  queryParams?: Partial<QueryParameters>
) {
  const compiledPath = toPath({
    salesRepCode,
  });
  const queryString = queryParams ? qs.stringify(queryParams) : '';
  return `${compiledPath}${queryString ? `?${queryString}` : ''}`;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (
  salesRepCode: string,
  queryParams?: Partial<QueryParameters>
): string => {
  return faireBaseUrl(3000) + path(salesRepCode, queryParams);
};

export type PathParameters = {
  salesRepCode: string;
};

export default path;
