export enum CountryGroup {
  EUROPEAN_UNION = 'EUROPEAN_UNION',
  /**
   * @deprecated
   */
  EUROZONE = 'EUROZONE',
  /**
   * This list will include all countries where Faire Wholesale B.V. (our european entity) currently operates in, as
   * well as future expansion targets in Europe. This should be preferred over EUROPE for setting purposes.
   * Excludes Great Britain, but includes Ireland.
   */
  FAIRELY_EUROPEAN_REGION = 'FAIRELY_EUROPEAN_REGION',
  /**
   * Continent of Africa and nearby Islands
   */
  AFRICA = 'AFRICA',
  ASIA = 'ASIA',
  OCEANIA = 'OCEANIA',
  SOUTH_AMERICA = 'SOUTH_AMERICA',
  EUROPE = 'EUROPE',
  NORTH_AMERICA = 'NORTH_AMERICA',
  ANTARCTICA = 'ANTARCTICA',
  REST_OF_EUROPE = 'REST_OF_EUROPE',
  /**
   * This list will include all countries in FAIRELY_EUROPEAN_REGION but not in EU and not UK, this is for the purpose
   * of EU consolidation feature.
   * For example: Norway and Switzerland
   */
  BRAND_FACING_EUROPE_OTHER = 'BRAND_FACING_EUROPE_OTHER',
  /**
   * This list will include Australia and New Zealand
   */
  ANZ = 'ANZ',
}
