import { StandardPropertiesHyphen } from "csstype";

export const cssFromObject = (object?: StandardPropertiesHyphen) =>
  typeof object === "object"
    ? Object.keys(object)
        .map(
          (key) => `${key}: ${object[key as keyof StandardPropertiesHyphen]};`
        )
        .join("\n")
    : "";
