import { makeObservable } from "@faire/web--source/common/makeObservable";
import { ResponsiveValues } from "@faire/web--source/slate/Theme/ResponsiveValues";
import { computed } from "mobx";

import { MIN_WIDTH_BREAKPOINTS } from "./media";
import { ObservableWindow } from "./ObservableWindow";

export type MinWidthBreakpoints = {
  readonly tablet: number;
  readonly desktop: number;
  readonly xlarge: number;
  readonly xxlarge?: number;
};

export class Viewport {
  private readonly minWidthBreakpoints: MinWidthBreakpoints = {
    tablet: MIN_WIDTH_BREAKPOINTS.TABLET,
    desktop: MIN_WIDTH_BREAKPOINTS.DESKTOP,
    xlarge: MIN_WIDTH_BREAKPOINTS.XLARGE,
    xxlarge: MIN_WIDTH_BREAKPOINTS.XXLARGE,
  };

  private observableWindow = new ObservableWindow();

  get width() {
    return this.observableWindow.width;
  }

  get height() {
    return this.observableWindow.height;
  }

  get scrollY() {
    return this.observableWindow.scrollY;
  }

  get lastScrolledDirection() {
    return this.observableWindow.lastScrolledDirection;
  }

  get isTouchDevice() {
    return this.observableWindow.isTouchDevice;
  }

  get isWindowFocused() {
    return this.observableWindow.isWindowFocused;
  }

  get isOnline() {
    return this.observableWindow.isOnline;
  }

  constructor() {
    makeObservable(this);
  }

  initialize = () => {
    this.observableWindow.initialize();
  };

  @computed
  get isMobile() {
    return this.width < this.minWidthBreakpoints.tablet;
  }

  @computed
  get isTablet() {
    return (
      this.minWidthBreakpoints.tablet <= this.width &&
      this.width < this.minWidthBreakpoints.desktop
    );
  }

  @computed
  get isDesktop() {
    return (
      this.minWidthBreakpoints.desktop <= this.width &&
      this.width < this.minWidthBreakpoints.xlarge
    );
  }

  @computed
  get isXLargeDesktop() {
    return (
      this.minWidthBreakpoints.xlarge <= this.width &&
      this.width <
        (this.minWidthBreakpoints.xxlarge ?? MIN_WIDTH_BREAKPOINTS.XXLARGE)
    );
  }

  @computed
  get isXXLargeDesktop() {
    return (
      this.width >=
      (this.minWidthBreakpoints.xxlarge ?? MIN_WIDTH_BREAKPOINTS.XXLARGE)
    );
  }

  @computed
  get isTabletAndAbove() {
    return this.width >= this.minWidthBreakpoints.tablet;
  }

  @computed
  get isDesktopAndAbove() {
    return this.width >= this.minWidthBreakpoints.desktop;
  }

  @computed
  get isXLargeAndAbove() {
    return this.width >= this.minWidthBreakpoints.xlarge;
  }

  @computed
  get isTabletAndBelow() {
    return !this.isDesktopAndAbove;
  }

  responsivelyReturnValue = <T>({
    mobileAndAbove,
    tabletAndAbove,
    desktopAndAbove,
    xLargeAndAbove,
    xxLargeDesktop,
  }: ResponsiveValues<T>): T => {
    if (this.isXXLargeDesktop) {
      return (
        xxLargeDesktop ??
        xLargeAndAbove ??
        desktopAndAbove ??
        tabletAndAbove ??
        mobileAndAbove
      );
    }
    if (this.isXLargeAndAbove) {
      return (
        xLargeAndAbove ?? desktopAndAbove ?? tabletAndAbove ?? mobileAndAbove
      );
    }
    if (this.isDesktopAndAbove) {
      return desktopAndAbove ?? tabletAndAbove ?? mobileAndAbove;
    }
    if (this.isTabletAndAbove) {
      return tabletAndAbove ?? mobileAndAbove;
    }
    return mobileAndAbove;
  };
}

let defaultViewport: Viewport;
export namespace Viewport {
  /**
   * @deprecated replace with useViewport
   * @trackfunction
   */
  export const getDefault = (): Viewport => {
    if (!defaultViewport) {
      defaultViewport = new Viewport();
      defaultViewport.initialize();
    }
    return defaultViewport;
  };
}
export default Viewport;
