import { request } from '../../..';
import { IFirstOrderIncentiveDiscountData } from '../../../indigofair/data/IFirstOrderIncentiveDiscountData';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';

export const path = () => {
  return `/api/first-order-incentives/first-order-incentive-data`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/first-order-incentives/first-order-incentive-data';

/**
 * Product Area: RETAILER_FIRST_ORDER_INCENTIVE
 */
export function execute(): Promise<IFirstOrderIncentiveDiscountData>;
export function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IFirstOrderIncentiveDiscountData>>;
export function execute(
  options?: EndpointOptions
): Promise<IFirstOrderIncentiveDiscountData>;
export function execute(
  options?: EndpointOptions
): Promise<
  | IFirstOrderIncentiveDiscountData
  | SuccessResponse<IFirstOrderIncentiveDiscountData>
> {
  const { route, ...requestOptions } = build(options);
  return request<IFirstOrderIncentiveDiscountData>(requestOptions);
}
execute[PATH] = 'api/first-order-incentives/first-order-incentive-data/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(): Promise<IFirstOrderIncentiveDiscountData>;
export function darkRead(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IFirstOrderIncentiveDiscountData>>;
export function darkRead(
  options?: EndpointOptions
): Promise<IFirstOrderIncentiveDiscountData>;
export function darkRead(
  options?: EndpointOptions
): Promise<
  | IFirstOrderIncentiveDiscountData
  | SuccessResponse<IFirstOrderIncentiveDiscountData>
> {
  const { route, ...requestOptions } = build(options);
  return request<IFirstOrderIncentiveDiscountData>(requestOptions);
}
