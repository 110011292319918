import { DefaultTheme as StyledComponentsTheme } from "styled-components";

import { DefaultTheme } from "./DefaultTheme";
import { ITheme } from "./Theme";

export * from "./Theme";
export { DefaultTheme } from "./DefaultTheme";

/**
 * @param theme The styled-components theme prop, which may or may not
 *     be plumbing an active ITheme.
 * @example ```jsx
 *   const myDiv = styled.div`
 *     background: ${({theme}) => themeOrDefault(theme).colors.primary.color};
 *   `;
 * ```
 */
export const themeOrDefault = (theme?: StyledComponentsTheme): ITheme => {
  // NOTE(luke): "unnecessary" cast is for at-loader's benefit.
  if ((theme as ITheme)?.name) {
    return theme as ITheme;
  }
  return DefaultTheme;
};

/**
 * @deprecated use mediaFromTheme
 * Gets the media2strings from the given theme, or grabs the default
 * theme and uses that. Using this function instead of only grabbing it from
 * the theme avoids the need to set a root ThemeProvider in tests.
 */
export const media2strings = (theme?: ITheme) =>
  themeOrDefault(theme).media2strings;
