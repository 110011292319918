import { emitDatadogError } from "@faire/web--source/common/datadog/rum";
import { isLocal } from "@faire/web--source/common/user-agent/isLocal";
import { isStaging } from "@faire/web--source/common/user-agent/isStaging";

export type IExceptionLevel = "fatal" | "error" | "warning" | "info";
export interface ILogExceptionOptions {
  // extra information about the error which will be visible in datadog
  data?: { [prop: string]: any };
  // all errors with the same set of fingerprints will be grouped together
  fingerprint?: string[];
  // automatically fingerprints the error using the error's name and message
  autoFingerprint?: boolean;
  // tags to attach to the issue/event
  tags?: Record<string, any>;
  // true when the exception is being logged from server component render. false otherwise.
  serverOnly?: boolean;
}

const logException =
  (level: IExceptionLevel) =>
  (error: unknown, options?: ILogExceptionOptions) => {
    if (options?.serverOnly) {
      // TODO: FD-176705 - log exceptions without the use of globals.
      // (we can't `getGlobalProperty` from server components)
      return;
    }
    const optionsWithLevel = {
      ...(options ?? {}),
      level,
    };
    // eslint-disable-next-line @faire-web/no-restricted-globals
    if (typeof window === "undefined") {
      import("@faire/web--source/common/serverLogging").then(
        ({ default: log }) => {
          if (level === "fatal" || level === "error") {
            log.error(error, optionsWithLevel);
          } else if (level === "warning") {
            log.warn(error, optionsWithLevel);
          } else {
            log.info(error, optionsWithLevel);
          }
        }
      );
    } else {
      emitDatadogError(error, optionsWithLevel);
    }
  };

export const logFatal = logException("fatal");
export const logError = logException("error");
export const logWarning = logException("warning");
export const logInfo = logException("info");

export const logAssert: (
  condition: boolean,
  message: string,
  options?: ILogExceptionOptions,
  reporter?: string
) => asserts condition = (
  condition: boolean,
  message: string,
  options?: ILogExceptionOptions,
  reporter?: string
): asserts condition => {
  if (!condition) {
    const msg = `${reporter ? `${reporter}: ` : ""}${message}`;
    const error = new Error(msg);
    logError(error, options ?? {});
    if (isLocal() || isStaging()) {
      throw error;
    }
  }
};
