import { request } from '../../../../..';
import { IGetCrmV3CustomerDetailsResponse } from '../../../../../indigofair/brand/crm/IGetCrmV3CustomerDetailsResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../../types';
import qs from 'query-string';

export type QueryParameters = {
  retailer_token: string | undefined;
  brand_customer_token: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (brandToken: string, queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/v3/crm/${brandToken}/customer_details${queryString}`;
};

export const build = (
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(brandToken),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v3/crm/:brand_token/customer_details';

/**
 * Product Area: BRAND_CRM
 */
export function execute(
  brandToken: string,
  queryParams?: QueryParameters
): Promise<IGetCrmV3CustomerDetailsResponse>;
export function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetCrmV3CustomerDetailsResponse>>;
export function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IGetCrmV3CustomerDetailsResponse>;
export function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  | IGetCrmV3CustomerDetailsResponse
  | SuccessResponse<IGetCrmV3CustomerDetailsResponse>
> {
  const { route, ...requestOptions } = build(brandToken, queryParams, options);
  return request<IGetCrmV3CustomerDetailsResponse>(requestOptions);
}
execute[PATH] = 'api/v3/crm/brandToken/customer_details/get';
export default execute;
