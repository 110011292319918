"use client";

import React from "react";

import { SVGIcon } from "../SVGIcon";
import { createSVGIcon } from "../util";

/**
 * @deprecated use Lock from foundation/Lock
 * @trackcomponent
 */
export const LockSquare = createSVGIcon(
  <>
    <rect y="6" width="12" height="8" fill="currentColor" />
    <rect
      x="2.5"
      y="0.5"
      width="7"
      height="13"
      rx="3.5"
      stroke="currentColor"
    />
  </>,
  "Lock",
  { viewBox: "0 0 12 14", fill: "none" } // This icon is decorative, keep titleAccess empty so it doesn't clutter the screenreader experience
);

/**
 * @deprecated use Lock from foundation/Lock
 * @trackcomponent
 */
export const LockCircle = createSVGIcon(
  <g fillRule="nonzero" fill="currentColor">
    <path d="M7 18.97c-3.805 0-6.89-3.14-6.89-7.012C.11 8.085 3.196 4.946 7 4.946c3.805 0 6.89 3.14 6.89 7.012-.004 3.871-3.087 7.009-6.89 7.012zM7 6.178c-3.136 0-5.679 2.588-5.679 5.78s2.543 5.78 5.679 5.78 5.679-2.588 5.679-5.78c-.003-3.19-2.544-5.777-5.679-5.78z" />
    <ellipse cx="7" cy="11.958" rx="1.301" ry="1.324" />
    <path d="M6.393 11.958h1.21v3.687h-1.21zM11.002 6.605H9.788v-3.34c0-1.104-1.26-2.006-2.806-2.006-1.545 0-2.802.89-2.802 2.007v3.34H2.97v-3.34c0-1.782 1.799-3.24 4.015-3.24 2.217 0 4.017 1.458 4.017 3.24v3.34z" />
  </g>,
  "Lock",
  { viewBox: "0 0 22 22" } // This icon is decorative, keep titleAccess empty so it doesn't clutter the screenreader experience
);

/**
 * @deprecated use Lock from foundation/Lock
 * @trackcomponent
 */
export const LockRoundBottom = createSVGIcon(
  <>
    <path
      d="M9.65217 18.526H5.34783C2.9441 18.526 1 16.537 1 14.0778V6.70007H14V14.0778C14 16.5307 12.0559 18.526 9.65217 18.526Z"
      stroke="currentcolor"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.27285 6.65561V5.32114C3.27285 2.9318 5.16726 1 7.49645 1C9.82565 1 11.7201 2.93816 11.7201 5.32114V6.65561"
      stroke="currentcolor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.50285 11.2435V15.7108"
      stroke="currentcolor"
      strokeMiterlimit="10"
    />
    <path
      d="M7.50344 11.7965C8.06945 11.7965 8.52829 11.327 8.52829 10.7479C8.52829 10.1689 8.06945 9.69943 7.50344 9.69943C6.93744 9.69943 6.4786 10.1689 6.4786 10.7479C6.4786 11.327 6.93744 11.7965 7.50344 11.7965Z"
      fill="currentcolor"
    />
  </>,
  "Lock",
  { viewBox: "0 0 15 20", fill: "none" } // This icon is decorative, keep titleAccess empty so it doesn't clutter the screenreader experience
);

/**
 * @deprecated use Lock from foundation/Lock
 * @trackcomponent
 */
export const LockFlatTop = createSVGIcon(
  <>
    <g clipPath="url(#prefix__clip0)">
      <path
        d="M16 1.755A1.714 1.714 0 0014.286.041H1.714A1.714 1.714 0 000 1.755v3.338a11.588 11.588 0 007.384 10.752c.396.152.836.152 1.232 0A11.588 11.588 0 0016 5.093V1.755zM9.286 7.982a.288.288 0 00-.143.247v2.098a1.143 1.143 0 01-2.286 0V8.229a.287.287 0 00-.143-.248 2.571 2.571 0 112.572 0z"
        fill="currentcolor"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="none" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </>,
  "Lock",
  { viewBox: "0 0 24 24", fill: "none" } // This icon is decorative, keep titleAccess empty so it doesn't clutter the screenreader experience
);

/**
 * @deprecated use Lock from foundation/Lock
 * @trackcomponent
 */
export const LockRoundedSquare = createSVGIcon(
  <>
    <path
      d="M1 11.667H19.6663V25.0001H1V11.667Z"
      stroke="#AAAAAA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33325 18.3339C8.33325 18.8644 8.54396 19.3731 8.91903 19.7481C9.29409 20.1232 9.80279 20.3339 10.3332 20.3339C10.8636 20.3339 11.3723 20.1232 11.7474 19.7481C12.1225 19.3731 12.3332 18.8644 12.3332 18.3339C12.3332 17.8035 12.1225 17.2948 11.7474 16.9198C11.3723 16.5447 10.8636 16.334 10.3332 16.334C9.80279 16.334 9.29409 16.5447 8.91903 16.9198C8.54396 17.2948 8.33325 17.8035 8.33325 18.3339Z"
      fill="#AAAAAA"
    />
    <path
      d="M3.66675 11.6665V7.66654C3.66675 5.89847 4.36911 4.20281 5.61933 2.95259C6.86955 1.70237 8.56522 1 10.3333 1C12.1014 1 13.797 1.70237 15.0473 2.95259C16.2975 4.20281 16.9998 5.89847 16.9998 7.66654V11.6665"
      stroke="#AAAAAA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  "Lock",
  { viewBox: "0 0 21 26", fill: "none" } // This icon is decorative, keep titleAccess empty so it doesn't clutter the screenreader experience
);

type LockType = "square" | "circle" | "roundBottom" | "flat" | "roundedSquare";

/**
 * @deprecated
 * @trackcomponent
 */
export const Lock = React.memo(
  React.forwardRef<
    SVGElement,
    SVGIcon.IProps & {
      lockType: LockType;
    }
  >(({ lockType, ...props }, ref) => {
    let SelectedLock = LockSquare;
    switch (lockType) {
      case "circle":
        SelectedLock = LockCircle;
        break;
      case "roundBottom":
        SelectedLock = LockRoundBottom;
        break;
      case "flat":
        SelectedLock = LockFlatTop;
        break;
      case "roundedSquare":
        SelectedLock = LockRoundedSquare;
        break;
      case "square":
      default:
        break;
    }
    return <SelectedLock ref={ref} {...props} />;
  })
);
