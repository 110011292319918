export enum CurrencyUnit {
  CURRENCY_UNIT_UNKNOWN = 'CURRENCY_UNIT_UNKNOWN',
  /**
   * Australian dollar
   */
  AUD = 'AUD',
  /**
   * Canadian dollar
   */
  CAD = 'CAD',
  /**
   * Swiss Franc
   */
  CHF = 'CHF',
  /**
   * Euro
   */
  EUR = 'EUR',
  /**
   * British pound
   */
  GBP = 'GBP',
  /**
   * Japanese Yen
   */
  JPY = 'JPY',
  /**
   * United States Dollar
   */
  USD = 'USD',
  /**
   * Danish krone
   */
  DKK = 'DKK',
  /**
   * Norwegian krone
   */
  NOK = 'NOK',
  /**
   * Swedish krona
   */
  SEK = 'SEK',
  /**
   * South Korean won
   */
  KRW = 'KRW',
  /**
   * Currency for Afghanistan (AFG)
   */
  AFN = 'AFN',
  /**
   * Currency for Albania (ALB)
   */
  ALL = 'ALL',
  /**
   * Currency for Algeria (DZA)
   */
  DZD = 'DZD',
  /**
   * Currency for Angola (AGO)
   */
  AOA = 'AOA',
  /**
   * Currency for Anguilla (AIA), Antigua and Barbuda (ATG), Dominica (DMA), Grenada (GRD), Montserrat (MSR), Saint
   * Kitts and Nevis (KNA), Saint Lucia (LCA), Saint Vincent and Grenadines (VCT)
   */
  XCD = 'XCD',
  /**
   * Currency for Argentina (ARG)
   */
  ARS = 'ARS',
  /**
   * Currency for Armenia (ARM)
   */
  AMD = 'AMD',
  /**
   * Currency for Aruba (ABW)
   */
  AWG = 'AWG',
  /**
   * Currency for Azerbaijan (AZE)
   */
  AZN = 'AZN',
  /**
   * Currency for Bahamas (BHS)
   */
  BSD = 'BSD',
  /**
   * Currency for Bahrain (BHR)
   */
  BHD = 'BHD',
  /**
   * Currency for Bangladesh (BGD)
   */
  BDT = 'BDT',
  /**
   * Currency for Barbados (BRB)
   */
  BBD = 'BBD',
  /**
   * Currency for Belarus (BLR)
   */
  BYN = 'BYN',
  /**
   * Currency for Belize (BLZ)
   */
  BZD = 'BZD',
  /**
   * Currency for Benin (BEN), Burkina Faso (BFA), Côte d’Ivoire (CIV), Guinea-Bissau (GNB), Mali (MLI), Niger (NER),
   * Senegal (SEN), Togo (TGO)
   */
  XOF = 'XOF',
  /**
   * Currency for Bermuda (BMU)
   */
  BMD = 'BMD',
  /**
   * Currency for Bhutan (BTN)
   */
  BTN = 'BTN',
  /**
   * Currency for Bolivia (BOL)
   */
  BOB = 'BOB',
  /**
   * Currency for Bosnia and Herzegovina (BIH)
   */
  BAM = 'BAM',
  /**
   * Currency for Botswana (BWA)
   */
  BWP = 'BWP',
  /**
   * Currency for Brazil (BRA)
   */
  BRL = 'BRL',
  /**
   * Currency for Brunei Darussalam (BRN)
   */
  BND = 'BND',
  /**
   * Currency for Bulgaria (BGR)
   */
  BGN = 'BGN',
  /**
   * Currency for Burundi (BDI)
   */
  BIF = 'BIF',
  /**
   * Currency for Cambodia (KHM)
   */
  KHR = 'KHR',
  /**
   * Currency for Cameroon (CMR), Central African Republic (CAF), Chad (TCD), Republic of the Congo (COG),
   * Equatorial Guinea (GNQ), Gabon (GAB)
   */
  XAF = 'XAF',
  /**
   * Currency for Cape Verde (CPV)
   */
  CVE = 'CVE',
  /**
   * Currency for Cayman Islands (CYM)
   */
  KYD = 'KYD',
  /**
   * Currency for Chile (CHL)
   */
  CLP = 'CLP',
  /**
   * Currency for China (CHN) - Chinese Yuan
   */
  CNY = 'CNY',
  /**
   * Currency for Hong Kong (HKG)
   */
  HKD = 'HKD',
  /**
   * Currency for Macao (MAC)
   */
  MOP = 'MOP',
  /**
   * Currency for Colombia (COL)
   */
  COP = 'COP',
  /**
   * Currency for Comoros (COM)
   */
  KMF = 'KMF',
  /**
   * Currency for Democratic Republic of the Congo (COD)
   */
  CDF = 'CDF',
  /**
   * Currency for Cook Islands (COK), New Zealand (NZL), Niue (NIU), Pitcairn (PCN), Tokelau (TKL)
   */
  NZD = 'NZD',
  /**
   * Currency for Costa Rica (CRI)
   */
  CRC = 'CRC',
  /**
   * TODO (liam.mesrefoglu): Remove when safe to do so.
   * Currency for Croatia (HRV)
   * @deprecated
   */
  HRK = 'HRK',
  /**
   * Currency for Cuba (CUB)
   */
  CUP = 'CUP',
  /**
   * Currency for Curacao (CUW), Sint Maarten (SXM)
   */
  ANG = 'ANG',
  /**
   * Currency for Czech Republic (CZE)
   */
  CZK = 'CZK',
  /**
   * Currency for Djibouti (DJI)
   */
  DJF = 'DJF',
  /**
   * Currency for Dominican Republic (DOM)
   */
  DOP = 'DOP',
  /**
   * Currency for Egypt (EGY)
   */
  EGP = 'EGP',
  /**
   * Currency for El Salvador (SLV)
   */
  SVC = 'SVC',
  /**
   * Currency for Eritrea (ERI)
   */
  ERN = 'ERN',
  /**
   * Currency for Ethiopia (ETH)
   */
  ETB = 'ETB',
  /**
   * Currency for Falkland Islands (FLK), South Georgia and the South Sandwich Islands (SGS)
   */
  FKP = 'FKP',
  /**
   * Currency for Fiji (FJI)
   */
  FJD = 'FJD',
  /**
   * Currency for French Polynesia (PYF), New Caledonia (NCL), Wallis and Futuna Islands (WLF)
   */
  XPF = 'XPF',
  /**
   * Currency for Gambia (GMB)
   */
  GMD = 'GMD',
  /**
   * Currency for Georgia (GEO)
   */
  GEL = 'GEL',
  /**
   * Currency for Ghana (GHA)
   */
  GHS = 'GHS',
  /**
   * Currency for Gibraltar (GIB)
   */
  GIP = 'GIP',
  /**
   * Currency for Guatemala (GTM)
   */
  GTQ = 'GTQ',
  /**
   * Currency for Guinea (GIN)
   */
  GNF = 'GNF',
  /**
   * Currency for Guyana (GUY)
   */
  GYD = 'GYD',
  /**
   * Currency for Haiti (HTI)
   */
  HTG = 'HTG',
  /**
   * Currency for Honduras (HND)
   */
  HNL = 'HNL',
  /**
   * Currency for Hungary (HUN)
   */
  HUF = 'HUF',
  /**
   * Currency for Iceland (ISL)
   */
  ISK = 'ISK',
  /**
   * Currency for India (IND)
   */
  INR = 'INR',
  /**
   * Currency for Indonesia (IDN)
   */
  IDR = 'IDR',
  /**
   * Currency for Iran, Islamic Republic of (IRN)
   */
  IRR = 'IRR',
  /**
   * Currency for Iraq (IRQ)
   */
  IQD = 'IQD',
  /**
   * Currency for Israel (ISR), Palestine (PSE)
   */
  ILS = 'ILS',
  /**
   * Currency for Jamaica (JAM)
   */
  JMD = 'JMD',
  /**
   * Currency for Jordan (JOR)
   */
  JOD = 'JOD',
  /**
   * Currency for Kazakhstan (KAZ)
   */
  KZT = 'KZT',
  /**
   * Currency for Kenya (KEN)
   */
  KES = 'KES',
  /**
   * Currency for North Korea (PRK)
   */
  KPW = 'KPW',
  /**
   * Currency for Kuwait (KWT)
   */
  KWD = 'KWD',
  /**
   * Currency for Kyrgyzstan (KGZ)
   */
  KGS = 'KGS',
  /**
   * Currency for Laos (LAO)
   */
  LAK = 'LAK',
  /**
   * Currency for Lebanon (LBN)
   */
  LBP = 'LBP',
  /**
   * Currency for Lesotho (LSO)
   */
  LSL = 'LSL',
  /**
   * Currency for Liberia (LBR)
   */
  LRD = 'LRD',
  /**
   * Currency for Libya (LBY)
   */
  LYD = 'LYD',
  /**
   * Currency for Macedonia (MKD)
   */
  MKD = 'MKD',
  /**
   * Currency for Madagascar (MDG)
   */
  MGA = 'MGA',
  /**
   * Currency for Malawi (MWI)
   */
  MWK = 'MWK',
  /**
   * Currency for Malaysia (MYS)
   */
  MYR = 'MYR',
  /**
   * Currency for Maldives (MDV)
   */
  MVR = 'MVR',
  /**
   * Currency for Mauritania (MRT)
   */
  MRU = 'MRU',
  /**
   * Currency for Mauritius (MUS)
   */
  MUR = 'MUR',
  /**
   * Currency for Mexico (MEX)
   */
  MXN = 'MXN',
  /**
   * Currency for Moldova (MDA)
   */
  MDL = 'MDL',
  /**
   * Currency for Mongolia (MNG)
   */
  MNT = 'MNT',
  /**
   * Currency for Morocco (MAR), Western Sahara (ESH)
   */
  MAD = 'MAD',
  /**
   * Currency for Mozambique (MOZ)
   */
  MZN = 'MZN',
  /**
   * Currency for Myanmar (MMR)
   */
  MMK = 'MMK',
  /**
   * Currency for Namibia (NAM)
   */
  NAD = 'NAD',
  /**
   * Currency for Nepal (NPL)
   */
  NPR = 'NPR',
  /**
   * Currency for Nicaragua (NIC)
   */
  NIO = 'NIO',
  /**
   * Currency for Nigeria (NGA)
   */
  NGN = 'NGN',
  /**
   * Currency for Oman (OMN)
   */
  OMR = 'OMR',
  /**
   * Currency for Pakistan (PAK)
   */
  PKR = 'PKR',
  /**
   * Currency for Panama (PAN)
   */
  PAB = 'PAB',
  /**
   * Currency for Papua New Guinea (PNG)
   */
  PGK = 'PGK',
  /**
   * Currency for Paraguay (PRY)
   */
  PYG = 'PYG',
  /**
   * Currency for Peru (PER)
   */
  PEN = 'PEN',
  /**
   * Currency for Philippines (PHL)
   */
  PHP = 'PHP',
  /**
   * Currency for Poland (POL)
   */
  PLN = 'PLN',
  /**
   * Currency for Qatar (QAT)
   */
  QAR = 'QAR',
  /**
   * Currency for Romania (ROU)
   */
  RON = 'RON',
  /**
   * Currency for Russia (RUS)
   */
  RUB = 'RUB',
  /**
   * Currency for Rwanda (RWA)
   */
  RWF = 'RWF',
  /**
   * Currency for Saint Helena (SHN)
   */
  SHP = 'SHP',
  /**
   * Currency for Samoa (WSM)
   */
  WST = 'WST',
  /**
   * Currency for Sao Tome and Principe (STP)
   */
  STN = 'STN',
  /**
   * Currency for Saudi Arabia (SAU)
   */
  SAR = 'SAR',
  /**
   * Currency for Serbia (SRB)
   */
  RSD = 'RSD',
  /**
   * Currency for Seychelles (SYC)
   */
  SCR = 'SCR',
  /**
   * Currency for Sierra Leone (SLE)
   */
  SLL = 'SLL',
  /**
   * Currency for Singapore (SGP)
   */
  SGD = 'SGD',
  /**
   * Currency for Solomon Islands (SLB)
   */
  SBD = 'SBD',
  /**
   * Currency for Somalia (SOM)
   */
  SOS = 'SOS',
  /**
   * Currency for South Africa (ZAF)
   */
  ZAR = 'ZAR',
  /**
   * Currency for South Sudan (SSD)
   */
  SSP = 'SSP',
  /**
   * Currency for Sri Lanka (LKA)
   */
  LKR = 'LKR',
  /**
   * Currency for Sudan (SDN)
   */
  SDG = 'SDG',
  /**
   * Currency for Suriname (SUR)
   */
  SRD = 'SRD',
  /**
   * Currency for Eswatini (SWZ)
   */
  SZL = 'SZL',
  /**
   * Currency for Syria (SYR)
   */
  SYP = 'SYP',
  /**
   * Currency for Taiwan (TWN)
   */
  TWD = 'TWD',
  /**
   * Currency for Tajikistan (TJK)
   */
  TJS = 'TJS',
  /**
   * Currency for Tanzania, United Republic of (TZA)
   */
  TZS = 'TZS',
  /**
   * Currency for Thailand (THA)
   */
  THB = 'THB',
  /**
   * Currency for Tonga (TON)
   */
  TOP = 'TOP',
  /**
   * Currency for Trinidad and Tobago (TTO)
   */
  TTD = 'TTD',
  /**
   * Currency for Tunisia (TUN)
   */
  TND = 'TND',
  /**
   * Currency for Turkey (TUR)
   */
  TRY = 'TRY',
  /**
   * Currency for Turkmenistan (TKM)
   */
  TMT = 'TMT',
  /**
   * Currency for Uganda (UGA)
   */
  UGX = 'UGX',
  /**
   * Currency for Ukraine (UKR)
   */
  UAH = 'UAH',
  /**
   * Currency for United Arab Emirates (ARE)
   */
  AED = 'AED',
  /**
   * Currency for Uruguay (URY)
   */
  UYU = 'UYU',
  /**
   * Currency for Uzbekistan (UZB)
   */
  UZS = 'UZS',
  /**
   * Currency for Vanuatu (VUT)
   */
  VUV = 'VUV',
  /**
   * Currency for Venezuela (VEN)
   */
  VES = 'VES',
  /**
   * Currency for Vietnam (VNM)
   */
  VND = 'VND',
  /**
   * Currency for Yemen (YEM)
   */
  YER = 'YER',
  /**
   * Currency for Zambia (ZMB)
   */
  ZMW = 'ZMW',
  /**
   * Currency for Zimbabwe (ZWE)
   */
  ZWL = 'ZWL',
  /**
   * Currency for China (CNH) - Chinese Rinmembi
   */
  RMB = 'RMB',
}
