import { TrackedEvent } from "@faire/web-api--source/types";
import { hotjar } from "react-hotjar";

export const hotjarInterceptor = (params: TrackedEvent) => {
  if (params.bypassSessionRecording) {
    // Do not pass event to Hotjar if bypassing session recording
    return;
  }
  try {
    hotjar.event(params.eventKey);
  } catch (_) {
    /* ignore */
  }
};
