import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'signup-open_modal-click';

/**
 * Event to track clicks to all signup modal entry-points
 */
export function trackSignupOpenModalClick(
  SignUpType: string,
  PageURL2: string,
  SourceID3: string,
  Element4: string = ''
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: { SignUpType, PageURL2, SourceID3, Element4 },
  });
}
