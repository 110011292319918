import { SettingConst } from '../../types';

/**
 * end time of UK_EU_BRANDS_FREE_SHIPPING
 */
export const UK_EU_BRANDS_FREE_SHIPPING_END_MILLIS =
  'UK_EU_BRANDS_FREE_SHIPPING_END_MILLIS' as SettingConst<
    'UK_EU_BRANDS_FREE_SHIPPING_END_MILLIS',
    number,
    'LOGGED_IN_RETAILER'
  >;
