let idCounter = 0;

/**
 * @deprecated use useUniqueId instead
 */
export const uniqueId = (prefix: string) => {
  const id = ++idCounter;
  return `${prefix}${id}`;
};

export const disposePreviousCounter = () => {
  idCounter = 0;
};
