"use client";

import { buildResponsiveCss } from "@faire/web--source/slate/Theme/ResponsiveCss";
import { TypographyStyles } from "@faire/web--source/slate/Typography";
import { cssFromObject } from "@faire/web--source/ui/lib/styleUtils";
import { css } from "styled-components";

import { buttonTheme } from "./theme";
import {
  ButtonStateType,
  ButtonVariantTheme,
  CSSObject,
  StyledButtonProps,
} from "./types";

export const cssFromVariantTheme = (
  variantTheme: ButtonVariantTheme,
  stateKey: keyof ButtonVariantTheme,
  buttonProps: StyledButtonProps
) => {
  const variantStateTheme = variantTheme[stateKey];
  const evaluatedCssObject =
    typeof variantStateTheme === "function"
      ? variantStateTheme(buttonProps)
      : variantStateTheme;
  return cssFromObject(evaluatedCssObject);
};

export const buttonChildrenColorFromVariantTheme = (
  variantTheme: ButtonVariantTheme,
  stateKey: keyof ButtonVariantTheme,
  buttonProps: StyledButtonProps
) => {
  const variantStateTheme = variantTheme[stateKey];
  const evaluatedCssObject =
    typeof variantStateTheme === "function"
      ? variantStateTheme(buttonProps)
      : variantStateTheme;

  return evaluatedCssObject?.color;
};

const makeOverriddenColorTheme = (
  theme: ButtonVariantTheme,
  color: string,
  buttonProps: StyledButtonProps
): ButtonVariantTheme => {
  const overridePart = (themePart: ButtonStateType): CSSObject => {
    const evaluatedTheme =
      typeof themePart === "function" ? themePart(buttonProps) : themePart;
    return {
      ...evaluatedTheme,
      color,
      "text-decoration-color": color,
    };
  };

  return {
    ...theme,
    base: overridePart(theme.base),
    hover: {
      ...overridePart(theme.hover),
      filter: "brightness(0.8)",
    },
    disabled: {
      ...overridePart(theme.disabled),
      filter: "brightness(0.65)",
    },
  };
};

export const ButtonStyles = (buttonProps: StyledButtonProps) => {
  const {
    $variant = "primary",
    theme: styledComponentsTheme,
    $tertiaryColor,
    $loading,
    $destructive,
    disabled,
    $width,
    $minWidth,
  } = buttonProps;
  const theme = styledComponentsTheme?.buttons2 ?? buttonTheme;
  const variantTheme = theme[$variant];
  const overriddenTheme =
    $tertiaryColor !== undefined && $variant === "tertiary"
      ? makeOverriddenColorTheme(variantTheme, $tertiaryColor, buttonProps)
      : variantTheme;

  return css`
    ${({ theme }) => TypographyStyles({ theme })};
    display: flex;
    align-items: center;
    justify-content: center;
    ${buildResponsiveCss("width", $width ?? "auto")};
    ${buildResponsiveCss("min-width", $minWidth ?? "initial")};
    cursor: pointer;
    position: relative;
    ${cssFromVariantTheme(overriddenTheme, "base", buttonProps)}
    p,svg {
      color: ${buttonChildrenColorFromVariantTheme(
        overriddenTheme,
        "base",
        buttonProps
      )};
    }

    &:hover {
      ${cssFromVariantTheme(overriddenTheme, "hover", buttonProps)}
      p,svg {
        color: ${buttonChildrenColorFromVariantTheme(
          overriddenTheme,
          "hover",
          buttonProps
        )};
      }
    }

    &:focus-visible {
      outline: auto; /* Support Firefox & non-webkit browsers */
      outline: 2px solid -webkit-focus-ring-color;
      outline-offset: 2px;
    }

    &:active {
      ${cssFromVariantTheme(overriddenTheme, "active", buttonProps)}

      p,svg {
        color: ${buttonChildrenColorFromVariantTheme(
          overriddenTheme,
          "active",
          buttonProps
        )};
      }
    }

    &:focus:not(:active):not(:hover) {
      ${cssFromVariantTheme(overriddenTheme, "focus", buttonProps)}

      p,svg {
        color: ${buttonChildrenColorFromVariantTheme(
          overriddenTheme,
          "focus",
          buttonProps
        )};
      }
    }

    &:disabled,
    &[disabled] {
      ${$loading && ($destructive || disabled)
        ? cssFromVariantTheme(overriddenTheme, "loading", buttonProps)
        : cssFromVariantTheme(overriddenTheme, "disabled", buttonProps)}
      cursor: default;

      p,
      svg {
        color: ${$loading && ($destructive || disabled)
          ? buttonChildrenColorFromVariantTheme(
              overriddenTheme,
              "loading",
              buttonProps
            )
          : buttonChildrenColorFromVariantTheme(
              overriddenTheme,
              "disabled",
              buttonProps
            )};
      }
    }
  `;
};
