import { createContextStore } from "@faire/web--source/ui/hooks/ContextStore";
import getTime from "date-fns/getTime";

interface OpeningOrderIncentivesStore {
  currentTime: number;
}

const { useStore, Provider } = createContextStore<OpeningOrderIncentivesStore>(
  {
    currentTime: getTime(new Date()),
  },
  {
    name: "OpeningOrderIncentivesContextStore",
  }
);

export { useStore, Provider };
