// NOTE(luke): datadogRum has import side-effects (around boot-strapping DD_RUM) that
// we don't want to invoke, because we set it up in the HTML document (outside react,
// so that we don't depend on this code succeeding in order to get errors logged).
// Thus, this is a type-only import, just to give us a little safety from its definition.
// eslint-disable-next-line no-restricted-imports
import type { datadogRum } from "@datadog/browser-rum";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";

export type DD_RUM = Partial<typeof datadogRum>;

export const getDD_RUM = (): DD_RUM | undefined =>
  getGlobalProperty<DD_RUM>("DD_RUM");

export const emitDatadogAction: (typeof datadogRum)["addAction"] = (
  name,
  context
) => {
  getDD_RUM()?.addAction?.(name, context);
};

export const emitDatadogError: (typeof datadogRum)["addError"] = (
  error,
  context
) => {
  getDD_RUM()?.addError?.(error, context);
};

export const emitDatadogTiming: (typeof datadogRum)["addTiming"] = (
  name,
  time
) => {
  getDD_RUM()?.addTiming?.(name, time);
};
