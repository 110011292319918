import { request } from '../../..';
import { IGetProductsPreviewForMessengerRequest } from '../../../indigofair/data/IGetProductsPreviewForMessengerRequest';
import { IGetProductsPreviewForMessengerResponse } from '../../../indigofair/data/IGetProductsPreviewForMessengerResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';

export const path = () => {
  return `/api/product/get-messenger-previews`;
};

export const build = (
  data: IGetProductsPreviewForMessengerRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/product/get-messenger-previews';

/**
 * Used by messenger to fetch product previews by tokens. Used by both retailers and brands.
 * Product Area: PRODUCTS_API
 */
export function execute(
  data: IGetProductsPreviewForMessengerRequest
): Promise<IGetProductsPreviewForMessengerResponse>;
export function execute(
  data: IGetProductsPreviewForMessengerRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetProductsPreviewForMessengerResponse>>;
export function execute(
  data: IGetProductsPreviewForMessengerRequest,
  options?: EndpointOptions
): Promise<IGetProductsPreviewForMessengerResponse>;
export function execute(
  data: IGetProductsPreviewForMessengerRequest,
  options?: EndpointOptions
): Promise<
  | IGetProductsPreviewForMessengerResponse
  | SuccessResponse<IGetProductsPreviewForMessengerResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<IGetProductsPreviewForMessengerResponse>(requestOptions);
}
execute[PATH] = 'api/product/get-messenger-previews/post';
export default execute;
