"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const Snowflake: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_WINTER_MARKET_2023].badgeIcon;
  return (
    <SVGIcon
      width="123"
      height="123"
      viewBox="0 0 123 123"
      fill="none"
      {...rest}
    >
      <path
        d="M123 57.1394H89.0171L110.068 35.7873L104.054 29.4719L76.3863 57.1394H65.8606V47.8166L93.2274 20.1491L87.2127 13.8337L65.8606 35.1858V0H57.1394V35.1858L35.7873 13.8337L29.4719 20.1491L57.1394 47.8166V57.1394H47.5159L20.1491 29.4719L13.8337 35.7873L35.1858 57.1394H0V65.8606H35.1858L13.8337 87.2127L20.1491 93.2274L47.5159 65.8606H57.1394V76.687L29.4719 104.054L35.7873 110.369L57.1394 89.0171V123H65.8606V89.0171L87.2127 110.369L93.2274 104.054L65.8606 76.687V65.8606H76.3863L104.054 93.2274L110.068 87.2127L89.0171 65.8606H123V57.1394Z"
        fill={iconColor}
      />
    </SVGIcon>
  );
};
