import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey =
  'messenger-message_from_retailer_to_brand-action_unknown';

/**
 * Track brand messenger, when brand receives messages from the retailer, and the event will be emitted
 */
export function trackMessengerMessageFromRetailerToBrand(
  Path: string,
  brandToken: string,
  retailerToken: string,
  received_at2: string,
  additional3: string,
  source4: string = ''
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: {
      Path,
      brandToken,
      retailerToken,
      received_at2,
      additional3,
      source4,
    },
  });
}
