import { EventColors } from "@faire/web--source/ui/Events/EventColors";

const Faire = {
  Text: {
    /** @deprecated */
    faireBlack: "#333333",
    /** @deprecated Color doesn't exist in the Slate Design System. Use an equivalent color from `@faire/design-tokens` or visit https://slate.faire.team/tokens/colors */
    slate: "#666666",
    /** @deprecated Color doesn't exist in the Slate Design System. Use an equivalent color from `@faire/design-tokens` or visit https://slate.faire.team/tokens/colors */
    darkGold: "#b4804e",
    /** @deprecated Use an equivalent color from `@faire/design-tokens` or visit https://slate.faire.team/tokens/colors */
    graphite: "#757575",
    /** @deprecated Color doesn't exist in the Slate Design System. Use an equivalent color from `@faire/design-tokens` or visit https://slate.faire.team/tokens/colors */
    alert: "#B50303",
  },
  /** @deprecated Use an equivalent color from `@faire/design-tokens` or visit https://slate.faire.team/tokens/colors */
  Hilight: {
    /** @deprecated Use an equivalent color from `@faire/design-tokens` or visit https://slate.faire.team/tokens/colors */
    richBlack: "#000000",
  },
} as const;

const Colors = {
  ...Faire.Hilight,
  ...Faire.Text,

  /** @deprecated Color doesn't exist in the Slate Design System. Use either `Core.border.info` or `Decorative.icon.blue` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  aqua: "#a6c5d0",
  /** @deprecated Color doesn't exist in the Slate Design System. Use either `Core.text.subdued`, `Core.icon.subdued`, `Core.surface.disabled`, `Core.text.subdued`, `Core.border.subdued`, `Core.icon.subdued`, or `Core.icon.disabled` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  asphalt: "#aaaaaa",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.teal` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  atlantic: "#2e4a53",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.tint.green` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  avocado: "#d1cead",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.icon.green` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  battleshipGray: "#808A6E",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.icon.red` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  blush: "#C9907B",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.icon.neutral` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  bone: "#dbd6d2",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.red` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  cabernet: "#7c4659",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.surface.warning` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  cardemon: "#f1d9a5",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.tint.neutral` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  champagne: "#fcf7f2",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.orange` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  cinnamon: "#773c27",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.border.warning` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  dandelion: "#F5CE81",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.border.muted`, `Core.border.muted`, or `Core.border.disabled` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  dove: "#cccccc",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.border.info`, or `Decorative.icon.blue` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  dust: "#bda6ad",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.surface.subdued`, or `Core.surface.subdued` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  fog: "#eaeaea",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.border.success` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  forest: "#7c978e",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.orange` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  gold: "#cb9968",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.orange` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  hazel: "#946951",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.border.info`, or `Decorative.icon.blue` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  indigo: "#848A9F",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.surface.success` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  lightGreen: "#edf2ea",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.surface.muted` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  linen: "#f8f8f8",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.orange` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  marigold: "#e0b881",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.surface.info`, or `Decorative.tint.blue` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  mist: "#bfd0d7",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.orange` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  mustard: "#d09954",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `TopShop.shade.green` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  olive: "#50583D",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.border.critical` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  passionFruit: "#D7470A",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.teal` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  peacock: "#2b413e",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.surface.warning` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  pearl: "#FCEAC7",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.surface.success from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  peppermint: "#d8dfd5",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.text.subdued`, `Core.icon.subdued`, `Core.surface.disabled`, `Core.text.subdued`, `Core.border.subdued`, `Core.icon.subdued`, or `Core.icon.disabled` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  pewter: "#888888",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.teal` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  pinot: "#a0818e",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.teal` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  raspberry: "#d6b4be",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.surface.success` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  sea: "#b9dbbc",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.green` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  seaweed: "#2f4743",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.tint.teal` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  sky: "#dae9e5",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.border.success` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  spruce: "#768783",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.border.critical` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  tanHide: "#F9996F",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.icon.red` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  toast: "#97735C",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.tint.neutral` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  vanilla: "#f9f5f0",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.icon.neutral` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  desert: "#857d68",

  /**
   * Usage is reserved for components with educational purposes (i.e. tooltips).
   */
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.surface.info`, or `Decorative.tint.blue` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  frost: "#E1ECFD",

  // Faire Summer/Winter Markets
  ...EventColors,

  // Neighborhood.
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.surface.subdued`, or `Core.surface.enabled` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  milk: "#F7F7F7",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.surface.subdued`, or `Core.surface.enabled` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  cream: "#F8F8F3",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.icon.green` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  moss: "#677161",

  /** Reserved for Top Shop Program only. Please do not use outside of the Top Shop Program */
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.tint.teal` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  lichen: "#EDF0E6",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `TopShop.icon.green` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  sage: "#8B9478",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `TopShop.tint.green` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  matcha: "#DCE3CE",

  // Usage is reserved for Insider
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.tint.teal` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  oasis: "#F2F5F5",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.shade.teal` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  belize: "#154548",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.icon.teal` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  bora: "#2A666A",

  // Usage is reserved for Faire Direct Rewards
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Core.border.info`, or `Decorative.icon.blue` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  dusk: "#5F788A",
  /** @deprecated Color doesn't exist in the Slate Design System. Use `Decorative.tint.teal` from `@faire/design-tokens`, or visit https://slate.faire.team/tokens/colors */
  glass: "#F7FAFC",
} as const;

/**
 * Faire's named colors.
 */
export type ColorKey = keyof typeof Colors;

/**
 * Faire's named color palette.
 * @trackobjectkeys
 */
export const Color = Colors;

/**
 * Type for restricting a color string value to one of Faire's
 * color palette values.
 */
export type Color = (typeof Colors)[ColorKey] | ColorWithOpacity;

export type ColorWithOpacity =
  `${(typeof Colors)[ColorKey]}${(typeof Opacity)[keyof typeof Opacity]}`;

/**
 * Helper type for extracting the color names or values from a subset
 * of color keys, e.g. the TextColor type.
 */
export type ColorsOrKeys<T extends ColorKey> = T | (typeof Colors)[T];

/**
 * Faire's named colors, or their values
 */
export type ColorOrKey = ColorsOrKeys<ColorKey> | ColorWithOpacity | "inherit";

export const Opacity = {
  O_95: "F2",
  O_90: "E6",
  O_85: "D9",
  O_80: "CC",
  O_75: "BF",
  O_70: "B3",
  O_65: "A6",
  O_60: "99",
  O_55: "8C",
  O_50: "80",
  O_45: "73",
  O_40: "66",
  O_35: "59",
  O_30: "4D",
  O_25: "40",
  O_20: "33",
  O_15: "26",
  O_10: "1A",
  O_05: "0D",
  O_03: "08",
} as const;

export default Color;
