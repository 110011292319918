import { Core } from "@faire/design-tokens";

import { embeddedLinkTheme } from "../../ui/EmbeddedLink/theme";
import { buttonTheme } from "../Button/theme";
import { Weight } from "../Font";
import { fontRamp } from "../Typography/Themes";

import { Media2Strings } from "./Media2Strings";
import { ITheme } from "./Theme";

/**
 * Default theme used for Faire properties.
 */
export const DefaultTheme: ITheme = {
  name: "Faire",
  ...Core,
  toggle: {
    active: {
      on: {
        label: {
          text: Core.text.default,
        },
        toggle: {
          container: {
            surface: Core.surface.default,
            border: Core.border.default,
          },
          indicator: {
            surface: Core.surface.inverse,
          },
        },
      },
      off: {
        label: {
          text: Core.text.default,
        },
        toggle: {
          container: {
            surface: Core.surface.inverse,
            border: Core.border.subdued,
          },
          indicator: {
            surface: Core.icon.disabled,
          },
        },
      },
    },
    disabled: {
      on: {
        label: {
          text: Core.text.disabled,
        },
        toggle: {
          container: {
            surface: Core.surface.disabled,
            border: "transparent",
          },
          indicator: {
            surface: Core.icon.disabled,
          },
        },
      },
      off: {
        label: {
          text: Core.text.disabled,
        },
        toggle: {
          container: {
            surface: Core.surface.subdued,
            border: "transparent",
          },
          indicator: {
            surface: Core.icon.disabled,
          },
        },
      },
    },
  },
  input: {
    label: { color: Core.text.default },
    placeholder: { color: Core.text.subdued },
    helper: { variant: "labelSansRegular", color: Core.text.default },
  },
  checkboxes: {
    primary: {
      icon: Core.icon.inverse,
      fill: Core.surface.default,
      border: Core.border.default,
      borderActive: Core.border.enabled,
      base: { color: Core.text.default },
      checked: { color: Core.text.default },
      hover: { color: Core.text.hover },
    },
    secondary: {
      icon: Core.icon.default,
      fill: Core.surface.inverse,
      border: Core.border.default,
      borderActive: Core.border.default,
      base: { color: Core.text.default },
      checked: { color: Core.text.default },
      hover: { color: Core.text.hover },
    },
    disabled: {
      icon: Core.icon.disabled,
      fill: Core.surface.subdued,
      border: Core.border.muted,
      borderActive: Core.border.muted,
      base: { color: Core.text.disabled },
      checked: { color: Core.text.disabled },
      hover: { color: Core.text.disabled },
    },
  },
  radios: {
    enabled: Core.text.default,
    disabled: Core.text.disabled,
  },
  pills: {
    filter: {
      primary: {
        color: Core.text.default,
        hover: Core.text.hover,
        contrast: Core.surface.inverse,
      },
      secondary: {
        color: Core.surface.subdued,
        hover: Core.border.muted,
        contrast: Core.surface.default,
      },
      label: {},
    },
    searchRecommendation: {
      background: {
        color: Core.surface.subdued,
        hover: Core.border.muted,
        contrast: Core.text.subdued,
      },
      label: {},
    },
  },
  modals: {
    primary: {
      background: Core.surface.enabled,
      color: Core.text.inverse,
      iconProps: { strokeWidth: 2 },
      overlay: Core.surface.enabled + "99",
      boxShadow: Core.surface.default,
    },
    default: {
      background: Core.surface.inverse,
      color: Core.text.default,
      iconProps: { strokeWidth: 2 },
      overlay: Core.surface.enabled + "99",
      boxShadow: Core.surface.default,
    },
  },
  buttons: {
    primary: {
      background: {
        color: Core.surface.default,
        hover: Core.surface.hover,
      },
      text: {
        color: Core.text.inverse,
        hover: Core.text.inverse,
      },
      border: {
        color: Core.border.default,
        hover: Core.border.hover,
      },
      disabled: {
        background: Core.surface.disabled,
        border: Core.surface.disabled,
        text: Core.text.disabled,
      },
    },
    secondary: {
      background: {
        color: Core.surface.inverse,
        hover: Core.surface.hover,
      },
      text: {
        color: Core.text.default,
        hover: Core.text.inverse,
      },
      border: {
        color: Core.border.default,
        hover: Core.border.hover,
      },
      disabled: {
        background: Core.surface.inverse,
        border: Core.border.disabled,
        text: Core.text.disabled,
      },
    },
  },
  embeddedLink: embeddedLinkTheme,
  tooltips: {
    primary: {
      background: Core.surface.default,
      color: Core.text.inverse,
      border: Core.border.default,
      actionButtons: {
        disabled: {
          background: Core.surface.disabled,
          color: Core.icon.enabled,
        },
        enabled: {
          background: Core.surface.inverse,
          color: Core.icon.enabled,
        },
      },
    },
    default: {
      background: Core.surface.inverse,
      color: Core.text.default,
      border: Core.border.muted,
      actionButtons: {
        disabled: {
          background: Core.surface.inverse,
          color: Core.text.disabled,
        },
        enabled: {
          background: Core.surface.inverse,
          color: Core.text.default,
        },
      },
    },
  },
  stepper: {
    step: {
      active: {
        text: Core.text.inverse,
        surface: Core.surface.default,
        border: Core.border.default,
      },
      inactive: {
        text: Core.text.subdued,
        surface: Core.surface.inverse,
        border: Core.border.subdued,
      },
      completed: {
        surface: Core.surface.disabled,
        border: Core.border.subdued,
        text: Core.text.disabled,
      },
    },
    label: {
      active: {
        text: Core.text.default,
      },
      inactive: {
        text: Core.text.subdued,
      },
      completed: {
        text: Core.text.subdued,
      },
    },
    line: {
      complete: {
        surface: Core.surface.default,
      },
      incomplete: {
        surface: Core.surface.subdued,
      },
    },
  },
  avatar: {
    placeholder: {
      background: Core.surface.default,
      color: Core.text.inverse,
    },
    border: {
      color: Core.border.muted,
    },
    background: Core.surface.inverse,
  },
  fonts: {
    serif: {
      // Display L
      h1: {
        family: () => "nantes",
        weight: Weight.medium,
        size: "52px",
        lineHeight: "64px",
        letterSpacing: "0px",
      },
      // Display M
      h2: {
        family: () => "nantes",
        weight: Weight.medium,
        size: "38px",
        lineHeight: "50px",
        letterSpacing: "0px",
      },
      // Display S
      h3: {
        family: () => "nantes",
        weight: Weight.medium,
        size: "30px",
        lineHeight: "38px",
        letterSpacing: "0px",
      },
      // Page Header
      h4: {
        family: () => "nantes",
        weight: Weight.medium,
        size: "22px",
        lineHeight: "32px",
        letterSpacing: "0px",
      },
      h5: {
        family: () => "nantes",
        weight: Weight.medium,
        size: "18px",
        lineHeight: "26px",
        letterSpacing: "0px",
      },
      p: {
        family: () => "nantes",
        weight: Weight.medium,
        size: "14px",
        lineHeight: "20px",
        letterSpacing: "0px",
      },
      small: {
        family: () => "nantes",
        weight: Weight.medium,
        size: "12px",
        lineHeight: "16px",
        letterSpacing: "0px",
      },
    },
    sansSerif: {
      h1: {
        family: () => "graphik",
        weight: Weight.bold,
        size: "52px",
        lineHeight: "64px",
        letterSpacing: "0.15px",
      },
      h2: {
        family: () => "graphik",
        weight: Weight.bold,
        size: "38px",
        lineHeight: "50px",
        letterSpacing: "0.15px",
      },
      // Display S (can also be 200 - "Regular")
      h3: {
        family: () => "graphik",
        weight: Weight.bold,
        size: "30px",
        lineHeight: "38px",
        letterSpacing: "0.15px",
      },
      // Section Header
      h4: {
        family: () => "graphik",
        weight: Weight.medium,
        size: "22px",
        lineHeight: "28px",
        letterSpacing: "0.15px",
      },
      // Subheading (can also be 200 - "Regular")
      h5: {
        family: () => "graphik",
        weight: Weight.medium,
        size: "18px",
        lineHeight: "26px",
        letterSpacing: "0.15px",
      },
      // Paragraph
      p: {
        family: () => "graphik",
        weight: Weight.book,
        size: "14px",
        lineHeight: "20px",
        letterSpacing: "0.15px",
      },
      // Label
      small: {
        family: () => "graphik",
        weight: Weight.book,
        size: "12px",
        lineHeight: "16px",
        letterSpacing: "0.15px",
      },
    },
  },
  banners: {},
  pagination: {
    activePage: {
      color: Core.text.default,
      hover: Core.text.hover,
      contrast: Core.text.inverse,
    },
    inactivePage: {
      color: "transparent",
      hover: "transparent",
      contrast: Core.text.default,
    },
    text: {
      variant: "labelSansRegular",
      align: "center",
    },
  },
  typography: fontRamp,
  buttons2: buttonTheme,
  media2strings: new Media2Strings(),
  spacing: 8,
} as const;
