import { IApiError } from "@faire/web-api--source/indigofair/data/IApiError";

// Error class abstraction that enables framework agnostic error handling
export class WebApiError extends Error {
  name = "WebApiError";

  readonly httpStatusCode: number | undefined;

  readonly responseData: IApiError;

  constructor(
    message: string,
    httpStatusCode: number | undefined,
    responseData: IApiError | undefined
  ) {
    super(message);
    this.httpStatusCode = httpStatusCode;
    this.responseData = IApiError.build(responseData);
  }
}
