import { useLocalization } from "@faire/web--source/common/localization/useLocalization";
import { useCallback } from "react";

import { localNum } from "./localNum";

export const useLocalNum = (): { localNum: typeof localNum } => {
  const { currencyFormatLocale } = useLocalization();
  const localNumHook = useCallback(
    (value: number, opts?: Intl.NumberFormatOptions): string | undefined => {
      if (value === undefined) {
        return undefined;
      }
      return Intl.NumberFormat(currencyFormatLocale, opts).format(value);
    },
    [currencyFormatLocale]
  ) as typeof localNum;
  return { localNum: localNumHook };
};
