import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { makeObservable } from "@faire/web--source/common/makeObservable";
import { isWindowUndefined } from "@faire/web--source/common/server-side-rendering/isWindowUndefined";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { createStoreHook } from "@faire/web--source/ui/hooks/useStore";
import {
  Action,
  createBrowserHistory,
  createMemoryHistory,
  History,
  Location,
  LocationDescriptorObject,
  MemoryHistory,
} from "history";
import cloneDeep from "lodash/cloneDeep";
import defer from "lodash/defer";
import { action, computed, IObservableArray, observable } from "mobx";
import qs from "query-string";

import { localizePath } from "@faire/retailer/lib/localizationUtils";

const logger = require("debug")("IF:HISTORY");

type IOverloadGoBack = {
  (path: History.Path, state?: History.LocationState): void;
  (location: LocationDescriptorObject): void;
};

type IOverloadReplace = {
  (path: History.Path, state?: History.LocationState): void;
  (location: LocationDescriptorObject): void;
};

/**
 * @deprecated prefer to use `useAppHistory` or `withStores` instead
 */
export class AppHistory {
  static get = singletonGetter(AppHistory);

  private pastLocations: IObservableArray<Location> = observable.array();

  history: History;

  constructor(history?: History | undefined) {
    makeObservable(this);
    const memoryHistory = getGlobalProperty("memoryHistory");

    if (history) {
      this.history = history;
    } else if (memoryHistory) {
      // Used in Next.js during migration from react-router to app-router.
      this.history = memoryHistory as unknown as MemoryHistory;
    } else if (!isWindowUndefined()) {
      this.history = createBrowserHistory({
        basename: undefined,
      });
    } else {
      const location = getLocationOrThrow();
      this.history = createMemoryHistory({
        initialEntries: [
          `${location.pathname}${location.search}${location.hash}`,
        ],
        initialIndex: 0,
      });
    }
    this.pastLocations.push(this.history.location);
    this.history.listen(this.updatePastLocations);
  }

  /**
   * @deprecated Use withRouter or withQueryParams to inject location as a prop
   */
  @computed
  get location() {
    return this.currentLocation();
  }

  /**
   * Returns a parsed representation of history.location.search. It is possible for this value
   * to not reflect the browser's actual location.search momentarily depending on the order that
   * components are reacting to changes.
   *
   * @deprecated nothing should use this.
   */
  @computed
  private get potentiallyStaleQuery() {
    return qs.parse(this.location?.search ?? "");
  }

  /**
   * @deprecated Use withQueryParams to inject queryParams as a prop
   *
   * Returns a parsed representation of history.location.search.
   *
   * NOTE this cannot be annoated as @computed because it is relying on the browser's
   * history.location object which is not observable.
   */
  get query() {
    // Crazy hack... we dereference potentiallyStaleQuery here which IS observable so that
    // consumers of this class building reactions on the query can continue working.
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.potentiallyStaleQuery;

    // Using raw history.location here because it will always be true, this.location can become stale.
    return qs.parse(this.history.location.search);
  }

  /**
   * @deprecated Use withRouter or withQueryParams to inject location as a prop
   */
  currentLocation = () => {
    return cloneDeep(this.pastLocations[this.pastLocations.length - 1]);
  };

  previousLocation = (): Location | undefined => {
    if (this.pastLocations.length < 2) {
      return undefined;
    }
    return cloneDeep(this.pastLocations[this.pastLocations.length - 2]);
  };

  previousPaths = () => {
    return cloneDeep(this.pastLocations.map((location) => location.pathname));
  };

  isPreviousLocationWithinApp = (): boolean => {
    return this.pastLocations.length > 1;
  };

  /**
   * Supports including the query in the path or specifying an additional
   * object with the query params.
   */
  goTo = (path: History.Path, query?: { [key: string]: string | string[] }) => {
    if (query) {
      if (path.includes("?")) {
        throw new Error(
          "Query params included in both path and in the query object"
        );
      }

      const queryString = qs.stringify(query);
      if (queryString) {
        path = `${path}?${queryString}`;
      }
    }
    this.history.push(localizePath(path));
  };

  /**
   * Use this only if you want to go to the last location in the app.
   *
   * If you are exiting out of a modal you probably want to use this
   * function to remove the modal path from your history.
   *
   * goBack takes a required location because sometimes going back
   * take us out of the react app. In this case we should replace
   * the location with the provided path.
   */
  goBack: IOverloadGoBack = (
    location: History.Path | LocationDescriptorObject,
    state?: History.LocationState
  ): void => {
    if (this.isPreviousLocationWithinApp()) {
      this.history.goBack();
    } else {
      if (typeof location === "string") {
        this.replace(location, state);
      } else {
        this.replace(location);
      }
    }
  };

  replace: IOverloadReplace = (
    location: History.Path | LocationDescriptorObject,
    state?: History.LocationState
  ) => {
    if (typeof location === "string") {
      this.history.replace(localizePath(location), state);
    } else {
      this.history.replace(location);
    }
  };

  private updatePastLocations = (location: Location, historyAction: Action) => {
    defer(() => {
      logger(historyAction, location);
      switch (historyAction) {
        case "PUSH":
          this.pushLocation(location);
          break;
        case "REPLACE":
          this.replaceLocation(location);
          break;
        case "POP": {
          this.popLocation(location);
          break;
        }
        default:
      }
    });
  };

  @action
  private pushLocation = (location: Location) => {
    this.pastLocations.push(location);
  };

  @action
  private replaceLocation = (location: Location) => {
    const updatedPastLocations = this.pastLocations.slice(0, -1);
    updatedPastLocations.push(location);
    this.pastLocations.replace(updatedPastLocations);
  };

  @action
  private popLocation = (location: Location) => {
    let updatedPastLocations = this.pastLocations.slice(0, -1);
    const previousLocation =
      updatedPastLocations[updatedPastLocations.length - 1];
    if (!previousLocation || previousLocation.key !== location.key) {
      updatedPastLocations = [location];
    }
    this.pastLocations.replace(updatedPastLocations);
  };

  addQuery = (restOfQuery: Record<string, unknown>) => {
    this.history.replace({
      ...this.history.location,
      search: qs.stringify({
        ...this.query,
        ...restOfQuery,
      }),
    });
  };

  getLocationWithQueryParamsAdded = (params: {
    [name: string]: string | undefined;
  }): Location => {
    return {
      ...(this.location ?? {
        pathname: "",
        search: "",
        hash: "",
        state: undefined,
      }),
      search: qs.stringify({ ...this.query, ...params }),
    };
  };

  getLocationWithQueryParamAdded = (name: string, value: string): Location => {
    return this.getLocationWithQueryParamsAdded({ [name]: value });
  };
}

/**
 * @deprecated -- use useHistory from react-router-dom instead
 */
export const useAppHistory = createStoreHook(AppHistory);
