import { IBrandCartV2 } from "@faire/web-api--source/faire/carts/IBrandCartV2";
import { ICartItemV2 } from "@faire/web-api--source/faire/carts/ICartItemV2";
import { ICartV2 } from "@faire/web-api--source/faire/carts/ICartV2";
import { IUpdateCartRequestV2 } from "@faire/web-api--source/faire/carts/IUpdateCartRequestV2";
import { IUpdateCartResponseV2 } from "@faire/web-api--source/faire/carts/IUpdateCartResponseV2";
import { IItemCustomization } from "@faire/web-api--source/indigofair/data/IItemCustomization";
import { IOrderSummaryLineItem } from "@faire/web-api--source/indigofair/data/IOrderSummaryLineItem";

import { UpdateCartUPLTParams } from "./triggerAddToCartEvent";

export type ILeanCartItemV2 = ICartItemV2;

export interface IBrandCartV2WithSummary extends IBrandCartV2 {
  checkout_summary: ILeanBrandCheckoutSummary | undefined;
}

export namespace IBrandCartV2WithSummary {
  export const build = (
    partial?: Partial<IBrandCartV2WithSummary> | undefined
  ): IBrandCartV2WithSummary => ({
    checkout_summary: undefined,
    ...IBrandCartV2.build(partial),
  });
}

export interface ILeanBrandCheckoutSummary {
  order_summary_line_items: Array<IOrderSummaryLineItem>;
  disclaimer: string | undefined;
}

export namespace ILeanBrandCheckoutSummary {
  export const build = (
    partial?: Partial<ILeanBrandCheckoutSummary> | undefined
  ): ILeanBrandCheckoutSummary => ({
    order_summary_line_items: [],
    disclaimer: undefined,
    ...(partial ?? {}),
  });
}

export interface ILeanCartItem {
  product_token: string | undefined;
  product_option_token: string | undefined;
  customizations: Array<IItemCustomization>;
  includes_tester: boolean | undefined;
  quantity: number | undefined;
}

export namespace ILeanCartItem {
  export const build = (
    partial?: Partial<ILeanCartItem> | undefined
  ): ILeanCartItem => ({
    product_token: undefined,
    product_option_token: undefined,
    customizations: [],
    includes_tester: undefined,
    quantity: undefined,
    ...(partial ?? {}),
  });
}

export interface IBrandShipping {
  lower_bound_lead_time_days: number | undefined;
  upper_bound_lead_time_days: number | undefined;
  shipping_time_lower_bound_days: number | undefined;
  shipping_time_upper_bound_days: number | undefined;
}

export interface IUpdateItemsOptions {
  upltParams: UpdateCartUPLTParams;
  skipItemInventoryFetch?: boolean;
  skipCartRefetch?: boolean;
}

export type ICartItemForUpdate = IUpdateCartRequestV2.ICartItemForUpdate;

export type IBrandCartForUpdate = IUpdateCartRequestV2.IBrandCartForUpdate;
export namespace ICartItemForUpdate {
  export const build = IUpdateCartRequestV2.ICartItemForUpdate.build;
}

export type UpdateCartArg = {
  /*
    Items to be updated in cart
  */
  items: IUpdateCartRequestV2.ICartItemForUpdate[];
  /*
    UPLT parameters for performance evaluation purposes
  */
  upltParams: UpdateCartUPLTParams;
  /*
    Whether to allow the PUT request api/v2/carts/cartToken to happen even
    when the `items` array is empty
  */
  allowEmptyItems?: boolean;
  /*
    Whether to skip invalidating the cart page cache after the PUT request
  */
  skipCartRefetch?: boolean;
  /*
    Updates for each brand carts
  */
  brand_carts?: IBrandCartForUpdate[];
  /*
    Used for updating shipping date
  */
  requested_ship_dates?: Record<string, number>;
  /*
    Used to update shipping groupings
  */
  should_collapse_preorder_groups?: boolean;
};

// TODO: Eventually we should consolidate UpdateCartHandler and NewUpdateCartFn
// UpdateCartHandler is the function signature of updateCartData in CartViewState while
// NewUpdateCartFn is the signature of updateCartItems in useCartUpdate hook.
// Eventually we will move away from view states and the two can be consolidated
export type UpdateCartHandler = (
  items: ICartItemForUpdate[],
  options: IUpdateItemsOptions
) => Promise<ICartV2 | null | void>;

export type UpdateCartItemsHandler = (
  arg: UpdateCartArg
) => Promise<IUpdateCartResponseV2 | undefined>;

interface IFixCartOptions {
  refetchCart?: boolean;
  singleBrandToken?: string;
}

export type FixCartHandler = (
  items: ILeanCartItemV2[],
  options?: IFixCartOptions
) => Promise<void>;

export type UpdateShippingDateHandler = (
  requestedShipDates: Record<string, number>
) => Promise<void>;

export type UpdateBrandCartSavedForLaterHandler = (
  cart: Pick<
    IBrandCartV2,
    "brand_token" | "brand_name" | "last_updated_by_retailer_at"
  >,
  savedForLaterValue: boolean,
  trigger?: "undo" | "mobile_details_page" | undefined
) => Promise<void>;

export type UndoDeleteBrandCartHandler = (cart: IBrandCartV2) => Promise<void>;

export type DeleteBrandCartHandler = (cart: IBrandCartV2) => Promise<void>;

export interface IBrandCartMovingInfo {
  state: BrandCartMovingState;
  lastUpdatedAt: number | undefined;
}

export type BrandCartMovingState =
  | "to_bag"
  | "to_saved_for_later"
  | "to_delete"
  | "undo_pending_to_bag"
  | "undo_pending_to_saved_for_later"
  | "undo_pending_to_delete"
  | "move_undone";

export enum CartsTabs {
  ALL_BRAND = "ALL_BRAND",
  SAVED_FOR_LATER = "SAVED_FOR_LATER",
}
