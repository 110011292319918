import { RequestOptions } from "@faire/web-api--source/types";

export const CLIENT_SIDE_LATENCY_HEADER = "X-Client-Side-Latency-Timing";

/**
 * Adds a performance timestamp to request headers for client-side latency tracking.
 * Works with all HTTP methods when tracking is enabled.
 *
 * @param config - The original request configuration
 * @returns Modified request configuration with latency header if tracking is enabled
 */
export const clientSideLatencyRequestInterceptor = (
  config: RequestOptions
): RequestOptions => {
  if (config.trackClientSideLatency) {
    config.headers[CLIENT_SIDE_LATENCY_HEADER] = performance.now();
  }

  return config;
};
