import { getGlobalLookup } from "@faire/web--source/common/globals/lookup";

export const scopedSingletonGetter = <T>(InstanceConstructor: {
  new (): T;
}) => {
  const key = Symbol();
  const scopedGetter = (): T => {
    const singletons = getGlobalLookup()("faire.singletons") as
      | Record<symbol, unknown>
      | undefined;
    if (!singletons) {
      throw new Error("Cannot use singleton getter outside of React");
    }
    return (
      (singletons[key] as T) ?? (singletons[key] = new InstanceConstructor())
    );
  };
  return scopedGetter;
};
