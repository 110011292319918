import { ILocaleKey } from '../../indigofair/locale/ILocaleKey';
import { SettingConst } from '../../types';

/**
 * List of available display languages that the retailer can choose from.
 */
export const RETAILER_AVAILABLE_LOCALES =
  'RETAILER_AVAILABLE_LOCALES' as SettingConst<
    'RETAILER_AVAILABLE_LOCALES',
    Array<ILocaleKey>,
    'HELP_CENTER' | 'RETAILER' | 'MAKER' | 'ADMIN' | 'VISITOR_WEB'
  >;
