import { request } from '../../../../..';
import { IGetCategoriesResponse } from '../../../../../indigofair/data/IGetCategoriesResponse';
import { UserGroup } from '../../../../../indigofair/data/UserGroup';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../../types';
import qs from 'query-string';

export type QueryParameters = {
  locale: string | undefined;
  userGroup: keyof typeof UserGroup | undefined;
  hashCode: string | undefined;
  cacheBust: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (retailerToken: string, queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/v3/categories/personalized/${retailerToken}${queryString}`;
};

export const build = (
  retailerToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(retailerToken),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v3/categories/personalized/:retailerToken';

/**
 * Product Area: NAVIGATION_TREE_UX
 */
export function execute(
  retailerToken: string,
  queryParams?: QueryParameters
): Promise<IGetCategoriesResponse>;
export function execute(
  retailerToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetCategoriesResponse>>;
export function execute(
  retailerToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IGetCategoriesResponse>;
export function execute(
  retailerToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IGetCategoriesResponse | SuccessResponse<IGetCategoriesResponse>> {
  const { route, ...requestOptions } = build(
    retailerToken,
    queryParams,
    options
  );
  return request<IGetCategoriesResponse>(requestOptions);
}
execute[PATH] = 'api/v3/categories/personalized/retailerToken/get';
export default execute;
