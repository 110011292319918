import { RetailerRoute } from "@faire/web--source/common/routes/RetailerRouteMatcher";
import { RouteMatcher } from "@faire/web--source/common/routes/RouteMatcher";
import { route as brandRoute } from "@faire/web-api--source/routes/brand/brandToken";
import { route as c1Route } from "@faire/web-api--source/routes/category/c1CategoryUrlId";
import { route as c2Route } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId";
import { route as c3Route } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId";
import { route as checkoutSuccessRoute } from "@faire/web-api--source/routes/checkout/success/orderToken";
import { route as productRoute } from "@faire/web-api--source/routes/product/productToken";
import { route as searchRoute } from "@faire/web-api--source/routes/search";
import { route as ordersRoute } from "@faire/web-api--source/routes/user/account/orders";

import { MOBILE_APP_PROMOTION_ALLOWED_PATHS } from "@faire/retailer/stores/ui/PromoModalStore";

export const APP_DOWNLOAD_NUDGE_ALLOWED_PATHS: RetailerRoute[] = [
  searchRoute,
  c1Route,
  c2Route,
  c3Route,
  productRoute,
  brandRoute,
  ordersRoute,
  checkoutSuccessRoute,
];

// paths for both control and treatment, used to ensure balanced bucketing
const APP_DOWNLOAD_PATHS = [
  ...APP_DOWNLOAD_NUDGE_ALLOWED_PATHS,
  ...MOBILE_APP_PROMOTION_ALLOWED_PATHS,
];
const appDownloadRouteMatcher: RouteMatcher<RetailerRoute> = new RouteMatcher(
  APP_DOWNLOAD_PATHS
);
export const isAppDownloadPath = (pathname: string) =>
  !!appDownloadRouteMatcher.match(pathname);
