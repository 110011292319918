import { LocationDescriptor } from "history";
import { pathToRegexp } from "path-to-regexp";

import { AdminRoutes } from "./routes/adminRoutes";
import { BrandRoutes } from "./routes/brandRoutes";
import { HelpCenterRoutes } from "./routes/helpCenterRoutes";
import { RetailerRoutes } from "./routes/retailerRoutes";
import { VisitorRoutes } from "./routes/visitorRoutes";

export type RetailerRoute = RetailerRoutes[keyof RetailerRoutes];
export type BrandRoute = BrandRoutes[keyof BrandRoutes];
export type AdminRoute = AdminRoutes[keyof AdminRoutes];
export type HelpCenterRoute = HelpCenterRoutes[keyof HelpCenterRoutes];
export type VisitorRoute = VisitorRoutes[keyof VisitorRoutes];

export type FaireRoute =
  | RetailerRoute
  | BrandRoute
  | AdminRoute
  | HelpCenterRoute
  | VisitorRoute;

/**
 * Returns true if the given pathname matches the given route.
 * Note that the pathname must not include query params.
 */
export function pathMatchesRoute<T extends FaireRoute>(
  pathname: string,
  route: T
): boolean;
/**
 * Returns true if the given location matches the given route.
 * Note that the pathname must not include query params.
 */
export function pathMatchesRoute<T extends FaireRoute>(
  pathname: LocationDescriptor,
  route: T
): boolean;
export function pathMatchesRoute<T extends FaireRoute>(
  pathOrLocation: string | LocationDescriptor,
  route: T
): boolean {
  const pathname: string | undefined =
    typeof pathOrLocation === "string"
      ? pathOrLocation
      : pathOrLocation.pathname;
  if (!pathname) {
    return false;
  }
  return pathToRegexp(route).test(pathname);
}
