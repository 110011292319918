import { getWindowOrThrow } from "@faire/web--source/common/globals/getWindow";
import { throttleIfPossible } from "@faire/web--source/common/globals/throttleIfPossible";
import { getOverride } from "@faire/web--source/common/PublicSettings";
import { IFrontendSetting } from "@faire/web--source/common/settings/declarations/IFrontendSetting";
import {
  FrontendSettingName,
  NullableSettingType,
} from "@faire/web--source/common/settings/declarations/ISettingSchema";
import { ClientOverlap } from "@faire/web--source/common/settings/hooks/createSettingsContextProvider";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { ISettingEntry } from "@faire/web-api--source/indigofair/settings/ISettingEntry";
import { SettingConst } from "@faire/web-api--source/types";
import once from "lodash/once";

import {
  AssignmentAccumulator,
  AssignmentCache,
  BATCH_SETTING_THROTTLE_MS,
  BatchAssignSettingOptions,
  sharedAssignSetting,
  unthrottledBatchAssignSetting,
} from "./sharedAssignSetting";
import { sharedGetSetting } from "./sharedGetSetting";

/**
 * Needs to be a singleton store for SSR.
 * @deprecated Use createSettingsContextProvider instead.
 */
export class SerializedSettingCache {
  static get = singletonGetter(SerializedSettingCache);

  private _assignmentCache: AssignmentCache = new Set();

  private _onUnloadListener = once(() => {
    try {
      getWindowOrThrow().addEventListener("visibilitychange", (e: Event) => {
        if (
          "flush" in legacyThrottledBatchAssignSetting &&
          (e.target as Document | undefined)?.visibilityState === "hidden"
        ) {
          legacyThrottledBatchAssignSetting.flush();
        }
      });
    } catch (_) {
      /* silence, handle if window is not defined */
    }
  });

  clear = () => {
    this._assignmentCache.clear();
    this._assignmentAccumulator.clear();
  };

  private _assignmentAccumulator: AssignmentAccumulator = new Map();

  assignSetting = async (
    settingOrKey: SettingConst | IFrontendSetting,
    options?: BatchAssignSettingOptions
  ) => {
    this._onUnloadListener();

    return sharedAssignSetting(
      settingOrKey,
      options,
      this._assignmentCache,
      this._assignmentAccumulator,
      legacyThrottledBatchAssignSetting
    );
  };
}

/**
 * Creates the getSetting function associated with a specific Realm, i.e. Brand, Retailer, Admin, etc.
 * @param pageSerializedData
 * @deprecated Use createSettingsContextProvider instead.
 */
export const getSettingFactory = <
  Client extends keyof typeof ISettingEntry.SerializeToClient
>(
  pageSerializedData: () => { [key: string]: unknown } | undefined
) => {
  function getSettingValueWithoutAssignment<
    V,
    C extends keyof typeof ISettingEntry.SerializeToClient
  >(
    setting: SettingConst<string, V, ClientOverlap<Client, C>>,
    defaultValue: V
  ): V;

  function getSettingValueWithoutAssignment<
    V extends NullableSettingType,
    C extends keyof typeof ISettingEntry.SerializeToClient
  >(
    setting: IFrontendSetting<V, FrontendSettingName, ClientOverlap<Client, C>>
  ): V;

  function getSettingValueWithoutAssignment<
    V extends NullableSettingType,
    T,
    C extends keyof typeof ISettingEntry.SerializeToClient
  >(
    setting:
      | IFrontendSetting<V, FrontendSettingName, ClientOverlap<Client, C>>
      | SettingConst<string, T, ClientOverlap<Client, C>>,
    defaultValue?: T
  ): V | T;

  function getSettingValueWithoutAssignment(
    settingOrKey: SettingConst | IFrontendSetting,
    defaultValue?: unknown
  ) {
    const pageData = pageSerializedData();
    const key =
      typeof settingOrKey === "string" ? settingOrKey : settingOrKey.name;
    const overrideSettingValue = getOverride(key);
    return sharedGetSetting(
      settingOrKey,
      pageData,
      overrideSettingValue,
      defaultValue
    );
  }

  function getSettingValueAndAssign<
    V,
    C extends keyof typeof ISettingEntry.SerializeToClient
  >(key: SettingConst<string, V, ClientOverlap<Client, C>>, defaultValue: V): V;

  function getSettingValueAndAssign<
    T extends NullableSettingType,
    C extends keyof typeof ISettingEntry.SerializeToClient
  >(
    setting: IFrontendSetting<T, FrontendSettingName, ClientOverlap<Client, C>>
  ): T;

  function getSettingValueAndAssign<
    T,
    V extends NullableSettingType,
    C extends keyof typeof ISettingEntry.SerializeToClient
  >(
    settingOrKey:
      | SettingConst<string, T, ClientOverlap<Client, C>>
      | IFrontendSetting<V, FrontendSettingName, ClientOverlap<Client, C>>,
    defaultValue?: unknown
  ) {
    SerializedSettingCache.get().assignSetting(settingOrKey);
    return getSettingValueWithoutAssignment(settingOrKey, defaultValue);
  }

  return {
    getSettingValueWithoutAssignment,
    getSettingValueAndAssign,
    assignSetting: SerializedSettingCache.get().assignSetting,
  };
};

/**
 * @deprecated Use the hooks implementation instead.
 */
export const legacyThrottledBatchAssignSetting = throttleIfPossible(
  unthrottledBatchAssignSetting,
  BATCH_SETTING_THROTTLE_MS,
  {
    leading: false,
  }
);
