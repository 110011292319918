import { ITypography } from "@faire/web--source/ui/Typography";
import assign from "lodash/assign";
import flow from "lodash/flow";
import isFunction from "lodash/isFunction";
import keys from "lodash/keys";
import last from "lodash/last";
import mapValues from "lodash/mapValues";
import pickBy from "lodash/pickBy";
import reduce from "lodash/reduce";
import values from "lodash/values";

import { ITheme } from "../../slate/Theme";

export type StyleResolver<IStyleProps, IComponentProps extends ITypography> = {
  [key in keyof Partial<IStyleProps>]: (
    key: string,
    props: IComponentProps,
    theme: ITheme
  ) => void;
};

export const getValueFromBooleanProps = <IStyleProps>(
  props: Partial<IStyleProps>,
  keysList: string[]
) =>
  flow([
    (passedProps) =>
      pickBy(
        passedProps,
        (value: any, key: string) => keysList.includes(key) && !!value
      ),
    keys,
    // log a warning if caller has specified competing attributes
    (attributes) => {
      if (attributes.length > 1) {
        // eslint-disable-next-line no-console
        console.warn(
          `Typography is using competing props ${attributes.join(
            ", "
          )}. Beware that the props which will be used is not guaranteed. It is best to avoid using competing props.`
        );
      }
      return attributes;
    },
    last,
  ])(props);

export const convertPropsToStyle = <
  IStyleProps,
  IComponentProps extends ITypography
>(
  getStyle: StyleResolver<IStyleProps, IComponentProps>,
  style: IStyleProps,
  props: IComponentProps,
  theme: ITheme
) =>
  flow([
    // Grab the props that have a transformer function in getStyle
    (passedProps) =>
      pickBy(passedProps, (_: string, key: string) =>
        isFunction(getStyle[key as keyof IStyleProps])
      ),
    // Apply the transformation
    (passedProps) =>
      mapValues(passedProps, (value: string, key: string) =>
        getStyle[key as keyof IStyleProps](value, props, theme)
      ),
    // Strip the transformer keys off so we just have an array of CSS rule
    // objects
    values,
    // Create a new object with all these objects merged together
    (passedProps) =>
      reduce(
        passedProps,
        (acc: Partial<IStyleProps>, styleGroup: Partial<IStyleProps>) =>
          assign(acc, styleGroup),
        {}
      ),
  ])(style);

export const parseUnit = (value: string) => {
  if (value === "") {
    return "0";
  }
  return /^\d+$/.test(value) ? `${value}px` : value;
};

// Regex to determine if a given link url / pathname is to an external address
export const externalLinkRegex = new RegExp("^((?:[a-z]+:)|(//))", "i");

export const shouldUseAnchorForLink = (
  link: string | unknown | undefined
): link is string => {
  const isExternalLink =
    typeof link === "string" && externalLinkRegex.test(link);
  const hasHash = typeof link === "string" && link.startsWith("#");

  return isExternalLink || hasHash;
};
