import { RouteMatcher } from "@faire/web--source/common/routes/RouteMatcher";
import {
  darkReadRoutes,
  darkReadOverrideLocaleHeaderRoutes,
} from "@faire/web-api--source/darkReads";
import { RequestMethod } from "@faire/web-api--source/types";

export const getAllowList = (method: RequestMethod): RouteMatcher<string> =>
  // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
  DARK_READ_ALLOW_LIST[method];

const DARK_READ_ALLOW_LIST: Record<
  Extract<RequestMethod, "GET" | "POST">,
  RouteMatcher<string>
> = {
  POST: RouteMatcher.of(...darkReadRoutes.POST),
  GET: RouteMatcher.of(...darkReadRoutes.GET),
};

export const getOverrideLocaleHeaderAllowList = (
  method: RequestMethod
  // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
): RouteMatcher<string> => DARK_READ_OVERRIDE_LOCALE_HEADER_ALLOW_LIST[method];

const DARK_READ_OVERRIDE_LOCALE_HEADER_ALLOW_LIST: Record<
  Extract<RequestMethod, "GET" | "POST">,
  RouteMatcher<string>
> = {
  POST: RouteMatcher.of(...darkReadOverrideLocaleHeaderRoutes.POST),
  GET: RouteMatcher.of(...darkReadOverrideLocaleHeaderRoutes.GET),
};
