import {
  AllowedFormats,
  intlDateWithTimezone,
} from "@faire/web--source/common/localization/date-fns/intlDate";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import { ICurrentPromotionalEventResponse } from "@faire/web-api--source/indigofair/data/ICurrentPromotionalEventResponse";
import { ITimestamp } from "@faire/web-api--source/indigofair/rfc3339/ITimestamp";

import { StrictLocalizeFunction } from "../localization";

const MARKET_TIMEZONE = "America/Toronto";

export type ICurrentPromotionalEventDates = Pick<
  ICurrentPromotionalEventResponse,
  "insider_early_access_start_at" | "start_at" | "end_at"
>;

export const MARKET_FALLBACKS = (strictLocalize: StrictLocalizeFunction) => ({
  currentEventType: PromotionalEventType.FAIRE_MARKET_JANUARY_2025,
  name: "Faire Market",

  /* Below variables are used for date ranges.
   * Currently our localization for date ranges aren't good enough
   * context: https://github.com/Faire/web/pull/2195
   * Use dates from DB for date ranges once we have a solution
   */
  month: strictLocalize({
    defaultMessage: "January",
    description: {
      text: "The month of an event",
    },
  }),
  earlyAccessDayNumber: "21",
  startDayNumber: "22",
  endDayNumber: "24",
  yearNumber: "2025",
  earlyAccessStartAtTimestamp: 1737435600000, // January 21, 2025 12AM EST
  mainEventStartAtTimestamp: 1737522000000, // January 22, 2025 12AM EST
  endAtTimestamp: 1737781199000, // January 24, 2024 11:59:59PM EST
});

const formatDateInMarketTimezone = (
  value: ITimestamp | Date | number,
  format: AllowedFormats = "MMMM d"
): string => {
  return intlDateWithTimezone(value, format, MARKET_TIMEZONE);
};

const generateFormattedRange = (
  dates: {
    startDayNumber: string;
    endDayNumber: string;
    startMonthDate: string;
    endMonthDate?: string;
    yearNumber?: string;
  },
  options: {
    hasYear?: boolean;
    hasEndMonth?: boolean;
  },
  strictLocalize: StrictLocalizeFunction
) => {
  const {
    startMonthDate,
    startDayNumber,
    endMonthDate,
    endDayNumber,
    yearNumber,
  } = dates;

  const { hasYear, hasEndMonth } = options;

  if (hasYear && yearNumber) {
    if (hasEndMonth && endMonthDate) {
      return strictLocalize(
        {
          defaultMessage:
            "{startMonthDate} {startDayNumber} to {endMonthDate} {endDayNumber}, {yearNumber}",
          description: {
            text: "A date range for an event that starts and ends in different months in the same year (ex: January 30-February 4, 2022)",
          },
        },
        {
          startMonthDate,
          startDayNumber,
          endMonthDate,
          endDayNumber,
          yearNumber: yearNumber,
        }
      );
    }
    return strictLocalize(
      {
        defaultMessage:
          "{monthDate} {startDayNumber} to {endDayNumber}, {yearNumber}",
        description: {
          text: "A date range for an event that starts and ends in the same month and year (ex: January 20-25, 2022)",
        },
      },
      {
        monthDate: startMonthDate,
        startDayNumber,
        endDayNumber,
        yearNumber: yearNumber,
      }
    );
  }
  if (hasEndMonth && endMonthDate) {
    return strictLocalize(
      {
        defaultMessage:
          "{startMonthDate} {startDayNumber} to {endMonthDate} {endDayNumber}",
        description: {
          text: "A date range for an event that starts and ends in different months (ex: January 30-February 4)",
        },
      },
      {
        startMonthDate,
        startDayNumber,
        endMonthDate,
        endDayNumber,
      }
    );
  }
  return strictLocalize(
    {
      defaultMessage: "{monthDate} {startDayNumber} to {endDayNumber}",
      description: {
        text: "A date range for an event that starts and ends in the same month (ex: January 20-25)",
      },
    },
    {
      monthDate: startMonthDate,
      startDayNumber,
      endDayNumber,
    }
  );
};

const earlyAccessRangeDate = (
  strictLocalize: StrictLocalizeFunction,
  event?: ICurrentPromotionalEventDates
) => {
  return getDateRange(strictLocalize, event, true);
};

const mainEventRangeDate = (
  strictLocalize: StrictLocalizeFunction,
  event?: ICurrentPromotionalEventDates
) => {
  return getDateRange(strictLocalize, event);
};

// For strings that only show the market dates, languages other than English want to prepend "From" to the string
const getLocalizedDateIncludingFrom = (
  date: string,
  strictLocalize: StrictLocalizeFunction
): string => {
  return strictLocalize(
    {
      defaultMessage: "{Date}",
      description: {
        text: "Made to allow a change in translation for languages that need to prepend a 'from' preposition here. See FD-113671 for more info.",
      },
    },
    {
      Date: date,
    }
  );
};

const getDateRange = (
  strictLocalize: StrictLocalizeFunction,
  event?: ICurrentPromotionalEventDates,
  isEarlyAccess?: boolean,
  includeYear?: boolean,
  year?: string
) => {
  const earlyAccessStartDay = event?.insider_early_access_start_at
    ? intlDateWithTimezone(
        event.insider_early_access_start_at,
        "d",
        MARKET_TIMEZONE
      )
    : MARKET_FALLBACKS(strictLocalize).earlyAccessDayNumber;

  const startDay = event?.start_at
    ? intlDateWithTimezone(event.start_at, "d", MARKET_TIMEZONE)
    : MARKET_FALLBACKS(strictLocalize).startDayNumber;

  const endDay = event?.end_at
    ? intlDateWithTimezone(event.end_at, "d", MARKET_TIMEZONE)
    : MARKET_FALLBACKS(strictLocalize).endDayNumber;

  const startMonth = event?.start_at
    ? intlDateWithTimezone(event.start_at, "MMMM", MARKET_TIMEZONE)
    : MARKET_FALLBACKS(strictLocalize).month;

  const endMonth = event?.end_at
    ? intlDateWithTimezone(event.end_at, "MMMM", MARKET_TIMEZONE)
    : undefined;

  const yearNumber = year ?? MARKET_FALLBACKS(strictLocalize).yearNumber;
  return generateFormattedRange(
    {
      startDayNumber: isEarlyAccess ? earlyAccessStartDay : startDay,
      endDayNumber: endDay,
      startMonthDate: startMonth,
      endMonthDate: !includeYear ? endMonth : undefined,
      yearNumber: includeYear ? yearNumber : undefined,
    },
    {
      hasYear: includeYear,
      hasEndMonth: false,
    },
    strictLocalize
  );
};

export const getFaireMarketUtils = (
  marketYear: string | undefined,
  strictLocalize: StrictLocalizeFunction
) => {
  const fallbacks = MARKET_FALLBACKS(strictLocalize);
  const earlyAccessAndYearRangeDate = generateFormattedRange(
    {
      startDayNumber: fallbacks.earlyAccessDayNumber,
      endDayNumber: fallbacks.endDayNumber,
      startMonthDate: fallbacks.month,
      yearNumber: marketYear ?? fallbacks.yearNumber,
    },
    {
      hasYear: true,
      hasEndMonth: false,
    },
    strictLocalize
  );

  const earlyAccessRangeDateWithYear = (
    strictLocalize: StrictLocalizeFunction,
    event?: ICurrentPromotionalEventDates
  ) => {
    return getDateRange(
      strictLocalize,
      event,
      true,
      true,
      marketYear ?? fallbacks.yearNumber
    );
  };

  return {
    formatDateInMarketTimezone,
    generateFormattedRange,
    /**
     * @deprecated use earlyAccessRangeDateWithYear instead so that dates from BE are used and hardcoded dates are only a fallback.
     */
    earlyAccessAndYearRangeDate,
    earlyAccessRangeDate,
    mainEventRangeDate,
    getLocalizedDateIncludingFrom,
    earlyAccessRangeDateWithYear,
    MARKET_TIMEZONE,
  };
};
