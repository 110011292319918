import type { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";

import { THREE_LETTER_EUROPE_COUNTRY_CODES } from "./THREE_LETTER_EUROPE_COUNTRY_CODES";
import { THREE_LETTER_PRIMARY_COUNTRY_CODES } from "./THREE_LETTER_PRIMARY_COUNTRY_CODES";

type EuropeSupportedCountry = Extract<
  keyof typeof THREE_LETTER_PRIMARY_COUNTRY_CODES,
  keyof typeof THREE_LETTER_EUROPE_COUNTRY_CODES
>;

const isEurope = (k: keyof typeof ISO3166Alpha3): k is EuropeSupportedCountry =>
  k in THREE_LETTER_EUROPE_COUNTRY_CODES;

export const THREE_LETTER_EUROPE_SUPPORTED_COUNTRIES: Array<EuropeSupportedCountry> =
  (
    Object.keys(THREE_LETTER_PRIMARY_COUNTRY_CODES) as Array<
      keyof typeof THREE_LETTER_PRIMARY_COUNTRY_CODES
    >
  ).filter(isEurope);
