import { ITheme, IHighVisState } from "@faire/web--source/slate/Theme";

const HOT_PINK = "#FF69B4";

export enum HIGH_VIS_FILTER {
  TYPOGRAPHY = "typography",
  LAYOUT = "layout",
  COMPONENT = "component",
}

export const highVisSlateComponent =
  (elementType: keyof IHighVisState) =>
  ({ theme }: { theme: ITheme }) => {
    void theme;
    return theme.highVis?.[elementType]
      ? `border: 1px solid ${HOT_PINK} !important;`
      : "";
  };
