import { request } from '../../../..';
import { FilterSectionDisplayOrder } from '../../../../indigofair/data/FilterSectionDisplayOrder';
import { ISearchBrandsV2Response } from '../../../../indigofair/data/ISearchBrandsV2Response';
import { SearchBrandsSortBy } from '../../../../indigofair/data/SearchBrandsSortBy';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../types';
import qs from 'query-string';

export type QueryParameters = {
  suppress_filter_sections: boolean | undefined;
  filter_keys: Array<string> | undefined;
  filter_section_display_order:
    | keyof typeof FilterSectionDisplayOrder
    | undefined;
  page_size: number | undefined;
  page: number | undefined;
  container_name: string | undefined;
  sort_by: keyof typeof SearchBrandsSortBy | undefined;
  brand_name: string | undefined;
  is_initial_request: boolean | undefined;
  load_upcoming_market_promo: boolean | undefined;
  initial_request_exposed_visual_filter_option_keys: Array<string> | undefined;
  include_product_names: boolean | undefined;
  num_product_images: number | undefined;
  navigation_path: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/v2/search/brands${queryString}`;
};

export const build = (
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    trackClientSideLatency: true,
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/search/brands';

/**
 * Product Area: BRAND_SEARCH
 */
export function execute(
  queryParams?: QueryParameters
): Promise<ISearchBrandsV2Response>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ISearchBrandsV2Response>>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ISearchBrandsV2Response>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ISearchBrandsV2Response | SuccessResponse<ISearchBrandsV2Response>> {
  const { route, ...requestOptions } = build(queryParams, options);
  return request<ISearchBrandsV2Response>(requestOptions);
}
execute[PATH] = 'api/v2/search/brands/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  queryParams?: QueryParameters
): Promise<ISearchBrandsV2Response>;
export function darkRead(
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ISearchBrandsV2Response>>;
export function darkRead(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ISearchBrandsV2Response>;
export function darkRead(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ISearchBrandsV2Response | SuccessResponse<ISearchBrandsV2Response>> {
  const { route, ...requestOptions } = build(queryParams, options);
  return request<ISearchBrandsV2Response>(requestOptions);
}
