export namespace IMessengerParticipantToken {
  /**
   * Return an instance of IMessengerParticipantToken with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IMessengerParticipantToken>
  ): IMessengerParticipantToken =>
    ({
      ...(partial ?? {}),
    }) as IMessengerParticipantToken;
}

export interface IMessengerParticipantToken {
  /**
   * @protobufOption (.faire.common.tokenType) = BRAND
   */
  brand_token: string | undefined;
  /**
   * @protobufOption (.faire.common.tokenType) = RETAILER
   */
  retailer_token: string | undefined;
}
