import {
  instanceOfDeprecatedEvent,
  instanceOfPeteEvent,
} from "@faire/web--source/common/batch-events/utils";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { IRecordClientEventRequest } from "@faire/web-api--source/indigofair/data/IRecordClientEventRequest";
import { IUserEvent } from "@faire/web-api--source/indigofair/data/IUserEvent";

import { PeteEventsQueue } from "./__internal__/PeteEventsQueue";
import { UserEventsQueue } from "./__internal__/UserEventsQueue";

/**
 * @deprecated use `useBatchEventsManager` or `withStores` instead
 */
export class BatchEventsManager {
  static get = singletonGetter(BatchEventsManager);

  private _peteEventsQueue: PeteEventsQueue;

  private _userEventsQueue: UserEventsQueue;

  constructor() {
    this._peteEventsQueue = new PeteEventsQueue();
    this._userEventsQueue = new UserEventsQueue();
  }

  async enqueueEvent(event: IRecordClientEventRequest): Promise<void>;
  async enqueueEvent(event: IUserEvent): Promise<void>;
  // eslint-disable-next-line @faire/prefer-arrow-methods
  async enqueueEvent(event: IRecordClientEventRequest | IUserEvent) {
    if (instanceOfDeprecatedEvent(event)) {
      return this._userEventsQueue.enqueueEvent(event);
    } else if (instanceOfPeteEvent(event)) {
      return this._peteEventsQueue.enqueueEvent(event);
    }
  }

  flush = () => {
    return Promise.all([
      this._peteEventsQueue.flush(),
      this._userEventsQueue.flush(),
    ]);
  };
}
