import { OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_231218 } from "@faire/web-api--source/indigofair/settings/OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_231218";

import {
  assignSetting,
  getSettingValueWithoutAssignment,
} from "./__internal__/getSetting";

export const getSettingOptimizeImageUrlForCacheHit = (): boolean =>
  getSettingValueWithoutAssignment(
    OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_231218,
    false
  );

export const assignOptimizeImageUrlForCacheHit = (): Promise<void> =>
  assignSetting(OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_231218);
