import { ITraceEventMarker } from "@faire/web--source/common/pete/TraceEventMarkerTracker";
import { IManualEventOverrides } from "@faire/web--source/common/pete/types";
import { IPixelCustomDataParameters } from "@faire/web-api--source/indigofair/data/IPixelCustomDataParameters";
import { ISettingEntry } from "@faire/web-api--source/indigofair/settings/ISettingEntry";

export type RequestMethodLowercase =
  | "get"
  | "delete"
  | "head"
  | "options"
  | "post"
  | "put"
  | "patch";

export type RequestMethodUppercase = Uppercase<RequestMethodLowercase>;

export type RequestMethod = RequestMethodLowercase | RequestMethodUppercase;

export type ResponseType = "json" | "text" | "blob";

interface NextFetchRequestConfig {
  revalidate?: number | false;
  tags?: string[];
}

export interface RequestOptions {
  /**
   * Path, or full URL, to fetch.
   */
  url: string;
  /**
   * Optional baseUrl (i.e. origin) to send the request to. Only applies if the `url` field
   * is a relative path. When not specified, falls back to window.location.
   *
   * This field is generally applicable outside a browser context, where we can't
   * infer the base URL from the location, or when sending an arbitrary request to a
   * different origin than the current location.
   */
  baseUrl?: string;
  method: RequestMethod;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers?: any;
  next?: NextFetchRequestConfig;
  /**
   * Whether to include (or omit) credentials in the request.
   * For XHR, this will set `withCredentials`, which defaults to false.
   * For fetch, this will result in `omit` for false, `include` for true, or `same-origin` when not specified.
   */
  withCredentials?: boolean;
  /**
   * Flag the request as a dark read ("X-FAIRE-PERF-DARK").
   * This request option should not be set manually - instead call the `darkRead` helper
   * function generated in web-api, for endpoints annotated `@DarkReads` on the backend.
   */
  darkRead?: boolean;
  /**
   * The subdomain of faire.com that this endpoint should be accessed at.
   */
  subdomain?: string;
  /**
   * Ignore this request when the user agent is the prerender bot.
   *
   * default is empty (false)
   */
  ignoreDuringPrerender?: true | false;
  /**
   * Whether this endpoint should be ignored (not called) by the client for robot crawlers.
   */
  ignoreForRobots?: true | false;
  /**
   * Override the locale header for this dark read request.
   */
  darkReadOverrideLocaleHeader?: boolean;
  /**
   * Allows us to communicate with a DOM request and abort it if required.
   * https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal
   */
  signal?: AbortSignal;
  /**
   * Custom response type for request. If no response type is passed,
   * the fetch request handler will treat the response type as either
   * json or text based on the content-type returned from the response.
   */
  responseType?: ResponseType;
  /**
   * If true, returns a response object instead of the data.
   * Response object including status, headers, as well as the data
   *
   * default is empty (false)
   */
  rawResponse?: true | false;
  /**
   * The cache mode of the request. This controls how the request
   * will interact with the browser's HTTP cache or, if server-side
   * in Next.js, will control how the request will interact with the
   * Next.js data cache.
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
   *
   * https://nextjs.org/docs/app/building-your-application/caching#data-cache
   *
   * defaults to regular HTTP cache behaviour in either context.
   */
  cache?: RequestCache;
  /**
   * The mode for how redirects are handled. When using fetch, this corresponds to the redirect
   * option.
   *
   * When using Axios, this corresponds to the followRedirects option
   * (0 if set to "manual", default if set to "follow").
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/Request/redirect
   *
   * https://axios-http.com/docs/req_config
   *
   * default is empty (follow)
   */
  redirect?: "follow" | "manual";
  /**
   * Callback for tracking upload progress.
   * Only applicable for some requests, and only partially supported.
   */
  onUploadProgress?: (progress: { loaded: number; total: number }) => void;
  /**
   * Whether to track client-side latency for this request.
   */
  trackClientSideLatency?: boolean;
}

export type EndpointOptions = Partial<
  Omit<
    RequestOptions,
    | "url"
    | "method"
    | "params"
    | "data"
    | "darkRead"
    | "responseType"
    | "subdomain"
    | "ignoreDuringPrerender"
    | "ignoreForRobots"
    | "cache"
  >
>;

/**
 * Legacy handler, that passes the response data as the result.
 */
type UnboxedRequestHandler = <T = unknown>(
  config: RequestOptions
) => Promise<T>;

/**
 * Handler that normalizes the response.
 */
export type NormalizedRequestHandler = <T = unknown>(
  config: RequestOptions
) => Promise<Response<T>>;

export type RequestHandler = UnboxedRequestHandler | NormalizedRequestHandler;

/**
 * Handler for modifying an outgoing requests, e.g. amending global headers.
 */
export type RequestInterceptor = (
  config: RequestOptions
) => RequestOptions | Promise<RequestOptions>;

export interface SuccessResponse<T> {
  data: T;
  status: number;
  headers: Record<string, string>;
}

interface FailureResponse {
  error: Error;
  status?: number;
  headers?: Record<string, string>;
}

export type Response<T = unknown> = SuccessResponse<T> | FailureResponse;

export const isSuccessResponse = <T>(
  response: Response<T>
): response is SuccessResponse<T> => {
  return "data" in response;
};

/**
 * Handler for an incoming request response, e.g. augmenting it, or caching, or recording timings.
 */
export type ResponseInterceptor = <T = unknown>(
  request: RequestOptions,
  response: Response<T>
) => Response<T> | Promise<Response<T>>;

export type EventHandler = (event: TrackEvent) => void;

export interface IFaire {
  api?: IFaireApi;
  events?: IFaireEvents;
}

export interface IFaireApi {
  handler?: RequestHandler;
  requestInterceptors?: RequestInterceptor[];
  responseInterceptors?: ResponseInterceptor[];
}

export type TrackEvent = {
  eventKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parameters?: any;
  realTimeTracking?: boolean;
  googleTagManagerEvent?: string;
  facebookPixelEvent?: string;
  pixelCustomDataParams?: Partial<IPixelCustomDataParameters>;
  sourceEventKey?: string;
  sourceEventMarker?: ITraceEventMarker;
  manualOverrides?: IManualEventOverrides;
  bypassSessionRecording?: boolean;
  startTrace?: boolean;
  endTrace?: boolean;
};

export type TrackedEvent = TrackEvent & { eventId: string };

/**
 * Handler for outgoing events, e.g. forwarding to FB.
 */
export type EventInterceptor = (event: TrackedEvent) => void;

export interface IFaireEvents {
  interceptors?: EventInterceptor[];
}

/**
 * Symbol used to identify API import paths, e.g. "api/v2/user/get".
 */
export const PATH = Symbol("faire.web-api.path");

/**
 * Symbol used to identify types, e.g. "boolean".
 */
declare const TYPE: unique symbol;

/**
 * Symbol used to identify clients, e.g. "RETAILER".
 */
declare const CLIENTS: unique symbol;

export type SettingConst<
  S extends string = string,
  T = unknown,
  C extends
    keyof typeof ISettingEntry.SerializeToClient = keyof typeof ISettingEntry.SerializeToClient,
> = S & { [TYPE]: T; [CLIENTS]: C };
