import { request } from '../..';
import { IUser } from '../../indigofair/data/IUser';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../types';

export const path = () => {
  return `/api/user`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/user';

/**
 * Product Area: USERS
 */
export function execute(): Promise<IUser>;
export function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IUser>>;
export function execute(options?: EndpointOptions): Promise<IUser>;
export function execute(
  options?: EndpointOptions
): Promise<IUser | SuccessResponse<IUser>> {
  const { route, ...requestOptions } = build(options);
  return request<IUser>(requestOptions);
}
execute[PATH] = 'api/user/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(): Promise<IUser>;
export function darkRead(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IUser>>;
export function darkRead(options?: EndpointOptions): Promise<IUser>;
export function darkRead(
  options?: EndpointOptions
): Promise<IUser | SuccessResponse<IUser>> {
  const { route, ...requestOptions } = build(options);
  return request<IUser>(requestOptions);
}
