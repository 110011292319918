import { request } from '../../..';
import { CurrencyUnit } from '../../../faire/currency/CurrencyUnit';
import { IRetailer } from '../../../indigofair/data/IRetailer';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';
import qs from 'query-string';

export type QueryParameters = {
  displayCurrency: keyof typeof CurrencyUnit | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (retailerToken: string, queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/retailer/${retailerToken}${queryString}`;
};

export const build = (
  retailerToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(retailerToken),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/retailer/:retailer_token';

/**
 * Product Area: RETAILER_PROFILE_INFO
 */
export function execute(
  retailerToken: string,
  queryParams?: QueryParameters
): Promise<IRetailer>;
export function execute(
  retailerToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IRetailer>>;
export function execute(
  retailerToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IRetailer>;
export function execute(
  retailerToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IRetailer | SuccessResponse<IRetailer>> {
  const { route, ...requestOptions } = build(
    retailerToken,
    queryParams,
    options
  );
  return request<IRetailer>(requestOptions);
}
execute[PATH] = 'api/retailer/retailerToken/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  retailerToken: string,
  queryParams?: QueryParameters
): Promise<IRetailer>;
export function darkRead(
  retailerToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IRetailer>>;
export function darkRead(
  retailerToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IRetailer>;
export function darkRead(
  retailerToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IRetailer | SuccessResponse<IRetailer>> {
  const { route, ...requestOptions } = build(
    retailerToken,
    queryParams,
    options
  );
  return request<IRetailer>(requestOptions);
}
