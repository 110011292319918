/**
 * This list needs to be updated when new locales are added.
 * Locales must match the names of translation files in @faire/web-translations
 */
export const TRANSLATION_LOCALES = [
  "da_DK",
  "de_DE",
  "en_GB",
  "en_US",
  "es_ES",
  "fr_FR",
  "it_IT",
  "nl_NL",
  "pt_PT",
  "sv_SE",
] as const;
