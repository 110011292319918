import { IBadgeList } from '../../indigofair/badges/IBadgeList';
import { ICartItemIssue } from '../../indigofair/data/ICartItemIssue';
import { IFaireMoney } from '../../indigofair/data/IFaireMoney';
import { IImage } from '../../indigofair/data/IImage';
import { IItemCustomization } from '../../indigofair/data/IItemCustomization';
import { IItemPromoPrice } from '../../indigofair/data/IItemPromoPrice';
import { IProduct } from '../../indigofair/data/IProduct';

export namespace ICartItemV2 {
  export enum Type {
    TYPE_UNKNOWN = 'TYPE_UNKNOWN',
    SINGLE_PRODUCT = 'SINGLE_PRODUCT',
    PREPACK = 'PREPACK',
    OPEN_SIZING = 'OPEN_SIZING',
    DISPLAY = 'DISPLAY',
  }

  /**
   * Return an instance of ICartItemV2 with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<ICartItemV2>): ICartItemV2 =>
    ({
      customizations: [],
      issues: [],
      associated_cart_item_tokens: [],
      brand_codes: [],
      settings_to_assign_on_view: [],
      ...(partial ?? {}),
    }) as ICartItemV2;
}

export interface ICartItemV2 {
  /**
   * The cart item token depends on the product that it corresponds to:
   * - for ordinary product, this corresponds to the cart item that powers it
   * - for pre-pack product, this corresponds to one of the cart items that constitute the pre-pack
   * - for open-sizing product, this corresponds to one of the cart items that constitute the open-sizing
   * @protobufOption (.faire.common.tokenType) = CART_ITEM
   */
  token: string | undefined;
  /**
   * @protobufOption (.faire.common.tokenType) = BRAND
   */
  brand_token: string | undefined;
  /**
   * @protobufOption (.faire.common.tokenType) = PRODUCT
   */
  product_token: string | undefined;
  product_option_token: string | undefined;
  /**
   * @protobufOption (.faire.common.tokenType) = PRODUCT_COLLECTION
   */
  product_collection_token: string | undefined;
  name: string | undefined;
  image: IImage | undefined;
  price: IFaireMoney | undefined;
  retail_price: IFaireMoney | undefined;
  prepack_unit_wholesale_price: IFaireMoney | undefined;
  prepack_unit_retail_price: IFaireMoney | undefined;
  promo_price: IItemPromoPrice | undefined;
  total_price: IFaireMoney | undefined;
  /**
   * The brand price less any discounts.
   */
  total_price_with_promos: IItemPromoPrice | undefined;
  customizations: Array<IItemCustomization>;
  /**
   * @protobufOption (faire.common.diffAs) = UNORDERED
   * @protobufOption (faire.common.uniqueKey) = "cartItemToken, reason"
   */
  issues: Array<ICartItemIssue>;
  style_name: string | undefined;
  style_group_name: string | undefined;
  type: keyof typeof ICartItemV2.Type | undefined;
  available_units: number | undefined;
  min_order_quantity: number | undefined;
  unit_multiplier: number | undefined;
  /**
   * @deprecated
   */
  non_returnable_reason: keyof typeof IProduct.NonReturnableReason | undefined;
  quantity: number | undefined;
  display_quantity: number | undefined;
  includes_tester: boolean | undefined;
  tester_price: IFaireMoney | undefined;
  tester_promo_price: IItemPromoPrice | undefined;
  expected_ship_date: number | undefined;
  expected_ship_window_days: number | undefined;
  /**
   * In case of open-sizing products, BE sends back to clients a single cart item and this fields lists all the cart
   * item tokens that constitute the open-sizing product. Clients can then use this information to get/update associated
   * cart items.
   * @protobufOption (faire.common.diffAs) = UNORDERED
   */
  associated_cart_item_tokens: Array<string>;
  /**
   * @protobufOption (faire.common.diffIgnore) = IF_NOT_NULL
   */
  created_at: number | undefined;
  badge_list: IBadgeList | undefined;
  /**
   * If true, retailers can purchase any amount of this item, even if the item is out of stock.
   */
  allow_sales_when_out_of_stock: boolean | undefined;
  non_returnable_reason_data: IProduct.INonReturnableReasonData | undefined;
  is_highlighted_product_from_promotional_event: boolean | undefined;
  /**
   * @protobufOption (faire.common.diffIgnore) = IF_NOT_NULL
   */
  updated_at: number | undefined;
  /**
   * @protobufOption (faire.common.diffAs) = UNORDERED
   */
  brand_codes: Array<string>;
  settings_to_assign_on_view: Array<string>;
}
