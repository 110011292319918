import React from "react";

export interface IAutoDimensionProps {
  height?: string;
  width?: string;
  className?: string;
}

/**
 * HOC to set height and width of SVG icon to auto when unset
 * @param WrappedComponent The component to use auto height & width
 */
export const withAutoDimension = <T extends IAutoDimensionProps>(
  WrappedComponent: React.ComponentType<T>
) => {
  const Component = (props: T) => {
    const height = props.height ?? "auto";
    const width = props.width ?? "auto";
    return <WrappedComponent {...props} height={height} width={width} />;
  };
  return Component;
};
