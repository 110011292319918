import { getPersonalizedCategoriesQueryParams } from "@faire/retailer-shared/categories/categoryRequest";
import { DEFAULT_LOCALE_KEY } from "@faire/web--source/common/consts/DEFAULT_LOCALE";
import { buildLocale } from "@faire/web--source/common/localization/utils";
import { QueryParameters as NonPersonalizedCategoriesParams } from "@faire/web-api--source/api/v3/categories/get";
import {
  useQuery as useCategoriesQuery,
  useInvalidateQuery as useInvalidateCategoriesQuery,
} from "@faire/web-api--source/api/v3/categories/get-hooks";
import {
  useQuery as usePersonalizedCategoriesQuery,
  useInvalidateQuery as useInvalidatePersonalizedCategoriesQuery,
} from "@faire/web-api--source/api/v3/categories/personalized/retailerToken/get-hooks";
import { _allowStateChangesInsideComputed } from "mobx";
import { useCallback, useMemo } from "react";

import { getLocaleKey } from "@faire/retailer/serialized-data/getLocaleKey";
import { getNavigationTreeHashCode } from "@faire/retailer/serialized-data/getNavigationTreeHashCode";
import { getSessionCountry } from "@faire/retailer/serialized-data/getSessionCountry";
import { findActiveCategoryByPathIdentifier } from "@faire/retailer/stores/domain/CategoryStore/findByPathIdentifier";
import { useRetailerStore } from "@faire/retailer/stores/domain/RetailerStore";

export const useCategoryStore = () => {
  const localeKey = getLocaleKey();
  const navigationTreeHashCode = getNavigationTreeHashCode();
  const { retailerToken, isInsider } = useRetailerStore([
    "retailerToken",
    "isInsider",
  ]);
  const locale = buildLocale(localeKey ?? DEFAULT_LOCALE_KEY);
  const nonPersonalizedParams = useMemo(
    () =>
      NonPersonalizedCategoriesParams.build({
        hashCode: navigationTreeHashCode,
        locale,
        country: getSessionCountry(),
      }),
    [locale, navigationTreeHashCode]
  );
  const personalizedParams = useMemo(
    () =>
      getPersonalizedCategoriesQueryParams(
        locale,
        navigationTreeHashCode,
        isInsider
      ),
    [isInsider, locale, navigationTreeHashCode]
  );
  const { data: categoriesData, isFetching: isCategoriesFetching } =
    useCategoriesQuery(nonPersonalizedParams, {
      enabled: !retailerToken,
      staleTime: Infinity,
    });
  const {
    data: personalizedCategoriesData,
    isFetching: isPersonalizedCategoriesFetching,
  } = usePersonalizedCategoriesQuery(retailerToken ?? "", personalizedParams, {
    enabled: !!retailerToken,
    staleTime: Infinity,
  });

  const categories =
    categoriesData?.category_list ?? personalizedCategoriesData?.category_list;

  const categoriesLoading =
    isCategoriesFetching ||
    isPersonalizedCategoriesFetching ||
    categories === undefined;

  const shouldShowVerticalTile = useCallback(
    (pathIdentifier?: string): boolean => {
      if (pathIdentifier === undefined) {
        return false;
      }
      const categoriesForPathIdentifier = findActiveCategoryByPathIdentifier(
        pathIdentifier,
        categories ?? []
      );
      return !!categoriesForPathIdentifier?.tree_node_metadata
        ?.show_vertical_tile;
    },
    [categories]
  );

  const invalidateCategories = useInvalidateCategoriesQuery();
  const invalidatePersonalizedCategories =
    useInvalidatePersonalizedCategoriesQuery();

  const refetchCategories = useCallback(async () => {
    if (retailerToken) {
      invalidatePersonalizedCategories(retailerToken, personalizedParams);
    } else {
      invalidateCategories(nonPersonalizedParams);
    }
  }, [
    retailerToken,
    invalidatePersonalizedCategories,
    personalizedParams,
    invalidateCategories,
    nonPersonalizedParams,
  ]);

  return {
    categories,
    categoriesLoading,
    shouldShowVerticalTile,
    refetchCategories,
  };
};
