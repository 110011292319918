import { ISignUpRequest } from "@faire/web-api--source/indigofair/data/ISignUpRequest";

export enum BUSINESS_TYPE {
  POP_UP_STORE = "Pop-up Store",
  ONLINE_ONLY = "Online Only",
  BRICK_AND_MORTAR_SHOP = "Brick & Mortar Store",
  OTHER = "Other",
  CONSUMER = "I Don't Have a Store",
}

export const BUSINESS_TYPE_REVAMP: Record<
  ISignUpRequest.BusinessPlatform,
  string
> = {
  [ISignUpRequest.BusinessPlatform.POP_UP]: BUSINESS_TYPE.POP_UP_STORE,
  [ISignUpRequest.BusinessPlatform.ONLINE_ONLY]: BUSINESS_TYPE.ONLINE_ONLY,
  [ISignUpRequest.BusinessPlatform.BRICK_AND_MORTAR]:
    BUSINESS_TYPE.BRICK_AND_MORTAR_SHOP,
  [ISignUpRequest.BusinessPlatform.OTHER_PLATFORM]: BUSINESS_TYPE.OTHER,
  [ISignUpRequest.BusinessPlatform.BUSINESS_PLATFORM_UNKNOWN]: "Unknown",
};
