import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { logError } from "@faire/web--source/common/logging";
import { IPixelContents } from "@faire/web-api--source/indigofair/data/IPixelContents";
import { IPixelCustomDataParameters } from "@faire/web-api--source/indigofair/data/IPixelCustomDataParameters";
import { TrackedEvent } from "@faire/web-api--source/types";

import { getSettingTwitterEventNameToId } from "../../settings/getSettingTwitterEventNameToId";

export const twitterAdsInterceptor = (params: TrackedEvent) => {
  if (params.facebookPixelEvent) {
    try {
      emitTwitterPixelEvent(
        params.facebookPixelEvent,
        params.pixelCustomDataParams,
        { eventID: params.eventId }
      );
    } catch (_) {
      /* ignore */
    }
  }
};

/* docs: https://business.twitter.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html */
declare function twitterPixel(
  _: "event",
  event: string,
  value: Partial<Record<string, any>> | undefined
): void;

export const emitTwitterPixelEvent = (
  event: string,
  vars: Partial<IPixelCustomDataParameters> | undefined,
  context: { eventID: string }
) => {
  const twq = getGlobalProperty<typeof twitterPixel>("twq");
  const twitterAdsPixelId = getGlobalProperty<string>("twitterAdsPixelId");
  if (!twq) {
    logError(`twq not initialized`);
    return;
  } else if (!twitterAdsPixelId) {
    if (getGlobalProperty("envName") === "production") {
      logError(`twitterAdsPixelId is not specified`);
    }
    return;
  }

  const eventNameToIDMap = getSettingTwitterEventNameToId();
  const eventID = "tw-" + twitterAdsPixelId + "-" + eventNameToIDMap[event]; //tw-oev03-ofgsl

  const contents = vars?.contents?.map((content: IPixelContents) => {
    return {
      content_id: content.id,
      content_price: content.item_price,
      num_items: content.quantity,
    };
  });
  const data = {
    value: vars?.cart_value ? vars.cart_value : vars?.value,
    currency: vars?.currency,
    conversion_id: context.eventID,
    contents: contents,
  };
  twq("event", eventID, data);
};
