/**
 * How Retailers selected to pay.
 */
export enum PaymentTerm {
  UNSPECIFIED = 'UNSPECIFIED',
  HOLD_ON_PLACEMENT = 'HOLD_ON_PLACEMENT',
  PAYMENT_ON_SHIPMENT = 'PAYMENT_ON_SHIPMENT',
  NET90 = 'NET90',
  CONSIGNMENT = 'CONSIGNMENT',
  NET90_ON_FIRST_ORDER = 'NET90_ON_FIRST_ORDER',
  NET60 = 'NET60',
  NET60_ON_FIRST_ORDER = 'NET60_ON_FIRST_ORDER',
  NET30 = 'NET30',
  INSTALLMENTS = 'INSTALLMENTS',
  DISCOVERY_NET90 = 'DISCOVERY_NET90',
}
