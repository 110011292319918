import { request } from '../../../../..';
import { IItemInventoryResponse } from '../../../../../faire/carts/IItemInventoryResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../../types';
import qs from 'query-string';

export type QueryParameters = {
  brand_tokens: Array<string> | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (cartToken: string, queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/v2/carts/${cartToken}/item-inventory${queryString}`;
};

export const build = (
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(cartToken),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/carts/:cartToken/item-inventory';

/**
 * Product Area: CART
 */
export function execute(
  cartToken: string,
  queryParams?: QueryParameters
): Promise<IItemInventoryResponse>;
export function execute(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IItemInventoryResponse>>;
export function execute(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IItemInventoryResponse>;
export function execute(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IItemInventoryResponse | SuccessResponse<IItemInventoryResponse>> {
  const { route, ...requestOptions } = build(cartToken, queryParams, options);
  return request<IItemInventoryResponse>(requestOptions);
}
execute[PATH] = 'api/v2/carts/cartToken/item-inventory/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  cartToken: string,
  queryParams?: QueryParameters
): Promise<IItemInventoryResponse>;
export function darkRead(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IItemInventoryResponse>>;
export function darkRead(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IItemInventoryResponse>;
export function darkRead(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IItemInventoryResponse | SuccessResponse<IItemInventoryResponse>> {
  const { route, ...requestOptions } = build(cartToken, queryParams, options);
  return request<IItemInventoryResponse>(requestOptions);
}
