import { SettingConst } from '../../types';

/**
 * Pre-multiplies the images DPR to the dimensions and then rounds them to a mutliple of 360
 */
export const BRAND_PORTAL_OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_240422 =
  'BRAND_PORTAL_OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_240422' as SettingConst<
    'BRAND_PORTAL_OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_240422',
    boolean,
    'MAKER'
  >;
