import { request } from '../../../..';
import { IProductCollectionMetadataResponse } from '../../../../indigofair/data/IProductCollectionMetadataResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../types';
import qs from 'query-string';

export type QueryParameters = {
  page: number | undefined;
  pageSize: number | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (brandToken: string, queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/brand/${brandToken}/published-product-collection-metadata${queryString}`;
};

export const build = (
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(brandToken),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route =
  '/api/brand/:brand_token/published-product-collection-metadata';

/**
 * Product Area: CORE_COLLECTIONS
 */
export function execute(
  brandToken: string,
  queryParams?: QueryParameters
): Promise<IProductCollectionMetadataResponse>;
export function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IProductCollectionMetadataResponse>>;
export function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IProductCollectionMetadataResponse>;
export function execute(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  | IProductCollectionMetadataResponse
  | SuccessResponse<IProductCollectionMetadataResponse>
> {
  const { route, ...requestOptions } = build(brandToken, queryParams, options);
  return request<IProductCollectionMetadataResponse>(requestOptions);
}
execute[PATH] =
  'api/brand/brandToken/published-product-collection-metadata/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters
): Promise<IProductCollectionMetadataResponse>;
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IProductCollectionMetadataResponse>>;
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IProductCollectionMetadataResponse>;
export function darkRead(
  brandToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  | IProductCollectionMetadataResponse
  | SuccessResponse<IProductCollectionMetadataResponse>
> {
  const { route, ...requestOptions } = build(brandToken, queryParams, options);
  return request<IProductCollectionMetadataResponse>(requestOptions);
}
