import { BESTSELLERS_CATEGORY_URL_ID } from "@faire/retailer-shared/categories/urlId";
import { Button } from "@faire/web--source/slate/Button";
import { Column } from "@faire/web--source/slate/Layout";
import { Spacer } from "@faire/web--source/slate/spacing";
import { Typography } from "@faire/web--source/slate/Typography";
import { useCurrentPath } from "@faire/web--source/ui/hooks/useCurrentPath";
import { StrictLocalMsg } from "@faire/web--source/ui/lib/localization";
import categoryRoute from "@faire/web-api--source/routes/category/c1CategoryUrlId";
import * as React from "react";

import { ShopOurCategories } from "@faire/retailer/components/ShopOurCategoriesV2";
import { useIsLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";

import { container } from "./PageNotFoundTemplate.css";

interface IProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  trackingContainerName: string;
}

export const PageNotFoundTemplate: React.FC<IProps> = ({
  title,
  subtitle,
  trackingContainerName,
}) => {
  const isLoggedIn = useIsLoggedInRetailer();
  const currentPath = useCurrentPath();
  const signupLink = currentPath
    .replace({
      signUp: "1",
    })
    .toString();

  return (
    <Column
      className={container}
      align={{ mobileAndAbove: "flex-start", tabletAndAbove: "center" }}
    >
      <Typography
        variant={{
          mobileAndAbove: "displaySSerifRegular",
          tabletAndAbove: "displayMSerifRegular",
        }}
        data-test-id="page-not-found-header"
      >
        {title}
      </Typography>
      {subtitle ? (
        <>
          <Spacer height={{ mobileAndAbove: "2x", tabletAndAbove: "3x" }} />
          <Typography
            variant="subheadingSansRegular"
            data-test-id="page-not-found-text"
            align={{ mobileAndAbove: "left", tabletAndAbove: "center" }}
          >
            {subtitle}
          </Typography>
        </>
      ) : null}
      <Spacer height={{ mobileAndAbove: "2x", tabletAndAbove: "3x" }} />
      {isLoggedIn ? (
        <Button
          variant="primary"
          linkTo={categoryRoute(BESTSELLERS_CATEGORY_URL_ID)}
        >
          <StrictLocalMsg
            defaultMessage="Shop bestsellers"
            description={{
              text: "Button nudging the user to explore best sellers",
              img: "https://cdn.faire.com/fastly/2e95f6752e31970ef6fa49d2ab92aee7a12eac48b889d247f2125dc86b7d4213.png",
            }}
          />
        </Button>
      ) : (
        <Button variant="primary" linkTo={signupLink}>
          <StrictLocalMsg
            defaultMessage="Sign up to shop"
            description={{
              text: "Button nudging the user to sign up to shop",
              img: "https://cdn.faire.com/fastly/2725932a8b2e4263c801c6bb205a4c50a985fd89b30ba71a28fb09ba42915a24.png",
            }}
          />
        </Button>
      )}
      <Spacer
        height={{
          mobileAndAbove: "4x",
          tabletAndAbove: "9x",
          desktopAndAbove: "10x",
        }}
      />
      <ShopOurCategories trackingContainerName={trackingContainerName} />
    </Column>
  );
};
