import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'cx_foundations-errorboundary-click';

/**
 * Recording the contact us link click from error boundary page
 */
export function trackCxFoundationsErrorboundaryClick(): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
  });
}
