import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { uuid } from "@faire/web--source/common/uuid";

const SOURCE_UNKNOWN_MARKER: ITraceEventMarker = {
  sourceEventHash: "NO_SOURCE_EVENT_HASH",
  sourceEventStart: 0,
  sourceEventKey: "NO_SOURCE_EVENT_KEY",
};

/**
 * This singleton class is used to generate and track hashes
 * of PETE events to be referenced by any subsequent trace events
 * for the purpose of linking source and trace events.
 * @deprecated use `useTraceEventTracker` or `withStores` instead
 */
export class TraceEventTracker {
  static get = singletonGetter(TraceEventTracker);

  private traceMap = new Map<string, ITraceEventMarker>();

  getTraceHash = (eventKey: string) => {
    return this.traceMap.get(eventKey) || SOURCE_UNKNOWN_MARKER;
  };

  createTraceHash = (eventKey: string, eventTime: number) => {
    this.traceMap.set(eventKey, {
      sourceEventHash: uuid(),
      sourceEventStart: eventTime,
      sourceEventKey: eventKey,
    });
    return this.traceMap.get(eventKey) || SOURCE_UNKNOWN_MARKER;
  };
}

/**
 * This interface represents the firing of a PETE event.
 * It is used to track the source event for subsequent trace events.
 */
export interface ITraceEventMarker {
  sourceEventStart: number;
  sourceEventHash: string;
  sourceEventKey: string;
}
