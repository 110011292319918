import { SettingConst } from '../../types';

/**
 * Determine whether to enable language selector on the FE.
 */
export const ENABLE_SEO_LANGUAGE_SELECTOR =
  'ENABLE_SEO_LANGUAGE_SELECTOR' as SettingConst<
    'ENABLE_SEO_LANGUAGE_SELECTOR',
    boolean,
    'RETAILER' | 'VISITOR_WEB'
  >;
