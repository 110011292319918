import { CurrencyStore } from "@faire/web--source/common/currency/CurrencyStore";
import {
  Currency,
  selectCurrency,
} from "@faire/web--source/common/currencyConfig";
import { formatMoney, RoundingStrategy } from "@faire/web--source/common/money";
import { ICurrencyConversionRate } from "@faire/web-api--source/indigofair/data/ICurrencyConversionRate";
import { IFaireMoney } from "@faire/web-api--source/indigofair/data/IFaireMoney";
import { runInAction } from "mobx";

import { DEFAULT_CURRENCY } from "@faire/retailer/consts/DEFAULT_CURRENCY";
import { isLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { RetailerStore } from "@faire/retailer/stores/domain/RetailerStore";

interface IFormatCents {
  cents: number;
  round?: boolean;
  dropCents?: boolean;
  dropEmptyCents?: boolean;
  /**
   * Rounds the value up to the nearest whole-dollar amount.
   */
  dropCentsRoundUp?: boolean;
  currency?: Currency;
  hideCurrency?: boolean;
  conversionRate?: ICurrencyConversionRate;
  shouldConvert?: boolean;
}

/**
 * configures the currency string formatting
 * @param userLocale where the user is located
 * @param currencyConversionRate conversion rate given by backend based on locale
 */
export const configureCurrency = (
  userLocale?: string,
  currencyConversionRate?: ICurrencyConversionRate
) => {
  if (
    (RetailerStore.get().isEuropeNonUk || RetailerStore.get().isUK) &&
    RetailerStore.get().currency !==
      CurrencyStore.get().configuration.selectedCurrency &&
    isLoggedInRetailer()
  ) {
    selectCurrency();
  }
  runInAction(() => {
    CurrencyStore.get().setConfiguration({
      ...CurrencyStore.get().configuration,
      userLocale,
      currencyConversionRate,
    });
  });
};

/**
 * New function to make it easy to transition from using a hardcoded dollar sign
 * to being more currency aware. Will slowly move over usages of formatAndConvertCents.
 * Takes a number of cents and returns a dollar amount string after conversion
 * to local currency. Currency type is specified for Canadian users only at the
 * moment
 * @param param0 IFormatCents configuration
 * @deprecated Please use FaireMoney with either formatMoney() or <Money /> for greater currency awareness
 */
export const formatAndConvertCentsWithPrefix = ({
  cents,
  round,
  dropCents,
  dropEmptyCents,
  dropCentsRoundUp,
  hideCurrency,
  conversionRate = CurrencyStore.get().configuration.currencyConversionRate,
}: IFormatCents): string => {
  const { selectedCurrency } = CurrencyStore.get().configuration;

  const roundingStrategy = round
    ? RoundingStrategy.ROUND
    : dropCents
      ? RoundingStrategy.DROP_CENTS
      : dropCentsRoundUp
        ? RoundingStrategy.DROP_CENTS_ROUND_UP
        : RoundingStrategy.NONE;

  return formatMoney({
    money: convertAndBuildFaireMoney(cents, conversionRate, selectedCurrency),
    roundingStrategy,
    omitCentsIfZero: dropEmptyCents,
    disableAutoConvertCurrency: true,
    omitCurrency: hideCurrency,
  });
};

/**
 * Takes a number of cents and returns a dollar amount string after conversion
 * to local currency. Currency type is specified for Canadian users only at the
 * moment
 * @param param0 IFormatCents configuration
 * @deprecated use formatAndConvertCentsWithPrefix for currency awareness
 */
export const formatAndConvertCents = ({
  cents,
  round,
  dropCents,
  dropEmptyCents,
  dropCentsRoundUp,
  hideCurrency,
  conversionRate = CurrencyStore.get().configuration.currencyConversionRate,
  shouldConvert = true,
}: IFormatCents): string => {
  const { selectedCurrency } = CurrencyStore.get().configuration;

  const roundingStrategy = round
    ? RoundingStrategy.ROUND
    : dropCents
      ? RoundingStrategy.DROP_CENTS
      : dropCentsRoundUp
        ? RoundingStrategy.DROP_CENTS_ROUND_UP
        : RoundingStrategy.NONE;

  return formatMoney({
    money: shouldConvert
      ? convertAndBuildFaireMoney(cents, conversionRate, selectedCurrency)
      : IFaireMoney.build({
          amount_cents: cents,
          currency: selectedCurrency,
        }),
    roundingStrategy,
    omitCentsIfZero: dropEmptyCents,
    disableAutoConvertCurrency: true,
    omitCurrency: hideCurrency,
  });
};

/**
 * converts a USD cents amount using given or selected currency conversion rate and returns IFaireMoney
 * @param cents cost in USD cents
 * @param currencyConversionRate rate to use for conversion
 * @param currency the currency to display to the user
 */
export const convertAndBuildFaireMoney = (
  cents: number,
  currencyConversionRate?: ICurrencyConversionRate,
  currency?: Currency
): IFaireMoney => {
  const { selectedCurrency } = CurrencyStore.get().configuration;

  return IFaireMoney.build({
    amount_cents:
      selectedCurrency ===
      (RetailerStore.get().retailer?.currency ?? DEFAULT_CURRENCY)
        ? cents
        : convertCents(
            cents,
            currencyConversionRate ??
              CurrencyStore.get().configuration.currencyConversionRate
          ),
    currency: currency,
  });
};

/**
 * converts a USD cents amount using given rate
 * @param cents cost in USD cents
 * @param currencyConversionRate rate to use for conversion
 */
const convertCents = (
  cents: number,
  currencyConversionRate?: ICurrencyConversionRate
): number =>
  currencyConversionRate && currencyConversionRate.rate
    ? cents * currencyConversionRate.rate
    : cents;
