import { CurrencyUnit } from "@faire/web-api--source/faire/currency/CurrencyUnit";
import { IGeoConstraint } from "@faire/web-api--source/faire/geo/IGeoConstraint";
import { CountryGroup } from "@faire/web-api--source/indigofair/data/CountryGroup";
import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";

export const CURRENCY_TO_GEO_CONSTRAINT: {
  [key in CurrencyUnit]?: IGeoConstraint;
} = {
  [CurrencyUnit.CAD]: IGeoConstraint.build({
    country: ISO3166Alpha3.CAN,
  }),
  [CurrencyUnit.USD]: IGeoConstraint.build({
    country: ISO3166Alpha3.USA,
  }),
  [CurrencyUnit.GBP]: IGeoConstraint.build({
    country: ISO3166Alpha3.GBR,
  }),
  [CurrencyUnit.EUR]: IGeoConstraint.build({
    country_group: CountryGroup.EUROPEAN_UNION,
  }),
  [CurrencyUnit.SEK]: IGeoConstraint.build({
    country_group: CountryGroup.EUROPEAN_UNION,
  }),
  [CurrencyUnit.DKK]: IGeoConstraint.build({
    country_group: CountryGroup.EUROPEAN_UNION,
  }),
  [CurrencyUnit.NOK]: IGeoConstraint.build({
    country_group: CountryGroup.EUROPEAN_UNION,
  }),
  [CurrencyUnit.CHF]: IGeoConstraint.build({
    country_group: CountryGroup.EUROPEAN_UNION,
  }),
  [CurrencyUnit.AUD]: IGeoConstraint.build({
    country: ISO3166Alpha3.AUS,
  }),
};
