"use client";

import React from "react";

import { createSVGIcon } from "../util";

export const Apple = createSVGIcon(
  <>
    <g clipPath="url(#clip0_526_5777)">
      <path
        d="M10.1267 5.74667C9.33667 5.74667 8.11417 4.84833 6.82667 4.88C5.12667 4.9025 3.56833 5.86583 2.6925 7.39167C0.928334 10.4542 2.2375 14.9775 3.95833 17.4667C4.8025 18.6783 5.79833 20.0417 7.11833 19.9992C8.385 19.945 8.86 19.1767 10.3975 19.1767C11.9233 19.1767 12.3558 19.9992 13.6975 19.9667C15.0617 19.945 15.9275 18.7333 16.7608 17.51C17.7242 16.1033 18.1242 14.7392 18.1458 14.6642C18.1133 14.6533 15.4942 13.6467 15.4625 10.6167C15.4408 8.08333 17.5292 6.87167 17.6267 6.8175C16.4358 5.07583 14.6075 4.88083 13.9683 4.8375C12.3017 4.7075 10.9058 5.74583 10.1267 5.74583V5.74667ZM12.9417 3.19167C13.6442 2.34833 14.1083 1.16917 13.9792 0C12.9733 0.0433333 11.7608 0.670833 11.0358 1.515C10.3858 2.26167 9.82417 3.46333 9.975 4.61C11.09 4.69667 12.2375 4.03667 12.9408 3.1925"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_526_5777">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </>,
  "Apple",
  (strictLocalize) => ({
    viewBox: "0 0 20 20",
    fill: "none",
    titleAccess: strictLocalize({
      defaultMessage: "Apple logo",
      description: { text: "The label for Apple's company logo icon" },
    }),
  })
);
