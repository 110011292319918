/**
 * Wrapper for an asynchronous function that limits calls so that if the
 * function is called again before the first promise resolves, the same promise
 * is returned as opposed to calling the wrapped function again.
 */
export const throttlePromise = <T>(
  asyncFunc: () => Promise<T>
): (() => Promise<T>) => {
  let lastRequest: Promise<any> | undefined;

  return async () => {
    if (lastRequest) {
      return lastRequest;
    }
    lastRequest = asyncFunc();

    const resetLastRequest = () => {
      lastRequest = undefined;
    };
    lastRequest.then(resetLastRequest).catch(resetLastRequest);
    return lastRequest;
  };
};
