import { SettingConst } from '../../types';

/**
 * Controls whether or not we use server components to render the cart page
 */
export const NEXTJS_RSC_CART_PAGE_240917 =
  'NEXTJS_RSC_CART_PAGE_240917' as SettingConst<
    'NEXTJS_RSC_CART_PAGE_240917',
    boolean,
    'RETAILER' | 'RETAILER_HEAD_TAGS' | 'HOTJAR_RETAILER'
  >;
