import { ISO_TO_COUNTRY_NAME_WITHOUT_SANCTIONS } from "@faire/web--source/common/consts/ISO_TO_COUNTRY_NAME";
import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";

import { getDefaultTwoLetterLanguageFromCountry } from "../consts/THREE_LETTER_COUNTRY_TO_DEFAULT_TWO_LETTER_LANGUAGE";
import { THREE_LETTER_PRIMARY_COUNTRY_CODES } from "../consts/THREE_LETTER_PRIMARY_COUNTRY_CODES";

import { StrictLocalizeFunction } from "./localize";

// this is mainly for SEO purposes (title tags)
// please do not update the logic before speaking to the SEO team
export const getCountryDisplayNameFromSessionCountry = (
  sessionCountry: keyof typeof ISO3166Alpha3 | undefined,
  strictLocalize: StrictLocalizeFunction
): string | null => {
  // we do not want to show US in title tags
  if (
    !sessionCountry ||
    sessionCountry === THREE_LETTER_PRIMARY_COUNTRY_CODES.USA
  ) {
    return null;
  }

  if (sessionCountry === THREE_LETTER_PRIMARY_COUNTRY_CODES.GBR) {
    return strictLocalize({
      defaultMessage: "UK",
      description: {
        text: "Short form country name of United Kingdom.",
      },
    });
  }

  // For countries that are the default for a particular language,
  // return that language code instead of the country name. i.e. we show
  // "Faire DE" instead of "Faire Germany", because faire.com/de is the
  // home for all German-speaking users.
  const defaultLanguageCode =
    getDefaultTwoLetterLanguageFromCountry(sessionCountry);
  if (defaultLanguageCode) {
    return defaultLanguageCode;
  }

  return ISO_TO_COUNTRY_NAME_WITHOUT_SANCTIONS(strictLocalize)[
    sessionCountry as keyof typeof ISO_TO_COUNTRY_NAME_WITHOUT_SANCTIONS
  ];
};
