import { request } from '../../..';
import { IGetBuyItAgainProductsRequest } from '../../../faire/bia/client/IGetBuyItAgainProductsRequest';
import { IGetBuyItAgainProductsResponse } from '../../../faire/bia/client/IGetBuyItAgainProductsResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';

export const path = () => {
  return `/api/buy-it-again/product-data`;
};

export const build = (
  data: IGetBuyItAgainProductsRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/buy-it-again/product-data';

/**
 * Product Area: BUY_IT_AGAIN
 */
export function execute(
  data: IGetBuyItAgainProductsRequest
): Promise<IGetBuyItAgainProductsResponse>;
export function execute(
  data: IGetBuyItAgainProductsRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetBuyItAgainProductsResponse>>;
export function execute(
  data: IGetBuyItAgainProductsRequest,
  options?: EndpointOptions
): Promise<IGetBuyItAgainProductsResponse>;
export function execute(
  data: IGetBuyItAgainProductsRequest,
  options?: EndpointOptions
): Promise<
  | IGetBuyItAgainProductsResponse
  | SuccessResponse<IGetBuyItAgainProductsResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<IGetBuyItAgainProductsResponse>(requestOptions);
}
execute[PATH] = 'api/buy-it-again/product-data/post';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  data: IGetBuyItAgainProductsRequest
): Promise<IGetBuyItAgainProductsResponse>;
export function darkRead(
  data: IGetBuyItAgainProductsRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetBuyItAgainProductsResponse>>;
export function darkRead(
  data: IGetBuyItAgainProductsRequest,
  options?: EndpointOptions
): Promise<IGetBuyItAgainProductsResponse>;
export function darkRead(
  data: IGetBuyItAgainProductsRequest,
  options?: EndpointOptions
): Promise<
  | IGetBuyItAgainProductsResponse
  | SuccessResponse<IGetBuyItAgainProductsResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<IGetBuyItAgainProductsResponse>(requestOptions);
}
