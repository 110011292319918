import { SettingConst } from '../../types';

/**
 * Enables retailers to request unlisted products from Brands in carts
 */
export const ENABLE_UNLISTED_PRODUCTS_REQUESTS_250115 =
  'ENABLE_UNLISTED_PRODUCTS_REQUESTS_250115' as SettingConst<
    'ENABLE_UNLISTED_PRODUCTS_REQUESTS_250115',
    boolean,
    'RETAILER'
  >;
