import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { logError } from "@faire/web--source/common/logging";
import { IPixelContents } from "@faire/web-api--source/indigofair/data/IPixelContents";
import { IPixelCustomDataParameters } from "@faire/web-api--source/indigofair/data/IPixelCustomDataParameters";
import { TrackedEvent } from "@faire/web-api--source/types";

export const tiktokAdsInterceptor = (params: TrackedEvent) => {
  if (params.facebookPixelEvent) {
    try {
      emitTiktokPixelEvent(
        params.facebookPixelEvent,
        params.pixelCustomDataParams,
        { eventID: params.eventId }
      );
    } catch (_) {
      /* ignore */
    }
  }
};

// Docs: https://ads.tiktok.com/help/article/standard-events-parameters?lang=en
interface TiktokPixel {
  track: (event: string, data: Partial<Record<string, any>>) => void;
}

export const emitTiktokPixelEvent = (
  event: string,
  vars: Partial<IPixelCustomDataParameters> | undefined,
  context: { eventID: string }
) => {
  const ttq = getGlobalProperty<TiktokPixel>("ttq");

  if (!ttq) {
    if (getGlobalProperty("envName") === "production") {
      logError(`ttq not initialized`);
    }
    return;
  }

  const contents = vars?.contents?.map((content: IPixelContents) => {
    return {
      content_id: content.id,
      price: content.item_price,
      quantity: content.quantity,
    };
  });
  const data = {
    value: vars?.cart_value ?? vars?.value,
    currency: vars?.currency,
    conversion_id: context.eventID,
    contents: contents,
  };

  ttq.track(event, data);
};
