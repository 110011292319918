export enum ProductArea {
  /**
   * Owned by Unassigned (#pod-unassigned)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_UNASSIGNED
   */
  PRODUCT_AREA_UNKNOWN = 'PRODUCT_AREA_UNKNOWN',
  /**
   * About Us Page
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "About Us Page"
   */
  ABOUT_US_PAGE = 'ABOUT_US_PAGE',
  /**
   * Admin tooling
   * Admin tooling
   * Owned by Retailer Order Placement (#pod-retailer-order-placement-sla)
   * @protobufOption (description) = "Admin tooling"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_PLACEMENT
   * @protobufOption (title) = "Admin tooling"
   */
  ADMIN = 'ADMIN',
  /**
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   */
  ADYEN = 'ADYEN',
  /**
   * Adyen Payout
   * Adyen brand side integration (Adyen for Platforms)
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Adyen brand side integration (Adyen for Platforms)"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Adyen Payout"
   */
  ADYEN_PAYOUT = 'ADYEN_PAYOUT',
  /**
   * Affiliates
   * Affiliate link system for brands
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (description) = "Affiliate link system for brands"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "Affiliates"
   */
  AFFILIATES = 'AFFILIATES',
  /**
   * AI Bridge
   * AI Bridge is a service that provides an interface for executing ML models
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @protobufOption (description) = "AI Bridge is a service that provides an interface for executing ML models"
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "AI Bridge"
   */
  AI_BRIDGE = 'AI_BRIDGE',
  /**
   * Alcohol
   * Alcohol sales, category restrictions related to alcohol
   * Owned by Shipping (#pod-shipping)
   * @protobufOption (description) = "Alcohol sales, category restrictions related to alcohol"
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   * @protobufOption (title) = "Alcohol"
   */
  ALCOHOL = 'ALCOHOL',
  /**
   * Alert Banners
   * Alert banners for brands and retailers
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Alert banners for brands and retailers"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Alert Banners"
   */
  ALERT_BANNERS = 'ALERT_BANNERS',
  /**
   * Amex landing page
   * AMEX sign up offer landing page
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "AMEX sign up offer landing page"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Amex landing page"
   */
  AMEX = 'AMEX',
  /**
   * Anti-Abuse
   * Owned by Data Team Financial Products (#data-team-financial-products)
   * @protobufOption (pod) = FAIRE_POD_DATA_TEAM_FINANCIAL_PRODUCTS
   * @protobufOption (title) = "Anti-Abuse"
   */
  ANTI_ABUSE = 'ANTI_ABUSE',
  /**
   * Api Performance
   * Api Performance and Metrics
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Api Performance and Metrics"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Api Performance"
   */
  API_PERFORMANCE = 'API_PERFORMANCE',
  /**
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   */
  APPAREL_BRAND = 'APPAREL_BRAND',
  /**
   * Apparel Product Based Collections
   * Apparel Collections are Product Based Collections catered for Apparel Brands
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (description) = "Apparel Collections are Product Based Collections catered for Apparel Brands"
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   * @protobufOption (title) = "Apparel Product Based Collections"
   */
  APPAREL_COLLECTIONS = 'APPAREL_COLLECTIONS',
  /**
   * Core Product Based Collections
   * Core Product Based Collections are Product Based Collections catered for Non-Apparel Brands and Retailers
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (description) = "Core Product Based Collections are Product Based Collections catered for Non-Apparel Brands and Retailers"
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   * @protobufOption (title) = "Core Product Based Collections"
   */
  CORE_COLLECTIONS = 'CORE_COLLECTIONS',
  /**
   * Owned by Browse (#pod-browse-sla)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_BROWSE
   */
  APPAREL_DISCOVERY = 'APPAREL_DISCOVERY',
  /**
   * Appdash
   * Internal tool for managing multiservices. Contains Goose.
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Internal tool for managing multiservices. Contains Goose."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "Appdash"
   */
  APPDASH = 'APPDASH',
  /**
   * L'Atelier Faire
   * Specific to the L'Atelier Faire event
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Specific to the L'Atelier Faire event"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "L'Atelier Faire"
   */
  ATELIER_FAIRE = 'ATELIER_FAIRE',
  /**
   * Authentication
   * User authentication.
   * Owned by Security (#pod-security)
   * @protobufOption (description) = "User authentication."
   * @protobufOption (pod) = FAIRE_POD_SECURITY
   * @protobufOption (title) = "Authentication"
   */
  AUTH = 'AUTH',
  /**
   * AWS Sagemaker
   * Build, train, and deploy machine learning models
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @protobufOption (description) = "Build, train, and deploy machine learning models"
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "AWS Sagemaker"
   */
  AWS_SAGEMAKER = 'AWS_SAGEMAKER',
  /**
   * B2B Referrals
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "B2B Referrals"
   */
  B2B_REFERRALS = 'B2B_REFERRALS',
  /**
   * Owned by Shipping (#pod-shipping)
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   */
  BACKEND_DRIVEN_MESSAGING_CONTAINERS = 'BACKEND_DRIVEN_MESSAGING_CONTAINERS',
  /**
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   */
  BACKFILA = 'BACKFILA',
  /**
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   */
  BADGES = 'BADGES',
  /**
   * Blog Gated Content
   * Gated content that requires a brand to register, typically linked to from the Faire blog
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Gated content that requires a brand to register, typically linked to from the Faire blog"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Blog Gated Content"
   */
  BLOG_GATED_CONTENT = 'BLOG_GATED_CONTENT',
  /**
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   */
  BOARDS = 'BOARDS',
  /**
   * Brand Account Settings
   * Settings and features related to a brand's account
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Settings and features related to a brand's account"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Brand Account Settings"
   */
  BRAND_ACCOUNT_SETTINGS = 'BRAND_ACCOUNT_SETTINGS',
  /**
   * Brand Acquisition
   * Areas related to acquiring brands
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Areas related to acquiring brands"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Brand Acquisition"
   */
  BRAND_ACQUISITION = 'BRAND_ACQUISITION',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_ANALYTICS = 'BRAND_ANALYTICS',
  /**
   * Brand Analytics Product Sales Report
   * Areas related to the product sales report related analytics
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Areas related to the product sales report related analytics"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Brand Analytics Product Sales Report"
   */
  PRODUCT_SALES_REPORT = 'PRODUCT_SALES_REPORT',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_ANNOUNCEMENTS = 'BRAND_ANNOUNCEMENTS',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  BRAND_APP = 'BRAND_APP',
  /**
   * Brand catalog completeness
   * Areas related to the completeness of the brand's catalog
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (description) = "Areas related to the completeness of the brand's catalog"
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   * @protobufOption (title) = "Brand catalog completeness"
   */
  BRAND_CATALOG_COMPLETENESS = 'BRAND_CATALOG_COMPLETENESS',
  /**
   * Core brand resources that cannot be assigned to a more specific product area
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Core brand resources that cannot be assigned to a more specific product area"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_CORE = 'BRAND_CORE',
  /**
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   */
  BRAND_CRM = 'BRAND_CRM',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  BRAND_CUSTOMER_ENGAGEMENT_SURVEY = 'BRAND_CUSTOMER_ENGAGEMENT_SURVEY',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  BRAND_DAILY_DIGEST = 'BRAND_DAILY_DIGEST',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  BRAND_EMAIL_PREFERENCES = 'BRAND_EMAIL_PREFERENCES',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_F14D_DISCOUNTS = 'BRAND_F14D_DISCOUNTS',
  /**
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   */
  BRAND_FRAUD = 'BRAND_FRAUD',
  /**
   * Owned by Data (#data-alerts)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_DATA
   */
  BRAND_HYPE_MAP = 'BRAND_HYPE_MAP',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  BRAND_INTEGRATIONS = 'BRAND_INTEGRATIONS',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  BRAND_INVOICES = 'BRAND_INVOICES',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_LEADS = 'BRAND_LEADS',
  /**
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   */
  BRAND_LISTING_QUALITY = 'BRAND_LISTING_QUALITY',
  /**
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   */
  BRAND_LOYALTY = 'BRAND_LOYALTY',
  /**
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   */
  BRAND_MARKETING_CAMPAIGNS = 'BRAND_MARKETING_CAMPAIGNS',
  /**
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   */
  BRAND_MARKETPLACE = 'BRAND_MARKETPLACE',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_MARKETPLACE_HOMEPAGE = 'BRAND_MARKETPLACE_HOMEPAGE',
  /**
   * Faire Brand App
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Faire Brand App"
   */
  BRAND_MOBILE = 'BRAND_MOBILE',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  BRAND_MULTI_USER_MANAGEMENT = 'BRAND_MULTI_USER_MANAGEMENT',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_NUDGES = 'BRAND_NUDGES',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_ONBOARDING = 'BRAND_ONBOARDING',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  BRAND_ORDERS = 'BRAND_ORDERS',
  /**
   * Payout calculation
   * Calculating Payouts to brands.
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Calculating Payouts to brands."
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Payout calculation"
   */
  BRAND_PAYOUT_CALCULATION = 'BRAND_PAYOUT_CALCULATION',
  /**
   * Brand payouts
   * Payouts to brands (only payout creation/initiation without payout infrastructure)
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Payouts to brands (only payout creation/initiation without payout infrastructure)"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Brand payouts"
   */
  BRAND_PAYOUTS = 'BRAND_PAYOUTS',
  /**
   * Brand portal homepage
   * Components of the brand portal homepage
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Components of the brand portal homepage"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Brand portal homepage"
   */
  BRAND_PORTAL_HOMEPAGE = 'BRAND_PORTAL_HOMEPAGE',
  /**
   * Brand portal search
   * Brand portal products search
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Brand portal products search"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Brand portal search"
   */
  BRAND_PORTAL_SEARCH = 'BRAND_PORTAL_SEARCH',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_PORTAL_NAVIGATION = 'BRAND_PORTAL_NAVIGATION',
  /**
   * Brand Preview
   * AKA View My Shop. Allows brands to see their own brand page
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "AKA View My Shop. Allows brands to see their own brand page"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Brand Preview"
   */
  BRAND_PREVIEW = 'BRAND_PREVIEW',
  /**
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   */
  BRAND_PRICING = 'BRAND_PRICING',
  /**
   * Brand Product Search Suggestions
   * The suggested search queries that appear when a retailer types search requests on the brand page
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @protobufOption (description) = "The suggested search queries that appear when a retailer types search requests on the brand page"
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   * @protobufOption (title) = "Brand Product Search Suggestions"
   */
  BRAND_PRODUCT_SEARCH_SUGGESTIONS = 'BRAND_PRODUCT_SEARCH_SUGGESTIONS',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_PROMOS = 'BRAND_PROMOS',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_PROSPECTING = 'BRAND_PROSPECTING',
  /**
   * Brand restrictions
   * Allows brands to choose the countries from which retailers can buy their products
   * Owned by Shipping (#pod-shipping)
   * @protobufOption (description) = "Allows brands to choose the countries from which retailers can buy their products"
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   * @protobufOption (title) = "Brand restrictions"
   */
  BRAND_RESTRICTIONS = 'BRAND_RESTRICTIONS',
  /**
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   */
  BRAND_REVIEWS = 'BRAND_REVIEWS',
  /**
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   */
  BRAND_SAFETY = 'BRAND_SAFETY',
  /**
   * Brand Search
   * Searching for brands on Faire (not a brand's products)
   * Owned by Browse (#pod-browse-sla)
   * @protobufOption (description) = "Searching for brands on Faire (not a brand's products)"
   * @protobufOption (pod) = FAIRE_POD_BROWSE
   * @protobufOption (title) = "Brand Search"
   */
  BRAND_SEARCH = 'BRAND_SEARCH',
  /**
   * Brand shop currency
   * Logic that allows brands to view the brand portal in currencies other than USD
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (description) = "Logic that allows brands to view the brand portal in currencies other than USD"
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   * @protobufOption (title) = "Brand shop currency"
   */
  BRAND_SHOP_CURRENCY = 'BRAND_SHOP_CURRENCY',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_SHOP_PAGE = 'BRAND_SHOP_PAGE',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_SHOP_SETTINGS = 'BRAND_SHOP_SETTINGS',
  /**
   * Deprecated, use a more specific product area.
   * Owned by BOM (#pod-bom)
   * @deprecated
   * @protobufOption (description) = "Deprecated, use a more specific product area."
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  BRAND_SHOPIFY_INTEGRATION = 'BRAND_SHOPIFY_INTEGRATION',
  /**
   * Brand Shopify Catalog Sync
   * Shopify catalog management for the brand Shopify integration
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Shopify catalog management for the brand Shopify integration"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Brand Shopify Catalog Sync"
   */
  BRAND_SHOPIFY_CATALOG_SYNC = 'BRAND_SHOPIFY_CATALOG_SYNC',
  /**
   * Brand Shopify Home
   * Homepage on Shopify for the brand Shopify integration, such as banners, home resources, link summaries
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Homepage on Shopify for the brand Shopify integration, such as banners, home resources, link summaries"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Brand Shopify Home"
   */
  BRAND_SHOPIFY_HOME = 'BRAND_SHOPIFY_HOME',
  /**
   * Brand Shopify Inventory Sync
   * Shopify inventory sync for the brand Shopify integration
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Shopify inventory sync for the brand Shopify integration"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Brand Shopify Inventory Sync"
   */
  BRAND_SHOPIFY_INVENTORY_SYNC = 'BRAND_SHOPIFY_INVENTORY_SYNC',
  /**
   * Brand Shopify Order Sync
   * Shopify order sync for the brand Shopify integration
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Shopify order sync for the brand Shopify integration"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Brand Shopify Order Sync"
   */
  BRAND_SHOPIFY_ORDER_SYNC = 'BRAND_SHOPIFY_ORDER_SYNC',
  /**
   * Brand Shopify Preferences
   * Managing preferences for the brand Shopify integration
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Managing preferences for the brand Shopify integration"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Brand Shopify Preferences"
   */
  BRAND_SHOPIFY_PREFERENCES = 'BRAND_SHOPIFY_PREFERENCES',
  /**
   * Brand Shopify Price Sync
   * Product price syncing from Shopify to Faire
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Product price syncing from Shopify to Faire"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Brand Shopify Price Sync"
   */
  BRAND_SHOPIFY_PRICE_SYNC = 'BRAND_SHOPIFY_PRICE_SYNC',
  /**
   * Brand Shopify Sales Channel Migration
   * Migrating brands from the old brand integration to the Sales Channel one
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Migrating brands from the old brand integration to the Sales Channel one"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Brand Shopify Sales Channel Migration"
   */
  BRAND_SHOPIFY_SALES_CHANNEL_MIGRATION = 'BRAND_SHOPIFY_SALES_CHANNEL_MIGRATION',
  /**
   * Brand Shopify Setup
   * Installation and setup of the brand Shopify integration
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Installation and setup of the brand Shopify integration"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Brand Shopify Setup"
   */
  BRAND_SHOPIFY_SETUP = 'BRAND_SHOPIFY_SETUP',
  /**
   * Changes limited to the embedded retailer Shopify app. Actual features of the retailer integration (e.g, syncing over ordered products) should use RETAILER_POS_INTEGRATIONS.
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Changes limited to the embedded retailer Shopify app. Actual features of the retailer integration (e.g, syncing over ordered products) should use RETAILER_POS_INTEGRATIONS."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   */
  RETAILER_SHOPIFY_APP = 'RETAILER_SHOPIFY_APP',
  /**
   * Brand SLA
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   * @protobufOption (title) = "Brand SLA"
   */
  BRAND_SLA = 'BRAND_SLA',
  /**
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   */
  BRAND_TAGS = 'BRAND_TAGS',
  /**
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   */
  BRAND_TILE = 'BRAND_TILE',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_VERIFICATION = 'BRAND_VERIFICATION',
  /**
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   */
  BRAND_WIDGET = 'BRAND_WIDGET',
  /**
   * Deprecated, use a more specific product area.
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @deprecated
   * @protobufOption (description) = "Deprecated, use a more specific product area."
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   */
  BRANDS = 'BRANDS',
  /**
   * Owned by Growth Platform (#pod-growth-platform)
   * @protobufOption (pod) = FAIRE_POD_GROWTH_PLATFORM
   */
  BRAZE = 'BRAZE',
  /**
   * Faire Browser Extension
   * Faire Browser Extension (Chrome + Firefox)
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Faire Browser Extension (Chrome + Firefox)"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Faire Browser Extension"
   */
  BROWSER_EXTENSION = 'BROWSER_EXTENSION',
  /**
   * Buy it again
   * Buy it again page or carousel
   * Owned by Retailer Order Placement (#pod-retailer-order-placement-sla)
   * @protobufOption (description) = "Buy it again page or carousel"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_PLACEMENT
   * @protobufOption (title) = "Buy it again"
   */
  BUY_IT_AGAIN = 'BUY_IT_AGAIN',
  /**
   * Bulk Actions
   * Bulk action internal framework.
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (description) = "Bulk action internal framework."
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   * @protobufOption (title) = "Bulk Actions"
   */
  BULK_ACTIONS = 'BULK_ACTIONS',
  /**
   * Capabilities
   * User capabilities, teams and permissions.
   * Owned by Security (#pod-security)
   * @protobufOption (description) = "User capabilities, teams and permissions."
   * @protobufOption (pod) = FAIRE_POD_SECURITY
   * @protobufOption (title) = "Capabilities"
   */
  CAPABILITIES = 'CAPABILITIES',
  /**
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   */
  CARBON_NEUTRAL_RETAILER = 'CARBON_NEUTRAL_RETAILER',
  /**
   * Cart
   * Retailer cart.
   * Owned by Retailer Order Placement (#pod-retailer-order-placement-sla)
   * @protobufOption (description) = "Retailer cart."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_PLACEMENT
   * @protobufOption (title) = "Cart"
   */
  CART = 'CART',
  /**
   * Cart Collaboration
   * Retailer cart collaborators and reviews.
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "Retailer cart collaborators and reviews."
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Cart Collaboration"
   */
  CART_COLLABORATION = 'CART_COLLABORATION',
  /**
   * Owned by Discovery Infra Enablement (#pod-discovery-infra-enablement)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_DISCOVERY_INFRA_ENABLEMENT
   */
  CATEGORIES = 'CATEGORIES',
  /**
   * Category page layouts
   * Generate/show/hide category page layouts
   * Owned by Browse (#pod-browse-sla)
   * @protobufOption (description) = "Generate/show/hide category page layouts"
   * @protobufOption (pod) = FAIRE_POD_BROWSE
   * @protobufOption (title) = "Category page layouts"
   */
  CATEGORY_PAGE_RECOMMENDATIONS = 'CATEGORY_PAGE_RECOMMENDATIONS',
  /**
   * browse Infrastructure
   * Related to the infrastructure that powers Faire's browsing surfaces
   * Owned by Browse (#pod-browse-sla)
   * @protobufOption (description) = "Related to the infrastructure that powers Faire's browsing surfaces"
   * @protobufOption (pod) = FAIRE_POD_BROWSE
   * @protobufOption (title) = "browse Infrastructure"
   */
  BROWSE_INFRASTRUCTURE = 'BROWSE_INFRASTRUCTURE',
  /**
   * Category results page
   * Category page search results
   * Owned by Browse (#pod-browse-sla)
   * @protobufOption (description) = "Category page search results"
   * @protobufOption (pod) = FAIRE_POD_BROWSE
   * @protobufOption (title) = "Category results page"
   */
  CATEGORY_RESULTS = 'CATEGORY_RESULTS',
  /**
   * Category results ranking
   * Ranking logic/rules for category page results
   * Owned by Personalization (#personalization-team)
   * @protobufOption (description) = "Ranking logic/rules for category page results"
   * @protobufOption (pod) = FAIRE_POD_PERSONALIZATION
   * @protobufOption (title) = "Category results ranking"
   */
  CATEGORY_RESULTS_RANKING = 'CATEGORY_RESULTS_RANKING',
  /**
   * Code related to migrating from monolith -> catalogs service
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   * @protobufOption (title) = "Code related to migrating from monolith -> catalogs service"
   */
  CATALOGS_MIGRATION = 'CATALOGS_MIGRATION',
  /**
   * Catalog Verification
   * Verification of brand submitted catalogs for pricing and completeness
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Verification of brand submitted catalogs for pricing and completeness"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Catalog Verification"
   */
  CATALOG_VERIFICATION = 'CATALOG_VERIFICATION',
  /**
   * Chat GPT Enterprise
   * Enterprise solutions for Chat GPT
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @protobufOption (description) = "Enterprise solutions for Chat GPT"
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "Chat GPT Enterprise"
   */
  CHAT_GPT_ENTERPRISE = 'CHAT_GPT_ENTERPRISE',
  /**
   * Checkout
   * Checkout flow for retailers.
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "Checkout flow for retailers."
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Checkout"
   */
  CHECKOUT = 'CHECKOUT',
  /**
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   */
  CLAWBACKS = 'CLAWBACKS',
  /**
   * Client Events
   * Anything related to our client event analytics or PETE
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (description) = "Anything related to our client event analytics or PETE"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Client Events"
   */
  CLIENT_EVENTS = 'CLIENT_EVENTS',
  /**
   * Client Health Tool
   * Tool to allow pillars and pods to view client health metrics
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Tool to allow pillars and pods to view client health metrics"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Client Health Tool"
   */
  CLIENT_HEALTH_TOOL = 'CLIENT_HEALTH_TOOL',
  /**
   * Cockroach DB
   * Migration of MySQL database to Cockroach DB
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Migration of MySQL database to Cockroach DB"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "Cockroach DB"
   */
  COCKROACH_DB = 'COCKROACH_DB',
  /**
   * (Deprecated) Starter Collections
   * DEPRECATED. Use either PREPACKS or CORE_COLLECTIONS.
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @deprecated
   * @protobufOption (description) = "DEPRECATED. Use either PREPACKS or CORE_COLLECTIONS."
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   * @protobufOption (title) = "(Deprecated) Starter Collections"
   */
  COLLECTIONS = 'COLLECTIONS',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  COMMISSIONS = 'COMMISSIONS',
  /**
   * Outgoing Comms Planner
   * Anything related to outgoing communication planner
   * Owned by Growth Platform (#pod-growth-platform)
   * @protobufOption (description) = "Anything related to outgoing communication planner"
   * @protobufOption (pod) = FAIRE_POD_GROWTH_PLATFORM
   * @protobufOption (title) = "Outgoing Comms Planner"
   */
  COMMS_PLANNER = 'COMMS_PLANNER',
  /**
   * Conductor
   * The Conductor UI Orchestration System (Docs to be added)
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "The Conductor UI Orchestration System (Docs to be added)"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Conductor"
   */
  CONDUCTOR = 'CONDUCTOR',
  /**
   * Consumer
   * DEPRECATED. Neighborhood, Faire's, direct-to-consumer marketplace.
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @deprecated
   * @protobufOption (description) = "DEPRECATED. Neighborhood, Faire's, direct-to-consumer marketplace."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Consumer"
   */
  CONSUMER = 'CONSUMER',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  CSV_IMPORT = 'CSV_IMPORT',
  /**
   * Currency conversion
   * Logic for converting monetary amounts between currencies
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (description) = "Logic for converting monetary amounts between currencies"
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   * @protobufOption (title) = "Currency conversion"
   */
  CURRENCY_CONVERSION = 'CURRENCY_CONVERSION',
  /**
   * CX Customer Queues
   * Various customer queues used by CX to handle incoming tickets
   * Owned by Ops Enablement (#pod-ops-enablement)
   * @protobufOption (description) = "Various customer queues used by CX to handle incoming tickets"
   * @protobufOption (pod) = FAIRE_POD_OPS_ENABLEMENT
   * @protobufOption (title) = "CX Customer Queues"
   */
  CX_CUSTOMER_QUEUES = 'CX_CUSTOMER_QUEUES',
  /**
   * CX Foundations
   * Owned by Ops Enablement (#pod-ops-enablement)
   * @protobufOption (pod) = FAIRE_POD_OPS_ENABLEMENT
   * @protobufOption (title) = "CX Foundations"
   */
  CX_FOUNDATIONS = 'CX_FOUNDATIONS',
  /**
   * First Order Incentive
   * Area to manage first order incentives for retailers.
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Area to manage first order incentives for retailers."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "First Order Incentive"
   */
  RETAILER_FIRST_ORDER_INCENTIVE = 'RETAILER_FIRST_ORDER_INCENTIVE',
  /**
   * Retailer Activation Incentives
   * Area to manage every incentive of the Retailer Activation pod.
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Area to manage every incentive of the Retailer Activation pod."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Retailer Activation Incentives"
   */
  RETAILER_ACTIVATION_INCENTIVES = 'RETAILER_ACTIVATION_INCENTIVES',
  /**
   * CX Operations
   * Anything related to CX operations, zendesk etc
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (description) = "Anything related to CX operations, zendesk etc"
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   * @protobufOption (title) = "CX Operations"
   */
  CX_OPS = 'CX_OPS',
  /**
   * Data Atlas
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Data Atlas"
   */
  DATA_ATLAS = 'DATA_ATLAS',
  /**
   * Data Factory
   * Data Science data sets loading and management.
   * Owned by Data (#data-alerts)
   * @protobufOption (description) = "Data Science data sets loading and management."
   * @protobufOption (pod) = FAIRE_POD_DATA
   * @protobufOption (title) = "Data Factory"
   */
  DATA_FACTORY = 'DATA_FACTORY',
  /**
   * Data Lake
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Data Lake"
   */
  DATA_LAKE = 'DATA_LAKE',
  /**
   * Owned by Data (#data-alerts)
   * @protobufOption (pod) = FAIRE_POD_DATA
   */
  DATA_LOOKUP = 'DATA_LOOKUP',
  /**
   * Database
   * Relational database persistence. Core infrastructure.
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Relational database persistence. Core infrastructure."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "Database"
   */
  DATABASE = 'DATABASE',
  /**
   * Database Explorer
   * Tool to explorer our DB https:www.faire.com/admin2/eng/db-explorer
   * Owned by Data (#data-alerts)
   * @protobufOption (description) = "Tool to explorer our DB https://www.faire.com/admin2/eng/db-explorer"
   * @protobufOption (pod) = FAIRE_POD_DATA
   * @protobufOption (title) = "Database Explorer"
   */
  DATABASE_EXPLORER = 'DATABASE_EXPLORER',
  /**
   * Delayer
   * Delayed jobs management
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Delayed jobs management"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "Delayer"
   */
  DELAYER = 'DELAYER',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  DRAFT_ORDERS = 'DRAFT_ORDERS',
  /**
   * Owned by Retailer POS Integrations (#pod-retailer-order-management-sla)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_MANAGEMENT
   */
  RETAILER_DRAFT_ORDERS = 'RETAILER_DRAFT_ORDERS',
  /**
   * Owned by Shipping (#pod-shipping)
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   */
  DUTIES = 'DUTIES',
  /**
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   */
  E2E = 'E2E',
  /**
   * Eco-friendly Experience
   * Sustainability Hub and Eco-friendly brand tags and product attributes
   * Owned by Inspire (#pod-discovery-inspire-eng)
   * @deprecated
   * @protobufOption (description) = "Sustainability Hub and Eco-friendly brand tags and product attributes"
   * @protobufOption (pod) = FAIRE_POD_INSPIRE
   * @protobufOption (title) = "Eco-friendly Experience"
   */
  ECO_FRIENDLY = 'ECO_FRIENDLY',
  /**
   * Edge Worker Infra
   * Core infrastructure and framework running on the edge network
   * Owned by Platform Infrastructure And Resilience (#pod-platform-infrastructure-and-resilience)
   * @protobufOption (description) = "Core infrastructure and framework running on the edge network"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_INFRASTRUCTURE_AND_RESILIENCE
   * @protobufOption (title) = "Edge Worker Infra"
   */
  EDGE_INFRA = 'EDGE_INFRA',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  EDIT_ORDERS = 'EDIT_ORDERS',
  /**
   * Email Infrastructure
   * Infrastructure related to emails
   * Owned by Growth Platform (#pod-growth-platform)
   * @protobufOption (description) = "Infrastructure related to emails"
   * @protobufOption (pod) = FAIRE_POD_GROWTH_PLATFORM
   * @protobufOption (title) = "Email Infrastructure"
   */
  EMAIL_INFRA = 'EMAIL_INFRA',
  /**
   * DD Monitor Error Ticket to Pod Triage
   * Triage product area used for PR bot to assign DD monitor error tickets to correct pod.
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Triage product area used for PR bot to assign DD monitor error tickets to correct pod."
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "DD Monitor Error Ticket to Pod Triage"
   */
  ERROR_RATE_TRIAGE = 'ERROR_RATE_TRIAGE',
  /**
   * Events
   * Event producer and consumer core framework.
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (description) = "Event producer and consumer core framework."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Events"
   */
  EVENTS = 'EVENTS',
  /**
   * Exclusivity
   * Exclusivity feature for Retailers and Brands.
   * Owned by Retailer Order Placement (#pod-retailer-order-placement-sla)
   * @protobufOption (description) = "Exclusivity feature for Retailers and Brands."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_PLACEMENT
   * @protobufOption (title) = "Exclusivity"
   */
  EXCLUSIVITY = 'EXCLUSIVITY',
  /**
   * Experiments
   * Tooling for running experiments
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (description) = "Tooling for running experiments"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Experiments"
   */
  EXPERIMENTS = 'EXPERIMENTS',
  /**
   * Experiment Platform
   * Experiment Platform
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Experiment Platform"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Experiment Platform"
   */
  EXPERIMENT_PLATFORM = 'EXPERIMENT_PLATFORM',
  /**
   * Expired Settings Cleanup
   * Product Area for PR Bot to assign Expired Settings Jira tickets
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Product Area for PR Bot to assign Expired Settings Jira tickets"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Expired Settings Cleanup"
   */
  EXPIRED_SETTINGS_CLEANUP = 'EXPIRED_SETTINGS_CLEANUP',
  /**
   * DEPRECATED: Use `EXERNAL_API_XXX` instead for specific features
   * Owned by BOM (#pod-bom)
   * @deprecated
   * @protobufOption (description) = "DEPRECATED: Use `EXERNAL_API_XXX` instead for specific features"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  EXTERNAL_APIS = 'EXTERNAL_APIS',
  /**
   * External API - Brands
   * External API support for Brand functionality
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "External API support for Brand functionality"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "External API - Brands"
   */
  EXTERNAL_API_BRANDS = 'EXTERNAL_API_BRANDS',
  /**
   * External API - Core
   * External API core shared functionality, e.g. ratelimiters
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "External API core shared functionality, e.g. ratelimiters"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "External API - Core"
   */
  EXTERNAL_API_CORE = 'EXTERNAL_API_CORE',
  /**
   * External API - Brands
   * External API support for Brand Promotions functionality
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "External API support for Brand Promotions functionality"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "External API - Brands"
   */
  EXTERNAL_API_BRAND_PROMOS = 'EXTERNAL_API_BRAND_PROMOS',
  /**
   * External API - Orders
   * External API support for Orders functionality
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "External API support for Orders functionality"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "External API - Orders"
   */
  EXTERNAL_API_ORDERS = 'EXTERNAL_API_ORDERS',
  /**
   * External API OAuth
   * External Api OAuth for installing apps for brands via OAuth protocol
   * Owned by Security (#pod-security)
   * @protobufOption (description) = "External Api OAuth for installing apps for brands via OAuth protocol"
   * @protobufOption (pod) = FAIRE_POD_SECURITY
   * @protobufOption (title) = "External API OAuth"
   */
  EXTERNAL_API_OAUTH = 'EXTERNAL_API_OAUTH',
  /**
   * External API - Partnerships
   * External API support for partnerships functionality
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "External API support for partnerships functionality"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "External API - Partnerships"
   */
  EXTERNAL_API_PARTNERSHIPS = 'EXTERNAL_API_PARTNERSHIPS',
  /**
   * External API - Products
   * External API support for Product model functionality
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @protobufOption (description) = "External API support for Product model functionality"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   * @protobufOption (title) = "External API - Products"
   */
  EXTERNAL_API_PRODUCTS = 'EXTERNAL_API_PRODUCTS',
  /**
   * External API - Prepacks
   * External API support for Prepack functionality
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "External API support for Prepack functionality"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "External API - Prepacks"
   */
  EXTERNAL_API_PREPACKS = 'EXTERNAL_API_PREPACKS',
  /**
   * External API - Inventory
   * External API support for product inventory functionality
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @protobufOption (description) = "External API support for product inventory functionality"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   * @protobufOption (title) = "External API - Inventory"
   */
  EXTERNAL_API_PRODUCT_INVENTORY = 'EXTERNAL_API_PRODUCT_INVENTORY',
  /**
   * External API - Retailers
   * External API support for retailers functionality
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "External API support for retailers functionality"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "External API - Retailers"
   */
  EXTERNAL_API_RETAILERS = 'EXTERNAL_API_RETAILERS',
  /**
   * External API - Taxonomy
   * External API support for product taxonomy functionality
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (description) = "External API support for product taxonomy functionality"
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   * @protobufOption (title) = "External API - Taxonomy"
   */
  EXTERNAL_API_TAXONOMY = 'EXTERNAL_API_TAXONOMY',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  EXTERNAL_DEVELOPER = 'EXTERNAL_DEVELOPER',
  /**
   * Fairey
   * Fairey chatbot infrastructure, such as integrations and prompt factories.
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @protobufOption (description) = "Fairey chatbot infrastructure, such as integrations and prompt factories."
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "Fairey"
   */
  FAIREY = 'FAIREY',
  /**
   * Faire CLI
   * Faire CLI, for various command-line utilities.
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Faire CLI, for various command-line utilities."
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Faire CLI"
   */
  FAIRE_CLI = 'FAIRE_CLI',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  FAIRE_CONTENT = 'FAIRE_CONTENT',
  /**
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   */
  FAIRE_DIRECT = 'FAIRE_DIRECT',
  /**
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   */
  FAIRE_DIRECT_ADOPTION_EMAIL = 'FAIRE_DIRECT_ADOPTION_EMAIL',
  /**
   * Faire Direct App
   * iPad App for brands to write invoices for retailers at trade shows
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "iPad App for brands to write invoices for retailers at trade shows"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Faire Direct App"
   */
  FAIRE_DIRECT_APP = 'FAIRE_DIRECT_APP',
  /**
   * Faire Direct Retailers
   * Area specific to Faire Direct retailers
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @deprecated
   * @protobufOption (description) = "Area specific to Faire Direct retailers"
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   * @protobufOption (title) = "Faire Direct Retailers"
   */
  FAIRE_DIRECT_RETAILERS = 'FAIRE_DIRECT_RETAILERS',
  /**
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   */
  FAIRE_DIRECT_REWARDS = 'FAIRE_DIRECT_REWARDS',
  /**
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   */
  FAIRE_DIRECT_REWARDS_LIFECYCLE_EMAIL = 'FAIRE_DIRECT_REWARDS_LIFECYCLE_EMAIL',
  /**
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   */
  FAIRE_DIRECT_TRIGGER_EMAIL = 'FAIRE_DIRECT_TRIGGER_EMAIL',
  /**
   * Shut down due to lack of an owner.
   * Owned by Internal Tools (#pod-internal-tools)
   * @deprecated
   * @protobufOption (description) = "Shut down due to lack of an owner."
   * @protobufOption (pod) = FAIRE_POD_INTERNAL_TOOLS
   */
  FAIRE_FOLK_HUB = 'FAIRE_FOLK_HUB',
  /**
   * TrustPilot integration
   * Fetching and returning TrustPilot information
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Fetching and returning TrustPilot information"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "TrustPilot integration"
   */
  FAIRE_SOCIAL_PROOF = 'FAIRE_SOCIAL_PROOF',
  /**
   * Faire Spaces was a hack week project https:www.notion.so/faire/Faire-Spaces-The-Faire-Office-App-52628a7b69f048dd9253a42c768bdeb8
   * Owned by Mobile Core (#pod-mobile-core)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_MOBILE_CORE
   */
  FAIRE_SPACES = 'FAIRE_SPACES',
  /**
   * Integrations for retailer side
   * Owned by Retailer POS Integrations (#pod-retailer-order-management-sla)
   * @protobufOption (description) = "Integrations for retailer side"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_MANAGEMENT
   */
  RETAILER_POS_INTEGRATIONS = 'RETAILER_POS_INTEGRATIONS',
  /**
   * Owned by Retailer POS Integrations (#pod-retailer-order-management-sla)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_MANAGEMENT
   */
  FAIRESYNC = 'FAIRESYNC',
  /**
   * Owned by Retailer POS Integrations (#pod-retailer-order-management-sla)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_MANAGEMENT
   */
  FAIRESYNC_INTEGRATION_CLOVER = 'FAIRESYNC_INTEGRATION_CLOVER',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  FAIRESYNC_INTEGRATION_SHOPIFY = 'FAIRESYNC_INTEGRATION_SHOPIFY',
  /**
   * Owned by Retailer POS Integrations (#pod-retailer-order-management-sla)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_MANAGEMENT
   */
  FAIRESYNC_INTEGRATION_SQUARE = 'FAIRESYNC_INTEGRATION_SQUARE',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  FAIRESYNC_INTEGRATION_QUICKBOOKS = 'FAIRESYNC_INTEGRATION_QUICKBOOKS',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  FAIREWORKS = 'FAIREWORKS',
  /**
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   */
  FASHION_WEEK = 'FASHION_WEEK',
  /**
   * Filters
   * Retailer facing filters used to narrow down results across pages
   * Owned by Browse (#pod-browse-sla)
   * @protobufOption (description) = "Retailer facing filters used to narrow down results across pages"
   * @protobufOption (pod) = FAIRE_POD_BROWSE
   * @protobufOption (title) = "Filters"
   */
  FILTERS = 'FILTERS',
  /**
   * Owned by Ops Enablement (#pod-ops-enablement)
   * @protobufOption (pod) = FAIRE_POD_OPS_ENABLEMENT
   */
  FLASH_SALES = 'FLASH_SALES',
  /**
   * Replaced by more specific product areas (ALCOHOL, SAMPLES, etc)
   * Owned by Food And Beverage (#pod-food-and-beverage)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_FOOD_AND_BEVERAGE
   */
  FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
  /**
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   */
  FRONTEND_APIS = 'FRONTEND_APIS',
  /**
   * Frontend Tooling
   * Frontend tooling that doesn't have a more specific ProductArea
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Frontend tooling that doesn't have a more specific ProductArea"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Frontend Tooling"
   */
  FRONTEND_TOOLING = 'FRONTEND_TOOLING',
  /**
   * FullStory recording
   * Owned by Web Platform (#pod-web-platform)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "FullStory recording"
   */
  FULLSTORY_CONFIG = 'FULLSTORY_CONFIG',
  /**
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   */
  GMAIL = 'GMAIL',
  /**
   * Goose
   * Goose is a service for managing sql migrations and queries. It is a replacement for Shift.
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Goose is a service for managing sql migrations and queries. It is a replacement for Shift."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "Goose"
   */
  GOOSE = 'GOOSE',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  GUARANTEED_VOLUME = 'GUARANTEED_VOLUME',
  /**
   * Owned by Unassigned (#pod-unassigned)
   * @protobufOption (pod) = FAIRE_POD_UNASSIGNED
   */
  HACK_WEEK = 'HACK_WEEK',
  /**
   * Help Center
   * General FAQ/support resources
   * Owned by Ops Enablement (#pod-ops-enablement)
   * @protobufOption (description) = "General FAQ/support resources"
   * @protobufOption (pod) = FAIRE_POD_OPS_ENABLEMENT
   * @protobufOption (title) = "Help Center"
   */
  HELP_CENTER = 'HELP_CENTER',
  /**
   * Home Feed
   * Retailer homepage on web and home feed on mobile
   * Owned by Inspire (#pod-discovery-inspire-eng)
   * @deprecated
   * @protobufOption (description) = "Retailer homepage on web and home feed on mobile"
   * @protobufOption (pod) = FAIRE_POD_INSPIRE
   * @protobufOption (title) = "Home Feed"
   */
  HOME_FEED = 'HOME_FEED',
  /**
   * Hot Jar Config
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Hot Jar Config"
   */
  HOT_JAR_CONFIG = 'HOT_JAR_CONFIG',
  /**
   * Hotjar recording
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Hotjar recording"
   */
  HOTJAR_CONFIG = 'HOTJAR_CONFIG',
  /**
   * Translation Infrastucture
   * Backend infrastructure and tooling supporting translation requests
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (description) = "Backend infrastructure and tooling supporting translation requests"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Translation Infrastucture"
   */
  TRANSLATIONS = 'TRANSLATIONS',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  HUBSPOT = 'HUBSPOT',
  /**
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   */
  IDV = 'IDV',
  /**
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   */
  IMAGES = 'IMAGES',
  /**
   * Inalytics
   * Intake of client analytics events.
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (description) = "Intake of client analytics events."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Inalytics"
   */
  INALYTICS = 'INALYTICS',
  /**
   * Incentives
   * Faire offered incentives for retailers
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Faire offered incentives for retailers"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Incentives"
   */
  INCENTIVES = 'INCENTIVES',
  /**
   * Infrastructure
   * General infrastructure and frameworks.
   * Owned by Platform Infrastructure And Resilience (#pod-platform-infrastructure-and-resilience)
   * @protobufOption (description) = "General infrastructure and frameworks."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_INFRASTRUCTURE_AND_RESILIENCE
   * @protobufOption (title) = "Infrastructure"
   */
  INFRA = 'INFRA',
  /**
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   */
  INFERENCE = 'INFERENCE',
  /**
   * Owned by Insider (#pod-insider-sla)
   * @protobufOption (pod) = FAIRE_POD_INSIDER
   */
  INSIDER = 'INSIDER',
  /**
   * Instruments
   * Payment instruments used to make payments.
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (description) = "Payment instruments used to make payments."
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   * @protobufOption (title) = "Instruments"
   */
  INSTRUMENTS = 'INSTRUMENTS',
  /**
   * DEPRECATED: Use `INTERNAL_API_XXX` instead for specific features
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "DEPRECATED: Use `INTERNAL_API_XXX` instead for specific features"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  INTERNAL_API = 'INTERNAL_API',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  INVENTORY = 'INVENTORY',
  /**
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   */
  ISSUE_REPORTS = 'ISSUE_REPORTS',
  /**
   * Job Framework
   * Job producer and consumer core framework.
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (description) = "Job producer and consumer core framework."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Job Framework"
   */
  JOBS = 'JOBS',
  /**
   * Kitchen Sink
   * All of our core components in one place.
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "All of our core components in one place."
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Kitchen Sink"
   */
  KITCHEN_SINK = 'KITCHEN_SINK',
  /**
   * Deprecated - use OPEN_WITH_FAIRE product area instead
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @deprecated
   * @protobufOption (description) = "Deprecated - use OPEN_WITH_FAIRE product area instead"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   */
  LAUNCH_WITH_FAIRE = 'LAUNCH_WITH_FAIRE',
  /**
   * DD Monitor Latency Ticket to Pod Triage
   * Triage product area used for PR bot to assign DD monitor latency tickets to correct pod.
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Triage product area used for PR bot to assign DD monitor latency tickets to correct pod."
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "DD Monitor Latency Ticket to Pod Triage"
   */
  LATENCY_TRIAGE = 'LATENCY_TRIAGE',
  /**
   * Legal Documents
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Legal Documents"
   */
  LEGAL_DOCUMENTS = 'LEGAL_DOCUMENTS',
  /**
   * Staging
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Staging"
   */
  STAGING = 'STAGING',
  /**
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   */
  LIVE_STREAMS = 'LIVE_STREAMS',
  /**
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   */
  LLM = 'LLM',
  /**
   * LLM Operations
   * Operations related to Large Language Models
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @protobufOption (description) = "Operations related to Large Language Models"
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "LLM Operations"
   */
  LLM_OPERATIONS = 'LLM_OPERATIONS',
  /**
   * Localization
   * Focus on translations, currencies, timezones, addresses.
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (description) = "Focus on translations, currencies, timezones, addresses."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Localization"
   */
  LOCALIZATION = 'LOCALIZATION',
  /**
   * Logged-in Homepage
   * Area specific to homepage for logged-in retailers
   * Owned by Inspire (#pod-discovery-inspire-eng)
   * @protobufOption (description) = "Area specific to homepage for logged-in retailers"
   * @protobufOption (pod) = FAIRE_POD_INSPIRE
   * @protobufOption (title) = "Logged-in Homepage"
   */
  LOGGED_IN_HOMEPAGE = 'LOGGED_IN_HOMEPAGE',
  /**
   * Owned by Ops Enablement (#pod-ops-enablement)
   * @protobufOption (pod) = FAIRE_POD_OPS_ENABLEMENT
   */
  MAKER_BUY_BACK = 'MAKER_BUY_BACK',
  /**
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   */
  MARKETS = 'MARKETS',
  /**
   * Messenger
   * User to user direct messaging.
   * Owned by Brand Unassigned (#pillar-brand-eng-sla)
   * @protobufOption (description) = "User to user direct messaging."
   * @protobufOption (pod) = FAIRE_POD_BRAND_UNASSIGNED
   * @protobufOption (title) = "Messenger"
   */
  MESSENGER = 'MESSENGER',
  /**
   * Milestones
   * Fetching, saving, and removing milestones for user events
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Fetching, saving, and removing milestones for user events"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Milestones"
   */
  MILESTONES = 'MILESTONES',
  /**
   * Mispriced Reseller
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   * @protobufOption (title) = "Mispriced Reseller"
   */
  MISPRICED_RESELLER = 'MISPRICED_RESELLER',
  /**
   * Mobile Retailer Homepage Logged In
   * The homepage on the retailer mobile apps, for logged in users
   * Owned by Inspire (#pod-discovery-inspire-eng)
   * @deprecated
   * @protobufOption (description) = "The homepage on the retailer mobile apps, for logged in users"
   * @protobufOption (pod) = FAIRE_POD_INSPIRE
   * @protobufOption (title) = "Mobile Retailer Homepage Logged In"
   */
  MOBILE_RETAILER_HOMEPAGE_LOGGED_IN = 'MOBILE_RETAILER_HOMEPAGE_LOGGED_IN',
  /**
   * Mobile Retailer Homepage Logged Out
   * The homepage on the retailer mobile apps, for logged out users
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (description) = "The homepage on the retailer mobile apps, for logged out users"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "Mobile Retailer Homepage Logged Out"
   */
  MOBILE_RETAILER_HOMEPAGE_LOGGED_OUT = 'MOBILE_RETAILER_HOMEPAGE_LOGGED_OUT',
  /**
   * Mobile Retailer Logged Out Experience
   * The logged out experience on the retailer mobile apps
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (description) = "The logged out experience on the retailer mobile apps"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "Mobile Retailer Logged Out Experience"
   */
  MOBILE_RETAILER_LOGGED_OUT = 'MOBILE_RETAILER_LOGGED_OUT',
  /**
   * Mobile Retailer Messenger
   * Messenger feature within the retailer mobile apps
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Messenger feature within the retailer mobile apps"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Mobile Retailer Messenger"
   */
  MOBILE_RETAILER_MESSENGER = 'MOBILE_RETAILER_MESSENGER',
  /**
   * Mobile Retailer Onboarding
   * Onboarding experience in the retailer mobile apps
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Onboarding experience in the retailer mobile apps"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Mobile Retailer Onboarding"
   */
  MOBILE_RETAILER_ONBOARDING = 'MOBILE_RETAILER_ONBOARDING',
  /**
   * Mobile Retailer Signup
   * Signup experience in the retailer mobile apps
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Signup experience in the retailer mobile apps"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Mobile Retailer Signup"
   */
  MOBILE_RETAILER_SIGNUP = 'MOBILE_RETAILER_SIGNUP',
  /**
   * My Brands
   * Owned by Retailer Order Placement (#pod-retailer-order-placement-sla)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_PLACEMENT
   * @protobufOption (title) = "My Brands"
   */
  MY_BRANDS = 'MY_BRANDS',
  /**
   * Zendesk Translations
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Zendesk Translations"
   */
  ZENDESK_TRANSLATIONS = 'ZENDESK_TRANSLATIONS',
  /**
   * MySQL
   * Relational database persistence. Core infrastructure.
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Relational database persistence. Core infrastructure."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "MySQL"
   */
  MYSQL = 'MYSQL',
  /**
   * Infrastructure that powers the navigation experience
   * Owned by Discovery Infra Enablement (#pod-discovery-infra-enablement)
   * @protobufOption (pod) = FAIRE_POD_DISCOVERY_INFRA_ENABLEMENT
   * @protobufOption (title) = "Infrastructure that powers the navigation experience"
   */
  NAVIGATION_INFRASTRUCTURE = 'NAVIGATION_INFRASTRUCTURE',
  /**
   * Navigation Tree UX
   * How navigation nodes and category nodes (navigation tree) are displayed to users
   * Owned by Browse (#pod-browse-sla)
   * @protobufOption (description) = "How navigation nodes and category nodes (navigation tree) are displayed to users"
   * @protobufOption (pod) = FAIRE_POD_BROWSE
   * @protobufOption (title) = "Navigation Tree UX"
   */
  NAVIGATION_TREE_UX = 'NAVIGATION_TREE_UX',
  /**
   * Net Terms
   * Retailer terms management
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (description) = "Retailer terms management"
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   * @protobufOption (title) = "Net Terms"
   */
  NET_TERMS = 'NET_TERMS',
  /**
   * Newsroom
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "Newsroom"
   */
  NEWSROOM = 'NEWSROOM',
  /**
   * Partnerships Page
   * Include pages like Amex and Square landing pages
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (description) = "Include pages like Amex and Square landing pages"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "Partnerships Page"
   */
  PARTNERSHIPS_PAGE = 'PARTNERSHIPS_PAGE',
  /**
   * Omnisearch
   * Tool to search across many entities in the database https:www.faire.com/admin2
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Tool to search across many entities in the database https://www.faire.com/admin2"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Omnisearch"
   */
  OMNISEARCH = 'OMNISEARCH',
  /**
   * One Faire Team
   * Features related to the one.faire.team hub for connecting with your co-workers.
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Features related to the one.faire.team hub for connecting with your co-workers."
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "One Faire Team"
   */
  ONE_FAIRE_TEAM = 'ONE_FAIRE_TEAM',
  /**
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   */
  OPEN_SIZING = 'OPEN_SIZING',
  /**
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   */
  OPEN_WITH_FAIRE = 'OPEN_WITH_FAIRE',
  /**
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   */
  OPEN_WITH_FAIRE_LOGGED_OUT = 'OPEN_WITH_FAIRE_LOGGED_OUT',
  /**
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   */
  ORDER_SUBMISSION = 'ORDER_SUBMISSION',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  ORDERS = 'ORDERS',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  PACKING_SLIP = 'PACKING_SLIP',
  /**
   * Payments
   * Payments from retailers and payments infrastructure.
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (description) = "Payments from retailers and payments infrastructure."
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   * @protobufOption (title) = "Payments"
   */
  PAYMENTS = 'PAYMENTS',
  /**
   * PDF Rendering
   * Ensure international brands get first class experience viewing tax forms and commercial invoices.
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @protobufOption (description) = "Ensure international brands get first class experience viewing tax forms and commercial invoices."
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   * @protobufOption (title) = "PDF Rendering"
   */
  PDF_RENDERING = 'PDF_RENDERING',
  /**
   * Performance Dark Read
   * performance testing using dark reads.
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "performance testing using dark reads."
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Performance Dark Read"
   */
  PERF_DARK_READ = 'PERF_DARK_READ',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  PICK_LIST = 'PICK_LIST',
  /**
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   */
  PLAID = 'PLAID',
  /**
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   */
  POSTMARK = 'POSTMARK',
  /**
   * PR Bot
   * PR Bot NodeJS server, and Faire PR Bot GitHub App
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "PR Bot NodeJS server, and Faire PR Bot GitHub App"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "PR Bot"
   */
  PR_BOT = 'PR_BOT',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  PREORDERS = 'PREORDERS',
  /**
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   */
  PREPACKS = 'PREPACKS',
  /**
   * Prerelease
   * Work related to the prerelease environment
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (description) = "Work related to the prerelease environment"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Prerelease"
   */
  PRERELEASE = 'PRERELEASE',
  /**
   * Prestashop
   * Brand acquisition landing page for Prestashop partnership
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Brand acquisition landing page for Prestashop partnership"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Prestashop"
   */
  PRESTASHOP = 'PRESTASHOP',
  /**
   * Pricing has been split into Markets and Brand Pricing
   * Owned by Pricing (#pod-pricing)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_PRICING
   */
  PRICING = 'PRICING',
  /**
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   */
  PRICING_PARITY = 'PRICING_PARITY',
  /**
   * Owned by Security (#pod-security)
   * @protobufOption (pod) = FAIRE_POD_SECURITY
   */
  PRIVACY = 'PRIVACY',
  /**
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   */
  PRODUCERS = 'PRODUCERS',
  /**
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   */
  PRODUCT_INFO = 'PRODUCT_INFO',
  /**
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   */
  PRODUCT_PRICE_MATCH_GUARANTEE = 'PRODUCT_PRICE_MATCH_GUARANTEE',
  /**
   * This version of samples was product specific, and was deleted in 2022
   * Product Samples
   * Area for retailers to order samples for products. Deprecated, the new feature that is similar is SAMPLES
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @deprecated
   * @protobufOption (description) = "Area for retailers to order samples for products. Deprecated, the new feature that is similar is SAMPLES"
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   * @protobufOption (title) = "Product Samples"
   */
  PRODUCT_SAMPLES = 'PRODUCT_SAMPLES',
  /**
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   */
  PRODUCT_TILE = 'PRODUCT_TILE',
  /**
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   */
  PRODUCTS = 'PRODUCTS',
  /**
   * APIs for managing products
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @protobufOption (description) = "APIs for managing products"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   */
  PRODUCTS_API = 'PRODUCTS_API',
  /**
   * Promo modals
   * Modals for promotion
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Modals for promotion"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Promo modals"
   */
  PROMO_MODALS = 'PROMO_MODALS',
  /**
   * Promotional Events
   * Events like Faire Summer Market or Faire Winter Market
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "Events like Faire Summer Market or Faire Winter Market"
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Promotional Events"
   */
  PROMOTIONAL_EVENTS = 'PROMOTIONAL_EVENTS',
  /**
   * Promo codes for retailers
   * General infrastructure surrounding promotional codes to be used by retailers
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "General infrastructure surrounding promotional codes to be used by retailers"
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Promo codes for retailers"
   */
  PROMO_CODES = 'PROMO_CODES',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  /**
   * Push Notifications
   * Sending notification to mobile devices running Faire apps
   * Owned by Growth Platform (#pod-growth-platform)
   * @protobufOption (description) = "Sending notification to mobile devices running Faire apps"
   * @protobufOption (pod) = FAIRE_POD_GROWTH_PLATFORM
   * @protobufOption (title) = "Push Notifications"
   */
  PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
  /**
   * Owned by Growth Platform (#pod-growth-platform)
   * @protobufOption (pod) = FAIRE_POD_GROWTH_PLATFORM
   */
  QUALTRICS = 'QUALTRICS',
  /**
   * Queues
   * Lists of cases worked through by ops teams to complete various business objectives.
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (description) = "Lists of cases worked through by ops teams to complete various business objectives."
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   * @protobufOption (title) = "Queues"
   */
  QUEUES = 'QUEUES',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  R2B_REFERRALS = 'R2B_REFERRALS',
  /**
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   */
  R2R_REFERRALS = 'R2R_REFERRALS',
  /**
   * Search Ranking
   * Search Ranking Algorithms
   * Owned by Search Ranking Algorithms (#search-algo-alerts)
   * @protobufOption (description) = "Search Ranking Algorithms"
   * @protobufOption (pod) = FAIRE_POD_SEARCH_RANKING_ALGORITHMS
   * @protobufOption (title) = "Search Ranking"
   */
  RANKING = 'RANKING',
  /**
   * Owned by Inspire (#pod-discovery-inspire-eng)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_INSPIRE
   */
  RECOMMENDATIONS = 'RECOMMENDATIONS',
  /**
   * Redis
   * Redis and ElasticCache core infrastructure.
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Redis and ElasticCache core infrastructure."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "Redis"
   */
  REDIS = 'REDIS',
  /**
   * Release Tool
   * Internal tool for managing deployments.
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (description) = "Internal tool for managing deployments."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Release Tool"
   */
  RELEASE_TOOL = 'RELEASE_TOOL',
  /**
   * Regional Search
   * Regional search for retailers.
   * Owned by Browse (#pod-browse-sla)
   * @deprecated
   * @protobufOption (description) = "Regional search for retailers."
   * @protobufOption (pod) = FAIRE_POD_BROWSE
   * @protobufOption (title) = "Regional Search"
   */
  REGIONAL_SEARCH = 'REGIONAL_SEARCH',
  /**
   * Reorder Updates Hub
   * Area for retailers to see updates from previously ordered brands (AKA Retailer Brand Insights)
   * Owned by Retailer Order Placement (#pod-retailer-order-placement-sla)
   * @protobufOption (description) = "Area for retailers to see updates from previously ordered brands (AKA Retailer Brand Insights)"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_PLACEMENT
   * @protobufOption (title) = "Reorder Updates Hub"
   */
  REORDER_UPDATES_HUB = 'REORDER_UPDATES_HUB',
  /**
   * Advertising to retailers
   * Sending data to platforms for advertising purposes such as facebook.
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (description) = "Sending data to platforms for advertising purposes such as facebook."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "Advertising to retailers"
   */
  RETAILER_ADVERTISING = 'RETAILER_ADVERTISING',
  /**
   * Retailer Brand Loyalty
   * Area for benefits given to Retailers when ordering from brands they're loyal to
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @deprecated
   * @protobufOption (description) = "Area for benefits given to Retailers when ordering from brands they're loyal to"
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Retailer Brand Loyalty"
   */
  RETAILER_BRAND_LOYALTY = 'RETAILER_BRAND_LOYALTY',
  /**
   * Retailer Brands Management
   * Area for retailers to manager their relationships with brands
   * Owned by Retailer Order Placement (#pod-retailer-order-placement-sla)
   * @protobufOption (description) = "Area for retailers to manager their relationships with brands"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_PLACEMENT
   * @protobufOption (title) = "Retailer Brands Management"
   */
  RETAILER_BRANDS_MANAGEMENT = 'RETAILER_BRANDS_MANAGEMENT',
  /**
   * Owned by Mobile Platform (#pod-mobile-platform-notifications)
   * @protobufOption (pod) = FAIRE_POD_MOBILE_PLATFORM
   */
  RETAILER_FINDER_APP = 'RETAILER_FINDER_APP',
  /**
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   */
  RETAILER_INVOICES = 'RETAILER_INVOICES',
  /**
   * Retailer Layout Elements
   * Dynamic FE elements that allow BE to control frontend layout and content
   * Owned by Inspire (#pod-discovery-inspire-eng)
   * @protobufOption (description) = "Dynamic FE elements that allow BE to control frontend layout and content"
   * @protobufOption (pod) = FAIRE_POD_INSPIRE
   * @protobufOption (title) = "Retailer Layout Elements"
   */
  RETAILER_LAYOUT_ELEMENTS = 'RETAILER_LAYOUT_ELEMENTS',
  /**
   * Layout Service
   * Service-specific infrastructure for layout service
   * Owned by Discovery Product Platform (#pod-discovery-product-platform)
   * @protobufOption (description) = "Service-specific infrastructure for layout service"
   * @protobufOption (pod) = FAIRE_POD_DISCOVERY_PRODUCT_PLATFORM
   * @protobufOption (title) = "Layout Service"
   */
  LAYOUT_SERVICE = 'LAYOUT_SERVICE',
  /**
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   */
  RETAILER_MARKETING = 'RETAILER_MARKETING',
  /**
   * Faire Retailer App
   * Owned by Rtp Mobile (#pod-rtp-mobile)
   * @protobufOption (pod) = FAIRE_POD_RTP_MOBILE
   * @protobufOption (title) = "Faire Retailer App"
   */
  RETAILER_MOBILE = 'RETAILER_MOBILE',
  /**
   * Retailer Onboarding
   * Sign up and post sign up experiences for new retailers.
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Sign up and post sign up experiences for new retailers."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Retailer Onboarding"
   */
  RETAILER_ONBOARDING = 'RETAILER_ONBOARDING',
  /**
   * Retailer Order Management
   * Area for retailers to manage their orders
   * Owned by Retailer POS Integrations (#pod-retailer-order-management-sla)
   * @protobufOption (description) = "Area for retailers to manage their orders"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_MANAGEMENT
   * @protobufOption (title) = "Retailer Order Management"
   */
  RETAILER_ORDER_MANAGEMENT = 'RETAILER_ORDER_MANAGEMENT',
  /**
   * Retailer owner transfer
   * Transferring account ownership of a retailer from one user to another within the same retailer.
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "Transferring account ownership of a retailer from one user to another within the same retailer."
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Retailer owner transfer"
   */
  RETAILER_OWNER_TRANSFER = 'RETAILER_OWNER_TRANSFER',
  /**
   * Neighborhood is sunsetted.
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   */
  RETAILER_PORTAL = 'RETAILER_PORTAL',
  /**
   * Now known as FAIRESYNC
   * Owned by Retailer POS Integrations (#pod-retailer-order-management-sla)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_MANAGEMENT
   */
  RETAILER_POS = 'RETAILER_POS',
  /**
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   */
  RETAILER_REPORTING = 'RETAILER_REPORTING',
  /**
   * Retailer Settings Page
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Retailer Settings Page"
   */
  RETAILER_SETTINGS_PAGE = 'RETAILER_SETTINGS_PAGE',
  /**
   * Careers Page
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "Careers Page"
   */
  CAREERS_PAGE = 'CAREERS_PAGE',
  /**
   * Retailer Year In Review
   * Unique lookback at a retailer's past year
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Unique lookback at a retailer's past year"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Retailer Year In Review"
   */
  RETAILER_YEAR_IN_REVIEW = 'RETAILER_YEAR_IN_REVIEW',
  /**
   * Retailers
   * Deprecated, use a more specific product area.
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @deprecated
   * @protobufOption (description) = "Deprecated, use a more specific product area."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Retailers"
   */
  RETAILERS = 'RETAILERS',
  /**
   * Retailer Conversion
   * Looks into converting inactive retailers into active ordering ones
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Looks into converting inactive retailers into active ordering ones"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Retailer Conversion"
   */
  RETAILER_CONVERSION = 'RETAILER_CONVERSION',
  /**
   * Core retailer resources that cannot be assigned to a more specific product area
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Core retailer resources that cannot be assigned to a more specific product area"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   */
  RETAILER_CORE = 'RETAILER_CORE',
  /**
   * Owned by Ops Enablement (#pod-ops-enablement)
   * @protobufOption (pod) = FAIRE_POD_OPS_ENABLEMENT
   */
  RETURNS = 'RETURNS',
  /**
   * Reverse Syncer
   * Replay CockroachDB write back to MySQL
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Replay CockroachDB write back to MySQL"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "Reverse Syncer"
   */
  REVERSE_SYNCER = 'REVERSE_SYNCER',
  /**
   * Deprecated; use a more fine-grained RISK_* product area instead
   * Owned by Data Team Financial Products (#data-team-financial-products)
   * @deprecated
   * @protobufOption (description) = "Deprecated; use a more fine-grained RISK_* product area instead"
   * @protobufOption (pod) = FAIRE_POD_DATA_TEAM_FINANCIAL_PRODUCTS
   */
  RISK = 'RISK',
  /**
   * Risk Operations
   * Risk Ops tools
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (description) = "Risk Ops tools"
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   * @protobufOption (title) = "Risk Operations"
   */
  RISK_OPS = 'RISK_OPS',
  /**
   * Risk Assessment
   * Data-driven risk assesment
   * Owned by Data Team Financial Products (#data-team-financial-products)
   * @protobufOption (description) = "Data-driven risk assesment"
   * @protobufOption (pod) = FAIRE_POD_DATA_TEAM_FINANCIAL_PRODUCTS
   * @protobufOption (title) = "Risk Assessment"
   */
  RISK_ASSESSMENT = 'RISK_ASSESSMENT',
  /**
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   */
  SALES_REPS = 'SALES_REPS',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  SALESFORCE = 'SALESFORCE',
  /**
   * Samples
   * Brand-facing samples. Built for Food and Beverage products and brands.
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (description) = "Brand-facing samples. Built for Food and Beverage products and brands."
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   * @protobufOption (title) = "Samples"
   */
  SAMPLES = 'SAMPLES',
  /**
   * Retailer-facing samples
   * The retailer side of the sampling feature. Retailers request samples from brands. See also SAMPLES.
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @protobufOption (description) = "The retailer side of the sampling feature. Retailers request samples from brands. See also SAMPLES."
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   * @protobufOption (title) = "Retailer-facing samples"
   */
  SAMPLES_RETAILER = 'SAMPLES_RETAILER',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  SANDBOX = 'SANDBOX',
  /**
   * Owned by Browse (#pod-browse-sla)
   * @protobufOption (pod) = FAIRE_POD_BROWSE
   */
  SEARCH = 'SEARCH',
  /**
   * Search Suggestions
   * The suggested search queries that appear when a retailer types search requests
   * Owned by Browse (#pod-browse-sla)
   * @protobufOption (description) = "The suggested search queries that appear when a retailer types search requests"
   * @protobufOption (pod) = FAIRE_POD_BROWSE
   * @protobufOption (title) = "Search Suggestions"
   */
  SEARCH_SUGGESTIONS = 'SEARCH_SUGGESTIONS',
  /**
   * Search Infrastructure
   * Owned by Discovery Infra Enablement (#pod-discovery-infra-enablement)
   * @protobufOption (pod) = FAIRE_POD_DISCOVERY_INFRA_ENABLEMENT
   * @protobufOption (title) = "Search Infrastructure"
   */
  SEARCH_INFRASTRUCTURE = 'SEARCH_INFRASTRUCTURE',
  /**
   * Temporary catch-all product area for things owned by the Search Experience Accelerator Pod
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   * @protobufOption (title) = "Temporary catch-all product area for things owned by the Search Experience Accelerator Pod"
   */
  SEARCH_EXPERIENCE_ACCELERATOR = 'SEARCH_EXPERIENCE_ACCELERATOR',
  /**
   * Retailer users management
   * Management (updating permission and subscription) of the multi users under a retailer
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "Management (updating permission and subscription) of the multi users under a retailer"
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Retailer users management"
   */
  RETAILER_MULTI_USER_MANAGEMENT = 'RETAILER_MULTI_USER_MANAGEMENT',
  /**
   * Retailer team item sharing
   * Sharing of shareable item with anonymous users via limited deep-links or established users via e-mail
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "Sharing of shareable item with anonymous users via limited deep-links or established users via e-mail"
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Retailer team item sharing"
   */
  RETAILER_TEAM_SHARING = 'RETAILER_TEAM_SHARING',
  /**
   * Seasonal Categories
   * Owned by Inspire (#pod-discovery-inspire-eng)
   * @protobufOption (pod) = FAIRE_POD_INSPIRE
   * @protobufOption (title) = "Seasonal Categories"
   */
  SEASONAL_CATEGORIES = 'SEASONAL_CATEGORIES',
  /**
   * Impersonation
   * Owned by Security (#pod-security)
   * @protobufOption (pod) = FAIRE_POD_SECURITY
   * @protobufOption (title) = "Impersonation"
   */
  IMPERSONATION = 'IMPERSONATION',
  /**
   * Owned by Security (#pod-security)
   * @protobufOption (pod) = FAIRE_POD_SECURITY
   */
  SECURITY = 'SECURITY',
  /**
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   */
  SENTRY = 'SENTRY',
  /**
   * SEO
   * Pages/features introduced to drive up our SEO (Search Engine Optimization).
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (description) = "Pages/features introduced to drive up our SEO (Search Engine Optimization)."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "SEO"
   */
  SEO = 'SEO',
  /**
   * Session
   * User session management.
   * Owned by Security (#pod-security)
   * @protobufOption (description) = "User session management."
   * @protobufOption (pod) = FAIRE_POD_SECURITY
   * @protobufOption (title) = "Session"
   */
  SESSIONS = 'SESSIONS',
  /**
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   */
  SETTINGS = 'SETTINGS',
  /**
   * Owned by Shipping (#pod-shipping)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   */
  SHIPMENTS = 'SHIPMENTS',
  /**
   * Shipping
   * Shipping of products.
   * Owned by Shipping (#pod-shipping)
   * @protobufOption (description) = "Shipping of products."
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   * @protobufOption (title) = "Shipping"
   */
  SHIPPING = 'SHIPPING',
  /**
   * Addresses
   * Management of addresses
   * Owned by Shipping (#pod-shipping)
   * @protobufOption (description) = "Management of addresses"
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   * @protobufOption (title) = "Addresses"
   */
  ADDRESSES = 'ADDRESSES',
  /**
   * Shipping Insurance
   * Insurance for shipped items
   * Owned by Shipping (#pod-shipping)
   * @protobufOption (description) = "Insurance for shipped items"
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   * @protobufOption (title) = "Shipping Insurance"
   */
  SHIPPING_INSURANCE = 'SHIPPING_INSURANCE',
  /**
   * Commercial Invoices
   * Handling of commercial invoices
   * Owned by Shipping (#pod-shipping)
   * @protobufOption (description) = "Handling of commercial invoices"
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   * @protobufOption (title) = "Commercial Invoices"
   */
  COMMERCIAL_INVOICES = 'COMMERCIAL_INVOICES',
  /**
   * Tariff Codes
   * Management of tariff codes
   * Owned by Shipping (#pod-shipping)
   * @protobufOption (description) = "Management of tariff codes"
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   * @protobufOption (title) = "Tariff Codes"
   */
  TARIFF_CODES = 'TARIFF_CODES',
  /**
   * Sapient tracking integration
   * Integration with Sapient for Royalmail tracking
   * Owned by Shipping (#pod-shipping)
   * @protobufOption (description) = "Integration with Sapient for Royalmail tracking"
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   * @protobufOption (title) = "Sapient tracking integration"
   */
  SAPIENT = 'SAPIENT',
  /**
   * Shopify Makers has been shut down and deleted
   * Owned by BOM (#pod-bom)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  SHOPIFY_MAKERS = 'SHOPIFY_MAKERS',
  /**
   * Signin
   * faire.com signin
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (description) = "faire.com signin"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "Signin"
   */
  SIGNIN = 'SIGNIN',
  /**
   * Signup
   * faire.com signup
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "faire.com signup"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Signup"
   */
  SIGNUP = 'SIGNUP',
  /**
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   */
  SLACK = 'SLACK',
  /**
   * Slate Design System
   * Implementation of our design system, Slate
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Implementation of our design system, Slate"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Slate Design System"
   */
  SLATE_DESIGN_SYSTEM = 'SLATE_DESIGN_SYSTEM',
  /**
   * Slate Tools
   * Tools relating to Slate
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Tools relating to Slate"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Slate Tools"
   */
  SLATE_TOOLS = 'SLATE_TOOLS',
  /**
   * Search Query Understanding and Retrieval Algorithm
   * Owned by Search Query Understanding And Retrieval (#search-algo-alerts)
   * @protobufOption (pod) = FAIRE_POD_SEARCH_QUERY_UNDERSTANDING_AND_RETRIEVAL
   * @protobufOption (title) = "Search Query Understanding and Retrieval Algorithm"
   */
  SEARCH_QUERY_UNDERSTANDING_AND_RETRIEVAL = 'SEARCH_QUERY_UNDERSTANDING_AND_RETRIEVAL',
  /**
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   */
  STRIPE = 'STRIPE',
  /**
   * SunCo chat integration
   * Integration with Sunshine Conversations for the Help Center
   * Owned by Ops Enablement (#pod-ops-enablement)
   * @protobufOption (description) = "Integration with Sunshine Conversations for the Help Center"
   * @protobufOption (pod) = FAIRE_POD_OPS_ENABLEMENT
   * @protobufOption (title) = "SunCo chat integration"
   */
  SUNCO = 'SUNCO',
  /**
   * Swatches
   * https:www.notion.so/faire/Swatches-f049024297c046c8aa122bc25c8214fc
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (description) = "https://www.notion.so/faire/Swatches-f049024297c046c8aa122bc25c8214fc"
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   * @protobufOption (title) = "Swatches"
   */
  SWATCHES = 'SWATCHES',
  /**
   * Switchboard
   * Websocket management.
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (description) = "Websocket management."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Switchboard"
   */
  SWITCHBOARD = 'SWITCHBOARD',
  /**
   * Task
   * Scheduled task execution core framework.
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (description) = "Scheduled task execution core framework."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   * @protobufOption (title) = "Task"
   */
  TASKS = 'TASKS',
  /**
   * Tax Forms for brands
   * Owned by Brand Platform Compliance (#pod-brand-platform-compliance)
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_COMPLIANCE
   * @protobufOption (title) = "Tax Forms for brands"
   */
  TAX_FORMS = 'TAX_FORMS',
  /**
   * Owned by Brand Platform Compliance (#pod-brand-platform-compliance)
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_COMPLIANCE
   */
  TAXES = 'TAXES',
  /**
   * Owned by Brand Listing Quality And Product Attributes (#pod-listing-quality-product-attributes)
   * @protobufOption (pod) = FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES
   */
  TAXONOMY = 'TAXONOMY',
  /**
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   */
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
  /**
   * Owned by Platform Productivity (#pod-platform-productivity)
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PRODUCTIVITY
   */
  TEST = 'TEST',
  /**
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   */
  TESTIMONIALS = 'TESTIMONIALS',
  /**
   * Time Turner
   * Tool to view changes in a database entity over time https:www.faire.com/admin2/records/time-turner
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Tool to view changes in a database entity over time https://www.faire.com/admin2/records/time-turner"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Time Turner"
   */
  TIME_TURNER = 'TIME_TURNER',
  /**
   * Owned by Marketplace Quality (#pod-marketplace-quality-alerts)
   * @protobufOption (pod) = FAIRE_POD_MARKETPLACE_QUALITY
   */
  TOP_BRAND = 'TOP_BRAND',
  /**
   * Owned by BOM (#pod-bom)
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  TRADESHOW = 'TRADESHOW',
  /**
   * Universal Feedback Tool
   * Form for employees to submit feedback on internal tools
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Form for employees to submit feedback on internal tools"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Universal Feedback Tool"
   */
  UNIVERSAL_FEEDBACK_TOOL = 'UNIVERSAL_FEEDBACK_TOOL',
  /**
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   */
  UPMARKET = 'UPMARKET',
  /**
   * Owned by Upmarket Experience (#pod-upmarket-experience)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_EXPERIENCE
   */
  UPMARKET_EXPERIENCE = 'UPMARKET_EXPERIENCE',
  /**
   * User invitation
   * Feature for retailer admin to invite multiple users to the retailer
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "Feature for retailer admin to invite multiple users to the retailer"
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "User invitation"
   */
  USER_INVITATION = 'USER_INVITATION',
  /**
   * Owned by Security (#pod-security)
   * @protobufOption (pod) = FAIRE_POD_SECURITY
   */
  USERS = 'USERS',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  VIDEOS = 'VIDEOS',
  /**
   * web-visitor is discontinued
   * web-visitor React App
   * General fallback for features pertaining to the web-visitor React App.
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @deprecated
   * @protobufOption (description) = "General fallback for features pertaining to the web-visitor React App."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "web-visitor React App"
   */
  VISITORS = 'VISITORS',
  /**
   * VS Code Extension
   * The Faire VS Code Extension
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "The Faire VS Code Extension"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "VS Code Extension"
   */
  VS_CODE_EXTENSION = 'VS_CODE_EXTENSION',
  /**
   * Waitlisted signup
   * Allows retailers in countries where we haven't launched to record their interest in joining Faire
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Allows retailers in countries where we haven't launched to record their interest in joining Faire"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Waitlisted signup"
   */
  WAITLISTED_SIGNUP = 'WAITLISTED_SIGNUP',
  /**
   * Owned by Ops Enablement (#pod-ops-enablement)
   * @protobufOption (pod) = FAIRE_POD_OPS_ENABLEMENT
   */
  WAREHOUSE_TOOLING = 'WAREHOUSE_TOOLING',
  /**
   * Web Docs Site
   * Faire Web Docs site for all frontend and slate documentation
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Faire Web Docs site for all frontend and slate documentation"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Web Docs Site"
   */
  WEB_DOCS_SITE = 'WEB_DOCS_SITE',
  /**
   * Web Retailer Server
   * Node.js server for web-retailer
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Node.js server for web-retailer"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Web Retailer Server"
   */
  WEB_RETAILER_SERVER = 'WEB_RETAILER_SERVER',
  /**
   * Server-side rendering
   * Server-side rendering for web front-end
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Server-side rendering for web front-end"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Server-side rendering"
   */
  WEB_SERVER_SIDE_RENDERING = 'WEB_SERVER_SIDE_RENDERING',
  /**
   * Web Server
   * Web server core infrastructure and framework.
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Web server core infrastructure and framework."
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "Web Server"
   */
  WEBSERVER = 'WEBSERVER',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  XLSX_IMPORT = 'XLSX_IMPORT',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  XLSX_ONBOARDING_IMPORT = 'XLSX_ONBOARDING_IMPORT',
  /**
   * Owned by Retailer Order Placement (#pod-retailer-order-placement-sla)
   * @deprecated
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_PLACEMENT
   */
  ZIP_CODE_PROTECTION = 'ZIP_CODE_PROTECTION',
  /**
   * Teams Page, checkout review and collaborator tracking on cart, orders, etc.
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "Teams Page, checkout review and collaborator tracking on cart, orders, etc."
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   */
  RETAILER_TEAM_MANAGEMENT = 'RETAILER_TEAM_MANAGEMENT',
  /**
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   */
  BRAND_INCREMENTALITY = 'BRAND_INCREMENTALITY',
  /**
   * Owned by Ops Enablement (#pod-ops-enablement)
   * @protobufOption (pod) = FAIRE_POD_OPS_ENABLEMENT
   */
  ZENDESK = 'ZENDESK',
  /**
   * Owned by Mobile Platform (#pod-mobile-platform-notifications)
   * @protobufOption (pod) = FAIRE_POD_MOBILE_PLATFORM
   */
  MOBILE_PERFORMANCE_MONITORING_INFRASTRUCTURE = 'MOBILE_PERFORMANCE_MONITORING_INFRASTRUCTURE',
  /**
   * Faire's internal marketplace ads that are sold to brands, a.k.a. paid placement or promoted products
   * Owned by Ads (#pod-discovery-paid-placement-sla)
   * @protobufOption (description) = "Faire's internal marketplace ads that are sold to brands, a.k.a. paid placement or promoted products"
   * @protobufOption (pod) = FAIRE_POD_ADS
   */
  ADS = 'ADS',
  /**
   * Filters and merchandising to increase local supply in Europe
   * Owned by Browse (#pod-browse-sla)
   * @protobufOption (description) = "Filters and merchandising to increase local supply in Europe"
   * @protobufOption (pod) = FAIRE_POD_BROWSE
   */
  LOCAL_FILTERS_AND_MERCHANDISING = 'LOCAL_FILTERS_AND_MERCHANDISING',
  /**
   * Recognizable Brands
   * Relate to Recognizable Brands model usage
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Relate to Recognizable Brands model usage"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Recognizable Brands"
   */
  RECOGNIZABLE_BRANDS = 'RECOGNIZABLE_BRANDS',
  /**
   * Logged Out Retailers
   * Logged out retailer experience
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (description) = "Logged out retailer experience"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "Logged Out Retailers"
   */
  LOGGED_OUT_RETAILERS = 'LOGGED_OUT_RETAILERS',
  /**
   * Web Brand Server
   * Node.js server for brand-portal
   * Owned by Brand Platform Compliance (#pod-brand-platform-compliance)
   * @protobufOption (description) = "Node.js server for brand-portal"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_COMPLIANCE
   * @protobufOption (title) = "Web Brand Server"
   */
  WEB_BRAND_SERVER = 'WEB_BRAND_SERVER',
  /**
   * Web Brand NextJS
   * NextJS infrastructure and routing for brand-portal
   * Owned by Brand Platform Compliance (#pod-brand-platform-compliance)
   * @protobufOption (description) = "NextJS infrastructure and routing for brand-portal"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_COMPLIANCE
   * @protobufOption (title) = "Web Brand NextJS"
   */
  WEB_BRAND_NEXTJS = 'WEB_BRAND_NEXTJS',
  /**
   * Financial Events
   * Financial Events
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (description) = "Financial Events"
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   * @protobufOption (title) = "Financial Events"
   */
  FINANCIAL_EVENTS = 'FINANCIAL_EVENTS',
  /**
   * Web Proxy service
   * Web Proxy service
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Web Proxy service"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "Web Proxy service"
   */
  WEB_PROXY = 'WEB_PROXY',
  /**
   * Retailer Profile Info
   * Related to keeping the retailer's profile information up to date
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "Related to keeping the retailer's profile information up to date"
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Retailer Profile Info"
   */
  RETAILER_PROFILE_INFO = 'RETAILER_PROFILE_INFO',
  /**
   * Retailer Shipping Restriction
   * Related to retailer restrictions to prevent certain products from being shipped to certain countries (e.g, can't ship cosmetics to Spain)
   * Owned by Shipping (#pod-shipping)
   * @protobufOption (description) = "Related to retailer restrictions to prevent certain products from being shipped to certain countries (e.g, can't ship cosmetics to Spain)"
   * @protobufOption (pod) = FAIRE_POD_SHIPPING
   * @protobufOption (title) = "Retailer Shipping Restriction"
   */
  SHIPPING_RESTRICTION = 'SHIPPING_RESTRICTION',
  /**
   * Recommendations Infrastructure
   * Related to the infrastructure that powers Faire's recommendations
   * Owned by Inspire (#pod-discovery-inspire-eng)
   * @protobufOption (description) = "Related to the infrastructure that powers Faire's recommendations"
   * @protobufOption (pod) = FAIRE_POD_INSPIRE
   * @protobufOption (title) = "Recommendations Infrastructure"
   */
  RECOMMENDATIONS_INFRASTRUCTURE = 'RECOMMENDATIONS_INFRASTRUCTURE',
  /**
   * Discovery Recommendations
   * Related to the general process of recommendation at Faire, across all discovery surfaces
   * Owned by Inspire (#pod-discovery-inspire-eng)
   * @protobufOption (description) = "Related to the general process of recommendation at Faire, across all discovery surfaces"
   * @protobufOption (pod) = FAIRE_POD_INSPIRE
   * @protobufOption (title) = "Discovery Recommendations"
   */
  DISCOVERY_RECOMMENDATIONS = 'DISCOVERY_RECOMMENDATIONS',
  /**
   * Brand page
   * Area specific to brand page for retailers
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @protobufOption (description) = "Area specific to brand page for retailers"
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   * @protobufOption (title) = "Brand page"
   */
  BRAND_PAGE = 'BRAND_PAGE',
  /**
   * Product page
   * Area specific to product page for retailers
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @protobufOption (description) = "Area specific to product page for retailers"
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   * @protobufOption (title) = "Product page"
   */
  PRODUCT_PAGE = 'PRODUCT_PAGE',
  /**
   * Fonoa third-party vendor integration
   * Integration with Fonoa for business identifier validations
   * Owned by Brand Platform Compliance (#pod-brand-platform-compliance)
   * @protobufOption (description) = "Integration with Fonoa for business identifier validations"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_COMPLIANCE
   * @protobufOption (title) = "Fonoa third-party vendor integration"
   */
  FONOA = 'FONOA',
  /**
   * Mobile Core Infrastructure
   * Owned by Mobile Platform (#pod-mobile-platform-notifications)
   * @protobufOption (pod) = FAIRE_POD_MOBILE_PLATFORM
   * @protobufOption (title) = "Mobile Core Infrastructure"
   */
  MOBILE_CORE = 'MOBILE_CORE',
  /**
   * Retailer store management
   * Features related to managing multiple stores within a retailer.
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "Features related to managing multiple stores within a retailer."
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Retailer store management"
   */
  RETAILER_STORE_MANAGEMENT = 'RETAILER_STORE_MANAGEMENT',
  /**
   * Commission update requests
   * Features related to manage commission update requests from the brand
   * Owned by Faire Direct Growth (#pod-faire-direct-growth-notifications)
   * @protobufOption (description) = "Features related to manage commission update requests from the brand"
   * @protobufOption (pod) = FAIRE_POD_FAIRE_DIRECT_GROWTH
   * @protobufOption (title) = "Commission update requests"
   */
  COMMISSION_UPDATE_REQUESTS = 'COMMISSION_UPDATE_REQUESTS',
  /**
   * SCIM User Management
   * Features related Scim integrations with Okta.
   * Owned by Security (#pod-security)
   * @protobufOption (description) = "Features related Scim integrations with Okta."
   * @protobufOption (pod) = FAIRE_POD_SECURITY
   * @protobufOption (title) = "SCIM User Management"
   */
  SCIM = 'SCIM',
  /**
   * Internal API support for Salesforce
   * Internal API support for Salesforce
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Internal API support for Salesforce"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Internal API support for Salesforce"
   */
  INTERNAL_API_SALESFORCE = 'INTERNAL_API_SALESFORCE',
  /**
   * Internal API support for Catalogs Migration
   * Internal API support for Catalogs Migration
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @protobufOption (description) = "Internal API support for Catalogs Migration"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   * @protobufOption (title) = "Internal API support for Catalogs Migration"
   */
  INTERNAL_API_CATALOGS_MIGRATION = 'INTERNAL_API_CATALOGS_MIGRATION',
  /**
   * Internal API support for the embedded Retailer Shopify App
   * Internal API support for the embedded Retailer Shopify App
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (description) = "Internal API support for the embedded Retailer Shopify App"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "Internal API support for the embedded Retailer Shopify App"
   */
  INTERNAL_API_RETAILER_SHOPIFY_INTEGRATION = 'INTERNAL_API_RETAILER_SHOPIFY_INTEGRATION',
  /**
   * Internal API support for Inventory
   * Internal API support for Inventory
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Internal API support for Inventory"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Internal API support for Inventory"
   */
  INTERNAL_API_INVENTORY = 'INTERNAL_API_INVENTORY',
  /**
   * Internal API support for orders
   * Internal API support for orders
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Internal API support for orders"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Internal API support for orders"
   */
  INTERNAL_API_ORDERS = 'INTERNAL_API_ORDERS',
  /**
   * Faire for Teams
   * Faire for teams is a feature that allows brands to manage multiple users within their organization.
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Faire for teams is a feature that allows brands to manage multiple users within their organization."
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Faire for Teams"
   */
  FAIRE_FOR_TEAMS = 'FAIRE_FOR_TEAMS',
  /**
   * Multi Brand
   * Allows users to manage multiple brands.
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Allows users to manage multiple brands."
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Multi Brand"
   */
  MULTI_BRAND = 'MULTI_BRAND',
  /**
   * Alert Banners Brand
   * Alert banners for brands
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Alert banners for brands"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Alert Banners Brand"
   */
  ALERT_BANNERS_BRAND = 'ALERT_BANNERS_BRAND',
  /**
   * Alert Banners Retailer
   * Alert banners for retailers
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Alert banners for retailers"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Alert Banners Retailer"
   */
  ALERT_BANNERS_RETAILERS = 'ALERT_BANNERS_RETAILERS',
  /**
   * Clawback for brands
   * Clawback for brands
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Clawback for brands"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Clawback for brands"
   */
  ISSUE_REPORT_CLAWBACKS = 'ISSUE_REPORT_CLAWBACKS',
  /**
   * Clawback for retailers
   * Clawback for retailers
   * Owned by Financial Products (#pod-financial-products-sla)
   * @protobufOption (description) = "Clawback for retailers"
   * @protobufOption (pod) = FAIRE_POD_FINANCIAL_PRODUCTS
   * @protobufOption (title) = "Clawback for retailers"
   */
  RETURN_CLAWBACKS = 'RETURN_CLAWBACKS',
  /**
   * Canonical Data Model & ETLs
   * Canonical Data Model & ETLs
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Canonical Data Model & ETLs"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Canonical Data Model & ETLs"
   */
  CANONICAL_DATA = 'CANONICAL_DATA',
  /**
   * Financial Data Platform
   * Financial Data Platform
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Financial Data Platform"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Financial Data Platform"
   */
  FINACIAL_DATA_PLATFORM = 'FINACIAL_DATA_PLATFORM',
  /**
   * Data Security & Compliance
   * Data Security & Compliance
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Data Security & Compliance"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Data Security & Compliance"
   */
  DATA_SECURITY = 'DATA_SECURITY',
  /**
   * Snowflake
   * Data Warehouse (Snowflake)
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Data Warehouse (Snowflake)"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Snowflake"
   */
  DATA_WAREHOUSE = 'DATA_WAREHOUSE',
  /**
   * Airflow
   * Data Workflow Management (Airflow)
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Data Workflow Management (Airflow)"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Airflow"
   */
  DATA_AIRFLOW = 'DATA_AIRFLOW',
  /**
   * Fivetran
   * Data Movement (Fivetran)
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Data Movement (Fivetran)"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Fivetran"
   */
  DATA_FIVETRAN = 'DATA_FIVETRAN',
  /**
   * Looker
   * Data Reporting (Looker)
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Data Reporting (Looker)"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Looker"
   */
  DATA_LOOKER = 'DATA_LOOKER',
  /**
   * Mode
   * Data Reporting (Mode)
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Data Reporting (Mode)"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Mode"
   */
  DATA_MODE = 'DATA_MODE',
  /**
   * SelectStar
   * SelectStar
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "SelectStar"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "SelectStar"
   */
  DATA_SELECTSTAR = 'DATA_SELECTSTAR',
  /**
   * data.faire.com
   * data.faire.com
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "data.faire.com"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "data.faire.com"
   */
  DATA_FAIRE_COM = 'DATA_FAIRE_COM',
  /**
   * Data Mesh
   * Data Mesh
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Data Mesh"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Data Mesh"
   */
  DATA_MESH = 'DATA_MESH',
  /**
   * Data Environments (Staging, Pre-release, PROD)
   * Data Environments (Staging, Pre-release, PROD)
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Data Environments (Staging, Pre-release, PROD)"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Data Environments (Staging, Pre-release, PROD)"
   */
  DATA_ENV = 'DATA_ENV',
  /**
   * Stream Processing (Ververica, Flink)
   * Stream Processing (Ververica, Flink)
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Stream Processing (Ververica, Flink)"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Stream Processing (Ververica, Flink)"
   */
  STREAM_PROCESSING = 'STREAM_PROCESSING',
  /**
   * Data AWS (S3, EC2, etc.)
   * Data AWS (S3, EC2, etc.)
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Data AWS (S3, EC2, etc.)"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Data AWS (S3, EC2, etc.)"
   */
  DATA_AWS = 'DATA_AWS',
  /**
   * Data Contracts
   * Data Contracts
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @protobufOption (description) = "Data Contracts"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Data Contracts"
   */
  DATA_CONTRACTS = 'DATA_CONTRACTS',
  /**
   * Retailer Universe
   * Retailer Universe
   * Owned by Knowledge Graph (#pod-knowledge-graph)
   * @protobufOption (description) = "Retailer Universe"
   * @protobufOption (pod) = FAIRE_POD_KNOWLEDGE_GRAPH
   * @protobufOption (title) = "Retailer Universe"
   */
  RETAILER_UNIVERSE = 'RETAILER_UNIVERSE',
  /**
   * KG Scraping
   * External Data Integration (web crawlers, 3rd party APIs)
   * Owned by Knowledge Graph (#pod-knowledge-graph)
   * @protobufOption (description) = "External Data Integration (web crawlers, 3rd party APIs)"
   * @protobufOption (pod) = FAIRE_POD_KNOWLEDGE_GRAPH
   * @protobufOption (title) = "KG Scraping"
   */
  KG_SCRAPE = 'KG_SCRAPE',
  /**
   * KG Matching
   * NLP & Image-based Entity Matching
   * Owned by Knowledge Graph (#pod-knowledge-graph)
   * @protobufOption (description) = "NLP & Image-based Entity Matching"
   * @protobufOption (pod) = FAIRE_POD_KNOWLEDGE_GRAPH
   * @protobufOption (title) = "KG Matching"
   */
  KG_MATCH = 'KG_MATCH',
  /**
   * Experiment Metrics
   * Experiment Metrics
   * Owned by Core Data Infra (#pod-core-data-infra)
   * @deprecated
   * @protobufOption (description) = "Experiment Metrics"
   * @protobufOption (pod) = FAIRE_POD_CORE_DATA_INFRA
   * @protobufOption (title) = "Experiment Metrics"
   */
  XP_METRICS = 'XP_METRICS',
  /**
   * Feature Store & DSL
   * Feature Store & DSL
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @protobufOption (description) = "Feature Store & DSL"
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "Feature Store & DSL"
   */
  FEATURE_STORE = 'FEATURE_STORE',
  /**
   * Model Store (CometML)
   * Model Store (CometML)
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @protobufOption (description) = "Model Store (CometML)"
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "Model Store (CometML)"
   */
  MODEL_STORE = 'MODEL_STORE',
  /**
   * Inference Service
   * ML Inference Service
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @protobufOption (description) = "ML Inference Service"
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "Inference Service"
   */
  INFERENCE_SERVICE = 'INFERENCE_SERVICE',
  /**
   * ML Observability
   * ML Observability
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @protobufOption (description) = "ML Observability"
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "ML Observability"
   */
  ML_OBSERVABILITY = 'ML_OBSERVABILITY',
  /**
   * Observability
   * Observability
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Observability"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "Observability"
   */
  OBSERVABILITY = 'OBSERVABILITY',
  /**
   * Oracle Cloud GPU Cluster
   * Oracle Cloud GPU Cluster
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @deprecated
   * @protobufOption (description) = "Oracle Cloud GPU Cluster"
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "Oracle Cloud GPU Cluster"
   */
  ORACLE_CLOUD = 'ORACLE_CLOUD',
  /**
   * Oracle Cloud GPU Cluster Infra
   * Oracle Cloud GPU Cluster Infra
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @protobufOption (description) = "Oracle Cloud GPU Cluster Infra"
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "Oracle Cloud GPU Cluster Infra"
   */
  OCI_GPU_CLUSTER_INFRA = 'OCI_GPU_CLUSTER_INFRA',
  /**
   * ML Model Training Framework
   * ML Model Training Framework
   * Owned by Ai Platform (#data-alerts-ml-platform)
   * @protobufOption (description) = "ML Model Training Framework"
   * @protobufOption (pod) = FAIRE_POD_AI_PLATFORM
   * @protobufOption (title) = "ML Model Training Framework"
   */
  ML_TRAINING = 'ML_TRAINING',
  /**
   * Internal API support for Products
   * Internal API support for Products
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @protobufOption (description) = "Internal API support for Products"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   * @protobufOption (title) = "Internal API support for Products"
   */
  INTERNAL_API_PRODUCTS = 'INTERNAL_API_PRODUCTS',
  /**
   * Internal API support for Images
   * Internal API support for Images
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @protobufOption (description) = "Internal API support for Images"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   * @protobufOption (title) = "Internal API support for Images"
   */
  INTERNAL_API_IMAGES = 'INTERNAL_API_IMAGES',
  /**
   * Backend Test Infra
   * Common test utilities for backend services
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @protobufOption (description) = "Common test utilities for backend services"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   * @protobufOption (title) = "Backend Test Infra"
   */
  BACKEND_TEST_INFRA = 'BACKEND_TEST_INFRA',
  /**
   * Catalogs Service
   * Service-specific infrastructure for catalogs service
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @protobufOption (description) = "Service-specific infrastructure for catalogs service"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   * @protobufOption (title) = "Catalogs Service"
   */
  CATALOGS_SERVICE = 'CATALOGS_SERVICE',
  /**
   * Brand Terms of Service
   * Brand Terms of Service
   * Owned by Brand Platform Product (#pod-brand-platform-product)
   * @protobufOption (description) = "Brand Terms of Service"
   * @protobufOption (pod) = FAIRE_POD_BRAND_PLATFORM_PRODUCT
   * @protobufOption (title) = "Brand Terms of Service"
   */
  BRAND_TERMS_OF_SERVICE = 'BRAND_TERMS_OF_SERVICE',
  /**
   * Retailer Notifications
   * Retailer Notifications
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Retailer Notifications"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Retailer Notifications"
   */
  RETAILER_NOTIFICATIONS = 'RETAILER_NOTIFICATIONS',
  /**
   * Mobile Phoenix Architecture
   * Mobile Phoenix architecture infrastructure
   * Owned by Mobile Platform (#pod-mobile-platform-notifications)
   * @protobufOption (description) = "Mobile Phoenix architecture infrastructure"
   * @protobufOption (pod) = FAIRE_POD_MOBILE_PLATFORM
   * @protobufOption (title) = "Mobile Phoenix Architecture"
   */
  PHOENIX = 'PHOENIX',
  /**
   * Mobile Build Infrastructure
   * Build and CI infrastructure for mobile
   * Owned by Mobile Platform (#pod-mobile-platform-notifications)
   * @protobufOption (description) = "Build and CI infrastructure for mobile"
   * @protobufOption (pod) = FAIRE_POD_MOBILE_PLATFORM
   * @protobufOption (title) = "Mobile Build Infrastructure"
   */
  MOBILE_APP_BUILD_INFRASTRUCTURE = 'MOBILE_APP_BUILD_INFRASTRUCTURE',
  /**
   * Mobile App Settings
   * Settings and experiment infrastructure for mobile
   * Owned by Mobile Platform (#pod-mobile-platform-notifications)
   * @protobufOption (description) = "Settings and experiment infrastructure for mobile"
   * @protobufOption (pod) = FAIRE_POD_MOBILE_PLATFORM
   * @protobufOption (title) = "Mobile App Settings"
   */
  MOBILE_APP_SETTINGS = 'MOBILE_APP_SETTINGS',
  /**
   * Mobile App Startup
   * Mobile app launch and initialization processes
   * Owned by Mobile Platform (#pod-mobile-platform-notifications)
   * @protobufOption (description) = "Mobile app launch and initialization processes"
   * @protobufOption (pod) = FAIRE_POD_MOBILE_PLATFORM
   * @protobufOption (title) = "Mobile App Startup"
   */
  MOBILE_APP_STARTUP = 'MOBILE_APP_STARTUP',
  /**
   * Product Messaging
   * Product messaging platform
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Product messaging platform"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Product Messaging"
   */
  PRODUCT_MESSAGING = 'PRODUCT_MESSAGING',
  /**
   * Personalization
   * Personalization
   * Owned by Personalization (#personalization-team)
   * @protobufOption (description) = "Personalization"
   * @protobufOption (pod) = FAIRE_POD_PERSONALIZATION
   * @protobufOption (title) = "Personalization"
   */
  PERSONALIZATION = 'PERSONALIZATION',
  /**
   * Custom Arrangement
   * Tool to arrange products in the brand portal and then show them in the retailer portal in that order
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @protobufOption (description) = "Tool to arrange products in the brand portal and then show them in the retailer portal in that order"
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   * @protobufOption (title) = "Custom Arrangement"
   */
  CUSTOM_ARRANGEMENT = 'CUSTOM_ARRANGEMENT',
  /**
   * Runway Client
   * Integration with third-party vendor Runway, our mobile release tooling
   * Owned by Mobile Platform (#pod-mobile-platform-notifications)
   * @protobufOption (description) = "Integration with third-party vendor Runway, our mobile release tooling"
   * @protobufOption (pod) = FAIRE_POD_MOBILE_PLATFORM
   * @protobufOption (title) = "Runway Client"
   */
  RUNWAY = 'RUNWAY',
  /**
   * Conversational Search
   * Conversational Search aims to use LLMs to provide guided shopping assistance to users.
   * Owned by Discovery Infra Enablement (#pod-discovery-infra-enablement)
   * @protobufOption (description) = "Conversational Search aims to use LLMs to provide guided shopping assistance to users."
   * @protobufOption (pod) = FAIRE_POD_DISCOVERY_INFRA_ENABLEMENT
   * @protobufOption (title) = "Conversational Search"
   */
  CONVERSATIONAL_SEARCH = 'CONVERSATIONAL_SEARCH',
  /**
   * Retailer Address Management
   * Managing addresses for retailers; EG: CRUD operations as well as managing associations with other entities.
   * Owned by Upmarket Tooling (#pod-upmarket-tooling-sla)
   * @protobufOption (description) = "Managing addresses for retailers; EG: CRUD operations as well as managing associations with other entities."
   * @protobufOption (pod) = FAIRE_POD_UPMARKET_TOOLING
   * @protobufOption (title) = "Retailer Address Management"
   */
  RETAILER_ADDRESS_MANAGEMENT = 'RETAILER_ADDRESS_MANAGEMENT',
  /**
   * Retailer Backorders
   * Handling backorders on retailer side.
   * Owned by Retailer POS Integrations (#pod-retailer-order-management-sla)
   * @protobufOption (description) = "Handling backorders on retailer side."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ORDER_MANAGEMENT
   * @protobufOption (title) = "Retailer Backorders"
   */
  RETAILER_BACKORDERS = 'RETAILER_BACKORDERS',
  /**
   * The Digest or 'Weekly Drop' Page
   * A personalized page with recommendations for each retailer.
   * Owned by Inspire (#pod-discovery-inspire-eng)
   * @protobufOption (description) = "A personalized page with recommendations for each retailer."
   * @protobufOption (pod) = FAIRE_POD_INSPIRE
   * @protobufOption (title) = "The Digest or 'Weekly Drop' Page"
   */
  DIGEST_PAGE = 'DIGEST_PAGE',
  /**
   * Page data APIs
   * Moving page data returned from HomeResourceTemplateBuilder to page APIs.
   * Owned by Retailer Acquisition (#pod-acquisition)
   * @protobufOption (description) = "Moving page data returned from HomeResourceTemplateBuilder to page APIs."
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACQUISITION
   * @protobufOption (title) = "Page data APIs"
   */
  PAGE_DATA_APIS = 'PAGE_DATA_APIS',
  /**
   * Mobile CI
   * Mobile CI Projects Infrastructure.
   * Owned by Mobile Platform (#pod-mobile-platform-notifications)
   * @protobufOption (description) = "Mobile CI Projects Infrastructure."
   * @protobufOption (pod) = FAIRE_POD_MOBILE_PLATFORM
   * @protobufOption (title) = "Mobile CI"
   */
  MOBILE_CI = 'MOBILE_CI',
  /**
   * Framework for asynchronous csv exports
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Framework for asynchronous csv exports"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   */
  CSV_EXPORT = 'CSV_EXPORT',
  /**
   * MarTech
   * Integrations and support for external Marketing platforms like Meta and Google
   * Owned by Growth Platform (#pod-growth-platform)
   * @protobufOption (description) = "Integrations and support for external Marketing platforms like Meta and Google"
   * @protobufOption (pod) = FAIRE_POD_GROWTH_PLATFORM
   * @protobufOption (title) = "MarTech"
   */
  PAID_MARKETING = 'PAID_MARKETING',
  /**
   * Brand Messenger
   * Brand specific messaging
   * Owned by BOM (#pod-bom)
   * @protobufOption (description) = "Brand specific messaging"
   * @protobufOption (pod) = FAIRE_POD_BRAND_OPERATIONS_MANAGEMENT
   * @protobufOption (title) = "Brand Messenger"
   */
  BRAND_MESSENGER = 'BRAND_MESSENGER',
  /**
   * Retailer Messenger
   * Retailer specific messaging
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Retailer specific messaging"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Retailer Messenger"
   */
  RETAILER_MESSENGER = 'RETAILER_MESSENGER',
  /**
   * messenger infrastructure
   * Related to the infrastructure that powers Faire's messaging
   * Owned by Brand Unassigned (#pillar-brand-eng-sla)
   * @protobufOption (description) = "Related to the infrastructure that powers Faire's messaging"
   * @protobufOption (pod) = FAIRE_POD_BRAND_UNASSIGNED
   * @protobufOption (title) = "messenger infrastructure"
   */
  MESSENGER_INFRASTRUCTURE = 'MESSENGER_INFRASTRUCTURE',
  /**
   * Retailer Authorization
   * Retailer authorization for the Activation team (for example, credit check authorization)
   * Owned by Retailer Activation (#pod-retailer-activation)
   * @protobufOption (description) = "Retailer authorization for the Activation team (for example, credit check authorization)"
   * @protobufOption (pod) = FAIRE_POD_RETAILER_ACTIVATION
   * @protobufOption (title) = "Retailer Authorization"
   */
  RETAILER_AUTHORIZATION = 'RETAILER_AUTHORIZATION',
  /**
   * Shop Local
   * Shop Local app
   * Owned by Web Platform (#pod-web-platform)
   * @protobufOption (description) = "Shop Local app"
   * @protobufOption (pod) = FAIRE_POD_WEB_PLATFORM
   * @protobufOption (title) = "Shop Local"
   */
  SHOP_LOCAL = 'SHOP_LOCAL',
  /**
   * Config Vars
   * Configuring backend applications with ConfigVars and SecretConfigVars
   * Owned by Platform Infrastructure And Resilience (#pod-platform-infrastructure-and-resilience)
   * @protobufOption (description) = "Configuring backend applications with ConfigVars and SecretConfigVars"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_INFRASTRUCTURE_AND_RESILIENCE
   * @protobufOption (title) = "Config Vars"
   */
  CONFIG_VARS = 'CONFIG_VARS',
  /**
   * Caching
   * Caching data to improve performance and reduce load on downstream components
   * Owned by Platform Performance And Insights (#pod-platform-performance-and-insights)
   * @protobufOption (description) = "Caching data to improve performance and reduce load on downstream components"
   * @protobufOption (pod) = FAIRE_POD_PLATFORM_PERFORMANCE_AND_INSIGHTS
   * @protobufOption (title) = "Caching"
   */
  CACHING = 'CACHING',
  /**
   * Side Cart
   * The side cart experience, used on brand and PDP pages to show the items currently in cart
   * Owned by Evaluate (#pod-evaluate-notifications)
   * @protobufOption (description) = "The side cart experience, used on brand and PDP pages to show the items currently in cart"
   * @protobufOption (pod) = FAIRE_POD_EVALUATE
   * @protobufOption (title) = "Side Cart"
   */
  SIDE_CART = 'SIDE_CART',
  /**
   * Unlisted Products
   * Retailer requests unlisted products from Brands functionality
   * Owned by Brand Catalog Growth (#pod-brand-catalog-growth)
   * @protobufOption (description) = "Retailer requests unlisted products from Brands functionality"
   * @protobufOption (pod) = FAIRE_POD_BRAND_CATALOG_GROWTH
   * @protobufOption (title) = "Unlisted Products"
   */
  UNLISTED_PRODUCTS = 'UNLISTED_PRODUCTS',
}
