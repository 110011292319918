import React from "react";

import { Flex, IFlexProps } from "../Flex";

/**
 * @trackcomponent
 */
export const Row = React.forwardRef<
  HTMLDivElement,
  Omit<IFlexProps, "direction">
>((props, ref) => {
  return (
    <Flex
      {...props}
      ref={ref}
      // HACK: Row shouldn't accept direction, but it's passed by builder.io and possibly other sources. Support it for now.
      direction={(props as unknown as IFlexProps).direction ?? "row"}
    />
  );
});
