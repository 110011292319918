import { COOKIE_CURRENCY_OVERRIDE } from "@faire/web--source/common/consts/COOKIE_CURRENCY_OVERRIDE";
import { getCookies } from "@faire/web--source/common/cookies";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { makeObservable } from "@faire/web--source/common/makeObservable";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { Storage } from "@faire/web--source/common/Storage";
import { createStoreHook } from "@faire/web--source/ui/hooks/useStore";
import { ICurrencyConversionRate } from "@faire/web-api--source/indigofair/data/ICurrencyConversionRate";
import { IRetailer } from "@faire/web-api--source/indigofair/data/IRetailer";
import { IUser } from "@faire/web-api--source/indigofair/data/IUser";
import { UserRole } from "@faire/web-api--source/indigofair/data/UserRole";
import intersection from "lodash/intersection";
import { action, computed, observable } from "mobx";

import { Currency } from "./Currency";

export interface IFormatCentsConfiguration {
  userLocale?: string; // where the user is located
  currencyConversionRate?: ICurrencyConversionRate; // conversion rate given by backend based on locale
  selectedCurrency: Currency; // what currency the user chose
}

const CURRENCY_CONFIGURATION_KEY = "CURRENCY_CONFIGURATION_SELECTED_CURRENCY";
const allowedRoles = [UserRole.MAKER, UserRole.MAKER_EDITOR];

/**
 * @deprecated use `useCurrencyStore` or `withStores` instead
 */
export class CurrencyStore {
  static get = singletonGetter(CurrencyStore);

  @observable
  private _configuration: IFormatCentsConfiguration | undefined;

  constructor() {
    makeObservable(this);
  }

  @computed
  get retailerCurrency(): Currency | undefined {
    return getGlobalProperty<IRetailer>("retailer")?.currency as Currency;
  }

  setCurrency = (currency?: Currency) => {
    if (currency) {
      Storage.local.setItem(CURRENCY_CONFIGURATION_KEY, currency);
    } else {
      Storage.local.removeItem(CURRENCY_CONFIGURATION_KEY);
    }
  };

  @computed
  get effectiveCurrency(): Currency {
    if (this.isBrandPreview) {
      return (
        getCookies(COOKIE_CURRENCY_OVERRIDE) ??
        (getGlobalProperty<ICurrencyConversionRate>("currencyConversionRate")
          ?.to_currency as Currency | undefined) ??
        "USD"
      );
    }
    // Only brand preview and legacy Canadian retailers need to use localStorage
    if (
      !this.retailerCountry ||
      this.retailerCountry !== "CAN" ||
      this.retailerCurrency !== "USD"
    ) {
      return (
        this.retailerCurrency ??
        (getGlobalProperty<ICurrencyConversionRate>("currencyConversionRate")
          ?.to_currency as Currency | undefined) ??
        "USD"
      );
    }

    return (
      (Storage.local.getItem(CURRENCY_CONFIGURATION_KEY) as
        | Currency
        | undefined) ??
      this.retailerCurrency ??
      (getGlobalProperty<ICurrencyConversionRate>("currencyConversionRate")
        ?.to_currency as Currency | undefined) ??
      "USD"
    );
  }

  @computed
  get configuration(): IFormatCentsConfiguration {
    return this._configuration ?? this.defaultConfiguration;
  }

  @action
  setConfiguration = (configuration: IFormatCentsConfiguration) => {
    this._configuration = configuration;
  };

  @computed
  private get currencyConversions() {
    return getGlobalProperty<ICurrencyConversionRate>("currencyConversionRate");
  }

  @computed
  private get retailerCountry(): string | undefined {
    return getGlobalProperty<IRetailer>("retailer")?.country;
  }

  @computed
  private get user(): IUser | undefined {
    return getGlobalProperty<IUser>("user");
  }

  @computed
  private get isBrandPreview(): boolean {
    if (this.user?.roles === null || this.user?.roles === undefined) {
      return false;
    }
    return intersection(allowedRoles, this.user?.roles).length > 0;
  }

  /**
   * If the retailer's charged currency is USD we want to allow them to see CAD prices for now
   */
  @computed
  private get effectiveRetailerCurrency() {
    const { retailerCurrency } = this;
    if (retailerCurrency === "USD") {
      return undefined;
    }
    return retailerCurrency;
  }

  @computed
  private get defaultConfiguration(): IFormatCentsConfiguration {
    return {
      selectedCurrency: this.effectiveCurrency,
      currencyConversionRate: this.currencyConversionsRate,
    };
  }

  @computed
  private get currencyConversionsRate() {
    const { currencyConversions, effectiveRetailerCurrency } = this;
    if (
      currencyConversions?.to_currency === effectiveRetailerCurrency ||
      currencyConversions?.from_currency === effectiveRetailerCurrency
    ) {
      return currencyConversions;
    }
    return undefined;
  }
}

export const useCurrencyStore = createStoreHook(CurrencyStore);
