import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";

export type ViewportType =
  | "xxLarge"
  | "xLarge"
  | "desktop"
  | "tablet"
  | "mobile";

export interface IEstimatedViewport {
  width: number;
  height: number;
  type: ViewportType;
}

export const getEstimatedViewportType = (): IEstimatedViewport =>
  getGlobalProperty<IEstimatedViewport>("estimatedViewportType", {
    width: 1280,
    height: 768,
    type: "desktop",
  });
