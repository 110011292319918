import { SettingConst } from '../../types';

/**
 * Controls whether or not we use server components to render the logged-in brand page
 */
export const NEXTJS_RSC_LOGGED_IN_BRAND_PAGE_240826 =
  'NEXTJS_RSC_LOGGED_IN_BRAND_PAGE_240826' as SettingConst<
    'NEXTJS_RSC_LOGGED_IN_BRAND_PAGE_240826',
    boolean,
    'RETAILER' | 'RETAILER_HEAD_TAGS'
  >;
