import { IF_DEBUG } from "@faire/web--source/common/consts/DEBUG";
import { DEFAULT_LOCALES } from "@faire/web--source/common/consts/DEFAULT_LOCALES";
import { stringToGibberish } from "@faire/web--source/common/localization/gibberish";
import { Storage } from "@faire/web--source/common/Storage";
import { IDayMonthYear } from "@faire/web-api--source/indigofair/data/IDayMonthYear";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ITimestamp } from "@faire/web-api--source/indigofair/rfc3339/ITimestamp";
import { intlFormat } from "date-fns";
import upperFirst from "lodash/upperFirst";

import { getDateOrMillis, getUserLocale } from "./utils";

// prettier-ignore
export type AllowedFormats =
  | "MMM"       // Feb
  | "MMM d"     // Feb 1
  | "MMM dd"    // Feb 01
  | "MMM yyyy"  // Feb 2021
  | "MMMM"      // February
  | "MMMM d"    // February 1
  | "MMMM dd"   // February 01
  | "MMMM yyyy" // February 2021
  | "d"         // 1
  | "yyyy"      // 2021
  | LocalizedDateFormats
  | LocalizedTimeFormats
  | LocalizedDateTimeFormats;

// prettier-ignore
type LocalizedDateFormats =
  | "P"     // 2/1/2021
  | "PP"    // Feb 1, 2021
  | "PPP"   // February 1, 2021
  | "PPPP"; // Monday, February 1, 2021
type LocalizedTimeFormats =
  | "p" // 12:00 AM
  | "px"; // 12:00 AM GMT
type LocalizedDateTimeFormats =
  `${LocalizedDateFormats}${LocalizedTimeFormats}`;

/**
 * Return the formatted date string in the given format.
 *
 * @param date Date to be formatted.
 * @param format Format to be applied to the date. Check the {@link AllowedFormats}.
 * @param userLocale Set this param to override the user locale. Default value from {@link LocalizationStore} or "en-US" as fallback.
 *
 * @example
 * // Represent Friday, October 10, 2019 in German.
 * intlDate(new Date(2019, 9, 4, 12, 30), "PPPP", { code: "de" }); //=> Freitag, 4. Oktober 2019
 *
 * @example
 * // Represent April 2, 2020 in French.
 * intlDate(new Date(2020, 3, 2, 12, 45), "PP", { code: "fr" }); //=> 2 avr. 2020
 */
export const intlDate = (
  date: IDayMonthYear | ITimestamp | Date | number,
  format: AllowedFormats,
  userLocale?: Locale
): string => {
  const formatAtIndex = formats[format];
  const formatOptions: FormatOptions =
    format !== undefined && formatAtIndex
      ? formatAtIndex
      : ({} as FormatOptions);

  return intlDateInternal(date, formatOptions, userLocale);
};

/**
 * Return the formatted date string in the given format in the specified timezone.
 *
 * @param date Date to be formatted.
 * @param format Format to be applied to the date. Check the {@link AllowedFormats}.
 * @param timeZone Standard timezone formatted string such as 'America/Toronto'
 * @param userLocale Set this param to override the user locale. Default value from {@link LocalizationStore} or "en-US" as fallback.
 *
 * @example
 * // Represent April 2, 2020, 1:45 AM UTC in Berlin.
 * intlDateWithTimezone(new Date(2020, 3, 2, 1, 45), "PPp", "Europe/Berlin",); //=> Apr 2, 2020, 7:45 AM
 *
 * @example
 // Represent April 2, 2020, 1:45 AM UTC in Los Angeles
 * intlDateWithTimezone(new Date(2020, 3, 2, 1, 45), "PPp", "America/Los_Angeles",); //=> Apr 1, 2020, 10:45 PM
 * */
export const intlDateWithTimezone = (
  date: ITimestamp | Date | number,
  format: AllowedFormats,
  timeZone: string,
  userLocale?: Locale
): string => {
  const formatAtIndex = formats[format];
  const formatOptions: FormatOptions =
    format !== undefined && formatAtIndex
      ? formatAtIndex
      : ({} as FormatOptions);

  formatOptions.timeZone = timeZone;

  return intlDateInternal(date, formatOptions, userLocale);
};

export const intlDateFormatOptions = (format: AllowedFormats): FormatOptions =>
  formats[format] ?? {};

const intlDateInternal = (
  date: IDayMonthYear | ITimestamp | Date | number,
  formatOptions: FormatOptions,
  userLocale?: Locale
) => {
  if (Storage.local.getItem(IF_DEBUG)) {
    // eslint-disable-next-line no-console
    console.log("userLocale", userLocale);
  }
  const localeCode = userLocale?.code ?? getUserLocale().code;
  if (Storage.local.getItem(IF_DEBUG)) {
    // eslint-disable-next-line no-console
    console.log("localeCode", localeCode);
  }
  const formattedDate = intlFormat(getDateOrMillis(date), formatOptions, {
    locale: localeCode,
  });

  if (localeCode === DEFAULT_LOCALES[Language.GIBBERISH]) {
    return stringToGibberish(formattedDate);
  }

  return formattedDate;
};

/**
 * Return the formatted date in with the first letter capitilized.
 * We can use this funtion when the date is the first word in a sentence or when using dates in titles/labels.
 * We should not be using this function when the date is not at the start of a sentence as some languages has months in lower case.
 **/
export const capitalizedIntlDate = (
  date: IDayMonthYear | ITimestamp | Date | number,
  format: AllowedFormats,
  userLocale?: Locale
) => upperFirst(intlDate(date, format, userLocale));

type FormatOptions = {
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "narrow" | "short" | "long";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
  timeZoneName?: "short" | "long";
  weekday?: "narrow" | "short" | "long";
  localeMatcher?: "lookup" | "best fit";
  era?: "narrow" | "short" | "long";
  formatMatcher?: "basic" | "best fit";
  hour12?: boolean;
  timeZone?: string;
};

const formats: { [key: string]: FormatOptions } = {
  P: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  PP: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  PPP: {
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  PPPP: {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  },
  Pp: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  PPp: {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  PPPp: {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  PPPPp: {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
  },
  Ppx: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  },
  PPpx: {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  },
  PPPpx: {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  },
  PPPPpx: {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  },
  p: {
    hour: "numeric",
    minute: "numeric",
  },
  px: {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  },
  MMM: {
    month: "short",
  },
  "MMM d": {
    month: "short",
    day: "numeric",
  },
  "MMM dd": {
    month: "short",
    day: "2-digit",
  },
  "MMM yyyy": {
    month: "short",
    year: "numeric",
  },
  MMMM: {
    month: "long",
  },
  "MMMM d": {
    month: "long",
    day: "numeric",
  },
  "MMMM dd": {
    month: "long",
    day: "2-digit",
  },
  "MMMM yyyy": {
    month: "long",
    year: "numeric",
  },
  d: {
    day: "numeric",
  },
  yyyy: {
    year: "numeric",
  },
};
