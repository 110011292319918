import { getRequestInterceptors } from "@faire/retailer-shared/web-api/getRequestInterceptors";
import { CurrencyStore } from "@faire/web--source/common/currency/CurrencyStore";
import { clientSideLatencyRequestInterceptor } from "@faire/web--source/common/requests/request-interceptors/clientSideLatencyRequestInterceptor";
import { opentelemetryRequestInterceptor } from "@faire/web--source/common/requests/request-interceptors/opentelemetryRequestInterceptor";
import { RequestInterceptor } from "@faire/web-api--source/types";

import { darkReadsRequestInterceptor } from "@faire/retailer/initialize/dark-read/darkReadsRequestInterceptor";
import { getIsBrandPreview } from "@faire/retailer/lib/getIsBrandPreview";
import { getIsApparelRetailer } from "@faire/retailer/serialized-data/getIsApparelRetailer";

const interceptors = getRequestInterceptors({
  getIsApparelRetailer: () => !!getIsApparelRetailer(),
  getIsBrandPreview: () => getIsBrandPreview(),
  getSelectedCurrency: () => CurrencyStore.get().configuration.selectedCurrency,
});

interceptors.push(darkReadsRequestInterceptor);
interceptors.push(opentelemetryRequestInterceptor);
interceptors.push(clientSideLatencyRequestInterceptor);

export const requestInterceptors: ReadonlyArray<RequestInterceptor> =
  Object.freeze(interceptors);
