"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const Primary: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_MARKET_JULY_2023].decorative5;
  return (
    <SVGIcon
      width="240"
      height="150"
      viewBox="0 0 240 150"
      fill="none"
      {...rest}
    >
      <path
        d="M170.57 30.17C171.496 31.1495 174.175 36.455 175.517 39.1712C176.043 40.2353 176.404 41.3623 176.521 42.5434C177.608 53.5039 176.303 80.5704 176.303 88.3278C176.303 96.5438 172.33 105.008 168.148 117.954C163.965 130.9 153.301 139.863 142.845 145.838C132.39 151.813 119.843 149.822 114.824 149.075C109.806 148.328 90.5677 136.876 87.222 135.133C83.8762 133.39 77.6029 125.672 74.2571 122.187C71.5805 119.398 69.7961 114.884 69.2385 112.975C62.547 96.2946 62.7561 62.6846 63.1743 57.4564C63.5925 52.2281 67.1474 42.0206 69.2385 37.2902C71.3296 32.5597 77.812 31.0662 81.7851 31.3152C85.7582 31.5642 92.6588 38.5351 93.7044 40.7757C94.7499 43.0162 93.7044 81.8548 93.7044 83.3485C93.7044 84.8423 96.2137 87.5809 97.8866 88.3278C99.5594 89.0747 99.9777 87.8299 101.023 77.1245C102.069 66.419 104.996 44.2613 107.087 38.5351C109.179 32.809 117.752 27.8297 123.816 28.0787C129.88 28.3277 133.017 32.56 135.526 42.0206C137.534 49.5891 134.69 78.5353 133.017 92.0623C132.878 93.473 133.184 96.3941 135.526 96.7924C138.454 97.2904 137.827 96.0455 138.454 86.087C139.081 76.1285 142.218 40.7757 144.309 37.2902C146.4 33.8047 151.628 27.3317 158.947 26.5848C164.802 25.9873 169.315 28.8422 170.57 30.17Z"
        fill={iconColor}
      />
    </SVGIcon>
  );
};
