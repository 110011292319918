import { SettingConst } from '../../types';

/**
 * This experiment is to change the color and the copy of sales and promo texts on
 * multiple surfaces https:www.figma.com/design/ziBrhaBAj6OxLFsNkFoUPE/Color-in-product?node-id=1152-40090
 */
export const SALE_COLOR_COPY_CHANGE_241030 =
  'SALE_COLOR_COPY_CHANGE_241030' as SettingConst<
    'SALE_COLOR_COPY_CHANGE_241030',
    boolean,
    'RETAILER' | 'HOTJAR_RETAILER'
  >;
