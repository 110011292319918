import { Core } from "@faire/design-tokens";
import { Snowflake } from "@faire/web--source/slate/icons/faire-markets/Snowflake";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";

import {
  buttonEventsTheme,
  primaryDestructiveStyle,
  secondaryDestructiveStyle,
  tertiaryDestructiveStyle,
} from "../Button/theme";
import { extendBaseThemeVariant } from "../Button/utils";
import { pillEventsTheme } from "../Filter/theme";
import { FaireFashionWeek } from "../icons/FaireFashionWeek";

import { bannerEventsTheme } from "./Banners";
import { checkboxEventsTheme } from "./Checkbox";
import { DefaultTheme } from "./DefaultTheme";
import { eventsThemeColors, getEventTheme } from "./eventsThemeColors";
import { EventsThemeShape } from "./EventsThemeShape";
import { ITheme } from "./Theme";

export const EventsTheme: ITheme = {
  ...DefaultTheme,
  name: "EventsTheme",
  eventIcon: {
    Component: Snowflake,
    color: getEventTheme("faireMarket").badgeIcon,
    backgroundColor: getEventTheme("faireMarket").badgeBackground,
  },
  buttons2: buttonEventsTheme,
  pills: pillEventsTheme,
  banners: bannerEventsTheme,
  checkboxes: checkboxEventsTheme,
};

export const FaireMarketTheme: ITheme = {
  ...EventsTheme,
  name: "FaireMarketTheme",
  eventsThemeColors: getEventTheme("faireMarket"),
  eventIcon: {
    Component: EventsThemeShape.faireMarket.badge,
    color: getEventTheme("faireMarket").badgeIcon,
    backgroundColor: getEventTheme("faireMarket").badgeBackground,
  },
  buttons2: {
    ...buttonEventsTheme,
    primary: {
      ...buttonEventsTheme.primary,
    },
    secondary: {
      ...buttonEventsTheme.secondary,
    },
    tertiary: {
      ...buttonEventsTheme.tertiary,
      base: extendBaseThemeVariant(
        buttonEventsTheme.tertiary.base,
        ({ $destructive }) => ({
          "text-decoration-color": getEventTheme("faireMarket").promoText,
          color: getEventTheme("faireMarket").promoText,
          ...($destructive ? tertiaryDestructiveStyle.base : {}),
        })
      ),
      hover: extendBaseThemeVariant(
        buttonEventsTheme.tertiary.hover,
        ({ $destructive }) => ({
          "text-decoration-color": getEventTheme("faireMarket").promoText,
          color: getEventTheme("faireMarket").promoText,
          ...($destructive ? tertiaryDestructiveStyle.hover : {}),
        })
      ),
      active: extendBaseThemeVariant(
        buttonEventsTheme.tertiary.active,
        ({ $destructive }) => ({
          "text-decoration-color": getEventTheme("faireMarket").promoText,
          color: getEventTheme("faireMarket").promoText,
          ...($destructive ? tertiaryDestructiveStyle.active : {}),
        })
      ),
    },
  },
  pills: {
    ...pillEventsTheme,
    filter: {
      ...pillEventsTheme.filter,
      primary: {
        ...pillEventsTheme.filter?.primary,
        color: getEventTheme("faireMarket").primaryDark,
        hover: getEventTheme("faireMarket").primaryDark,
        contrast:
          getEventTheme("faireMarket").promoTextInverse ??
          getEventTheme("faireMarket").promoText,
      },
      secondary: pillEventsTheme.filter?.secondary ?? {
        color: Core.surface.subdued,
        hover: Core.border.muted,
        contrast:
          getEventTheme("faireMarket").promoTextInverse ??
          getEventTheme("faireMarket").promoText,
      },
      label: pillEventsTheme.filter?.label ?? { p: true },
    },
  },
  banners: {
    ...bannerEventsTheme,
    alert: {
      ...bannerEventsTheme.alert,
      color: getEventTheme("faireMarket").lightBackground,
      textColor: getEventTheme("faireMarket").promoText,
    },
  },
  checkboxes: {
    ...EventsTheme.checkboxes,
    primary: {
      ...checkboxEventsTheme.primary,
      fill: getEventTheme("faireMarket").primaryDark,
      icon: checkboxEventsTheme.primary?.icon ?? Core.surface.inverse,
      border: getEventTheme("faireMarket").primaryDark,
      checked: {
        ...checkboxEventsTheme.primary?.checked,
        color: getEventTheme("faireMarket").primaryDark,
      },
      hover: {
        ...checkboxEventsTheme.primary?.hover,
        color: getEventTheme("faireMarket").primaryDark,
      },
    },
  },
  toggle: {
    ...EventsTheme.toggle,
    active: {
      ...EventsTheme.toggle.active,
      on: {
        ...EventsTheme.toggle.active.on,
        toggle: {
          ...EventsTheme.toggle.active.on.toggle,
          container: {
            ...EventsTheme.toggle.active.on.toggle.container,
            surface: getEventTheme("faireMarket").primaryDark,
            border: getEventTheme("faireMarket").primaryDark,
          },
          indicator: {
            ...EventsTheme.toggle.active.on.toggle.indicator,
            surface: getEventTheme("faireMarket").promoText,
          },
        },
      },
      off: {
        ...EventsTheme.toggle.active.off,
        toggle: {
          ...EventsTheme.toggle.active.off.toggle,
          container: {
            ...EventsTheme.toggle.active.off.toggle.container,
            border: getEventTheme("faireMarket").primaryDark,
          },
          indicator: {
            ...EventsTheme.toggle.active.off.toggle.indicator,
            surface: getEventTheme("faireMarket").primaryDark,
          },
        },
      },
    },
  },
};

export const FaireFashionWeekTheme: ITheme = {
  ...EventsTheme,
  name: "FaireFashionWeekTheme",
  eventsThemeColors:
    eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3],
  eventIcon: {
    Component: FaireFashionWeek,
    color:
      eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3].badgeIcon,
    backgroundColor:
      eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
        .badgeBackground,
  },
  buttons2: {
    primary: {
      ...buttonEventsTheme.primary,
      base: extendBaseThemeVariant(
        buttonEventsTheme.primary.base,
        ({ $destructive }) => ({
          border: `1px solid ${
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark
          }`,
          "background-color": eventsThemeColors.faireFashionWeek.primaryLight,
          color:
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark,
          ...($destructive ? primaryDestructiveStyle.base : {}),
        })
      ),
      hover: extendBaseThemeVariant(
        buttonEventsTheme.primary.hover,
        ({ $destructive }) => ({
          border: `1px solid ${eventsThemeColors.faireFashionWeek.primaryLight}`,
          "background-color":
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark,
          color: eventsThemeColors.faireFashionWeek.primaryLight,
          ...($destructive ? primaryDestructiveStyle.hover : {}),
        })
      ),
      active: extendBaseThemeVariant(
        buttonEventsTheme.primary.active,
        ({ $destructive }) => ({
          border: `1px solid ${
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark
          }`,
          "background-color": eventsThemeColors.faireFashionWeek.primaryLight,
          color:
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark,
          ...($destructive ? primaryDestructiveStyle.active : {}),
        })
      ),
    },
    secondary: {
      ...buttonEventsTheme.secondary,
      base: extendBaseThemeVariant(
        buttonEventsTheme.secondary.base,
        ({ $destructive }) => ({
          border: `1px solid ${eventsThemeColors.faireFashionWeek.primaryLight}`,
          "background-color":
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark,
          color: eventsThemeColors.faireFashionWeek.primaryLight,
          ...($destructive ? secondaryDestructiveStyle.base : {}),
        })
      ),
      hover: extendBaseThemeVariant(
        buttonEventsTheme.secondary.hover,
        ({ $destructive }) => ({
          border: `1px solid ${
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark
          }`,
          "background-color": eventsThemeColors.faireFashionWeek.primaryLight,
          color:
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark,
          ...($destructive ? secondaryDestructiveStyle.hover : {}),
        })
      ),
      active: extendBaseThemeVariant(
        buttonEventsTheme.secondary.active,
        ({ $destructive }) => ({
          border: `1px solid ${eventsThemeColors.faireFashionWeek.primaryLight}`,
          "background-color":
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark,
          color: eventsThemeColors.faireFashionWeek.primaryLight,
          ...($destructive ? secondaryDestructiveStyle.active : {}),
        })
      ),
    },
    tertiary: {
      ...buttonEventsTheme.tertiary,
      base: extendBaseThemeVariant(
        buttonEventsTheme.tertiary.base,
        ({ $destructive }) => ({
          "text-decoration-color":
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark,
          color:
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark,
          ...($destructive ? tertiaryDestructiveStyle.base : {}),
        })
      ),
      hover: extendBaseThemeVariant(
        buttonEventsTheme.tertiary.hover,
        ({ $destructive }) => ({
          color: eventsThemeColors.faireFashionWeek.primaryLight,
          "text-decoration-color":
            eventsThemeColors.faireFashionWeek.primaryLight,
          ...($destructive ? tertiaryDestructiveStyle.hover : {}),
        })
      ),
      active: extendBaseThemeVariant(
        buttonEventsTheme.tertiary.active,
        ({ $destructive }) => ({
          color:
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark,
          "text-decoration-color":
            eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
              .primaryDark,
          ...($destructive ? tertiaryDestructiveStyle.active : {}),
        })
      ),
    },
  },
  pills: {
    ...pillEventsTheme,
    filter: {
      ...pillEventsTheme.filter,
      primary: {
        color: eventsThemeColors.faireFashionWeek.primaryLight,
        hover:
          eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
            .primaryDark,
        contrast:
          eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
            .primaryDark,
      },
      secondary: pillEventsTheme.filter?.secondary ?? {
        color: Core.surface.subdued,
        hover: Core.border.muted,
        contrast: Core.surface.default,
      },
      label: pillEventsTheme.filter?.label ?? { p: true },
    },
  },
  banners: {
    ...bannerEventsTheme,
    alert: {
      ...bannerEventsTheme.alert,
      color:
        eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
          .decorative1,
      textColor:
        eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3].promoText,
    },
  },
  checkboxes: {
    ...EventsTheme.checkboxes,
    primary: {
      ...checkboxEventsTheme.primary,
      fill: eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
        .primaryDark,
      icon: checkboxEventsTheme.primary?.icon ?? Core.surface.inverse,
      border:
        eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
          .primaryDark,
      checked: {
        ...checkboxEventsTheme.primary?.checked,
        color:
          eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
            .primaryDark,
      },
      hover: {
        ...checkboxEventsTheme.primary?.hover,
        color:
          eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3]
            .primaryDark,
      },
    },
  },
};
