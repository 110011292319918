import { PromotionalEventType } from '../../indigofair/common/PromotionalEventType';
import { IConfigurableLayoutElementColors } from '../../indigofair/data/IConfigurableLayoutElementColors';
import { IFaireIcon } from '../../indigofair/data/IFaireIcon';
import { IImage } from '../../indigofair/data/IImage';
import { ITimestamp } from '../../indigofair/rfc3339/ITimestamp';

export namespace ICurrentPromotionalEventResponse {
  export namespace IPromotionalEvent {
    export namespace IStyle {
      /**
       * Return an instance of IStyle with the provided fields. Any field not
       * listed will be populated with an empty value (an empty array,
       * or undefined, whichever is valid for the type of the value.)
       */
      export const build = (partial?: Partial<IStyle>): IStyle =>
        ({
          ...(partial ?? {}),
        }) as IStyle;
    }

    export interface IStyle {
      /**
       * Used for promo text
       * @protobufOption (faire.protobuf.validation) = HEX_COLOR_NO_HASH
       */
      text_color: string | undefined;
      /**
       * Used for PDP. brand pages, alert banner
       * @protobufOption (faire.protobuf.validation) = HEX_COLOR_NO_HASH
       */
      background_color: string | undefined;
      /**
       * Used in combination with [backgroundColor] to make a gradient when present
       * @protobufOption (faire.protobuf.validation) = HEX_COLOR_NO_HASH
       */
      background_gradient_color: string | undefined;
      /**
       * Used for PDP. brand pages, alert banner. Priortized over color string if provided
       * @protobufOption (faire.protobuf.validation) = IMAGE_URL
       */
      background_image: string | undefined;
      /**
       * Icon image
       * @protobufOption (faire.protobuf.validation) = IMAGE_URL
       */
      image_url: string | undefined;
      /**
       * Badge background
       * @protobufOption (faire.protobuf.validation) = HEX_COLOR_NO_HASH
       */
      badge_background_color: string | undefined;
      /**
       * Used in combination with [badgeBackgroundColor] to make a gradient when present
       * @protobufOption (faire.protobuf.validation) = HEX_COLOR_NO_HASH
       */
      badge_background_gradient_color: string | undefined;
      /**
       * Badge background. Prioritzed over color string if provided
       * @protobufOption (faire.protobuf.validation) = IMAGE_URL
       */
      badge_background_image: string | undefined;
      /**
       * Hero banner, button, wordmark
       * @protobufOption (faire.protobuf.validation) = HEX_COLOR_NO_HASH
       */
      primary_color1: string | undefined;
      /**
       * Button hover
       * @protobufOption (faire.protobuf.validation) = HEX_COLOR_NO_HASH
       */
      primary_color2: string | undefined;
      /**
       * Shopping Bag, Cart
       * @protobufOption (faire.protobuf.validation) = HEX_COLOR_NO_HASH
       */
      text_primary_light: string | undefined;
      /**
       * Homepage carousels
       */
      layout_element_colors: IConfigurableLayoutElementColors | undefined;
      /**
       * Brands on My Market List homepage carousel
       */
      market_listlayout_element_colors:
        | IConfigurableLayoutElementColors
        | undefined;
      /**
       * Homepage pre-show banner enrolled retailer
       */
      pre_show_enrolledlayout_element_colors:
        | IConfigurableLayoutElementColors
        | undefined;
      component_title_prefix_icon_type:
        | keyof typeof IFaireIcon.Type
        | undefined;
    }

    export namespace IMobileImages {
      /**
       * Return an instance of IMobileImages with the provided fields. Any field not
       * listed will be populated with an empty value (an empty array,
       * or undefined, whichever is valid for the type of the value.)
       */
      export const build = (partial?: Partial<IMobileImages>): IMobileImages =>
        ({
          ...(partial ?? {}),
        }) as IMobileImages;
    }

    /**
     * Mobile native does not store any assets since users do not update the apps immediately.
     * These images need to be backend driven to ensure that they can be updated at any time.
     */
    export interface IMobileImages {
      mobile_pre_show_banner_ineligible_image: IImage | undefined;
      mobile_pre_show_banner_enrolled_image: IImage | undefined;
      mobile_pre_show_banner_inactive_image: IImage | undefined;
      mobile_hero_banner_image: IImage | undefined;
      mobile_interstitial_banner_image: IImage | undefined;
      mobile_insider_banner_title_icon_image: IImage | undefined;
      mobile_transparent_title_icon_image: IImage | undefined;
      mobile_hompage_icon_image: IImage | undefined;
      mobile_homepage_enrolled_icon_image: IImage | undefined;
      mobile_insider_icon_image: IImage | undefined;
      mobile_insider_enrolled_icon_image: IImage | undefined;
    }

    /**
     * Grouping labels for individual promotional events to their parent events
     */
    export enum PromotionalEventGroup {
      PROMOTIONAL_EVENT_GROUP_UNKNOWN = 'PROMOTIONAL_EVENT_GROUP_UNKNOWN',
      MARKET = 'MARKET',
      FASHION_WEEK = 'FASHION_WEEK',
    }

    /**
     * Return an instance of IPromotionalEvent with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<IPromotionalEvent>
    ): IPromotionalEvent =>
      ({
        ...(partial ?? {}),
      }) as IPromotionalEvent;
  }

  export interface IPromotionalEvent {
    event_type: keyof typeof PromotionalEventType | undefined;
    event_name: string | undefined;
    main_style:
      | ICurrentPromotionalEventResponse.IPromotionalEvent.IStyle
      | undefined;
    /**
     * @deprecated
     */
    promotional_style:
      | ICurrentPromotionalEventResponse.IPromotionalEvent.IStyle
      | undefined;
    mobile_images:
      | ICurrentPromotionalEventResponse.IPromotionalEvent.IMobileImages
      | undefined;
    /**
     * Indicates that this event requires a isHighlightedForPromotionalEvent check,
     * as only a subset of products will be event badged regardless of existing brand promos
     */
    only_style_highlighted_for_promotional_event: boolean | undefined;
    event_group:
      | keyof typeof ICurrentPromotionalEventResponse.IPromotionalEvent.PromotionalEventGroup
      | undefined;
    /**
     * Event logo image URL
     */
    logo_url: string | undefined;
    /**
     * Event's Blog post URL
     */
    blog_url: string | undefined;
  }

  /**
   * Return an instance of ICurrentPromotionalEventResponse with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<ICurrentPromotionalEventResponse>
  ): ICurrentPromotionalEventResponse =>
    ({
      ...(partial ?? {}),
    }) as ICurrentPromotionalEventResponse;
}

export interface ICurrentPromotionalEventResponse {
  event: ICurrentPromotionalEventResponse.IPromotionalEvent | undefined;
  brand_portal_launch_time: ITimestamp | undefined;
  market_list_launch_time: ITimestamp | undefined;
  retailer_pre_show_launch_time: ITimestamp | undefined;
  pre_compute_homepage_launch_time: ITimestamp | undefined;
  /**
   * @protobufOption (faire.protobuf.validation) = TIMESTAMP_MILLIS
   */
  insider_early_access_start_at: number | undefined;
  /**
   * @protobufOption (faire.protobuf.validation) = TIMESTAMP_MILLIS
   */
  start_at: number | undefined;
  /**
   * @protobufOption (faire.protobuf.validation) = TIMESTAMP_MILLIS
   */
  end_at: number | undefined;
  /**
   * For the market events, it is determined by if the brand has setup promotions during the event
   * For the fashion week events, it is determined by if the brand has completed RSVP workflow
   */
  is_enrolled: boolean | undefined;
}
