"use client";

import React from "react";

import {
  LockCircle as ReactLockCircle,
  LockFlatTop as ReactLockFlatTop,
  LockRoundBottom as ReactLockRoundBottom,
  LockRoundedSquare as ReactLockRoundedSquare,
  LockSquare as ReactLockSquare,
} from "../deprecated/Lock";
import { SVGIcon } from "../SVGIcon";
import { createSVGIcon } from "../util";

/**
 * @trackcomponent
 */
export const FoundationLock = createSVGIcon(
  <>
    <path
      d="M3.42871 9.42871H20.5716V23.143H3.42871V9.42871Z"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 9.42836V6.85693C18 5.26563 17.3679 3.73951 16.2426 2.61429C15.1174 1.48907 13.5913 0.856934 12 0.856934C10.4087 0.856934 8.88258 1.48907 7.75736 2.61429C6.63214 3.73951 6 5.26563 6 6.85693V9.42836"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1431 16.2859C11.1431 16.5132 11.2334 16.7312 11.3941 16.8919C11.5549 17.0527 11.7729 17.143 12.0002 17.143C12.2275 17.143 12.4456 17.0527 12.6063 16.8919C12.767 16.7312 12.8574 16.5132 12.8574 16.2859C12.8574 16.0585 12.767 15.8405 12.6063 15.6798C12.4456 15.519 12.2275 15.4287 12.0002 15.4287C11.7729 15.4287 11.5549 15.519 11.3941 15.6798C11.2334 15.8405 11.1431 16.0585 11.1431 16.2859Z"
      stroke="currentcolor"
      fill="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  "Lock",
  { viewBox: "0 0 24 24", titleAccess: "Lock", fill: "none" }
);

export const LockSquare = ReactLockSquare;
export const LockCircle = ReactLockCircle;
export const LockRoundBottom = ReactLockRoundBottom;
export const LockFlatTop = ReactLockFlatTop;
export const LockRoundedSquare = ReactLockRoundedSquare;

type LockType =
  | "square"
  | "circle"
  | "roundBottom"
  | "flat"
  | "roundedSquare"
  | "foundation";

/**
 * TODO (jfangrad): Replace with just single foundation lock icon
 * @trackcomponent
 */
export const Lock = React.memo(
  React.forwardRef<
    SVGElement,
    SVGIcon.IProps & {
      lockType: LockType;
    }
  >(({ lockType, ...props }, ref) => {
    let SelectedLock = LockSquare;
    switch (lockType) {
      case "circle":
        SelectedLock = LockCircle;
        break;
      case "foundation":
        SelectedLock = FoundationLock;
        break;
      case "roundBottom":
        SelectedLock = LockRoundBottom;
        break;
      case "flat":
        SelectedLock = LockFlatTop;
        break;
      case "roundedSquare":
        SelectedLock = LockRoundedSquare;
        break;
      case "square":
      default:
        break;
    }
    return <SelectedLock ref={ref} {...props} />;
  })
);
