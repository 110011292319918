import { IMessageContents } from '../../faire/messenger/IMessageContents';

export namespace ISendMessengerMessageRequestV3 {
  /**
   * Return an instance of ISendMessengerMessageRequestV3 with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<ISendMessengerMessageRequestV3>
  ): ISendMessengerMessageRequestV3 =>
    ({
      ...(partial ?? {}),
    }) as ISendMessengerMessageRequestV3;
}

export interface ISendMessengerMessageRequestV3 {
  /**
   * @protobufOption (.faire.common.tokenType) = MESSENGER_CONVERSATION
   */
  conversation_token: string | undefined;
  message_contents: IMessageContents | undefined;
}
