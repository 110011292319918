"use client";

import { useIsSSR } from "@faire/web--source/common/server-side-rendering/isSSR";
import React, { ReactNode } from "react";

/**
 * Opt out of server side rendering. This component will render its children
 * on the client side only.
 *
 * You can also provide a `serverRenderFallback` prop to render something else on the server.
 */
export const NoSSR = ({
  children,
  serverRenderFallback,
}: {
  children: ReactNode;
  serverRenderFallback?: ReactNode;
}) => {
  const isSSR = useIsSSR();

  if (isSSR) {
    return <>{serverRenderFallback}</>;
  }

  return <>{children}</>;
};
