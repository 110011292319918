import { mapValues } from "@faire/web--source/common/mapValues";
import { moneyOrZero } from "@faire/web--source/common/money";
import { MoneyMath } from "@faire/web--source/common/MoneyMath";
import { ICartItemV2 } from "@faire/web-api--source/faire/carts/ICartItemV2";
import { IItemInventoryResponse } from "@faire/web-api--source/faire/carts/IItemInventoryResponse";
import { IUpdateCartResponseV2 } from "@faire/web-api--source/faire/carts/IUpdateCartResponseV2";
import { IFaireMoney } from "@faire/web-api--source/indigofair/data/IFaireMoney";

import {
  AddToCart,
  IAddedCartItem,
  IAddToCartBrandData,
  IAddToCartEvent,
} from "@faire/retailer/events";
import { RetailerStore } from "@faire/retailer/stores/domain/RetailerStore";

interface IUpdateCartItemV2 extends ICartItemV2 {
  addedQuantity: number;
  addedTester: boolean;
}

const productQuantitiesForCustomization = (
  LeanCartItemMap: Partial<
    Record<string, IItemInventoryResponse.IInventoryItem>
  >
) => {
  const productMap: Record<string, number> = {};

  mapValues(LeanCartItemMap ?? {}).forEach(
    (item: IItemInventoryResponse.IInventoryItem) => {
      if (!item?.product_token) {
        return;
      }

      const hash = `${item.product_token}${
        item.customizations?.[0]?.value ?? ""
      }`;

      productMap[hash] =
        (productMap[hash] ?? 0) + (item.aggregate_quantity ?? 0);
    }
  );

  return productMap;
};

export type UpdateCartUPLTParams =
  | {
      event: "none";
      startTime?: never;
      variant?: never;
    }
  | {
      event: "add-to-cart";
      startTime: number;
      variant: AddToCartVariants;
    };

export enum AddToCartVariants {
  QUICK_ADD_BTN = "QUICK_ADD_BTN",
  QUICK_ADD_MODAL = "QUICK_ADD_MODAL",
  ENCAPSULATED_QUICK_ADD_BTN = "ENCAPSULATED_QUICK_ADD_BTN",
  PDP_ADD_BTN = "PDP_ADD_BTN",
  ROUND_QUANTITY_PICKER = "ROUND_QUANTITY_PICKER",
  QUANTITY_DROPDOWN = "QUANTITY_DROPDOWN",
  REORDER = "REORDER",
  ADD_ALL_TO_CART = "ADD_ALL_TO_CART",
  FREE_DISPLAY = "FREE_DISPLAY",
}

export const triggerAddToCartEventV2 = (
  updatedCartResponse: IUpdateCartResponseV2,
  oldLeanCartItemMap: Partial<
    Record<string, IItemInventoryResponse.IInventoryItem>
  >,
  upltParams: UpdateCartUPLTParams
) => {
  // we currently only support updating one brand
  const updatedBrand = updatedCartResponse?.updated_brand_data?.[0];
  const brand = updatedCartResponse?.brand_carts?.find(
    (brand) => brand.brand_token === updatedBrand?.brand_token
  );

  if (!brand || !updatedBrand) {
    return;
  }

  const updatedItemTokens = updatedBrand.updated_cart_item_tokens ?? [];
  const brandItems = brand.cart_items ?? {};

  const addedItems: IUpdateCartItemV2[] = updatedItemTokens.flatMap((token) => {
    const oldItem = oldLeanCartItemMap[token];
    const item = brandItems[token];

    if (!item) {
      return [];
    }

    const addedTester = !!oldItem?.includes_tester && item?.includes_tester;
    let addedQuantity = (item?.quantity ?? 0) - (oldItem?.quantity ?? 0);

    if (item.type === ICartItemV2.Type.OPEN_SIZING) {
      const oldQuantityMap =
        productQuantitiesForCustomization(oldLeanCartItemMap);

      const hash = `${item.product_token}${
        item.customizations?.[0]?.value ?? ""
      }`;

      addedQuantity =
        (item?.display_quantity ?? 0) - (oldQuantityMap[hash] ?? 0);
    }

    if (!addedTester && addedQuantity <= 0) {
      return [];
    }

    return {
      ...item,
      addedQuantity: addedQuantity,
      addedTester: !!addedTester,
    };
  });

  const added: IAddedCartItem[] =
    addedItems?.map((item: IUpdateCartItemV2) => {
      const quantity = item.quantity ?? 0;
      const totalPrice = item.total_price?.amount_cents ?? 0;

      const price_cents = item.price?.amount_cents ?? 0;

      return {
        token: item.token ?? "",
        productToken: item.product_token ?? "",
        productOptionType: item.type,
        brandToken: brand.brand_token ?? "",
        quantity,
        productOptionToken: item.product_option_token ?? "",
        totalPriceCents: quantity * totalPrice,
        totalPrice: item.total_price,
        isOpenSizing: item.type === ICartItemV2.Type.OPEN_SIZING ?? false,
        productName: item.name,
        imageUrl: item.image?.url ?? "",
        addedQuantity: item.addedQuantity,
        addedPrice: item.addedQuantity * price_cents,
        addedTester: item.addedTester,
        styleGroupName: item.style_group_name,
        styleName: item.style_name,
        totalPromoPrice: item.total_price_with_promos,
      };
    }) ?? [];
  if (added.length === 0) {
    return;
  }

  const amountBelowMinimum = brand?.amount_below_minimum?.amount_cents ?? 0;
  const amountOverMaximum = brand?.amount_over_maximum?.amount_cents ?? 0;
  const hasCartItemIssues = !!Object.values(brand?.cart_items ?? {})?.some(
    (item?: ICartItemV2) => (item?.issues?.length ?? 0) > 0
  );

  const brandData = IAddToCartBrandData.build({
    name: brand?.brand_name,
    token: brand?.brand_token,
    hasEarnedPromo:
      !!brand.promo_description?.earned_promo_or_minimum_description,
    brandPromoDescription:
      brand?.promo_description?.earned_promo_or_minimum_description,
    minimumOrderAmountCents: brand?.minimum_order_amount?.amount_cents ?? 0,
    hasCartItemIssues: hasCartItemIssues,
    brandTotalAmount: brand?.total?.amount_cents ?? 0,
    isAboveFirstOrderMinimumThreshold:
      !!brand.has_ordered_from_brand && amountBelowMinimum >= 0,
    deltaFromMinimum: IFaireMoney.build({
      ...brand?.amount_below_minimum,
      amount_cents: Math.abs(brand?.amount_below_minimum?.amount_cents ?? 0),
    }),
  });

  const brandTotalBeforeUpdate = MoneyMath.subtract(
    moneyOrZero(brand.total, RetailerStore.get().displayCurrency),
    moneyOrZero(updatedBrand?.amount_added, RetailerStore.get().displayCurrency)
  );

  const wasAlreadyOverMinimum = MoneyMath.greaterOrEqual(
    brandTotalBeforeUpdate,
    moneyOrZero(brand.minimum_order_amount, RetailerStore.get().displayCurrency)
  );

  const tooltips = brand.cart_items_tooltips ?? {};
  const tokens = Object.keys(tooltips);
  const tooltipMap = new Map<string, string>();
  tokens.forEach((token) => {
    const tooltip = tooltips[token];
    if (tooltip) {
      tooltipMap.set(token, tooltip);
    }
  });

  return AddToCart.publish(
    IAddToCartEvent.build({
      added,
      brandData,
      minimumReached: amountBelowMinimum <= 0 && !wasAlreadyOverMinimum,
      remaining:
        amountBelowMinimum > 0
          ? brand?.amount_below_minimum
          : IFaireMoney.build(),
      amountAdded: updatedBrand?.amount_added ?? IFaireMoney.build(),
      maximumExceeded: amountOverMaximum > 0,
      exceeding:
        amountOverMaximum > 0
          ? brand?.amount_over_maximum
          : IFaireMoney.build(),
      isAlreadyOverMinimum: wasAlreadyOverMinimum,
      cartItemsTooltips: tooltipMap,
      ...upltParams,
    })
  );
};
