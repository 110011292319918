"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const Tertiary: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_WINTER_MARKET_2023]
      .decorative3;
  return (
    <SVGIcon
      width="194"
      height="194"
      viewBox="0 0 194 194"
      fill="none"
      {...rest}
    >
      <path
        d="M0.00924963 77.6627C0.00924963 76.9807 -0.659036 57.2037 9.36524 38.7906C19.3895 20.3775 38.1015 12.8759 52.8038 5.37426C67.506 -2.12736 90.2277 -0.763431 106.267 3.32837C112.415 4.89689 122.974 8.10213 130.325 15.6038C137.676 23.1054 147.7 31.289 157.056 61.9775C166.412 92.6659 152.378 121.99 130.325 141.086C108.271 160.181 77.7305 160.427 70.922 159.858C64.1135 159.29 38.7698 154.043 23.3992 141.086C8.02867 128.128 2.68239 114.489 1.34582 104.259C0.00924967 94.0299 0.00924963 77.6627 0.00924963 77.6627Z"
        fill={iconColor}
      />
    </SVGIcon>
  );
};
