import { SettingConst } from '../../types';

/**
 * Seasonal warehouse sale end time
 */
export const SEASONAL_WAREHOUSE_SALE_END_AT =
  'SEASONAL_WAREHOUSE_SALE_END_AT' as SettingConst<
    'SEASONAL_WAREHOUSE_SALE_END_AT',
    number,
    'RETAILER'
  >;
