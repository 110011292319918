import { Core } from "@faire/design-tokens";

import { IEmbeddedLinkTheme } from "./types";

export const embeddedLinkTheme: IEmbeddedLinkTheme = {
  base: {
    display: "inline-flex",
    "background-color": "transparent",
    "text-decoration": "underline",
    "text-decoration-color": Core.text.default,
    color: Core.text.default,
  },
  hover: {
    color: Core.text.hover,
    "text-decoration": "underline",
    "text-decoration-color": Core.text.hover,
  },
  disabled: {
    color: Core.text.disabled,
    "text-decoration-color": Core.text.disabled,
  },
  active: {},
};
