import { request } from '../../..';
import { IGetMostRecentMessageResponse } from '../../../indigofair/data/IGetMostRecentMessageResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';

export const path = () => {
  return `/api/messenger/most-recent-message`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/messenger/most-recent-message';

/**
 * Product Area: MESSENGER_INFRASTRUCTURE
 */
export function execute(): Promise<IGetMostRecentMessageResponse>;
export function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetMostRecentMessageResponse>>;
export function execute(
  options?: EndpointOptions
): Promise<IGetMostRecentMessageResponse>;
export function execute(
  options?: EndpointOptions
): Promise<
  IGetMostRecentMessageResponse | SuccessResponse<IGetMostRecentMessageResponse>
> {
  const { route, ...requestOptions } = build(options);
  return request<IGetMostRecentMessageResponse>(requestOptions);
}
execute[PATH] = 'api/messenger/most-recent-message/get';
export default execute;
