import { removeLocaleFromUrl } from "@faire/web--source/ui/routing/util";
import { route as brandPageRoute } from "@faire/web-api--source/routes/brand/brandToken";
import { route as faireDirectRoute } from "@faire/web-api--source/routes/direct/brandAlias";
import { route as faireDirectSalesRepRoute } from "@faire/web-api--source/routes/direct/brandAlias/rep/salesRepCode";
import { route as marketsRoute } from "@faire/web-api--source/routes/markets";
import { route as collectionRoute } from "@faire/web-api--source/routes/product-based-collection/product_based_collection_token";
import { route as productPageRoute } from "@faire/web-api--source/routes/product/productToken";
import { route as salesRepRoute } from "@faire/web-api--source/routes/rep/salesRepCode";
import { route as welcomeRoute } from "@faire/web-api--source/routes/welcome/r";
import some from "lodash/some";
import qs from "query-string";
import { matchPath } from "react-router-dom";

const FAIRE_DIRECT_LANDING_PAGE_ROUTES = [
  faireDirectRoute,
  faireDirectSalesRepRoute,
];

const VALID_LOGGED_OUT_FAIRE_DIRECT_ROUTES = (
  faireDirectBrandToken: string
) => [
  ...FAIRE_DIRECT_LANDING_PAGE_ROUTES,
  welcomeRoute,
  brandPageRoute.replace(":brandToken([^/]*)", faireDirectBrandToken),
  productPageRoute,
  collectionRoute,
  salesRepRoute,
  marketsRoute,
];

const parsePath = (path: string) => {
  const { url: pathWithoutQueryParams } = qs.parseUrl(path);
  const pathWithoutQueryParamsAndLocale = removeLocaleFromUrl(
    pathWithoutQueryParams
  );

  return pathWithoutQueryParamsAndLocale;
};

export const isValidFaireDirectPath = (
  path: string,
  faireDirectBrandToken: string
): boolean => {
  const pathWithoutQueryParamsAndLocale = parsePath(path);

  // welcomeRoute (sign-up flow) mustn't match path exactly.
  if (
    matchPath(pathWithoutQueryParamsAndLocale, {
      path: welcomeRoute,
      exact: false,
    })
  ) {
    return true;
  }

  return some(
    VALID_LOGGED_OUT_FAIRE_DIRECT_ROUTES(faireDirectBrandToken),
    (validFaireDirectPath) =>
      matchPath(pathWithoutQueryParamsAndLocale, {
        path: validFaireDirectPath,
        exact: true,
      })
  );
};

/**
 * A more strict check than `isValidFaireDirectPath` as it checks
 * specifically the routes that will always be Faire Direct active,
 * that is, the Faire Direct landing page routes.
 */
export const isValidFaireDirectPathWithoutQualification = (
  path: string
): boolean => {
  const pathWithoutQueryParamsAndLocale = parsePath(path);

  return some(FAIRE_DIRECT_LANDING_PAGE_ROUTES, (validFaireDirectPath) =>
    matchPath(pathWithoutQueryParamsAndLocale, {
      path: validFaireDirectPath,
      exact: true,
    })
  );
};

export const isPDP = (path: string): boolean => {
  const pathWithoutQueryParamsAndLocale = parsePath(path);

  return !!matchPath(pathWithoutQueryParamsAndLocale, {
    path: productPageRoute,
    exact: true,
  });
};
