import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { QueryParameters as CategoryQueryParameters } from "@faire/web-api--source/routes/category/c1CategoryUrlId";
import { match as matchC1CategoryBrandValueRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/brand-value/brandValue/match";
import { match as matchC1CategoryRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/match";
import { match as matchC2CategoryBrandValueRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/brand-value/brandValue/match";
import { PathParameters as C3CategoryBrandValuePathParameters } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId/brand-value/brandValue";
import { match as matchC3CategoryBrandValueRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId/brand-value/brandValue/match";
import { match as matchC3CategoryRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId/match";
import { match as matchC2CategoryRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/match";

import { isLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { getSettingNextJSRscCategoryPage } from "@faire/retailer/settings/useSettingNextJSRscCategoryPage";
import { isRSCCategoryNavigationPath } from "@faire/retailer/views/category/HubsRSC/isRSCCategoryNavigationPath";

const supportedRoutesMatch = [
  matchC3CategoryBrandValueRoute,
  matchC2CategoryBrandValueRoute,
  matchC1CategoryBrandValueRoute,
  matchC3CategoryRoute,
  matchC2CategoryRoute,
  matchC1CategoryRoute,
];
export const getShouldRequestRSCOnCategoryPage = (options: {
  prevPathname: string;
  prevSearch: string;
  pathname: string;
  search: string;
}) => {
  const { prevPathname, prevSearch, pathname, search } = options;
  return (
    shouldShowRSCOnCategoryPage() &&
    isCategoryPageRoute(pathname, search) &&
    isProductFirstOrHubCategoryPage(pathname) &&
    buildCategoryPageLoadKey(prevPathname, prevSearch) !==
      buildCategoryPageLoadKey(pathname, search)
  );
};

const isProductFirstOrHubCategoryPage = (pathname: string) => {
  const supportedRouteMatch = supportedRoutesMatch.find((match) =>
    match(pathname)
  );
  const matchedPathParams: Partial<C3CategoryBrandValuePathParameters> =
    supportedRouteMatch?.(pathname)?.params ?? {};
  // brandValue param is a hack to preselect a filter and is not part of navigation path
  delete matchedPathParams?.brandValue;

  const navigationPath = Object.values(matchedPathParams)
    .map(decodeURIComponent)
    .join("|");

  return isRSCCategoryNavigationPath(navigationPath);
};

const shouldShowRSCOnCategoryPage = () => {
  const search = getLocationOrThrow().search;
  const rscParam = (
    CategoryQueryParameters.parse(search) as Record<string, string | undefined>
  ).rsc;
  return getSettingNextJSRscCategoryPage(rscParam) && isLoggedInRetailer();
};

const isCategoryPageRoute = (pathname: string, search: string) => {
  if (!pathname.endsWith("/")) {
    pathname += "/";
  }
  return supportedRoutesMatch.some(
    (match) => match(`${pathname}${search}`) !== null
  );
};

const buildCategoryPageLoadKey = (pathname: string, search: string) => {
  const queryParam = CategoryQueryParameters.parse(search);
  return `${pathname}-${queryParam.filters}-${queryParam.page}`;
};
