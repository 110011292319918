import { createContextStore } from "@faire/web--source/ui/hooks/ContextStore";

const {
  useStore: usePromoModalContextStore,
  Provider: PromoModalContextStoreProvider,
} = createContextStore(
  {
    hasUserClosedModal: false,
  },
  {
    name: "PromoModalContextStore",
  }
);

export { usePromoModalContextStore, PromoModalContextStoreProvider };
