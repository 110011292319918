import { FIRST_ORDER_INCENTIVE_MODAL_DISMISS } from "@faire/web-api--source/indigofair/data/IUserMilestone/RetailerMilestone/FIRST_ORDER_INCENTIVE_MODAL_DISMISS";
import { LAST_CHANCE_FIRST_ORDER_INCENTIVE_MODAL_DISMISS } from "@faire/web-api--source/indigofair/data/IUserMilestone/RetailerMilestone/LAST_CHANCE_FIRST_ORDER_INCENTIVE_MODAL_DISMISS";
import { useCallback } from "react";

import { useMilestone } from "@faire/retailer/stores/domain/useMilestone";

export const useDismissFOIModalMilestones = () => {
  const {
    count: foiModalDismissCount,
    incrementCount: incrementFoiModalDismissCount,
    setHasOccurred: setHasFOIModalDismissed,
  } = useMilestone(FIRST_ORDER_INCENTIVE_MODAL_DISMISS);

  const {
    count: lastChanceFoiModalDismissCount,
    incrementCount: incrementLastChanceFoiModalDismissCount,
    setHasOccurred: setHasLastChanceModalDismissed,
  } = useMilestone(LAST_CHANCE_FIRST_ORDER_INCENTIVE_MODAL_DISMISS);

  const dismissFirstOrderIncentiveModal = useCallback(() => {
    setHasFOIModalDismissed();
    incrementFoiModalDismissCount();
  }, [setHasFOIModalDismissed, incrementFoiModalDismissCount]);

  const dismissLastChanceFOIModal = useCallback(() => {
    setHasLastChanceModalDismissed();
    incrementLastChanceFoiModalDismissCount();
  }, [setHasLastChanceModalDismissed, incrementLastChanceFoiModalDismissCount]);

  return {
    foiModalDismissCount,
    lastChanceFoiModalDismissCount,
    dismissFirstOrderIncentiveModal,
    dismissLastChanceFOIModal,
  };
};
