import {
  ITraceEventMarker,
  TraceEventTracker,
} from "./TraceEventMarkerTracker";

export const getTraceTiming = (
  sourceEventKey: string,
  sourceEventMarker?: ITraceEventMarker,
  traceTime?: number
): number | undefined => {
  const currentTime = traceTime ? traceTime : performance.now();

  const sourceMarker = sourceEventMarker
    ? sourceEventMarker
    : TraceEventTracker.get().getTraceHash(sourceEventKey);

  return Math.round(currentTime - sourceMarker.sourceEventStart);
};
