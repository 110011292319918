import invert from "lodash/invert";

export const Family = {
  serif: "Nantes, serif",
  sansSerif: "Graphik, sans-serif",
  /** @deprecated use Family.serif instead. */
  bigCaslon: "Big Caslon, serif",
} as const;

export type Family = (typeof Family)[keyof typeof Family];

export const Weight = {
  thin: "100",
  book: "200",
  light: "300",
  medium: "400",
  bold: "700",
} as const;
export type Weight = (typeof Weight)[keyof typeof Weight];

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export const getFontFamilyWithWeight = (family: Family, weight: Weight) => {
  const fontFamilyBase =
    family === Family.serif
      ? "Sentinel"
      : family === Family.sansSerif
      ? "GothamSSm"
      : "BigCaslon";
  const weightSuffix = invert(Weight)[`${weight}`] ?? "medium";
  return `${fontFamilyBase}-${weightSuffix
    .charAt(0)
    .toUpperCase()}${weightSuffix.slice(1)}, ${family}`;
};

/** @deprecated This is an old font. Use Alphabet Soup fonts instead */
export const mediumBigCaslon = getFontFamilyWithWeight(
  Family.bigCaslon,
  Weight.medium
);

/** @deprecated This is an old font. Use Alphabet Soup fonts instead */
export const lightSentinel = getFontFamilyWithWeight(
  Family.serif,
  Weight.light
);

/** @deprecated This is an old font. Use Alphabet Soup fonts instead */
export const bookSentinel = getFontFamilyWithWeight(Family.serif, Weight.book);

/** @deprecated This is an old font. Use Alphabet Soup fonts instead */
export const mediumSentinel = getFontFamilyWithWeight(
  Family.serif,
  Weight.medium
);

/** @deprecated This is an old font. Use Alphabet Soup fonts instead */
export const bookGotham = getFontFamilyWithWeight(
  Family.sansSerif,
  Weight.book
);

/** @deprecated This is an old font. Use Alphabet Soup fonts instead */
export const mediumGotham = getFontFamilyWithWeight(
  Family.sansSerif,
  Weight.medium
);

/** @deprecated This is an old font. Use Alphabet Soup fonts instead */
export const boldGotham = getFontFamilyWithWeight(
  Family.sansSerif,
  Weight.bold
);
