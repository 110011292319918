export namespace IRetailerAccountUserManagement {
  export enum Role {
    ROLE_UNKNOWN = 'ROLE_UNKNOWN',
    OWNER = 'OWNER',
    ADMIN = 'ADMIN',
    CONTRIBUTOR = 'CONTRIBUTOR',
  }

  export enum Permission {
    PERMISSION_UNKNOWN = 'PERMISSION_UNKNOWN',
    CAN_PLACE_ORDER = 'CAN_PLACE_ORDER',
  }

  /**
   * Return an instance of IRetailerAccountUserManagement with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IRetailerAccountUserManagement>
  ): IRetailerAccountUserManagement =>
    ({
      ...(partial ?? {}),
    }) as IRetailerAccountUserManagement;
}

export interface IRetailerAccountUserManagement {}
