import { useQuery as useCartBrandDiscountsQuery } from "@faire/web-api--source/api/cart/brand-discounts/get-hooks";
import { useMemo } from "react";

import { getBrandDiscounts } from "@faire/retailer/serialized-data/getBrandDiscounts";

export const useBrandDiscounts = () => {
  const { data, refetch } = useCartBrandDiscountsQuery({
    initialData: getBrandDiscounts,
    staleTime: Infinity,
  });

  return useMemo(() => {
    const brandDiscounts = data?.brand_discounts ?? {};

    return { brandDiscounts, refetch };
  }, [data?.brand_discounts, refetch]);
};
