import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { useEffect, useRef } from "react";

export const useHandleResize = (
  handleResize: () => void,
  { fireImmediately }: { fireImmediately: boolean } = { fireImmediately: false }
) => {
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current && fireImmediately) {
      handleResize();
    }

    if (isInitialRender.current) {
      isInitialRender.current = false;
    }

    getWindow()?.addEventListener("resize", handleResize, false);
    return () => {
      getWindow()?.removeEventListener("resize", handleResize, false);
    };
  }, [fireImmediately, handleResize]);
};
