import { isSandbox } from "./sandbox";

/**
 * Replaces the *first* subdomain of a hostname with a new subdomain.
 * If the url provided contains no subdomains, it will add the provided one.
 * @param url The url to replace the subdomain
 * @param subdomain The subdomain to replace
 * @returns The url with the new subdomain
 */
export const replaceSubdomain = (url: string, subdomain: string) => {
  const replace = "://" + subdomain + ".";

  // Do not replace if subdomain is not provided or if url is localhost
  if (url.replace(/^https?:\/\//, "").startsWith("localhost") || !subdomain) {
    return url;
  }

  // Prepend http:// if not using SSL
  if (!/^\w*:\/\//.test(url)) {
    // eslint-disable-next-line @faire/require-https
    url = "http://" + url;
  }

  // Remove the subdomain if the url is a sandbox url
  // Subdomains do not work in our sandbox environment
  if (isSandbox(url)) {
    return url.replace(replace, "://");
  }

  // Check if we got a subdomain in url
  if ((url.match(/\.[^.]+/g)?.length ?? 0) > 1) {
    return url.replace(/(:\/\/\w+\.)/, replace);
  }

  return url.replace(/:\/\//, replace);
};
