"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const Secondary: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_WINTER_MARKET_2023]
      .decorative5;
  return (
    <SVGIcon
      width="199"
      height="175"
      viewBox="0 0 199 175"
      fill="none"
      {...rest}
    >
      <path
        d="M198.822 175L0.00105286 175C0.00105286 175 -1.45018 4.41437 103.04 0.0774605C207.53 -4.25946 198.822 175 198.822 175Z"
        fill={iconColor}
      />
    </SVGIcon>
  );
};
