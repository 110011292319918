import { IPerfDarkReadsConfig } from '../../indigofair/settings/IPerfDarkReadsConfig';
import { SettingConst } from '../../types';

/**
 *   Configuration for sending an overridden locale for dark read
 *   requests from endpoints with localeOverride = true set in the DarkReads annotation
 */
export const PERF_DARK_READ_TESTING_OVERRIDE_LOCALE_HEADER =
  'PERF_DARK_READ_TESTING_OVERRIDE_LOCALE_HEADER' as SettingConst<
    'PERF_DARK_READ_TESTING_OVERRIDE_LOCALE_HEADER',
    IPerfDarkReadsConfig,
    'RETAILER'
  >;
