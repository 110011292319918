import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'signin-hcaptcha_render_is_not_function-error';

/**
 * hCaptcha API was loaded but the render property of hcaptcha on window is not a function.
 */
export function trackSigninHcaptchaRenderIsNotFunctionError(): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
  });
}
