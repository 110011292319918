import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";

import { getFullLanguageName } from "../consts/THREE_TO_FULL_LANGUAGE_NAME";
import { getTwoLetterCountryCode } from "../consts/THREE_TO_TWO_LETTER_COUNTRY_ISO_CODE";

const getCountryLabel = (
  country: keyof typeof ISO3166Alpha3 | undefined
): string | undefined => {
  switch (country) {
    case undefined:
      return "US";
    case ISO3166Alpha3.GBR:
      return "UK";
    default:
      return getTwoLetterCountryCode(country);
  }
};

export const localeKeyToFullLanguageName = (
  localeKey: ILocaleKey,
  includeCountry: boolean = true
) => {
  if (localeKey.language === undefined) {
    return "";
  }

  if (includeCountry) {
    const fullLanguageName = getFullLanguageName(localeKey.language);
    const countryLabel = getCountryLabel(localeKey.country);
    return `${fullLanguageName} (${countryLabel})`;
  }

  return getFullLanguageName(localeKey.language);
};
