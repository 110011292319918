import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { useSyncExternalStore } from "use-sync-external-store/shim";

import { isWindowUndefined } from "./isWindowUndefined";

let _isHydrating = getGlobalProperty("needsHydration", false);

/**
 * Return true during server-side rendering or client hydration.
 *
 * This returns true during client hydration because React requires that the result
 * of server-side rendering matches the initial render ("hydration") on the client.
 *
 * More information: https://www.joshwcomeau.com/react/the-perils-of-rehydration
 */
export const maybeSSR = () => isWindowUndefined() || _isHydrating;

export const handleWillHydrate = () => {
  _isHydrating = true;
};

let _hydarationCallbacks: Array<() => void> = [];

export const handleHydrationCompleted = () => {
  _isHydrating = false;
  for (const cb of _hydarationCallbacks) {
    cb();
  }
  _hydarationCallbacks = [];
};

/**
 * @deprecated replace with useIsSSR.
 */
export const onHydration = (cb: () => void) => {
  if (_isHydrating) {
    _hydarationCallbacks.push(cb);
  }
};

/**
 * Hook that returns true during SSR and hydration.
 */
export const useIsSSR = (): boolean => {
  return useSyncExternalStore(
    // The value never changes, no need to subscribe.
    () => () => {},
    // Called during client rendering
    maybeSSR,
    // During hydration, we need to match SSR.
    () => true
  );
};
