import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";
import Axios from "axios";

export const uploadImageFile = (imageFile: File) => {
  const formData = new FormData();
  formData.append("file", imageFile);
  return uploadImage(formData);
};

const uploadImage = (
  formData: FormData,
  onUploadProgress?: (progressEvent: uploadImage.IProgressEvent) => void
): Promise<IImage> => {
  const releaseVersion = getGlobalProperty("releaseVersion", "");

  return Axios.post("/api/image/upload", formData, {
    headers: {
      "content-type": "multipart/form-data",
      "X-IF-CLIENT-RELEASE": releaseVersion,
    },
    withCredentials: true,
    onUploadProgress,
  }).then((res) => res.data);
};

export namespace uploadImage {
  export interface IProgressEvent {
    loaded: number;
    total: number;
    type: "progress";
  }
}
