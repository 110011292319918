import { SettingConst } from '../../types';

/**
 * Set of regex paths that the recurring FD marketplace modal can be shown on.
 */
export const FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_ELIGIBLE_ROUTES =
  'FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_ELIGIBLE_ROUTES' as SettingConst<
    'FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_ELIGIBLE_ROUTES',
    Array<string>,
    'RETAILER'
  >;
