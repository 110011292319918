import { TRANSLATION_LOCALES } from "@faire/web--source/common/rsc/localization/TRANSLATION_LOCALES";
import type { MessageFormatElement } from "intl-messageformat-parser";
import type { IntlShape } from "react-intl";

export type Dictionary = Record<string, MessageFormatElement[]>;

export const parseDictionary = (content: string): Dictionary =>
  JSON.parse(content);

// ts-prune-ignore-next
export type LocaleDictionary = Record<
  (typeof TRANSLATION_LOCALES)[number],
  Dictionary
>;

// ts-prune-ignore-next
export const INITIAL_LOCALE_DICTIONARY: LocaleDictionary =
  TRANSLATION_LOCALES.reduce(
    (acc, locale) => ({
      ...acc,
      [locale]: {},
    }),
    {} as LocaleDictionary
  );

export type IntlMap = Record<(typeof TRANSLATION_LOCALES)[number], IntlShape>;

export const INITIAL_INTL_MAP: IntlMap = TRANSLATION_LOCALES.reduce(
  (acc, locale) => ({
    ...acc,
    [locale]: {},
  }),
  {} as IntlMap
);

/**
 * Matches the locale in the url to the name of the translation file
 */
export const getTranslationLocale = (locale: string) => {
  const determineLocale = (locale: string) => {
    const [lang, country] = locale.split("-");

    // Special case for English
    if (lang === "en") {
      if (country?.toUpperCase() === "GB") {
        return "en_GB";
      }
      return "en_US";
    }

    const langTranslationLocale = TRANSLATION_LOCALES.find((l) =>
      l.startsWith(lang!)
    );
    if (!country) {
      return langTranslationLocale;
    }

    return (
      TRANSLATION_LOCALES.find((l) =>
        l.startsWith(`${lang}_${country.toUpperCase()}`)
      ) ?? langTranslationLocale
    );
  };

  return determineLocale(locale) ?? determineLocale("en") ?? "en_US";
};
