"use client";

import { Core, Decorative } from "@faire/design-tokens";
import entries from "lodash/entries";
import isNil from "lodash/isNil";
import keys from "lodash/keys";
import pickBy from "lodash/pickBy";
import * as React from "react";

import Color, { ColorOrKey } from "../../slate/Color";
import {
  getWeight,
  TextTransform,
  TypographyStyleProps,
  Variants,
  WeightOrKey,
} from "../../slate/Font";
import {
  IFonts,
  IFontVariants,
  ITheme,
  themeOrDefault,
} from "../../slate/Theme";
import { getFontStyles } from "../../slate/Theme/fontUtil";
import { Align } from "../Align";
import { convertPropsToStyle, getValueFromBooleanProps } from "../lib";

export interface ITypography {
  // -------- Variants -----------
  /**
   * Use the h1 variant's tag, family, weight and line-height.
   */
  h1?: boolean;
  /**
   * Use the h2 variant's tag, family, weight and line-height.
   */
  h2?: boolean;
  /**
   * Use the h3 variant's tag, family, weight and line-height.
   */
  h3?: boolean;
  /**
   * Use the h4 variant's tag, family, weight and line-height.
   */
  h4?: boolean;
  /**
   * Use the h5 variant's tag, family, weight and line-height.
   */
  h5?: boolean;
  /**
   * Use the p variant's tag, family, weight and line-height.
   */
  p?: boolean;

  // --------- Colors ------------
  /**
   * Use the active theme's primary text color variant.
   */
  primary?: boolean;
  /**
   * Use the active theme's secondary text color variant.
   */
  secondary?: boolean;
  /**
   * Use the active theme's default text color variant.
   */
  default?: boolean;
  /**
   * Use the active theme's error text color variant.
   */
  error?: boolean;
  /**
   * Use a color from Faire's typography palette.
   */
  color?: ColorOrKey;

  // --------- Family ------------
  /**
   * Use the serif variant of the font.
   */
  serif?: boolean;
  /**
   * Use the sans-serif variant of the font (this is the default).
   */
  sansSerif?: boolean;
  /**
   * Weight name or value to use.
   */
  weight?: WeightOrKey;

  // --------- Alignment ------------
  left?: boolean;
  right?: boolean;
  center?: boolean;

  // --------- Style ----------------
  italic?: boolean;
  truncate?: boolean;
  small?: boolean;
  /**
   * Use "Title Casing" (each word's first letter is capitalized).
   * @deprecated do not capitalize text, this is language dependent
   */
  capitalize?: boolean;
  /**
   * Use "SCREAM CASING" (every letter is uppercased).
   * @deprecated do not uppercase text, this is language dependent
   */
  uppercase?: boolean;
  /**
   * Will truncate to n lines
   */
  maxLines?: number;
  /**
   * Shows a line through the text
   */
  strikethrough?: boolean;
}

export interface IStyleProps {
  color?: ColorOrKey;
  align?: string;
  textTransform?: string;
  variant?: keyof IFontVariants;
  family?: string;
  weight?: WeightOrKey;
  italic?: boolean;
  truncate?: boolean;
  maxLines?: number;
  strikethrough?: boolean;
}

const getStyle: {
  [key in keyof IStyleProps]: (key: string, props: any, theme: ITheme) => void;
} = {
  variant: (key, props, theme) => {
    return key
      ? getFontStyles(
          theme,
          key as keyof IFontVariants,
          props.weight,
          props.serif
        )
      : {};
  },
  color: (key, props) => {
    const colorMap = {
      default: Core.text.default,
      primary: Core.text.default,
      secondary: Decorative.shade.orange,
      error: Core.text.critical,
    };
    const color =
      colorMap[key as keyof typeof colorMap] ??
      colorMap[props[key] as keyof typeof colorMap] ??
      Color[props[key] as keyof typeof Color] ??
      props[key];
    return { color: color ?? Core.text.default };
  },
  align: (key) => ({ "text-align": Align[key as keyof typeof Align] }),
  // eslint-disable-next-line @faire/no-text-transform
  textTransform: (key) => ({
    "text-transform": TextTransform[key as keyof typeof TextTransform],
  }),
  italic: (_, props) => (props.italic ? { "font-style": "italic" } : {}),
  truncate: (_, props) =>
    props.truncate || props.maxLines === 1
      ? {
          "white-space": "nowrap",
          "text-overflow": "ellipsis",
          overflow: "hidden",
        }
      : {},
  maxLines: (_, props, theme) => {
    // 0 is invalid, so we expect the empty object in this case
    if ((props.maxLines || -1) <= 0) {
      return {};
    }
    const font: keyof IFonts = props.sansSerif ? "sansSerif" : "serif";
    const fontVariant: keyof IFontVariants =
      getValueFromBooleanProps<IStyleProps>(props, keys(Variants)) ?? "p";
    return {
      "-webkit-line-clamp": props.maxLines,
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      "max-height": `calc(${
        themeOrDefault(theme).fonts[font][fontVariant].lineHeight
      } * ${props.maxLines})`,
      "text-overflow": "ellipsis",
    };
  },
  weight: (key) => ({ "font-weight": getWeight(key as WeightOrKey) }),
  strikethrough: (_, props) =>
    props.strikethrough ? { "text-decoration": "line-through" } : {},
};

export const defaultProps: IStyleProps = {
  variant: "p",
  color: Core.text.default,
};

export const TypographyContext = React.createContext<ITypography>({});

/**
 * Produces the CSS for the given Typography variant, optionally filtered
 * to the given properties.
 * @deprecated use TypographyStyles from `"@faire/web--source/slate/Typography"`
 * @param typography Typography definition to use.
 * @param props Optional filter list for CSS properties to produce.
 * @example ```jsx
 * const StyledInput = styled.input`
 *   ${TypographyStyles(
 *     { p: true, sansSerif: true },
 *     ['font-family', 'font-size']
 *   )}
 * `;
 */
export function TypographyStyles(
  typography: ITypography,
  propsFilter?: Array<keyof TypographyStyleProps>
): string;

/**
 * Produces the CSS for the given Typography variant, in the given theme,
 * optionally filtered to the given properties.
 * Default theme is globally available, so this function should really only
 * be needed in testing.
 *
 * Note: Avoid using competing props, e.g. `color` & `error`, as the prop which will
 * be respected is dependent on the order in which they're provided, which isn't
 * intuitive/predictable.
 *
 * @deprecated use TypographyStyles from `"@faire/web--source/slate/Typography"`
 * @param typography Typography definition to use.
 * @param theme Theme definition to get the fonts from.
 * @param props Optional filter list for CSS properties to produce.
 * @example ```jsx
 * const StyledInput = styled.input`
 *   ${TypographyStyles(
 *     { p: true, sansSerif: true },
 *     ['font-family', 'font-size']
 *   )}
 * `;
 */
export function TypographyStyles(
  typography: ITypography,
  theme: ITheme,
  propsFilter?: Array<keyof TypographyStyleProps>
): string;

export function TypographyStyles(
  typography: ITypography,
  propsFilterOrTheme?: Array<keyof TypographyStyleProps> | ITheme,
  propsFilter?: Array<keyof TypographyStyleProps>
): string {
  const theme = themeOrDefault(propsFilterOrTheme as ITheme);
  const props =
    propsFilterOrTheme && Array.isArray(propsFilterOrTheme)
      ? (propsFilterOrTheme as Array<keyof TypographyStyleProps>)
      : propsFilter;
  const activeProps = getActiveProps(typography);
  const finalProps = {
    ...defaultProps,
    ...pickBy(activeProps, (value: any) => !isNil(value)),
  };
  const style = pickBy(
    convertPropsToStyle<IStyleProps, ITypography>(
      getStyle,
      finalProps,
      typography,
      theme
    ),
    (_, key: keyof TypographyStyleProps) => !props || props?.includes(key)
  );
  return entries(style)
    .map(([key, value]) => `${key}: ${value};`)
    .join("\n");
}

const getActiveProps = (props: ITypography): IStyleProps => ({
  variant: getValueFromBooleanProps<IStyleProps>(props, keys(Variants)),
  color: getValueFromBooleanProps<IStyleProps>(props, [
    "default",
    "primary",
    "secondary",
    "error",
    "color",
  ]),
  align: getValueFromBooleanProps<IStyleProps>(props, keys(Align)),
  // eslint-disable-next-line @faire/no-text-transform
  textTransform: getValueFromBooleanProps<IStyleProps>(
    props,
    keys(TextTransform)
  ),
  italic: props.italic,
  truncate: props.truncate,
  weight: props.weight,
  maxLines: props.maxLines,
  strikethrough: props.strikethrough,
});
