import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import qs from 'query-string';
import type { IRouteMetadata } from '../../../Route';

export type QueryParameters = {
  reorder: string | undefined;
  contactUs: string | undefined;
  highlightedCollection: string | undefined;
  exclusivity_agreement_details: string | undefined;
  modalType: string | undefined;
  sampleInvite: string | undefined;
  fdb: string | undefined;
  signUp: string | undefined;
  widgetToken: string | undefined;
  utm_source: string | undefined;
  utm_medium: string | undefined;
  scanned_at: string | undefined;
  refC: string | undefined;
  refSC: string | undefined;
  refS: string | undefined;
  refF: string | undefined;
  refOrigin: string | undefined;
  refP: string | undefined;
  refReqId: string | undefined;
  refType: string | undefined;
  sortingHint: string | undefined;
  impressionId: string | undefined;
  q: string | undefined;
  cat: string | undefined;
  sortBy: string | undefined;
  page: string | undefined;
  filters: string | undefined;
  product: string | undefined;
  scrollToTop: string | undefined;
  scrollToGrid: string | undefined;
  collections: string | undefined;
  trackEventToken: string | undefined;
  story: string | undefined;
  requestSamples: string | undefined;
  fc: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;

  /**
   * Parse the given raw query string or partially-parsed react-router-dom queryParams object.
   */
  export function parse(
    search: string | { [K in keyof QueryParameters]?: string } = ''
  ): QueryParameters {
    const rawParsed =
      typeof search === 'string' ? qs.parse(search) : (search ?? {});
    const parsed: any = { ...rawParsed };

    return parsed as QueryParameters;
  }
}

/**
 * Generated from Jersey path "/brand/{brandToken:([^/])*}"
 *
 * Brand's page (store) in the retailer portal
 * Product Area: BRAND_PAGE
 */
export const route = '/brand/:brandToken([^/]*)';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/brand/{brandToken:([^/])*}',
  productArea: 'BRAND_PAGE',
  pod: 'FAIRE_POD_EVALUATE',
  pillar: 'FAIRE_PILLAR_DISCOVERY',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

/**
 * Brand's page (store) in the retailer portal
 */
export function path(
  brandToken: string,
  queryParams?: Partial<QueryParameters>
) {
  const compiledPath = toPath({
    brandToken,
  });
  const queryString = queryParams ? qs.stringify(queryParams) : '';
  return `${compiledPath}${queryString ? `?${queryString}` : ''}`;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (
  brandToken: string,
  queryParams?: Partial<QueryParameters>
): string => {
  return faireBaseUrl(3000) + path(brandToken, queryParams);
};

export type PathParameters = {
  brandToken: string;
};

export default path;
