export namespace IRetailerPosDisabledSettings {
  export enum PosDisabledSettingReason {
    POS_DISABLED_SETTING_REASON_UNKNOWN = 'POS_DISABLED_SETTING_REASON_UNKNOWN',
    MISSING_CAPABILITIES = 'MISSING_CAPABILITIES',
    DELETED_LOCATION = 'DELETED_LOCATION',
  }

  /**
   * Return an instance of IRetailerPosDisabledSettings with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IRetailerPosDisabledSettings>
  ): IRetailerPosDisabledSettings =>
    ({
      ...(partial ?? {}),
    }) as IRetailerPosDisabledSettings;
}

export interface IRetailerPosDisabledSettings {
  update_inventory:
    | keyof typeof IRetailerPosDisabledSettings.PosDisabledSettingReason
    | undefined;
  /**
   * @deprecated
   */
  product_publishing:
    | keyof typeof IRetailerPosDisabledSettings.PosDisabledSettingReason
    | undefined;
}
