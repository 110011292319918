"use client";

// This must be first, because it sets up the global lookup.
// eslint-disable-next-line import/no-unassigned-import
import "./initialization/clientComponentSSR";

import { IntlProvider } from "@faire/web--source/common/localization/IntlProvider";
import { LocalizationProvider } from "@faire/web--source/common/localization/useLocalization";
import { NoSSR } from "@faire/web--source/common/NoSSR";
import { QueryClientProvider } from "@faire/web--source/common/reactQuery";
import { DefaultTheme } from "@faire/web--source/slate/Theme";
import { ThemeProvider } from "@faire/web--source/slate/Theme/ThemeProvider";
import { ObservableWindowStoreProvider } from "@faire/web--source/ui/hooks/useObservableWindow";
import { MemoryHistory } from "history";
import React, { useMemo } from "react";

import { useMemoryHistory } from "@faire/retailer/app/_lib/routing/useMemoryHistory";
import { AppEffects } from "@faire/retailer/components/App/AppEffects";
import { DevTools } from "@faire/retailer/components/App/DevTools";
import { HCaptcha } from "@faire/retailer/components/AppSignIn/HCaptcha";
import { FirstOrderIncentiveFabWrapper } from "@faire/retailer/components/FirstOrderIncentiveFab/FirstOrderIncentiveFabWrapper";
import { AddedToBagContextStoreProvider } from "@faire/retailer/components/Notifications/AddedToBag/AddedToBagStore";
import { RetailerRoutingContextProvider } from "@faire/retailer/RetailerRoutingContextProvider";
import { getSettingsValues } from "@faire/retailer/serialized-data/getSettingsValues";
import { ReactQueryClientStoreProvider } from "@faire/retailer/services/ReactQueryClient";
// eslint-disable-next-line @faire/enforce-internal-folder-encapsulation
import { SettingsContextProvider } from "@faire/retailer/settings/__internal__/useSetting";
import { HCaptchaStoreProvider } from "@faire/retailer/stores/domain/HCaptchaStore";
import { OpeningOrderIncentivesProvider } from "@faire/retailer/stores/domain/OpeningOrderIncentivesProvider";
import { SSOContextStoreProvider } from "@faire/retailer/stores/domain/SSO/SSOContextStore";
import { AppSnackbarProvider } from "@faire/retailer/stores/ui/AppSnackbarStore";
import { FreeShippingContextStoreProvider } from "@faire/retailer/stores/ui/FreeShippingContextStore";
import { PromoModalContextStoreProvider } from "@faire/retailer/stores/ui/PromoModalContextStore";
import { TopOfPageContextStoreProvider } from "@faire/retailer/stores/ui/TopOfPageContextStore";

import { Globals } from "./Globals";
import { AppInitializer } from "./initialization/AppInitializer";
import { ReactQueryDevtools } from "./ReactQueryDevtools";
import { ReactScanMonitoring } from "./ReactScanMonitoring";
import { NextRouterContextProvider } from "./routing/NextRouterContext";
import { ReactRouterCompat } from "./routing/ReactRouterCompat";
import { ZustandLocationProvider } from "./routing/useZustandLocation";
import { AppDownloadWrapper } from "./wrappers/AppDownloadWrapper";

type PublicApiKeys = {
  REACT_SCAN_MONITORING_API_KEY: string | undefined;
};

type BaseProps = {
  children: React.ReactNode;
  memoryHistory: MemoryHistory;
  publicApiKeys: PublicApiKeys;
};

const BaseProviders = ({
  children,
  memoryHistory,
  publicApiKeys,
}: BaseProps) => {
  const settings = useMemo(() => getSettingsValues(), []);
  return (
    <SettingsContextProvider settings={settings}>
      <NextRouterContextProvider>
        <LocalizationProvider>
          <RetailerRoutingContextProvider>
            <ReactRouterCompat memoryHistory={memoryHistory}>
              <ReactScanMonitoring
                apiKey={publicApiKeys.REACT_SCAN_MONITORING_API_KEY}
              />
              <ZustandLocationProvider />
              <ObservableWindowStoreProvider>
                <ThemeProvider theme={DefaultTheme}>
                  <QueryClientProvider>
                    <ReactQueryClientStoreProvider>
                      <FreeShippingContextStoreProvider>
                        <TopOfPageContextStoreProvider>
                          <HCaptchaStoreProvider>
                            <AddedToBagContextStoreProvider>
                              <AppSnackbarProvider>
                                <IntlProvider>
                                  <SSOContextStoreProvider>
                                    <PromoModalContextStoreProvider>
                                      <OpeningOrderIncentivesProvider>
                                        <AppEffects />
                                        {children}
                                        <NoSSR>
                                          <HCaptcha />
                                        </NoSSR>
                                        <AppDownloadWrapper />
                                        <FirstOrderIncentiveFabWrapper />
                                        <DevTools />
                                      </OpeningOrderIncentivesProvider>
                                    </PromoModalContextStoreProvider>
                                  </SSOContextStoreProvider>
                                </IntlProvider>
                              </AppSnackbarProvider>
                            </AddedToBagContextStoreProvider>
                          </HCaptchaStoreProvider>
                        </TopOfPageContextStoreProvider>
                      </FreeShippingContextStoreProvider>
                    </ReactQueryClientStoreProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </QueryClientProvider>
                </ThemeProvider>
              </ObservableWindowStoreProvider>
            </ReactRouterCompat>
          </RetailerRoutingContextProvider>
        </LocalizationProvider>
      </NextRouterContextProvider>
    </SettingsContextProvider>
  );
};

type Props = {
  children: React.ReactNode;
  userAgent: string | null;
  referer: string | null;
  globals: Record<string, unknown>;
  originalUrl: string | undefined;
  publicApiKeys: PublicApiKeys;
};

export const Providers = ({
  children,
  userAgent,
  referer,
  globals,
  originalUrl,
  publicApiKeys,
}: Props) => {
  const memoryHistory = useMemoryHistory();
  return (
    <>
      <AppInitializer history={memoryHistory} />
      <Globals
        globals={globals}
        userAgent={userAgent}
        referer={referer}
        memoryHistory={memoryHistory}
        originalUrl={originalUrl}
      >
        <BaseProviders
          memoryHistory={memoryHistory}
          publicApiKeys={publicApiKeys}
        >
          {children}
        </BaseProviders>
      </Globals>
    </>
  );
};
