export namespace IFaireMoney {
  /**
   * Return an instance of IFaireMoney with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IFaireMoney>): IFaireMoney =>
    ({
      ...(partial ?? {}),
    }) as IFaireMoney;
}

export interface IFaireMoney {
  /**
   * The amount of money in the smallest unit of the applicable currency. For example, US dollars is in cents.
   */
  amount_cents: number | undefined;
  /**
   * The type of currency involved in the current payment in ISO 4217 format. For example, US dollars is USD.
   */
  currency: string | undefined;
  /**
   * If present, this is the original amountCents of the money before converting, indicating an estimate.
   */
  original_amount_cents: number | undefined;
  /**
   * If present, this is the original currency of the money before converting.
   */
  original_currency: string | undefined;
}
