import React, { type JSX } from "react";

import { Bold, Medium, Book, Thin, Light } from "../../Typography/Span";

// TODO(paul, Q3): Dynamically generate eslint exceptions from this list.
export const chunkTransformations = {
  Bold: (chunks: JSX.Element) => <Bold>{chunks}</Bold>,
  Medium: (chunks: JSX.Element) => <Medium>{chunks}</Medium>,
  Book: (chunks: JSX.Element) => <Book>{chunks}</Book>,
  Thin: (chunks: JSX.Element) => <Thin>{chunks}</Thin>,
  Light: (chunks: JSX.Element) => <Light>{chunks}</Light>,
  b: (chunks: JSX.Element) => <b>{chunks}</b>,
  u: (chunks: JSX.Element) => <u>{chunks}</u>,
  i: (chunks: JSX.Element) => <i>{chunks}</i>,
  nbsp: "\xa0",
  strong: (chunks: JSX.Element) => <strong>{chunks}</strong>,
  lineBreak: <br />,
} as const;

export interface INoTranslateProps {
  children: React.ReactNode;
}

/**
 * A span which can be wrapped around text content to inform Google Translate
 * that the content within the span should not be translated. This is useful to
 * wrap around things like brand names that should be left unmodified even when
 * the user is viewing a page through Google Translate.
 * @link [Docs](https://web-docs.faire.team/component/NoTranslate)
 */
export const NoTranslate: React.FC<INoTranslateProps> = ({ children }) => (
  <span className="notranslate">{children}</span>
);
