import { SettingConst } from '../../types';

/**
 * Controls whether or not we fetch data from the server on the logged out search page
 */
export const NEXTJS_RSC_LOGGED_OUT_SEARCH_PAGE_240514 =
  'NEXTJS_RSC_LOGGED_OUT_SEARCH_PAGE_240514' as SettingConst<
    'NEXTJS_RSC_LOGGED_OUT_SEARCH_PAGE_240514',
    boolean,
    'RETAILER' | 'RETAILER_HEAD_TAGS'
  >;
