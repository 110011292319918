import { logError } from "./logging";

export function exhaustiveSwitchWithDefault<
  Unhandled extends string,
  DefaultValue = undefined
>(
  defaultValue: DefaultValue,
  unhandled: { [K in Unhandled]: `TODO(${string}): Handle this case` }
): (value: Unhandled) => DefaultValue;

/**
 *
 * @ example
 *  switch(foo) {
 *    ...
 *    default:
 *      return exhaustiveSwitchWithDefault(null)(foo);
 *  }
 */
export function exhaustiveSwitchWithDefault<DefaultValue = undefined>(
  defaultValue?: DefaultValue | undefined
): (value: never) => DefaultValue;

export function exhaustiveSwitchWithDefault<
  Unhandled extends string = never,
  DefaultValue = undefined
>(
  defaultValue?: DefaultValue | undefined,
  _unhandled?: Unhandled extends string ? { [K in Unhandled]: K } : never
) {
  return (switchValue: Unhandled | never): DefaultValue => {
    logError(new Error("Received unexpected value inside exhaustive switch"), {
      data: {
        defaultValue,
        unexpectedValue: switchValue,
      },
    });
    return defaultValue as never;
  };
}
