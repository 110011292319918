export enum RetailerOnboardingProgress {
  RETAILER_ONBOARDING_PROGRESS_UNKNOWN = 'RETAILER_ONBOARDING_PROGRESS_UNKNOWN',
  /**
   * Use NOT_STARTED
   * @deprecated
   */
  ONBOARDING_NOT_STARTED = 'ONBOARDING_NOT_STARTED',
  INTRODUCTION_TO_POS_INTEGRATION = 'INTRODUCTION_TO_POS_INTEGRATION',
  START_MATCHING_ITEMS = 'START_MATCHING_ITEMS',
  CONFIGURE_SETTINGS = 'CONFIGURE_SETTINGS',
  /**
   * Use FINISHED
   * @deprecated
   */
  ONBOARDING_FINISHED = 'ONBOARDING_FINISHED',
  NOT_STARTED = 'NOT_STARTED',
  FINISHED = 'FINISHED',
  /**
   * States for the new V2 onboarding flow
   * Retailer is deciding on initial settings and configuration for the POS integration
   */
  V2_CONFIGURE_SETTINGS = 'V2_CONFIGURE_SETTINGS',
  /**
   * Connection to the retailer's POS is being established (oAuth, app installation if applicable)
   */
  V2_LINK_POS = 'V2_LINK_POS',
  /**
   * Initial data is being pulled from the linked POS to our database
   * and retailer's data is being transferred from BE Monolith into shop-integration service
   */
  V2_SETTING_UP = 'V2_SETTING_UP',
  /**
   * Retailer is configuring which location in their POS to link to
   */
  V2_SET_LOCATION = 'V2_SET_LOCATION',
  /**
   * Onboarding is fully complete, integration can be used with full functionality
   */
  V2_FINISHED = 'V2_FINISHED',
}
