import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";

import { logError } from "../logging";

import { localeKeyToFullLanguageName } from "./localeKeyToFullLanguageName";

export interface ILocaleOption {
  label: string;
  value: ILocaleKey;
}
/** indicates the language of this locale is duplicated in the locale list */
interface ILocaleKeyWithDuplicateFlag extends ILocaleKey {
  duplicate?: boolean;
}

/**
 * Given a list of ILocaleKey, returns true if a duplicate language exists in the list.
 * @param locales list of ILocaleKey to check for duplicate language
 * @returns true if there are one or more duplicate languages in the list
 */
export const hasDuplicateLanguages = (locales: ILocaleKey[]): boolean => {
  const languagesSeen = {};

  for (const localeKey of locales) {
    if (localeKey.language) {
      if (localeKey.language in languagesSeen) {
        return true;
      } else {
        // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
        languagesSeen[localeKey.language] = 1;
      }
    }
  }

  return false;
};

/**
 * Returns true if the language occurs more than once in the list of locales
 * @param language the language to check for duplicates
 * @param locales the array of locales to check in
 * @returns true if the language occurs more than once
 */
export const isLanguageDuplicatedInLocales = (
  language: Language,
  locales: ILocaleKey[]
): boolean => {
  let timesSeen = 0;

  for (const localeKey of locales) {
    if (localeKey.language === language && timesSeen > 0) {
      return true;
    }

    if (localeKey.language === language) {
      timesSeen += 1;
    }
  }

  return false;
};

const markDuplicateLocaleKeyLanguage = (
  availableLocales: ILocaleKey[]
): ILocaleKeyWithDuplicateFlag[] => {
  const languagesSeen = {};

  for (const localeKey of availableLocales) {
    if (localeKey.language) {
      if (localeKey.language in languagesSeen) {
        // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
        languagesSeen[localeKey.language] += 1;
      } else {
        // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
        languagesSeen[localeKey.language] = 1;
      }
    }
  }

  return availableLocales
    .filter((localeKey) => !!localeKey.language)
    .map((localeKey) => {
      // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      if (languagesSeen[localeKey.language!] > 1) {
        return {
          ...localeKey,
          duplicate: true,
        };
      }

      return localeKey;
    });
};

/**
 * Returns ILocaleOption list for avaiilable locales. Shows full locale for duplicate languages.
 * E.g. "English (US) - EN-US" and "English (UK) - EN-GB".
 * @param availableLocales list of locale keys
 * @returns list of ILocaleOption with string label and value
 */
export const languageOptionsWithDisambiguatedLocale = (
  availableLocales: ILocaleKey[]
): ILocaleOption[] => {
  const availableLocalesWithDuplicatesMarked =
    markDuplicateLocaleKeyLanguage(availableLocales);

  return availableLocalesWithDuplicatesMarked.map(
    (localeKey: ILocaleKeyWithDuplicateFlag) => {
      if (localeKey.language === undefined) {
        logError(
          new Error("Cannot create language option from undefined language.")
        );
        return {
          label: "Unknown Language",
          value: {
            language: Language.LANGUAGE_UNKNOWN,
            country: undefined,
          },
        };
      }

      return {
        label: `${localeKeyToFullLanguageName(
          localeKey,
          !!localeKey.duplicate
        )}`,
        value: localeKey,
      };
    }
  );
};
