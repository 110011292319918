import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { QueryParameters as SearchQueryParameters } from "@faire/web-api--source/routes/search";

import { isLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { getSettingRscLoggedInSearchPage } from "@faire/retailer/settings/getSettingRscLoggedInSearchPage";

export const shouldShowRscLoggedInSearchPage = () => {
  const search = getLocationOrThrow().search;
  const rscParam = (
    SearchQueryParameters.parse(search) as Record<string, string | undefined>
  ).rsc;
  return getSettingRscLoggedInSearchPage(rscParam) && isLoggedInRetailer();
};
