import { isWindowUndefined } from "@faire/web--source/common/server-side-rendering/isWindowUndefined";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import EventEmitter from "eventemitter3";

interface PartialEventEmitter {
  on(event: string, fn: EventEmitter.ListenerFn): unknown;
  off(event: string, fn: EventEmitter.ListenerFn): unknown;
  removeListener(event: string, fn: EventEmitter.ListenerFn): unknown;
  emit: (event: string, data: unknown) => unknown;
  removeAllListeners: () => void;
}

/**
 * @deprecated prefer to use `useAppEventsStore` or `withStores` instead
 */
export class AppEventsStore {
  static get = singletonGetter(AppEventsStore);

  constructor() {
    if (isWindowUndefined()) {
      this.appEvents = {
        on: () => {},
        off: () => {},
        emit: () => {},
        removeListener: () => {},
        removeAllListeners: () => {},
      };
    } else {
      this.appEvents = new EventEmitter();
    }
  }

  appEvents: PartialEventEmitter;
}
// export const useAppEventsStore = createStoreHook(AppEventsStore);
