import { request } from '../../../../../..';
import { ISearchBrandProductsFiltersRequest } from '../../../../../../indigofair/data/ISearchBrandProductsFiltersRequest';
import { ISearchBrandProductsFiltersResponse } from '../../../../../../indigofair/data/ISearchBrandProductsFiltersResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../../../types';

export const path = () => {
  return `/api/v2/search/products/from-brand/filters`;
};

export const build = (
  data: ISearchBrandProductsFiltersRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    trackClientSideLatency: true,
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/search/products/from-brand/filters';

/**
 * Product Area: BRAND_PAGE
 */
export function execute(
  data: ISearchBrandProductsFiltersRequest
): Promise<ISearchBrandProductsFiltersResponse>;
export function execute(
  data: ISearchBrandProductsFiltersRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ISearchBrandProductsFiltersResponse>>;
export function execute(
  data: ISearchBrandProductsFiltersRequest,
  options?: EndpointOptions
): Promise<ISearchBrandProductsFiltersResponse>;
export function execute(
  data: ISearchBrandProductsFiltersRequest,
  options?: EndpointOptions
): Promise<
  | ISearchBrandProductsFiltersResponse
  | SuccessResponse<ISearchBrandProductsFiltersResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<ISearchBrandProductsFiltersResponse>(requestOptions);
}
execute[PATH] = 'api/v2/search/products/from-brand/filters/post';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  data: ISearchBrandProductsFiltersRequest
): Promise<ISearchBrandProductsFiltersResponse>;
export function darkRead(
  data: ISearchBrandProductsFiltersRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ISearchBrandProductsFiltersResponse>>;
export function darkRead(
  data: ISearchBrandProductsFiltersRequest,
  options?: EndpointOptions
): Promise<ISearchBrandProductsFiltersResponse>;
export function darkRead(
  data: ISearchBrandProductsFiltersRequest,
  options?: EndpointOptions
): Promise<
  | ISearchBrandProductsFiltersResponse
  | SuccessResponse<ISearchBrandProductsFiltersResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<ISearchBrandProductsFiltersResponse>(requestOptions);
}
