import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey =
  'web_retailer_server-retailer_edge_cache_experiment-action_unknown';

/**
 * Event used to test whether we can do experiment bucketing correctly with Cloudflare cache layer
 */
export function trackWebRetailerServerRetailerEdgeCacheExperiment(
  expected: string,
  actual2: string,
  actualCookie3: string,
  actualEndpoint4: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: true,
    parameters: { expected, actual2, actualCookie3, actualEndpoint4 },
  });
}
