import { QueryParams } from "@faire/web--source/common/query-params/QueryParams";
import { Storage } from "@faire/web--source/common/Storage";
import addDays from "date-fns/addDays";
import History from "history";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useMemo, useState } from "react";
import { matchPath } from "react-router-dom";

import { isMobileApp } from "@faire/retailer/lib/isMobileApp";
import { getOsName } from "@faire/retailer/lib/user-agent/osName";
import {
  getIsProduction,
  getSafeEnvName,
} from "@faire/retailer/serialized-data/getEnvName";
import { getFaireBaseUrl } from "@faire/retailer/serialized-data/getFaireBaseUrl";
import { getIsAppInstalled } from "@faire/retailer/serialized-data/getIsAppInstalled";
import { useUserStore } from "@faire/retailer/stores/domain/UserStore";

import { useSettingAppPromotionBannerLinks } from "../settings/useSettingAppPromotionBannerLinks";

export const BANNER_FLAG_TIMESTAMP =
  "retailer_mobile-app-banner-flag-timestamp";
const DISMISS_INTERVAL_DAYS = 1;

export enum OSType {
  IOS = "iOS",
  ANDROID = "Android",
}

export const useMobileAppBannerService = () => {
  const { userToken } = useUserStore(["userToken"]);
  const [showBanner, setShowBanner] = useState(false);
  const storeLinks = useSettingAppPromotionBannerLinks();
  const storeLinkUrl = useMemo(() => {
    if (getOsName() === OSType.IOS) {
      return storeLinks.app_store_link;
    } else if (getOsName() === OSType.ANDROID) {
      return storeLinks.play_store_link;
    }
    return undefined;
  }, [storeLinks.app_store_link, storeLinks.play_store_link]);
  const deepLinkUrl = useMemo(() => {
    if (getOsName() === OSType.IOS) {
      return storeLinks.ios_deep_link;
    } else if (getOsName() === OSType.ANDROID) {
      return storeLinks.android_deep_link;
    }
    return undefined;
  }, [storeLinks.android_deep_link, storeLinks.ios_deep_link]);
  const bannerLinkUrl = useMemo((): string | undefined => {
    const linkUrl = (!!getIsAppInstalled() && deepLinkUrl) || storeLinkUrl;

    return linkUrl;
  }, [deepLinkUrl, storeLinkUrl]);
  const shouldShowBanner =
    !isMobileApp() && !isEmpty(bannerLinkUrl) && !!userToken;

  useEffect(() => {
    setShowBanner(shouldShowBanner);
  }, [shouldShowBanner]);

  const dismissBanner = useCallback(() => {
    Storage.local.setItem(
      BANNER_FLAG_TIMESTAMP,
      addDays(new Date(), DISMISS_INTERVAL_DAYS).getTime().toString()
    );
    setShowBanner(false);
  }, []);

  const updateBannerAvailibility = useCallback(() => {
    setShowBanner(shouldShowBanner);
  }, [shouldShowBanner]);

  return {
    showBanner,
    dismissBanner,
    updateBannerAvailibility,
  };
};

export const getDeepLinkUrl = (
  location: History.Location | Location,
  queryParams?: QueryParams
) => {
  const onSearchPage = !!matchPath(location.pathname, {
    path: "/search",
    exact: true,
  });

  let pathName;
  if (onSearchPage) {
    pathName = `${location.pathname}?q=${queryParams?.getOrDefault("q", "")}`;
  } else {
    pathName = location.pathname;
  }

  return `https://faireretailer.page.link/?link=${`https://www.${getFaireBaseUrl()}`}${pathName}&efr=1&apn=${
    getIsProduction() ? "com.faire.retailer" : "com.faire.retailer.internal"
  }&isi=1413340491&ibi=${`com.indigofair.${getSafeEnvName()}`}`;
};
