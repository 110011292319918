import {
  StrictLocalizeFunction,
  useStrictLocalization,
} from "@faire/web--source/common/localization";
import React, { NamedExoticComponent, RefAttributes } from "react";

import { SVGIcon } from "./SVGIcon";

export type SVGIconComponent = NamedExoticComponent<
  SVGIcon.IProps & RefAttributes<SVGElement>
>;

// NOTE: This needs to stay a function component otherwise downstream icon tooling will not be able
// to find icon nodes via ts-morph since it expects a function node. (We can't switch to variable node)

export function createSVGIcon(
  path: React.ReactNode,
  displayName: string,
  defaultProps?:
    | ((strictLocalize: StrictLocalizeFunction) => SVGIcon.IProps)
    | SVGIcon.IProps
): SVGIconComponent {
  const Component = React.memo(
    React.forwardRef<SVGElement, SVGIcon.IProps>((props, ref) => {
      const { strictLocalize } = useStrictLocalization();
      const unwrappedDefaultProps =
        typeof defaultProps === "function"
          ? defaultProps?.(strictLocalize)
          : defaultProps;

      const { className: defaultClass, titleAccess } =
        unwrappedDefaultProps ?? {};
      const { className: explicitClass } = props ?? {};
      const curriedClass = [defaultClass, explicitClass]
        .filter((c) => !!c)
        .join(" ");

      return (
        <SVGIcon
          ref={ref}
          titleAccess={titleAccess}
          {...unwrappedDefaultProps}
          {...props}
          className={curriedClass}
        >
          {path}
        </SVGIcon>
      );
    })
  );

  if (process.env.NODE_ENV !== "production") {
    Component.displayName = `${displayName}Icon`;
  }

  return Component;
}
