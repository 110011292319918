import { StrictLocalizeFunction } from "@faire/web--source/common/localization";

export const FALLBACK_INTERVAL = 30 * 1000;

export const FORMAT_DATE_HOUR_MINUTE = "MM/DD h:mm A";
export const FORMAT_HOUR_MINUTE = "h:mm A";
export const FORMAT_LONG_DATE = "dddd, MMMM D";

export const SYNTHETIC_BRAND_VACATION_TOKEN = "mm_brand_vacation";
export const SYNTHETIC_SUPPORT_TOKEN = "mm_support_text";
export const SYNTHETIC_OTHER_PARTY_STATUS_TOKEN = "mm_other_party_status";

export const SYNTHETIC_MESSAGE_TOKENS = [
  SYNTHETIC_BRAND_VACATION_TOKEN,
  SYNTHETIC_SUPPORT_TOKEN,
  SYNTHETIC_OTHER_PARTY_STATUS_TOKEN,
];

export const SYNTHETIC_SUPPORT_MESSAGE = (
  strictLocalize: StrictLocalizeFunction
) =>
  strictLocalize({
    defaultMessage:
      "Have a question about Faire? We're online from 10am-3pm PST, Monday through Friday.",
  });

export const MAX_IMAGE_SIZE = 10 * 1024 * 1024;

export const fadeColor = (hexColor: string, opacityValue: number) => {
  const opacityHexValue = Math.round(opacityValue * 255).toString(16);
  return `${hexColor}${opacityHexValue}`;
};
