import type { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";

import { THREE_LETTER_EU_COUNTRY_CODES } from "./THREE_LETTER_EU_COUNTRY_CODES";

export const THREE_LETTER_EUROPE_COUNTRY_CODES: {
  [K in Extract<
    keyof typeof ISO3166Alpha3,
    | keyof typeof THREE_LETTER_EU_COUNTRY_CODES
    | "ALA"
    | "ALB"
    | "AND"
    | "ARM"
    | "AZE"
    | "BIH"
    | "BLR"
    | "CHE"
    | "FRO"
    | "GBR"
    | "GEO"
    | "GGY"
    | "GIB"
    | "IMN"
    | "ISL"
    | "JEY"
    | "KAZ"
    | "LIE"
    | "MCO"
    | "MDA"
    | "MKD"
    | "MNE"
    | "NOR"
    | "RUS"
    | "SJM"
    | "SMR"
    | "SRB"
    | "TUR"
    | "UKR"
    | "VAT"
    | "XKX"
  >]: K;
} = {
  ...THREE_LETTER_EU_COUNTRY_CODES,
  ALA: "ALA",
  ALB: "ALB",
  AND: "AND",
  ARM: "ARM",
  AZE: "AZE",
  BIH: "BIH",
  BLR: "BLR",
  CHE: "CHE",
  FRO: "FRO",
  GBR: "GBR",
  GEO: "GEO",
  GGY: "GGY",
  GIB: "GIB",
  IMN: "IMN",
  ISL: "ISL",
  JEY: "JEY",
  KAZ: "KAZ",
  LIE: "LIE",
  MCO: "MCO",
  MDA: "MDA",
  MKD: "MKD",
  MNE: "MNE",
  NOR: "NOR",
  RUS: "RUS",
  SJM: "SJM",
  SMR: "SMR",
  SRB: "SRB",
  TUR: "TUR",
  UKR: "UKR",
  VAT: "VAT",
  XKX: "XKX",
};
