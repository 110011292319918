import { request } from '../../../..';
import { IFreeShippingStatusRequest } from '../../../../indigofair/data/IFreeShippingStatusRequest';
import { IFreeShippingStatusResponse } from '../../../../indigofair/data/IFreeShippingStatusResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../types';

export const path = (retailerToken: string) => {
  return `/api/retailer/${retailerToken}/free-shipping-status`;
};

export const build = (
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(retailerToken),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/retailer/:retailer_token/free-shipping-status';

/**
 * Product Area: SHIPPING
 */
export function execute(
  retailerToken: string,
  data: IFreeShippingStatusRequest
): Promise<IFreeShippingStatusResponse>;
export function execute(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IFreeShippingStatusResponse>>;
export function execute(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  options?: EndpointOptions
): Promise<IFreeShippingStatusResponse>;
export function execute(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  options?: EndpointOptions
): Promise<
  IFreeShippingStatusResponse | SuccessResponse<IFreeShippingStatusResponse>
> {
  const { route, ...requestOptions } = build(retailerToken, data, options);
  return request<IFreeShippingStatusResponse>(requestOptions);
}
execute[PATH] = 'api/retailer/retailerToken/free-shipping-status/post';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  retailerToken: string,
  data: IFreeShippingStatusRequest
): Promise<IFreeShippingStatusResponse>;
export function darkRead(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IFreeShippingStatusResponse>>;
export function darkRead(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  options?: EndpointOptions
): Promise<IFreeShippingStatusResponse>;
export function darkRead(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  options?: EndpointOptions
): Promise<
  IFreeShippingStatusResponse | SuccessResponse<IFreeShippingStatusResponse>
> {
  const { route, ...requestOptions } = build(retailerToken, data, options);
  return request<IFreeShippingStatusResponse>(requestOptions);
}
