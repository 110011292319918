export enum RetailerUserNotificationGroup {
  RETAILER_USER_NOTIFICATION_GROUP_UNKNOWN = 'RETAILER_USER_NOTIFICATION_GROUP_UNKNOWN',
  TEAM_COMMUNICATION = 'TEAM_COMMUNICATION',
  R2B_NOTIFICATION = 'R2B_NOTIFICATION',
  TEAM_UPDATES_NOTIFICATION = 'TEAM_UPDATES_NOTIFICATION',
  IDV_MESSAGES_NOTIFICATION = 'IDV_MESSAGES_NOTIFICATION',
  POS_SYNC_ISSUE_NOTIFICATION = 'POS_SYNC_ISSUE_NOTIFICATION',
  NET_60_APPLICATION_NOTIFICATION = 'NET_60_APPLICATION_NOTIFICATION',
  INSIDER_MEMBERSHIP_PAYMENT_NOTIFICATION = 'INSIDER_MEMBERSHIP_PAYMENT_NOTIFICATION',
  OPEN_WITH_FAIRE_NOTIFICATION = 'OPEN_WITH_FAIRE_NOTIFICATION',
  ORDER_ISSUES_NOTIFICATION = 'ORDER_ISSUES_NOTIFICATION',
  PAYMENT_NOTIFICATION = 'PAYMENT_NOTIFICATION',
  BUSINESS_IDENTIFIERS_NOTIFICATION = 'BUSINESS_IDENTIFIERS_NOTIFICATION',
  SHIPPING_COST_NOTIFICATION = 'SHIPPING_COST_NOTIFICATION',
  NET_TERMS_NOTIFICATION = 'NET_TERMS_NOTIFICATION',
  EXCLUSIVITY_NOTIFICATION = 'EXCLUSIVITY_NOTIFICATION',
}
