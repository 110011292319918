"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const Tidepool: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_SUMMER_MARKET_2022]
      .decorative4;
  return (
    <SVGIcon
      width="194"
      height="194"
      viewBox="0 0 194 194"
      fill="none"
      {...rest}
    >
      <path
        d="M32.2915 129.291C50.1255 129.291 64.5829 114.803 64.5829 96.9686C64.5829 79.0999 79.0999 64.5829 96.9686 64.5829C114.803 64.5829 129.291 50.1255 129.291 32.2915C129.291 14.4574 143.749 0 161.583 0H194V194H0V161.583C0 143.749 14.4574 129.291 32.2915 129.291Z"
        fill={iconColor}
      />
    </SVGIcon>
  );
};
