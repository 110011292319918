import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { QueryParameters as SearchQueryParameters } from "@faire/web-api--source/routes/search";

import { isLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { getSettingRscLibp } from "@faire/retailer/settings/getSettingRscLibp";

export const shouldShowRscLibp = () => {
  const search = getLocationOrThrow().search;
  const rscParam = (
    SearchQueryParameters.parse(search) as Record<string, string | undefined>
  ).rsc;
  return getSettingRscLibp(rscParam) && isLoggedInRetailer();
};
