export enum PromoModalType {
  PROMO_MODAL_TYPE_UNKNOWN = 'PROMO_MODAL_TYPE_UNKNOWN',
  /**
   * @deprecated
   */
  ELEVATE_PROMO = 'ELEVATE_PROMO',
  /**
   * @deprecated
   */
  INSIDER_PROMO = 'INSIDER_PROMO',
  STYLE_QUIZ_PROMO = 'STYLE_QUIZ_PROMO',
  NONE = 'NONE',
  /**
   * @deprecated
   */
  NET90_PROMO = 'NET90_PROMO',
  GREAT_APP_MIGRATION = 'GREAT_APP_MIGRATION',
  /**
   * @deprecated
   */
  COVID_19_MODAL = 'COVID_19_MODAL',
  /**
   * Neighborhood shutting down as of Oct 2020, so we are no longer promoting it
   * @deprecated
   */
  NEIGHBORHOOD_PROMO = 'NEIGHBORHOOD_PROMO',
  CARES_ASSISTANCE_MODAL = 'CARES_ASSISTANCE_MODAL',
  /**
   * @deprecated
   */
  PAST_DUE_INVOICE_MODAL = 'PAST_DUE_INVOICE_MODAL',
  SHOP_BOARDS_MODAL = 'SHOP_BOARDS_MODAL',
  /**
   * @deprecated
   */
  PRE_QUALIFIED_LIMIT_INCREASE_PROMO = 'PRE_QUALIFIED_LIMIT_INCREASE_PROMO',
  NET_TERMS_APPLICATION_PROMO = 'NET_TERMS_APPLICATION_PROMO',
  LIMIT_INCREASE_V2_PROMO = 'LIMIT_INCREASE_V2_PROMO',
  /**
   * @deprecated
   */
  YOUR_PICKS_QUIZ = 'YOUR_PICKS_QUIZ',
  /**
   * @deprecated
   */
  LIMIT_INCREASE_AT_FIRST_ORDER_PROMO = 'LIMIT_INCREASE_AT_FIRST_ORDER_PROMO',
  LAUNCH_WITH_FAIRE_ACCEPTANCE = 'LAUNCH_WITH_FAIRE_ACCEPTANCE',
  /**
   * @deprecated
   */
  WINTER_DISCOUNT_PROMO = 'WINTER_DISCOUNT_PROMO',
  /**
   * @deprecated
   */
  WINTER_2021Q1_DIRECT_MAIL_PROMO = 'WINTER_2021Q1_DIRECT_MAIL_PROMO',
  /**
   * @deprecated
   */
  WINTER_MARKET_2021Q1_DIRECT_MAIL_PROMO = 'WINTER_MARKET_2021Q1_DIRECT_MAIL_PROMO',
  /**
   * @deprecated
   */
  PURCHASE_ORDER_LANDING_PAGE = 'PURCHASE_ORDER_LANDING_PAGE',
  /**
   * @deprecated
   */
  WINTER_DISCOUNT_LAST_WEEK_REMINDER = 'WINTER_DISCOUNT_LAST_WEEK_REMINDER',
  /**
   * @deprecated
   */
  WINTER_DISCOUNT_LAST_DAY_COUNTDOWN = 'WINTER_DISCOUNT_LAST_DAY_COUNTDOWN',
  /**
   * Was used for a promotion during Faire Winter Market 2021
   * @deprecated
   */
  FWM_APPAREL_CLAIM_YOUR_GIFTS_MODAL = 'FWM_APPAREL_CLAIM_YOUR_GIFTS_MODAL',
  /**
   * Was used for a promotion during Faire Winter Market 2021
   * @deprecated
   */
  FWM_SELF_VERIFICATION_MODAL = 'FWM_SELF_VERIFICATION_MODAL',
  NON_BM_TERMS_APPLICATION_MODAL = 'NON_BM_TERMS_APPLICATION_MODAL',
  /**
   * @deprecated
   */
  NON_BRICK_AND_MORTAR_GUARANTEED_TERMS_MODAL = 'NON_BRICK_AND_MORTAR_GUARANTEED_TERMS_MODAL',
  /**
   * @deprecated
   */
  NON_BRICK_AND_MORTAR_POSSIBLE_TERMS_MODAL = 'NON_BRICK_AND_MORTAR_POSSIBLE_TERMS_MODAL',
  /**
   * @deprecated
   */
  PRICE_MATCH_GUARANTEE_MODAL = 'PRICE_MATCH_GUARANTEE_MODAL',
  NET_TERMS_HUB_LAUNCH_WITH_FAIRE_POST_APPLICATION_MODAL = 'NET_TERMS_HUB_LAUNCH_WITH_FAIRE_POST_APPLICATION_MODAL',
  /**
   * @deprecated
   */
  FAIRE_FASHION_WEEK_RETAILER_MODAL = 'FAIRE_FASHION_WEEK_RETAILER_MODAL',
  /**
   * @deprecated
   */
  POST_ORDER_INSIDER_ENROLLMENT = 'POST_ORDER_INSIDER_ENROLLMENT',
  OPENING_ORDER_DISCOUNT_MODAL = 'OPENING_ORDER_DISCOUNT_MODAL',
  OPENING_ORDER_DISCOUNT_COUNTDOWN_MODAL = 'OPENING_ORDER_DISCOUNT_COUNTDOWN_MODAL',
  /**
   * @deprecated
   */
  FAIRE_FASHION_WEEK_IDENTITY_VERIFICATION = 'FAIRE_FASHION_WEEK_IDENTITY_VERIFICATION',
  PROTECTED_FOR_NEW_RETAILER = 'PROTECTED_FOR_NEW_RETAILER',
  INTERNATIONAL_BUYING_INFORMATION_MODAL = 'INTERNATIONAL_BUYING_INFORMATION_MODAL',
  /**
   * @deprecated
   */
  INSIDER_BRAND_REFRESH_ANNOUNCEMENT_MODAL = 'INSIDER_BRAND_REFRESH_ANNOUNCEMENT_MODAL',
  FIRST_ORDER_INCENTIVE_MODAL = 'FIRST_ORDER_INCENTIVE_MODAL',
  HOLIDAY_EXTENDED_WINDOW_START = 'HOLIDAY_EXTENDED_WINDOW_START',
  HOLIDAY_EXTENDED_WINDOW_END = 'HOLIDAY_EXTENDED_WINDOW_END',
  /**
   * @deprecated
   */
  RE_ENGAGEMENT_INCENTIVE_MODAL = 'RE_ENGAGEMENT_INCENTIVE_MODAL',
  /**
   * @deprecated
   */
  PRE_EVENT_MODAL_FFW_2022 = 'PRE_EVENT_MODAL_FFW_2022',
  INSIDER_SIGNUP_EDUCATION_MODAL = 'INSIDER_SIGNUP_EDUCATION_MODAL',
  LANGUAGE_UPDATE_MODAL = 'LANGUAGE_UPDATE_MODAL',
  LANGUAGE_DAN_UPDATE_MODAL = 'LANGUAGE_DAN_UPDATE_MODAL',
  LANGUAGE_SWE_UPDATE_MODAL = 'LANGUAGE_SWE_UPDATE_MODAL',
  LOCAL_DISCOUNT_RETAILER_MODAL = 'LOCAL_DISCOUNT_RETAILER_MODAL',
  /**
   * @deprecated
   */
  FFW_PRE_EVENT_MODAL = 'FFW_PRE_EVENT_MODAL',
  /**
   * @deprecated
   */
  FD_CREDIT_MARKETPLACE_PIVOT_MODAL = 'FD_CREDIT_MARKETPLACE_PIVOT_MODAL',
  MULTI_USER_RETAILER_SET_UP_TEAM_MODAL = 'MULTI_USER_RETAILER_SET_UP_TEAM_MODAL',
  PAYMENT_DISPUTE_MODAL = 'PAYMENT_DISPUTE_MODAL',
  WAREHOUSE_SALE_MODAL = 'WAREHOUSE_SALE_MODAL',
  /**
   * @deprecated
   */
  INSIDER_GROWTH_MIGRATION_PROMO_MODAL = 'INSIDER_GROWTH_MIGRATION_PROMO_MODAL',
  SUNSET_LOCAL_DISCOUNT_RETAILER_MODAL = 'SUNSET_LOCAL_DISCOUNT_RETAILER_MODAL',
  /**
   * @deprecated
   */
  PREPAID_CARDS_RESTRICTED_MODAL = 'PREPAID_CARDS_RESTRICTED_MODAL',
  INSIDER_FRAMANY_NORDICS_PRE_LAUNCH_ANNOUNCEMENT_MODAL = 'INSIDER_FRAMANY_NORDICS_PRE_LAUNCH_ANNOUNCEMENT_MODAL',
  INSIDER_FRAMANY_NORDICS_POST_LAUNCH_ANNOUNCEMENT_MODAL = 'INSIDER_FRAMANY_NORDICS_POST_LAUNCH_ANNOUNCEMENT_MODAL',
  /**
   * @deprecated
   */
  OVERDUE_INVOICE_MODAL = 'OVERDUE_INVOICE_MODAL',
  PAYMENT_PLAN_PAYMENT_FAILED_MODAL = 'PAYMENT_PLAN_PAYMENT_FAILED_MODAL',
  PAYMENT_PLAN_FULLY_PAID_MODAL = 'PAYMENT_PLAN_FULLY_PAID_MODAL',
  SHOPIFY_NEW_RETAILER_BENEFITS_MODAL = 'SHOPIFY_NEW_RETAILER_BENEFITS_MODAL',
  SHOPIFY_EXISTING_RETAILER_BENEFITS_MODAL = 'SHOPIFY_EXISTING_RETAILER_BENEFITS_MODAL',
  ACH_PROACTIVE_LIMIT_INCREASE_LANDING_MODAL = 'ACH_PROACTIVE_LIMIT_INCREASE_LANDING_MODAL',
  FAIRE_DIRECT_MARKETPLACE_DISCOUNT_MODAL = 'FAIRE_DIRECT_MARKETPLACE_DISCOUNT_MODAL',
  INSIDER_TERMS_AND_CONDITIONS_MODAL = 'INSIDER_TERMS_AND_CONDITIONS_MODAL',
  ACH_TERMS_PRE_APPROVAL_LANDING_MODAL = 'ACH_TERMS_PRE_APPROVAL_LANDING_MODAL',
  TERMS_PRE_APPROVAL_LANDING_MODAL = 'TERMS_PRE_APPROVAL_LANDING_MODAL',
  FAIRE_DIRECT_INCENTIVE_MODAL = 'FAIRE_DIRECT_INCENTIVE_MODAL',
  /**
   * @deprecated
   */
  FAIRE_DIRECT_GIFT_CARD = 'FAIRE_DIRECT_GIFT_CARD',
  FAIRE_DIRECT_EVERGREEN_MARKETPLACE_DISCOUNT_MODAL = 'FAIRE_DIRECT_EVERGREEN_MARKETPLACE_DISCOUNT_MODAL',
  FAIRE_PAY_DEFAULTED_MODAL = 'FAIRE_PAY_DEFAULTED_MODAL',
  NON_BM_INITIAL_LIMIT_LANDING_MODAL = 'NON_BM_INITIAL_LIMIT_LANDING_MODAL',
  INSIDER_TERMS_AND_CONDITIONS_ACCEPTANCE = 'INSIDER_TERMS_AND_CONDITIONS_ACCEPTANCE',
  ONE_OFF_LIMIT_INCREASE_MODAL = 'ONE_OFF_LIMIT_INCREASE_MODAL',
  INSIDER_PARTNERSHIP_LAUNCH_ANNOUNCEMENT = 'INSIDER_PARTNERSHIP_LAUNCH_ANNOUNCEMENT',
  SDUI_MODAL = 'SDUI_MODAL',
  SALE_ANNONCEMENT_MODAL = 'SALE_ANNONCEMENT_MODAL',
  INSIDER_REFRESH_REMINDER_MODAL = 'INSIDER_REFRESH_REMINDER_MODAL',
  INSIDER_CAN_AUS_LAUNCH_MODAL = 'INSIDER_CAN_AUS_LAUNCH_MODAL',
}
