import { Language } from "@faire/web-api--source/indigofair/languages/Language";

import { THREE_TO_TWO_LETTER_LANGUAGE_CODE } from "./THREE_TO_TWO_LETTER_LANGUAGE_CODE";

/**
 * @deprecated use #getDefaultLocale
 */
export const DEFAULT_LOCALES: {
  [K in Exclude<keyof typeof THREE_TO_TWO_LETTER_LANGUAGE_CODE, "JPN">]: string;
} = {
  [Language.ENG]: "en",
  [Language.FRA]: "fr-FR",
  [Language.DEU]: "de-DE",
  [Language.ITA]: "it-IT",
  [Language.SPA]: "es-ES",
  [Language.SWE]: "sv-SE",
  [Language.DAN]: "da-DK",
  [Language.POR]: "pt-PT",
  [Language.NLD]: "nl-NL",
  [Language.GIBBERISH]: "xa",
  [Language.STRING_ID]: "xc",
} as const;

export function getDefaultLocale<K extends keyof typeof DEFAULT_LOCALES>(
  key: K
): (typeof DEFAULT_LOCALES)[K];
export function getDefaultLocale<K extends keyof typeof Language>(
  key: K
): (typeof DEFAULT_LOCALES)[keyof typeof DEFAULT_LOCALES] | undefined;

export function getDefaultLocale(key: keyof typeof Language) {
  return DEFAULT_LOCALES[key as keyof typeof DEFAULT_LOCALES];
}
