import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'webserver-ssr_viewpoint_miss-view';

/**
 * Emitted when the actual viewpoint in client side is different from the viewpoint used during SSR
 */
export function trackWebserverSsrViewpointMissView(
  productArea: string,
  path2: string,
  actualDeviceType3: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: { productArea, path2, actualDeviceType3 },
  });
}
