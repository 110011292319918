import { DefaultTheme } from "styled-components";

import { getWeight, Weight, WeightOrKey } from "../Font";
import { themeOrDefault } from "../Theme/index";

import { IFonts, IFontVariants } from "./Theme";

/** @deprecated use variants on Typography v2 from "@faire/web--source/slate/Typography" */
export const getFontStyles = (
  theme: DefaultTheme,
  key: keyof IFontVariants,
  weight?: WeightOrKey,
  serif?: boolean
) => {
  const sansOrNot: keyof IFonts = serif ? "serif" : "sansSerif";
  const font = themeOrDefault(theme).fonts[sansOrNot][key];
  if (!font) {
    return {};
  }

  let resolvedWeight =
    (weight && getWeight(weight)) || font.weight || Weight.medium;
  if (theme.name === "Faire") {
    // should fall back to font.weight first but don't want to
    // break existing usages of the default theme.
    resolvedWeight = (weight && getWeight(weight)) || Weight.medium;
  }

  return {
    "font-family": font.family(resolvedWeight),
    "font-weight": font.weight,
    "font-size": font.size,
    "line-height": font.lineHeight,
    "letter-spacing": font.letterSpacing,
  };
};
