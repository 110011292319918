import { IMessageContents } from '../../faire/messenger/IMessageContents';
import { IMessengerParticipantToken } from '../../faire/messenger/IMessengerParticipantToken';

export namespace ICreateConversationRequestV3 {
  /**
   * Return an instance of ICreateConversationRequestV3 with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<ICreateConversationRequestV3>
  ): ICreateConversationRequestV3 =>
    ({
      ...(partial ?? {}),
    }) as ICreateConversationRequestV3;
}

export interface ICreateConversationRequestV3 {
  /**
   * The recipient token for the message. This can be a user, brand, or retailer token.
   */
  other_participant_token: IMessengerParticipantToken | undefined;
  message_contents: IMessageContents | undefined;
}
