"use client";

import { NoSSR } from "@faire/web--source/common/NoSSR";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Monitoring } from "react-scan/monitoring/next";

import { getReleaseSha } from "@faire/retailer/serialized-data/getReleaseSha";
import { useSettingEnableReactScanMonitoring } from "@faire/retailer/settings/useSettingEnableReactScanMonitoring";

type Props = {
  apiKey: string | undefined;
};

export const ReactScanMonitoring: React.FC<Props> = ({ apiKey }) => {
  const enableReactScanMonitoring = useSettingEnableReactScanMonitoring();

  if (!enableReactScanMonitoring) {
    return null;
  }

  return (
    <NoSSR>
      <ErrorBoundary fallback={null}>
        {apiKey ? (
          <Monitoring apiKey={apiKey} commit={getReleaseSha()} branch="_" />
        ) : null}
      </ErrorBoundary>
    </NoSSR>
  );
};
