export namespace IMessageContents {
  /**
   * Return an instance of IMessageContents with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IMessageContents>
  ): IMessageContents =>
    ({
      image_tokens: [],
      product_tokens: [],
      ...(partial ?? {}),
    }) as IMessageContents;
}

export interface IMessageContents {
  /**
   * The text for the message, if any.
   */
  text: string | undefined;
  /**
   * The image tokens of the images attached to the message, if any.
   * @protobufOption (.faire.common.tokenType) = IMAGE
   */
  image_tokens: Array<string>;
  /**
   * The products attached to the message, if any. *
   * @protobufOption (.faire.common.tokenType) = PRODUCT
   */
  product_tokens: Array<string>;
  /**
   * The line sheet attached to the message, if any. We only support ProductBasedCollectionType = LINE_SHEET
   * @deprecated
   * @protobufOption (.faire.common.tokenType) = PRODUCT_BASED_COLLECTION
   */
  product_based_collection_token: string | undefined;
}
