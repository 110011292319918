import { Color } from "../Color";

import { eventsThemeColors } from "./eventsThemeColors";

/**
 * Styling of the banners, if it deviates from the defaults.
 */
export interface IBanners {
  alert?: IBanner;
  promotional?: IBanner;
}

export interface IBanner {
  /**
   * Optional color of banner.
   */
  color?: Color;
  textColor?: Color;
}

export const bannerEventsTheme: IBanners = {
  alert: {
    color: eventsThemeColors.faireMarket.lightBackground,
    textColor: eventsThemeColors.faireMarket.promoText,
  },
};
