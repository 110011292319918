import { QueryParameters as ConductorComponentQueryParameters } from "@faire/web-api--source/api/conductor/component/get";
import { useQuery as useGetConductorComponentQuery } from "@faire/web-api--source/api/conductor/component/get-hooks";
import { useQuery as useGetConductorComponentFromToken } from "@faire/web-api--source/api/conductor/component/componentToken/get-hooks";
import { ConductorSurface } from "@faire/web-api--source/indigofair/data/ConductorSurface";

import { useIsLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";

interface UseConductorComponentProps {
  surface: keyof typeof ConductorSurface | undefined;
  componentToken?: string;
}

export const useConductorComponent = ({
  surface,
  componentToken,
}: UseConductorComponentProps) => {
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const queryParams = ConductorComponentQueryParameters.build({
    surface,
  });

  const conductorComponentFromToken = useGetConductorComponentFromToken(componentToken || '', {
    enabled: !!componentToken,
  });

  const conductorComponentFallback = useGetConductorComponentQuery(queryParams, {
    enabled: isLoggedInRetailer && !!queryParams,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  const activeConductorComponent = componentToken ? conductorComponentFromToken : conductorComponentFallback;
  const { data, isPending } = activeConductorComponent;

  const conductorSduiViewLayout = data?.component?.sdui_component?.view_layout;
  const conductorBuilderModelName =
    data?.component?.builder_component?.builder_model_name;
  const shouldShowConductor =
    !!conductorSduiViewLayout || !!conductorBuilderModelName;

  return {
    componentToken: data?.component?.token,
    shouldShowConductor: shouldShowConductor,
    conductorSduiViewLayout: conductorSduiViewLayout,
    conductorBuilderModelName: conductorBuilderModelName,
    isConductorComponentPending: isPending,
  };
};
