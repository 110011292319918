import { readPrefetchedDataFactory } from "@faire/web--source/common/server-side-rendering/ssrPrefetch";
import { IRetailerPageData } from "@faire/web-api--source/faire/home_resource/IRetailerPageData";

/**
 * Read bundled web-retailer data from the globals.
 *
 * Bundled data is any data that we fetched on the backend along with the data
 * needed to render the HTML document. This was historically called "backend prefetch",
 * but we have attempted to disambiguate prefetching (calling endpoints early from the client)
 * from bundling (loading extra data during the backend data fetch).
 */
export const readBundledData =
  readPrefetchedDataFactory<IRetailerPageData.IPrefetchedData>();
