import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { isSandbox } from "@faire/web--source/common/sandbox";
import { RequestOptions } from "@faire/web-api--source/types";

export const replaceSubdomainInSandboxInterceptor = (
  config: RequestOptions
) => {
  // E2E sandbox doesn't support subdomains, so we need to replace it with www
  // when targeting sandbox environment as BE
  if (
    isSandbox(getWindow()?.location?.href ?? "") &&
    config.subdomain === "events.api"
  ) {
    config.subdomain = "www";
  }

  return config;
};
