import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { localDate } from "@faire/web--source/common/localization";
import {
  StrictLocalizeFunction,
  backwardsCompatibleValues,
} from "@faire/web--source/common/localization/localize";
import { IGetMessengerConversationsResponse } from "@faire/web-api--source/indigofair/data/IGetMessengerConversationsResponse";
import { IUser } from "@faire/web-api--source/indigofair/data/IUser";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInCalendarYears from "date-fns/differenceInCalendarYears";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
import getHours from "date-fns/getHours";

import { ConversationModel } from "./ConversationModel";
import { MessageModel } from "./MessageModel";

export const toReadableLatestMessageDate = (
  unixDate: number,
  strictLocalize: StrictLocalizeFunction
) => {
  const diffDays = differenceInCalendarDays(Date.now(), unixDate);

  if (!unixDate || diffDays < 0) {
    return "";
  } else if (diffDays === 0) {
    return localDate(unixDate, "p");
  } else if (diffDays < 7) {
    return strictLocalize(
      { defaultMessage: "{days, plural, one{# day ago} other{# days ago}}" },
      backwardsCompatibleValues({ days: diffDays })
    );
  } else if (differenceInCalendarYears(Date.now(), unixDate) > 0) {
    return localDate(unixDate, "PP");
  } else {
    return localDate(unixDate, "MMM d");
  }
};

export const getOtherPartyCompanyName = (
  conversation: ConversationModel,
  strictLocalize: StrictLocalizeFunction
) => {
  let companyName = "";
  if (conversation.otherParty?.type === IUser.Type.ADMIN) {
    companyName = strictLocalize({ defaultMessage: "Faire Support" });
  } else if (
    conversation.otherParty?.type === IUser.Type.USER &&
    conversation.retailer &&
    conversation.retailer.name
  ) {
    companyName = conversation.retailer.name;
  } else if (
    conversation.otherParty?.type === IUser.Type.BRAND_USER &&
    conversation.brand &&
    conversation.brand.name
  ) {
    companyName = conversation.brand.name;
  } else if (
    conversation.otherParty?.type === IUser.Type.GUEST &&
    conversation.otherPartyCompany
  ) {
    companyName = conversation.otherPartyCompany;
  }
  return companyName;
};

export const shouldShowHourTimestamp = (
  currentMessage: MessageModel,
  previousMessage?: MessageModel
): boolean => {
  if (!currentMessage.createdAt) {
    return false;
  }
  // FIXME: Should we also hide these hour timestamps on synthetic messages?

  if (
    !previousMessage ||
    !previousMessage.createdAt ||
    previousMessage.authorToken !== currentMessage.authorToken
  ) {
    return true;
  }

  const differenceInTimeMinutes = differenceInMinutes(
    currentMessage.createdAt,
    previousMessage.createdAt
  );

  return differenceInTimeMinutes >= 1;
};

export const shouldShowDateTimestamp = (
  currentMessage: MessageModel,
  nextMessage?: MessageModel
): boolean => {
  if (!currentMessage.createdAt) {
    return false;
  }

  if (!nextMessage || !nextMessage.createdAt) {
    return true;
  }

  const diffHours = differenceInHours(
    currentMessage.createdAt,
    nextMessage.createdAt
  );
  const currentHours = getHours(currentMessage.createdAt);

  return Math.floor(diffHours / currentHours) > 0;
};

export const shouldShowTimestampOnDeliveredMessage = (
  currentMessage: MessageModel,
  nextMessage?: MessageModel
): boolean => {
  if (!currentMessage.createdAt) {
    return false;
  }

  if (
    !nextMessage ||
    !nextMessage.createdAt ||
    nextMessage?.authorToken !== currentMessage.authorToken
  ) {
    return true;
  }

  const differenceInTimeMinutes = differenceInMinutes(
    nextMessage.createdAt,
    currentMessage.createdAt
  );

  return differenceInTimeMinutes >= 1;
};

export const isOnVacation = (
  brand?: IGetMessengerConversationsResponse.ISimpleBrand
): boolean => {
  const now = Date.now();

  return (
    !!brand &&
    !!brand.vacation_start_date &&
    !!brand.vacation_end_date &&
    brand.vacation_start_date <= now &&
    brand.vacation_end_date >= now
  );
};

export const conversationLastModifiedComparator = (
  conversationA: ConversationModel,
  conversationB: ConversationModel
) => {
  if (conversationA.lastModified === undefined) {
    return 1;
  }
  if (conversationB.lastModified === undefined) {
    return -1;
  }
  if (conversationA.lastModified < conversationB.lastModified) {
    return 1;
  }
  if (conversationA.lastModified > conversationB.lastModified) {
    return -1;
  }
  return 0;
};

export const conversationRetailerContextComparator = (
  conversationA: ConversationModel,
  conversationB: ConversationModel,
  retailerContextToken: string | undefined
) => {
  if (retailerContextToken === undefined) {
    return 0;
  }
  if (conversationA.otherPartyToken === retailerContextToken) {
    return -1;
  }
  if (conversationB.otherPartyToken === retailerContextToken) {
    return 1;
  }
  return 0;
};

export const conversationBrandContextComparator = (
  conversationA: ConversationModel,
  conversationB: ConversationModel,
  brandContextToken: string | undefined
) => {
  if (brandContextToken === undefined) {
    return 0;
  }
  if (conversationA.otherPartyBrandToken === brandContextToken) {
    return -1;
  }
  if (conversationB.otherPartyBrandToken === brandContextToken) {
    return 1;
  }
  return 0;
};

export const createToken = () => {
  // Must be large than zzzzzzzz base 36
  const longMultiplier = 10000000000000;
  const longNum = Math.random() * longMultiplier + longMultiplier;
  return `mm_${longNum.toString(36).slice(0, 8)}`;
};

export const getBrandTokenInSession = (): string | undefined => {
  return getGlobalProperty("brandToken");
};
