import { getWindow } from "@faire/web--source/common/globals/getWindow";

declare const InstallTrigger: unknown;
declare const safari: any;

// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769
export const isSupportedBrowser = () => {
  const window: any = getWindow();
  if (!window) {
    return false;
  }
  const isFirefox = typeof InstallTrigger !== "undefined";

  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    ((p: any) => p.toString() === "[object SafariRemoteNotification]")(
      !window["safari"] ||
        (typeof safari !== "undefined" && safari.pushNotification)
    );

  const isChrome = !!window?.chrome?.webstore || !!window?.chrome?.runtime;
  return isFirefox || isSafari || isChrome;
};
