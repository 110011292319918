import { Language } from "@faire/web-api--source/indigofair/languages/Language";

/**
 * @deprecated use #getFullLanguageName
 */
export const THREE_TO_FULL_LANGUAGE_NAME: {
  [K in Exclude<Language, Language.LANGUAGE_UNKNOWN | Language.NOR>]: string;
} = {
  [Language.ENG]: "English",
  [Language.FRA]: "Français",
  [Language.DEU]: "Deutsch",
  [Language.ITA]: "Italiano",
  [Language.SPA]: "Español",
  [Language.SWE]: "Svenska",
  [Language.DAN]: "Dansk",
  [Language.POR]: "Português",
  [Language.NLD]: "Nederlands",
  [Language.GIBBERISH]: "Gibberish",
  [Language.STRING_ID]: "String IDs",
} as const;

export function getFullLanguageName<
  K extends keyof typeof THREE_TO_FULL_LANGUAGE_NAME
>(key: K): (typeof THREE_TO_FULL_LANGUAGE_NAME)[K];
export function getFullLanguageName(
  key: string
):
  | (typeof THREE_TO_FULL_LANGUAGE_NAME)[keyof typeof THREE_TO_FULL_LANGUAGE_NAME]
  | undefined;

export function getFullLanguageName(key: string) {
  return THREE_TO_FULL_LANGUAGE_NAME[
    key as keyof typeof THREE_TO_FULL_LANGUAGE_NAME
  ];
}
