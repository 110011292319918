import { IBrandDiscount } from "@faire/web-api--source/indigofair/data/IBrandDiscount";
import isBefore from "date-fns/isBefore";

export const hasFaireDirectFirstOrderIncentive = (
  faireDirectFirstOrderIncentive: IBrandDiscount | undefined
): boolean => {
  if (!faireDirectFirstOrderIncentive) {
    return false;
  }

  const { claimed_at: claimedAt, expires_at: expiresAt } =
    faireDirectFirstOrderIncentive;

  return !claimedAt && isBefore(Date.now(), expiresAt ?? 0);
};
