import { getGlobalProperty } from "./getGlobalProperty";
import { getWindow } from "./getWindow";

export const getLocation = (): Location | undefined =>
  getGlobalProperty<Location>("location") ?? getWindow()?.location;

export const getLocationOrThrow = (): Location => {
  const location = getLocation();
  if (!location) {
    throw new Error("Unable to resolve window.location");
  }
  return location;
};
