import { BatchEventsManager } from "@faire/web--source/common/batch-events/BatchEventsManager";
import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { getFaireDirectBrandAliasFromQueryParam } from "@faire/web--source/common/routes/faire-direct/getFaireDirectBrandAliasFromQueryParam";
import { splitHostname } from "@faire/web--source/common/splitHostname";
import { IUserEvent } from "@faire/web-api--source/indigofair/data/IUserEvent";
import debug from "debug";

const logEvent = debug("IF:EVENT");

/**
 * @deprecated track events using PETE instead. https://faire.com/pete
 * @trackfunction
 */
export const trackEvent = (
  eventPath: string | string[],
  prependPathname: boolean = false
) => {
  const joinedPath = (() => {
    if (Array.isArray(eventPath)) {
      return eventPath.join("/");
    }
    return eventPath;
  })();
  const fullEventPath = `${
    prependPathname ? getLocationOrThrow().pathname.replace(/\/$/, "") : ""
  }/${joinedPath.replace(/^\//, "")}`;
  logEvent(fullEventPath);

  let subdomain: string | undefined;
  try {
    const faireDirectBrandAlias = getFaireDirectBrandAliasFromQueryParam(
      getLocationOrThrow().search
    );
    if (faireDirectBrandAlias) {
      subdomain = faireDirectBrandAlias;
    } else {
      subdomain = splitHostname(getLocationOrThrow().hostname)?.subdomain;
    }
  } catch (_) {
    /* ignore */
  }
  const event = IUserEvent.build({
    client_created_at: Date.now(),
    path: fullEventPath,
    subdomain,
  });
  return BatchEventsManager.get().enqueueEvent(event);
};

/**
 * @deprecated track events using PETE instead. https://faire.com/pete
 * @trackfunction
 */
export const trackEventOnClick =
  (eventPath: string | string[], prependPathname: boolean = false) =>
  () => {
    trackEvent(eventPath, prependPathname);
  };
