import { LocalizationStore } from "./LocalizationStore";

/**
 * @deprecated use `useLocalNum` instead
 */
export function localNum(
  value: number,
  opts?: Intl.NumberFormatOptions
): string;
/**
 * @deprecated use `useLocalNum` instead
 */
export function localNum(
  value: number | undefined,
  opts?: Intl.NumberFormatOptions
): string | undefined;
/**
 * @deprecated use `useLocalNum` instead
 */
export function localNum(
  value?: number,
  opts?: Intl.NumberFormatOptions
): string | undefined {
  if (value === undefined) {
    return undefined;
  }
  return Intl.NumberFormat(
    LocalizationStore.get().currencyFormatLocale,
    opts
  ).format(value);
}
