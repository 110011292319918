import { singletonGetter } from "@faire/web--source/common/singletons/getter";

import { isWindowUndefined } from "./server-side-rendering/isWindowUndefined";

/* eslint-disable @faire/no-local-storage */
/* eslint-disable @faire/one-exported-class-per-file */

const getLocalStorage = (): Window["localStorage"] | undefined => {
  try {
    if (isWindowUndefined()) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @faire-web/no-restricted-globals
    return window.localStorage;
  } catch (_) {
    return undefined;
  }
};

const getSessionStorage = (): Window["sessionStorage"] | undefined => {
  try {
    if (isWindowUndefined()) {
      return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @faire-web/no-restricted-globals
    return window.sessionStorage;
  } catch (_) {
    return undefined;
  }
};

export class Storage {
  static get local() {
    return LocalStorage.get();
  }

  static get session() {
    return SessionStorage.get();
  }

  protected _fallbackCache: Record<string, string> = {};

  constructor(public dataStorage?: any) {}

  isStorageAvailable = () => {
    if (!this.dataStorage) {
      return false;
    }

    const testKey = "test";
    try {
      this.dataStorage.setItem(testKey, "test");
      this.dataStorage.removeItem(testKey);
    } catch (_) {
      return false;
    }

    return true;
  };

  readonly getItem = (key: string): string | undefined => {
    if (this.isStorageAvailable()) {
      const value = this.dataStorage.getItem(key);
      if (typeof value === "string") {
        return value;
      }
    }

    if (this._fallbackCache[key] !== undefined) {
      return this._fallbackCache[key];
    }
    return undefined;
  };

  readonly setItem = (key: string, value: string): void => {
    if (this.isStorageAvailable()) {
      this.dataStorage.setItem(key, value);
      return;
    }

    this._fallbackCache[key] = value;
  };

  readonly removeItem = (key: string): void => {
    if (this.isStorageAvailable()) {
      this.dataStorage.removeItem(key);
      return;
    }

    delete this._fallbackCache[key];
  };

  readonly clear = () => {
    if (this.isStorageAvailable()) {
      this.dataStorage.clear();
      return;
    }

    this._fallbackCache = {};
  };
}

export class LocalStorage extends Storage {
  static get = singletonGetter(LocalStorage);
  constructor() {
    super(getLocalStorage());
  }
}

export class SessionStorage extends Storage {
  static get = singletonGetter(SessionStorage);
  constructor() {
    super(getSessionStorage());
  }
}

/**
 * @deprecated Use Storage.local. This const is evaluated too early in a cypress context.
 */
export const StorageService = new LocalStorage();
