/**
 * For auto cropping project,
 * in phrase 1 hero images of products are cropped by google cloud api
 * and tag generated with this prefix.
 * There are two kinds of tags. one is square for normal product images
 * Another is 3:4 crop for apparel product images.
 * **/
import {
  MANUAL_IMAGE_CROP_APPAREL_TAG_PREFIX,
  MANUAL_IMAGE_CROP_TAG_PREFIX,
} from "@faire/web--source/common/images/cropUtils/manualImageCropTagPrefix";

/**
 * @deprecated
 */
export const AUTO_IMAGE_CROP_TAG_PREFIX = "auto_image_crop:";
export const AUTO_IMAGE_CROP_SQUARE_TAG_PREFIX = "auto_image_crop_1x1:";
export const AUTO_IMAGE_CROP_APPAREL_TAG_PREFIX = "auto_image_crop_3x4:";
export const ALL_IMAGE_CROPPING_PREFIX = [
  AUTO_IMAGE_CROP_SQUARE_TAG_PREFIX,
  AUTO_IMAGE_CROP_APPAREL_TAG_PREFIX,
  MANUAL_IMAGE_CROP_APPAREL_TAG_PREFIX,
  MANUAL_IMAGE_CROP_TAG_PREFIX,
];
