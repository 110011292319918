/**
 * If you are displaying an image from any DB object on any client it should be transformed.
 *
 * You probably either want to use [[optimizeProductImageUrl]] or [[optimizeImageUrl]].
 *
 * ONLY transform **product** or **product option** images using [[optimizeProductImageUrl]]!
 *
 * Details about options can be found in [[IOptimizeImageOptions]]
 *
 * @packageDocumentation
 */
import {
  ALL_IMAGE_CROPPING_PREFIX,
  AUTO_IMAGE_CROP_APPAREL_TAG_PREFIX,
  AUTO_IMAGE_CROP_SQUARE_TAG_PREFIX,
} from "@faire/web--source/common/images/cropUtils/autoImageCropTagPrefix";
import {
  MANUAL_IMAGE_CROP_APPAREL_TAG_PREFIX,
  MANUAL_IMAGE_CROP_TAG_PREFIX,
} from "@faire/web--source/common/images/cropUtils/manualImageCropTagPrefix";
import { removeImageCropTag } from "@faire/web--source/common/images/cropUtils/removeImageCropTag";
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";
import clone from "lodash/clone";
import isNil from "lodash/isNil";
import isObject from "lodash/isObject";
import merge from "lodash/merge";
import startsWith from "lodash/startsWith";
import qs from "query-string";

import { logError } from "../logging";
import {
  assignOptimizeImageUrlForCacheHit,
  getSettingOptimizeImageUrlForCacheHit,
} from "../settings/getSettingOptimizeImageUrlForCacheHit";
import {
  assignOptimizeImageUrlForCacheHitBrand,
  getSettingOptimizeImageUrlForCacheHitBrand,
} from "../settings/getSettingOptimizeImageUrlForCacheHitBrand";
import { hasErrorMessage } from "../typescriptUtils";

export interface IOptimizeImageOptions {
  width?: number;
  height?: number;
  /**
   * given a bounding rectangle
   * fit: scales the image to fit inside it
   * fill: scales and crops the image to fill the rectangle
   * pad: scales the image to fit inside and pads the remaining space
   */
  resizeMethod?: "fit" | "fill" | "pad";
  /**
   * Use this to override resize method tag and use provided resize method
   */
  ignoreResizeTag?: boolean;

  /**
   * crop
   * smartSquare: crop the image square using content-aware image cropping. Overrides other crop options.
   * width and height: crop width and height in px
   * x and y: offset x and y from top left corner in px
   */
  crop?: {
    smartSquare?: boolean;
    width?: number;
    height?: number;
    x?: number;
    y?: number;
  };

  /**
   * By default everything is converted to a jpeg.
   * This allows for better optimization, however, remove transparency.
   * By maintaining transparency the images returned will not be as well optimized
   */
  maintainTransparency?: boolean;

  /**
   * When a resize method is used that leave blank canvas space around an image
   * a default color of white will be used.
   * This option allows you to control the background.
   */
  background?: string;

  /**
   * 0-100 values, values closer to 100 use more bits for more quality.
   * Only need to set this on images that don't work nicely with fastly's
   * default quality level.
   */
  quality?: number;

  /** Only use this if you really know what you are doing. */
  dprOverride?: number;

  /** `true` when the image is being optimized from server component render. `false` otherwise. */
  serverOnly?: boolean;
}

interface IOptimizeImageConfig extends IOptimizeImageOptions {
  /**
   * By default lossy compression is used (jpeg or webp).
   * By using a lossless format we avoid compression artifacts, but beware that the file size will increase by ~10x.
   * Therefore it is not recommended to unconditionally set `useLossless: true` in code.
   * Instead it should only be set for specific images using the `prefer_lossless` tag.
   */
  useLossless?: boolean;
}

let defaultDPR: number = 1;

/**
 * Sets the DPR for a session. Should only be set once per repo.
 *
 * If you want to set the DPR for a specific image see [[IOptimizeImageOptions.dprOverride]]
 */
export const setImageDPR = (dpr?: number): void => {
  defaultDPR = dpr ? Math.round(dpr * 2) / 2 : 1;
};

// Test if given URL is a Shopify CDN url
const isShopifyImageUrl = (url: string): boolean =>
  /cdn\.shopify\.com\//.test(url);

// Test if given URL is a fastly/internal faire CDN url
export const isFastly = (url: string): boolean =>
  /cdn\.faire\.com\//.test(url) || /cdn\.faire-stage\.com\//.test(url);

// Test if given URL is a .tiff url
const isTiff = (url: string): boolean => /\.tiff?($|\?)/.test(url);

/**
 * Useful if different UI handles transformable image vs something else
 */
export const isTransformableImageUrl = (image?: Partial<IImage>): boolean =>
  !!image && !!image.url && !isTiff(image.url) && isFastly(image.url);

const isHexColor = (color: string): boolean => color[0] === "#";

const getShopifyImageUrl = (
  imageUrl: string,
  options: IOptimizeImageConfig
) => {
  const [pathName, ...queries] = imageUrl.split("?");
  const params = qs.parse(queries.join("?"));
  const width =
    options.width && options.width > 0 ? options.width.toString() : undefined;
  const height =
    options.height && options.height > 0
      ? options.height.toString()
      : undefined;
  if (width) {
    params.width = width;
  }
  if (height) {
    params.height = height;
  }
  return `${pathName}?${qs.stringify(params, { encode: false })}`;
};

// eslint-disable-next-line complexity
const getFastlyUrl = (
  imageUrl: string,
  options: IOptimizeImageConfig,
  isSSR: boolean
): string => {
  if (!isFastly(imageUrl)) {
    return imageUrl;
  }

  const [pathName, ...queries] = imageUrl.split("?");

  const params = qs.parse(queries.join("?"));

  params.dpr = String(getDpr(options, isSSR));

  if (options.useLossless) {
    params.format = "png";
  } else if (options.maintainTransparency) {
    params.format = null;
  } else {
    params.format = "jpg";
  }

  const width =
    options.width && options.width > 0 ? options.width.toString() : undefined;
  const height =
    options.height && options.height > 0
      ? options.height.toString()
      : undefined;

  if (width) {
    params.width = width;
  }
  if (height) {
    params.height = height;
  }

  const { serverOnly } = options;

  if (options.resizeMethod) {
    // Default resizeMethod is fit, if you override, you needs to
    // provide both width and height
    if (!width || !height) {
      logError(new Error("getFastlyUrl - resizing requires width and height"), {
        data: { imageUrl, options },
        serverOnly,
      });
    }
    if (options.resizeMethod === "fit") {
      params.fit = "bounds";
    } else if (options.resizeMethod === "fill") {
      params.fit = "crop";
    } else if (options.resizeMethod === "pad") {
      params.fit = "bounds";
      params.canvas = `${width}:${height}`;
    }
  }

  if (options.crop) {
    const { width, height, x, y, smartSquare } = options.crop;
    if (smartSquare) {
      params.precrop = "1:1,smart";
      if (!isNil(width) || !isNil(height) || !isNil(x) || !isNil(y)) {
        logError(
          new Error(
            "getFastlyUrl - crop options includes options that will be overriden"
          ),
          {
            data: {
              imageUrl,
              options,
            },
            serverOnly,
          }
        );
      }
    } else if (!isNil(width) && !isNil(height) && !isNil(x) && !isNil(y)) {
      // https://developer.fastly.com/reference/io/crop/
      // CROP_FAIL_SAFE is passed to ensure that the image loads
      // even if the crop param is malformed.
      // If the crop param is malformed the crop will not be applied.
      // Currently we only use manual crop on brand headers,
      // if we use that image anywhere else the manual crop might not be applied.
      // If we want to preserve the manual crop we should look into
      // changing from using fixed crop values to using relative values
      // see: https://github.com/Faire/web-common/pull/222#pullrequestreview-591312668
      const CROP_FAIL_SAFE = "safe";
      if (width > 0 && height > 0) {
        params.precrop = `${width},${height},x${x},y${y},${CROP_FAIL_SAFE}`;
      } else {
        logError(new Error("getFastlyUrl - crop options is malformed"), {
          data: {
            imageUrl,
            options,
          },
          serverOnly,
        });
      }
    } else {
      logError(new Error("getFastlyUrl - crop options have missing props"), {
        data: {
          imageUrl,
          options,
        },
        serverOnly,
      });
    }
  }

  if (!isNil(options.quality)) {
    params.quality = `${options.quality}`;
  }

  if (options.background) {
    if (isHexColor(options.background)) {
      const hexColor = options.background.slice(1);
      params["bg-color"] = `${hexColor}`;
    } else {
      logError(new Error("getFastlyUrl - background option is invalid"), {
        data: {
          imageUrl,
          options,
        },
        serverOnly,
      });
    }
  }

  return `${pathName}?${qs.stringify(params, { encode: false })}`;
};

const RESIZE_METHOD_TAG_PREFIX = "cloudinary_crop_";
const getResizeMethodTag = (
  tags: string[] = []
): IOptimizeImageOptions["resizeMethod"] | undefined => {
  const resizeMethodTag =
    Array.isArray(tags) &&
    tags.find((tag) => startsWith(tag, RESIZE_METHOD_TAG_PREFIX));
  if (resizeMethodTag) {
    return resizeMethodTag.replace(
      new RegExp(`^${RESIZE_METHOD_TAG_PREFIX}`),
      ""
    ) as IOptimizeImageOptions["resizeMethod"];
  }
  return undefined;
};

//TODO: change this name to include auto cropped image.
export const isManuallyCroppedImage = (image?: Partial<IImage>) =>
  !!image && !!getCropTagParams(image);

export const isAutoCroppedApparelImage = (image?: Partial<IImage>): boolean => {
  const apparelTag = image?.tags?.find((tag) =>
    tag.startsWith(AUTO_IMAGE_CROP_APPAREL_TAG_PREFIX)
  );
  return isProductImage(image) && !!apparelTag;
};

export const isAutoCroppedSquareImage = (image?: Partial<IImage>): boolean => {
  const squareTag = image?.tags?.find((tag) =>
    tag.startsWith(AUTO_IMAGE_CROP_SQUARE_TAG_PREFIX)
  );
  return isProductImage(image) && !!squareTag;
};

const isProductImage = (image?: Partial<IImage>): boolean => {
  return (
    !!image &&
    !!image.url &&
    !!(image.tags ?? []).find((tag) => ["Hero", "Product"].includes(tag))
  );
};

export const getCropTagParams = (
  image: Partial<IImage>
): IOptimizeImageOptions["crop"] | undefined => {
  //there should only exist one crop tag
  const cropTag =
    image !== undefined &&
    image.url !== undefined &&
    Array.isArray(image.tags) &&
    image.tags
      .slice()
      // make sure manual crop takes precedence
      .sort()
      .reverse()
      .find((tag) =>
        ALL_IMAGE_CROPPING_PREFIX.find((prefix) => tag.startsWith(prefix))
      );
  if (cropTag) {
    try {
      const allCropPrefixPattern = new RegExp(
        ALL_IMAGE_CROPPING_PREFIX.join("|"),
        "g"
      );
      const crop = JSON.parse(
        cropTag.replace(allCropPrefixPattern, "")
      ) as IOptimizeImageOptions["crop"];

      if (
        crop &&
        (!crop.width || !crop.height || isNil(crop.x) || isNil(crop.y))
      ) {
        const error = new Error("getFastlyUrl - crop option is malformed");
        logError(error, {
          data: {
            image,
            crop,
          },
          fingerprint: [error.message, image.url ?? "no image url provided"],
        });
      } else {
        return crop;
      }
    } catch (error) {
      const fingerprint: string[] = [];
      if (isObject(error) && hasErrorMessage(error)) {
        fingerprint.push(error.message);
      } else {
        fingerprint.push("no error message provided");
      }

      fingerprint.push(image.url ?? "no image url provided");

      logError(
        new Error("getCropTagParams - manual image crop tag is invalid"),
        {
          data: {
            cropTag,
          },
          fingerprint,
        }
      );
    }
  }
  return undefined;
};

/**
 * Transform image urls to resize and crop images for use on the client
 *
 * All product images should be encoded using [[optimizeProductImageUrl]]
 */
export const optimizeImageUrl = (
  image: Partial<IImage>,
  isSSR: boolean,
  options: IOptimizeImageOptions = {}
): string | undefined => {
  const { serverOnly } = options;
  if (!image || !image.url) {
    logError(new Error("optimizeImageUrl - invalid ImageOptions argument"), {
      data: { image, options },
      serverOnly,
    });
    return image ? image.url : undefined;
  }

  if (isShopifyImageUrl(image.url)) {
    return getShopifyImageUrl(image.url, options);
  }

  if (!isTransformableImageUrl(image)) {
    // If not transformable short circuit
    return image.url;
  }

  const imageConfig: IOptimizeImageConfig = clone(options);

  const resizeMethodTag = getResizeMethodTag(image.tags);
  if (resizeMethodTag && !options.ignoreResizeTag) {
    imageConfig.resizeMethod = resizeMethodTag;
    if (resizeMethodTag === "pad" && !options.background) {
      imageConfig.background = "#fff";
    }
  }

  const cropTagParams = getCropTagParams(image);
  if (cropTagParams) {
    if (!imageConfig.crop?.smartSquare) {
      imageConfig.crop = cropTagParams;
    }
  }

  if (Array.isArray(image.tags) && image.tags.indexOf("prefer_lossless") >= 0) {
    imageConfig.useLossless = true;
  }

  return getFastlyUrl(image.url, imageConfig, isSSR);
};

const PRODUCT_DEFAULT_OPTIONS: IOptimizeImageOptions = {
  width: 350,
  height: 350,
  resizeMethod: "fill",
};

/**
 * # Optimize a IProduct or IProductOption IImage
 *
 * Product images have special cropping logic built into the IImage data.
 * They also default to being squared.
 * This function obeys these constraints and allows overrides if needed.
 *
 * This is the **only function** that should be used for optimizing product images.
 */
export const optimizeProductImageUrl = (
  image: Partial<IImage>,
  isSSR: boolean,
  dimension?: number,
  options: IOptimizeImageOptions = {}
): string | undefined => {
  const productImageDefaults = {
    ...(dimension ? { width: dimension, height: dimension } : {}),
    ...(options.maintainTransparency ? {} : { background: "#FFFFFF" }),
  };

  //To enable auto crop XP for all product images with auto crop tag,
  //check if tag exist first and assign setting after.
  if (isAutoCroppedSquareImage(image)) {
    //This image might have two tags: square and apparel, remove the apparel one.
    const tags = removeImageCropTag(image.tags, [
      AUTO_IMAGE_CROP_APPAREL_TAG_PREFIX,
      MANUAL_IMAGE_CROP_APPAREL_TAG_PREFIX,
    ]);
    const imageWithSquareCrop = IImage.build({ ...image, tags: tags });
    return optimizeImageUrl(
      imageWithSquareCrop,
      isSSR,
      merge({}, PRODUCT_DEFAULT_OPTIONS, productImageDefaults, options, {
        ignoreResizeTag: true,
      })
    );
  }
  if (!image || !image.url) {
    return;
  }
  //remove auto crops outside the experiment, keep manual square tag
  const tags = removeImageCropTag(image.tags, [
    AUTO_IMAGE_CROP_SQUARE_TAG_PREFIX,
    AUTO_IMAGE_CROP_APPAREL_TAG_PREFIX,
    MANUAL_IMAGE_CROP_APPAREL_TAG_PREFIX,
  ]);
  const imageWithoutAutoCrop = IImage.build({ ...image, tags: tags });
  return optimizeImageUrl(
    imageWithoutAutoCrop,
    isSSR,
    transformOptionsForCacheHits(
      merge({}, PRODUCT_DEFAULT_OPTIONS, productImageDefaults, options),
      isSSR
    )
  );
};

/** Quick method for cropping image into a rectangle  */
export const optimizeImageUrlFillRectangle = (
  image: Partial<IImage>,
  isSSR: boolean,
  width: number,
  height: number,
  options: IOptimizeImageOptions = {}
) =>
  optimizeImageUrl(image, isSSR, {
    ...options,
    width,
    height,
    resizeMethod: "fill",
  });

/** Quick method for cropping image into a square  */
export const optimizeImageUrlFillSquare = (
  image: Partial<IImage>,
  isSSR: boolean,
  dimension: number,
  options: IOptimizeImageOptions = {}
) =>
  optimizeImageUrl(image, isSSR, {
    ...options,
    width: dimension,
    height: dimension,
    resizeMethod: "fill",
  });

/** Quick method for resizing image by width */
export const optimizeImageUrlWidth = (
  image: Partial<IImage>,
  isSSR: boolean,
  width: number,
  options: IOptimizeImageOptions = {}
) =>
  optimizeImageUrl(image, isSSR, {
    ...options,
    width,
  });

/** Quick method for resizing image by height */
export const optimizeImageUrlHeight = (
  image: Partial<IImage>,
  isSSR: boolean,
  height: number,
  options: IOptimizeImageOptions = {}
) =>
  optimizeImageUrl(image, isSSR, {
    ...options,
    height,
  });

/** Quick method for getting a vertical product image
 * Vertical product images' standard aspect ratio is 3/4
 *
 * Image retains its aspect ratio while filling the entire 3:4 container, where
 * dimension is the width of the container. If the aspect ratio of the image is
 * not 3:4, the image will be cropped to fit.
 */
const VERTICAL_ASPECT_RATIO = 3 / 4;
export const optimizeProductImageUrlFillVertical = (
  image: Partial<IImage>,
  isSSR: boolean,
  width: number,
  options: IOptimizeImageOptions = {}
) => {
  const productImageDefaults = {
    ...{ width: width, height: width / VERTICAL_ASPECT_RATIO },
    ...(options.maintainTransparency ? {} : { background: "#FFFFFF" }),
  };
  //To enable auto crop XP for all product images with auto crop tag,
  //check if tag exist first and assign setting after.
  if (isAutoCroppedApparelImage(image)) {
    //This image might have two tags: square and apparel, remove the square one.
    const tags = removeImageCropTag(image.tags, [
      AUTO_IMAGE_CROP_SQUARE_TAG_PREFIX,
      MANUAL_IMAGE_CROP_TAG_PREFIX,
    ]);
    const imageWithApparelCrop = IImage.build({ ...image, tags: tags });
    return optimizeImageUrl(
      imageWithApparelCrop,
      isSSR,
      merge({}, PRODUCT_DEFAULT_OPTIONS, productImageDefaults, options, {
        ignoreResizeTag: true,
      })
    );
  }
  //remove auto crops outside the experiment, keep manual apparel crop
  const tags = removeImageCropTag(image.tags, [
    AUTO_IMAGE_CROP_SQUARE_TAG_PREFIX,
    AUTO_IMAGE_CROP_APPAREL_TAG_PREFIX,
    MANUAL_IMAGE_CROP_TAG_PREFIX,
  ]);

  const imageWithoutAutoCrop = IImage.build({ ...image, tags: tags });
  const doesExistManualApparelTag = tags.find((tag) =>
    tag.startsWith(MANUAL_IMAGE_CROP_APPAREL_TAG_PREFIX)
  );

  if (doesExistManualApparelTag) {
    return optimizeImageUrl(
      imageWithoutAutoCrop,
      isSSR,
      transformOptionsForCacheHits(
        merge({}, PRODUCT_DEFAULT_OPTIONS, productImageDefaults, options, {
          ignoreResizeTag: true,
        }),
        isSSR
      )
    );
  }
  return optimizeProductImageUrl(imageWithoutAutoCrop, isSSR, width, {
    ...options,
    height: width / VERTICAL_ASPECT_RATIO,
    resizeMethod: "fill",
    ignoreResizeTag: true,
  });
};

function multiplyDprAndRoundUp(value: number, dpr: number): number {
  const TINY_IMAGE_SIZE = 60;
  if (value * dpr <= TINY_IMAGE_SIZE) {
    /*
     * Return a tiny image size to support incremental loading (ie. displaying
     * a low-resolution image while the full image is downloading).
     */
    return TINY_IMAGE_SIZE;
  }

  const MULTIPLE = 360;
  dpr = Math.min(3, Math.max(1, dpr));
  return Math.round(Math.ceil((value * dpr) / MULTIPLE) * MULTIPLE);
}

function transformOptionsForCacheHits(
  options: IOptimizeImageOptions,
  isSSR: boolean
): IOptimizeImageOptions {
  if (!options.serverOnly) {
    assignOptimizeImageUrlForCacheHit();
    assignOptimizeImageUrlForCacheHitBrand();
  }

  if (
    !getSettingOptimizeImageUrlForCacheHit() &&
    !getSettingOptimizeImageUrlForCacheHitBrand()
  ) {
    return options;
  }

  const dpr = getDpr(options, isSSR);

  let { width, height } = options;
  if (width && height) {
    const ratio = height / width;
    width = multiplyDprAndRoundUp(width, dpr);
    height = Math.round(width * ratio);
  } else if (width) {
    width = multiplyDprAndRoundUp(width, dpr);
  } else if (height) {
    height = multiplyDprAndRoundUp(height, dpr);
  }

  return {
    ...options,
    width,
    height,
    dprOverride: 1,
  };
}

function getDpr(options: IOptimizeImageConfig, isSSR: boolean): number {
  if (options.dprOverride != null && options.dprOverride > 0) {
    return options.dprOverride;
  } else if (isSSR) {
    return 1;
  } else {
    return defaultDPR;
  }
}
