import { StoreType } from "@faire/retailer-shared/lib/StoreType";
import { useQuery as useFirstOrderIncentiveQuery } from "@faire/web-api--source/api/first-order-incentives/first-order-incentive-data/get-hooks";
import { IFaireMoney } from "@faire/web-api--source/indigofair/data/IFaireMoney";
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";
import { IOffer } from "@faire/web-api--source/indigofair/data/IOffer";
import isEmpty from "lodash/isEmpty";
import { useMemo } from "react";

import { useIsLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";

import { mapStyleQuizAnswerToStoreType } from "./mapStyleQuizAnswerToStoreType";

export interface IFormattedIncentiveProps {
  expiresAt: number | undefined;
  incentiveAmount: IFaireMoney | undefined;
  incentiveMinimum: IFaireMoney | undefined;
  incentiveMaxOfferAmount: IFaireMoney | undefined;
  incentivePercent: number;
  // adding a temporary state here to hopefully replace incentivePercent
  incentiveBps: number | undefined;
  incentiveOfferName: keyof typeof IOffer.RetailerOfferName | undefined;
  incentiveOfferState: keyof typeof IOffer.State | undefined;
  desktopModalImage?: IImage;
  mobileModalImage?: IImage;
  hasFreeShipping?: boolean;
  storeTypeWithVerticalizedOther?: StoreType;
}

export const useFirstOrderIncentiveStore = () => {
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const { data, isLoading } = useFirstOrderIncentiveQuery({
    enabled: isLoggedInRetailer,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  // Need to check for hasPlacedFirstOrder to prevent showing the banner after the first order is placed
  const firstOrderIncentiveDiscountData = useMemo(() => {
    if (isLoading || !data || isEmpty(data)) {
      return;
    }

    return data;
  }, [data, isLoading]);

  const storeTypeWithVerticalizedOther = mapStyleQuizAnswerToStoreType(
    firstOrderIncentiveDiscountData?.store_type_for_modal_text
  );

  const formattedIncentiveData: IFormattedIncentiveProps = useMemo(() => {
    return {
      expiresAt: firstOrderIncentiveDiscountData?.expires_at,
      incentiveAmount: firstOrderIncentiveDiscountData?.discount_amount,
      incentiveMinimum: firstOrderIncentiveDiscountData?.minimum_amount,
      incentiveMaxOfferAmount:
        firstOrderIncentiveDiscountData?.max_offer_amount,
      // Divide by 100 to convert to readable percentage (eg; 50%)
      incentivePercent:
        (firstOrderIncentiveDiscountData?.discount_bps || 0) / 100,
      incentiveBps: firstOrderIncentiveDiscountData?.discount_bps,
      incentiveOfferName: firstOrderIncentiveDiscountData?.offer_name,
      incentiveOfferState: firstOrderIncentiveDiscountData?.offer_state,
      desktopModalImage: firstOrderIncentiveDiscountData?.modal_image_desktop,
      mobileModalImage: firstOrderIncentiveDiscountData?.modal_image_mobile,
      hasFreeShipping: firstOrderIncentiveDiscountData?.has_free_shipping,
      storeTypeWithVerticalizedOther: storeTypeWithVerticalizedOther,
    };
  }, [firstOrderIncentiveDiscountData, storeTypeWithVerticalizedOther]);

  const isRetailerFoiEligible =
    firstOrderIncentiveDiscountData?.is_retailer_eligible;

  const incentiveType = firstOrderIncentiveDiscountData?.offer_name;

  const shouldShowShopifyIncentive =
    incentiveType === "SHOPIFY_FIRST_ORDER_INCENTIVE_OFFER";

  const isFirstOrderForFaireDirectIncentive = [
    "FAIRE_DIRECT_SIGN_UP",
    "FAIRE_DIRECT_RETAILER_FOI_ORDER_CANCELLATION",
  ].includes(firstOrderIncentiveDiscountData?.source ?? "");

  // There are 2 versions of auto extend:
  // 1. default auto extendb
  // 2. personalized auto extend (high value retailer incentive)
  const isFirstOrderIncentiveAutoExtendEnabled =
    incentiveType === "FIRST_ORDER_INCENTIVE_AUTO_EXTEND_V1" ||
    incentiveType === "PERSONALIZED_FIRST_ORDER_INCENTIVE_V2_AUTO_EXTEND";

  // There are 2 versions of last chance:
  // 1. default last chance
  // 2. personalized last chance (high value retailer incentive)
  const isLastChanceIncentive =
    incentiveType === "LAST_CHANCE_FIRST_ORDER_INCENTIVE_V1" ||
    incentiveType === "PERSONALIZED_LAST_CHANCE_FIRST_ORDER_INCENTIVE_V1";

  const hasFirstOrderIncentive = !!firstOrderIncentiveDiscountData;

  const isMaximalistFoi = !!formattedIncentiveData?.hasFreeShipping;

  return {
    isLoading,
    isRetailerFoiEligible,
    formattedIncentiveData,
    isLastChanceIncentive,
    isFirstOrderIncentiveAutoExtendEnabled,
    isFirstOrderForFaireDirectIncentive,
    hasFirstOrderIncentive,
    shouldShowShopifyIncentive,
    isMaximalistFoi,
  };
};
