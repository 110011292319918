import qs from "query-string";

import { FAIRE_DIRECT_BRAND_QUERY_PARAM } from "./FAIRE_DIRECT_BRAND_QUERY_PARAM";

export const getFaireDirectBrandAliasFromQueryParam = (
  search: string
): string | undefined => {
  const query = qs.parse(search);

  const faireDirectBrandAlias = query[FAIRE_DIRECT_BRAND_QUERY_PARAM];

  if (typeof faireDirectBrandAlias === "string") {
    return faireDirectBrandAlias;
  }

  return;
};
