import { FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_VISIT_STARTED_THRESHOLD_MILLIS } from "@faire/web-api--source/indigofair/settings/FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_VISIT_STARTED_THRESHOLD_MILLIS";

import { useSetting } from "./__internal__/useSetting";

const DEFAULT_THRESHOLD_MILLIS = 2 * 60 * 1000;

export const useSettingFaireDirectRecurringMarketplaceModalVisitThreshold =
  (): number =>
    useSetting(
      FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_VISIT_STARTED_THRESHOLD_MILLIS,
      DEFAULT_THRESHOLD_MILLIS
    );
