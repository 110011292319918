"use client";

import * as H from "history";
import * as React from "react";
import type { LinkProps as ReactLinkProps } from "react-router-dom";

export type ILinkComponentProps = Omit<ReactLinkProps, "to"> & {
  to: H.LocationDescriptor;
};

export type RouterLike = {
  push: (href: string) => void;
  replace: (href: string) => void;
};

export interface IRoutingContext {
  locale?: string | undefined;
  canonicalLocale?: string;
  localeCountryUrlPrefix?: string;
  allowedRobotLocales?: (string | undefined)[];
  faireDirectBrandAlias?: string | undefined;
  faireDirectBrandToken?: string | undefined;
  Link?: React.ComponentType<ILinkComponentProps>;
  useSearchParams?: () => string;
  useRouterOrHistory?: () => RouterLike;
  usePathname?: () => string;
}

export const RoutingContext: React.Context<IRoutingContext> =
  React.createContext({
    locale: undefined,
    canonicalLocale: "en-US",
    localeCountryUrlPrefix: "",
    allowedRobotLocales: [],
    faireDirectBrandAlias: undefined,
    faireDirectBrandToken: undefined,
    Link: undefined,
    useSearchParams: undefined,
    useRouterOrHistory: undefined,
    usePathname: undefined,
  } as IRoutingContext);
