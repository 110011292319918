import { optimizeImageUrl } from "@faire/web--source/common/images/optimizeImages";
import { useIsSSR } from "@faire/web--source/common/server-side-rendering/isSSR";
import { Grid } from "@faire/web--source/slate/Layout";
import { Spacer } from "@faire/web--source/slate/spacing";
import { Typography } from "@faire/web--source/slate/Typography";
import { useResponsiveValue } from "@faire/web--source/ui/hooks/useResponsiveValue";
import { IClickInfo } from "@faire/web-api--source/indigofair/data/IClickInfo";
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";
import { path as c1CategoryPath } from "@faire/web-api--source/routes/category/c1CategoryUrlId";
import { path as c2CategoryPath } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId";
import React from "react";
import styled from "styled-components";

import { AspectRatioPicture } from "@faire/retailer/components/Image/AspectRatioPicture";
import {
  TrackedLink,
  TrackedLinkContext,
} from "@faire/retailer/components/TrackedLink";
import { getIsApparelRetailer } from "@faire/retailer/serialized-data/getIsApparelRetailer";
import { useCategoryStore } from "@faire/retailer/stores/domain/CategoryStore";
import { findActiveCategoryByUrlIds } from "@faire/retailer/stores/domain/CategoryStore/findByUrlId";

interface IProps {
  // container name usef for TrackedLinkContext
  trackingContainerName: string;
}

export const ShopOurCategories = React.forwardRef<HTMLDivElement, IProps>(
  ({ trackingContainerName }, ref) => {
    const { categories } = useCategoryStore();

    const filteredTiles = CATEGORI_TILES(getIsApparelRetailer()).filter(
      (tile) => {
        const [c1] = tile.categoryUrlIds;
        const category = findActiveCategoryByUrlIds(
          tile.categoryUrlIds,
          categories
        );

        return c1 !== undefined && category !== undefined;
      }
    );

    return (
      <TrackedLinkContext.Provider
        value={{
          type: IClickInfo.Type.BRAND,
          containerName: trackingContainerName,
        }}
      >
        <Grid
          ref={ref}
          gap="2x"
          columns={{ mobileAndAbove: 1, tabletAndAbove: 2, desktopAndAbove: 3 }}
          columnTemplate={(c: number) => `repeat(${c}, minmax(0, 1fr));`}
          style={{ width: "100%" }}
        >
          {filteredTiles.slice(0, MAX_TILES).map((categoryTile) => (
            <CategoryTile
              {...categoryTile}
              key={categoryTile.categoryUrlIds.join("|")}
            />
          ))}
        </Grid>
      </TrackedLinkContext.Provider>
    );
  }
);

interface ICategoryTile {
  categoryUrlIds: string[];
  backgroundImage: IImage;
}

const CategoryTile: React.FC<ICategoryTile> = ({
  categoryUrlIds,
  backgroundImage,
}) => {
  const isSSR = useIsSSR();
  const { categories } = useCategoryStore();
  const ratio = useResponsiveValue({
    mobileAndAbove: 358 / 194,
  });

  const [c1, c2] = categoryUrlIds;

  const category = findActiveCategoryByUrlIds(categoryUrlIds, categories);

  if (!c1 || !category) {
    return null;
  }

  const categoryPath = c2 ? c2CategoryPath(c1, c2) : c1CategoryPath(c1);
  const displayName = category.unique_display_name ?? category.name;
  const token = category.path_identifier;

  return (
    <CategoryLink to={categoryPath} token={token} key={token}>
      <AspectRatioPicture $width="100%" $ratio={ratio}>
        <img
          src={optimizeImageUrl(backgroundImage, isSSR, {
            width: 750,
          })}
          alt={displayName}
        />
      </AspectRatioPicture>
      <Spacer height={12} />
      <Typography>{displayName}</Typography>
    </CategoryLink>
  );
};

const ACCESSORIES_CATEGORY_URL_ID = "Accessories";
const APPAREL_CATEGORY_URL_ID = "Apparel";
const BEAUTY_AND_WELLNESS_CATEGORY_URL_ID = "Beauty & Wellness";
const FOOD_AND_DRINK_CATEGORY_URL_ID = "Food & Drink";
const FOOTWEAR_CATEGORY_URL_ID = "Footwear";
const HOME_DECOR_CATEGORY_URL_ID = "Home Decor";
const JEWELRY_CATEGORY_URL_ID = "Jewelry";
const KIDS_AND_BABY_CATEGORY_URL_ID = "Kids & Baby";
const MEN_CATEGORY_URL_ID = "Men";
const PAPER_AND_NOVELTY_CATEGORY_URL_ID = "Paper & Novelty";
const PETS_CATEGORY_URL_ID = "Pets";
const WOMEN_CATEGORY_URL_ID = "Women";

const CATEGORI_TILES = (
  isApparelRetailer: boolean | undefined
): ICategoryTile[] => [
  {
    categoryUrlIds: [HOME_DECOR_CATEGORY_URL_ID],
    backgroundImage: IImage.build({
      url: "https://cdn.faire.com/fastly/a9ad00b7bedd2795b68d88d2dcd5bf1373c0bc57c3a13c5cdecae7ebc429d8a9.png",
    }),
  },
  {
    categoryUrlIds: [PAPER_AND_NOVELTY_CATEGORY_URL_ID],
    backgroundImage: IImage.build({
      url: "https://cdn.faire.com/fastly/94042a923ecba7a0ba22b7d8639267588f04415cff740bdc71cab584cf639ada.png",
    }),
  },
  {
    categoryUrlIds: [FOOD_AND_DRINK_CATEGORY_URL_ID],
    backgroundImage: IImage.build({
      url: "https://cdn.faire.com/fastly/c76ebda181f5b0da56b55fdb3c435602bb117c6a033dec23468dde152b322bd9.png",
    }),
  },
  {
    categoryUrlIds: isApparelRetailer
      ? [APPAREL_CATEGORY_URL_ID, WOMEN_CATEGORY_URL_ID]
      : [WOMEN_CATEGORY_URL_ID],
    backgroundImage: isApparelRetailer
      ? IImage.build({
          url: "https://cdn.faire.com/fastly/dcab16a6c7ba06726eac3d2b744fc2790e8c96b21ea362486c798807951d7c84.png",
        })
      : IImage.build({
          url: "https://cdn.faire.com/fastly/dd03f8041beb9c9b6588617a134846518f6854c03cd15a5ee7801a88c3ae93cb.png",
        }),
  },
  {
    categoryUrlIds: [BEAUTY_AND_WELLNESS_CATEGORY_URL_ID],
    backgroundImage: IImage.build({
      url: "https://cdn.faire.com/fastly/0f6454bc68b163e21f7ab3ab0a4b5b49954fbd1f0b3ce86c726849c2c838acce.png",
    }),
  },
  {
    categoryUrlIds: [JEWELRY_CATEGORY_URL_ID],
    backgroundImage: IImage.build({
      url: "https://cdn.faire.com/fastly/12aa88d24b3c82d386933195c463092f14679e9d7af51db48b8daf8039d47cd1.png",
    }),
  },
  {
    categoryUrlIds: [KIDS_AND_BABY_CATEGORY_URL_ID],
    backgroundImage: IImage.build({
      url: "https://cdn.faire.com/fastly/1ce238d213d0538acf06f7fb24df88ce36c87fc53e084e17f59847a001aa117c.png",
    }),
  },
  {
    categoryUrlIds: [PETS_CATEGORY_URL_ID],
    backgroundImage: IImage.build({
      url: "https://cdn.faire.com/fastly/f8fa728a8c118c594c30c1d55671bb965cf783d6fcb144a8fd2a34b036abcc1d.png",
    }),
  },
  {
    categoryUrlIds: [MEN_CATEGORY_URL_ID],
    backgroundImage: IImage.build({
      url: "https://cdn.faire.com/fastly/9a990aea3c0d8f01781e21a551eeb5c1cfa6a513f5f92d4e41a7b42ea1aa6ff9.png",
    }),
  },
  {
    categoryUrlIds: isApparelRetailer
      ? [ACCESSORIES_CATEGORY_URL_ID, WOMEN_CATEGORY_URL_ID]
      : [],
    backgroundImage: IImage.build({
      url: "https://cdn.faire.com/fastly/b2af1aa5c9ebd2e97cf367f6e978cabfdcc553328018c09412a6792a5f8ec185.png",
    }),
  },
  {
    categoryUrlIds: isApparelRetailer
      ? [FOOTWEAR_CATEGORY_URL_ID, WOMEN_CATEGORY_URL_ID]
      : [],
    backgroundImage: IImage.build({
      url: "https://cdn.faire.com/fastly/693f5a0e2ef616996a5f8c73826beeb1116f03394ce500d27dd7aa469f9e2c30.png",
    }),
  },
];

const MAX_TILES = 6;

const CategoryLink = styled(TrackedLink)`
  display: flex;
  flex-direction: column;
  border-bottom: none;
`;
