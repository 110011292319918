import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { maybeSSR } from "@faire/web--source/common/server-side-rendering/isSSR";
import * as React from "react";

export const SERIALIZED_PREFETCHED_DATA_IDENTIFIER = "prefetchedData";
export const prefetchMethodsSymbol = Symbol.for("prefetchMethodsSymbol");

/**
 * This factory function returns a Typescript decorator to mark a component
 * method to be executed in SSR
 *
 * @example
 * ```typescript
 * type PrefetchedTypes = {
 *   timestamp: number;
 * };
 *
 * const SSRPrefetch = SSRPrefetchFactory<PrefetchedTypes>();
 *
 * class MyComponent extends React.Component {
 *   @SSRPrefetch<'timestamp'>()
 *   async timestamp() {
 *     return { timestamp: Date.now() };
 *   }
 * }
 * ```
 */
export const SSRPrefetchFactory =
  <PrefetchedTypes>() =>
  <Key extends keyof PrefetchedTypes, T = PrefetchedTypes[Key]>() =>
  (
    target: React.Component<any>,
    _: string,
    descriptor: TypedPropertyDescriptor<() => Promise<Record<Key, T>>>
  ) => {
    // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
    if (!target[prefetchMethodsSymbol]) {
      // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      target[prefetchMethodsSymbol] = [];
    }

    // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
    target[prefetchMethodsSymbol].push(descriptor.value!);
  };

/**
 * This factory function returns a helper for extracting (i.e. reading and deleting)
 * any prefetched data for the given key. Prefetched data is data that might be already
 * available on the client, from a prefetch pass on the server during a server-side render.
 *
 * NOTE: his method deletes the value after reading it, since the
 * prefetched data is only intended for a single use (to rehydrate the component's state).
 * If the value needs to be cached, please do it at the call site.
 *
 * @example
 * ```
 * type PrefetchedTypes = {
 *   timestamp: number;
 * };
 *
 * const readPrefetchedData = readPrefetchedDataFactory<PrefetchedTypes>()
 *
 * class Foo {
 *   init() {
 *     const prefetchedTimeStamp = readPrefetchedData('timestamp');
 *     this.timestamp = prefetchedTimeStamp ?? Date.now();
 *   }
 * }
 * ```
 */
export const readPrefetchedDataFactory =
  <PrefetchedTypes>() =>
  <Key extends keyof PrefetchedTypes>(
    key: Key,
    options?: {
      keep?: boolean;
    }
  ): PrefetchedTypes[Key] | undefined => {
    const prefetched = getGlobalProperty<PrefetchedTypes>(
      SERIALIZED_PREFETCHED_DATA_IDENTIFIER
    );
    const dataToReturn = prefetched?.[key];
    // Notice that during SSR, we shouldn't delete the data otherwise the
    // HTML returned to the client side will have an empty prefetchedData
    // field. This will make the hydration renders results that are inconsistent
    // with SSR result thus cause the page to flash and lead to bugs
    const shouldKeepData = options?.keep || maybeSSR();
    if (!shouldKeepData) {
      deletePrefetchedData<PrefetchedTypes, Key>(key);
    }
    return dataToReturn;
  };

/**
 * Helper for deleting a key from the prefetched data, to avoid reusing the data
 * multiple times. This prevents bugs where data is expected to be re-fetched.
 */
const deletePrefetchedData = <
  PrefetchedTypes,
  Key extends keyof PrefetchedTypes
>(
  key: Key
): void => {
  const prefetched = getGlobalProperty<PrefetchedTypes>(
    SERIALIZED_PREFETCHED_DATA_IDENTIFIER
  );
  if (prefetched?.[key]) {
    delete prefetched[key];
  }
};
