"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const Secondary: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_MARKET_JULY_2023].decorative5;
  return (
    <SVGIcon
      width="199"
      height="175"
      viewBox="0 0 199 175"
      fill="none"
      {...rest}
    >
      <path
        d="M126.225 174.182C106.497 174.182 49.6733 174.182 49.6733 174.182C49.6733 174.182 8 176.551 8 150.691C8.00001 124.83 45.7023 122.985 45.7023 122.985L45.7023 113.768C45.7023 113.768 10.1305 110.073 10.1305 93.461C10.1305 82.9768 7.39468 67.6008 45.7023 67.6008L45.7023 63.8838C45.7023 63.8838 16.5137 65.7335 16.5137 43.5771C16.5137 21.4208 22.897 14 76.0934 14L125.037 14C125.037 14 191 14 191 38.0149C191 62.0298 156.953 65.7203 156.953 65.7203L156.953 71.2604C156.953 71.2604 191 71.2605 191 93.4257C191 115.591 161.21 113.732 161.21 113.732L161.21 122.967C161.21 122.967 191 121.122 191 148.828C191 182.073 145.954 174.182 126.225 174.182Z"
        fill={iconColor}
      />
    </SVGIcon>
  );
};
