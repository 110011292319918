import { isTestEnv } from "@faire/web--source/common/user-agent/isTestEnv";

import { getGlobalLookup } from "./lookup";
import { getAllOverriddenGlobalProperties } from "./override";

/**
 * Gets the given property name from the global page data.
 * Note that it supports nested/chained properties, e.g. "foo.bar".
 */
export function getGlobalProperty<T>(name: string): T | undefined;
export function getGlobalProperty<T>(name: string, defaultValue: T): T;
export function getGlobalProperty<T>(
  name: string,
  defaultValue?: T
): T | undefined {
  const allOverriddenPageData = getAllOverriddenGlobalProperties();
  // Check if direct reference is mocked out
  if (name in allOverriddenPageData) {
    const overrideValue = allOverriddenPageData[name];
    if (!isTestEnv()) {
      // eslint-disable-next-line no-console
      console.log(
        `Using global property override for ${name} of ${JSON.stringify(
          overrideValue
        )}`
      );
    }
    return overrideValue ?? defaultValue;
  }

  const overrideProperties: Partial<T> = {};
  const allOverriddenPageDataKeys = Object.keys(allOverriddenPageData);
  for (const key of allOverriddenPageDataKeys) {
    const [parent, child] = key.split(".");
    // Check if child reference is mocked out
    if (name === parent && child !== undefined) {
      const overrideValue = allOverriddenPageData[key];
      if (!isTestEnv()) {
        // eslint-disable-next-line no-console
        console.log(
          `Using global property override for ${key} of ${JSON.stringify(
            overrideValue
          )}`
        );
      }

      overrideProperties[child as keyof T] = overrideValue;
    }
  }

  const value: T | undefined = getGlobalLookup()(name) ?? defaultValue;

  if (
    typeof process !== "undefined" &&
    process.env.NEXT_PUBLIC_DEBUG_GLOBAL_LOOKUP &&
    !globalsToSkip.includes(name)
  ) {
    // eslint-disable-next-line no-console
    console.log(
      `Global Lookup: ${name}`,
      new Error().stack?.split("\n").slice(1, 6)
    );
  }

  if (
    typeof value === "object" &&
    Object.keys(overrideProperties).length !== 0
  ) {
    return { ...value, ...overrideProperties };
  }

  return value;
}

// Globals that we don't want to log - they are either expected (like window)
// or are too noisy to give any insight.
const globalsToSkip = [
  "window",
  "document",
  "location",
  "navigator",
  "needsHydration",
  "envName",
  "faire.app",
  "isRobot",
  "isPrerender",
  "settingsValues",
  "user",
  "retailer",
];
