import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'sessions-localusertime-view';

/**
 * This event will track the time on the user&#39;s browser.

Param1: Local user browser time.
*/
export function trackSessionsLocalusertimeView(
  customParameterToken: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: { customParameterToken },
  });
}
