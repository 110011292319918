import { SettingConst } from '../../types';

/**
 * Controls whether an audience is shown modals that promote app download
 */
export const APP_MIGRATION_AVAILABLE =
  'APP_MIGRATION_AVAILABLE' as SettingConst<
    'APP_MIGRATION_AVAILABLE',
    boolean,
    'RETAILER'
  >;
