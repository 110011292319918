import { throttleIfPossible } from "@faire/web--source/common/globals/throttleIfPossible";
import { MIN_WIDTH_BREAKPOINTS } from "@faire/web--source/common/media";
import { useIsSSR } from "@faire/web--source/common/server-side-rendering/isSSR";
import { getEstimatedViewportType } from "@faire/web--source/common/user-agent/getEstimatedViewportType";
import { getWindowWidth } from "@faire/web--source/ui/hooks/useObservableWindow";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useHandleResize } from "./__internal__/useHandleResize";

export type MinWidthBreakpoints = {
  readonly tablet: number;
  readonly desktop: number;
  readonly xlarge: number;
  readonly xxlarge?: number;
};

const DEFAULT_MIN_WIDTH_BREAKPOINTS: MinWidthBreakpoints = {
  tablet: MIN_WIDTH_BREAKPOINTS.TABLET,
  desktop: MIN_WIDTH_BREAKPOINTS.DESKTOP,
  xlarge: MIN_WIDTH_BREAKPOINTS.XLARGE,
  xxlarge: MIN_WIDTH_BREAKPOINTS.XXLARGE,
};

export type Viewport = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isXLargeDesktop: boolean;
  isXXLargeDesktop: boolean;
  isTabletAndAbove: boolean;
  isDesktopAndAbove: boolean;
  isXLargeAndAbove: boolean;
  isTabletAndBelow: boolean;
};

const getViewportValues = (
  width: number,
  minWidthBreakpoints: MinWidthBreakpoints
) => {
  const isMobile = width < minWidthBreakpoints.tablet;
  const isTablet =
    minWidthBreakpoints.tablet <= width && width < minWidthBreakpoints.desktop;
  const isDesktop =
    minWidthBreakpoints.desktop <= width && width < minWidthBreakpoints.xlarge;
  const isXLargeDesktop =
    width >= minWidthBreakpoints.xlarge &&
    width < (minWidthBreakpoints.xxlarge ?? MIN_WIDTH_BREAKPOINTS.XXLARGE);
  const isXXLargeDesktop =
    width >= (minWidthBreakpoints.xxlarge ?? MIN_WIDTH_BREAKPOINTS.XXLARGE);
  const isTabletAndAbove = width >= minWidthBreakpoints.tablet;
  const isDesktopAndAbove = width >= minWidthBreakpoints.desktop;
  const isXLargeAndAbove = width >= minWidthBreakpoints.xlarge;
  const isTabletAndBelow = !isDesktopAndAbove;
  return {
    isMobile,
    isTablet,
    isDesktop,
    isXLargeDesktop,
    isXXLargeDesktop,
    isTabletAndAbove,
    isDesktopAndAbove,
    isXLargeAndAbove,
    isTabletAndBelow,
  };
};

export const useViewport = (
  minWidthBreakpoints: MinWidthBreakpoints = DEFAULT_MIN_WIDTH_BREAKPOINTS
): Viewport => {
  const isSSR = useIsSSR();

  const initialViewport = useMemo(() => {
    if (isSSR) {
      return getViewportValues(
        getEstimatedViewportType().width,
        minWidthBreakpoints
      );
    }

    return getViewportValues(getWindowWidth(), minWidthBreakpoints);
  }, [isSSR, minWidthBreakpoints]);

  const [isMobile, setIsMobile] = useState(initialViewport.isMobile);
  const [isTablet, setIsTablet] = useState(initialViewport.isTablet);
  const [isDesktop, setIsDesktop] = useState(initialViewport.isDesktop);
  const [isXLargeDesktop, setIsXLargeDesktop] = useState(
    initialViewport.isXLargeDesktop
  );
  const [isXXLargeDesktop, setIsXXLargeDesktop] = useState(
    initialViewport.isXXLargeDesktop
  );
  const [isTabletAndAbove, setIsTabletAndAbove] = useState(
    initialViewport.isTabletAndAbove
  );
  const [isDesktopAndAbove, setIsDesktopAndAbove] = useState(
    initialViewport.isDesktopAndAbove
  );
  const [isXLargeAndAbove, setIsXLargeAndAbove] = useState(
    initialViewport.isXLargeAndAbove
  );
  const [isTabletAndBelow, setIsTabletAndBelow] = useState(
    initialViewport.isTabletAndBelow
  );

  const handleResize = useCallback(() => {
    const width = getWindowWidth();
    const viewport = getViewportValues(width, minWidthBreakpoints);
    setIsMobile(viewport.isMobile);
    setIsTablet(viewport.isTablet);
    setIsDesktop(viewport.isDesktop);
    setIsXLargeDesktop(viewport.isXLargeDesktop);
    setIsXXLargeDesktop(viewport.isXXLargeDesktop);
    setIsTabletAndAbove(viewport.isTabletAndAbove);
    setIsDesktopAndAbove(viewport.isDesktopAndAbove);
    setIsXLargeAndAbove(viewport.isXLargeAndAbove);
    setIsTabletAndBelow(viewport.isTabletAndBelow);
  }, [minWidthBreakpoints]);

  // SSR correction
  useEffect(() => {
    handleResize();
  }, [handleResize]);

  const throttledHandleResize = useMemo(
    () => throttleIfPossible(handleResize, 200),
    [handleResize]
  );

  useHandleResize(throttledHandleResize);

  return useMemo(
    () => ({
      isMobile,
      isTablet,
      isDesktop,
      isXLargeDesktop,
      isXXLargeDesktop,
      isTabletAndAbove,
      isDesktopAndAbove,
      isXLargeAndAbove,
      isTabletAndBelow,
    }),
    [
      isDesktop,
      isDesktopAndAbove,
      isMobile,
      isTablet,
      isTabletAndAbove,
      isTabletAndBelow,
      isXLargeDesktop,
      isXXLargeDesktop,
      isXLargeAndAbove,
    ]
  );
};
