enum TOKEN_TYPES {
  ADDRESS = "a",
  BRAND = "b",
  BRAND_INVOICE = "bi",
  BRAND_ORDER = "bo",
  BRAND_REVIEW = "br",
  CART = "c",
  CART_ITEM = "ci",
  MESSENGER_CONVERSATION = "mc",
  MESSENGER_MESSAGE = "mm",
  MEMBERSHIP = "ms",
  DOCUMENT = "d",
  EXTERNAL = "ex",
  FLASH_SALE = "fs",
  FLASH_SALE_ITEM = "fsi",
  HOST_PROCESS = "hp",
  IMAGE = "i",
  INSTRUMENT_LINK = "il",
  INVENTORY_ITEM = "it",
  ORDER = "o",
  ORDER_ITEM = "oi",
  OUTGOING_EMAIL = "oe",
  PAYMENT = "pay",
  PAYMENT_PLAN = "ppl",
  PRODUCT = "p",
  PRODUCT_COLLECTION = "pc",
  PRODUCT_COLLECTION_ITEM = "pci",
  PRODUCT_OPTION = "po",
  REFUND = "rfd",
  RETAILER = "r",
  RETURN_ORDER = "ro",
  SCHEDULED_PICKUP = "sp",
  SHIPMENT = "s",
  SHIPPING_OPTION = "so",
  USER = "u",
}

const TOKEN_REGEX = /^(\w{1,4})_.*$/;

export const getNormalizedUrl = (path: string) =>
  path
    .split("/")
    .map((segment) => {
      for (const token in TOKEN_TYPES) {
        if (!TOKEN_TYPES.hasOwnProperty(token)) {
          continue;
        }
        if (
          segment.startsWith(
            `${
              // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
              TOKEN_TYPES[token]
            }_`
          )
        ) {
          return `{${token}}`;
        }
      }

      const match = segment.match(TOKEN_REGEX);

      if (match) {
        return `{${match[1]?.toUpperCase() ?? ""}}`;
      }
      return segment;
    })
    .join("/")
    .substring(0, 255);
