import { ITimestamp } from "@faire/web-api--source/indigofair/rfc3339/ITimestamp";
import dateFnsFormat from "date-fns/format";

import {
  maybeConvertToGibberish,
  getDateOrMillis,
  getUserLocale,
} from "./utils";

/**
 * Locale-aware version of date-fns/format
 *
 * @deprecated use {@link intlDate}
 */
export const localDate = (
  date: ITimestamp | Date | number,
  format: AllowedFormats,
  userLocale?: Locale
): string =>
  maybeConvertToGibberish(
    dateFnsFormat(getDateOrMillis(date), format, {
      locale: userLocale ?? getUserLocale(),
    })
  );

/**
 * 'P' types are localized dates. Prefer these if you don't want to exclude the year
 * The other allowed types omit the year but show the month in words, making them
 * understandable in other locales.
 *
 * https://date-fns.org/v2.19.0/docs/format
 */
// prettier-ignore
type localizedDateFormats =
  | "P"     // 04/29/1453
  | "PP"    // Apr 29, 1453
  | "PPP"   // April 29th, 1453
  | "PPPP"; // Friday, April 29th, 1453

// prettier-ignore
type localizedTimeFormats =
  | "p"     // 12:00 AM
  | "pp"    // 12:00:00 AM
  | "ppp"   // 12:00:00 AM GMT+2
  | "pppp"; // 12:00:00 AM GMT+02:00

// Pp, PPpp, PPPppp, PPPPpppp
// Any localized date time starting with PPP or PPPP will concatentate
// date and time with the word `at` between the two
// e.g PPPppp April 29th, 1453 at 12:00:00 AM GMT+2

// Any localized date time starting with P or PP will be concatenate
// date and time with a comma
// e.g. Pp	04/29/1453, 12:00 AM
type localizedDateTimeFormats =
  `${localizedDateFormats}${localizedTimeFormats}`;

// prettier-ignore
export type AllowedFormats =
  | "ccc, MMM d"    // Sun, Jan 1
  | "MMM"           // Jan
  | "MMM d"         // Jan 1
  | "MMM dd"        // Jan 01
  | "MMM do"        // Jan 1st, Jan 2nd, ..., Jan 31st
  | "MMM yyyy"      // Jan 2021
  | "MMMM"          // Name of month January, February, ..., December
  | "MMMM d"        // January 1
  | "MMMM dd"       // January 01
  | "MMMM do"       // January 1st
  | "MMMM yyyy"     // January 2021
  | "MMM do, yyyy"  // Jan 1st, 2021
  | "MMMM do, yyyy" // January 1st, 2021
  | "d"             // Day of month 1, 2, ..., 31
  | "do"            // Ordinal Day of month 1st, 2nd, ..., 31st
  | localizedDateFormats
  | localizedTimeFormats
  | localizedDateTimeFormats;
