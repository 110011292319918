import { SettingConst } from '../../types';

/**
 * Seasonal warehouse sale start time
 */
export const SEASONAL_WAREHOUSE_SALE_START_AT =
  'SEASONAL_WAREHOUSE_SALE_START_AT' as SettingConst<
    'SEASONAL_WAREHOUSE_SALE_START_AT',
    number,
    'RETAILER'
  >;
