import { Language } from "@faire/web-api--source/indigofair/languages/Language";

/**
 * @deprecated use #getTwoLetterLanguageCode
 */
export const THREE_TO_TWO_LETTER_LANGUAGE_CODE: {
  [K in
    | Exclude<Language, Language.LANGUAGE_UNKNOWN | Language.NOR>
    | "JPN"]: string;
} = {
  [Language.ENG]: "EN",
  [Language.FRA]: "FR",
  [Language.DEU]: "DE",
  [Language.ITA]: "IT",
  [Language.SPA]: "ES",
  [Language.SWE]: "SV",
  [Language.DAN]: "DA",
  [Language.POR]: "PT",
  [Language.NLD]: "NL",
  [Language.GIBBERISH]: "XA",
  [Language.STRING_ID]: "XC",
  // Adding to ease testing
  JPN: "JA",
} as const;

export function getTwoLetterLanguageCode<
  K extends keyof typeof THREE_TO_TWO_LETTER_LANGUAGE_CODE
>(key: K): (typeof THREE_TO_TWO_LETTER_LANGUAGE_CODE)[K];
export function getTwoLetterLanguageCode(
  key: string
):
  | (typeof THREE_TO_TWO_LETTER_LANGUAGE_CODE)[keyof typeof THREE_TO_TWO_LETTER_LANGUAGE_CODE]
  | undefined;

export function getTwoLetterLanguageCode(key: string) {
  return THREE_TO_TWO_LETTER_LANGUAGE_CODE[
    key as keyof typeof THREE_TO_TWO_LETTER_LANGUAGE_CODE
  ];
}
