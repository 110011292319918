import { getNavigator } from "@faire/web--source/common/globals/getNavigator";
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { logError } from "@faire/web--source/common/logging";
import { IWebSocketMessage } from "@faire/web-api--source/indigofair/websockets/IWebSocketMessage";

import { IMessageChannel, Message, MessageSubscriber } from "./MessageChannel";

export type NotificationChannelSocketListenerHandle = number;

export class WebSocketMessageChannel implements IMessageChannel {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  public static readonly PING_FREQUENCY = 15_000;
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  public static readonly MISSED_PONG_RECONNECT_THRESHOLD = 3;

  readonly retryDelay = 10_000 + 10_000 * Math.random();

  isConnected: boolean = false;
  retryingConnection: boolean = false;
  retryConnectionIntervalFactor: number = 1;
  socket: WebSocket;

  listenerCounter: NotificationChannelSocketListenerHandle = 0;
  listeners = new Map<number, MessageSubscriber>();

  pingInterval: number | undefined;
  receivedPong: boolean = false;
  missedPongCount: number = 0;

  private isClosed = false;

  constructor(private websocketUrl: string) {
    this.socket = new WebSocket(websocketUrl);
    this.startConnection();

    // When we disconnect from the internet and reconnect, retry connection if not connected
    getWindow()?.addEventListener("online", () => {
      if (!this.isConnected) {
        this.maybeRetryConnectionWithBackoff();
      }
    });
  }

  subscribe = (listener: MessageSubscriber) => {
    // Start the connect if this is the first time someone is subscribing
    if (this.listenerCounter === 0) {
      this.startConnection();
    }

    // Increment the counter to make a new handle
    this.listenerCounter = this.listenerCounter + 1;
    this.listeners.set(this.listenerCounter, listener);

    listener({ type: "CONNECTION", isConnected: this.isConnected });

    return this.listenerCounter;
  };

  unsubscribe = (listenerHandle: number) => {
    this.listeners.delete(listenerHandle);
  };

  close = () => {
    this.isClosed = true;
    this.socket?.close();
  };

  private retryConnection = () => {
    // Disconnect if still not closed
    if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
      this.socket.close();
    }

    this.socket = new WebSocket(this.websocketUrl);
    this.startConnection();
  };

  private startConnection = () => {
    this.dispatchConnectionStatus();

    const currentSocket = this.socket;

    currentSocket.onopen = () => {
      this.isConnected = true;
      this.dispatchConnectionStatus();
      this.retryingConnection = false;
      this.retryConnectionIntervalFactor = 1;

      // Set the onclose behavior only once we have a real connection to avoid endlessly invoking it
      currentSocket.onclose = () => {
        if (this.socket === currentSocket) {
          this.isConnected = false;
          this.dispatchConnectionStatus();
          this.maybeRetryConnectionWithBackoff();
        }
      };
    };

    currentSocket.onerror = () => this.maybeRetryConnectionWithBackoff();

    currentSocket.onmessage = (message) => {
      if (this.socket === currentSocket) {
        try {
          this.handleMessage(JSON.parse(message.data));
        } catch (err) {
          logError(
            `Error handling message: ${JSON.stringify(
              err
            )}. Message: ${message}`
          );
        }
      }
    };
    this.startPingTimer();
  };

  private handleMessage = (message: Message) => {
    if (message.type === IWebSocketMessage.Type.ECHO_RESPONSE) {
      this.receivedPong = true;
      this.missedPongCount = 0;
      this.isConnected = true;
      this.dispatchConnectionStatus();
    } else {
      this.dispatchMessage(message);
    }
  };

  private dispatchMessage = (message: Message) => {
    this.listeners.forEach((l) => l(message));
  };

  private dispatchConnectionStatus = () => {
    this.dispatchMessage({ type: "CONNECTION", isConnected: this.isConnected });
  };

  private startPingTimer = () => {
    if (this.pingInterval === undefined) {
      this.receivedPong = true;
      this.missedPongCount = 0;
      this.pingInterval = getWindow()?.setInterval(() => {
        // In case it's closing/closed, handle it as a timeout
        if (
          this.socket.readyState === WebSocket.CLOSING ||
          this.socket.readyState === WebSocket.CLOSED
        ) {
          this.handleTimeout();
        }

        if (!this.receivedPong) {
          this.missedPongCount++;
          if (
            this.missedPongCount >
            WebSocketMessageChannel.MISSED_PONG_RECONNECT_THRESHOLD
          ) {
            this.handleTimeout();
          }
        }

        // Ping if its still connected
        if (this.isConnected) {
          this.receivedPong = false;
          this.socket.send(createPingMessage());
        } else {
          this.stopPingTimer();
        }
      }, WebSocketMessageChannel.PING_FREQUENCY);
    }
  };

  private stopPingTimer = () => {
    if (this.pingInterval !== undefined) {
      getWindow()?.clearInterval(this.pingInterval);
      this.pingInterval = undefined;
    }
  };

  private handleTimeout = () => {
    // Stop pinging the server
    this.stopPingTimer();

    // Let everyone else know we've disconnected
    this.isConnected = false;
    this.dispatchConnectionStatus();

    // We'll want to try again after a number of milliseconds if we're connected to the internet
    if (getNavigator()?.onLine) {
      this.maybeRetryConnectionWithBackoff();
    }
  };

  private maybeRetryConnectionWithBackoff = () => {
    if (this.isClosed) {
      return;
    }

    if (!this.retryingConnection) {
      // Wait a random amount before first attempt to avoid slamming the backend all at once
      setTimeout(() => this.retryConnectionWithBackoff(), this.retryDelay);
      this.retryingConnection = true;
    }
  };

  private retryConnectionWithBackoff = () => {
    if (!this.isConnected) {
      this.retryConnectionIntervalFactor *= 2;
      this.retryConnection();
      setTimeout(
        () => this.retryConnectionWithBackoff(),
        this.retryConnectionIntervalFactor * this.retryDelay
      );
    }
  };
}

const createPingMessage = () => {
  const ping: Partial<IWebSocketMessage> = {
    type: IWebSocketMessage.Type.ECHO_REQUEST,
    echo_string: "ping",
  };
  return JSON.stringify(ping);
};
