import { getWindowOrThrow } from "@faire/web--source/common/globals/getWindow";
import { createContextStore } from "@faire/web--source/ui/hooks/ContextStore";
import React, { useCallback, useEffect } from "react";

const { useStore, Provider } = createContextStore({
  height: 0,
  shouldKeepTopNavVisible: false,
  bannerHeight: 0,
  isBannerInView: false,
});

const Synchronizer: React.FC = () => {
  const [{ bannerHeight }, setStore] = useStore(["bannerHeight"]);

  /**
   * Called on scroll events to determine if the banner is in view.
   */
  const handleScrollEvent = useCallback(() => {
    const scrollY = getWindowOrThrow().scrollY;
    if (bannerHeight - scrollY <= 0) {
      setStore(({ isBannerInView }) =>
        isBannerInView ? { isBannerInView: false } : {}
      );
    } else {
      setStore(({ isBannerInView }) =>
        isBannerInView ? {} : { isBannerInView: true }
      );
    }
  }, [bannerHeight, setStore]);

  useEffect(() => {
    getWindowOrThrow().addEventListener("scroll", handleScrollEvent);
    return () => {
      getWindowOrThrow().removeEventListener("scroll", handleScrollEvent);
    };
  }, [handleScrollEvent]);

  return null;
};

export const useTopOfPageContextStore = useStore;

export const TopOfPageContextStoreProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Provider>
      {children}
      <Synchronizer />
    </Provider>
  );
};
