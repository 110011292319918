import { BatchEventsManager } from "@faire/web--source/common/batch-events/BatchEventsManager";
import { forceReload } from "@faire/web--source/common/forceReload";
import { getDocument } from "@faire/web--source/common/globals/getDocument";
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { trackFrontendApisReleaseReloaded } from "@faire/web-api--source/events/frontendApis/actionUnknown/releaseReloaded";

const MAXIMUM_SESSION_LENGTH_HOURS = 168;
const checkForExpiredSession = async (
  numHours: number = MAXIMUM_SESSION_LENGTH_HOURS
) => {
  const MAXIMUM_SESSION_LENGTH_MILLISECONDS: number = numHours * 60 * 60 * 1000;
  if (
    new Date().getTime() - websiteLoadTime >
    MAXIMUM_SESSION_LENGTH_MILLISECONDS
  ) {
    try {
      trackFrontendApisReleaseReloaded();
      await BatchEventsManager.get().flush();
    } finally {
      forceReload();
    }
  }
};

const websiteLoadTime = new Date().getTime();

// Default is 7 days
export const reloadExpiredSessions = (
  numHours: number = MAXIMUM_SESSION_LENGTH_HOURS
) => {
  // Mobile does not fire a visibility changed event when loading an app for the first time,
  // so we need to fire an event on focus
  getWindow()?.addEventListener("focus", () =>
    checkForExpiredSession(numHours)
  );
  // Mobile browsers only fire a focus event when the app is closed. Since we would like to fire
  // events whenever people return to the tab or switch to the app, we need to listen for visibility changes
  getDocument()?.addEventListener("visibilitychange", () =>
    checkForExpiredSession(numHours)
  );
  // In rare cases, a session may stay hidden for a long time and won't trigger the visibility change event
  // nor the focus event, so we need a general timout check to force reload the page after numHours hours. Otherwise,
  // the stale session may stay alive and keep sending API requests with outdated parameters or data.
  getWindow()?.setTimeout(() => {
    checkForExpiredSession(numHours);
  }, numHours * 60 * 60 * 1000);
};
