import { pathMatchesRoute } from "@faire/web--source/common/routes/RouteMatcher";
import { isNotUndefined } from "@faire/web--source/common/typescriptUtils";
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";
import { IProductPageResponse } from "@faire/web-api--source/indigofair/data/IProductPageResponse";
import { route as homeRoute } from "@faire/web-api--source/routes/root";
import { QueryParameters as SignUpQueryParameters } from "@faire/web-api--source/routes/welcome/r/personal";
import { Location } from "history";

import { SignUpType } from "@faire/retailer/lib/popSignUp";
import { ElevatePortalStore } from "@faire/retailer/stores/domain/ElevatePortalStore";

import { AppSignUpPageResults } from "./AppSignUpPageResultsProvider";

export const enum AppSignUpModalVariant {
  DEFAULT = "default",
  AUTO = "auto",
  AUTO_WITH_IMAGE = "auto_with_image",
  PRODUCT = "product",
  FACEBOOK = "facebook",
  FAIRE_DIRECT = "faire_direct",
}

export function determineAppSignUpModalVariant(
  signUpParams: SignUpQueryParameters,
  location: Location,
  productPageResponse: IProductPageResponse | undefined
): AppSignUpModalVariant {
  if (
    ElevatePortalStore.get().isFaireDirectActiveInLoggedOutWithPageLocation(
      location,
      productPageResponse
    )
  ) {
    return AppSignUpModalVariant.FAIRE_DIRECT;
  } else if (
    signUpParams.signUp === SignUpType.FACEBOOK &&
    isNotUndefined(signUpParams.refP)
  ) {
    return AppSignUpModalVariant.FACEBOOK;
  } else if (
    signUpParams.signUp === SignUpType.PRODUCT &&
    isNotUndefined(signUpParams.product)
  ) {
    return AppSignUpModalVariant.PRODUCT;
  } else if (signUpParams.signUp === SignUpType.AUTO) {
    const isHomepage = pathMatchesRoute(location, homeRoute);
    return isHomepage
      ? AppSignUpModalVariant.AUTO
      : AppSignUpModalVariant.AUTO_WITH_IMAGE;
  } else {
    return AppSignUpModalVariant.DEFAULT;
  }
}

export function isFaireDirectVariant(variant: AppSignUpModalVariant): boolean {
  return variant === AppSignUpModalVariant.FAIRE_DIRECT;
}

export function isAutoSignUpVariant(variant: AppSignUpModalVariant): boolean {
  return (
    variant === AppSignUpModalVariant.AUTO ||
    variant === AppSignUpModalVariant.AUTO_WITH_IMAGE
  );
}

type ModalImageryType =
  | "image-from-product-token"
  | "featured-product-image"
  | "no-image";
export function getModalImageryType(
  variant: AppSignUpModalVariant
): ModalImageryType {
  if (variant === AppSignUpModalVariant.AUTO_WITH_IMAGE) {
    return "featured-product-image";
  } else if (
    variant === AppSignUpModalVariant.FACEBOOK ||
    variant === AppSignUpModalVariant.PRODUCT
  ) {
    return "image-from-product-token";
  } else {
    return "no-image";
  }
}

export function getModalImageryFromPageResults(
  modalImageryType: ModalImageryType,
  pageResults: AppSignUpPageResults,
  productToken: string | undefined
): IImage | undefined {
  if (modalImageryType === "featured-product-image") {
    if (pageResults.productTiles) {
      return pageResults.productTiles[0]?.best_image;
    } else if (pageResults.suppliersPageBrands) {
      return pageResults.suppliersPageBrands[0]?.image;
    } else if (pageResults.pdpProduct) {
      return pageResults.pdpProduct.visuals?.images[0];
    } else if (pageResults.productBasedCollection) {
      return pageResults.productBasedCollection.image;
    }
  } else if (modalImageryType === "image-from-product-token" && productToken) {
    if (pageResults.productTiles) {
      return pageResults.productTiles?.find(
        (p) => p?.product?.token === productToken
      )?.best_image;
    } else if (pageResults.suppliersPageBrands) {
      const brand = pageResults.suppliersPageBrands.find(
        (brand) =>
          Object.values(brand.image_tokens_to_product_tokens).indexOf(
            productToken
          ) >= 0
      );
      if (brand) {
        return brand.product_images.find(
          (image) =>
            brand.image_tokens_to_product_tokens[image.token || ""] ===
            productToken
        );
      }
    } else if (pageResults.pdpProduct) {
      if (pageResults.pdpProduct.token === productToken) {
        return pageResults.pdpProduct.visuals?.images[0];
      }
    }
  }

  return undefined;
}
