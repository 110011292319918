import { getWindow } from "@faire/web--source/common/globals/getWindow";
import {
  isNotUndefined,
  isPromise,
} from "@faire/web--source/common/typescriptUtils";
import { ButtonOnClick } from "@faire/web--source/slate/Button/types";
import React, { useEffect, useRef, useState } from "react";

const SHOW_LOADING_SPINNER_DELAY = 1000;

export const useShouldShowSpinner = (
  initialDisabled: boolean,
  initialLoading: boolean,
  initialOnClick?: ButtonOnClick
) => {
  const [internalLoading, setInternalLoading] = useState(false);
  const [loadingDelayExpired, setLoadingDelayExpired] = useState(false);

  const asyncLoadingTimeout = useRef<number | undefined>(undefined);
  const isMounted = useRef(true);

  const isDisabled = initialDisabled || initialLoading || internalLoading;

  useEffect(() => {
    // Set isMounted to true when the component mounts and to false when it unmounts.
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      if (isNotUndefined(asyncLoadingTimeout.current)) {
        getWindow()?.clearTimeout(asyncLoadingTimeout.current);
      }
    };
  }, []);

  /**
   * If provided onClick returns a promise this will automatically set the button into a loading state.
   */
  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>
  ) => {
    if (isDisabled) {
      e.preventDefault();
      return;
    }

    const promise = initialOnClick?.(e);

    if (isPromise(promise)) {
      setInternalLoading(true);
      asyncLoadingTimeout.current = getWindow()?.setTimeout(() => {
        if (isMounted.current) {
          setLoadingDelayExpired(true);
        }
      }, SHOW_LOADING_SPINNER_DELAY);

      try {
        await promise;
      } finally {
        getWindow()?.clearTimeout(asyncLoadingTimeout.current);
        asyncLoadingTimeout.current = undefined;

        if (isMounted.current) {
          setInternalLoading(false);
          setLoadingDelayExpired(false);
        }
      }
    }
  };

  // only show spinner if loading prop passed or we are loading due to onClick promise and loading delay has passed.
  const shouldShowSpinner =
    initialLoading || (internalLoading && loadingDelayExpired);

  return { isDisabled, handleClick, shouldShowSpinner };
};
