import { SettingConst } from '../../types';

/**
 * If enabled, we prevent showing Faire Direct sign-up modals and logic for marketplace brands
 * https:www.notion.so/faire/FD-Signup-From-Marketplace-da843cae334d46f5b5a9e50bdaad65bd
 */
export const FAIRE_DIRECT_SIGN_UP_FROM_MARKETPLACE_240809 =
  'FAIRE_DIRECT_SIGN_UP_FROM_MARKETPLACE_240809' as SettingConst<
    'FAIRE_DIRECT_SIGN_UP_FROM_MARKETPLACE_240809',
    boolean,
    'RETAILER'
  >;
