import { WebApiError } from "@faire/web-api--source/WebApiError";

import { StrictLocalizeFunction } from "./localization";
import { isError } from "./typescriptUtils";

const pleaseTryAgain = (strictLocalize: StrictLocalizeFunction) =>
  strictLocalize({
    defaultMessage: "Oops, something went wrong! Please try again.",
    description: {
      text: "This error states that an unexpected error occurred",
    },
  });

const pleaseContactSupport = (strictLocalize: StrictLocalizeFunction) =>
  strictLocalize({
    defaultMessage: "Oops, something went wrong! Please contact support.",
    description: {
      text: "This error states that an unexpected error occurred",
    },
  });

const pleaseRefreshThePage = (strictLocalize: StrictLocalizeFunction) =>
  strictLocalize({
    defaultMessage: "Oops, something went wrong! Please refresh the page.",
    description: {
      text: "This error states that an unexpected error occurred",
    },
  });

const pleaseTryAgainOrContactSupport = (
  strictLocalize: StrictLocalizeFunction
) =>
  strictLocalize({
    defaultMessage:
      "Oops, something went wrong! Please try again or contact support.",
    description: {
      text: "This error states that an unexpected error occurred",
    },
  });

const pleaseRefreshAndTryAgain = (strictLocalize: StrictLocalizeFunction) =>
  strictLocalize({
    defaultMessage: "Oops, something went wrong! Please refresh and try again.",
    description: {
      text: "This error states that an unexpected error occurred. User is told to refresh the page and try again",
    },
  });

export enum ErrorMessages {
  PLEASE_TRY_AGAIN,
  PLEASE_CONTACT_SUPPORT,
  PLEASE_REFRESH_THE_PAGE,
  PLEASE_TRY_AGAIN_OR_CONTACT_SUPPORT,
  PLEASE_REFRESH_AND_TRY_AGAIN,
}

export const getErrorMessage = (
  strictLocalize: StrictLocalizeFunction,
  messageType?: ErrorMessages
): string => {
  let errorMessage;
  switch (messageType) {
    case ErrorMessages.PLEASE_TRY_AGAIN:
      errorMessage = pleaseTryAgain(strictLocalize);
      break;
    case ErrorMessages.PLEASE_CONTACT_SUPPORT:
      errorMessage = pleaseContactSupport(strictLocalize);
      break;
    case ErrorMessages.PLEASE_REFRESH_THE_PAGE:
      errorMessage = pleaseRefreshThePage(strictLocalize);
      break;
    case ErrorMessages.PLEASE_TRY_AGAIN_OR_CONTACT_SUPPORT:
      errorMessage = pleaseTryAgainOrContactSupport(strictLocalize);
      break;
    case ErrorMessages.PLEASE_REFRESH_AND_TRY_AGAIN:
      errorMessage = pleaseRefreshAndTryAgain(strictLocalize);
      break;
    default:
      errorMessage = pleaseTryAgain(strictLocalize);
  }
  return errorMessage;
};

export const getWebApiErrorMessage = (
  error: unknown,
  strictLocalize: StrictLocalizeFunction,
  fallbackErrorMessage?: string
) => {
  let message = fallbackErrorMessage ?? getErrorMessage(strictLocalize);

  /**
   * OR operater ("||") used here instead of null coalescing operator ("??")
   * to handle empty strings
   * */
  if (error instanceof WebApiError) {
    message =
      error.responseData.localized_message ||
      error.responseData.message ||
      message;
  } else if (isError(error)) {
    message = error.message || message;
  }
  return message;
};
