import { IFaireMoney } from "@faire/web-api--source/indigofair/data/IFaireMoney";
import sumBy from "lodash/sumBy";
import uniq from "lodash/uniq";

import { logAssert } from "./logging";
import { getCentsInLocalCurrency, moneyOrZero } from "./money";
import { isNotUndefined } from "./typescriptUtils";

export class MoneyMath {
  /**
   * Multiplies IFaireMoney by a given multiplier.
   *
   * This function will multiply both fields (amount_cents | original_amount_cents) independently,
   * and will ignore of the value is undefined
   * @param money IFaireMoney
   * @param multiplier
   * @returns IFaireMoney
   */
  static multiply = (
    money: IFaireMoney,
    multiplier: number = 1
  ): IFaireMoney => {
    const { amount_cents, currency, original_amount_cents, original_currency } =
      money;
    let newAmount: number | undefined;
    let newOriginalAmount: number | undefined;

    if (amount_cents !== undefined) {
      newAmount = amount_cents * multiplier;
    }

    if (original_amount_cents !== undefined) {
      newOriginalAmount = original_amount_cents * multiplier;
    }

    return IFaireMoney.build({
      amount_cents: newAmount,
      currency,
      original_amount_cents: newOriginalAmount,
      original_currency,
    });
  };

  /**
   * Sums IFaireMoney's amount_cents only (original_amount_cents and original_currency will be undefined)
   *
   * This function ignores undefined values, but will **throw** an Error
   * if all defined moneys don't have the same currency value.
   * @param variadic of IFaireMoney
   */
  static sum = (...args: Array<IFaireMoney | undefined>): IFaireMoney => {
    const definedMoneys = args.filter(isNotUndefined);

    const uniqCurrencies = uniq(definedMoneys.map((money) => money.currency));

    if (uniqCurrencies.length > 1) {
      throw new Error(
        `Summing money is currently only possible if all currencies are the same. Currencies provided: ${uniqCurrencies}`
      );
    }

    const total = sumBy(definedMoneys, (money) => money.amount_cents ?? 0);

    return IFaireMoney.build({
      currency: uniqCurrencies[0],
      amount_cents: total,
    });
  };

  /**
   * Sums IFaireMoney's amount_cents only (original_amount_cents and original_currency will be undefined)
   * after converting the amount_cents to the currency of the first IFaireMoney
   * This function ignores undefined values, but will **throw** an Error
   * if all defined moneys don't have the same currency value.
   * this function is useful when there's a need to show the total of a list of moneys ensuring that
   * the total matches the expected sum after appling the rounding strategy
   * @param variadic of IFaireMoney
   */
  static sumAfterConversion = (
    ...args: Array<IFaireMoney | undefined>
  ): IFaireMoney => {
    const definedMoneys = args.filter(isNotUndefined);

    const uniqCurrencies = uniq(definedMoneys.map((money) => money.currency));

    if (uniqCurrencies.length > 1) {
      throw new Error(
        `Summing money is currently only possible if all currencies are the same. Currencies provided: ${uniqCurrencies}`
      );
    }

    const total = sumBy(
      definedMoneys,
      (money) => getCentsInLocalCurrency(money) ?? 0
    );

    return IFaireMoney.build({
      currency: uniqCurrencies[0],
      amount_cents: total,
    });
  };

  /**
   * Subtracts IFaireMoney's amount_cents only (original_amount_cents and original_currency will be undefined)
   * Subtracts the second argument from the first (minuend - subtrahend = result)
   *
   * This function will **throw** an Error if its arguments have different currency values
   * Treats an undefined amount_cents as 0
   *
   * @param minuend IFaireMoney
   * @param subtrahends variadic IFaireMoney
   * @returns IFaireMoney
   */
  static subtract = (
    minuend: IFaireMoney,
    ...subtrahends: IFaireMoney[]
  ): IFaireMoney => {
    const definedSubtrahends = subtrahends.filter(isNotUndefined);
    const uniqSubrahendCurrencies = uniq(
      definedSubtrahends.map((money) => money.currency)
    );

    if (
      uniqSubrahendCurrencies.length > 1 ||
      minuend.currency !== uniqSubrahendCurrencies[0]
    ) {
      throw new Error(
        `Subtracting money is currently only possible if the currencies are the same. Minuend currency: ${minuend?.currency}. Subtrahend currencies: ${uniqSubrahendCurrencies}`
      );
    }

    let result = minuend.amount_cents ?? 0;
    definedSubtrahends.forEach((subtrahend) => {
      result -= subtrahend.amount_cents ?? 0;
    });

    return IFaireMoney.build({
      amount_cents: result,
      currency: minuend.currency,
    });
  };

  /**
   * Comparison. Returns true if a < b, false otherwise.
   * Checks for currency match.
   */
  static lessThan = (a: IFaireMoney, b: IFaireMoney): boolean => {
    logAssert(
      a.currency === b.currency,
      `Comparing IFaireMoney objects of different currencies: ${a.currency} and ${b.currency}`
    );
    logAssert(
      a.amount_cents !== undefined && b.amount_cents !== undefined,
      "One or both of the amount_cents to be compared is undefined"
    );

    return (a.amount_cents ?? 0) < (b.amount_cents ?? 0);
  };

  /**
   * Comparison. Returns true if money < 0, false otherwise.
   */
  static lessThanZero = (money: IFaireMoney): boolean =>
    this.lessThan(money, moneyOrZero(undefined, money.currency));

  /**
   * Comparison. Returns true if a > b, false otherwise.
   * Checks for currency match.
   */
  static greaterThan = (a: IFaireMoney, b: IFaireMoney): boolean => {
    logAssert(
      a.currency === b.currency,
      `Comparing IFaireMoney objects of different currencies: ${a.currency} and ${b.currency}`
    );
    logAssert(
      a.amount_cents !== undefined && b.amount_cents !== undefined,
      "One or both of the amount_cents to be compared is undefined"
    );

    return (a.amount_cents ?? 0) > (b.amount_cents ?? 0);
  };

  /**
   * Comparison. Returns true if money > 0, false otherwise.
   */
  static greaterThanZero = (money: IFaireMoney): boolean =>
    this.greaterThan(money, moneyOrZero(undefined, money.currency));

  /**
   * Comparison. Returns true if a == b, false otherwise.
   * Checks for currency match.
   */
  static isEqual = (a: IFaireMoney, b: IFaireMoney): boolean => {
    logAssert(
      a.amount_cents !== undefined && b.amount_cents !== undefined,
      "One or both of the amount_cents to be compared is undefined"
    );

    return a.amount_cents === b.amount_cents && a.currency === b.currency;
  };

  /**
   * Comparison. Returns true if money == 0, false otherwise.
   */
  static isEqualToZero = (money: IFaireMoney): boolean =>
    this.isEqual(money, moneyOrZero(undefined, money.currency));

  /**
   * Comparison. Returns true if a != b, false otherwise.
   * Checks for currency match.
   */
  static notEqual = (a: IFaireMoney, b: IFaireMoney): boolean => {
    logAssert(
      a.amount_cents !== undefined && b.amount_cents !== undefined,
      "One or both of the amount_cents to be compared is undefined"
    );

    return a.amount_cents !== b.amount_cents || a.currency !== b.currency;
  };

  /**
   * Comparison. Returns true if money != 0, false otherwise.
   */
  static notEqualToZero = (money: IFaireMoney): boolean =>
    this.notEqual(money, moneyOrZero(undefined, money.currency));

  /**
   * Comparison. Returns true if a <= b, false otherwise.
   * Checks for currency match.
   */
  static lessOrEqual = (a: IFaireMoney, b: IFaireMoney): boolean => {
    logAssert(
      a.currency === b.currency,
      `Comparing IFaireMoney objects of different currencies: ${a.currency} and ${b.currency}`
    );
    logAssert(
      a.amount_cents !== undefined && b.amount_cents !== undefined,
      "One or both of the amount_cents to be compared is undefined"
    );
    return (a.amount_cents ?? 0) <= (b.amount_cents ?? 0);
  };

  /**
   * Comparison. Returns true if money <= 0, false otherwise.
   */
  static lessOrEqualToZero = (money: IFaireMoney): boolean =>
    this.lessOrEqual(money, moneyOrZero(undefined, money.currency));

  /**
   * Comparison. Returns true if a >= b, false otherwise.
   * Checks for currency match.
   */
  static greaterOrEqual = (a: IFaireMoney, b: IFaireMoney): boolean => {
    logAssert(
      a.currency === b.currency,
      `Comparing IFaireMoney objects of different currencies: ${a.currency} and ${b.currency}`
    );
    logAssert(
      a.amount_cents !== undefined && b.amount_cents !== undefined,
      "One or both of the amount_cents to be compared is undefined"
    );
    return (a.amount_cents ?? 0) >= (b.amount_cents ?? 0);
  };

  /**
   * Comparison. Returns true if money >= 0, false otherwise.
   */
  static greaterOrEqualToZero = (money: IFaireMoney): boolean =>
    this.greaterOrEqual(money, moneyOrZero(undefined, money.currency));
}
