import { logError, logWarning } from "@faire/web--source/common/logging";
import {
  isLoggingSettingUsageEnabled,
  trackSettingsAndExperiment,
} from "@faire/web--source/common/serialized-setting-tracker/SerializedSettingTracker";
import { IFrontendSetting } from "@faire/web--source/common/settings/declarations/IFrontendSetting";
import batchAssignSettingRequest from "@faire/web-api--source/api/v2/setting/batch_assign/post";
import type { IBatchAssignSettingRequest } from "@faire/web-api--source/faire/settings_boss/IBatchAssignSettingRequest";
import { SettingConst } from "@faire/web-api--source/types";

export type AssignmentCache = Set<string>;
export type AssignmentAccumulator = Map<
  string,
  IBatchAssignSettingRequest.IAssignment
>;
export type BatchAssignSettingOptions = {
  submitImmediately?: boolean;
};

export const BATCH_SETTING_THROTTLE_MS = 1000;

/**
 * Assignment logic shared between the hook implementation and the legacy implementation of setting assignment.
 */
export const sharedAssignSetting = async (
  settingOrKey: SettingConst | IFrontendSetting,
  options: BatchAssignSettingOptions | undefined,
  cache: AssignmentCache,
  accumulator: AssignmentAccumulator,
  throttledBatchAssignSetting: typeof unthrottledBatchAssignSetting
) => {
  const key =
    typeof settingOrKey === "string" ? settingOrKey : settingOrKey.name;

  if (accumulator.has(key) || cache.has(key)) {
    return;
  }
  accumulator.set(key, {
    setting: key,
    created_at: Date.now(),
  });

  // Don't throttle when testing, adds unnecessary complexity
  if (
    options?.submitImmediately ||
    (process.env.NODE_ENV === "test" && !process.env.TEST_ASSIGNMENTS)
  ) {
    return unthrottledBatchAssignSetting(cache, accumulator);
  }

  // We shouldn't await this bulkAssignment because it will get throttled
  return throttledBatchAssignSetting(cache, accumulator);
};

export const unthrottledBatchAssignSetting = async (
  cache: AssignmentCache,
  accumulator: AssignmentAccumulator
) => {
  const assignments = Array.from(accumulator.values());
  accumulator.clear();

  if (assignments.length === 0) {
    return;
  }

  const keys = assignments.map((i) => i.setting as string);

  try {
    // we add to our cache before submitting the job to prevent a race condition
    keys.forEach((key) => cache.add(key));
    await batchAssignSettingRequest({ assignments });

    if (isLoggingSettingUsageEnabled()) {
      trackSettingsAndExperiment(keys, {
        isAssignment: true,
      });
    }
  } catch (error) {
    logWarning(`Failed to assign ${keys.join(", ")}`);
    logError(error);
    // if our request failed we assume the assignment did not work so we clear the keys
    keys.forEach((key) => cache.delete(key));
  }
};
