import { ITimestampRange } from '../../indigofair/data/ITimestampRange';
import { SettingConst } from '../../types';

/**
 * TBD
 */
export const SPECIAL_HOLIDAY_NET90_TIMERANGE =
  'SPECIAL_HOLIDAY_NET90_TIMERANGE' as SettingConst<
    'SPECIAL_HOLIDAY_NET90_TIMERANGE',
    ITimestampRange,
    'RETAILER' | 'BRAND_WIDGET'
  >;
