import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import type { IRouteMetadata } from '../../Route';

/**
 * Generated from Jersey path "/tos-insider"
 *
 * Product Area: TERMS_OF_SERVICE
 */
export const route = '/tos-insider';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/tos-insider',
  productArea: 'TERMS_OF_SERVICE',
  pod: 'FAIRE_POD_FINANCIAL_PRODUCTS',
  pillar: 'FAIRE_PILLAR_RETAILER_TOOLS_AND_PROGRAMS',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path() {
  const compiledPath = toPath({});
  return compiledPath;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (): string => {
  return faireBaseUrl(3000) + path();
};

export default path;
