import { logError } from "@faire/web--source/common/logging";
import { makeObservable } from "@faire/web--source/common/makeObservable";
import getProducts from "@faire/web-api--source/api/product/get-messenger-previews/post";
import sendMessengerMessageV3 from "@faire/web-api--source/api/v3/messenger/send-message/post";
import { IMessageContents } from "@faire/web-api--source/faire/messenger/IMessageContents";
import { ISendMessengerMessageRequestV3 } from "@faire/web-api--source/faire/messenger/ISendMessengerMessageRequestV3";
import { IBasicUser } from "@faire/web-api--source/indigofair/data/IBasicUser";
import { IGetProductsPreviewForMessengerResponse } from "@faire/web-api--source/indigofair/data/IGetProductsPreviewForMessengerResponse";
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";
import { IMessengerConversation } from "@faire/web-api--source/indigofair/data/IMessengerConversation";
import { IMessengerMessage } from "@faire/web-api--source/indigofair/data/IMessengerMessage";
import { IUser } from "@faire/web-api--source/indigofair/data/IUser";
import { UserRole } from "@faire/web-api--source/indigofair/data/UserRole";
import clone from "lodash/clone";
import compact from "lodash/compact";
import { action, computed, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";

import { ImageFile } from "../types";

import { ConversationModel } from "./ConversationModel";
import { uploadImageFile } from "./uploadImageFile";

export interface IImageOrFile {
  file?: ImageFile;
  image?: IImage;
}

export class MessageModel {
  static createNewMessage = (
    conversation: ConversationModel,
    message: Partial<IMessengerMessage>,
    images: readonly File[] | undefined,
    products: string[] = []
  ) =>
    new MessageModel(
      conversation,
      {
        ...message,
        author_token: conversation.userToken,
        created_at: Date.now(),
      },
      images,
      products
    );

  @observable
  private data!: Partial<IMessengerMessage>;
  @observable
  private _error: any;
  @observable
  private _images?: readonly File[];
  @observable
  productTokens: string[] = [];
  @observable
  private productPromises?: IPromiseBasedObservable<IGetProductsPreviewForMessengerResponse>;

  constructor(
    private conversation: ConversationModel,
    data: Partial<IMessengerMessage>,
    images: readonly File[] | undefined = undefined,
    products: string[] = []
  ) {
    makeObservable(this);
    this.updateMessage(data, images, products);
  }

  @action fetchProducts = () => {
    if (!this.productTokens.length) {
      return;
    }
    this.productPromises = fromPromise(
      getProducts({
        product_tokens: this.productTokens,
      })
    );
  };

  @computed
  get products(): IGetProductsPreviewForMessengerResponse.IProductPreview[] {
    if (this.isPending) {
      return [];
    }

    return (
      this.productPromises?.case({
        fulfilled: (response) => response.product_previews,
      }) ?? []
    );
  }

  @action updateMessage = (
    messageData: Partial<IMessengerMessage>,
    images: readonly File[] | undefined,
    products: string[] = []
  ) => {
    this.data = messageData;
    this._images = images;
    this._error = undefined;
    this.productTokens = clone(products);
  };

  /*
   * Getters
   */

  get token(): string {
    return this.data.token!;
  }

  get conversationMessageAuthors(): IBasicUser[] {
    return this.conversation.messageAuthors;
  }

  get error(): any {
    return this._error;
  }

  get conversationToken(): string {
    return this.conversation.token;
  }

  get authorToken(): string | undefined {
    return this.data.author_token;
  }

  @computed
  get isFromOtherUser(): boolean {
    return this.data.author_token === this.conversation.otherPartyToken;
  }

  @computed
  get isFromCurrentUser(): boolean {
    return this.data.author_token === this.conversation.userToken;
  }

  @computed
  get isFromSameRetailerAsCurrentUser(): boolean {
    // Whether the message is from a retailer team that we are a part of.
    return (
      this.conversation.isCurrentUserARetailer &&
      !!this.conversationMessageAuthors
        .find((author) => this.authorToken === author.token)
        ?.roles?.includes(UserRole.RETAILER)
    );
  }

  @computed
  get isFromSameBrandAsCurrentUser(): boolean {
    // Whether the message is from a brand team that we are a part of.
    const messageAuthor = this.conversationMessageAuthors.find(
      (author) => this.authorToken === author.token
    );

    return (
      this.conversation.isCurrentUserABrand &&
      messageAuthor?.type === IUser.Type.BRAND_USER
    );
  }

  @computed
  get createdAt(): number | undefined {
    return this.data.created_at;
  }

  @computed
  get deletableUntil(): number | undefined {
    return this.data.deletable_until;
  }

  @computed
  get isRead(): boolean {
    if (this.data.read_at) {
      return true;
    }
    if (
      this.isFromOtherUser &&
      this.data.created_at &&
      this.conversation.lastReadByCurrentUser
    ) {
      return this.data.created_at < this.conversation.lastReadByCurrentUser;
    }
    return false;
  }

  @computed
  get shouldPopUp(): boolean {
    if (this.isFromOtherUser && this.data.should_pop_up && this.createdAt) {
      const sevenDaysAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
      if (sevenDaysAgo < this.createdAt) {
        return true;
      }
    }
    return false;
  }
  @computed
  get isLatestReadFromCurrentUser(): boolean {
    return this === this.conversation.latestMessage && this.isRead;
  }

  @computed
  get isLatestDelivered(): boolean {
    return this === this.conversation.latestMessage;
  }

  get text(): string | undefined {
    if (this.isPending) {
      return undefined;
    }
    return this.data.text;
  }

  @computed
  get images(): IImageOrFile[] {
    if (this.isPending) {
      return [];
    }

    if (this._images) {
      return this._images.map((image) => ({
        file: Object.assign(image, { preview: URL.createObjectURL(image) }),
      }));
    }
    if (this.data.images) {
      return this.data.images.map((image) => ({
        image,
      }));
    }
    return [];
  }

  get isDeleted(): boolean {
    return !!this.data.deleted;
  }

  @observable
  private _isDeleting: boolean = false;

  @computed
  get isPending(): boolean {
    return this._isDeleting;
  }

  @action
  setIsDeleting = (isDeleting: boolean) => {
    this._isDeleting = isDeleting;
  };

  deleteMessage = (): Promise<void> => {
    return this.conversation.deleteMessage(this.token);
  };

  @computed
  get latestMessageAuthorFirstName(): string {
    return this.data.author_first_name ?? "";
  }

  /*
   * async
   */

  sendMessage = async (): Promise<void> => {
    let updatedConversation: IMessengerConversation | undefined;

    if (this.data.updated_at !== undefined) {
      throw new Error(
        "This should not be called if the message is already on the server - Ian"
      );
    }

    /*
    Messenger Service + Umbrella Brands Migration Setting
    */
    try {
      const imageTokens = await this.uploadImages();

      const messageContents = IMessageContents.build({
        text: this.data.text,
        image_tokens: imageTokens ?? [],
        product_tokens: this.productTokens ?? [],
      });

      if (!this.conversation.token) {
        updatedConversation = await this.conversation.createConversation(
          messageContents
        );
      } else {
        const result = await sendMessengerMessageV3(
          ISendMessengerMessageRequestV3.build({
            conversation_token: this.conversation.token,
            message_contents: messageContents,
          })
        );

        updatedConversation = result.conversation;
      }

      if (!updatedConversation) {
        throw new Error(`This request should have returned a conversation.`);
      }

      this.data.token = updatedConversation.latest_message?.token;
      this.conversation.updateConversation(updatedConversation);
    } catch (e) {
      logError(e);
      this._error = e;
    }
  };

  @action
  setError = (message: string) => {
    this._error = message;
  };

  private uploadImages = async (): Promise<string[] | undefined> => {
    const images =
      this._images && (await Promise.all(this._images.map(uploadImageFile)));

    return images && compact(images.map((image) => image.token));
  };

  get otherPartyFirstName() {
    if (!this.isFromOtherUser) {
      return undefined;
    }

    return (
      this.conversation.otherParty?.first_name ||
      this.conversation.otherParty?.name
    );
  }

  get otherPartyProfilePicUrl() {
    if (!this.isFromOtherUser) {
      return undefined;
    }

    return this.conversation.otherPartyProfilePicUrl;
  }

  get currentPartyProfilePicUrl() {
    return this.conversation.userProfilePhotoUrl;
  }
}
