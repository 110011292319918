"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const Arch: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_SUMMER_MARKET_2022]
      .decorative2;
  return (
    <SVGIcon
      width="219"
      height="193"
      viewBox="0 0 219 193"
      fill="none"
      {...rest}
    >
      <path
        d="M219 193H122.29L122.29 110.565C122.29 103.441 116.564 97.6585 109.509 97.6585C102.455 97.6585 96.7097 103.441 96.7097 110.565L96.7097 193L0 193L0 110.565C0 49.5952 49.1133 0 109.491 0C169.868 0 218.981 49.5952 218.981 110.565V193H219Z"
        fill={iconColor}
      />
    </SVGIcon>
  );
};
