import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';
import {
  TimingPair,
  timingPairStringify,
} from '@faire/web--source/common/timings/TimingMarker';

export const eventKey = 'client_events-client_side_latency-timing';

/**
 * Timing event that tracks the client-side endpoint latency.
 */
export function trackClientEventsClientSideLatencyTiming(
  timingParameterToken: TimingPair,
  Endpoint: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: {
      timingParameterToken: timingPairStringify(timingParameterToken) ?? '',
      Endpoint,
    },
  });
}
