"use client";

import Color from "@faire/web--source/slate/Color";
import { Arch } from "@faire/web--source/slate/icons/faire-markets/Arch";
import { HalfMoon } from "@faire/web--source/slate/icons/faire-markets/HalfMoon";
import { Primary } from "@faire/web--source/slate/icons/faire-markets/market-january-2024/Primary";
import { Secondary } from "@faire/web--source/slate/icons/faire-markets/market-january-2024/Secondary";
import { Tertiary } from "@faire/web--source/slate/icons/faire-markets/market-january-2024/Tertiary";
import { Primary as PrimaryFsm23 } from "@faire/web--source/slate/icons/faire-markets/market-july-2023/Primary";
import { Secondary as SecondaryFsm23 } from "@faire/web--source/slate/icons/faire-markets/market-july-2023/Secondary";
import { Tertiary as TertiaryFsm23 } from "@faire/web--source/slate/icons/faire-markets/market-july-2023/Tertiary";
import { Snowflake as SnowflakeFwm23 } from "@faire/web--source/slate/icons/faire-markets/Snowflake";
import { Sun } from "@faire/web--source/slate/icons/faire-markets/Sun";
import { Tidepool } from "@faire/web--source/slate/icons/faire-markets/Tidepool";
import { Primary as PrimaryFwm23 } from "@faire/web--source/slate/icons/faire-markets/winter-market-2023/Primary";
import { Secondary as SecondaryFwm23 } from "@faire/web--source/slate/icons/faire-markets/winter-market-2023/Secondary";
import { Tertiary as TertiaryFwm23 } from "@faire/web--source/slate/icons/faire-markets/winter-market-2023/Tertiary";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import {
  IAutoDimensionProps,
  withAutoDimension,
} from "@faire/web--source/slate/Theme/withAutoDimension";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export interface IEventShapeProps extends IAutoDimensionProps {
  color?: Color;
}

type eventKeys =
  | "faireMarket"
  | Extract<
      PromotionalEventType,
      | PromotionalEventType.FAIRE_MARKET_JULY_2023
      | PromotionalEventType.FAIRE_WINTER_MARKET_2023
      | PromotionalEventType.FAIRE_SUMMER_MARKET_2022
    >;
type shapeKeys = "badge" | "primary" | "secondary" | "tertiary";

const BadgeShapeBase: React.FC<IEventShapeProps> = (props) => {
  const color = props.color ?? eventsThemeColors.faireMarket.badgeIcon;
  return <Sun {...props} color={color} />;
};
const BadgeShapeBaseFsm23: React.FC<IEventShapeProps> = (props) => {
  const color =
    props.color ??
    eventsThemeColors[PromotionalEventType.FAIRE_MARKET_JULY_2023].badgeIcon;
  return <Sun {...props} color={color} />;
};
const BadgeShapeBaseFwm23: React.FC<IEventShapeProps> = (props) => (
  <SnowflakeFwm23 {...props} />
);
const BadgeShapeBaseFsm22: React.FC<IEventShapeProps> = (props) => {
  const color =
    props.color ??
    eventsThemeColors[PromotionalEventType.FAIRE_SUMMER_MARKET_2022].badgeIcon;
  return <Sun {...props} color={color} />;
};

const BadgeShape = withAutoDimension(BadgeShapeBase);
const BadgeShapeFsm23 = withAutoDimension(BadgeShapeBaseFsm23);
const BadgeShapeFwm23 = withAutoDimension(BadgeShapeBaseFwm23);
const BadgeShapeFsm22 = withAutoDimension(BadgeShapeBaseFsm22);

const PrimaryShapeBase: React.FC<IEventShapeProps> = (props) => (
  <Primary {...props} />
);
const PrimaryShapeBaseFsm23: React.FC<IEventShapeProps> = (props) => (
  <PrimaryFsm23 {...props} />
);
const PrimaryShapeBaseFwm23: React.FC<IEventShapeProps> = (props) => (
  <PrimaryFwm23 {...props} />
);
const PrimaryShapeBaseFsm22: React.FC<IEventShapeProps> = (props) => (
  <Arch {...props} />
);

const PrimaryShape = withAutoDimension(PrimaryShapeBase);
const PrimaryShapeFsm23 = withAutoDimension(PrimaryShapeBaseFsm23);
const PrimaryShapeFwm23 = withAutoDimension(PrimaryShapeBaseFwm23);
const PrimaryShapeFsm22 = withAutoDimension(PrimaryShapeBaseFsm22);

const SecondaryShapeBase: React.FC<IEventShapeProps> = (props) => (
  <Secondary {...props} />
);
const SecondaryShapeBaseFsm23: React.FC<IEventShapeProps> = (props) => (
  <SecondaryFsm23 {...props} />
);
const SecondaryShapeBaseFwm23: React.FC<IEventShapeProps> = (props) => (
  <SecondaryFwm23 {...props} />
);
const SecondShapeBaseFsm22: React.FC<IEventShapeProps> = (props) => (
  <HalfMoon {...props} />
);

const SecondaryShape = withAutoDimension(SecondaryShapeBase);
const SecondaryShapeFsm23 = withAutoDimension(SecondaryShapeBaseFsm23);
const SecondaryShapeFwm23 = withAutoDimension(SecondaryShapeBaseFwm23);
const SecondaryShapeFsm22 = withAutoDimension(SecondShapeBaseFsm22);

const TertiaryShapeBase: React.FC<IEventShapeProps> = (props) => (
  <Tertiary {...props} />
);
const TertiaryShapeBaseFsm23: React.FC<IEventShapeProps> = (props) => (
  <TertiaryFsm23 {...props} />
);
const TertiaryShapeBaseFwm23: React.FC<IEventShapeProps> = (props) => (
  <TertiaryFwm23 {...props} />
);
const TertiaryShapeBaseFsm22: React.FC<IEventShapeProps> = (props) => (
  <Tidepool {...props} />
);

const TertiaryShape = withAutoDimension(TertiaryShapeBase);
const TertiaryShapeFsm23 = withAutoDimension(TertiaryShapeBaseFsm23);
const TertiaryShapeFwm23 = withAutoDimension(TertiaryShapeBaseFwm23);
const TertiaryShapeFsm22 = withAutoDimension(TertiaryShapeBaseFsm22);

export const EventsThemeShape: {
  [event in eventKeys]: {
    [shape in shapeKeys]: React.FC<IEventShapeProps>;
  };
} = {
  faireMarket: {
    badge: BadgeShape,
    primary: PrimaryShape,
    secondary: SecondaryShape,
    tertiary: TertiaryShape,
  },
  FAIRE_MARKET_JULY_2023: {
    badge: BadgeShapeFsm23,
    primary: PrimaryShapeFsm23,
    secondary: SecondaryShapeFsm23,
    tertiary: TertiaryShapeFsm23,
  },
  FAIRE_WINTER_MARKET_2023: {
    badge: BadgeShapeFwm23,
    primary: PrimaryShapeFwm23,
    secondary: SecondaryShapeFwm23,
    tertiary: TertiaryShapeFwm23,
  },
  FAIRE_SUMMER_MARKET_2022: {
    badge: BadgeShapeFsm22,
    primary: PrimaryShapeFsm22,
    secondary: SecondaryShapeFsm22,
    tertiary: TertiaryShapeFsm22,
  },
};
