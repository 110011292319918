import { SettingConst } from '../../types';

/**
 * The customer facing event name for seasonal warehouse sale
 */
export const SEASONAL_WAREHOUSE_SALE_NAME =
  'SEASONAL_WAREHOUSE_SALE_NAME' as SettingConst<
    'SEASONAL_WAREHOUSE_SALE_NAME',
    string,
    'RETAILER'
  >;
