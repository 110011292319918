import { StrictLocalizeFunction } from "../localization";

import { getIsoToCountryNames } from "./ISO_TO_COUNTRY_NAME";
import { THREE_LETTER_PRIMARY_COUNTRY_CODES } from "./THREE_LETTER_PRIMARY_COUNTRY_CODES";

/**
 * A function is required so we can change what is returned by localize
 * in a node environment where locale may change, e.g. web-email-server.
 */
export const getPrimaryCountriesIsoToCountryName = (
  strictLocalize: StrictLocalizeFunction
): {
  [key: string]: string;
} => {
  const isoToCountryNames = getIsoToCountryNames(strictLocalize);
  return Object.values(THREE_LETTER_PRIMARY_COUNTRY_CODES).reduce(
    (acc, value) => {
      acc[value] = isoToCountryNames[value];
      return acc;
    },
    {} as Record<
      (typeof THREE_LETTER_PRIMARY_COUNTRY_CODES)[keyof typeof THREE_LETTER_PRIMARY_COUNTRY_CODES],
      string
    >
  );
};

export const PRIMARY_COUNTRIES_ISO_TO_COUNTRY_NAME = (
  strictLocalize: StrictLocalizeFunction
) => getPrimaryCountriesIsoToCountryName(strictLocalize);
