import { RouteMatcher } from "@faire/web--source/common/routes/RouteMatcher";
import { Storage } from "@faire/web--source/common/Storage";
import { IPreSignUpActivity } from "@faire/web-api--source/indigofair/data/IPreSignUpActivity";
import { route as brandRoute } from "@faire/web-api--source/routes/brand/brandToken";
import { route as c1Route } from "@faire/web-api--source/routes/category/c1CategoryUrlId";
import { route as c1BrandValueRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/brand-value/brandValue";
import { route as c2Route } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId";
import { route as c2BrandValueRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/brand-value/brandValue";
import { route as c3Route } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId";
import { route as c3BrandValueRoute } from "@faire/web-api--source/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId/brand-value/brandValue";
import { route as faireDirectRoute } from "@faire/web-api--source/routes/direct/brandAlias";
import { route as discoverRoute } from "@faire/web-api--source/routes/discover/searchTerm";
import { route as exploreRoute } from "@faire/web-api--source/routes/explore/searchTerm";
import { route as collectionRoute } from "@faire/web-api--source/routes/product-based-collection/product_based_collection_token";
import { route as productRoute } from "@faire/web-api--source/routes/product/productToken";
import { route as searchRoute } from "@faire/web-api--source/routes/search";
import type { History } from "history";

import { isLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";

export const PRE_SIGNUP_ACTIVITY_LANDING = "pre_signup_activity_landing";
export const PRE_SIGNUP_ACTIVITY_SIGNUP = "pre_signup_activity_signup";
const getValidPageRoutes = () => [
  brandRoute,
  productRoute,
  collectionRoute,
  searchRoute,
  discoverRoute,
  exploreRoute,
  c1Route,
  c1BrandValueRoute,
  c2Route,
  c2BrandValueRoute,
  c3Route,
  c3BrandValueRoute,
  faireDirectRoute,
];

export const recordLandingActivity = (
  landingActivity: Partial<IPreSignUpActivity>,
  history: History
) => {
  if (
    history.length > 1 ||
    isLoggedInRetailer() ||
    Storage.session.getItem(PRE_SIGNUP_ACTIVITY_LANDING)
  ) {
    return;
  }

  Storage.session.setItem(
    PRE_SIGNUP_ACTIVITY_LANDING,
    JSON.stringify(
      IPreSignUpActivity.build({
        type: IPreSignUpActivity.PreSignUpActivityType.LANDING,
        ...landingActivity,
      })
    )
  );
};

export const getLandingActivity = (): IPreSignUpActivity | undefined => {
  const sessionLandingActivity = Storage.session.getItem(
    PRE_SIGNUP_ACTIVITY_LANDING
  );

  if (!sessionLandingActivity) {
    return undefined;
  }

  return JSON.parse(sessionLandingActivity);
};

export const recordSignupActivity = (
  signupActivity: Partial<IPreSignUpActivity>,
  expectedSignupSource: string
) => {
  if (isLoggedInRetailer()) {
    return;
  }

  Storage.session.setItem(
    PRE_SIGNUP_ACTIVITY_SIGNUP,
    JSON.stringify({
      signupActivity: IPreSignUpActivity.build({
        type: IPreSignUpActivity.PreSignUpActivityType.SIGNUP,
        ...signupActivity,
      }),
      expectedSignupSource,
    })
  );
};

export const getSignupActivity = (
  actualSignupSource: string
): IPreSignUpActivity | undefined => {
  const sessionSignupActivity = Storage.session.getItem(
    PRE_SIGNUP_ACTIVITY_SIGNUP
  );

  if (!sessionSignupActivity) {
    return undefined;
  }

  // Checks if the signed up pathname is equal to last recorded pathname with impactful activity
  const parsedSignupSource = JSON.parse(sessionSignupActivity);
  const expectedSignupSource = parsedSignupSource.expectedSignupSource;
  const validPageRoutes: string[] = getValidPageRoutes();
  const validSignupSource = validPageRoutes.some((route) => {
    const matcher = new RouteMatcher([route]);
    return (
      matcher.match(expectedSignupSource) && matcher.match(actualSignupSource)
    );
  });

  return validSignupSource ? parsedSignupSource.signupActivity : undefined;
};

export const clearLoggedOutSessionActivity = () => {
  Storage.session.removeItem(PRE_SIGNUP_ACTIVITY_LANDING);
  Storage.session.removeItem(PRE_SIGNUP_ACTIVITY_SIGNUP);
};
