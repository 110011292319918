import { PageType } from '../../indigofair/data/PageType';

export namespace ISearchCatNavBannersRequest {
  /**
   * Return an instance of ISearchCatNavBannersRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<ISearchCatNavBannersRequest>
  ): ISearchCatNavBannersRequest =>
    ({
      filter_keys: [],
      ...(partial ?? {}),
    }) as ISearchCatNavBannersRequest;
}

export interface ISearchCatNavBannersRequest {
  filter_keys: Array<string>;
  page_type: keyof typeof PageType | undefined;
  query: string | undefined;
  /**
   * No longer used by clients. User facing clients should leverage navigationPath instead of categoryPathIdentifier
   * @deprecated
   */
  category_path_identifier: string | undefined;
  /**
   * The URL path that represents the category
   */
  navigation_path: string | undefined;
}
