import { Color as ColorTokens } from "@faire/design-tokens/build/web/primitives";

export const EventColors = {
  // Faire Summer Market 2020
  /** @deprecated */
  corePrimary_fsm20: "#ed5b34",
  /** @deprecated */
  coreBadgeIcon_fsm20: "#ffdac0",

  // Faire Winter Market 2021
  /** @deprecated */
  burgundy_fwm21: "#943631",
  /** @deprecated */
  mint_fwm21: "#cbe3ca",
  /** @deprecated */
  peach_fwm21: "#fdd9bf",
  /** @deprecated */
  redPepper_fwm21: "#ed5b33",
  /** @deprecated */
  saffron_fwm21: "#ebb337",
  /** @deprecated */
  emerald_fwm21: "#005553",
  /** @deprecated */
  eggnog_fwm21: "#F9F4EE",
  /** @deprecated */
  chartreuse_fwm21: "#A08C4E",
  /** @deprecated */
  pine_fwm21: "#143332",
  /** @deprecated */
  badgeBlue_fwm21: "#569BEC",

  // Faire Summer Market 2021
  /** @deprecated */
  iris_fsm21: "#5262C7",
  /** @deprecated */
  pool_fsm21: "#CAD6FF",
  /** @deprecated */
  lilac_fsm21: "#F5CBFF",
  /** @deprecated */
  richLilac_fsm21: "#E98FFF",
  /** @deprecated */
  apricot_fsm21: "#FFAD7F",
  /** @deprecated */
  boysenberry_fsm21: "#7F263C",
  /** @deprecated */
  kiddiePool_fsm21: "#F2F5FF",
  /** @deprecated */
  whiteSand_fsm21: "#FFFCF6",

  // Faire Winter Market 2022
  plum_fwm22: "#581033",
  sugarPlum_fwm22: "#7C3A5A",
  jade_fwm22: "#6EA488",
  richJade_fwm22: "#2FA67B",
  jungle_fwm22: "#0F4736",
  jungleLight_fwm22: "#16634B",
  seaglass_fwm22: "#EFF9F3",
  glacier_fwm22: "#91C5C2",
  camel_fwm22: "#BD917A",
  mauve_fwm22: "#AB6161",
  cocoa_fwm22: "#886262",
  ballet_fwm22: "#FAE4F0",
  rouge_fwm22: "#A1380B",

  // Faire Summer Market 2022
  optimist_fsm22: "#993019",
  papaya_fsm22: "#B84026",
  sunny_fsm22: "#FA6E42",
  superSunny_fsm22: "#FF6130",
  promoText_fsm22: "#993019",
  promoTextLight_fsm22: "#B12D0F",
  whiteSand_fsm22: "#FFF6EA",
  green_fsm22: "#546C5B",
  jade_fsm22: "#A2BEAA",
  cocoa_fsm22: "#985344",
  ocean_fsm22: "#5B6365",
  salmon_fsm22: "#BA6452",
  cream_fsm22: "#F7ECD8",
  rośe_fsm22: "#D48F80",
  sky_fsm22: "#E5EDEF",

  // Faire Winter Market 2023
  corePrimary_fwm23: "#263F3E",
  corePrimaryLight_fwm23: "#3B6F66",
  coreSecondary_fwm23: "#968EEF",
  coreBadgeIcon_fwm23: "#7F74FA",
  textPrimary_fwm23: "#2C4948",
  textPrimaryLight_fwm23: "#3B6F66",
  textInverse_fwm23: "#FFFFFF",
  backgroundPrimary_fwm23: "#EDF7F2",
  backgroundLight_fwm23: "#FFFFFF",
  decorative1_fwm23: "#3C40A0",
  decorative2_fwm23: "#D5E0F2",
  decorative3_fwm23: "#968EEF",
  decorative4_fwm23: "#51111B",
  decorative5_fwm23: "#ECCBBB",
  decorative6_fwm23: "#FFEAE3",

  // Faire Summer Market 2023
  corePrimary_fsm23: "#CFD16B",
  corePrimaryLight_fsm23: "#E9EB8F",
  coreSecondary_fsm23: "#61124A",
  coreBadgeIcon_fsm23: "#72994B",
  textPrimary_fsm23: "#1B2834",
  textPrimaryLight_fsm23: "#394949",
  textInverse_fsm23: "#FFFFFF",
  backgroundPrimary_fsm23: "#F4EDE4",
  backgroundLight_fsm23: "#FFFFFF",
  decorative1_fsm23: "#61124A",
  decorative2_fsm23: "#BFA8FF",
  decorative3_fsm23: "#CC6E39",
  decorative4_fsm23: "#FBD8CE",
  decorative5_fsm23: "#394949",
  decorative6_fsm23: "#F4EDE4",

  // Faire Winter Market 2024
  corePrimary_fwm24: "#F4DED2",
  corePrimaryLight_fwm24: "#FFF8F6",
  coreSecondary_fwm24: "#57160B",
  coreBadgeIcon_fwm24: "#DF6630",
  textPrimary_fwm24: "#333333",
  textPrimaryLight_fwm24: "#57160B",
  textInverse_fwm24: "#FFFFFF",
  backgroundPrimary_fwm24: "#FFFAF5",
  backgroundLight_fwm24: "#FFFFFF",
  decorative1_fwm24: "#57160B",
  decorative2_fwm24: "#F38F80",
  decorative3_fwm24: "#DF6630",
  decorative4_fwm24: "#F9EAE2",
  decorative5_fwm24: "#DF6630",
  decorative6_fwm24: "#F38F80",

  // Faire Summer Market 2024
  // Note: For FSM 2024, some tokens share the same colour. Please see https://www.figma.com/design/mDFMOehyo1z5YWGDxh2lXV/%F0%9F%8C%9E-Faire-Summer-Market-2024?node-id=21631-2210&t=6OrErwDwbaFHkskz-0
  corePrimary_fsm24: "#56160C",
  corePrimaryLight_fsm24: "#F8F1E9",
  coreSecondary_fsm24: "#F38F7F",
  coreBadgeIcon_fsm24: "#DF6630",
  textPrimary_fsm24: "#333333",
  textPrimaryLight_fsm24: "#FFFFFF",
  textInverse_fsm24: "#FFFFFF",
  backgroundPrimary_fsm24: "#F8F1E9",
  backgroundLight_fsm24: "#FFFFFF",
  decorative1_fsm24: "#56160C",
  decorative2_fsm24: "#F38F7F",
  decorative3_fsm24: "#DF6630",
  decorative4_fsm24: "#F9EAE2",
  decorative5_fsm24: "#DF6630",
  decorative6_fsm24: "#F38F7F",

  // Faire Winter Market 2025
  corePrimary_fwm25: "#56160C",
  corePrimaryLight_fwm25: "#F8F1E9",
  coreSecondary_fwm25: "#F38F7F",
  coreBadgeIcon_fwm25: "#DF6630",
  textPrimary_fwm25: "#333333",
  textPrimaryLight_fwm25: "#FFFFFF",
  textInverse_fwm25: "#FFFFFF",
  backgroundPrimary_fwm25: "#F8F1E9",
  backgroundLight_fwm25: "#FFFFFF",
  decorative1_fwm25: "#56160C",
  decorative2_fwm25: "#F38F7F",
  decorative3_fwm25: "#DF6630",
  decorative4_fwm25: "#F9EAE2",
  decorative5_fwm25: "#DF6630",
  decorative6_fwm25: "#F38F7F",

  // Faire Fashion Week V2 - Winter/Spring
  /** @deprecated */
  firefly: "#DBFF49",
  /** @deprecated */
  denim: "#5559B2",
  /** @deprecated */
  perwinkle: "#E0E3FF",
  /** @deprecated */
  periwinkle_light: "#ECEEFF",
  /** @deprecated */
  meadow: "#B0A75B",

  cedarGreen: "#668000",
  oat: "#F7E8D6",
  electric: "linear-gradient(275.85deg, #DBFF49 0%, #F7E8D6 79.91%);",
  clay: "#A1675C",

  // Faire Fashion Week V1/V3 - Summer/Fall
  aura_FFWV1:
    "linear-gradient(289.12deg, #ECEEFF 20.74%, rgba(224, 227, 255, 0) 121.42%), #DBFF49;",
  ...ColorTokens,
} as const;

export type EventColorKey = keyof typeof EventColors;
