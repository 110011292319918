import { uniqueId } from "@faire/web--source/common/server-side-rendering/uniqueId";
import React from "react";

/** Use to generate a unique ID within a functional component */
export const useUniqueId =
  "useId" in React
    ? (idPrefix: string, _propId?: string) => {
        return `${idPrefix}${(React as any).useId()}`;
      }
    : (idPrefix: string, propId?: string) => {
        const idRef = React.useRef(propId ?? uniqueId(idPrefix));
        return idRef.current;
      };
