import { request } from '../../..';
import { IPublishUserEventsRequest } from '../../../indigofair/data/IPublishUserEventsRequest';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';

export const path = () => {
  return `/api/v2/events`;
};

export const build = (
  subdomain: 'www' | 'events.api',
  data: IPublishUserEventsRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    subdomain,
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/events';

/**
 * Product Area: CLIENT_EVENTS
 */
export function execute(
  subdomain: 'www' | 'events.api',
  data: IPublishUserEventsRequest
): Promise<void>;
export function execute(
  subdomain: 'www' | 'events.api',
  data: IPublishUserEventsRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<void>>;
export function execute(
  subdomain: 'www' | 'events.api',
  data: IPublishUserEventsRequest,
  options?: EndpointOptions
): Promise<void>;
export function execute(
  subdomain: 'www' | 'events.api',
  data: IPublishUserEventsRequest,
  options?: EndpointOptions
): Promise<void | SuccessResponse<void>> {
  const { route, ...requestOptions } = build(subdomain, data, options);
  return request<void>(requestOptions);
}
execute[PATH] = 'api/v2/events/post';
export default execute;
