import { getWindow } from "@faire/web--source/common/getWindow";

export const isElementVisible = (elem: HTMLElement | null): boolean => {
  if (!elem) {
    return false;
  }

  if (
    elem.offsetParent === null ||
    elem.offsetHeight === 0 ||
    elem.offsetLeft === 0 ||
    elem.offsetTop === 0 ||
    elem.offsetWidth === 0 ||
    elem.clientHeight === 0 ||
    elem.clientWidth === 0
  ) {
    return false;
  }

  const elementCSS = getWindow().getComputedStyle(elem);
  return !(
    elementCSS.getPropertyValue("display") === "none" ||
    elementCSS.getPropertyValue("visibility") === "hidden"
  );
};
