import { returnFuncIfServer } from "@faire/web--source/common/globals/returnFuncIfServer";
import throttle from "lodash/throttle";

export function throttleIfPossible<T extends (...args: any) => any>(
  func: T,
  wait?: number,
  options?: Parameters<typeof throttle>[2]
): ReturnType<typeof throttle> | T {
  return returnFuncIfServer(func) || throttle(func, wait, options);
}
