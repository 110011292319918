import { request } from '../../..';
import { IPromoBannerMessageResponse } from '../../../indigofair/retailer/IPromoBannerMessageResponse';
import { WebViewport } from '../../../indigofair/retailer/WebViewport';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';
import qs from 'query-string';

export type QueryParameters = {
  viewport: keyof typeof WebViewport | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/messaging/promo_banner${queryString}`;
};

export const build = (
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    params: queryParams,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/messaging/promo_banner';

/**
 * Product Area: PRODUCT_MESSAGING
 */
export function execute(
  queryParams?: QueryParameters
): Promise<IPromoBannerMessageResponse>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IPromoBannerMessageResponse>>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IPromoBannerMessageResponse>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  IPromoBannerMessageResponse | SuccessResponse<IPromoBannerMessageResponse>
> {
  const { route, ...requestOptions } = build(queryParams, options);
  return request<IPromoBannerMessageResponse>(requestOptions);
}
execute[PATH] = 'api/messaging/promo_banner/get';
export default execute;
