import { FAIRE_DIRECT_SIGN_UP_FROM_MARKETPLACE_240809 } from "@faire/web-api--source/indigofair/settings/FAIRE_DIRECT_SIGN_UP_FROM_MARKETPLACE_240809";

import {
  getSettingValueWithoutAssignment,
  assignSetting,
} from "@faire/retailer/settings/__internal__/getSetting";

import { useAssignSetting, useSetting } from "./__internal__/useSetting";

export const useSettingFaireDirectSignUpFromMarketplace = (): boolean =>
  useSetting(FAIRE_DIRECT_SIGN_UP_FROM_MARKETPLACE_240809, false);

export const getSettingFaireDirectSignUpFromMarketplace = (): boolean =>
  getSettingValueWithoutAssignment(
    FAIRE_DIRECT_SIGN_UP_FROM_MARKETPLACE_240809,
    false
  );

export const useAssignSettingFaireDirectSignUpFromMarketplace = () =>
  useAssignSetting(FAIRE_DIRECT_SIGN_UP_FROM_MARKETPLACE_240809);

export const assignSettingFaireDirectSignUpFromMarketplace = () =>
  assignSetting(FAIRE_DIRECT_SIGN_UP_FROM_MARKETPLACE_240809);
