import { SettingConst } from '../../types';

/**
 * When enabled, the messenger fab is removed from retailer portal
 */
export const REMOVE_MESSENGER_FAB_241112 =
  'REMOVE_MESSENGER_FAB_241112' as SettingConst<
    'REMOVE_MESSENGER_FAB_241112',
    boolean,
    'RETAILER'
  >;
