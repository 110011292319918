import { REMOVE_MESSENGER_FAB_241112 } from "@faire/web-api--source/indigofair/settings/REMOVE_MESSENGER_FAB_241112";
import { REMOVE_MESSENGER_FAB_FOR_UNVERIFIED_RETAILERS_241112 } from "@faire/web-api--source/indigofair/settings/REMOVE_MESSENGER_FAB_FOR_UNVERIFIED_RETAILERS_241112";

import { useAssignSetting, useSetting } from "./__internal__/useSetting";

export const useSettingRemoveMessengerFAB = (): boolean =>
  useSetting(REMOVE_MESSENGER_FAB_241112, false);

export const useAssignSettingRemoveMessengerFAB = () =>
  useAssignSetting(REMOVE_MESSENGER_FAB_241112);

export const useSettingRemoveMessengerFABForUnverifiedRetailers = (): boolean =>
  useSetting(REMOVE_MESSENGER_FAB_FOR_UNVERIFIED_RETAILERS_241112, false);
