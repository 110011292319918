import { useState as useReactState, Dispatch, SetStateAction } from "react";

type ReadonlyWeakMap<K extends object, V> = Omit<
  WeakMap<K, V>,
  "delete" | "set"
>;

// Note that mutable collections are a subclass of the corresponding immutable
// collection: a mutable collection has every capability an immutable collection
// does. Thus when we say `S extends ReadonlyMap` we're also implying that
// `S extends Map`.
type ReadonlyObject<S extends object> = S extends ReadonlyMap<
  Readonly<infer K>,
  Readonly<infer V>
>
  ? ReadonlyMap<Readonly<K>, Readonly<V>>
  : S extends ReadonlyWeakMap<Readonly<infer K>, Readonly<infer V>>
  ? ReadonlyWeakMap<Readonly<K>, Readonly<V>>
  : S extends ReadonlySet<Readonly<infer T>>
  ? ReadonlySet<Readonly<T>>
  : Readonly<S>;

export type ReadonlyState<S> = S extends object ? ReadonlyObject<S> : S;

type StateAndDispatch<S> = [
  ReadonlyState<S>,
  Dispatch<SetStateAction<ReadonlyState<S>>>
];

// Similarly, when we say you have to pass a `Readonly<S>` here as the initial
// value, we're actually only declaring that *the useState function* doesn't
// need to be able to modify the value that you pass in.
// You can pass in a `Set<T>` and it is considered an instance of
// `ReadonlySet<T>`, since it has every interface method that `ReadonlySet<T>`
// requires its instances to implement. The only actual *constraint* here, that
// isn't *loosening* the requirements, is the fact that we return
// `ReadonlyState<S>` as the first element of the array -- which now *does*
// constrain how you're permitted to interact with that return value.

/**
 * A wrapper around React's own `useState` function, which enforces that the
 * returned value is `Readonly` and won't allow you to call any mutation
 * methods on it.
 *
 * This helps to ensure that you avoid mistakenly invoking mutation methods
 * or modifying fields on the return value and missing a reactive update;
 * e.g. `array.push(1)` will not trigger a reactive update, and even if there
 * was a reactive update, derived values based on it (like
 * `useMemo(..., [array])`) would not correctly get recomputed as React checks
 * for changes using the `===` operator, i.e. it won't see changes to an array
 * unless the entire object instance gets replaced. Enforcing that all
 * mutations happen through the set-state action solves both these problems.
 */
export function useState<S>(
  initialState: () => ReadonlyState<S>
): StateAndDispatch<S>;
export function useState<S>(
  initialState: ReadonlyState<S>
): StateAndDispatch<S>;

export function useState<S>(
  initialState: ReadonlyState<S> | (() => ReadonlyState<S>)
): StateAndDispatch<S> {
  return useReactState(initialState);
}
