import { useQueryParams } from "@faire/web--source/ui/hooks/useQueryParams";
import { QueryParameters } from "@faire/web-api--source/routes/cart";
import pick from "lodash/pick";
import React from "react";

import { CartsTabs } from "@faire/retailer/stores/domain/CartStore/consts";

const RELEVANT_QUERY_PARAMS: (keyof QueryParameters)[] = ["filters", "query"];

export const useRelevantQueryParams = () =>
  pick(useQueryParams(QueryParameters.parse), RELEVANT_QUERY_PARAMS);

export const SERVER_CART_QUERY_KEY_PREFIX = "ServerBrandCarts";

export const buildServerCartQueryKey = (
  queryParams: QueryParameters,
  savedForLater: boolean,
  isOnCartPage: boolean,
  initialServerCart?: Record<string, unknown>
) => {
  // Don't include the initial data in the query key
  void initialServerCart;
  return [
    SERVER_CART_QUERY_KEY_PREFIX,
    queryParams,
    savedForLater,
    isOnCartPage,
  ];
};

export const SERVER_CART_SUMMARY_QUERY_KEY_PREFIX = "ServerCartSummary";

export const buildServerCartSummaryQueryKey = (
  queryParams: QueryParameters,
  selectedBrandTokens: string[],
  activeTab: CartsTabs,
  isOnCartPage: boolean,
  initialServerCartSummary?: React.ReactNode
) => {
  // Don't include the initial data in the query key
  void initialServerCartSummary;
  return [
    SERVER_CART_SUMMARY_QUERY_KEY_PREFIX,
    queryParams,
    selectedBrandTokens,
    activeTab,
    isOnCartPage,
  ];
};
