import { BatchEventsManager } from "@faire/web--source/common/batch-events/BatchEventsManager";
import { createRecordClientEventRequest } from "@faire/web--source/common/events/createRecordClientEventRequest";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { ITraceEventMarker } from "@faire/web--source/common/pete/TraceEventTracker";
import postClientEvent from "@faire/web-api--source/api/client-event/post";

// This is necessary for module resolution to work properly
import { EventInterceptor, TrackedEvent, TrackEvent } from "./types";

/**
 * Delegate for all generated PETE event utils, which handles the various schema configurations.
 */
export const trackClientEvent = (params: TrackEvent): ITraceEventMarker => {
  const { request, currentEventTime } = createRecordClientEventRequest(params);
  const eventRequest = request as Parameters<typeof postClientEvent>[1]; // Sidestep circular dependency issue;

  BatchEventsManager.get().enqueueEvent(eventRequest);

  const trackedEvent: TrackedEvent = {
    ...params,
    eventId: eventRequest.event_id!,
  };
  getGlobalProperty<EventInterceptor[]>("faire.events.interceptors")?.forEach(
    (i) => i(trackedEvent)
  );

  return {
    sourceEventHash: eventRequest.source_event_hash!,
    sourceEventKey: params.eventKey,
    sourceEventStart: currentEventTime,
  };
};
