import { ISSOToken } from '../../indigofair/data/ISSOToken';
import { MfaMethod } from '../../indigofair/data/MfaMethod';

export namespace ILoginRequest {
  export namespace IAdditionalSSOData {
    /**
     * Return an instance of IAdditionalSSOData with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<IAdditionalSSOData>
    ): IAdditionalSSOData =>
      ({
        ...(partial ?? {}),
      }) as IAdditionalSSOData;
  }

  export interface IAdditionalSSOData {
    first_name: string;
    last_name: string;
  }

  export namespace IIosAppAttestData {
    /**
     * Return an instance of IIosAppAttestData with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<IIosAppAttestData>
    ): IIosAppAttestData =>
      ({
        ...(partial ?? {}),
      }) as IIosAppAttestData;
  }

  export interface IIosAppAttestData {
    attestation_object_base64: string | undefined;
    key_id_base64: string | undefined;
    server_challenge: string | undefined;
  }

  export namespace IGooglePlayDeviceIntegrityData {
    export enum IntegrityTokenMissingReason {
      INTEGRITY_TOKEN_MISSING_REASON_UNKNOWN = 'INTEGRITY_TOKEN_MISSING_REASON_UNKNOWN',
      /**
       * The app wasn't able to fetch the nonce from the backend
       */
      NONCE_QUERY_FAILED = 'NONCE_QUERY_FAILED',
      /**
       * Reason from Google is "The calling app UID (user id) does not match the one from Package Manager.
       * Something is wrong (possibly an attack). Non-actionable."
       */
      ATTACK_SUSPECTED_BY_GOOGLE_SERVICES = 'ATTACK_SUSPECTED_BY_GOOGLE_SERVICES',
      NETWORK_ERROR = 'NETWORK_ERROR',
      GOOGLE_SERVER_ERROR = 'GOOGLE_SERVER_ERROR',
      /**
       * Most likely old play store version
       */
      COULD_NOT_CONNECT_TO_PLAY_STORE = 'COULD_NOT_CONNECT_TO_PLAY_STORE',
      /**
       * Similar to the above; IntegrityService : Binder has died
       */
      BINDER_HAS_DIED = 'BINDER_HAS_DIED',
      /**
       * We have reached capacity for out google services allowance
       */
      THROTTLED_BY_GOOGLE_SERVICES = 'THROTTLED_BY_GOOGLE_SERVICES',
      /**
       * We could not fetch the token in time for the user reaching the login page + 5 seconds
       */
      TIMED_OUT_WAITING_FOR_GOOGLE = 'TIMED_OUT_WAITING_FOR_GOOGLE',
      /**
       * Something else went wrong
       */
      OTHER = 'OTHER',
    }

    /**
     * Return an instance of IGooglePlayDeviceIntegrityData with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<IGooglePlayDeviceIntegrityData>
    ): IGooglePlayDeviceIntegrityData =>
      ({
        ...(partial ?? {}),
      }) as IGooglePlayDeviceIntegrityData;
  }

  export interface IGooglePlayDeviceIntegrityData {
    integrity_token: string | undefined;
    integrity_token_missing_reason:
      | keyof typeof ILoginRequest.IGooglePlayDeviceIntegrityData.IntegrityTokenMissingReason
      | undefined;
  }

  export namespace IHCaptchaData {
    export enum HCaptchaError {
      H_CAPTCHA_ERROR_UNKNOWN = 'H_CAPTCHA_ERROR_UNKNOWN',
      /**
       * hCaptcha token fetch promise not initialised before user attempted login
       */
      NO_PROMISE = 'NO_PROMISE',
      /**
       * Calling hCaptcha API failed
       */
      NO_RESPONSE = 'NO_RESPONSE',
      /**
       * hCaptcha script did not load
       */
      NO_SCRIPT = 'NO_SCRIPT',
      /**
       * hCaptcha returned an undefined token
       */
      NO_TOKEN = 'NO_TOKEN',
      /**
       * No widget ID was set when an API call was attempted
       */
      NO_WIDGET = 'NO_WIDGET',
      /**
       * Widget element failed to render
       */
      RENDER_FAIL = 'RENDER_FAIL',
      /**
       * Setting read as off for this user
       */
      SETTING_OFF = 'SETTING_OFF',
      /**
       * hCaptcha token fetch timed out
       */
      TIMED_OUT = 'TIMED_OUT',
      /**
       * We waited too long to submit the token
       */
      TOKEN_EXPIRED = 'TOKEN_EXPIRED',
      /**
       * The code path calling the login endpoint is not requesting hCaptcha
       */
      UNCOVERED_FLOW = 'UNCOVERED_FLOW',
    }

    /**
     * Redacted copy of IHCaptchaData (i.e. without redacted fields).
     */
    export type Redacted = Omit<IHCaptchaData, 'token'>;

    /**
     * Return an instance of IHCaptchaData with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (partial?: Partial<IHCaptchaData>): IHCaptchaData =>
      ({
        ...(partial ?? {}),
      }) as IHCaptchaData;
  }

  export interface IHCaptchaData {
    /**
     * [REDACTED]
     * @protobufOption (indigofair.data.redacted) = true
     */
    token: string | undefined; // redacted
    error: keyof typeof ILoginRequest.IHCaptchaData.HCaptchaError | undefined;
    token_fetched_at: number | undefined;
  }

  /**
   * Redacted copy of ILoginRequest (i.e. without redacted fields).
   */
  export type Redacted = Omit<
    ILoginRequest,
    | 'code'
    | 'password'
    | 'mfa_code'
    | 'email_address'
    | 'phone_number'
    | 'user_token'
    | 'sso_token'
  >;

  /**
   * Return an instance of ILoginRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<ILoginRequest>): ILoginRequest =>
    ({
      ...(partial ?? {}),
    }) as ILoginRequest;
}

export interface ILoginRequest {
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  code: string | undefined; // redacted
  /**
   * If true, it calls the phone number associated with this account.
   */
  call: boolean | undefined;
  /**
   * When present, we will try to login using the provided password.
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  password: string | undefined; // redacted
  /**
   * When present, we will try to login using the provided password and MFA code.
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  mfa_code: string | undefined; // redacted
  /**
   * If true, then try alternative provider to send login-code
   */
  resend: boolean | undefined;
  /**
   * When present, we will populate sign up suggestions with this info if we cannot get it from a JWT
   */
  additional_s_s_o_info: ILoginRequest.IAdditionalSSOData | undefined;
  /**
   * If true, use new MFA flow and disable MFA code auto sending
   */
  enable_mfa_method_selection: boolean | undefined;
  /**
   * Used when this is a MFA method selection request
   */
  mfa_method: keyof typeof MfaMethod | undefined;
  /**
   * Indicates that the user has attempted to suppress MFA requests for 30 days on this device
   */
  should_suppress_future_mfa: boolean | undefined;
  ios_app_attest_data: ILoginRequest.IIosAppAttestData | undefined;
  google_play_device_integrity_data:
    | ILoginRequest.IGooglePlayDeviceIntegrityData
    | undefined;
  h_captcha_data: ILoginRequest.IHCaptchaData | undefined;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  email_address: string | undefined; // redacted
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  phone_number: string | undefined; // redacted
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  user_token: string | undefined; // redacted
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  sso_token: ISSOToken | undefined; // redacted
}
