import { ITimestampRange } from "@faire/web-api--source/indigofair/data/ITimestampRange";
import { ITimestamp } from "@faire/web-api--source/indigofair/rfc3339/ITimestamp";
import { SPECIAL_HOLIDAY_NET90_TIMERANGE } from "@faire/web-api--source/indigofair/settings/SPECIAL_HOLIDAY_NET90_TIMERANGE";
import { SPECIAL_HOLIDAY_NET90_TIMEZONE } from "@faire/web-api--source/indigofair/settings/SPECIAL_HOLIDAY_NET90_TIMEZONE";
import isWithinInterval from "date-fns/isWithinInterval";

import { getSettingValueWithoutAssignment } from "@faire/retailer/settings/__internal__/getSetting";

export const getSettingSpecialHolidayNet90TimeRange = (): ITimestampRange =>
  getSettingValueWithoutAssignment(
    SPECIAL_HOLIDAY_NET90_TIMERANGE,
    ITimestampRange.build({
      start_at: ITimestamp.build({ seconds: 0, nanos: 0 }),
      end_at: ITimestamp.build({ seconds: 0, nanos: 0 }),
    })
  );

export const getSettingSpecialHolidayNet90Timezone = (): string =>
  getSettingValueWithoutAssignment(SPECIAL_HOLIDAY_NET90_TIMEZONE, "");

export const specialHolidayNet90Active = (): boolean => {
  const { start_at, end_at } = getSettingSpecialHolidayNet90TimeRange();

  return isWithinInterval(Date.now(), {
    start: (start_at?.seconds ?? 0) * 1000,
    end: (end_at?.seconds ?? 0) * 1000,
  });
};
