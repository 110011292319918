export const parseToNumber = (
  value: string | null | undefined
): number | undefined => {
  const parsedValue = parseInt(value ?? "");
  return Number.isInteger(parsedValue) ? parsedValue : undefined;
};

const BOOLEAN_VALUES = new Set<string>(["true", "1"]);

export const parseToBoolean = (value: string | null | undefined): boolean =>
  !!value && BOOLEAN_VALUES.has(value.toLowerCase());

export const parseToArray = <T = unknown>(
  rawValue: string | null | (string | null)[] | undefined,
  parseUtil?: (s: string) => T
): T[] => {
  if (!rawValue) {
    return [];
  }
  const value = Array.isArray(rawValue) ? rawValue : [rawValue];
  return value
    .map((v) => (v ? parseUtil?.(v) ?? v : undefined))
    .filter(Boolean) as T[];
};
