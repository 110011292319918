"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const Primary: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_MARKET_JULY_2023].decorative5;
  return (
    <SVGIcon
      width="240"
      height="150"
      viewBox="0 0 240 150"
      fill="none"
      {...rest}
    >
      <g clipPath="url(#clip0_2639_5579)">
        <mask
          id="mask0_2639_5579"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="14"
          y="13"
          width="214"
          height="150"
        >
          <path
            d="M17.2432 163L225.358 163C227.884 159.811 228.238 151.391 227.884 140.675C227.884 138.091 227.903 135.553 227.569 132.985C227.304 130.956 226.151 125.323 225.358 123.453C224.137 120.575 220.206 111.939 218.41 109.42C206.45 92.6448 204.533 85.8285 186.198 76.5706C180.53 73.7086 167.882 71.1488 160.302 69.1998C155.964 66.6963 156.513 64.8183 156.513 60.3053C156.513 52.0383 155.881 47.1551 155.881 38.966L155.881 18.9888C155.881 18.2084 156.185 13 155.53 13L148.302 13C134.873 13 122.879 14.2049 109.458 14.2049C106.46 14.2049 86.4043 13.0736 86.4043 15.1616C86.4043 16.8969 85.7727 20.6072 85.7727 22.497C85.365 24.93 85.3736 29.6682 84.8363 44.6583C84.6168 48.8682 83.8779 52.1445 83.8779 56.4782C83.8779 59.2676 83.345 65.3869 81.9832 67.3217C81.9832 67.3217 76.6342 68.4403 71.8775 69.1998C63.6666 70.511 58.6137 71.7867 48.508 79.4409C34.36 90.1569 27.2203 102.238 24.7874 107.577C22.4854 113.247 20.179 118.823 19.3135 120.902C17.6722 125.154 14.3288 135.508 14.0852 142.908C13.6756 155.346 14.7455 163 17.2432 163Z"
            fill="#E8552E"
          />
        </mask>
        <g mask="url(#mask0_2639_5579)">
          <rect width="240" height="150" fill={iconColor} />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2639_5579">
          <rect width="240" height="150" fill="white" />
        </clipPath>
      </defs>
    </SVGIcon>
  );
};
