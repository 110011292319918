import { RequestOptions } from "@faire/web-api--source/types";

import { getNormalizedUrl } from "../../getNormalizedUrl";
import {
  FAIRE_SPAN_ID_TRACKING,
  TelemetryManager,
} from "../../opentelemetry/TelemetryManager";
import { getSettingEnableDetailedSessionTracing } from "../../settings/getSettingEnableDetailedSessionTracing";

const ENDPOINT_EXCLUSION_LIST = ["client-event", "client-events", "v2/events"];

export const opentelemetryRequestInterceptor = (
  config: RequestOptions
): RequestOptions => {
  if (
    ENDPOINT_EXCLUSION_LIST.some((excluded_endpoint) =>
      config.url.includes(excluded_endpoint)
    ) ||
    !getSettingEnableDetailedSessionTracing()
  ) {
    return config;
  }

  const spanName = `${config.method} ${getNormalizedUrl(config.url)}`;

  const spanId = TelemetryManager.get().startManagedSpan(spanName, {
    kind: "client",
    name: spanName,
    // specifically avoiding `http.method` to avoid conflicts with the DD OTel instrumentation.
    "http.request_method": config.method,
    "http.url": config.url,
    resource_name: `${config.method}_${config.url}`.toLocaleLowerCase(),
    "service-group": "http_request",
    service: "http_request",
  });

  const traceparent =
    TelemetryManager.get().getActiveTraceHeaders()?.traceparent;

  return {
    ...config,
    headers: {
      ...config.headers,
      [FAIRE_SPAN_ID_TRACKING]: spanId,
      traceparent,
    },
  };
};
