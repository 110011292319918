/**
 * Return true if we are rendering on the server, and false otherwise.
 *
 * The return value MUST NOT be used to determine what is rendered, because
 * React requires that the result of server-side rendering matches the initial
 * render ("hydration") on the client.
 *
 * This is redundant in useEffect(), componentDidMount(), or event handling callbacks,
 * which are only ever triggered on the client.
 *
 * More information: https://www.joshwcomeau.com/react/the-perils-of-rehydration/
 */
export const isWindowUndefined = () => {
  // eslint-disable-next-line @faire-web/no-restricted-globals
  return typeof window === "undefined";
};
