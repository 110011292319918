export enum MessengerConversationType {
  /**
   * Historically, this was a conversation between a user and a member of our Support team.
   * This feature has been disabled since February of 2021.
   * @deprecated
   */
  SUPPORT = 'SUPPORT',
  /**
   * Unused on the backend, but still referenced on mobile as of 2022-09-20
   * @deprecated
   */
  SALES = 'SALES',
  /**
   * This is a conversation between a Retailer and a Brand.
   */
  B2B = 'B2B',
  /**
   * Unused on the backend.
   * @deprecated
   */
  INTERNAL = 'INTERNAL',
}
