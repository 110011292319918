import { route as brandPageRoute } from "@faire/web-api--source/routes/brand/brandToken";
import { route as brandPagePageNumberRoute } from "@faire/web-api--source/routes/brand/brandToken/page";
import { route as retailerCartRoute } from "@faire/web-api--source/routes/cart";
import { route as retailerCheckoutRoute } from "@faire/web-api--source/routes/checkout";
import { route as retailerCheckoutSignupRoute } from "@faire/web-api--source/routes/checkout/signup";
import { route as orderCheckoutSuccessRoute } from "@faire/web-api--source/routes/checkout/success/orderToken";
import { route as faireDirectRoute } from "@faire/web-api--source/routes/direct/brandAlias";
import { route as faireDirectSalesRepRoute } from "@faire/web-api--source/routes/direct/brandAlias/rep/salesRepCode";
import { route as collectionRoute } from "@faire/web-api--source/routes/product-based-collection/product_based_collection_token";
import { route as productPageRoute } from "@faire/web-api--source/routes/product/productToken";
import { route as salesRepRoute } from "@faire/web-api--source/routes/rep/salesRepCode";
import { route as retailerTokenRoute } from "@faire/web-api--source/routes/retailer/retailerToken";
import { route as retailerTokenBrandPageRoute } from "@faire/web-api--source/routes/retailer/retailerToken/brand/brandToken";
import { route as retailerTokenCartRoute } from "@faire/web-api--source/routes/retailer/retailerToken/cart";
import { route as welcomeRoute } from "@faire/web-api--source/routes/welcome/r";

export const faireDirectPortalPaths = (faireDirectBrandToken: string) => [
  retailerTokenRoute,
  retailerTokenBrandPageRoute.replace(
    ":brandToken([^/]*)",
    faireDirectBrandToken
  ),
  `/retailer/:retailToken/brand/${faireDirectBrandToken}/:page`,
  retailerTokenCartRoute,
  brandPageRoute.replace(":brandToken([^/]*)", faireDirectBrandToken),
  brandPagePageNumberRoute.replace(":brandToken([^/]*)", faireDirectBrandToken),
  productPageRoute,
  collectionRoute,
  retailerCartRoute,
  retailerCheckoutRoute,
  retailerCheckoutSignupRoute,
  orderCheckoutSuccessRoute,
  welcomeRoute,
  salesRepRoute,
  faireDirectRoute,
  faireDirectSalesRepRoute,
];
