"use client";

import { getWindowOrThrow } from "@faire/web--source/common/globals/getWindow";
import { RoutingContext } from "@faire/web--source/ui/routing/RoutingContext";
import { prependLocaleToUrl } from "@faire/web--source/ui/routing/util";
// eslint-disable-next-line no-restricted-imports
import { useRouter as useNextRouter } from "next/navigation";
import { useCallback, useContext, useMemo } from "react";

import { ModifiedNavigateOptions, ModifiedNextRouter } from "./types";

/**
 * A wrapper around next/navigation's useRouter that prepends the locale to the href and allows for shallow routing.
 * @example
 * ```tsx
 * const router = useRouter();
 * router.push('/?signIn=1');
 * router.push('/?signIn=1', { shallow: true });
 * ```
 */
export const useRouter = (): ModifiedNextRouter => {
  const nextRouter = useNextRouter();
  const routingContext = useContext(RoutingContext);

  const push = useCallback(
    (href: string, options?: ModifiedNavigateOptions) => {
      const hrefWithLocale = prependLocaleToUrl(href, routingContext);
      const { history } = getWindowOrThrow();
      if (options?.shallow) {
        history.pushState.call(history, null, "", hrefWithLocale);
      } else {
        nextRouter.push(
          replaceQuerySpacesWithUnderscores(hrefWithLocale),
          options
        );
      }
    },
    [nextRouter, routingContext]
  );

  const replace = useCallback(
    (href: string, options?: ModifiedNavigateOptions) => {
      const hrefWithLocale = prependLocaleToUrl(href, routingContext);
      const { history } = getWindowOrThrow();
      if (options?.shallow) {
        history.replaceState.call(history, null, "", hrefWithLocale);
      } else {
        nextRouter.replace(
          replaceQuerySpacesWithUnderscores(hrefWithLocale),
          options
        );
      }
    },
    [nextRouter, routingContext]
  );

  const prefetch: typeof nextRouter.prefetch = useCallback(
    (href, options) => {
      const hrefWithLocale = prependLocaleToUrl(href, routingContext);
      return nextRouter.prefetch(
        replaceQuerySpacesWithUnderscores(hrefWithLocale),
        options
      );
    },
    [nextRouter, routingContext]
  );

  // Replace URL encoded spaces with underscores in the query string.
  // This is required to ensure that the RSC payload is correctly cached by Next.js.
  const replaceQuerySpacesWithUnderscores = (href: string) => {
    const queryRegex = /search\?q=[^=&]*&?/g;
    // Extract only the query string from the URL.
    const query = href.match(queryRegex);
    if (!query) {
      return href;
    }
    // Replace URL encoded spaces with underscores in the query string.
    const replacedQuery = query[0].replace(/%20/g, "_");
    return href.replace(queryRegex, replacedQuery);
  };

  const modifiedRouter = useMemo(
    () => ({
      push,
      replace,
      prefetch,
      refresh: nextRouter.refresh,
      back: nextRouter.back,
      forward: nextRouter.forward,
    }),
    [push, replace, prefetch, nextRouter]
  );

  return modifiedRouter;
};
