import { CapabilityEnum } from '../../faire/session/CapabilityEnum';
import { IAddress } from '../../indigofair/data/IAddress';
import { IImage } from '../../indigofair/data/IImage';
import { IInstrumentLink } from '../../indigofair/data/IInstrumentLink';
import { MarketingSmsPreference } from '../../indigofair/data/MarketingSmsPreference';
import { UserRole } from '../../indigofair/data/UserRole';
import { Language } from '../../indigofair/languages/Language';
import { ILocaleKey } from '../../indigofair/locale/ILocaleKey';

export namespace IUser {
  export enum Type {
    GUEST = 'GUEST',
    UNKNOWN = 'UNKNOWN',
    BRAND_USER = 'BRAND_USER',
    USER = 'USER',
    STYLIST = 'STYLIST',
    EDITOR = 'EDITOR',
    ADMIN = 'ADMIN',
    CONSUMER = 'CONSUMER',
    DEVELOPER = 'DEVELOPER',
    AUDITOR = 'AUDITOR',
    POTENTIAL_SIGN_UP_LEAD = 'POTENTIAL_SIGN_UP_LEAD',
    SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
  }

  /**
   * A user's preference for a certain type of email, such as marketing emails.
   */
  export enum EmailPreference {
    /**
     * We should send emails of a certain type to the user.
     */
    OPTED_IN = 'OPTED_IN',
    /**
     * We should not send emails of a certain type to the user.
     */
    OPTED_OUT = 'OPTED_OUT',
    /**
     * The user has not decided on a preference, we should prompt them at a later time.
     */
    UNDECIDED = 'UNDECIDED',
  }

  /**
   * Redacted copy of IUser (i.e. without redacted fields).
   */
  export type Redacted = Omit<
    IUser,
    'password' | 'dwolla_customer_id' | 'blacklisted'
  >;

  /**
   * Return an instance of IUser with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IUser>): IUser =>
    ({
      roles: [],
      addresses: [],
      instruments: [],
      capabilities: [],
      brand_tokens: [],
      ...(partial ?? {}),
    }) as IUser;
}

export interface IUser {
  token: string | undefined;
  /**
   * The experiment identifier is the user session ID at the time of the user's sign-up. It's used to guarantee
   * we can track experiment before and after sign-ups for a certain user. This can be null for two reasons:
   * 1) the user signed up before we added experiment identifier
   * 2) the same user session was already used by another user to sign-up
   * The second case should be very rare and it's meant to guarantee correctness of the experiments.
   */
  experiment_identifier: string | undefined;
  type: keyof typeof IUser.Type | undefined;
  /**
   * @deprecated
   */
  roles: Array<keyof typeof UserRole>;
  community_username: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  /**
   * Never sent from server -> client. Only from client -> server when setting a password.
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  password: string | undefined; // redacted
  /**
   * True if and only if this user has never set a password.
   */
  missing_password: boolean | undefined;
  email_address: string | undefined;
  additional_email_address: string | undefined;
  phone_number: string | undefined;
  /**
   * The profile image for this user.
   */
  profile_image: IImage | undefined;
  /**
   * Deprecated in favor of getting addresses through an endpoint.
   * This is still populated for now, but don't add new usages.
   * @deprecated
   */
  addresses: Array<IAddress>;
  /**
   * Available on defaultShippingAddress. Still populated for now but don't add new usages.
   * @deprecated
   */
  default_shipping_address_token: string | undefined;
  instruments: Array<IInstrumentLink>;
  default_payment_instrument_token: string | undefined;
  /**
   * The retailer owned by this user, if any (for USER type).
   */
  retailer_token: string | undefined;
  /**
   * The first associated brand of this user, if any.
   * Since a user can be associated to multiple brands, use the [brandTokens] field instead.
   * @deprecated
   */
  brand_token: string | undefined;
  /**
   * The user owns a purchase order brand. This field is deprecated and will be removed.
   * @deprecated
   */
  is_purchase_order_user: boolean | undefined;
  /**
   * True if the current session is owned by an employee who is impersonating an user.
   */
  impersonating: boolean | undefined;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  dwolla_customer_id: string | undefined; // redacted
  /**
   * If true, the user is an employee. Not an employee if null or false.
   */
  is_employee: boolean | undefined;
  /**
   * TODO: This field needs to be removed.
   * @deprecated
   */
  name: string | undefined;
  /**
   * If true, this user cannot place any consumer order.
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  blacklisted: boolean | undefined; // redacted
  version: number | undefined;
  created_at: number | undefined;
  updated_at: number | undefined;
  active: boolean | undefined;
  /**
   * Use localeKey instead
   * @deprecated
   */
  language: keyof typeof Language | undefined;
  locale_key: ILocaleKey | undefined;
  marketing_email_preference: keyof typeof IUser.EmailPreference | undefined;
  sign_up_session_id: number | undefined;
  /**
   * Used to determine if a user is a retailer sub-user to allow client to restrict access.
   * A sub-user is an employee of the retailer but is not an admin of the retailer and so cannot access/update certain
   * aspects of the business eg. cannot update retailer business details.
   */
  is_sub_user: boolean | undefined;
  capabilities: Array<keyof typeof CapabilityEnum>;
  marketing_sms_preference: keyof typeof MarketingSmsPreference | undefined;
  default_shipping_address: IAddress | undefined;
  /**
   * @protobufOption (.faire.common.tokenType) = BRAND
   */
  brand_tokens: Array<string>;
}
