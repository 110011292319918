import { ISearchMakerProductsRequest } from '../../indigofair/data/ISearchMakerProductsRequest';
import { ISearchRequestReferrer } from '../../indigofair/data/ISearchRequestReferrer';

export namespace ISearchProductsFromBrandRequest {
  /**
   * Return an instance of ISearchProductsFromBrandRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<ISearchProductsFromBrandRequest>
  ): ISearchProductsFromBrandRequest =>
    ({
      filter_keys: [],
      ...(partial ?? {}),
    }) as ISearchProductsFromBrandRequest;
}

export interface ISearchProductsFromBrandRequest {
  brand_token: string | undefined;
  category: string | undefined;
  query: string | undefined;
  page_number: number | undefined;
  page_size: number | undefined;
  /**
   * The index of the first product to return (0-indexed), used with pageIndexCount.
   * Should not be set if pageNumber or pageSize are not null.
   * For example, a pageStartIndex of 10 would start the results on the 11th product, as it's zero-indexed, and return
   * pageIndexCount products.
   */
  page_start_index: number | undefined;
  /**
   * The max number of results to return, used with pageStartIndex.
   * Should not be set if pageNumber or pageSize are not null.
   * For example, a pageIndexCount of 10 would return 10 products, starting at pageStartIndex.
   */
  page_index_count: number | undefined;
  /**
   * Deprecated without replacement. We will always return SALES_PAUSED products and product options in the response.
   * @deprecated
   */
  include_sales_paused: boolean | undefined;
  /**
   * sortingHint is calculated in the backend for this request, so no need for the clients to use it
   * @deprecated
   */
  sorting_hint: ISearchMakerProductsRequest.ISortingHint | undefined;
  filter_keys: Array<string>;
  client_size: keyof typeof ISearchMakerProductsRequest.ClientSize | undefined;
  /**
   * The component that referred the user to this search.
   * This is primarily tracked for experiment metrics and data science purposes
   */
  referrer: ISearchRequestReferrer | undefined;
  ranking_boost: ISearchMakerProductsRequest.IRankingBoost | undefined;
  /**
   * If set, specifies an explicit sorting. If not set, last modified is the default.
   * sortBy is only used for ranking boost, so it should be passed by the clients using
   * the [rankingBoost] message.
   * @deprecated
   */
  sort_by: keyof typeof ISearchMakerProductsRequest.SortBy | undefined;
  return_retrieval_and_ranking_debug_info: boolean | undefined;
}
