import { featureToggle } from "@faire/web--source/common/settings/declarations/featureToggle";

/**
 * Controls whether react-scan monitoring is enabled in web-retailer.
 * https://react-scan.com/monitoring
 */
export const FE_ENABLE_REACT_SCAN_MONITORING_250107 = featureToggle({
  name: "FE_ENABLE_REACT_SCAN_MONITORING_250107",
  identifier: "RetailerTokenOrLoggedOutSessionToken",
  client: "RETAILER",
  notifyAfter: "2025-04-07",
  productArea: "FRONTEND_APIS",
});
