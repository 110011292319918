import { Storage } from "@faire/web--source/common/Storage";
import { IUser } from "@faire/web-api--source/indigofair/data/IUser";

const IS_OR_EVER_WAS_EMPLOYEE_KEY = "IS_OR_EVER_WAS_EMPLOYEE_KEY";

export const updateEmployeeState = (user: IUser | undefined) => {
  try {
    if (user) {
      const isEmployee = user.is_employee || user.impersonating;
      const isAdminOrEditor =
        user.type === IUser.Type.ADMIN || user.type === IUser.Type.EDITOR;

      if (isEmployee || isAdminOrEditor) {
        Storage.local.setItem(IS_OR_EVER_WAS_EMPLOYEE_KEY, "true");
      }
    }
  } catch (_ignore) {
    // Ignore error
  }
};

export const isOrEverWasEmployee = () =>
  Storage.local.getItem(IS_OR_EVER_WAS_EMPLOYEE_KEY) === "true";
