import { debounceIfPossible } from "@faire/web--source/common/globals/debounceIfPossible";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { isTouchDevice } from "@faire/web--source/common/isTouchDevice";
import { makeObservable } from "@faire/web--source/common/makeObservable";
import { MIN_WIDTH_BREAKPOINTS } from "@faire/web--source/common/media";
import {
  maybeSSR,
  onHydration,
} from "@faire/web--source/common/server-side-rendering/isSSR";
import {
  ViewportType,
  getEstimatedViewportType,
} from "@faire/web--source/common/user-agent/getEstimatedViewportType";
import { trackWebserverSsrViewpointMissView } from "@faire/web-api--source/events/webserver/view/ssrViewpointMiss";
import once from "lodash/once";
import { action, observable } from "mobx";

/**
 * Not compatible with React 18 SSR.
 *
 * @deprecated replace with useViewport
 */
export class ObservableWindow {
  @observable
  width: number = maybeSSR()
    ? getEstimatedViewportType().width
    : getWindow()?.innerWidth ?? 0;

  @observable
  height: number = maybeSSR()
    ? getEstimatedViewportType().height
    : getWindow()?.innerHeight ?? 0;

  @observable
  scrollY: number = maybeSSR() ? 0 : getWindow()?.scrollY ?? 0;

  @observable
  lastScrolledDirection: "up" | "down" = "down";

  @observable
  isTouchDevice: boolean = maybeSSR() ? false : isTouchDevice();

  @observable
  isWindowFocused: boolean = true;

  @observable
  isOnline = true;

  @action
  private setDimensions = (width: number, height: number) => {
    if (maybeSSR()) {
      return;
    }

    this.width = width;
    this.height = height;
  };

  @action
  private setScrollY = (scrollY: number) => {
    if (maybeSSR()) {
      return;
    }

    this.scrollY = scrollY;
  };

  @action
  private setlastScrolledDirection = (scrollY: number) => {
    if (maybeSSR()) {
      return;
    }

    if (scrollY > this.scrollY) {
      this.lastScrolledDirection = "down";
    } else if (scrollY < this.scrollY) {
      this.lastScrolledDirection = "up";
    }
  };

  private debounceSetDimensions = debounceIfPossible(this.setDimensions, 200);
  private debounceSetScrollY = debounceIfPossible(this.setScrollY, 10);
  private debounceSetlastScrolledDirection = debounceIfPossible(
    this.setlastScrolledDirection,
    10
  );

  @action
  private initializeMeasurements = () => {
    if (maybeSSR()) {
      return;
    }

    this.width = getWindow()?.innerWidth ?? 0;
    this.height = getWindow()?.innerHeight ?? 0;
    this.scrollY = getWindow()?.scrollY ?? 0;
    this.isTouchDevice = this.isTouchDevice || isTouchDevice();

    // If the current HTML is generated from SSR, we will record
    // whether the viewpoint we used in SSR is of same type as the
    // actual one.
    if (getGlobalProperty("needsHydration", false)) {
      const viewportType: ViewportType =
        this.width >= MIN_WIDTH_BREAKPOINTS.XXLARGE
          ? "xxLarge"
          : this.width >= MIN_WIDTH_BREAKPOINTS.XLARGE
          ? "xLarge"
          : this.width >= MIN_WIDTH_BREAKPOINTS.DESKTOP
          ? "desktop"
          : this.width >= MIN_WIDTH_BREAKPOINTS.TABLET
          ? "tablet"
          : "mobile";
      if (viewportType !== getEstimatedViewportType().type) {
        trackWebserverSsrViewpointMissView(
          getGlobalProperty("routeProductArea", ""),
          getGlobalProperty("templatedPath", ""),
          viewportType
        );
      }
    }
  };

  initialize = once(() => {
    this.initializeMeasurements();
    this.listenForTouch()
      .updateOnResize()
      .updateOnScroll()
      .updateOnFocus()
      .updateIsOnline();
  });

  constructor() {
    makeObservable(this);

    if (maybeSSR()) {
      onHydration(() => {
        this.initializeMeasurements();
      });
    }
  }

  private listenForTouch = () => {
    const setTouchDevice = action(() => {
      if (maybeSSR()) {
        onHydration(() => {
          this.isTouchDevice = true;
        });
      } else {
        this.isTouchDevice = true;
      }
      getWindow()?.removeEventListener("touchstart", setTouchDevice);
    });
    getWindow()?.addEventListener?.("touchstart", setTouchDevice);
    return this;
  };

  private updateOnResize = () => {
    getWindow()?.addEventListener?.(
      "resize",
      () => {
        if (maybeSSR()) {
          return;
        }

        this.debounceSetDimensions(
          getWindow()?.innerWidth ?? 0,
          getWindow()?.innerHeight ?? 0
        );
      },
      false
    );
    return this;
  };

  private updateOnScroll = () => {
    getWindow()?.addEventListener?.(
      "scroll",
      () => {
        if (maybeSSR()) {
          return;
        }

        this.debounceSetlastScrolledDirection(getWindow()?.scrollY ?? 0);
        this.debounceSetScrollY(getWindow()?.scrollY ?? 0);
      },
      false
    );
    return this;
  };

  private updateOnFocus = () => {
    getWindow()?.addEventListener?.(
      "focus",
      action(() => {
        if (maybeSSR()) {
          onHydration(() => {
            this.isWindowFocused = true;
          });
          return;
        }

        this.isWindowFocused = true;
      }),
      false
    );
    getWindow()?.addEventListener?.(
      "blur",
      action(() => {
        if (maybeSSR()) {
          onHydration(() => {
            this.isWindowFocused = false;
          });
          return;
        }

        this.isWindowFocused = false;
      }),
      false
    );
    return this;
  };

  private updateIsOnline = () => {
    getWindow()?.addEventListener?.(
      "online",
      action(() => {
        if (maybeSSR()) {
          onHydration(() => {
            this.isOnline = true;
          });
          return;
        }

        this.isOnline = true;
      }),
      false
    );
    getWindow()?.addEventListener?.(
      "offline",
      action(() => {
        if (maybeSSR()) {
          onHydration(() => {
            this.isOnline = false;
          });
          return;
        }

        this.isOnline = false;
      }),
      false
    );
    return this;
  };
}
