import { request } from '../../../..';
import { IProductPageResponse } from '../../../../indigofair/data/IProductPageResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../types';

export const path = (productToken: string) => {
  return `/api/v2/product/${productToken}`;
};

export const build = (
  productToken: string,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(productToken),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/product/:product_token';

/**
 * Product Area: PRODUCT_PAGE
 */
export function execute(productToken: string): Promise<IProductPageResponse>;
export function execute(
  productToken: string,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IProductPageResponse>>;
export function execute(
  productToken: string,
  options?: EndpointOptions
): Promise<IProductPageResponse>;
export function execute(
  productToken: string,
  options?: EndpointOptions
): Promise<IProductPageResponse | SuccessResponse<IProductPageResponse>> {
  const { route, ...requestOptions } = build(productToken, options);
  return request<IProductPageResponse>(requestOptions);
}
execute[PATH] = 'api/v2/product/productToken/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(productToken: string): Promise<IProductPageResponse>;
export function darkRead(
  productToken: string,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IProductPageResponse>>;
export function darkRead(
  productToken: string,
  options?: EndpointOptions
): Promise<IProductPageResponse>;
export function darkRead(
  productToken: string,
  options?: EndpointOptions
): Promise<IProductPageResponse | SuccessResponse<IProductPageResponse>> {
  const { route, ...requestOptions } = build(productToken, options);
  return request<IProductPageResponse>(requestOptions);
}
