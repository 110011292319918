/* eslint-disable @faire/no-overflow-scroll */
import { getDocument } from "@faire/web--source/common/globals/getDocument";

/**
 * Computes the width of the browser's scrollbar by creating a
 * forced-scrollbar div way offscreen, then sets a CSS variable
 * on the document body of the value.
 *
 * @example ```css
 * body.ReactModal__Body--open {
 *   padding-right: var(--scrollbar-width);
 *   overflow: hidden;
 *   width: 100%;
 *   @media screen and (max-width: 768px) {
 *     position: relative;
 *   }
 * }
 * ```
 */
export const initializeScrollbarWidth = () => {
  const document = getDocument();
  if (!document) {
    return;
  }
  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute(
    "style",
    `
      width: 100px;
      height: 100px;
      overflow-y: scroll;
      position: absolute;
      top: -9999px;
    `
  );
  scrollDiv.className = "scrollbar-measure";
  document.body.appendChild(scrollDiv);

  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`);
  document.body.removeChild(scrollDiv);
};
