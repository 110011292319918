import { exhaustiveSwitchWithDefault } from "@faire/web--source/common/exhaustiveSwitchWithDefault";
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { Storage } from "@faire/web--source/common/Storage";
import { useState, useCallback, useEffect } from "react";

import { setTimeoutIfPossible } from "@faire/retailer/lib/async-functions/setTimeoutIfPossible";

//                                    (scroll + delay)
//                                    ┌──────────────┐
//                                    │              │
// PRE_AUTO_SIGNUP──►POST_AUTO_SIGNUP─┤              ├─►DISPLAYABLE──►DISMISSED
//                                    │              │
//                                    └──────────────┘
//                                      (page load)

export const enum SignUpSnackbarState {
  PRE_AUTO_SIGNUP = "pre_auto_signup",
  POST_AUTO_SIGNUP = "post_auto_signup",
  DISPLAYABLE = "displayable",
  DISMISSED = "dismissed",
}

export const enum SignUpSnackbarEvent {
  AUTO_SIGNUP_OPENED = "auto_signup_opened",
  AUTO_SIGNUP_CLOSED = "auto_signup_closed",
  TIMEOUT_ELAPSED = "timeout_elapsed",
  NAVIGATED = "navigated",
  DISMISSED = "dismissed",
}

export const LOGGED_OUT_SIGN_UP_SNACKBAR_STATE =
  "logged_out_sign_up_snackbar_state";

const TIME_UNTIL_DISPLAY = 2000;

// TODO(quinlan): if expirement is successful, move this away from session storage, and into to a context store
const getStateFromStorage = (): SignUpSnackbarState => {
  return (
    (Storage.session.getItem(
      LOGGED_OUT_SIGN_UP_SNACKBAR_STATE
    ) as SignUpSnackbarState) || SignUpSnackbarState.PRE_AUTO_SIGNUP
  );
};

const setStateToStorage = (state: SignUpSnackbarState) => {
  Storage.session.setItem(LOGGED_OUT_SIGN_UP_SNACKBAR_STATE, state);
};

export const useSignUpSnackbarStateMachine = () => {
  const [isDisplayable, setIsDisplayable] = useState(
    () => getStateFromStorage() === SignUpSnackbarState.DISPLAYABLE
  );

  const updateSignUpSnackbarState = useCallback(
    (eventType: SignUpSnackbarEvent) => {
      notifySignupSnackbarEvent(eventType);
      setIsDisplayable(
        getStateFromStorage() === SignUpSnackbarState.DISPLAYABLE
      );
    },
    []
  );

  useEffect(() => {
    let timeoutId: number | undefined = undefined;
    const handleScroll = () => {
      if (getStateFromStorage() === SignUpSnackbarState.POST_AUTO_SIGNUP) {
        timeoutId = setTimeoutIfPossible(() => {
          updateSignUpSnackbarState(SignUpSnackbarEvent.TIMEOUT_ELAPSED);
        }, TIME_UNTIL_DISPLAY);
      }
    };

    getWindow()?.addEventListener("scroll", handleScroll);
    return () => {
      getWindow()?.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, [updateSignUpSnackbarState]);

  return {
    isSignUpSnackbarDisplayable: isDisplayable,
    updateSignUpSnackbarState,
  };
};

export const notifySignupSnackbarEvent = (eventType: SignUpSnackbarEvent) => {
  const state = getStateFromStorage();

  if (state === SignUpSnackbarState.DISMISSED) {
    return;
  }

  switch (eventType) {
    case SignUpSnackbarEvent.AUTO_SIGNUP_OPENED:
      setStateToStorage(SignUpSnackbarState.PRE_AUTO_SIGNUP);
      break;

    case SignUpSnackbarEvent.AUTO_SIGNUP_CLOSED:
      if (state === SignUpSnackbarState.PRE_AUTO_SIGNUP) {
        setStateToStorage(SignUpSnackbarState.POST_AUTO_SIGNUP);
      }
      break;

    case SignUpSnackbarEvent.TIMEOUT_ELAPSED:
    case SignUpSnackbarEvent.NAVIGATED:
      if (state === SignUpSnackbarState.POST_AUTO_SIGNUP) {
        setStateToStorage(SignUpSnackbarState.DISPLAYABLE);
      }
      break;

    case SignUpSnackbarEvent.DISMISSED:
      setStateToStorage(SignUpSnackbarState.DISMISSED);
      break;

    default:
      exhaustiveSwitchWithDefault()(eventType);
  }
};
