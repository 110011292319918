import { FilterSplitStrategy } from '../../indigofair/data/FilterSplitStrategy';
import { IAppliedRangeFilter } from '../../indigofair/data/IAppliedRangeFilter';
import { ISearchRequestReferrer } from '../../indigofair/data/ISearchRequestReferrer';
import { MakerValue } from '../../indigofair/data/MakerValue';
import { ProductSearchMethod } from '../../indigofair/data/ProductSearchMethod';

export namespace IQueryProductsRequest {
  /**
   * The following sort options are only enabled for Mix and Match requests
   *  All other requests can only use featured sorting
   */
  export enum SortBy {
    /**
     * This is the default sort by entirely defined by the server.
     */
    FEATURED = 'FEATURED',
    /**
     * Sort by wholesale price high to low or low to high.
     */
    WHOLESALE_PRICE_LOW_TO_HIGH = 'WHOLESALE_PRICE_LOW_TO_HIGH',
    WHOLESALE_PRICE_HIGH_TO_LOW = 'WHOLESALE_PRICE_HIGH_TO_LOW',
    /**
     * Sort by newest products first
     */
    NEWEST = 'NEWEST',
  }

  export enum Placement {
    /**
     * Obeys filter keys, and returns filter sections (default if not specified).
     */
    MAIN_GRID = 'MAIN_GRID',
    /**
     * Skips building filter sections and limit brands that were first activated within last 30 days
     * @deprecated
     */
    NEW_BRAND_CAROUSEL = 'NEW_BRAND_CAROUSEL',
  }

  export enum BrandOffFaireQueryMethod {
    BRAND_OFF_FAIRE_QUERY_METHOD_UNKNOWN = 'BRAND_OFF_FAIRE_QUERY_METHOD_UNKNOWN',
    PRODUCTS = 'PRODUCTS',
    RELATED_BRANDS = 'RELATED_BRANDS',
  }

  /**
   * Return an instance of IQueryProductsRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IQueryProductsRequest>
  ): IQueryProductsRequest =>
    ({
      maker_values: [],
      filter_keys: [],
      range_filters: [],
      previous_filter_keys: [],
      initial_request_exposed_visual_filter_option_keys: [],
      ...(partial ?? {}),
    }) as IQueryProductsRequest;
}

export interface IQueryProductsRequest {
  query: string | undefined;
  page_number: number | undefined;
  page_size: number | undefined;
  /**
   * @deprecated
   */
  maker_values: Array<keyof typeof MakerValue>;
  filter_keys: Array<string>;
  /**
   * Deprecated without replacement. Feature is being dropped
   * @deprecated
   */
  range_filters: Array<IAppliedRangeFilter>;
  return_filter_sections: boolean | undefined;
  /**
   * If true, the backend will use [previousFilterKeys] to deduce which filter keys were added or removed by the user
   * since the last request.
   */
  use_previous_filter_keys: boolean | undefined;
  /**
   * Clients should provide the value of [filterKeys] used to make the last request to this endpoint.
   * The backend uses [filterKeys] and [previousFilterKeys] to deduce which filter keys were selected or deselected by
   * the user, then:
   *   - If a filter option with sub-options was (de)selected, all its sub-options will also be (de)selected.
   *   - If a filter option with a parent was selected, its parent will be marked as partially or fully selected,
   *     depending on the states of the parent's other sub-options.
   *   - If a filter option with a parent was deselected, its parent will be marked as partially selected or
   *     deselected, depending on the states of the parent's other sub-options.
   */
  previous_filter_keys: Array<string>;
  sort_by: keyof typeof IQueryProductsRequest.SortBy | undefined;
  min_wholesale_price_cents: number | undefined;
  max_wholesale_price_cents: number | undefined;
  /**
   * If true, page number starts counting from 1, else page number starts from 0.
   */
  is_one_based_page_number: boolean | undefined;
  has_insider_free_shipping: boolean | undefined;
  /**
   * If true, allow return of available brands related to searches for unavailable brands
   */
  allow_related_brands_search: boolean | undefined;
  /**
   * If true, sort products from related brand search by product score
   */
  sort_related_brands_search_by_product_score: boolean | undefined;
  /**
   * If true, allow return of results for query pruned from right if no results originally
   */
  allow_query_pruning: boolean | undefined;
  /**
   * Deprecated. The response will always include prepacks as fake options, merged within the product options list
   * @deprecated
   */
  merge_pre_packs: boolean | undefined;
  /**
   * If true, allow return of semantically similar search results
   */
  allow_semantically_similar_results: boolean | undefined;
  /**
   * See ClickInfo.containerName.
   */
  container_name: string | undefined;
  /**
   * Only set this field to NEW_BRAND_CAROUSEL to get products for New Arrivals Carousel on search page
   */
  placement: keyof typeof IQueryProductsRequest.Placement | undefined;
  /**
   * Whether preorderable products that are visible to the retailer should be returned.
   * This might also change which filters are exposed (ie preorder-related filters).
   */
  return_preorderable_products: boolean | undefined;
  /**
   * Whether delivery / ship window filter is enabled, allowing per-client rollout.
   * Setting this to true does not mean the delivery filter will be returned, it only
   * means that it will be returned if other conditions (controlling setting enabled,
   * the number of preorder products in response greater than 0) are fulfilled.
   */
  enable_preorderable_delivery_window_filter: boolean | undefined;
  /**
   * Clients should set this field to true if the request is the first the retailer is making for a specific search
   * query. Subsequent requests for the same query with different filter keys should have this field set to false.
   * The backend might use this field to change the behaviour of the request, e.g. by auto-selecting all of the
   * promotions filter options during Faire Winter Market, so that retailers only see brands with promotions by default.
   * @deprecated
   */
  is_initial_request: boolean | undefined;
  /**
   * List of FilterOption.key
   * This is the list of visual filters that we decided to expose during first request.
   * It should be empty if this is the first time the retailer is searching for a specific search query.
   * For requests after first request(the requests which retailer stays in the search query but applies a filter option),
   * BE won't fetch possible filter options from S3 file. BE validates the options from this list.
   * @deprecated
   */
  initial_request_exposed_visual_filter_option_keys: Array<string>;
  /**
   * Return retrieval and ranking debug info in the response (only for employees).
   */
  return_retrieval_and_ranking_debug_info: boolean | undefined;
  /**
   * Should return promoted placement candidates
   */
  return_promoted_placement_products: boolean | undefined;
  brand_search_flag: boolean | undefined;
  /**
   * FE asks for this to add a breadcrumb link to the related explore page based on the search query.
   * The response proto would contain the string `relatedExploreKeyword` if an explore page exists for the search query.
   */
  return_related_explore_keyword: boolean | undefined;
  /**
   * The component that referred the user to this search.
   * This is primarily tracked for experiment metrics and data science purposes
   */
  referrer: ISearchRequestReferrer | undefined;
  /**
   * Indicates the filter split strategy that should be used to return results
   */
  filter_split_strategy: keyof typeof FilterSplitStrategy | undefined;
  brand_off_faire_search_method:
    | keyof typeof IQueryProductsRequest.BrandOffFaireQueryMethod
    | undefined;
  enable_brand_off_faire_query_response: boolean | undefined;
  do_query_spell_check: boolean | undefined;
  product_search_method: keyof typeof ProductSearchMethod | undefined;
  /**
   * brandTilesRelatedToSearch will never be included in this response
   * @deprecated
   */
  return_brand_tiles_related_to_search: boolean | undefined;
  /**
   * @deprecated
   */
  skip_auto_filter: boolean | undefined;
}
