import { request } from '../..';
import { IClickInfo } from '../../indigofair/data/IClickInfo';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../types';

export const path = () => {
  return `/api/click-track`;
};

export const build = (
  data: IClickInfo,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/click-track';

/**
 * Product Area: EVENTS
 */
export function execute(data: IClickInfo): Promise<void>;
export function execute(
  data: IClickInfo,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<void>>;
export function execute(
  data: IClickInfo,
  options?: EndpointOptions
): Promise<void>;
export function execute(
  data: IClickInfo,
  options?: EndpointOptions
): Promise<void | SuccessResponse<void>> {
  const { route, ...requestOptions } = build(data, options);
  return request<void>(requestOptions);
}
execute[PATH] = 'api/click-track/post';
export default execute;
