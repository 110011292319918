import { cachingApi } from "@faire/web--source/common/cachingApi";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { createStoreHook } from "@faire/web--source/ui/hooks/useStore";
import fetchBrand from "@faire/web-api--source/api/brand/brandToken/get";
import { IBrand } from "@faire/web-api--source/indigofair/data/IBrand";
import memoizee from "memoizee";

import { readBundledData } from "@faire/retailer/lib/bundledData";
import { getBrand } from "@faire/retailer/serialized-data/getBrand";

/**
 * @deprecated prefer to use `useBrandStore` or `withStores` instead
 */
export class BrandStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(BrandStore);

  private getPrefetchedBrand = memoizee(() => readBundledData("brand"), {
    maxAge: 1000 * 60 * 5, // 5 mins
  });

  private cachedFetchBrand = cachingApi(async (token: string) => {
    if (getBrand()?.token === token) {
      return getBrand();
    }
    const prefetched = this.getPrefetchedBrand();
    if (prefetched?.token === token) {
      return prefetched;
    }
    return await fetchBrand(token);
  });

  get prefetchedFullBrand() {
    return this.getPrefetchedBrand();
  }

  getBrand = async (
    brandToken: string | undefined
  ): Promise<IBrand | undefined> => {
    if (!brandToken) {
      return undefined;
    }
    return await this.cachedFetchBrand(brandToken);
  };
}

export const useBrandStore = createStoreHook(BrandStore);
