import { getDocumentOrThrow } from "@faire/web--source/common/globals/getDocument";
import { getCountryDisplayNameFromSessionCountry } from "@faire/web--source/common/localization/getCountryDisplayNameFromSessionCountry";
import {
  DEPRECATED_backwardsCompatibleValues,
  StrictLocalizeFunction,
} from "@faire/web--source/common/localization/localize";

import { isLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { getSessionCountry } from "@faire/retailer/serialized-data/getSessionCountry";

// We wish to access this value in other places
export const getDefaultTitle = (strictLocalize: StrictLocalizeFunction) => {
  const countryDisplayName = getCountryDisplayNameFromSessionCountry(
    getSessionCountry(),
    strictLocalize
  );
  const title = countryDisplayName
    ? // this is for the default title tag in case the page doesn't have a title tag
      // the description is outdated. Faire homepage title tag is being overriden in LoggedOutHome.tsx
      // we don't want to change the description because it will retrigger translation job
      strictLocalize(
        {
          defaultMessage:
            "Faire {country} - The Online Wholesale Marketplace & Store",
          description: { text: "Title text on Faire homepage" },
        },
        DEPRECATED_backwardsCompatibleValues({ country: countryDisplayName })
      )
    : strictLocalize({
        defaultMessage: "Faire - The Online Wholesale Marketplace & Store",
        description: { text: "Title text on Faire homepage" },
      });
  return isLoggedInRetailer()
    ? strictLocalize({
        defaultMessage: "Faire — Unique wholesale merchandise for your store.",
        description: { text: "Title text on Faire homepage" },
      })
    : title;
};

export const setDefaultTitleTag = (strictLocalize: StrictLocalizeFunction) => {
  let defaultTitleTag = getDocumentOrThrow().head.querySelector("title");
  const faireHelmetTitleTagFound = getDocumentOrThrow().head.querySelector(
    "title[data-faire-helmet]"
  );
  if (faireHelmetTitleTagFound) {
    return;
  }

  if (!defaultTitleTag) {
    defaultTitleTag = getDocumentOrThrow().createElement("title");
    getDocumentOrThrow().head.appendChild(defaultTitleTag);
  }
  defaultTitleTag.innerHTML = getDefaultTitle(strictLocalize);

  defaultTitleTag.setAttribute("data-react-helmet", "true");
};
