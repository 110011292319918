import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import type { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";
import type {
  IntlShape,
  MessageDescriptor,
} from "@formatjs/intl/lib/src/types";
import type { Options as IntlMessageFormatOptions } from "intl-messageformat/src/core";

import { messageToGibberish } from "./gibberish";

export const createFormatMessage = (
  localeKey: ILocaleKey | undefined,
  formatMessageFn: IntlShape["formatMessage"]
): IntlShape["formatMessage"] => {
  if (localeKey?.language === Language.STRING_ID) {
    return (descriptor: MessageDescriptor) => descriptor.id as string;
  }

  if (localeKey?.language === Language.GIBBERISH) {
    return (
      descriptor: MessageDescriptor,
      values: Record<string, any>,
      opts: IntlMessageFormatOptions
    ) =>
      formatMessageFn(
        {
          defaultMessage: messageToGibberish(descriptor.defaultMessage as any),
          id: descriptor.id,
        },
        values,
        opts
      );
  }

  return formatMessageFn;
};
