import { IBrandCartPromo } from '../../faire/carts/IBrandCartPromo';
import { ICartItemV2 } from '../../faire/carts/ICartItemV2';
import { IPromoDescription } from '../../faire/carts/IPromoDescription';
import { IShipmentGroup } from '../../faire/carts/IShipmentGroup';
import { FreeShippingReason } from '../../indigofair/data/FreeShippingReason';
import { IApplicableMembershipBenefits } from '../../indigofair/data/IApplicableMembershipBenefits';
import { ICollaborationInfo } from '../../indigofair/data/ICollaborationInfo';
import { IDisclaimer } from '../../indigofair/data/IDisclaimer';
import { IFaireMoney } from '../../indigofair/data/IFaireMoney';
import { IImage } from '../../indigofair/data/IImage';
import { IInformationMessage } from '../../indigofair/data/IInformationMessage';
import { IInsiderLabel } from '../../indigofair/data/IInsiderLabel';

export namespace IBrandCartV2 {
  export enum State {
    STATE_UNKNOWN = 'STATE_UNKNOWN',
    /**
     * The total of the brand cart is above brand's minimum but below maximum.
     */
    READY_FOR_CHECKOUT = 'READY_FOR_CHECKOUT',
    /**
     * The total of the brand cart is above brand's maximum.
     */
    ABOVE_MAXIMUM = 'ABOVE_MAXIMUM',
    /**
     * The total of the brand cart is below brand's minimum.
     */
    BELOW_MINIMUM = 'BELOW_MINIMUM',
    /**
     * The brand is on vacation.
     */
    ON_VACATION = 'ON_VACATION',
    /**
     * The brand cart is saved for later (and thus not checked out as a part of the overall cart).
     */
    SAVED_FOR_LATER = 'SAVED_FOR_LATER',
  }

  export enum FreeNudgeState {
    FREE_NUDGE_STATE_UNKNOWN = 'FREE_NUDGE_STATE_UNKNOWN',
    RETAILER_FIRST_ORDER_NUDGE = 'RETAILER_FIRST_ORDER_NUDGE',
    BRAND_FIRST_ORDER_NUDGE = 'BRAND_FIRST_ORDER_NUDGE',
    NO_NUDGE = 'NO_NUDGE',
  }

  export enum FaireProtectionStatus {
    FAIRE_PROTECTION_STATUS_UNKNOWN = 'FAIRE_PROTECTION_STATUS_UNKNOWN',
  }

  export namespace IBrandCartReview {
    /**
     * Return an instance of IBrandCartReview with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<IBrandCartReview>
    ): IBrandCartReview =>
      ({
        ...(partial ?? {}),
      }) as IBrandCartReview;
  }

  export interface IBrandCartReview {
    status: keyof typeof IBrandCartV2.ReviewStatus | undefined;
    /**
     * name of the person who requested a review for the cart
     */
    requested_by: string | undefined;
  }

  export enum ReviewStatus {
    REVIEW_STATUS_UNKNOWN = 'REVIEW_STATUS_UNKNOWN',
    NEEDS_REVIEW = 'NEEDS_REVIEW',
  }

  /**
   * Return an instance of IBrandCartV2 with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IBrandCartV2>): IBrandCartV2 =>
    ({
      cart_items: {},
      brand_promos: [],
      disclaimers: [],
      shipment_groups: [],
      cart_items_tooltips: {},
      ...(partial ?? {}),
    }) as IBrandCartV2;
}

export interface IBrandCartV2 {
  /**
   * @protobufOption (.faire.common.tokenType) = BRAND
   */
  brand_token: string | undefined;
  brand_name: string | undefined;
  profile_image: IImage | undefined;
  state: keyof typeof IBrandCartV2.State | undefined;
  total: IFaireMoney | undefined;
  available_to_ship_total: IFaireMoney | undefined;
  amount_over_maximum: IFaireMoney | undefined;
  amount_below_minimum: IFaireMoney | undefined;
  minimum_order_amount: IFaireMoney | undefined;
  maximum_order_amount: IFaireMoney | undefined;
  cart_items: Partial<Record<string, ICartItemV2>>;
  promo_description: IPromoDescription | undefined;
  /**
   * In the process of refactoring to allow multiple brand promos. Use `brandPromos` field instead if
   * USE_BRAND_PROMOS_LIST_220310 is true
   * @deprecated
   */
  brand_promo: IBrandCartPromo | undefined;
  brand_promos: Array<IBrandCartPromo>;
  disclaimers: Array<IDisclaimer>;
  lead_time_days: number | undefined;
  upper_bound_lead_time_days: number | undefined;
  lower_bound_lead_time_days: number | undefined;
  estimated_ship_date_formatted: string | undefined;
  estimated_get_by_date_range_formatted: string | undefined;
  shipping_time_lower_bound_days: number | undefined;
  shipping_time_upper_bound_days: number | undefined;
  vacation_start_date: number | undefined;
  vacation_end_date: number | undefined;
  is_brand_highlighted_for_promotional_event: boolean | undefined;
  has_non_returnable_items: boolean | undefined;
  is_international: boolean | undefined;
  has_ordered_from_brand: boolean | undefined;
  promo_for_only_immediate_ship_products: boolean | undefined;
  allows_scheduled_orders: boolean | undefined;
  is_above_first_order_minimum_threshold: boolean | undefined;
  has_preorders: boolean | undefined;
  saved_for_later: boolean | undefined;
  free_nudge_state: keyof typeof IBrandCartV2.FreeNudgeState | undefined;
  requested_ship_date: number | undefined;
  /**
   * @deprecated
   */
  free_shipping_reason: keyof typeof FreeShippingReason | undefined;
  shipment_groups: Array<IShipmentGroup>;
  is_top_shop: boolean | undefined;
  insider_cart_upsell_information_message: IInformationMessage | undefined;
  based_in: string | undefined;
  /**
   * Example data: 30% off at checkout ✦
   * Figma https:www.figma.com/file/AeF21BMh8kVZAT1n58GpfQ/FFWv2-%E2%80%93--Retailer-Handoff?node-id=2924%3A104908
   * @deprecated
   */
  brand_promo_string: string | undefined;
  /**
   * Deprecated! Use faireFundedPromoMinimumMessage instead.
   * @deprecated
   */
  faire_funded_promo_minimum_description: string | undefined;
  /**
   * Deprecated! FFW experience no longer needs a separate full-width banner to signal under-minimum.
   * However, we should keep it for legacy clients  because they do not have new discount tagging experience.
   * @deprecated
   */
  faire_funded_promo_minimum_message: IInformationMessage | undefined;
  /**
   * Used for FFW but no longer in use
   * @deprecated
   */
  promotional_event_discount_description: string | undefined;
  insider_benefits: IApplicableMembershipBenefits | undefined;
  insider_label: IInsiderLabel | undefined;
  insider_information_message: IInformationMessage | undefined;
  /**
   * Behaves similar to brandPromoString, but this contains more specs (such as background color).
   * This will be rendered in the cart header under the brand name.
   * Used for FFW but no longer in use
   * @deprecated
   */
  promotional_event_information_message: IInformationMessage | undefined;
  review_info: IBrandCartV2.IBrandCartReview | undefined;
  /**
   * Information on which users are collaborating on this brand cart
   */
  collaboration_info: ICollaborationInfo | undefined;
  /**
   * @protobufOption (faire.common.diffIgnore) = IF_NOT_NULL
   */
  last_updated_by_retailer_at: number | undefined;
  /**
   * @protobufOption (faire.common.diffIgnore) = IF_NOT_NULL
   */
  last_item_added_at: number | undefined;
  /**
   * A map of cart item tokens to tooltip texts that can be displayed for each cart item.
   */
  cart_items_tooltips: Partial<Record<string, string>>;
  /**
   * (FD-229056): Deprecated, waiting for old mobile releases to be removed before deletion
   * @deprecated
   */
  retailer_requires_eori: boolean | undefined;
  /**
   * @deprecated
   */
  retailer_has_eori: boolean | undefined;
  /**
   * This is true if the brand might supports guaranteed shipping. The frontend
   * should use this to decide if it needs to send two requests to backend in
   * the checkout summary call.
   */
  supports_guaranteed_shipping: boolean | undefined;
  /**
   * Information message for when the cart is below the guaranteed free shipping threshold
   */
  free_shipping_threshold_information_message: IInformationMessage | undefined;
}
