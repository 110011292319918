import { DefaultTheme } from "styled-components";

import { themeOrDefault } from "../Theme/index";
import { IMedia2Strings } from "../Theme/Media2Strings";

/**
 * Gets the media2strings from the given theme, or grabs the default
 * theme and uses that. Using this function instead of only grabbing it from
 * the theme avoids the need to set a root ThemeProvider in tests.
 *
 * Note: will not work within template strings within styled-components
 * unless they are prefixed with the styled-component "css" since they need theme injected
 */
export const responsiveMediaQueries: Record<
  keyof Omit<IMedia2Strings, "minWidthBreakpoints">,
  (args: { theme?: DefaultTheme }) => string
> = {
  mobile: ({ theme }) => themeOrDefault(theme).media2strings.mobile,
  tablet: ({ theme }) => themeOrDefault(theme).media2strings.tablet,
  desktop: ({ theme }) => themeOrDefault(theme).media2strings.desktop,
  xlarge: ({ theme }) => themeOrDefault(theme).media2strings.xlarge,
  xxlarge: ({ theme }) => themeOrDefault(theme).media2strings.xxlarge,
  tabletAndAbove: ({ theme }) =>
    themeOrDefault(theme).media2strings.tabletAndAbove,
  tabletAndBelow: ({ theme }) =>
    themeOrDefault(theme).media2strings.tabletAndBelow,
  desktopAndAbove: ({ theme }) =>
    themeOrDefault(theme).media2strings.desktopAndAbove,
  xLargeAndAbove: ({ theme }) =>
    themeOrDefault(theme).media2strings.xLargeAndAbove,
  canHover: ({ theme }) => themeOrDefault(theme).media2strings.canHover,
};
