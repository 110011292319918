import { LocationDescriptor } from "history";
import { pathToRegexp } from "path-to-regexp";

import { removeLocaleFromUrl } from "../../ui/routing/util";

export interface Match<T extends string = string, AssociatedValue = undefined> {
  route: T;
  match: Exclude<ReturnType<RegExp["exec"]>, null>;
  value: AssociatedValue;
}

export class RouteMatcher<
  RouteList extends string,
  AssociatedValue = undefined
> {
  static of = <T extends string>(...routes: T[]) =>
    new RouteMatcher<T, undefined>(routes);

  routes:
    | ReadonlyArray<[RouteList, RegExp]>
    | ReadonlyArray<[RouteList, RegExp, AssociatedValue]>;

  constructor(
    routes:
      | ReadonlyArray<RouteList>
      | ReadonlyArray<[RouteList, AssociatedValue]>
  ) {
    this.routes = Object.freeze(
      routes.map((r) => {
        if (Array.isArray(r)) {
          return [r[0], pathToRegexp(r[0]), r[1]];
        }
        return [r, pathToRegexp(r)];
      }) as typeof this.routes
    );
  }

  /**
   * Returns any route matching the given pathname.
   * Note that the pathname must not include query params.
   */
  match(pathname: string): Match<RouteList, AssociatedValue> | undefined;
  /**
   * Returns any route matching the given location.
   * Note that the pathname must not include query params.
   */
  match(
    pathname: LocationDescriptor
  ): Match<RouteList, AssociatedValue> | undefined;

  // eslint-disable-next-line @faire/prefer-arrow-methods
  match(
    pathOrLocation: string | LocationDescriptor
  ): Match<RouteList, AssociatedValue> | undefined {
    const pathname: string | undefined =
      typeof pathOrLocation === "string"
        ? pathOrLocation
        : pathOrLocation.pathname;
    if (!pathname) {
      return undefined;
    }
    const normalizedPath = removeLocaleFromUrl(pathname);
    for (const [route, regexp, value] of this.routes) {
      if (regexp.test(normalizedPath)) {
        return {
          route,
          match: regexp.exec(normalizedPath),
          value,
        } as Match<RouteList, AssociatedValue>;
      }
    }
    return undefined;
  }
}

/**
 * Returns true if the given pathname matches the given path-to-regexp route string.
 * Note that the pathname must not include query params.
 */
export function pathMatchesRoute<T extends string>(
  pathname: string,
  route: T
): boolean;
/**
 * Returns true if the given location matches the given route.
 * Note that the pathname must not include query params.
 */
export function pathMatchesRoute<T extends string>(
  pathname: LocationDescriptor,
  route: T
): boolean;

export function pathMatchesRoute<T extends string>(
  pathOrLocation: string | LocationDescriptor,
  route: T
): boolean {
  const pathname: string | undefined =
    typeof pathOrLocation === "string"
      ? pathOrLocation
      : pathOrLocation.pathname;
  if (!pathname) {
    return false;
  }
  return pathToRegexp(route).test(removeLocaleFromUrl(pathname));
}
