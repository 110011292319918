import { WebApiError } from "./WebApiError";

// Error class abstraction that enables framework agnostic error handling
export class WebApiNetworkError extends WebApiError {
  // Always undefined for this type of error
  httpStatusCode: undefined = undefined;

  constructor(message: string = "") {
    super(message, undefined, undefined);
  }
  name = "WebApiNetworkError";
}
