import { useObservable } from "@faire/web--source/ui/hooks/useObservable";

export type StoreHook<S extends object> = <T extends readonly (keyof S)[]>(
  fields: T
) => Pick<S, T[number]>;

/**
 * Creates a comparer that returns true if all `keys` in objects `a` and `b` are the same value.
 */
export const newStoreResultComparer =
  <S extends object>(keys: readonly (keyof S)[]) =>
  (a: Partial<S>, b: Partial<S>) => {
    for (const key of keys) {
      if (!Object.is(a[key], b[key])) {
        return false;
      }
    }

    return true;
  };

/** Store hook factory
 * @param store - store that we want a hook for
 * @returns - hook that observes changes to the store state
 */
export const createStoreHook =
  <S extends object>(Store: { get: () => S }): StoreHook<S> =>
  <T extends readonly (keyof S)[]>(fields: T) =>
    useObservable(
      () => {
        const store = Store.get();
        const partialStore: Partial<ReturnType<StoreHook<S>>> = {};
        for (const field of fields) {
          partialStore[field] = store[field];
        }
        return partialStore;
      },
      fields,
      newStoreResultComparer<S>(fields)
    ) as ReturnType<StoreHook<S>>;
