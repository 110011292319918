import { CountryGroup } from "@faire/web-api--source/indigofair/data/CountryGroup";
import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";

import { getTwoLetterCountryCode } from "../consts/THREE_TO_TWO_LETTER_COUNTRY_ISO_CODE";
import { getTwoLetterLanguageCode } from "../consts/THREE_TO_TWO_LETTER_LANGUAGE_CODE";
import { isNotUndefined, XOR } from "../typescriptUtils";

const getLanguageCode = (localeKey: ILocaleKey) => {
  const languageCode = getTwoLetterLanguageCode(localeKey.language!);

  if (
    languageCode === getTwoLetterLanguageCode(Language.GIBBERISH) ||
    languageCode === getTwoLetterLanguageCode(Language.STRING_ID)
  ) {
    return getTwoLetterLanguageCode(Language.ENG).toLowerCase();
  }

  return languageCode?.toLowerCase();
};
/**
 * Builds into locale language standard format i.e. en-US fr-FR de-DE
 * If for some reason language isn't returned, we should fail hard
 */
export const buildLocale = (localeKey: ILocaleKey): string => {
  const languageCode = getLanguageCode(localeKey);
  const countryCode = localeKey.country
    ? getTwoLetterCountryCode(localeKey.country)?.toUpperCase()
    : undefined;

  if (!languageCode) {
    throw new Error(`Failed to map ${localeKey.language} to locale`);
  }

  return [languageCode, countryCode].filter(isNotUndefined).join("-");
};

/**
 * Returns two letter language code from locale string constructed using buildLocale
 * @param locale locale string from buildLocale or window.navigator.languages
 */
export const languageFromLocaleString = (locale: string): string => {
  return locale.split("-")[0] ?? "";
};

type LinkByRegion = {
  link: string;
  region?: keyof typeof CountryGroup;
  // Restricted countries are necessary for overlapping country region pairs
  // i.e. If we have on set of links for Italy and another for the EU, the links for the
  // EU need to know never to show for someone in Italy
  restrictedCountries?: Array<keyof typeof ISO3166Alpha3>;
};

type LinkByCountry = {
  link: string;
  countries?: Array<keyof typeof ISO3166Alpha3>;
};

export type LanguageLinkMap = {
  [key in Language]?: XOR<LinkByRegion, LinkByCountry>[];
};

const getPdfLink = (isPdf: boolean, link: string) =>
  isPdf ? `https://docs.google.com/gview?embedded=true&url=${link}` : link;

export const getLanguageAndCountrySpecificLink = (args: {
  linkByLanguage: LanguageLinkMap;
  country: keyof typeof ISO3166Alpha3;
  region?: keyof typeof CountryGroup;
  language: keyof typeof Language;
  isPdf?: boolean;
}): string | undefined => {
  const { linkByLanguage, country, region, language, isPdf = true } = args;

  // If we do not have a link collection for the given language, return
  const linksForLanguage = linkByLanguage[language];
  if (!linksForLanguage) {
    return undefined;
  }

  // We search for a country specific link first
  const linkForCountry = linksForLanguage.find((l) =>
    l.countries?.includes(country)
  )?.link;
  if (linkForCountry) {
    return getPdfLink(isPdf, linkForCountry);
  }

  // If there is no country specific link, look for one for the region
  if (region) {
    const linkForRegion = linksForLanguage.find(
      (l) => l.region === region && !l.restrictedCountries?.includes(country)
    )?.link;
    if (linkForRegion) {
      return getPdfLink(isPdf, linkForRegion);
    }
  }

  // If there is no region specific link, look to see if there is one without any restrictions
  const defaultLink = linksForLanguage.find(
    (l) => !l.region && !l.countries?.length
  );
  if (defaultLink && !defaultLink.restrictedCountries?.includes(country)) {
    return getPdfLink(isPdf, defaultLink?.link);
  }

  // Otherwise there are no links available for this user
  return undefined;
};
