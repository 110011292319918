import { request } from '../../..';
import { IGetMessengerConversationsRequest } from '../../../indigofair/data/IGetMessengerConversationsRequest';
import { IGetMessengerConversationsResponse } from '../../../indigofair/data/IGetMessengerConversationsResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';

export const path = () => {
  return `/api/messenger/list-conversations`;
};

export const build = (
  data: IGetMessengerConversationsRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/messenger/list-conversations';

/**
 * Product Area: MESSENGER_INFRASTRUCTURE
 */
export function execute(
  data: IGetMessengerConversationsRequest
): Promise<IGetMessengerConversationsResponse>;
export function execute(
  data: IGetMessengerConversationsRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetMessengerConversationsResponse>>;
export function execute(
  data: IGetMessengerConversationsRequest,
  options?: EndpointOptions
): Promise<IGetMessengerConversationsResponse>;
export function execute(
  data: IGetMessengerConversationsRequest,
  options?: EndpointOptions
): Promise<
  | IGetMessengerConversationsResponse
  | SuccessResponse<IGetMessengerConversationsResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<IGetMessengerConversationsResponse>(requestOptions);
}
execute[PATH] = 'api/messenger/list-conversations/post';
export default execute;
