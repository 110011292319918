import { Span, Attributes, SpanOptions } from "@opentelemetry/api";

import { getSettingEnableDetailedSessionTracing } from "../settings/getSettingEnableDetailedSessionTracing";

import { TelemetryManagerNoOp } from "./TelemetryManagerNoOp";
import { TelemetryManagerOtel } from "./TelemetryManagerOtel";

export const B3_HEADER_SAMPLED = "x-b3-sampled";
export const B3_HEADER_SPAN = "x-b3-spanid";
export const B3_HEADER_TRACE = "x-b3-traceid";

export const DD_HEADER_SAMPLED = "x-datadog-sampling-priority";
export const DD_HEADER_SPAN = "x-datadog-parent-id";
export const DD_HEADER_TRACE = "x-datadog-trace-id";
export const DD_HEADER_ORIGIN = "x-datadog-origin";

export const FAIRE_SPAN_ID_TRACKING = "x-faire-span-id-tracking";
export interface ITelemetryHeaders {
  DD_HEADER_TRACE?: string | undefined;
  DD_HEADER_SPAN?: string | undefined;
  DD_HEADER_SAMPLED?: string | undefined;
  DD_HEADER_ORIGIN?: string | undefined;
  traceparent?: string | undefined;
  tracestate?: string | undefined;
}
export interface ITelemetryManager {
  initialize(serviceName: string, consoleProvider?: boolean): void;

  startTrace(
    name: string,
    attributes?: Attributes,
    options?: SpanOptions,
    useSerializedTraceContext?: boolean
  ): Span | undefined;

  endTrace(name?: string, attributes?: Attributes): void;

  getActiveTraceHeaders(): ITelemetryHeaders | undefined;

  getActiveTraceId(): string | undefined;

  startSpan(
    name: string,
    attributes?: Attributes,
    parentSpan?: Span,
    root?: boolean
  ): Span | undefined;

  endSpan(span: Span | undefined): void;

  startManagedSpan(
    name: string,
    attributes?: Attributes,
    options?: SpanOptions
  ): string | undefined;

  endManagedSpan(spanId: string, attributes?: Attributes): void;

  getManagedSpan(spanId: string): Span | null;

  getSpanRequestHeaders(span?: string | Span): ITelemetryHeaders | undefined;
}

/**
 * TelemetryManager is a singleton class that manages the OpenTelemetry
 * provider and tracer. It provides methods to start and end traces and spans,
 * and to get the headers for the active trace or span.
 * It also provides methods to start, end, and get headers for managed spans.
 * Managed spans are spans that are created by the TelemetryManager
 * and are not directly associated with the current context.
 */
export class TelemetryManager {
  private static instance: ITelemetryManager | null = null;

  static get = (): ITelemetryManager => {
    if (!this.instance) {
      if (getSettingEnableDetailedSessionTracing()) {
        this.instance = new TelemetryManagerOtel();
      } else {
        // A no-op implementation of TelemetryManager
        this.instance = new TelemetryManagerNoOp();
      }
    }
    return this.instance;
  };

  static reset = (): void => {
    this.instance = null;
  };
}
