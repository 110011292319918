/* eslint-disable @typescript-eslint/ban-types */
import qs from "query-string";

import { QueryParams, QueryParamValue } from "../QueryParams";

type ParamValue<K extends string, Q extends {}> = K extends keyof Q
  ? Q[K]
  : QueryParamValue;

export type QueryParamLookup<Q extends {} = {}> = {
  /**
   * Getter function for a single param.
   * If, in a sad edge-case, you are trying to get the param `get`,
   * you can use `#get('get')`.
   */
  get(param: string): string | null | undefined;

  /**
   * Getter function for a single param, with a fallback.
   */
  getOrDefault<K extends string, V extends ParamValue<K, Q>>(
    param: K,
    defaultValue: V
  ): V;

  /**
   * Getter function for a single param.
   * If, in a sad edge-case, you are trying to get the the param
   * `getAll`, you can use `#get('getAll')`.
   */
  getAll(param: string): string[] | null | undefined;

  /**
   * Returns all params combined as a single string.
   */
  toString(): string;

  /**
   * Get a naked object with the values, without the lookup utilities.
   */
  readonly values: Q;
};

export class QueryParamsProxyHandler<Q extends {} = {}>
  implements ProxyHandler<Q & QueryParamLookup<Q>>
{
  constructor(private parsed: Q) {}

  getSingle = (param: string) => {
    if (!(param in this.parsed)) {
      return undefined;
    }

    // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
    const value = this.parsed[param] ?? null;
    if (typeof value === "string") {
      return value;
    } else if (Array.isArray(value)) {
      return value?.[0] ?? null;
    }
    return value;
  };

  getAll = (param: string): string[] | null | undefined => {
    if (!(param in this.parsed)) {
      return undefined;
    }

    // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
    const value = this.parsed[param] ?? null;
    if (typeof value === "string") {
      return [value];
    } else if (typeof value === "object") {
      return value;
    }
    return null;
  };

  getOrDefault = <K extends keyof Q>(
    param: string,
    defaultValue: Q[K]
  ): Q[K] => {
    return this.getSingle(param) ?? defaultValue;
  };

  get values(): Q {
    return this.parsed;
  }

  get = (target: Q & QueryParamLookup<Q>, prop: string, receiver: any) => {
    if (prop === "get") {
      return this.getSingle;
    } else if (prop === "getAll") {
      return this.getAll;
    } else if (prop === "getOrDefault") {
      return this.getOrDefault;
    } else if (prop === "values") {
      return this.values;
    } else if (prop === "toString") {
      return this.toString.bind(this);
    }
    return Reflect.get(target.values, prop, receiver);
  };

  ownKeys = (target: Q & QueryParamLookup<Q>) => Reflect.ownKeys(target.values);

  getOwnPropertyDescriptor = (target: Q & QueryParamLookup<Q>, prop: string) =>
    Reflect.getOwnPropertyDescriptor(target.values, prop);

  toString = () => qs.stringify(this.values);
}

export const proxyQueryParams = <
  Q extends {} = {}
>(parsed: {}): QueryParams<Q> => {
  const proxy = new QueryParamsProxyHandler(parsed);
  // Casts are needed because we're expanding Q to QueryParams<Q>, done using the proxy.
  return new Proxy(
    proxy as unknown as QueryParams<Q>,
    proxy as unknown as ProxyHandler<QueryParams<Q>>
  );
};
