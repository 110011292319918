import { request } from '../../..';
import { ICategoryPageLayoutRequest } from '../../../indigofair/data/ICategoryPageLayoutRequest';
import { ICategoryPageLayoutResponse } from '../../../indigofair/data/ICategoryPageLayoutResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';
import qs from 'query-string';

export type QueryParameters = {
  hide_brand_tile_free_shipping: boolean | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/layout/category-page${queryString}`;
};

export const build = (
  data: ICategoryPageLayoutRequest,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    params: queryParams,
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/layout/category-page';

/**
 * Product Area: CATEGORY_PAGE_RECOMMENDATIONS
 */
export function execute(
  data: ICategoryPageLayoutRequest,
  queryParams?: QueryParameters
): Promise<ICategoryPageLayoutResponse>;
export function execute(
  data: ICategoryPageLayoutRequest,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ICategoryPageLayoutResponse>>;
export function execute(
  data: ICategoryPageLayoutRequest,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ICategoryPageLayoutResponse>;
export function execute(
  data: ICategoryPageLayoutRequest,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  ICategoryPageLayoutResponse | SuccessResponse<ICategoryPageLayoutResponse>
> {
  const { route, ...requestOptions } = build(data, queryParams, options);
  return request<ICategoryPageLayoutResponse>(requestOptions);
}
execute[PATH] = 'api/layout/category-page/post';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  data: ICategoryPageLayoutRequest,
  queryParams?: QueryParameters
): Promise<ICategoryPageLayoutResponse>;
export function darkRead(
  data: ICategoryPageLayoutRequest,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ICategoryPageLayoutResponse>>;
export function darkRead(
  data: ICategoryPageLayoutRequest,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ICategoryPageLayoutResponse>;
export function darkRead(
  data: ICategoryPageLayoutRequest,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  ICategoryPageLayoutResponse | SuccessResponse<ICategoryPageLayoutResponse>
> {
  const { route, ...requestOptions } = build(data, queryParams, options);
  return request<ICategoryPageLayoutResponse>(requestOptions);
}
