/* eslint-disable @faire/prefer-arrow-methods */
import { diag } from "@opentelemetry/api";
import type { HrTime, Span } from "@opentelemetry/api";
import { hrTime } from "@opentelemetry/core";
import { InstrumentationBase } from "@opentelemetry/instrumentation";
import type { InstrumentationConfig } from "@opentelemetry/instrumentation";

// Currently missing in typescript DOM definitions
export interface PerformanceLongTaskTiming extends PerformanceEntry {
  attribution: TaskAttributionTiming[];
}

export interface TaskAttributionTiming extends PerformanceEntry {
  containerType: string;
  containerSrc: string;
  containerId: string;
  containerName: string;
}

export interface ObserverCallbackInformation {
  longtaskEntry: PerformanceLongTaskTiming;
}

export type ObserverCallback = (
  span: Span,
  information: ObserverCallbackInformation
) => void;

export interface LongtaskInstrumentationConfig extends InstrumentationConfig {
  /** Callback for adding custom attributes to span */
  observerCallback?: ObserverCallback;
  /** Callback for creating the span, provides ability to set active trace context */
  onCreateSpan?: (startTime: HrTime) => Span | undefined;
}

const PACKAGE_VERSION = "faire-0.1";
const PACKAGE_NAME = "LongRunningTask";
export const LONGTASK_PERFORMANCE_TYPE = "LongTask";

export class LongTaskInstrumentation extends InstrumentationBase {
  readonly version: string = PACKAGE_VERSION;

  private _observer?: PerformanceObserver;

  constructor(config: LongtaskInstrumentationConfig = {}) {
    super(PACKAGE_NAME, PACKAGE_VERSION, config);
  }

  init() {}

  private isSupported() {
    if (
      typeof PerformanceObserver === "undefined" ||
      !PerformanceObserver.supportedEntryTypes
    ) {
      return false;
    }

    return PerformanceObserver.supportedEntryTypes.includes(
      LONGTASK_PERFORMANCE_TYPE
    );
  }

  private _createSpanFromEntry = (entry: PerformanceLongTaskTiming) => {
    const { observerCallback, onCreateSpan } =
      this.getConfig() as LongtaskInstrumentationConfig;

    let span: Span | undefined;

    if (onCreateSpan) {
      span = onCreateSpan(hrTime(entry.startTime));
    }

    if (!span) {
      span = this.tracer.startSpan(LONGTASK_PERFORMANCE_TYPE, {
        startTime: hrTime(entry.startTime),
      });
    }

    if (observerCallback) {
      try {
        observerCallback(span, { longtaskEntry: entry });
      } catch (err) {
        diag.error("longtask instrumentation: observer callback failed", err);
      }
    }
    span.setAttribute("longtask.name", entry.name);
    span.setAttribute("longtask.entry_type", entry.entryType);
    span.setAttribute("longtask.duration", entry.duration);

    if (Array.isArray(entry.attribution)) {
      entry.attribution.forEach((attribution, index) => {
        const prefix =
          entry.attribution.length > 1
            ? `longtask.attribution[${index}]`
            : "longtask.attribution";
        span?.setAttribute(`${prefix}.name`, attribution.name);
        span?.setAttribute(`${prefix}.entry_type`, attribution.entryType);
        span?.setAttribute(`${prefix}.start_time`, attribution.startTime);
        span?.setAttribute(`${prefix}.duration`, attribution.duration);
        span?.setAttribute(
          `${prefix}.container_type`,
          attribution.containerType
        );
        span?.setAttribute(`${prefix}.container_src`, attribution.containerSrc);
        span?.setAttribute(`${prefix}.container_id`, attribution.containerId);
        span?.setAttribute(
          `${prefix}.container_name`,
          attribution.containerName
        );
      });
    }

    span.end(hrTime(entry.startTime + entry.duration));
  };

  override enable() {
    if (!this.isSupported()) {
      this._diag.debug("Environment not supported");
      return;
    }

    if (this._observer) {
      // Already enabled
      return;
    }

    this._observer = new PerformanceObserver((list) => {
      list
        .getEntries()
        .forEach((entry) =>
          this._createSpanFromEntry(entry as PerformanceLongTaskTiming)
        );
    });
    this._observer.observe({
      type: LONGTASK_PERFORMANCE_TYPE,
      buffered: true,
    });
  }

  override disable() {
    if (!this._observer) {
      return;
    }

    this._observer.disconnect();
    this._observer = undefined;
  }
}
