export namespace IRetailerLaunchWithFaire {
  export enum Status {
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    ELIGIBLE = 'ELIGIBLE',
    APPLIED = 'APPLIED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    EXPIRED = 'EXPIRED',
    APPLICATION_IN_PROGRESS = 'APPLICATION_IN_PROGRESS',
    ON_HOLD = 'ON_HOLD',
    ON_HOLD_NO_LEASE = 'ON_HOLD_NO_LEASE',
  }

  export enum ApplicationType {
    APPLICATION_TYPE_UNKNOWN = 'APPLICATION_TYPE_UNKNOWN',
    SOFT_CREDIT_CHECK = 'SOFT_CREDIT_CHECK',
    PRE_QUALIFIED = 'PRE_QUALIFIED',
    IDV_CONFIRMED = 'IDV_CONFIRMED',
    OWF_AND_GRANTS = 'OWF_AND_GRANTS',
  }

  /**
   * Return an instance of IRetailerLaunchWithFaire with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IRetailerLaunchWithFaire>
  ): IRetailerLaunchWithFaire =>
    ({
      ...(partial ?? {}),
    }) as IRetailerLaunchWithFaire;
}

export interface IRetailerLaunchWithFaire {
  status: keyof typeof IRetailerLaunchWithFaire.Status | undefined;
  airtable_id: string | undefined;
  application_processed_at: number | undefined;
  store_opening_month: number | undefined;
  applied_at: number | undefined;
  show_entrypoints: boolean | undefined;
  entrypoints_expires_at: number | undefined;
  /**
   * @deprecated
   */
  is_pre_qualified: boolean | undefined;
  application_type:
    | keyof typeof IRetailerLaunchWithFaire.ApplicationType
    | undefined;
  has_pending_owf_uw_request: boolean | undefined;
}
