import { getDocument } from "@faire/web--source/common/globals/getDocument";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";

export const loadScript = async (
  src: string,
  globalIdentifier: string
): Promise<unknown> => {
  const existing = getGlobalProperty(globalIdentifier);
  if (existing) {
    return existing;
  }

  return new Promise((resolve, reject) => {
    const document = getDocument();
    if (!document) {
      return reject("Document not found");
    }
    const script = document.createElement("script");
    script.id = globalIdentifier;
    script.onerror = reject;
    script.onload = () => {
      resolve(getGlobalProperty(globalIdentifier));
    };
    script.src = src;

    document.body.appendChild(script);
  });
};
