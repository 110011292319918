import { trackEvent } from "@faire/web--source/common/trackEvent";
import debug from "debug";
import { History } from "history";
import { matchPath } from "react-router-dom";

import { isLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";

const logApp = debug("IF:APP");

export const startLocationLogging = (history: History) => {
  logApp("Start tracking location path changes");
  let currentPathname = history.location.pathname;

  // This homepagePath excludes elevate portal
  const homepagePath = "/";

  if (
    matchPath(homepagePath, {
      path: currentPathname,
    })
  ) {
    // We need finer tracking events to understand how log out homepage influence
    // sign up rate and other metrics
    trackEvent(
      `/view/land/${
        isLoggedInRetailer() ? "logged-in-homepage" : "logged-out-homepage"
      }`
    );
  }

  history.listen((location) => {
    if (location.pathname !== currentPathname) {
      currentPathname = location.pathname;
      if (
        matchPath(homepagePath, {
          path: currentPathname,
        })
      ) {
        // We need finer tracking events to understand how log out homepage influence
        // sign up rate and other metrics
        trackEvent(
          `/view/redirect/${
            isLoggedInRetailer() ? "logged-in-homepage" : "logged-out-homepage"
          }`
        );
      } else {
        trackEvent(currentPathname);
      }
    }
  });
};
