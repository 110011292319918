import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import qs from 'query-string';
import type { IRouteMetadata } from '../../../Route';

export type QueryParameters = {
  highlightedProduct: string | undefined;
  page: string | undefined;
  pageSize: string | undefined;
  fdb: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;

  /**
   * Parse the given raw query string or partially-parsed react-router-dom queryParams object.
   */
  export function parse(
    search: string | { [K in keyof QueryParameters]?: string } = ''
  ): QueryParameters {
    const rawParsed =
      typeof search === 'string' ? qs.parse(search) : (search ?? {});
    const parsed: any = { ...rawParsed };

    return parsed as QueryParameters;
  }
}

/**
 * Generated from Jersey path "/product-based-collection/{product_based_collection_token}"
 *
 * Product Area: CORE_COLLECTIONS
 */
export const route = '/product-based-collection/:productBasedCollectionToken';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/product-based-collection/{product_based_collection_token}',
  productArea: 'CORE_COLLECTIONS',
  pod: 'FAIRE_POD_BRAND_LISTING_QUALITY_AND_PRODUCT_ATTRIBUTES',
  pillar: 'FAIRE_PILLAR_BRAND',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path(
  productBasedCollectionToken: string,
  queryParams?: Partial<QueryParameters>
) {
  const compiledPath = toPath({
    productBasedCollectionToken,
  });
  const queryString = queryParams ? qs.stringify(queryParams) : '';
  return `${compiledPath}${queryString ? `?${queryString}` : ''}`;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (
  productBasedCollectionToken: string,
  queryParams?: Partial<QueryParameters>
): string => {
  return faireBaseUrl(3000) + path(productBasedCollectionToken, queryParams);
};

export type PathParameters = {
  productBasedCollectionToken: string;
};

export default path;
