import { request } from '../../..';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';

export const path = () => {
  return `/api/user/logout`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/user/logout';

/**
 * Product Area: USERS
 */
export function execute(): Promise<boolean>;
export function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<boolean>>;
export function execute(options?: EndpointOptions): Promise<boolean>;
export function execute(
  options?: EndpointOptions
): Promise<boolean | SuccessResponse<boolean>> {
  const { route, ...requestOptions } = build(options);
  return request<boolean>(requestOptions);
}
execute[PATH] = 'api/user/logout/post';
export default execute;
