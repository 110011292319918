import { WebApiError } from "@faire/web-api--source/WebApiError";

export const shouldRetryEndpoint = (
  failureCount: number,
  error: unknown
): boolean => {
  const hasNotExceededFailCount = failureCount < 3;
  if (!error || !(error instanceof WebApiError)) {
    return hasNotExceededFailCount;
  }

  const httpStatusCode = error?.httpStatusCode ?? 0;

  return (
    hasNotExceededFailCount && (httpStatusCode < 400 || httpStatusCode >= 500)
  );
};
