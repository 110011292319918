import { SettingConst } from '../../types';

/**
 * Pre-multiplies the images DPR to the dimensions and then rounds them to a mutliple of 360
 */
export const OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_231218 =
  'OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_231218' as SettingConst<
    'OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_231218',
    boolean,
    'RETAILER'
  >;
