/**
 * Interface for a pair of TimingMarker objects
 * @interface
 * @param {TimingMarker} start - start time of timer
 * @param {TimingMarker} [end] - end time of timer
 */
export interface TimingPair {
  start: TimingMarker;
  end?: TimingMarker;
}

/**
 * A class to create a TimingMarker instance to track time
 */
export class TimingMarker {
  private _globalMarker: number | undefined = undefined;
  constructor(startMarker?: number) {
    this._globalMarker = startMarker ? startMarker : performance.now();
  }

  get globalMarker() {
    return this._globalMarker;
  }
}

/**
 * A function to return a string value representing the time between end and start
 * or between time right now and start if end is undefined
 */
export const timingPairStringify = (pair: TimingPair): string | undefined => {
  const end = pair.end?.globalMarker ?? new TimingMarker().globalMarker;
  const start = pair.start.globalMarker;
  if (start === undefined || end === undefined || start > end) {
    return undefined;
  }
  return (end - start).toString();
};
