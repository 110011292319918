"use client";

import { Core } from "@faire/design-tokens";
import styled from "styled-components";

export const TransparencyOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${(props) => props.color ?? Core.surface.enabled}05;
  left: 0;
  top: 0;
  z-index: 2;
  pointer-events: none;
`;
