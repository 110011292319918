import { getDocument } from "@faire/web--source/common/globals/getDocument";
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { logError } from "@faire/web--source/common/logging";
import { shouldRetryEndpoint } from "@faire/web--source/common/shouldRetryEndpoint";
import { IRecordClientEventRequest } from "@faire/web-api--source/indigofair/data/IRecordClientEventRequest";
import { IUserEvent } from "@faire/web-api--source/indigofair/data/IUserEvent";
import throttle from "lodash/throttle";

export abstract class BatchEventsQueue<
  Event extends IRecordClientEventRequest | IUserEvent
> {
  private _queue: Event[] = [];

  abstract batchEvents: (events: Event[]) => Promise<void>;

  // For testing only
  protected constructor(private retry_timeout_multiplier: number = 100) {
    const window = getWindow();
    if (
      window &&
      "addEventListener" in window &&
      typeof window.addEventListener === "function"
    ) {
      window.addEventListener("visibilitychange", () => {
        if (getDocument()?.visibilityState === "hidden") {
          this.flush();
        }
      });
    }
  }

  flush = async () => {
    if (!this._queue.length) {
      return;
    }

    const sendEventPromise = this.sendEvents(this._queue);
    this._queue = [];
    await sendEventPromise;
  };

  enqueueEvent = async (event: Event) => {
    this._queue.push(event);
    return this.throttledFlush();
  };

  private throttledFlush = throttle(this.flush, 16, { leading: false });

  private sendEvents = async (events: Event[]): Promise<void> => {
    try {
      await this.batchEvents(events);
    } catch (error) {
      this.catchAndRetry(events, error);
    }
  };

  private catchAndRetry = (
    events: Event[],
    error: unknown,
    retryCounter: number = 0
  ) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (shouldRetryEndpoint(retryCounter, error)) {
          this.batchEvents(events)
            .then(resolve)
            .catch((error) =>
              this.catchAndRetry(events, error, retryCounter + 1)
            );
        } else {
          logError(`Error for batching-events. Lost ${events.length}`, {
            data: {
              events: events,
              error: error,
            },
          });
        }
        // Wait 100, 400, 900, 1600
      }, this.retry_timeout_multiplier * (retryCounter + 1) * (retryCounter + 1));
    });
  };
}
