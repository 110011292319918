import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { envName } from "@faire/web--source/common/user-agent/envName";

const getServerHost = () => {
  const serverHost = getGlobalProperty("switchboardUrl", defaultServerHost());
  return serverHost;
};

const defaultServerHost = () => {
  switch (envName()) {
    case "production":
      return "wss://www.faire.com:443";
    case "prerelease":
      return "wss://www.prerelease.faire.dev";
    case "sandbox": {
      const sandboxUrl = _getSandboxUrl();
      return sandboxUrl;
    }
    case "staging":
    default:
      return "wss://www.faire-stage.com";
  }
};

export const webSocketUrls = {
  faireGeneral: () => `${getServerHost()}/web-socket`,
  retailerNotification: () =>
    `${getServerHost()}/retailer-notification-web-socket`,
};

export const convertToSwitchBoardUrl = (url: string) =>
  url.replace("wss://www.", "wss://switchboard.");

const _getSandboxUrl = () => {
  const backendInternalUrl = getGlobalProperty("backendUrl", "");
  const re = /e2e(-fe|-e2e|-common)?(-\d+){1,2}\.sandbox(\.oci)?\.faire\.team/; // e2e-fe-58102, e2e-fe-2-24971, e2e-e2e-41313, e2e-5-10471, e2e-common-21290
  const backendUrl = backendInternalUrl.match(re);

  if (!backendUrl) {
    return "wss://backend-web";
  }

  return `wss://${backendUrl[0]}`;
};
