import memoize, { Memoized } from "memoizee";

// Default cache expiry time (5 minutes)
const CACHE_EXPIRY_TIME = 1000 * 60 * 5;

interface ICache<F extends (...args: any[]) => any> {
  cache: F & Memoized<F>;
  lookup: Set<Parameters<F>[0]>;
}

/**
 * Creates an API wrapper that caches responses when the same request payload is used.
 * Responses are used for up to 5 minutes and then are evicted from caches. This is
 * particularly useful for supporting quick back and forth navigation between list and
 * detail views.
 * @deprecated use react-query instead, which supports all the functionality in this utility. query hooks are provided by web-api.
 */
export const cachingApi = <F extends (...args: any[]) => any>(
  apiCall: F,
  optionsOrCacheExpiryTime?:
    | number
    | { cacheExpiryTime?: number; refetchBeforeExpiry?: true }
): F & Memoized<F> => {
  const options =
    typeof optionsOrCacheExpiryTime === "number"
      ? { cacheExpiryTime: optionsOrCacheExpiryTime }
      : optionsOrCacheExpiryTime;

  return memoize(apiCall, {
    normalizer: (args: Record<string, any>) => {
      const obj = { 0: args[0] };
      for (let i = 1; i < 4; i++) {
        if (args[i]) {
          // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
          obj[i] = args[i];
        }
      }
      return JSON.stringify(obj);
    },
    maxAge: options?.cacheExpiryTime ?? CACHE_EXPIRY_TIME,
    length: 4,
    promise: true,
    preFetch: options?.refetchBeforeExpiry,
  });
};

export const cachingApiWithLookup = <F extends (...args: any[]) => any>(
  apiCall: F,
  cacheExpiryTime?: number
): ICache<F> => ({
  cache: cachingApi(apiCall, cacheExpiryTime),
  lookup: new Set(),
});
