import { datadogInterceptor } from "@faire/web--source/common/events/interceptors/datadogInterceptor";
import { facebookInterceptor } from "@faire/web--source/common/events/interceptors/facebookInterceptor";
import { googleTagManagerInterceptor } from "@faire/web--source/common/events/interceptors/googleTagManagerInterceptor";
import { hotjarInterceptor } from "@faire/web--source/common/events/interceptors/hotjarInterceptor";
import { tiktokAdsInterceptor } from "@faire/web--source/common/events/interceptors/tiktokAdsInterceptor";
import { twitterAdsInterceptor } from "@faire/web--source/common/events/interceptors/twitterAdsInterceptor";
import { requestHandler as fetchRequestHandler } from "@faire/web--source/common/requests/handlers/fetch";
import { ensurePrefetchesConsumedWithin } from "@faire/web--source/common/requests/prefetch";
import { configure as configureWebMessenger } from "@faire/web--source/messenger/configure";
import {
  addRequestInterceptor,
  addResponseInterceptor,
  setRequestHandler,
  addEventInterceptor,
} from "@faire/web-api--source";

import { requestInterceptors } from "@faire/retailer/initialize/clientRequestInterceptors";

import { clientResponseInterceptors } from "./clientResponseInterceptors";

const PREFETCH_TIMEOUT_MILLIS = 30000;

export const init = () => {
  clientResponseInterceptors.forEach(addResponseInterceptor);

  requestInterceptors.forEach(addRequestInterceptor);
  setRequestHandler(fetchRequestHandler);
  configureWebMessenger({ requestHandler: fetchRequestHandler });

  addEventInterceptor(datadogInterceptor);
  addEventInterceptor(facebookInterceptor);
  addEventInterceptor(hotjarInterceptor);
  addEventInterceptor(twitterAdsInterceptor);
  addEventInterceptor(tiktokAdsInterceptor);
  addEventInterceptor(googleTagManagerInterceptor);

  ensurePrefetchesConsumedWithin(PREFETCH_TIMEOUT_MILLIS);
};
