export const createMediaQueryString = ({
  min,
  max,
}: {
  min?: string;
  max?: string;
}): string => {
  const minQuery = min ? `and (min-width: ${min}) ` : "";
  const maxQuery = max ? `and (max-width: ${max}) ` : "";
  return `@media screen ${minQuery}${maxQuery}`;
};

export type MinWidthBreakpoints = Readonly<{
  MOBILE: number;
  TABLET: number;
  DESKTOP: number;
  XLARGE: number;
  XXLARGE: number;
}>;

export const MIN_WIDTH_BREAKPOINTS: MinWidthBreakpoints = {
  MOBILE: 0,
  TABLET: 768,
  DESKTOP: 1024,
  XLARGE: 1440,
  XXLARGE: 1920,
};

const RESPONSIVE = {
  mobile: { max: `${MIN_WIDTH_BREAKPOINTS.TABLET - 1}px` },
  tablet: {
    min: `${MIN_WIDTH_BREAKPOINTS.TABLET}px`,
    max: `${MIN_WIDTH_BREAKPOINTS.DESKTOP - 1}px`,
  },
  desktop: {
    min: `${MIN_WIDTH_BREAKPOINTS.DESKTOP}px`,
    max: `${MIN_WIDTH_BREAKPOINTS.XLARGE - 1}px`,
  },
  xlarge: {
    min: `${MIN_WIDTH_BREAKPOINTS.XLARGE}px`,
    max: `${MIN_WIDTH_BREAKPOINTS.XXLARGE - 1}px`,
  },
  xxlarge: { min: `${MIN_WIDTH_BREAKPOINTS.XXLARGE}px` },
  tabletAndBelow: {
    max: `${MIN_WIDTH_BREAKPOINTS.DESKTOP - 1}px`,
  },
  tabletAndAbove: {
    min: `${MIN_WIDTH_BREAKPOINTS.TABLET}px`,
  },
  desktopAndAbove: {
    min: `${MIN_WIDTH_BREAKPOINTS.DESKTOP}px`,
  },
  xLargeAndAbove: {
    min: `${MIN_WIDTH_BREAKPOINTS.XLARGE}px`,
  },
};

/** @deprecated use media2strings from theme instead */
export const media2strings = {
  mobile: createMediaQueryString(RESPONSIVE.mobile),
  tablet: createMediaQueryString(RESPONSIVE.tablet),
  desktop: createMediaQueryString(RESPONSIVE.desktop),
  xlarge: createMediaQueryString(RESPONSIVE.xlarge),
  xxlarge: createMediaQueryString(RESPONSIVE.xxlarge),
  tabletAndAbove: createMediaQueryString(RESPONSIVE.tabletAndAbove),
  tabletAndBelow: createMediaQueryString(RESPONSIVE.tabletAndBelow),
  desktopAndAbove: createMediaQueryString(RESPONSIVE.desktopAndAbove),
  xLargeAndAbove: createMediaQueryString(RESPONSIVE.xLargeAndAbove),
  canHover: `@media (hover: hover)`,
} as const;
