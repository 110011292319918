import { ICartItem } from '../../indigofair/data/ICartItem';

export namespace IBrandCart {
  export enum State {
    STATE_UNKNOWN = 'STATE_UNKNOWN',
    /**
     * The brand cart is part of the currently active cart of the retailer and IS NOT saved for later.
     */
    ACTIVE = 'ACTIVE',
    /**
     * The brand cart is part of the currently active cart of the retailer and IS saved for later.
     */
    SAVED_FOR_LATER = 'SAVED_FOR_LATER',
    /**
     * The brand cart has been checked out.
     */
    CHECKED_OUT = 'CHECKED_OUT',
  }

  /**
   * Return an instance of IBrandCart with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IBrandCart>): IBrandCart =>
    ({
      items: [],
      ...(partial ?? {}),
    }) as IBrandCart;
}

export interface IBrandCart {
  token: string | undefined;
  brand_token: string | undefined;
  items: Array<ICartItem>;
  state: keyof typeof IBrandCart.State | undefined;
  /**
   * @protobufOption (faire.common.diffIgnore) = IF_NOT_NULL
   */
  checked_out_at: number | undefined;
  requested_ship_date: number | undefined;
  eligible_for_insider: boolean | undefined;
  /**
   * @deprecated
   * @protobufOption (faire.common.diffIgnore) = ALWAYS
   */
  updated_at: number | undefined;
  /**
   * @protobufOption (faire.common.diffIgnore) = IF_NOT_NULL
   */
  nudge_acknowledged_at: number | undefined;
  /**
   * @protobufOption (faire.common.diffIgnore) = IF_NOT_NULL
   * @protobufOption (faire.common.maxDelta) = 3000
   */
  last_updated_by_retailer_at: number | undefined;
  /**
   * @protobufOption (faire.common.diffIgnore) = IF_NOT_NULL
   * @protobufOption (faire.common.maxDelta) = 3000
   */
  last_item_added_at: number | undefined;
}
