export enum LineItemStyle {
  DEFAULT = 'DEFAULT',
  BRAND_PROMO = 'BRAND_PROMO',
  TOTAL = 'TOTAL',
  FREE_SHIPPING = 'FREE_SHIPPING',
  STRIKETHROUGH = 'STRIKETHROUGH',
  /**
   * @deprecated
   */
  INSIDER = 'INSIDER',
  /**
   * @deprecated
   */
  INSIDER_UPSELL_V2 = 'INSIDER_UPSELL_V2',
  /**
   * Used for the UK/EU free duties promo.
   */
  FREE_DUTIES = 'FREE_DUTIES',
  /**
   * Used to represent the FFW Discount. Show it as a Brand Promo but with the special event color.
   */
  FAIRE_FASHION_WEEK_DISCOUNT = 'FAIRE_FASHION_WEEK_DISCOUNT',
  SUB_TOTAL = 'SUB_TOTAL',
  /**
   * Used for promo given by Faire.
   */
  FAIRE_PROMO = 'FAIRE_PROMO',
  ELEVATE_CREDIT = 'ELEVATE_CREDIT',
  R2M_CREDIT = 'R2M_CREDIT',
  FAIRE_CREDIT = 'FAIRE_CREDIT',
  /**
   * @deprecated
   */
  CARBON_OFFSET = 'CARBON_OFFSET',
  INSIDER_BENEFIT = 'INSIDER_BENEFIT',
  /**
   * Used for promotions given due to a loyalty relationship that a retailer has with a brand.
   * @deprecated
   */
  RETAILER_BRAND_LOYALTY_PROMO = 'RETAILER_BRAND_LOYALTY_PROMO',
  /**
   * A promo for a Faire promotional event. Like a BRAND_PROMO but with different styling on FE.
   */
  FAIRE_PROMOTIONAL_EVENT_PROMO = 'FAIRE_PROMOTIONAL_EVENT_PROMO',
  SHIPPING_ESTIMATE = 'SHIPPING_ESTIMATE',
  /**
   * Used for when the line item free shipping is from FST.
   */
  BRAND_FREE_SHIPPING_THRESHOLD = 'BRAND_FREE_SHIPPING_THRESHOLD',
  /**
   * Used for payment line item.
   */
  PAYMENT_DUE = 'PAYMENT_DUE',
}
