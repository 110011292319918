import { Storage } from "@faire/web--source/common/Storage";

import {
  E2E_TEST_TOKEN_HEADER,
  E2E_TEST_TOKEN_LOCAL_STORAGE_KEY,
} from "../../consts/E2E_TEST_TOKEN";

import { headerInterceptorFactory } from "./headerInterceptor";

export const e2eTestTokenInterceptor = headerInterceptorFactory({
  header: E2E_TEST_TOKEN_HEADER,
  value: () => Storage.local.getItem(E2E_TEST_TOKEN_LOCAL_STORAGE_KEY),
});
