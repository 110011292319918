import { MessageFormatElement } from "intl-messageformat-parser/no-parser";

export const GIBBERISH_TEXT_MODE_KEY = "IF_GIBBERISH_TEXT_MODE";
export const GIBBERISH_TEXT_MODE_COOKIE = "X-IF-GIBBERISH-MODE";

/**
 * Text returned by create gibberish will have a length according to the formula:
 * length = originalLength * (1 / GIBBERISH_LENGTH_FACTOR) + 2
 *
 * The + 2 comes from the chevron added on either side of the gibberish string.
 *
 * For example, if GIBBERISH_LENGTH_FACTOR = 2, messageToGibberish will return strings
 * roughly 1.5x the original string length
 */
const GIBBERISH_LENGTH_FACTOR = 2;

const GIBBERISH_CHARS = "áβçδèƒϱhïJƙℓ₥ñôƥ9řƨƭúƲωж¥ƺ";

/**
 * Turns english default message into gibberish for testing purposes. Should only
 * be used by LocalMsg and localize. Expects to be called with
 * MessageFormatElement[]. Can be called with string if the fn calling it is
 * registered in our tsconfig under additionalComponentNames. This will cause
 * the string to be compiled to a MessageFormatElement by formatjs
 *
 * @param defaultMessage a MessageFormatElement[] to be turned to gibberish
 */
export const messageToGibberish = (
  defaultMessage: string | MessageFormatElement[] | undefined
): MessageFormatElement[] | string => {
  if (defaultMessage === undefined) {
    return [];
  }
  if (typeof defaultMessage === "string") {
    return stringToGibberish(defaultMessage);
  }

  return defaultMessage.map((messageObject) => {
    if (messageObject.type === 0 && "value" in messageObject) {
      messageObject.value = stringToGibberish(messageObject.value);
    }
    if (messageObject.type === 6 && "options" in messageObject) {
      Object.keys((messageObject as any).options ?? []).forEach((key) => {
        messageToGibberish(messageObject.options[key]?.value);
      });
    }
    if (messageObject.type === 8 && "children" in messageObject) {
      messageToGibberish(messageObject.children);
    }
    return messageObject;
  });
};

export const stringToGibberish = (message: string) => {
  const charStartIndex = "a".charCodeAt(0);

  const gibberishString = message
    .toLowerCase()
    .split("")
    .map((char, index) => {
      const charCode = char.charCodeAt(0) - charStartIndex;
      const newChar =
        charCode >= 0 && charCode < GIBBERISH_CHARS.length
          ? GIBBERISH_CHARS[charCode]
          : char;

      // add tilde after some chars to increase the string length
      if (index % GIBBERISH_LENGTH_FACTOR === 0) {
        return `${newChar}~`;
      } else {
        return newChar;
      }
    })
    .join("");

  return `>${gibberishString}<`;
};
