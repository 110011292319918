import { IPromiseBasedObservable } from "mobx-utils";

type TypeChecker = (value: any) => boolean;

/**
 * Util to check if a value is of a certain type or not.
 *
 * memberOrChecker can by either a (single or list of) property name
 * or a function with the following signature (value: any): boolean
 *
 * Example:
 *
 * ```ts
 * interface IInterface {
 *   member: string;
 *   anotherMember?: string;
 *   yetAnotherMember?: string;
 * }
 *
 * isInstanceOf<IInterface>(value, "member");
 * isInstanceOf<IInterface>(value, ["anotherMember", "yetAnotherMember"]);
 * isInstanceOf<IInterface[]>(someArray, Array.isArray);
 * ```
 *
 * @param value of unkown type
 * @param memberOrChecker key or function to check whether value is instance of T
 * @returns boolean
 */
const isInstanceOf = <T extends object>(
  value: unknown,
  memberOrChecker: keyof T | Array<keyof T> | TypeChecker
): value is T => {
  if (typeof memberOrChecker === "function") {
    return memberOrChecker(value);
  }
  if (
    value === null ||
    ["boolean", "number", "undefined"].includes(typeof value)
  ) {
    // at this point we cannot use `in` operator so we should return false
    return false;
  }
  if (Array.isArray(memberOrChecker)) {
    return memberOrChecker.some((member) => member in (value as T));
  }
  return memberOrChecker in (value as T);
};

/**
 * Util to coerce an unknown value if the value is of a certain type otherwise, returns undefined.
 *
 * memberOrChecker can by either a (single or list of) property name
 * or a function with the following signature (value: any): boolean
 *
 * Example:
 *
 * ```ts
 * interface IInterface {
 *   member: string;
 *   anotherMember?: string;
 *   yetAnotherMember?: string;
 * }
 *
 * // returns value as T or undefined
 * asInstanceOrUndefined<IInterface>(value, "member");
 * asInstanceOrUndefined<IInterface>(value, ["anotherMember", "yetAnotherMember"]);
 * asInstanceOrUndefined<IInterface[]>(someArray, Array.isArray);
 * ```
 *
 * @param value of unkown type
 * @param memberOrChecker key or function to check whether value is instance of T
 * @returns value as T or undefined
 */
export const asInstanceOrUndefined = <T extends object>(
  value: unknown,
  memberOrChecker: keyof T | Array<keyof T> | TypeChecker
): T | undefined => {
  if (isInstanceOf<T>(value, memberOrChecker)) {
    return value;
  }
  return undefined;
};

/**
 * Util to return fulfilled/stale coerced as T or undefined or fallback.
 *
 * memberOrChecker can by either a (single or list of) property name
 * or a function with the following signature (value: any): boolean
 *
 * Example:
 *
 * ```ts
 * interface IInterface {
 *   token: string;
 *   anotherMember?: string;
 *   yetAnotherMember?: string;
 * }
 *
 * result: IPromiseBasedObservable<IInterface> = fromPromise(...);
 *
 * // returns the resolved value or the stale if the last is "asInstanceOrUndefined" IInterface or undefined
 * fulfilledOrStale(result, "member");
 * fulfilledOrStale(result, ["anotherMember", "yetAnotherMember"]);
 * fulfilledOrStale(arrayResult, Array.isArray);
 *
 * // returns the resolved value or the stale or fallback if the first two are undefined
 * fulfilledOrStale(result, "member", IInterface.build(...));
 * ```
 *
 * @param observablePromise IPromiseBasedObservable
 * @param memberOrChecker key or function to check whether value is instance of T
 * @param fallback value to return in case the resolved value of the observablePromise is undefined
 * @returns value as T or undefined
 */
export function fulfilledOrStale<T>(
  observablePromise: IPromiseBasedObservable<T | undefined> | undefined,
  memberOrChecker: keyof T | Array<keyof T> | TypeChecker,
  fallback: T
): T;
export function fulfilledOrStale<T>(
  observablePromise: IPromiseBasedObservable<T> | undefined,
  memberOrChecker: keyof T | Array<keyof T> | TypeChecker,
  fallback: T
): T;
export function fulfilledOrStale<T>(
  observablePromise: IPromiseBasedObservable<T | undefined> | undefined,
  memberOrChecker: keyof T | Array<keyof T> | TypeChecker
): T | undefined;
export function fulfilledOrStale<T>(
  observablePromise: IPromiseBasedObservable<T> | undefined,
  memberOrChecker: keyof T | Array<keyof T> | TypeChecker
): T | undefined;

export function fulfilledOrStale<T extends object>(
  observablePromise: IPromiseBasedObservable<T | undefined> | undefined,
  memberOrChecker: keyof T | Array<keyof T> | TypeChecker,
  fallback?: T
): T | undefined {
  return (
    observablePromise?.case({
      fulfilled: (response) => response,
      pending: (stale) => asInstanceOrUndefined<T>(stale, memberOrChecker),
    }) ?? fallback
  );
}
