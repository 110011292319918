import { DEFAULT_LOCALES } from "@faire/web--source/common/consts/DEFAULT_LOCALES";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import daDateLocal from "date-fns/locale/da";
import deDateLocal from "date-fns/locale/de";
import enDateLocal from "date-fns/locale/en-US";
import esDateLocal from "date-fns/locale/es";
import frDateLocal from "date-fns/locale/fr";
import itDateLocal from "date-fns/locale/it";
import nlDateLocal from "date-fns/locale/nl";
import ptDateLocal from "date-fns/locale/pt";
import svDateLocal from "date-fns/locale/sv";

import { LocalizationStore } from "../LocalizationStore";

/**
 * @deprecated use `useDateLocaleStore` or `withStores` instead
 */
export class DateLocaleStore {
  static get = singletonGetter(DateLocaleStore);

  private _dateLocale!: Locale;

  constructor() {
    this.init();
  }

  init = () => {
    const locale = LocalizationStore.get().locale;
    const simplifiedLocale = locale.split("-")[0] ?? "";
    this._dateLocale =
      // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      DATE_LOCALES[locale] ||
      // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      DATE_LOCALES[simplifiedLocale] ||
      DATE_LOCALES["en"];
  };

  get dateLocale() {
    return this._dateLocale;
  }
}
const gibberishDateLocal: Locale = {
  code: DEFAULT_LOCALES[Language.GIBBERISH],
};

const DATE_LOCALES = {
  "en-US": enDateLocal,
  en: enDateLocal,
  "fr-FR": frDateLocal,
  fr: frDateLocal,
  "de-DE": deDateLocal,
  de: deDateLocal,
  "it-IT": itDateLocal,
  it: itDateLocal,
  "es-ES": esDateLocal,
  es: esDateLocal,
  "sv-SE": svDateLocal,
  sv: svDateLocal,
  "pt-PT": ptDateLocal,
  pt: ptDateLocal,
  "da-DK": daDateLocal,
  da: daDateLocal,
  "nl-NL": nlDateLocal,
  nl: nlDateLocal,
  xa: gibberishDateLocal,
};
