import { HEADER_E2E_AUTH } from "@faire/web--source/common/consts/HEADER_E2E_AUTH";
import { EnvironmentFactory } from "@faire/web--source/common/EnvironmentStore";

import { headerInterceptorFactory } from "./headerInterceptor";

const Env = EnvironmentFactory<{ E2E_AUTH: string }>();

export const e2eAuthHeaderInterceptor = headerInterceptorFactory({
  header: HEADER_E2E_AUTH,
  value: () => Env.get().getOrDefault("E2E_AUTH", undefined),
});
