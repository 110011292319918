import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { logError } from "@faire/web--source/common/logging";
import { getSettingFacebookRetailerPixelId } from "@faire/web--source/common/settings/getSettingFacebookRetailerPixelId";
import { TrackedEvent } from "@faire/web-api--source/types";

export const facebookInterceptor = (params: TrackedEvent) => {
  if (params.facebookPixelEvent) {
    try {
      emitFacebookPixelEvent(
        params.facebookPixelEvent,
        params.pixelCustomDataParams,
        { eventID: params.eventId }
      );
    } catch (_) {
      /* ignore */
    }
  }
};

/* docs: https://developers.facebook.com/docs/facebook-pixel/implementation */
declare function facebookPixel(
  _: "trackSingle" | "trackSingleCustom",
  pixel: string,
  event: string,
  value: Partial<Record<string, any>> | undefined,
  context: { eventID: string }
): void;

export const emitFacebookPixelEvent = (
  event: string,
  vars: Partial<Record<string, any>> | undefined,
  context: { eventID: string }
) => {
  const fbq = getGlobalProperty<typeof facebookPixel>("fbq");
  // NOTE(luke): We originally split facebookRetailerPixelId and facebookMakerPixelId,
  // but consolidated them when moving to PETE-driven facebook pixel events.
  const facebookRetailerPixelId = getSettingFacebookRetailerPixelId();
  if (!fbq) {
    logError(`fbq not initialized`);
    return;
  } else if (!facebookRetailerPixelId) {
    if (getGlobalProperty("envName") === "production") {
      logError(`facebookRetailerPixelId is not specified`);
    }
    return;
  }
  fbq("trackSingleCustom", facebookRetailerPixelId, event, vars, context);
};
