export namespace IGetMessengerConversationsRequest {
  export enum Filter {
    FILTER_UNKNOWN = 'FILTER_UNKNOWN',
    UNREAD = 'UNREAD',
    STARRED_BY_BRAND = 'STARRED_BY_BRAND',
  }

  /**
   * Return an instance of IGetMessengerConversationsRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IGetMessengerConversationsRequest>
  ): IGetMessengerConversationsRequest =>
    ({
      ...(partial ?? {}),
    }) as IGetMessengerConversationsRequest;
}

export interface IGetMessengerConversationsRequest {
  /**
   * Query for searching conversations.
   */
  query: string | undefined;
  /**
   * Context token.
   * This can be a:
   * 1. Retailer token if the requesting Brand is visiting a Retailer invoice.
   * 2. Brand token if the Retailer is visiting a brand page.
   */
  context_token: string | undefined;
  /**
   * The maximum number of results to return. This is capped at 20 by the server.
   */
  max_results: number | undefined;
  /**
   * Not to be used directly by clients. Will be automatically set in response's nextRequest.
   */
  pagination_token: number | undefined;
  /**
   * Deprecated - Use filter instead
   * @deprecated
   */
  only_unread: boolean | undefined;
  filter: keyof typeof IGetMessengerConversationsRequest.Filter | undefined;
}
