import { isNotUndefined } from "./typescriptUtils";

/**
 * Use this method when getting all values from a web-api map type.
 * To be type safe, they're `Partial<Record<string, Foo>>`, which causes
 * Object.values(theMap) to be Array<Foo | undefined>.
 */
export const mapValues = <K extends string | number | symbol, T>(
  partial: Partial<Record<K, T>>
): T[] => {
  if (!partial) {
    return [];
  }
  return Object.values(partial).filter(isNotUndefined) as T[];
};
