import { IFaireMoney } from '../../indigofair/data/IFaireMoney';

export namespace IOpeningOrderDiscountData {
  /**
   * Return an instance of IOpeningOrderDiscountData with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IOpeningOrderDiscountData>
  ): IOpeningOrderDiscountData =>
    ({
      ...(partial ?? {}),
    }) as IOpeningOrderDiscountData;
}

export interface IOpeningOrderDiscountData {
  is_retailer_eligible: boolean | undefined;
  /**
   * @protobufOption (faire.protobuf.validation) = TIMESTAMP_MILLIS
   */
  expires_at: number | undefined;
  /**
   * @protobufOption (faire.protobuf.validation) = BASIS_POINTS
   */
  discount_bps: number | undefined;
  discount_bps_cap_amount: IFaireMoney | undefined;
  recurring: boolean | undefined;
}
