import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { Path } from "@faire/web--source/common/Path";
import { trackSignupOpenModalClick } from "@faire/web-api--source/events/signup/click/openModal";

import { SignUpElement, SignUpType } from "./popSignUp";

export function getSignUpLink(
  type: SignUpType,
  location: { pathname: string; search: string }
) {
  return new Path(location).replace({ signUp: type }).toString();
}

export function trackSignUpClick(
  type: SignUpType,
  element: SignUpElement,
  sourceId: string | undefined
) {
  const location = getLocationOrThrow();
  const signUpType = type === SignUpType.GENERIC ? "generic" : type;

  trackSignupOpenModalClick(
    signUpType,
    location.pathname + location.search,
    sourceId ?? "",
    element
  );
}
