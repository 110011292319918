/**
 * The container or page on which the component can show. This allows for filtering through the API
 * so that the client can specify where they are asking to show components
 */
export enum ConductorSurface {
  CONDUCTOR_SURFACE_UNKNOWN = 'CONDUCTOR_SURFACE_UNKNOWN',
  /**
   * @deprecated
   */
  ANY = 'ANY',
  /**
   * @deprecated
   */
  HOME_PAGE_TOP = 'HOME_PAGE_TOP',
  /**
   * @deprecated
   */
  CHECKOUT_PAGE_TOP = 'CHECKOUT_PAGE_TOP',
  /**
   * Represents the surface available for conductor below the checkout summary.
   * Commonly used for checkout nudges
   */
  CHECKOUT_PAGE_ORDER_SUMMARY = 'CHECKOUT_PAGE_ORDER_SUMMARY',
  /**
   * Represents the top sections used to house banners that persist across multiple pages on the faire platform.
   */
  SITE_WIDE_TOP_BANNER = 'SITE_WIDE_TOP_BANNER',
  /**
   * Represents a modal that appears as a page/screen takeover on a variety of areas of the faire platform
   */
  SITE_WIDE_MODAL = 'SITE_WIDE_MODAL',
  /**
   * Represents a modal that appears on the bottom section of the screen
   */
  BOTTOM_SHEET_MODAL = 'BOTTOM_SHEET_MODAL',
  /**
   * Represents the surface available for conductor above the list of stores on the retailer stores page.
   */
  RETAILER_STORES_PAGE_TOP = 'RETAILER_STORES_PAGE_TOP',
}
