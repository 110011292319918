import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'signup-close_sign_up_modal-click';

/**
 * User clicked on the &#x60;x&#x60; button to close the sign up modal (non FD)
 */
export function trackSignupCloseSignUpModalClick(): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
  });
}
