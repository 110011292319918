import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { isLocal } from "@faire/web--source/common/user-agent/isLocal";

export const getEnvName = ():
  | "production"
  | "staging"
  | "prerelease"
  | undefined => getGlobalProperty("envName");

export const getIsProduction = (): boolean => getEnvName() === "production";

export const getIsStaging = (): boolean =>
  !isLocal() && getEnvName() === "staging";

export const getIsPrerelease = (): boolean =>
  !isLocal() && getEnvName() === "prerelease";

export const getSafeEnvName = (): "production" | "staging" | "prerelease" => {
  if (getIsProduction()) {
    return "production";
  } else if (getIsPrerelease()) {
    return "prerelease";
  }

  return "staging";
};
