import { SettingConst } from '../../types';

/**
 * Configuration for how long it should take to resurface the recurring FD marketplace modal given that retailer
 * has not used their offer.
 */
export const FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_VISIT_STARTED_THRESHOLD_MILLIS =
  'FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_VISIT_STARTED_THRESHOLD_MILLIS' as SettingConst<
    'FAIRE_DIRECT_RECURRING_MARKETPLACE_MODAL_VISIT_STARTED_THRESHOLD_MILLIS',
    number,
    'RETAILER'
  >;
