/* eslint-disable @typescript-eslint/ban-types */
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { parseQueryParams } from "@faire/web--source/common/query-params/parseQueryParams";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import forEach from "lodash/forEach";

import { QueryParamValue, RawQueryParams } from "./query-params/QueryParams";

export class Path<Q extends {} = {}> {
  pathname: string;
  searchParams: RawQueryParams<Q>;

  constructor({ pathname, search }: { pathname: string; search: string }) {
    this.pathname = pathname;
    this.searchParams = parseQueryParams<Q>(search);
  }

  delete = (paramNames: string | string[]) => {
    if (typeof paramNames === "string") {
      // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      delete this.searchParams.values[paramNames];
    } else {
      paramNames.forEach((name) => {
        // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
        delete this.searchParams.values[name];
      });
    }

    return this;
  };

  append = (params: Record<string, QueryParamValue>) => {
    forEach(params, (value, key) => {
      if (value === undefined) {
        return;
      }

      // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      const curValue = this.searchParams[key];

      // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      this.searchParams.values[key] = curValue
        ? [...flatten([curValue]), ...flatten([value])]
        : value;
    });
    return this;
  };

  replace = (params: Record<string, string | string[] | undefined>) => {
    forEach(params, (value, key) => {
      if (value === undefined) {
        return;
      }

      // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      this.searchParams.values[key] = value;
    });
    return this;
  };

  toString = () =>
    compact([this.pathname, this.searchParams.toString()]).join("?");

  static getFirstSearchParam = (query?: string | null | (string | null)[]) => {
    if (!query) {
      return undefined;
    }
    return Array.isArray(query) ? query[0] : query;
  };
}

/**
 * @deprecated use getCurrentPath
 */
export const currentPath = () => getCurrentPath();

export const getCurrentPath = () => {
  const window = getWindow();
  if (!window) {
    throw new Error("Cannot resolve path without window.location");
  }
  return new Path(window.location);
};
