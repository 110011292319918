import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import type { IRouteMetadata } from '../../Route';

/**
 * Generated from Jersey path "/small-business-grant"
 *
 * builder marketing landing page: small-business-grant
 * Product Area: LOGGED_OUT_RETAILERS
 */
export const route = '/small-business-grant';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/small-business-grant',
  productArea: 'LOGGED_OUT_RETAILERS',
  pod: 'FAIRE_POD_RETAILER_ACQUISITION',
  pillar: 'FAIRE_PILLAR_RETAILER_GROWTH',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

/**
 * builder marketing landing page: small-business-grant
 */
export function path() {
  const compiledPath = toPath({});
  return compiledPath;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (): string => {
  return faireBaseUrl(3000) + path();
};

export default path;
