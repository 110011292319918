import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'retailers-react_app_init-view';

/**
 * Emitted by the entrypoint of the React app script, to indicate that the React app and vendor bundle scripts have all been parsed
 */
export function trackRetailersReactAppInitView(
  htmlSource: string,
  productArea2: string,
  Path3: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: { htmlSource, productArea2, Path3 },
  });
}
