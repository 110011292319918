import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { logWarning } from "@faire/web--source/common/logging";
import { IPrefetch } from "@faire/web--source/common/requests/prefetch";
import { trackFrontendApisPrefetchMissView } from "@faire/web-api--source/events/frontendApis/view/prefetchMiss";
import { IApiError } from "@faire/web-api--source/indigofair/data/IApiError";
import {
  NormalizedRequestHandler,
  RequestOptions,
  Response as WebApiResponse,
  SuccessResponse,
} from "@faire/web-api--source/types";
import { WebApiError } from "@faire/web-api--source/WebApiError";
import { WebApiNetworkError } from "@faire/web-api--source/WebApiNetworkError";

import {
  emitDifferences,
  isSameRequest,
} from "../../__internal__/isSameRequest";

/**
 * Factory for wrapping a request handler with a prefetch-reader, that checks for
 * an equivalent request in faire.api.prefetches to return.
 * @param delegate Request handler to invoke when no prefetches are present
 */
export const prefetchReaderFactory =
  (delegate: NormalizedRequestHandler): NormalizedRequestHandler =>
  async <T = unknown>(requestOptions: RequestOptions) => {
    // TODO(luke): Drop this cast once web-api updates.
    if ((requestOptions as { prefetch?: boolean })?.prefetch) {
      return delegate<T>(requestOptions);
    }
    const prefetches = getGlobalProperty<IPrefetch[]>("faire.api.prefetches");
    if (!prefetches?.length) {
      return delegate<T>(requestOptions);
    }

    const prefetchIndex = prefetches.findIndex((p) =>
      isSameRequest(p.request, requestOptions)
    );

    if (prefetchIndex < 0) {
      const mismatch = prefetches.find((p) =>
        samePathAndMethod(p.request, requestOptions)
      );
      if (mismatch) {
        trackFrontendApisPrefetchMissView(mismatch.request.route ?? "");
        logWarning(
          `Found differing requests for ${mismatch.request.method} ${mismatch.request.route}`,
          {
            data: {
              prefetch: mismatch,
              request: requestOptions,
            },
          }
        );
        emitDifferences(requestOptions, mismatch.request);
      }
      return delegate<T>(requestOptions);
    }

    const [prefetch] = prefetches?.splice(prefetchIndex, 1) ?? [];
    const response = prefetch?.response;
    if (!response) {
      logWarning(
        `Prefetch for ${requestOptions.baseUrl ?? ""}${
          requestOptions.url
        } has no response`,
        {
          data: {
            prefetch,
          },
        }
      );
      return delegate<T>(requestOptions);
    }

    // eslint-disable-next-line no-console
    console.log(
      `Leveraging prefetch for ${requestOptions.method} ${
        requestOptions.baseUrl ?? ""
      }${requestOptions.url}`
    );
    try {
      const rawResponse = await response;
      if ("error" in response) {
        return response as WebApiResponse<T>;
      }
      const responseStatus = rawResponse.status;
      if (!!responseStatus && responseStatus >= 400) {
        logFailedResponse(
          `Prefetch of ${prefetch.request.method} ${
            prefetch.request.baseUrl ?? ""
          }${prefetch.request.url} failed with ${responseStatus}`,
          prefetch.request,
          rawResponse as SuccessResponse<T>
        );
        // TODO(ggayowsky): Remove once RequestHandler always returns Response<T>
        return delegate<T>(requestOptions) as Promise<WebApiResponse<T>>;
      } else {
        return rawResponse as SuccessResponse<T>;
      }
    } catch {
      // TODO: Gracefully handle cases where resp.json() cannot parse the payload
      // For now, since using fetch protocol for prefetch, assume that if awaiting the response results in an error,
      // consider it a connection issue during the fetch as prefetech currently aren't cancellable
      logFailedResponse(
        new WebApiNetworkError("Network Error during prefetch"),
        prefetch.request
      );
      // TODO(ggayowsky): Remove once RequestHandler always returns Response<T>
      return delegate<T>(requestOptions) as Promise<WebApiResponse<T>>;
    }
  };

const logFailedResponse = <T = unknown>(
  messageOrError: string | WebApiError | WebApiNetworkError,
  request: RequestOptions,
  response?: SuccessResponse<T>
) => {
  let error = messageOrError;
  if (typeof messageOrError === "string") {
    error = new WebApiError(
      messageOrError,
      response?.status,
      IApiError.build(response?.data as unknown as IApiError)
    );
  }
  logWarning(error, {
    data: {
      request: request,
      status: response?.status,
      headers: response?.headers,
    },
  });
};

const samePathAndMethod = (a: RequestOptions, b: RequestOptions): boolean => {
  return a.url === b.url && a.method?.toLowerCase() === b.method?.toLowerCase();
};
