import { request } from '../../../../..';
import { IProductFirstCatNavRequest } from '../../../../../indigofair/data/IProductFirstCatNavRequest';
import { IProductFirstCatNavWithLeanProductTileResponse } from '../../../../../indigofair/data/IProductFirstCatNavWithLeanProductTileResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../../types';

export const path = () => {
  return `/api/v2/search/products/cat-nav`;
};

export const build = (
  data: IProductFirstCatNavRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/search/products/cat-nav';

/**
 * Product Area: CATEGORY_RESULTS
 */
export function execute(
  data: IProductFirstCatNavRequest
): Promise<IProductFirstCatNavWithLeanProductTileResponse>;
export function execute(
  data: IProductFirstCatNavRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IProductFirstCatNavWithLeanProductTileResponse>>;
export function execute(
  data: IProductFirstCatNavRequest,
  options?: EndpointOptions
): Promise<IProductFirstCatNavWithLeanProductTileResponse>;
export function execute(
  data: IProductFirstCatNavRequest,
  options?: EndpointOptions
): Promise<
  | IProductFirstCatNavWithLeanProductTileResponse
  | SuccessResponse<IProductFirstCatNavWithLeanProductTileResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<IProductFirstCatNavWithLeanProductTileResponse>(
    requestOptions
  );
}
execute[PATH] = 'api/v2/search/products/cat-nav/post';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  data: IProductFirstCatNavRequest
): Promise<IProductFirstCatNavWithLeanProductTileResponse>;
export function darkRead(
  data: IProductFirstCatNavRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IProductFirstCatNavWithLeanProductTileResponse>>;
export function darkRead(
  data: IProductFirstCatNavRequest,
  options?: EndpointOptions
): Promise<IProductFirstCatNavWithLeanProductTileResponse>;
export function darkRead(
  data: IProductFirstCatNavRequest,
  options?: EndpointOptions
): Promise<
  | IProductFirstCatNavWithLeanProductTileResponse
  | SuccessResponse<IProductFirstCatNavWithLeanProductTileResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<IProductFirstCatNavWithLeanProductTileResponse>(
    requestOptions
  );
}
