"use client";

import {
  IStrictTaxonomyLocalMsgProps,
  StrictBaseLocalMsg,
} from "@faire/web--source/common/localization";
import React from "react";

import { chunkTransformations } from "./common";

export { chunkTransformations, NoTranslate } from "./common";
export type { INoTranslateProps } from "./common";

/**
 * @trackcomponent
 * @link [Docs](https://slate.faire.team/component/strict-local-message)
 */
export const StrictLocalMsg = <Values,>({
  values,
  ...props
}: IStrictTaxonomyLocalMsgProps<Values>) => {
  // Only merge if there's actually something special given, to reduce computation.
  const valuesAndTransforms: typeof values = Object.keys(values ?? {}).length
    ? { ...(chunkTransformations as any), ...values }
    : (chunkTransformations as any);
  return <StrictBaseLocalMsg {...props} values={valuesAndTransforms} />;
};
