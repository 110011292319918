"use client";

import { Core } from "@faire/design-tokens";
import { isSupportedBrowser } from "@faire/web--source/common/browser";
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { logError, logFatal } from "@faire/web--source/common/logging";
import { faireBaseUrl } from "@faire/web--source/common/routes/faireBaseUrl";
import { isError } from "@faire/web--source/common/typescriptUtils";
import { Column } from "@faire/web--source/slate/Layout";
import { FaireLogo } from "@faire/web--source/slate/logos/FaireLogo";
import { GrowingSpacer, Spacer } from "@faire/web--source/slate/spacing";
import { Typography } from "@faire/web--source/slate/Typography";
import { StrictLocalMsg } from "@faire/web--source/ui/lib/localization";
import { Anchor } from "@faire/web--source/ui/LinkAnchor";
import { History } from "history";
import * as React from "react";
import styled from "styled-components";

import { ContactFormLinkFormatting } from "../ContactFormLink";

const BACKGROUND_IMAGE =
  "https://cdn.faire.com/res/hszgttpjt/image/upload/error_page/PF-Lifestyle-12/1546985268.jpg";

type IProps = {
  children: React.ReactNode;
  history: History;
  fullPage?: boolean;
  logFatal?: boolean;
  error?: any;
  customMessage?: string;
  helpCenterPage?: boolean;
  storybook?: boolean;
  onFatalError?: (error: unknown) => void;
};

interface IState {
  error: any;
}

const createFingerPrint = (error: any) => {
  // Want to group react hook violations to prevent multiple sentry issues
  //   Because we minify our code, we cannot directly search for the error message
  // TODO should be extracted into @faire/web--source/common once we can verify it's doing what we want
  if (error.message?.includes?.("React error #321")) {
    return ["invalid-react-hook"];
  }
  return undefined;
};

/**
 * An app-level error boundary that serves as a global "catch all" for errors
 * thrown during rendering. The fallback content is designed to be displayed
 * in the entire window.
 */
export class FaireErrorBoundary extends React.Component<IProps, IState> {
  private unListen?: () => void;

  constructor(props: IProps) {
    super(props);
    this.state = { error: props.error || null };
  }

  componentDidMount() {
    const { history } = this.props;
    this.unListen = history.listen(() => {
      this.setState({ error: null });
    });
  }

  componentWillUnmount() {
    if (this.unListen) {
      this.unListen();
    }
  }

  componentDidCatch(error: unknown, errorInfo: React.ErrorInfo) {
    let errorName: string | undefined = undefined;
    let errorMessage: string | undefined = undefined;

    if (error instanceof Error) {
      errorName = error?.name;
      errorMessage = error?.message;
    }

    const isCSSLoadError = errorMessage?.includes("Loading CSS chunk");
    if (isCSSLoadError) {
      // We can ignore the CSS load error, as it doesn't make the page un-functional,
      // and we don't really have much to do for it
      return;
    }

    // For other network related errors, we still want to crash the page as it does
    // make the page un-functional
    this.setState({ error });

    const isNetworkError = errorMessage?.includes("Network Error");
    const isChunkLoadError = errorName === "ChunkLoadError";
    if (isNetworkError || isChunkLoadError) {
      // But since Datadog RUM track network errors, so we don't want to duplicate logs inside
      // the ErrorBoundary.
      return;
    }

    if (this.props.logFatal) {
      logFatal(error, {
        data: errorInfo,
        fingerprint: createFingerPrint(error),
      });
      this.props.onFatalError?.(error);
    } else {
      logError(error, { data: errorInfo });
    }
  }

  render() {
    if (isError(this.state.error)) {
      return <FaireErrorBoundaryErrorContent {...this.props} {...this.state} />;
    }
    return this.props.children;
  }
}

export const FaireErrorBoundaryErrorContent = (
  props: Pick<
    IProps,
    "fullPage" | "storybook" | "customMessage" | "helpCenterPage"
  > & {
    error: IState["error"];
  }
) => {
  if ((getWindow() as any).envName !== "production" && !props.storybook) {
    return (
      <StackTraceWrapper>
        {props.error.stack ? props.error.stack : props.error.message}
      </StackTraceWrapper>
    );
  }

  if (!props.fullPage) {
    return (
      <InlineErrorWrapper>
        <StrictLocalMsg defaultMessage="Sorry, something went wrong." />
        <br />
        {props.customMessage ?? (
          <StrictLocalMsg defaultMessage="Please refresh the page or try again later." />
        )}
        <br />
        {props.helpCenterPage ? (
          <>
            <StrictLocalMsg defaultMessage="If the problem persists, please contact us at:" />
            <span>
              <Anchor
                href="https://www.instagram.com/faire_wholesale/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </Anchor>
              <br />
              <Anchor
                href="https://www.facebook.com/FaireWholesale/"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </Anchor>
              <br />
              <Anchor
                href="https://twitter.com/faire_wholesale/"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </Anchor>
            </span>
          </>
        ) : (
          <span>
            <StrictLocalMsg
              defaultMessage="If the problem persists, please <ContactFormLinkFormatting>contact us</ContactFormLinkFormatting>"
              values={{
                ContactFormLinkFormatting,
              }}
            />
          </span>
        )}
      </InlineErrorWrapper>
    );
  }

  return (
    <>
      <StickyHeader>
        <HeaderContainer>
          <Logo>
            <Anchor href={faireBaseUrl()}>
              <FaireLogo size={128} />
            </Anchor>
          </Logo>
        </HeaderContainer>
      </StickyHeader>
      <BackgroundImage />
      <Wrapper>
        <Spacer
          size={{
            mobileAndAbove: 115,
            tabletAndAbove: 445,
            desktopAndAbove: 245,
          }}
        />
        {!location.host.includes("faire") &&
        !location.host.includes("localhost") &&
        !props.storybook ? (
          <>
            <Typography
              variant="displayLSerifRegular"
              color={Core.text.critical}
            >
              Oops!
            </Typography>
            <Spacer size="7x" />
            <BodyWrapper>
              <Anchor href="https://faire.com">
                <Typography variant="paragraphSansMedium">
                  <StrictLocalMsg defaultMessage="It looks like you're using an offline version of Faire" />
                  <br />
                  <StrictLocalMsg defaultMessage="Please visit Faire.com to continue." />
                </Typography>
              </Anchor>
              <Spacer size="4x" />
              <Typography
                variant="paragraphSansMedium"
                color={Core.text.subdued}
              >
                <StrictLocalMsg
                  defaultMessage="Still having problems?{lineBreak}Please <ContactFormLinkFormatting>contact us</ContactFormLinkFormatting>."
                  values={{ ContactFormLinkFormatting }}
                />
              </Typography>
              <Spacer size="10x" />
            </BodyWrapper>
          </>
        ) : (
          <>
            <Typography
              variant="displayLSerifRegular"
              color={Core.text.critical}
            >
              <StrictLocalMsg defaultMessage="Oops!{lineBreak}It’s not you, it’s us." />
            </Typography>
            <Spacer size="7x" />
            <BodyWrapper>
              <Typography variant="paragraphSansMedium">
                <StrictLocalMsg defaultMessage="Sorry, something went wrong." />
                <br />
                {props.customMessage ?? (
                  <StrictLocalMsg defaultMessage="Please refresh the page or try again later." />
                )}
              </Typography>
              <Spacer size="4x" />
              {!isSupportedBrowser() ? (
                <>
                  <Typography
                    variant="paragraphSansMedium"
                    color={Core.text.subdued}
                  >
                    <StrictLocalMsg defaultMessage="Please try these browsers:" />
                  </Typography>

                  <BrowserList>
                    <li>
                      <Typography variant="paragraphSansMedium">
                        <Anchor href="https://www.google.ca/chrome/">
                          Google Chrome
                        </Anchor>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="paragraphSansMedium">
                        <Anchor href="https://www.mozilla.org/en-US/firefox/new/">
                          Firefox
                        </Anchor>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="paragraphSansMedium">
                        <Anchor href="https://www.apple.com/ca/safari/">
                          Safari
                        </Anchor>
                      </Typography>
                    </li>
                  </BrowserList>
                  <GrowingSpacer />
                </>
              ) : null}

              <Spacer height="2x" />
              {props.helpCenterPage ? (
                <>
                  <Typography
                    variant="paragraphSansMedium"
                    color={Core.text.subdued}
                  >
                    <StrictLocalMsg defaultMessage="If the problem persists, please contact us at:" />
                  </Typography>
                  <BrowserList>
                    <li>
                      <Typography variant="paragraphSansMedium">
                        <Anchor
                          href="https://www.instagram.com/faire_wholesale/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Instagram
                        </Anchor>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="paragraphSansMedium">
                        <Anchor
                          href="https://www.facebook.com/FaireWholesale/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Facebook
                        </Anchor>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="paragraphSansMedium">
                        <Anchor
                          href="https://twitter.com/faire_wholesale/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Twitter
                        </Anchor>
                      </Typography>
                    </li>
                  </BrowserList>
                </>
              ) : (
                <Typography
                  variant="paragraphSansMedium"
                  color={Core.text.subdued}
                >
                  <StrictLocalMsg
                    defaultMessage="Still having problems?{lineBreak}Please <ContactFormLinkFormatting>contact us</ContactFormLinkFormatting>."
                    values={{ ContactFormLinkFormatting }}
                  />
                </Typography>
              )}

              <Spacer size="10x" />
            </BodyWrapper>
          </>
        )}
      </Wrapper>
    </>
  );
};

const StackTraceWrapper = styled.div`
  font-size: 13px;
  line-height: 1.5rem;
  margin: 30px;
  white-space: 30px;
  color: #aa0000;
`;

const InlineErrorWrapper = styled(Column).attrs({ justify: "center" })`
  text-align: center;
  width: 100%;
  padding: 13px;
  background-color: rgba(255, 109, 109, 0.2);
`;

const BodyWrapper = styled(Column)`
  flex-grow: 1;
  padding-left: 114px;
  padding-top: 0;
  @media screen and (max-width: 767px) {
    padding-left: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding-left: 37vw;
    padding-top: calc(60vh - 600px);
  }
`;

const Wrapper = styled(Column)`
  height: 100vh;
  max-width: 1024px;
  padding-left: 30px;
  margin: 0 auto;
  a {
    color: ${Core.text.default};
  }
`;

const SIDEBAR_WIDTH: number = 200;
const HEADER_HEIGHT: number = 60;

const Logo = styled.div`
  display: inline-block;
  width: ${SIDEBAR_WIDTH}px;
  text-align: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  position: relative;
`;

const StickyHeader = styled.div`
  z-index: 1;
  background: white;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  width: 100vw;
`;

const BrowserList = styled.ul`
  letter-spacing: 0;
  list-style-type: none;
  padding-left: 0;
`;

const BackgroundImage = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  width: calc(50vw + 100px);
  left: calc(50vw - 100px);
  height: 100vh;
  background: url(${BACKGROUND_IMAGE}) center / cover no-repeat;
  @media screen and (max-width: 767px) {
    background: none;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    height: 60vh;
  }
`;
