import { SettingConst } from '../../types';

/**
 * Control whether to show the language selector on FE
 */
export const LANGUAGE_SELECTOR_ENABLED =
  'LANGUAGE_SELECTOR_ENABLED' as SettingConst<
    'LANGUAGE_SELECTOR_ENABLED',
    boolean,
    'RETAILER' | 'RETAILER_ANDROID' | 'VISITOR_WEB' | 'HELP_CENTER'
  >;
