import parse from "date-fns/parse";

export function notifyAfter(yyyyMMdd: string): Date;
export function notifyAfter(yyyyMMdd: string | undefined): Date | undefined;
export function notifyAfter(yyyyMMdd: string | undefined): Date | undefined {
  if (typeof yyyyMMdd === "undefined") {
    return undefined;
  }
  return parse(yyyyMMdd, "yyyy-MM-dd", new Date());
}
