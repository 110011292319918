import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'signin-hcaptcha_init-action_unknown';

/**
 * event for hCaptcha successful initialization
 */
export function trackSigninHcaptchaInit(): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
  });
}

/**
 * Trace for event: signin-hcaptcha_init-action_unknown
 */
export function trackSigninHcaptchaInitTraceDataRequested(
  sourceEventMarker?: ITraceEventMarker
) {
  trackClientEvent({
    eventKey: 'signin-hcaptcha_init-action_unknown-trace-data_requested',
    realTimeTracking: false,
    sourceEventKey: 'signin-hcaptcha_init-action_unknown',
    sourceEventMarker: sourceEventMarker,
  });
}

/**
 * Trace for event: signin-hcaptcha_init-action_unknown
 */
export function trackSigninHcaptchaInitTraceDataRetrieved(
  sourceEventMarker?: ITraceEventMarker
) {
  trackClientEvent({
    eventKey: 'signin-hcaptcha_init-action_unknown-trace-data_retrieved',
    realTimeTracking: false,
    sourceEventKey: 'signin-hcaptcha_init-action_unknown',
    sourceEventMarker: sourceEventMarker,
  });
}

/**
 * Trace for event: signin-hcaptcha_init-action_unknown
 */
export function trackSigninHcaptchaInitTraceRetrievalFailed(
  sourceEventMarker?: ITraceEventMarker
) {
  trackClientEvent({
    eventKey: 'signin-hcaptcha_init-action_unknown-trace-retrieval_failed',
    realTimeTracking: false,
    sourceEventKey: 'signin-hcaptcha_init-action_unknown',
    sourceEventMarker: sourceEventMarker,
  });
}
