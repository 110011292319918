import { SettingConst } from '../../types';

/**
 * Controls whether or not we render product tiles on the server on the logged in search page
 */
export const NEXTJS_RSC_PRODUCT_TILE_LOGGED_IN_SEARCH_PAGE_240729 =
  'NEXTJS_RSC_PRODUCT_TILE_LOGGED_IN_SEARCH_PAGE_240729' as SettingConst<
    'NEXTJS_RSC_PRODUCT_TILE_LOGGED_IN_SEARCH_PAGE_240729',
    boolean,
    'RETAILER' | 'RETAILER_HEAD_TAGS'
  >;
