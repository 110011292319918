import { shouldUseAnchorForLink } from "@faire/web--source/ui/lib";
import { Anchor, Link } from "@faire/web--source/ui/LinkAnchor";
import { useHistory } from "react-router-dom";

import { ButtonProps, ButtonStateType, StyledButtonProps } from "./types";

type ButtonAttributes = {
  as: "button";
  linkProps: Record<string, never>;
};

type AnchorAttributes = {
  as: typeof Anchor;
  linkProps: { href: string };
};

type LinkAttributes = {
  as: typeof Link;
  linkProps: { to: ButtonProps["linkTo"]; replace: ButtonProps["replace"] };
};

type Attributes = ButtonAttributes | AnchorAttributes | LinkAttributes;

export const useGetAttributes = (props: ButtonProps): Attributes => {
  const { linkTo } = props;
  const history = useHistory();

  if (linkTo === undefined) {
    return { as: "button", linkProps: {} };
  }

  if ("download" in props || shouldUseAnchorForLink(linkTo)) {
    const descriptor =
      typeof linkTo === "function" ? linkTo(history.location) : linkTo;
    const resolved =
      typeof descriptor === "string"
        ? descriptor
        : history.createHref(descriptor);
    return {
      as: Anchor,
      linkProps: { href: resolved },
    };
  }

  return { as: Link, linkProps: { to: linkTo, replace: props.replace } };
};

export const extendBaseThemeVariant = (
  baseTheme: ButtonStateType,
  extendedTheme: ButtonStateType
) => {
  return (props: StyledButtonProps) => {
    const baseThemeComputed =
      typeof baseTheme === "function" ? baseTheme(props) : baseTheme;
    const extendedThemeComputed =
      typeof extendedTheme === "function"
        ? extendedTheme(props)
        : extendedTheme;
    return {
      ...baseThemeComputed,
      ...extendedThemeComputed,
    };
  };
};
