"use client";

import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { MessageDescriptor } from "@formatjs/intl/src/types";
import { Options as IntlMessageFormatOptions } from "intl-messageformat/src/core";
import React, { useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { getGlobalLookup } from "../globals/lookup";

import { StrictDescription, Description } from "./description";
import { messageToGibberish } from "./gibberish";
import { LocalizationStore } from "./LocalizationStore";
import {
  PlaceholderValueCheck,
  PlaceholderJsxValueCheck,
} from "./placeholderTaxonomy";

export { localNum } from "./localNum";
export { useLocalNum } from "./useLocalNum";

/**
 * Note: since react-intl uses the context API, we require our internal dependencies to have
 * the exact same version of react-intl and then we need to run `yarn yarn-deduplicate` in
 * order to only include a single version of the react-intl context.
 * We need to have react-intl in web, so we will need to live with this, we can use postinstall scripts
 * to make this hopefully imperceptible unless you are updating.
 * https://github.com/formatjs/formatjs/issues/1620
 */

export interface ILocalMsgProps {
  defaultMessage: string;
  description?: Description;
  values?: Record<string, any>;
  // We never want to explicitly pass an ID, but rather wait for the compiled ID to be passed through
  id?: never;
}

export interface IStrictTaxonomyLocalMsgProps<Values>
  extends Omit<ILocalMsgProps, "values" | "description"> {
  values?: PlaceholderJsxValueCheck<Values>;
  description?: StrictDescription;
}

export const StrictBaseLocalMsg = <Values,>(
  props: IStrictTaxonomyLocalMsgProps<Values>
) => {
  const { defaultMessage, description, values, id } = props;
  const { localeKey } = LocalizationStore.get();

  if (localeKey?.language === Language.STRING_ID) {
    return (
      <FormattedMessage
        // eslint-disable-next-line @faire/enforce-default-message-formatjs
        defaultMessage={id}
        id={id}
      />
    );
  }

  if (localeKey?.language === Language.GIBBERISH) {
    return (
      <FormattedMessage
        // eslint-disable-next-line @faire/enforce-default-message-formatjs
        defaultMessage={messageToGibberish(defaultMessage)}
        description={description}
        // Any default chunks should be defined using wrappers in the consuming repository
        // this is because updates to defaults need to be included in the eslintrc for that project
        values={values}
        id={id}
      />
    );
  }

  return (
    <FormattedMessage
      // eslint-disable-next-line @faire/enforce-default-message-formatjs
      defaultMessage={defaultMessage}
      description={description}
      // Any default chunks should be defined using wrappers in the consuming repository
      // this is because updates to defaults need to be included in the eslintrc for that project
      values={values}
      id={id}
    />
  );
};

interface IStrictFaireMessageDescriptor extends MessageDescriptor {
  description?: StrictDescription;
}

/**
 * @deprecated
 * Replace with `DEPRECATED_backwardsCompatibleValues`.
 * This should NOT be used in new code.
 *
 * This was used to migrate from localize -> strictLocalize and LocalMsg -> StrictLocalMsg to force values objects to align and should not be used anymore
 * */
export const backwardsCompatibleValues = <T,>(values: Record<string, any>): T =>
  values as any;

/**
 * This should NOT be used in new code.
 *
 * This was used to migrate from localize -> strictLocalize and LocalMsg -> StrictLocalMsg to force values objects to align and should not be used anymore
 * */
export const DEPRECATED_backwardsCompatibleValues = <T,>(
  values: Record<string, any>
): T => values as any;

export type StrictLocalizeFunction = <Values>(
  descriptor: IStrictFaireMessageDescriptor,
  values?: PlaceholderValueCheck<Values>,
  opts?: IntlMessageFormatOptions
) => string;

/**
 * @deprecated
 * @trackfunction
 * Access `strictLocalize` using `useStrictLocalize` (or `withStrictLocalize` for class components) instead.
 */
export const strictLocalize: StrictLocalizeFunction = (
  descriptor,
  values,
  opts
) => LocalizationStore.get().intl.formatMessage(descriptor, values, opts);

export const useStrictLocalization = () => {
  const intl = useIntl();
  const { intl: legacyIntl, localeKey } = LocalizationStore.get();

  const strictLocalize: StrictLocalizeFunction = useCallback(
    (descriptor, values, opts) => {
      if (localeKey?.language === Language.STRING_ID) {
        return descriptor.id ?? "";
      }

      if (localeKey?.language === Language.GIBBERISH) {
        return intl.formatMessage(
          {
            // eslint-disable-next-line @faire/enforce-default-message-formatjs
            defaultMessage: messageToGibberish(
              descriptor.defaultMessage as any
            ),
            id: descriptor.id,
          },
          values,
          opts
        );
      }

      return intl.formatMessage(
        /**
         * react-intl uses a different formatjs version than us.
         * The descriptor types don't perfectly match, but it should work fine.
         */
        descriptor as any,
        values,
        opts
      );
    },
    [intl, localeKey?.language]
  );

  const legacyStrictLocalize: StrictLocalizeFunction = useCallback(
    (descriptor, values, opts) =>
      legacyIntl.formatMessage(descriptor, values, opts),
    [legacyIntl]
  );

  // Return the named function for babel string extraction
  const lookup = getGlobalLookup();
  return {
    strictLocalize: lookup("isNextJS") ? strictLocalize : legacyStrictLocalize,
  };
};

export type WithStrictLocalizationProps = {
  strictLocalize: StrictLocalizeFunction;
};

export const withStrictLocalization = <
  P extends WithStrictLocalizationProps = WithStrictLocalizationProps
>(
  Component: React.ComponentType<P>
) => {
  const ComponentWithStrictLocalization = (
    props: Omit<P, keyof WithStrictLocalizationProps>
  ) => {
    const strictLocalizationProps = useStrictLocalization();
    return <Component {...(props as P)} {...strictLocalizationProps} />;
  };
  return ComponentWithStrictLocalization;
};
