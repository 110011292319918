import { Path } from "@faire/web--source/common/Path";
import { isNullish } from "@faire/web--source/common/typescriptUtils";
import { removeLocaleFromUrl } from "@faire/web--source/ui/routing/util";
import qs from "query-string";

import { FAIRE_DIRECT_BRAND_QUERY_PARAM } from "./FAIRE_DIRECT_BRAND_QUERY_PARAM";
import { isValidFaireDirectPath } from "./isValidFaireDirectPath";

/**
 *
 * Used to append a Faire Direct brand alias query param to a path.
 *
 * If 'search' is undefined or null, we assume a full 'path' is passed including
 * both the url and any query params. Accordingly, a full path with the newly
 * appended query param is returned.
 *
 * If 'search' is specified (even as empty string), we assume a 'path' is passed
 * *without* any query params, as this is what 'search' will specify. Accordingly,
 * only the query param string with the newly appended query param is returned.
 *
 */
export const appendFaireDirectBrandAliasQueryParam = (
  path: string,
  faireDirectBrandAlias: string,
  faireDirectBrandToken: string,
  search?: string
): string => {
  if (
    !isValidFaireDirectPath(removeLocaleFromUrl(path), faireDirectBrandToken)
  ) {
    return !isNullish(search) ? search : path;
  }

  const { url, query } = qs.parseUrl(path);

  const appendedPath = new Path({
    pathname: url,
    search: search ?? qs.stringify(query) ?? "",
  }).replace({ [FAIRE_DIRECT_BRAND_QUERY_PARAM]: faireDirectBrandAlias });

  if (!isNullish(search)) {
    return appendedPath.searchParams.toString();
  }

  return appendedPath.toString();
};
