import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { logError } from "@faire/web--source/common/logging";
import { trackInfraWebMemoryUsage } from "@faire/web-api--source/events/infra/actionUnknown/webMemoryUsage";
import * as Bowser from "bowser";
import { useEffect } from "react";

const MILLIS_1_MIN = 1000 * 60;
const MILLIS_5_MINS = 5 * MILLIS_1_MIN;

interface Performance {
  memory: MemoryUsage;
}

interface MemoryUsage {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
}

const useMemoryMonitor = (app_id: string, interval: number = MILLIS_5_MINS) => {
  return useEffect(() => {
    const performance = getWindow()?.performance as unknown as Performance;
    if (!performance?.memory) {
      return;
    }

    const measureMemory = async () => {
      try {
        const memory = performance.memory;
        const userAgent = getWindow()?.navigator.userAgent;
        const browser = userAgent ? Bowser.getParser(userAgent) : null;
        trackInfraWebMemoryUsage(
          browser?.getBrowserName() ?? "unknown",
          browser?.getBrowserVersion() ?? "unknown",
          browser?.getOSName() ?? "unknown",
          browser?.getOSVersion() ?? "unknown",
          browser?.getPlatformType() ?? "unknown",
          `${memory.jsHeapSizeLimit}`,
          `${memory.totalJSHeapSize}`,
          `${memory.usedJSHeapSize}`,
          `${getRoundedSessionTime()}`,
          app_id
        );
      } catch (error) {
        logError(error);
      }
    };

    const intervalId = setInterval(measureMemory, interval);

    return () => clearInterval(intervalId);
  }, [app_id, interval]);
};

function getRoundedSessionTime(roundedMinutes: number = 5): number {
  const timeInMilliseconds = performance.now();
  const timeInMinutes = timeInMilliseconds / MILLIS_1_MIN;
  const roundedTime =
    Math.round(timeInMinutes / roundedMinutes) * roundedMinutes;

  return roundedTime;
}

export default useMemoryMonitor;
