import { emitDatadogError } from "@faire/web--source/common/datadog/rum";
import { getWebApiErrorMessage } from "@faire/web--source/common/getErrorMessage";
import { getNormalizedUrl } from "@faire/web--source/common/getNormalizedUrl";
import {
  ResponseInterceptor,
  RequestOptions,
  Response as WebApiResponse,
} from "@faire/web-api--source/types";
import { WebApiError } from "@faire/web-api--source/WebApiError";
import { WebApiNetworkError } from "@faire/web-api--source/WebApiNetworkError";
import { WebApiRequestCancellationError } from "@faire/web-api--source/WebApiRequestCancellationError";

import { mockStrictLocalize } from "../../testing/unit/mockStrictLocalize";

enum Severity {
  /** JSDoc */
  Fatal = "fatal",
  /** JSDoc */
  Error = "error",
  /** JSDoc */
  Warning = "warning",
  /** JSDoc */
  Log = "log",
  /** JSDoc */
  Info = "info",
  /** JSDoc */
  Debug = "debug",
  /** JSDoc */
  Critical = "critical",
}

const createFingerprint = (
  method: string,
  url: string,
  statusCode: number = 0
): string[] => ["apiError", method, getNormalizedUrl(url), `${statusCode}`];

// The request was made and the server responded with a status code
// that falls out of the range of 2xx
export const logErrorResponse = (
  error: WebApiError,
  requestOptions: RequestOptions
) => {
  const { httpStatusCode } = error;
  if (httpStatusCode === undefined || httpStatusCode > 399) {
    return;
  }

  const errorMessage = getWebApiErrorMessage(error, mockStrictLocalize);
  const { method, url } = requestOptions;
  const message = `${(
    method || "Unknown"
  ).toUpperCase()} ${url} received a ${httpStatusCode}: ${errorMessage}`;
  emitDatadogError(
    {
      message,
      resource: {
        url,
      },
      stack: createFingerprint(method, url, httpStatusCode),
    },
    {
      level: Severity.Debug,
      data: { config: requestOptions, responseStatus: httpStatusCode },
    }
  );
};

// The request was made but no response was received
// OR
// Something happened in setting up the request that triggered an Error
const logErrorRequest = (error: Error, requestOptions: RequestOptions) => {
  const { method, url } = requestOptions;
  const message = `No response: ${error.message || "Unknown"}`;
  emitDatadogError(
    { message, resource: { url }, stack: createFingerprint(method, url) },
    {
      level: Severity.Debug,
      data: { error, config: requestOptions },
    }
  );
};

export const errorLoggingInterceptor: ResponseInterceptor = <T = unknown>(
  requestOptions: RequestOptions,
  response: WebApiResponse<T>
): WebApiResponse<T> => {
  if (
    !("error" in response) ||
    response.error instanceof WebApiRequestCancellationError
  ) {
    return response;
  }

  if (response.error instanceof WebApiNetworkError) {
    logErrorRequest(response.error, requestOptions);
  } else if (response.error instanceof WebApiError) {
    logErrorResponse(response.error, requestOptions);
  }
  return response;
};
