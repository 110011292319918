import { request } from '../../..';
import { IBrandDiscountsResponse } from '../../../indigofair/data/IBrandDiscountsResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';

export const path = () => {
  return `/api/cart/brand-discounts`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/cart/brand-discounts';

/**
 *   Intended for frontend to refresh brand discounts data
 *   so we can avoid loading entire cart before going to cart page
 * Product Area: CART
 */
export function execute(): Promise<IBrandDiscountsResponse>;
export function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IBrandDiscountsResponse>>;
export function execute(
  options?: EndpointOptions
): Promise<IBrandDiscountsResponse>;
export function execute(
  options?: EndpointOptions
): Promise<IBrandDiscountsResponse | SuccessResponse<IBrandDiscountsResponse>> {
  const { route, ...requestOptions } = build(options);
  return request<IBrandDiscountsResponse>(requestOptions);
}
execute[PATH] = 'api/cart/brand-discounts/get';
export default execute;
