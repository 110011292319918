import { getWindow } from "@faire/web--source/common/getWindow";
import getAdsBait from "@faire/web-api--source/api/ads/ad/get";
import { trackAdsAdblockDetectorFireForAllView } from "@faire/web-api--source/events/ads/view/adblockDetectorFireForAll";
import { trackAdsAdblockDetectorUserBlockedDomView } from "@faire/web-api--source/events/ads/view/adblockDetectorUserBlockedDom";
import { trackAdsAdblockDetectorUserBlockedResourceView } from "@faire/web-api--source/events/ads/view/adblockDetectorUserBlockedResource";
import { useEffect, useRef } from "react";

import { doesUserHaveAdblock } from "./doesUserHaveAdblock";
import { insertAdblockBait } from "./insertAdblockBait";

export const useAdblockDetector = (userToken: string = "") => {
  useEffect(() => {
    insertAdblockBait();
  }, []);

  useNestedRAF(() => {
    trackAdsAdblockDetectorFireForAllView(userToken);

    if (doesUserHaveAdblock()) {
      trackAdsAdblockDetectorUserBlockedDomView(userToken);
    }

    getAdsBait().catch(() => {
      trackAdsAdblockDetectorUserBlockedResourceView(userToken);
    });
  });
};

const useNestedRAF = (callback: () => unknown) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    let childRef: number | null = null;
    const parentRef = getWindow().requestAnimationFrame(() => {
      childRef = getWindow().requestAnimationFrame(() => {
        callbackRef.current();
      });
    });

    return () => {
      getWindow().cancelAnimationFrame(parentRef);
      if (childRef !== null) {
        getWindow().cancelAnimationFrame(childRef);
      }
    };
  }, []);
};
