"use client";

import { SVGIcon } from "@faire/web--source/slate/icons/SVGIcon";
import { eventsThemeColors } from "@faire/web--source/slate/Theme/eventsThemeColors";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

export const Tertiary: React.FC<SVGIcon.IProps> = ({ color, ...rest }) => {
  const iconColor =
    color ??
    eventsThemeColors[PromotionalEventType.FAIRE_MARKET_JULY_2023].decorative5;
  return (
    <SVGIcon
      width="194"
      height="194"
      viewBox="0 0 194 194"
      fill="none"
      {...rest}
    >
      <path
        d="M158.831 17.9882C133.007 15.8872 75.8195 15.858 50.4534 16.106C42.8933 16.1615 43.308 20.3721 44.4604 22.4705L47.937 32.1204C47.937 32.1204 63.9614 70.2121 65.2589 72.4667C66.5564 74.7214 67.977 78.223 66.7349 80.9468C62.7664 89.6494 28.5131 105.808 28.0414 112.913C27.223 125.239 38.7811 151.787 41.7897 157.541C44.7984 163.296 54.714 192.695 59.0224 193.464C63.3308 194.233 140.425 193.072 144.83 192.398C148.353 191.859 150.85 186.841 151.658 184.398C153.53 178.082 157.937 163.514 160.582 155.768C163.889 146.085 168.651 118.144 165.786 110.225C162.921 102.307 142.301 77.2693 140.05 74.7047C138.248 72.653 139.618 69.0407 140.528 67.4911C143.27 62.6818 149.844 51.2036 154.213 43.7652C159.675 34.4672 161.955 29.3052 162.82 23.5663C163.513 18.9752 160.449 17.9346 158.831 17.9882Z"
        fill={iconColor}
      />
    </SVGIcon>
  );
};
