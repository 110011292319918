import { optimizeAvatar } from "@faire/web--source/slate/Avatar/Avatar";
import getBrandView from "@faire/web-api--source/api/v2/brand-view/brandToken/get";
import { getQueryKey as getQueryKeyBrandView } from "@faire/web-api--source/api/v2/brand-view/brandToken/get-hooks";
import { IBrandViewV2Response } from "@faire/web-api--source/indigofair/data/IBrandViewV2Response";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { readBundledData } from "@faire/retailer/lib/bundledData";
import { stubImage } from "@faire/retailer/lib/preload-images/common";
import { ProductCacheStore } from "@faire/retailer/stores/domain/ProductCacheStore";

import { buildBannerImageSrcSet } from "../BrandHeader/buildBannerImageSrcSet";
import { createGetBuyItAgainProductsRequest } from "../carousels/ReorderArchetypeCarousels/createGetBuyItAgainProductsRequest";
import { isReorderArchetype } from "../isReorderArchetype";

const BRAND_VIEW_STALE_TIME_MS = 5 * 60 * 1000; // 5 min

export const useFetchCachedBrandView = (
  brandToken: string
): UseQueryResult<IBrandViewV2Response> => {
  return useQuery({
    enabled: !!brandToken,
    ...getBrandViewUseQueryArgs(brandToken),
  });
};

export function getBrandViewUseQueryArgs(brandToken: string) {
  const prefetched = readBundledData("brand_view_v2", { keep: true });
  return {
    initialData: () => {
      if (prefetched?.brand?.token === brandToken) {
        return prefetched;
      }
      return undefined;
    },
    queryKey: getQueryKeyBrandView(brandToken),
    queryFn: () => getBrandView(brandToken),
    staleTime: BRAND_VIEW_STALE_TIME_MS,
  };
}

export const usePreloadBuyItAgainAndImages = (brandToken: string) => {
  const { data } = useFetchCachedBrandView(brandToken ?? "");

  useEffect(() => {
    if (!brandToken) {
      return;
    }
    if (data) {
      if (isReorderArchetype(data)) {
        ProductCacheStore.get().getBuyItAgainProducts(
          createGetBuyItAgainProductsRequest(brandToken)
        );
      }

      if (data.brand) {
        preloadImagesBrandPageLoggedIn(data.brand);
      }
    }
  }, [data, brandToken]);
};

export const preloadImagesBrandPageLoggedIn = (
  brand: IBrandViewV2Response.ILeanBrand
) => {
  const { profile_image, banner_image } = brand;
  const isSSR = false;

  if (profile_image) {
    const src = optimizeAvatar(profile_image, "x-large", isSSR);
    stubImage(src);
  }

  if (banner_image) {
    const { src, srcset, sizes } = buildBannerImageSrcSet(banner_image);
    stubImage(src, srcset, sizes);
  }
};
