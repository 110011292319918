import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'frontend_apis-prefetch_unused-view';

/**
 * Fired when a prefetch request is not leveraged within ten seconds of being sent.
 */
export function trackFrontendApisPrefetchUnusedView(
  endpoint: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: true,
    parameters: { endpoint },
  });
}
