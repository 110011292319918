import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { isError } from "@faire/web--source/common/typescriptUtils";
import { isPrerender } from "@faire/web--source/common/user-agent/isPrerender";
import { isRobot } from "@faire/web--source/common/user-agent/isRobot";

import {
  EventInterceptor,
  IFaire,
  IFaireApi,
  IFaireEvents,
  RequestHandler,
  RequestInterceptor,
  RequestOptions,
  Response,
  ResponseInterceptor,
  SuccessResponse,
} from "./types";

const isRawResponse = <T = unknown>(r: unknown): r is Response<T> => {
  if (!r || typeof r !== "object") {
    return false;
  }
  const obj = r as Record<string, unknown>;
  const isSuccessResponse =
    "data" in obj && typeof obj["status"] === "number" && "headers" in obj;
  const isFailureResponse = "error" in obj && isError(obj.error);
  return isSuccessResponse || isFailureResponse;
};

const getFaireApiNamespace = (): IFaireApi => {
  const faire = getGlobalProperty<IFaire>("faire");
  if (!faire) {
    throw new Error("Faire global not found");
  }
  const api = faire.api || (faire.api = {});
  return api;
};

export const REQUEST_HANDLER_GLOBAL_KEY = "faire.api.handler";

export const getRequestHandler = (): RequestHandler | undefined =>
  getGlobalProperty<RequestHandler>(REQUEST_HANDLER_GLOBAL_KEY);
/**
 * Sets the global request handler, i.e. global.faire["api"]["handler"].
 * The request handler is later resolved via getRequestHandler (getGlobalProperty("faire.api.handler")),
 * so in a Zone context (i.e. SSR), this should be done using Zone.fork, setting "faire.api.handler".
 */
export const setRequestHandler = (handler: RequestHandler) => {
  getFaireApiNamespace().handler = handler;
};

export const REQUEST_INTERCEPTOR_GLOBAL_KEY = "faire.api.requestInterceptors";

export const getRequestInterceptors = (): Readonly<RequestInterceptor[]> => {
  const requestInterceptors =
    getGlobalProperty<RequestInterceptor[]>(REQUEST_INTERCEPTOR_GLOBAL_KEY) ??
    [];
  return Object.freeze(requestInterceptors.slice(0));
};
/**
 * Sets a global request interceptor.
 * The request interceptors are invoked before the request handler called.
 */
export const addRequestInterceptor = (interceptor: RequestInterceptor) => {
  const interceptors = (getFaireApiNamespace().requestInterceptors =
    getFaireApiNamespace().requestInterceptors ?? []);
  interceptors.push(interceptor);
};

export const RESPONSE_INTERCEPTOR_GLOBAL_KEY = "faire.api.responseInterceptors";

export const getResponseInterceptors = (): Readonly<ResponseInterceptor[]> => {
  const repsonseInterceptors =
    getGlobalProperty<ResponseInterceptor[]>(RESPONSE_INTERCEPTOR_GLOBAL_KEY) ??
    [];
  return Object.freeze(repsonseInterceptors.slice(0));
};
/**
 * Sets a global request interceptor.
 * The request interceptors are invoked before the request handler called.
 */
export const addResponseInterceptor = (interceptor: ResponseInterceptor) => {
  const interceptors = (getFaireApiNamespace().responseInterceptors =
    getFaireApiNamespace().responseInterceptors ?? []);
  interceptors.push(interceptor);
};
export async function request<T>(
  config: RequestOptions & { rawResponse: true; ignoreDuringPrerender: true }
): Promise<SuccessResponse<T> | undefined>;
export async function request<T>(
  config: RequestOptions & { rawResponse: true; ignoreForRobots: true }
): Promise<SuccessResponse<T> | undefined>;
export async function request<T>(
  config: RequestOptions & { rawResponse: true }
): Promise<SuccessResponse<T>>;
export async function request<T>(
  config: RequestOptions & { ignoreDuringPrerender: true }
): Promise<T | undefined>;
export async function request<T>(
  config: RequestOptions & { ignoreForRobots: true }
): Promise<T | undefined>;
export async function request<T>(config: RequestOptions): Promise<T>;
export async function request<T>(
  config: RequestOptions
): Promise<T | SuccessResponse<T> | undefined> {
  const requestHandler = getRequestHandler();
  if (requestHandler === undefined) {
    throw new Error(
      "Error: request handler does not exist! Please use setRequestHandler() to set one."
    );
  }

  if (config.ignoreDuringPrerender && isPrerender()) {
    return undefined;
  }

  if (config.ignoreForRobots && isRobot()) {
    return undefined;
  }

  if ((config.method === "post" || config.method === "put") && !config.data) {
    config.data = {};
  }

  const interceptors = getRequestInterceptors();
  for (const i of interceptors) {
    config = await i(config);
  }

  let response: Response<T> | T | undefined;

  try {
    response = await requestHandler<T>(config);
  } catch (e) {
    if (isError(e)) {
      response = {
        error: e,
      };
    } else {
      response = {
        error: new Error(
          `Unexpected error thrown while handling ${config.method} ${config.url}`
        ),
      };
    }
  }

  if (isRawResponse<T>(response)) {
    const interceptors = getResponseInterceptors() ?? [];
    for (const i of interceptors) {
      response = await i<T>(config, response);
    }

    if ("error" in response) {
      throw response.error;
    } else if (config.rawResponse !== true) {
      response = response.data;
    }
  }
  return response;
}

/**
 * Sets a global event interceptor.
 * The event interceptors are invoked when an event is tracked.
 */
export const addEventInterceptor = (interceptor: EventInterceptor) => {
  const interceptors = (getFaireEventsNamespace().interceptors =
    getFaireEventsNamespace().interceptors ?? []);
  interceptors.push(interceptor);
};

const getFaireEventsNamespace = (): IFaireEvents => {
  const faire = getGlobalProperty<IFaire>("faire");
  if (!faire) {
    throw new Error("Faire global not found");
  }
  const events = faire.events || (faire.events = {});
  return events;
};
