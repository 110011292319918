"use client";

import { Core } from "@faire/design-tokens";
import { extractImageCropFromTag } from "@faire/web--source/common/images/cropUtils/extractImageCropFromTag";
import {
  optimizeImageUrl,
  optimizeImageUrlFillSquare,
} from "@faire/web--source/common/images/optimizeImages";
import { useStrictLocalization } from "@faire/web--source/common/localization";
import { isNotUndefined } from "@faire/web--source/common/typescriptUtils";
import { Row } from "@faire/web--source/slate/Layout";
import {
  Typography,
  TypographyVariants,
} from "@faire/web--source/slate/Typography";
import { useResponsiveValue } from "@faire/web--source/ui/hooks/useResponsiveValue";
import { TransparencyOverlay } from "@faire/web--source/ui/TransparencyOverlay";
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";
import React from "react";
import styled, {
  css,
  DefaultTheme as StyledComponentsTheme,
  useTheme,
} from "styled-components";

import { useIsSSR } from "../../common/server-side-rendering/isSSR";
import { Color } from "../Color";
import { SVGIcon } from "../icons/SVGIcon";
import { themeOrDefault } from "../Theme";
import { FaireMarketTheme } from "../Theme/EventsTheme";
import { buildResponsiveCss } from "../Theme/ResponsiveCss";
import { isResponsiveProp, ResponsiveProp } from "../Theme/ResponsiveProp";
import {
  highVisSlateComponent,
  HIGH_VIS_FILTER,
} from "../utils/highVisSlateComponent";

export type AvatarSize =
  | "xx-small"
  | "x-small"
  | "smaller"
  | "small"
  | "medium"
  | "large"
  | "x-large";

export type AvatarIconPosition =
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight";

export type AvatarBorderStyle = "THIN" | "OUTLINED";

type IIconData = {
  position: AvatarIconPosition;
  icon: React.ReactNode;
};

const AvatarSizeToTypographyVariant: Record<
  AvatarSize,
  TypographyVariants | undefined
> = {
  "x-large": "displaySSansSemiBold",
  large: "sectionHeaderSansMedium",
  medium: "sectionHeaderSansMedium",
  small: "paragraphSansMedium",
  smaller: "paragraphSansMedium",
  "x-small": "paragraphSansMedium",
  "xx-small": "labelSansMedium",
};

const DEGREE_BY_POSITION: Record<AvatarIconPosition, number> = {
  right: 0,
  topRight: 45,
  top: 90,
  topLeft: 135,
  left: 180,
  bottomLeft: 225,
  bottom: 270,
  bottomRight: 315,
};

export const DIMENSION_BY_SIZE: Record<AvatarSize, number> = {
  "xx-small": 24,
  "x-small": 32,
  smaller: 40,
  small: 48,
  medium: 56,
  large: 64,
  "x-large": 96,
};

export const optimizeAvatar = (
  image: Partial<IImage>,
  size: AvatarSize,
  isSSR: boolean
) => {
  const dimension = DIMENSION_BY_SIZE[size];
  if (extractImageCropFromTag(image.tags)) {
    return optimizeImageUrl(image, isSSR, {
      width: dimension,
      height: dimension,
    });
  }
  return optimizeImageUrlFillSquare(image, isSSR, dimension);
};

export interface IProps {
  /**
   * Sets the size of the avatar image.
   *
   * x-small=32, smaller=40, small=48, medium=56, large=64. x-large: 96
   */
  size: ResponsiveProp<AvatarSize>;
  /** The image to be displayed. */
  image?: IImage;
  /** The first letter of the user name will appear in place of an image, if an image is not used. The entire username will be displayed on hover as a title tooltip. */
  userName?: string;
  /** The first letter of the user company will appear in place of an image, if an image is not used. The entire user company will be displayed on hover as a title tooltip. If {@link userName} is also set, that will supersede the single letter variant */
  userCompany?: string;
  /** The icon that appears around the avatar */
  iconData?: IIconData;
  /** If set, a border will appear around the avatar with the specified color */
  borderColor?: Color;
  /**
   * Determines what style the avatar border looks like (e.g. thin or outlined with a border). Defaults to OUTLINED (2px colored border with 1px white inner border)
   */
  borderStyle?: AvatarBorderStyle;
  /** When showEventIcon is true, show the icon from the theme on the top right */
  showEventIcon?: boolean;
  theme?: StyledComponentsTheme;
  className?: string;
  /** Hides transparency overlay on the avatar */
  hideTransparencyOverlay?: boolean;
  /** an HTML attribute applied to image tags to provide a text alternative for search engines; also used for screen readers.  It is intended to override image.alt_text **/
  altTag?: string;
}

/**
 * @link [Docs](https://slate.faire.team/slate/component/avatar)
 * @trackcomponent
 */
export const Avatar: React.FC<IProps> = ({
  size,
  image,
  userName,
  userCompany,
  iconData,
  className,
  borderColor,
  borderStyle,
  showEventIcon,
  hideTransparencyOverlay,
  altTag,
}) => {
  const { strictLocalize } = useStrictLocalization();
  const theme = useTheme();
  const responsiveSizeValue = useResponsiveValue(
    isResponsiveProp(size) ? size : { mobileAndAbove: size }
  );
  const [imageError, setImageError] = React.useState(false);
  const isSSR = useIsSSR();

  const onImageError = () => {
    if (!imageError) {
      setImageError(true);
    }
  };

  const owner = [userName, userCompany].filter(isNotUndefined).join(", ");

  let alt;
  if (altTag) {
    // altTag overrides all available alts
    alt = altTag;
  } else if (image?.alt_text) {
    alt = image.alt_text;
  } else if (owner) {
    alt = strictLocalize(
      {
        defaultMessage: "{PersonOrCompanyName}'s avatar",
        description: {
          text: "Default message for screenreaders for the Avatar",
        },
      },
      { PersonOrCompanyName: owner }
    );
  } else {
    alt = strictLocalize({
      defaultMessage: "Avatar",
      description: {
        text: "Default message for screenreaders for the Avatar",
      },
    });
  }

  return (
    <Container>
      {image && !imageError ? (
        <AvatarWrapper borderColor={borderColor} borderStyle={borderStyle}>
          <OverlayContainer>
            <Image
              className={className}
              alt={alt}
              title={owner}
              $size={size}
              src={optimizeAvatar(image, responsiveSizeValue, isSSR)}
              onError={onImageError}
            />
            {hideTransparencyOverlay ? null : <TransparencyOverlay />}
          </OverlayContainer>
        </AvatarWrapper>
      ) : (
        <AvatarWrapper borderColor={borderColor} borderStyle={borderStyle}>
          <Placeholder $size={size} title={owner} className={className}>
            <Typography
              color={theme.avatar.placeholder.color ?? Core.text.default}
              variant={AvatarSizeToTypographyVariant[responsiveSizeValue]}
            >
              {(userName?.[0] ?? userCompany?.[0] ?? "").toUpperCase()}
            </Typography>
          </Placeholder>
        </AvatarWrapper>
      )}
      {iconData ? (
        <IconContainer $degree={DEGREE_BY_POSITION[iconData.position]}>
          {iconData.icon}
        </IconContainer>
      ) : null}
      {showEventIcon ? (
        <IconContainer $degree={DEGREE_BY_POSITION.topRight}>
          {renderEventsIcon(theme, !!borderColor)}
        </IconContainer>
      ) : null}
    </Container>
  );
};

const AvatarWrapper: React.FC<
  React.PropsWithChildren<{
    borderColor?: Color;
    borderStyle?: AvatarBorderStyle;
  }>
> = ({ borderColor, borderStyle = "OUTLINED", children }) =>
  borderColor ? (
    <ContainerWithBorder $borderColor={borderColor} $borderStyle={borderStyle}>
      {children}
    </ContainerWithBorder>
  ) : (
    <>{children}</>
  );

const avatarStyles = (
  size: ResponsiveProp<AvatarSize>,
  styledComponentsTheme?: StyledComponentsTheme,
  isPlaceholder?: boolean
) => {
  const theme = themeOrDefault(styledComponentsTheme);
  const backgroundColor = isPlaceholder
    ? (theme.avatar?.placeholder.background ?? Core.surface.default)
    : (theme.avatar?.background ?? Core.surface.inverse);

  const sizeBasedStyles = (size: AvatarSize) => `${DIMENSION_BY_SIZE[size]}px`;

  return css`
    border-radius: 50%;
    flex-shrink: 0;
    background-color: ${backgroundColor};
    max-width: none;
    ${buildResponsiveCss("width", size, sizeBasedStyles)}
    ${buildResponsiveCss("height", size, sizeBasedStyles)}
  `;
};

const Image = styled.img<{ $size: ResponsiveProp<AvatarSize> }>`
  ${({ $size, theme }) => avatarStyles($size, theme)};
`;

const Placeholder = styled(Row).attrs({
  align: "center",
  justify: "center",
})<{ $size: ResponsiveProp<AvatarSize> }>`
  ${({ $size: size, theme }) => avatarStyles(size, theme, true)};
`;

const degreeToRadian = (degrees: number) => degrees * (Math.PI / 180);

const Container = styled(Row)`
  position: relative;
  width: fit-content;
  height: fit-content;
  flex-shrink: 0;
  ${highVisSlateComponent(HIGH_VIS_FILTER.COMPONENT)}
`;

const IconContainer = styled.div<{
  $degree: number;
}>`
  position: absolute;
  ${({ $degree }) => css`
    right: ${50 * (1 - Math.cos(degreeToRadian($degree)))}%;
    top: ${50 * (1 - Math.sin(degreeToRadian($degree)))}%;
  `}
  transform: translate(50%, -50%);
`;

const renderEventsIcon = (theme: IProps["theme"], hasBorder?: boolean) => {
  if (!theme?.eventIcon) {
    return null;
  }
  const Component = theme.eventIcon.Component;

  return (
    <Wrapper align="center" $hasBorder={!!hasBorder}>
      <DynamicIcon
        as={Component}
        width={theme.name === FaireMarketTheme.name ? "14px" : "12px"}
        height={theme.name === FaireMarketTheme.name ? "14px" : "12px"}
        $left={theme.name === FaireMarketTheme.name ? "3px" : "4px"}
      />
      <InnerBorder />
    </Wrapper>
  );
};

const ContainerWithBorder = styled(Row)<{
  $borderColor: Color;
  $borderStyle: AvatarBorderStyle;
}>`
  ${({ $borderColor, $borderStyle }) =>
    getBorderStyles($borderColor, $borderStyle)}
  background-color: ${Core.surface.inverse};
  border-radius: 50%;
`;

const getBorderStyles = (
  borderColor: Color,
  borderStyle: AvatarBorderStyle
) => {
  if (borderStyle === "THIN") {
    return css`
      border: 1px solid ${borderColor};
    `;
  } else if (borderStyle === "OUTLINED") {
    return css`
      border: 2px solid ${borderColor};
      padding: 1px;
    `;
  }
  return css``;
};

const OverlayContainer = styled(Row)`
  position: relative;
  border-radius: 50%;
  overflow: hidden;
`;

const DynamicIcon = styled(SVGIcon)<{ $left: string }>`
  position: absolute;
  left: ${(props) => props.$left};
`;

const Wrapper = styled(Row)<{ $backgroundColor?: Color; $hasBorder: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) => props.$backgroundColor};
  position: absolute;
  top: -8px;
  right: ${(props) => (props.$hasBorder ? "-10px" : "-13px")};
`;

const InnerBorder = styled.div`
  box-shadow: inset 0px 0px 0px 1px ${Core.surface.subdued};
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: inherit;
`;
