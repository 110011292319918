import { BrandMarketingEmailsPreference } from '../../indigofair/data/BrandMarketingEmailsPreference';
import { IAddress } from '../../indigofair/data/IAddress';
import { IAvailableMembership } from '../../indigofair/data/IAvailableMembership';
import { IFaireMoney } from '../../indigofair/data/IFaireMoney';
import { IImage } from '../../indigofair/data/IImage';
import { IMembership } from '../../indigofair/data/IMembership';
import { IPaymentTermData } from '../../indigofair/data/IPaymentTermData';
import { IRetailerIntegrationsMetadata } from '../../indigofair/data/IRetailerIntegrationsMetadata';
import { IRetailerLaunchWithFaire } from '../../indigofair/data/IRetailerLaunchWithFaire';
import { IRetailerNetTerms } from '../../indigofair/data/IRetailerNetTerms';
import { IRetailerOrderMinimumOverride } from '../../indigofair/data/IRetailerOrderMinimumOverride';
import { PaymentTerm } from '../../indigofair/data/PaymentTerm';
import { IBusinessIdentifier } from '../../indigofair/idv/IBusinessIdentifier';
import { IRetailerDetails } from '../../indigofair/retailer_details/IRetailerDetails';
import { ISalesforceUser } from '../../salesforce/ISalesforceUser';

export namespace IRetailer {
  export enum BusinessCategory {
    BUSINESS_CATEGORY_UNKNOWN = 'BUSINESS_CATEGORY_UNKNOWN',
    BUSINESS_OTHER = 'BUSINESS_OTHER',
    BRICK_AND_MORTAR = 'BRICK_AND_MORTAR',
    ONLINE_ONLY = 'ONLINE_ONLY',
    POP_UP = 'POP_UP',
  }

  export enum ReportedCreationTime {
    OPENING_SOON = 'OPENING_SOON',
    /**
     * @deprecated
     */
    STARTED_IN_2018 = 'STARTED_IN_2018',
    /**
     * @deprecated
     */
    BETWEEN_2013_AND_2017 = 'BETWEEN_2013_AND_2017',
    /**
     * @deprecated
     */
    BEFORE_2013 = 'BEFORE_2013',
    STARTED_THIS_YEAR = 'STARTED_THIS_YEAR',
    /**
     * @deprecated
     */
    BETWEEN_5_AND_1_YEAR_AGO = 'BETWEEN_5_AND_1_YEAR_AGO',
    BEFORE_5_YEAR_AGO = 'BEFORE_5_YEAR_AGO',
    STARTED_ONE_YEAR_AGO = 'STARTED_ONE_YEAR_AGO',
    STARTED_TWO_YEARS_AGO = 'STARTED_TWO_YEARS_AGO',
    STARTED_THREE_YEARS_AGO = 'STARTED_THREE_YEARS_AGO',
    STARTED_FOUR_YEARS_AGO = 'STARTED_FOUR_YEARS_AGO',
    /**
     * @deprecated
     */
    STARTED_BEFORE_FOUR_YEARS_AGO = 'STARTED_BEFORE_FOUR_YEARS_AGO',
    STARTED_BETWEEN_5_AND_2_YEAR_AGO = 'STARTED_BETWEEN_5_AND_2_YEAR_AGO',
    STARTED_FIVE_YEARS_AGO = 'STARTED_FIVE_YEARS_AGO',
  }

  export enum NeverClawbackReturnsReason {
    HIGH_VALUE = 'HIGH_VALUE',
    STRATEGIC_PARTNER = 'STRATEGIC_PARTNER',
    HIGH_RISK = 'HIGH_RISK',
    OTHER = 'OTHER',
  }

  export enum InsiderMembershipState {
    /**
     * Excluded from insider due to experiment
     */
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    /**
     * Eligible but have never had insider -- will purchase free trial first
     */
    ELIGIBLE = 'ELIGIBLE',
    /**
     * Currently active with insider on a TRIALING or ACTIVE membership
     */
    ACTIVE = 'ACTIVE',
    /**
     * Previously active with a CANCELLED membership
     */
    CHURNED = 'CHURNED',
    /**
     * Previously active with an UNPAID membership
     */
    UNPAID = 'UNPAID',
    /**
     * Previously active with an PAUSED membership
     */
    PAUSED = 'PAUSED',
  }

  export enum IdentityConfirmedBy {
    /**
     * Required by test
     */
    IDENTITY_CONFIRMED_BY_UNKNOWN = 'IDENTITY_CONFIRMED_BY_UNKNOWN',
    /**
     * Retailer confirmed through an IDV heuristic
     */
    HEURISTICS = 'HEURISTICS',
    /**
     * Retailer confirmed through the IDV model
     */
    MODEL = 'MODEL',
    /**
     * Retailer confirmed through admin
     */
    ADMIN = 'ADMIN',
    /**
     * Unsure how retailer was confirmed
     */
    UNSURE = 'UNSURE',
  }

  export enum MultiUserState {
    MULTI_USER_STATE_UNKNOWN = 'MULTI_USER_STATE_UNKNOWN',
    /**
     * Retailer has never invited a sub-user
     * @deprecated
     */
    NOT_MULTI_USER = 'NOT_MULTI_USER',
    /**
     * Retailer has invited at least one sub-user. The invitation may or may not currently be valid
     */
    HAS_INVITED_SUBUSER = 'HAS_INVITED_SUBUSER',
    /**
     * Retailer has no active sub-users, but at least one pending invite
     * @deprecated
     */
    HAS_INVITED_PENDING_SUBUSER = 'HAS_INVITED_PENDING_SUBUSER',
    /**
     * Retailer currently has active sub-users
     */
    HAS_ACTIVE_SUBUSER = 'HAS_ACTIVE_SUBUSER',
    /**
     * Retailer has had at least one active sub-user before. The retailer may or may not currently have active sub-users
     */
    HAS_EVER_HAD_ACTIVE_SUB_USER = 'HAS_EVER_HAD_ACTIVE_SUB_USER',
  }

  export enum MultiStoreStatus {
    MULTI_STORE_STATUS_UNKNOWN = 'MULTI_STORE_STATUS_UNKNOWN',
    /**
     * Retailer currently has multiple non-deleted store
     */
    HAS_MULTIPLE_STORES = 'HAS_MULTIPLE_STORES',
    /**
     * Retailer has had at least one active non-default-store before. The retailer may or may not currently have multiple non-deleted stores
     */
    HAS_EVER_HAD_MULTIPLE_STORES = 'HAS_EVER_HAD_MULTIPLE_STORES',
  }

  /**
   * Redacted copy of IRetailer (i.e. without redacted fields).
   */
  export type Redacted = Omit<
    IRetailer,
    | 'id'
    | 'salesforce_lead_id'
    | 'salesforce_account_id'
    | 'salesforce_owner_id'
    | 'default_shipping_address'
    | 'email_address'
    | 'phone_number'
    | 'recovery_email_address'
    | 'recovery_phone_number'
    | 'identity_confirmation_notes'
    | 'recommendation_notes'
    | 'square_access_renewed_at'
    | 'shopify_access_renewed_at'
    | 'blacklisted'
    | 'bankrupted'
    | 'instagram_followers'
    | 'never_clawback_returns_reason'
    | 'updated_at'
    | 'sales_tax_number'
    | 'accounting_email_address'
    | 'sales_activation_owner_email'
  >;

  /**
   * Return an instance of IRetailer with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IRetailer>): IRetailer =>
    ({
      categories: [],
      images: [],
      memberships: [],
      order_minimum_overrides: [],
      business_identifiers: [],
      default_store_business_identifiers: [],
      available_memberships: [],
      multi_user_states: [],
      business_platforms: [],
      multi_store_statuses: [],
      ...(partial ?? {}),
    }) as IRetailer;
}

export interface IRetailer {
  token: string | undefined;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  id: number | undefined; // redacted
  /**
   * The reseller ID for this retailer.
   */
  reseller_id: string | undefined;
  /**
   * [REDACTED]
   * @deprecated
   * @protobufOption (indigofair.data.redacted) = true
   */
  salesforce_lead_id: string | undefined; // redacted
  /**
   * [REDACTED]
   * @deprecated
   * @protobufOption (indigofair.data.redacted) = true
   */
  salesforce_account_id: string | undefined; // redacted
  /**
   * [REDACTED]
   * @deprecated
   * @protobufOption (indigofair.data.redacted) = true
   */
  salesforce_owner_id: string | undefined; // redacted
  /**
   * @deprecated
   */
  salesforce_owner: ISalesforceUser | undefined;
  active: boolean | undefined;
  name: string | undefined;
  /**
   * No longer valid since retailers no longer get unconditional free shipping
   * Please use free shipping endpoints to check whether retailer has free shipping under specific cases
   * @deprecated
   */
  free_shipping: boolean | undefined;
  /**
   * If true, this retailer is eligible for a reward on new makers.
   */
  first_order_reward_enabled: boolean | undefined;
  /**
   * If true, this retailer is eligible for a percentage discount on new makers.
   */
  first_order_discount_enabled: boolean | undefined;
  /**
   * If true, this retailer is eligible for free shipping on their first order.
   */
  first_order_free_shipping_enabled: boolean | undefined;
  /**
   * If an employee has granted this retailer free shipping, the time at which this free shipping ends.
   */
  employee_granted_free_shipping_end_at: number | undefined;
  /**
   * The current default payment term to be used for this retailer's payments.
   * Even for a retailer qualified for terms, this can be set to PAYMENT_ON_SHIPMENT. That just means that the
   * retailer's default is payment on shipment. However, the retailer can still choose to pay with net terms.
   * Use PaymentTermSelectorData instead if you need the effective payment term data used during checkout.
   */
  payment_term: keyof typeof PaymentTerm | undefined;
  /**
   * If the retailer qualifies for terms, this flag has their available Net term type.
   * For example, if a retailer signs up with NET60 then changes their default payment term to PAYMENT_ON_SHIPMENT,
   * we still keep this field set to NET60.
   * Use PaymentTermSelectorData instead if you need the effective payment term data used during checkout.
   */
  maximum_available_payment_term: keyof typeof PaymentTerm | undefined;
  /**
   * Deprecated, use paymentTerm or PaymentTermSelectorData instead.
   * @deprecated
   */
  payment_term_data: IPaymentTermData | undefined;
  /**
   * Deprecated, use maximumAvailablePaymentTerm or PaymentTermSelectorData instead.
   * @deprecated
   */
  maximum_available_payment_term_data: IPaymentTermData | undefined;
  /**
   * The fee in bps associated with the selected payment term. i.e. 100 -> 1%
   * @deprecated
   */
  payment_term_fee_bps: number | undefined;
  /**
   * Whether this retailer is qualified to get terms.
   */
  qualified_for_terms: boolean | undefined;
  /**
   * The type of business selected by the user during sign up.
   */
  business_type: string | undefined;
  /**
   * The url entered by the user during sign up.
   */
  website_url: string | undefined;
  /**
   * Use businessCategory instead.
   * @deprecated
   */
  online_only: boolean | undefined;
  /**
   * Whether we should automatically charge for this Retailer's invoices.
   */
  auto_payments: boolean | undefined;
  /**
   * The short description on a retailer is used to describe what the retailer's interests are.
   * Right now it's used on a retailer's landing page to describe their recommendations.
   */
  short_description: string | undefined;
  /**
   * Description of the business coming from Google Places.
   */
  description: string | undefined;
  /**
   * Url of the retailer coming from Google Places.
   */
  url: string | undefined;
  /**
   * Categories of the retailer coming from Google Places.
   */
  categories: Array<string>;
  images: Array<IImage>;
  address: IAddress | undefined;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  default_shipping_address: IAddress | undefined; // redacted
  /**
   * @deprecated
   */
  google_places_json: string | undefined;
  google_place_id: string | undefined;
  yelp_business_id: string | undefined;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  email_address: string | undefined; // redacted
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  phone_number: string | undefined; // redacted
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  recovery_email_address: string | undefined; // redacted
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  recovery_phone_number: string | undefined; // redacted
  owner_user_token: string | undefined;
  invited: boolean | undefined;
  /**
   * The Knowledge Graph type extracted from SerpApi
   */
  knowledge_graph_type: string | undefined;
  /**
   * If true, we've confirmed that this is a real retailer with a real store.
   */
  identity_confirmed: boolean | undefined;
  /**
   * If we've confirmed this retailer's identity manually, this shows the notes added.
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  identity_confirmation_notes: string | undefined; // redacted
  /**
   * @deprecated
   */
  can_skip_payment: boolean | undefined;
  /**
   * @deprecated
   */
  pay_via_check: boolean | undefined;
  /**
   * Notes on the retailer to keep track of past interactions with the retailer that can guide
   * recommendations. E.g., "This retailer does not like candles".
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  recommendation_notes: string | undefined; // redacted
  /**
   * The time at which this retailer placed their last order.
   */
  last_order_at: number | undefined;
  /**
   * The pending balance cents for this Retailer.
   * @deprecated
   */
  pending_balance_cents: number | undefined;
  /**
   * The available balance cents for this Retailer.
   */
  available_balance_cents: number | undefined;
  /**
   * The amount of money this Retailer has spent from their available balance.
   */
  spent_from_balance_cents: number | undefined;
  /**
   * Timestamp of last renewal of Square access for this Retailer.
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  square_access_renewed_at: number | undefined; // redacted
  /**
   * Timestamp of last renewal of Shopify access for this Retailer.
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  shopify_access_renewed_at: number | undefined; // redacted
  /**
   * Limit on how much this retailer can order with terms.
   * @deprecated
   */
  terms_limit_cents: number | undefined;
  terms_limit: IFaireMoney | undefined;
  /**
   * If true, we will enforce term limits on this retailer.
   * @deprecated
   */
  enforce_terms_limit_cents: boolean | undefined;
  /**
   * If true, this retailer cannot place any order. Order will fail explicitly.
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  blacklisted: boolean | undefined; // redacted
  /**
   * If true, this retailer would not receive any comm email or charging attempt.
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  bankrupted: boolean | undefined; // redacted
  instagram_handle: string | undefined;
  instagram_verified: boolean | undefined;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  instagram_followers: number | undefined; // redacted
  /**
   * @deprecated
   */
  annual_sales_min_dollars: number | undefined;
  /**
   * @deprecated
   */
  annual_sales_max_dollars: number | undefined;
  annual_sales_min_amount: IFaireMoney | undefined;
  annual_sales_max_amount: IFaireMoney | undefined;
  /**
   * Set through heuristics or internal review,
   * this is the time we believe this retailer started their operations.
   */
  estimated_creation_year: number | undefined;
  /**
   * The approximate creation year of the business as reported by the retailer on signup.
   */
  reported_creation_year: number | undefined;
  /**
   * The reported time when the business was created.
   */
  reported_creation_time:
    | keyof typeof IRetailer.ReportedCreationTime
    | undefined;
  memberships: Array<IMembership>;
  opted_in_to_marketing_emails: boolean | undefined;
  brand_marketing_emails_preference:
    | keyof typeof BrandMarketingEmailsPreference
    | undefined;
  /**
   * When the retailer last applied for increased limit.
   */
  applied_for_limit_increase_at: number | undefined;
  net_terms: IRetailerNetTerms | undefined;
  /**
   * The time at which this retailer placed their first order.
   */
  first_order_at: number | undefined;
  /**
   * The postal code of the retailer if known at sign-up.
   */
  postal_code: string | undefined;
  zip_code_protection_enabled: boolean | undefined;
  /**
   * Controls if flash sales and mix and match are shown in any entry point across all clients
   * @deprecated
   */
  hide_flash_sales_mix_and_match_r2_m: boolean | undefined;
  /**
   * Controls if flash sales and mix and match are shown in any entry point across all clients
   */
  hide_redistribution_channels: boolean | undefined;
  /**
   * Controls if Retailer2Maker is shown
   */
  hide_retailer2_maker: boolean | undefined;
  /**
   * Shows flash sale and mix and match in top nav with redirects to an unavailable page
   */
  show_flash_sales_mix_and_match_as_unavailable: boolean | undefined;
  order_minimum_overrides: Array<IRetailerOrderMinimumOverride>;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  never_clawback_returns_reason:
    | keyof typeof IRetailer.NeverClawbackReturnsReason
    | undefined; // redacted
  /**
   * 3 letter ISO-code for which country the retailer is in. This is determined by...
   *   1. The country provided at signup (post March 2020 signups)
   *   2. The country of the default shipping address for the owning user
   *   3. The country of the retailer.address
   * ...or undefined if we have none of the above information.
   */
  country: string | undefined;
  /**
   * @deprecated
   */
  redistribution_long_term_holdout: boolean | undefined;
  currency: string | undefined;
  version: number | undefined;
  created_at: number | undefined;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  updated_at: number | undefined; // redacted
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  sales_tax_number: string | undefined; // redacted
  /**
   * Retailer is eligible to onboard and activate on the neighborhood marketplace
   */
  allowed_in_neighborhood: boolean | undefined;
  /**
   * Retailer is currently active on the neighborhood marketplace
   */
  sell_on_neighborhood: boolean | undefined;
  /**
   * The token of payout instrument to be used when paying out Neighborhood orders for this Retailer.
   * @deprecated
   */
  payout_instrument_token: string | undefined;
  /**
   * TODO(robert.ma): Deprecated field used by Neighborhood; to be removed soon.
   * @deprecated
   */
  token_alias: string | undefined;
  /**
   * @deprecated
   */
  is14_day_return: boolean | undefined;
  /**
   * return default return window of the retailer *
   */
  return_window_days: number | undefined;
  auto_payments_suspended_until: number | undefined;
  is_high_returner: boolean | undefined;
  /**
   * no longer populated because - dead code
   * @deprecated
   */
  integrations_metadata: IRetailerIntegrationsMetadata | undefined;
  retailer_details: IRetailerDetails | undefined;
  /**
   * No longer populated because - dead code
   * @deprecated
   */
  reseller_certificate_during_signup: boolean | undefined;
  /**
   * Contains filtered information about the current state of the retailer's insider membership
   */
  insider_membership_state:
    | keyof typeof IRetailer.InsiderMembershipState
    | undefined;
  sent_to_debt_collections: boolean | undefined;
  pos_linked: boolean | undefined;
  credit_card_terms_limit_cents_during_signup: number | undefined;
  /**
   * Deprecated use, PaymentTermSelectorData instead.
   * @deprecated
   */
  payment_on_shipment_term_data: IPaymentTermData | undefined;
  auth_hold_eligible: boolean | undefined;
  launch_with_faire: IRetailerLaunchWithFaire | undefined;
  is_apparel_retailer: boolean | undefined;
  business_identifiers: Array<IBusinessIdentifier>;
  default_store_business_identifiers: Array<IBusinessIdentifier>;
  is_eligible_for_apparel_exclusive_sale: boolean | undefined;
  social_seller: boolean | undefined;
  /**
   * The state of the retailer for Insider purposes.
   * If the retailer has provided us an address, we'll use [bestAddressState].
   * If the retailer hasn't provided an address, we use the state they made their initial signup request from.
   */
  insider_experiment_state: string | undefined;
  show_insider_signup_offer: boolean | undefined;
  active_cart_token: string | undefined;
  annual_inventory_volume_min_amount: IFaireMoney | undefined;
  annual_inventory_volume_max_amount: IFaireMoney | undefined;
  discounted_membership_months_end_at: number | undefined;
  business_category: keyof typeof IRetailer.BusinessCategory | undefined;
  instrument_limit: IFaireMoney | undefined;
  has_placed_marketplace_order: boolean | undefined;
  identity_confirmed_by: keyof typeof IRetailer.IdentityConfirmedBy | undefined;
  available_memberships: Array<IAvailableMembership>;
  /**
   * @deprecated
   */
  has_multi_account_users: boolean | undefined;
  /**
   * @deprecated
   */
  has_multi_active_account_users: boolean | undefined;
  /**
   * @deprecated
   */
  multi_user_state: keyof typeof IRetailer.MultiUserState | undefined;
  multi_user_states: Array<keyof typeof IRetailer.MultiUserState>;
  business_platforms: Array<string>;
  legal_business_name: string | undefined;
  untranslated_business_type: string | undefined;
  original_locale: string | undefined;
  num_physical_stores_description: string | undefined;
  /**
   * True if the retailer has multiple non-deleted stores
   * @deprecated
   */
  is_multi_store_retailer: boolean | undefined;
  is_pre_approved_for_expanding_offer: boolean | undefined;
  is_pre_qualified_for_expanding_offer: boolean | undefined;
  use_bank_transfer: boolean | undefined;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  accounting_email_address: string | undefined; // redacted
  multi_store_statuses: Array<keyof typeof IRetailer.MultiStoreStatus>;
  /**
   * [REDACTED]
   * @protobufOption (indigofair.data.redacted) = true
   */
  sales_activation_owner_email: string | undefined; // redacted
  is_guest: boolean | undefined;
}
