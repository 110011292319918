import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { Storage } from "@faire/web--source/common/Storage";

export interface ISettingsAndExperimentsPerRouteProps {
  [pathName: string]: ITrackedSettingsAndExperimentProps;
}

export interface ITrackedSettingsAndExperimentProps {
  assignments: { [key: string]: any };
  settings: { [key: string]: any };
}

// used for development via extension plugin - allows for tracking of settings
export const ENABLE_SETTING_XP_LOGGING = "ENABLE_SETTING_XP_LOGGING";
export const SETTINGS_AND_XP_TRACKED = "SETTINGS_AND_XP_TRACKED";

export const isLoggingSettingUsageEnabled = () =>
  Storage.local.getItem(ENABLE_SETTING_XP_LOGGING);

export const trackSettingsAndExperiment = (
  settingNames: string[],
  options: {
    settingValue?: any;
    isAssignment?: boolean;
  }
) => {
  const pathName = getWindow()?.location.pathname ?? "path_undefined";

  const currentValues = JSON.parse(
    Storage.local.getItem(SETTINGS_AND_XP_TRACKED) || "{}"
  ) as ISettingsAndExperimentsPerRouteProps;
  const { settingValue, isAssignment } = options;

  const valuesOnCurrentPage = currentValues[pathName] ?? {
    assignments: {},
    settings: {},
  };

  settingNames.forEach((settingName) => {
    if (isAssignment) {
      valuesOnCurrentPage["assignments"][settingName] = true;
    } else {
      valuesOnCurrentPage["settings"][settingName] = settingValue;
    }
  });

  currentValues[pathName] = valuesOnCurrentPage;
  Storage.local.setItem(SETTINGS_AND_XP_TRACKED, JSON.stringify(currentValues));
};
