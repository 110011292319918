import { localNum } from "@faire/web--source/common/localization/localize";
import { RoundingStrategy, formatMoney } from "@faire/web--source/common/money";
import { isNullish } from "@faire/web--source/common/typescriptUtils";
import { IBrandDiscount } from "@faire/web-api--source/indigofair/data/IBrandDiscount";
import { IFaireMoney } from "@faire/web-api--source/indigofair/data/IFaireMoney";

import { getSettingFaireDirectRetailerAwardAmount } from "@faire/retailer/settings/getSettingFaireDirectRetailerAwardAmount";

export const getFaireDirectFirstOrderIncentiveAmountFields = (
  faireDirectFirstOrderIncentive: IBrandDiscount | undefined
) => {
  const {
    amount_bps: amountBps,
    original_amount_bps: originalAmountBps,
    max_percentage_discount_amount: maxPercentageDiscountAmount,
    retailer_amount: retailerAmount,
  } = faireDirectFirstOrderIncentive ?? {};

  const isFaireDirectCreditPercentageBased =
    getIsFaireDirectCreditPercentageBased({ amountBps, originalAmountBps });

  const formattedPercentageBasedNumber = formatPercentageBasedDiscount({
    amountBps,
    originalAmountBps,
  });

  const formattedMaxPercentageDiscountAmount = formatFixedBasedDiscount({
    fixedBasedAmount: maxPercentageDiscountAmount,
  });

  const formattedFixedBasedAmount = formatFixedBasedDiscount({
    fixedBasedAmount: !isFaireDirectCreditPercentageBased
      ? (retailerAmount ?? getSettingFaireDirectRetailerAwardAmount())
      : undefined,
  });

  return {
    isFaireDirectCreditPercentageBased,
    formattedPercentageBasedNumber,
    formattedMaxPercentageDiscountAmount,
    formattedFixedBasedAmount,
  };
};

const getIsFaireDirectCreditPercentageBased = ({
  amountBps,
  originalAmountBps,
}: {
  amountBps: number | undefined;
  originalAmountBps: number | undefined;
}) => {
  const hasAmountBps = !!amountBps && amountBps > 0;
  const hasOriginalAmountBps = !!originalAmountBps && originalAmountBps > 0;
  return hasAmountBps || hasOriginalAmountBps;
};

const formatPercentageBasedDiscount = ({
  amountBps,
  originalAmountBps,
}: {
  amountBps: number | undefined;
  originalAmountBps: number | undefined;
}) => {
  const percentageAmount = amountBps ?? originalAmountBps;

  if (isNullish(percentageAmount)) {
    return "";
  }

  return localNum(percentageAmount / 10000, { style: "percent" });
};

const formatFixedBasedDiscount = ({
  fixedBasedAmount,
}: {
  fixedBasedAmount: IFaireMoney | undefined;
}) => {
  if (isNullish(fixedBasedAmount)) {
    return "";
  }

  return formatMoney({
    money: fixedBasedAmount,
    roundingStrategy: RoundingStrategy.DROP_CENTS,
    disableAutoConvertCurrency: true,
  });
};
