/**
 * Codes are ISO 639-3
 */
export enum Language {
  LANGUAGE_UNKNOWN = 'LANGUAGE_UNKNOWN',
  /**
   * English
   */
  ENG = 'ENG',
  /**
   * French
   */
  FRA = 'FRA',
  /**
   * German
   */
  DEU = 'DEU',
  /**
   * Spanish
   */
  SPA = 'SPA',
  /**
   * Italian
   */
  ITA = 'ITA',
  /**
   * Norwegian
   */
  NOR = 'NOR',
  /**
   * Swedish
   */
  SWE = 'SWE',
  /**
   * Dutch
   */
  NLD = 'NLD',
  /**
   * Portuguese
   */
  POR = 'POR',
  /**
   * Danish
   */
  DAN = 'DAN',
  /**
   * Gibberish as a language to support gibberish string mode.
   * This language uses special letters and is intended to look like a foreign language.
   * Use case: it mimics rendering a page in a language not yet launched
   */
  GIBBERISH = 'GIBBERISH',
  /**
   * String ID as a language to support string ID mode.
   * This language corresponds to string ID mode and converts Faire generated contents to their ID.
   * Use case: this helps engineers and language managers to quick locate a string.
   */
  STRING_ID = 'STRING_ID',
}
