import { logError } from "@faire/web--source/common/logging";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import {
  context,
  propagation,
  trace,
  Span,
  Context,
  SpanKind,
  Attributes,
  SpanOptions,
  ROOT_CONTEXT,
  SpanContext,
  HrTime,
} from "@opentelemetry/api";
import { W3CTraceContextPropagator } from "@opentelemetry/core";
import { OTLPTraceExporter as ProtoExporter } from "@opentelemetry/exporter-trace-otlp-proto";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { DocumentLoadInstrumentation } from "@opentelemetry/instrumentation-document-load";
import { B3InjectEncoding, B3Propagator } from "@opentelemetry/propagator-b3";
import { Resource } from "@opentelemetry/resources";
import { SimpleSpanProcessor } from "@opentelemetry/sdk-trace-base";
import {
  ConsoleSpanExporter,
  WebTracerProvider,
  Tracer,
  BatchSpanProcessor,
} from "@opentelemetry/sdk-trace-web";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { Timeout } from "react-number-format/types/types";

import { getGlobalProperty } from "../globals/getGlobalProperty";
import { getSettingOpenTelemetryCollectorUrl } from "../settings/getSettingPeteOpenTelemetry";

import { LongAnimationFrameInstrumentation } from "./instrumentations/LongAnimationFrame";
import { LONG_ANIMATION_FRAME_PERFORMANCE_TYPE } from "./instrumentations/LongAnimationFrame";
import {
  LONGTASK_PERFORMANCE_TYPE,
  LongTaskInstrumentation,
} from "./instrumentations/LongRunningTask";
import {
  B3_HEADER_SAMPLED,
  B3_HEADER_SPAN,
  B3_HEADER_TRACE,
  DD_HEADER_ORIGIN,
  ITelemetryHeaders,
  ITelemetryManager,
} from "./TelemetryManager";

export interface ITraceEventProps {
  name: string;
  eventCategory?: string;
  displayName?: string;
  productArea?: string;
}

const PROTO_MAX_EXPORT_BATCH_SIZE = 100;
export const ACTIVE_TRACE_TIMEOUT = 60000; // 1 minute

/**
 * CustomIdGenerator is a custom ID generator that generates 128-bit trace and
 * 64-bit span IDs.
 *
 * This is required because the default ID generator generates IDs that are not
 * compatible with OpenTrace. This can be removed once OpenTelemetry is supported
 * in the backend entire stack.
 */
class CustomIdGenerator {
  generateTraceId = () => {
    let hex = "";
    for (let i = 0; i < 8; i++) {
      // Generate a random byte
      const byte = Math.floor(Math.random() * 256).toString(16);
      // Pad each byte to ensure 2 characters
      hex += byte.padStart(2, "0");
    }
    // Pad the generated 64-bit hex to 128 bits with leading zeros
    const id = hex.padStart(32, "0");
    return id;
  };

  generateSpanId = () => {
    let hex = "";
    for (let i = 0; i < 8; i++) {
      const byte = Math.floor(Math.random() * 256).toString(16);
      hex += byte.padStart(2, "0");
    }
    return hex;
  };
}

function hexToDecimal(hexString: string) {
  return BigInt(`0x${hexString}`).toString(10);
}

/**
 * TelemetryManager is a singleton class that manages the OpenTelemetry
 * provider and tracer. It provides methods to start and end traces and spans,
 * and to get the headers for the active trace or span.
 * It also provides methods to start, end, and get headers for managed spans.
 * Managed spans are spans that are created by the TelemetryManager
 * and are not directly associated with the current context.
 */
export class TelemetryManagerOtel implements ITelemetryManager {
  static get = singletonGetter(TelemetryManagerOtel);

  private provider: WebTracerProvider | undefined;
  private tracer: Tracer | undefined = undefined;
  private activeTrace: Span | undefined = undefined;
  private activeTraceContext: Context | undefined = undefined;
  private activeTraceName: string | undefined = undefined;
  private activeTraceTimeout: Timeout | undefined = undefined;
  private nextJSTraceContextUsed: boolean = false;

  // Managed spans are spans that are created by the TelemetryManager
  // and are not directly associated with the current context.
  private managedSpans: Map<string, Span> = new Map();

  constructor() {
    // TODO: Hardcoded service name to avoid doing this in multiple PRs.
    // Once web-retailer is updated to pass this in, we can remove this.
    this.initialize("web-retailer", false);
  }

  initialize = (serviceName: string, consoleProvider?: boolean) => {
    this.provider = new WebTracerProvider({
      idGenerator: new CustomIdGenerator(),
      resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: serviceName,
      }),
    });

    this.provider.register({
      propagator: new B3Propagator({
        injectEncoding: B3InjectEncoding.MULTI_HEADER,
      }),
    });

    if (consoleProvider) {
      this.provider.addSpanProcessor(
        new SimpleSpanProcessor(new ConsoleSpanExporter())
      );
    }
    propagation.setGlobalPropagator(new W3CTraceContextPropagator());

    const exporter = new ProtoExporter({
      url: getSettingOpenTelemetryCollectorUrl(),
    });
    this.provider.addSpanProcessor(
      new BatchSpanProcessor(exporter, {
        maxExportBatchSize: PROTO_MAX_EXPORT_BATCH_SIZE,
      })
    );

    this.tracer = this.provider.getTracer(serviceName);

    registerInstrumentations({
      instrumentations: [
        new DocumentLoadInstrumentation(),
        new LongTaskInstrumentation({
          onCreateSpan: (startTime: HrTime) => {
            if (this.activeTrace) {
              return this.tracer?.startSpan(
                LONGTASK_PERFORMANCE_TYPE,
                {
                  startTime,
                  root: !this.activeTraceContext,
                  kind: SpanKind.CLIENT,
                  attributes: {
                    ...TelemetryManagerOtel.getDefaultSpanAttributes(),
                  },
                },
                this.activeTraceContext
              );
            }
            return;
          },
        }),
        new LongAnimationFrameInstrumentation({
          onCreateSpan: (startTime: HrTime) => {
            if (this.activeTrace) {
              return this.tracer?.startSpan(
                LONG_ANIMATION_FRAME_PERFORMANCE_TYPE,
                {
                  startTime,
                  root: !this.activeTraceContext,
                  kind: SpanKind.CLIENT,
                  attributes: {
                    ...TelemetryManagerOtel.getDefaultSpanAttributes(),
                  },
                },
                this.activeTraceContext
              );
            }
            return;
          },
        }),
      ],
    });
  };

  /**
   * Start a trace with the given name and optional PETE event key.
   * Only one trace can be active at a time.
   * Managed spans created during the trace will be associated with the trace
   * regardless of the current context.
   *
   * @param name The name of the trace
   * @param attributes The attributes to attach to the trace
   * @returns
   */
  startTrace = (
    name: string,
    attributes?: Attributes,
    options?: SpanOptions
  ): Span | undefined => {
    if (this.tracer === undefined) {
      logError("TelemetryManager is not initialized");
      return;
    }

    if (this.activeTrace !== undefined) {
      logError("TelemetryManager - A trace is already active");
      return;
    }
    let parentContext;

    const nextJSTraceContext = getGlobalProperty<SpanContext>(
      "rootLayoutSpanContext"
    );
    if (nextJSTraceContext && !this.nextJSTraceContextUsed) {
      parentContext = trace.setSpanContext(ROOT_CONTEXT, nextJSTraceContext);
      this.nextJSTraceContextUsed = true;
    }

    const parentTraceId = parentContext
      ? trace.getSpan(parentContext)?.spanContext().traceId
      : undefined;

    const traceSpan = this.tracer.startSpan(
      name,
      {
        root: !parentTraceId,
        attributes: {
          ...TelemetryManagerOtel.getDefaultSpanAttributes(),
          ...attributes,
          parentTraceId: parentTraceId,
        },
        ...options,
      },
      parentContext
    );

    this.activeTrace = traceSpan;
    this.activeTraceContext = trace.setSpan(context.active(), traceSpan);

    this.activeTraceName = name;

    // end the active trace after ACTIVE_TRACE_TIMEOUT
    this.activeTraceTimeout = setTimeout(() => {
      this.activeTrace?.setAttribute("timed_out", true);
      this.endTrace(name);
    }, ACTIVE_TRACE_TIMEOUT);

    return traceSpan;
  };

  /**
   * End the active trace if it matches the given name.
   * If no name is provided, the active trace will be ended.
   * @param name
   */
  endTrace = (name?: string, attributes?: Attributes) => {
    if (!this.activeTraceName || !name || this.activeTraceName === name) {
      if (attributes) {
        this.activeTrace?.setAttributes(attributes);
      }
      this.activeTrace?.end();
      this.activeTraceContext = undefined;
      this.activeTrace = undefined;
      // clear the active trace timeout
      if (this.activeTraceTimeout) {
        clearTimeout(this.activeTraceTimeout);
        this.activeTraceTimeout = undefined;
      }
    }
  };

  private b3ToDataDogHeaders = (
    b3Headers: Record<string, string>
  ): ITelemetryHeaders => {
    return {
      DD_HEADER_TRACE: b3Headers.B3_HEADER_TRACE,
      DD_HEADER_SPAN: b3Headers.B3_HEADER_SPAN,
      DD_HEADER_SAMPLED: b3Headers.B3_HEADER_SAMPLED,
      DD_HEADER_ORIGIN: "web",
    };
  };

  getActiveTraceHeaders = (): ITelemetryHeaders | undefined => {
    if (!this.activeTrace) {
      return;
    }
    const spanContext = this.activeTrace.spanContext();
    const traceId = spanContext?.traceId || "";
    const spanId = spanContext?.spanId || "";
    const headers = {
      traceparent: `00-${traceId}-${spanId}-01`,
      tracestate: "fa=st:link",
    };
    return headers;
  };

  private static getDefaultSpanAttributes = () => {
    return {
      "usr.brand_token": getGlobalProperty("brand.token") as string,
      "usr.token": getGlobalProperty("user.token") as string,
      "usr.retailer_token": getGlobalProperty("user.retailer_token") as string,
      "usr.type": getGlobalProperty("user.type") as string,
      "usr.locale": getGlobalProperty("user.locale_key.language") as string,
      "sampling.priority:": 1,
    };
  };

  getActiveTraceId = (): string | undefined => {
    if (this.activeTrace) {
      const spanContext = this.activeTrace.spanContext();
      return spanContext?.traceId;
    }
    return undefined;
  };

  /**
   * Start a span with the given name and optional attributes.
   * This span will be attached to the current context.
   * If root is true, the span will be a root span (i.e. not a child of the current span).
   * @param name The name of the span
   * @param attributes The attributes to attach to the span
   * @param parentSpan The parent span of the new span
   * @param root If true, the span will be a root span
   * @returns
   */
  startSpan = (
    name: string,
    attributes?: Attributes,
    parentSpan?: Span,
    root?: boolean
  ) => {
    const spanAttributes = {
      ...TelemetryManagerOtel.getDefaultSpanAttributes(),
      ...attributes,
    };
    let span: Span | undefined;

    // if root is true, the span will be a root span (i.e. not a child of the current span)
    // and will set the active context to the span.
    if (!root && parentSpan) {
      context.with(trace.setSpan(context.active(), parentSpan), () => {
        span = this.tracer?.startSpan(name, {
          root,
          attributes: spanAttributes,
        });
      });
    } else {
      span = this.tracer?.startSpan(name, { attributes: spanAttributes });
    }

    return span;
  };

  /**
   * End the given span.
   * @param span
   */
  endSpan = (span: Span | undefined) => {
    span?.end();
  };

  /**
   * Start a managed span with the given name and attributes.
   * Managed spans are spans that are created by the TelemetryManager
   * and are not directly associated with the current context.
   *
   * @param name
   * @param attributes
   * @returns
   */
  startManagedSpan = (
    name: string,
    attributes?: Attributes,
    options?: SpanOptions
  ): string | undefined => {
    const span = this.tracer?.startSpan(
      name,
      {
        root: !this.activeTraceContext,
        kind: SpanKind.CLIENT,
        attributes: {
          ...TelemetryManagerOtel.getDefaultSpanAttributes(),
          ...attributes,
        },
        ...options,
      },
      this.activeTraceContext
    );

    if (!span) {
      return;
    }

    // Store the span in the map
    this.managedSpans.set(span.spanContext().spanId, span);

    return span.spanContext().spanId;
  };

  /**
   * End the managed span with the given span ID
   *
   * @param spanId
   */
  endManagedSpan = (spanId: string, attributes?: Attributes): void => {
    const span = this.getManagedSpan(spanId);
    if (!span) {
      return;
    }
    if (attributes) {
      span.setAttributes(attributes);
    }
    span.end();
    this.managedSpans.delete(spanId);
  };

  /**
   * Get the managed span with the given span ID
   *
   * @param spanId
   * @returns
   */
  getManagedSpan = (spanId: string): Span | null => {
    return this.managedSpans.get(spanId) || null;
  };

  /**
   * Get the headers for the span with the given span ID
   *
   * @param span The span or span ID in the case of a managed span
   * @returns
   */
  getSpanRequestHeaders = (
    span?: string | Span
  ): ITelemetryHeaders | undefined => {
    if (!span) {
      return;
    }
    let managedSpan: Span | undefined | null;
    if (typeof span === "string") {
      managedSpan = this.getManagedSpan(span);
    } else {
      managedSpan = span;
    }
    if (!managedSpan) {
      return;
    }
    const spanContext = managedSpan.spanContext();
    const headers = {
      [B3_HEADER_TRACE]: hexToDecimal(spanContext?.traceId || ""),
      [B3_HEADER_SPAN]: hexToDecimal(spanContext?.spanId || ""),
      [B3_HEADER_SAMPLED]: "1",
      [DD_HEADER_ORIGIN]: "web-maker",
    };
    return this.b3ToDataDogHeaders(headers);
  };
}
