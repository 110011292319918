import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { getLocation } from "@faire/web--source/common/globals/getLocation";
import { TraceEventTracker } from "@faire/web--source/common/pete/TraceEventMarkerTracker";
import { ITraceEventMarker } from "@faire/web--source/common/pete/TraceEventTracker";
import { getFaireDirectBrandAliasFromQueryParam } from "@faire/web--source/common/routes/faire-direct/getFaireDirectBrandAliasFromQueryParam";
import { splitHostname } from "@faire/web--source/common/splitHostname";
import { isNotUndefined } from "@faire/web--source/common/typescriptUtils";
import { uuid } from "@faire/web--source/common/uuid";
import { IPixelCustomDataParameters } from "@faire/web-api--source/indigofair/data/IPixelCustomDataParameters";
import { IRecordClientEventRequest } from "@faire/web-api--source/indigofair/data/IRecordClientEventRequest";
import { TrackEvent } from "@faire/web-api--source/types";

/**
 * Helper function to create IRecordClientEventRequest and fire client-events directly. Only to be used for special
 * scenarios.
 * @param params
 */
export const createRecordClientEventRequest = (
  params: TrackEvent
): { request: IRecordClientEventRequest; currentEventTime: number } => {
  const eventID = uuid();
  let subdomain: string | undefined;
  const currentEventTime =
    (params.manualOverrides?.timestampOverride ?? performance.now()) -
    (params.manualOverrides?.timestampOffset ?? 0);
  try {
    const faireDirectBrandAlias = getFaireDirectBrandAliasFromQueryParam(
      getLocation()?.search ?? ""
    );
    if (faireDirectBrandAlias) {
      subdomain = faireDirectBrandAlias;
    } else {
      subdomain = splitHostname(getLocation()?.hostname ?? "")?.subdomain;
    }
  } catch (_) {
    /* ignore */
  }

  let eventMarker: ITraceEventMarker | undefined;
  let eventTiming: number | undefined;
  if (params.sourceEventKey) {
    eventMarker = params.sourceEventMarker?.sourceEventHash
      ? params.sourceEventMarker
      : TraceEventTracker.get().getTraceHash(params.sourceEventKey);

    eventTiming = isNotUndefined(eventMarker?.sourceEventStart)
      ? Math.round(currentEventTime - eventMarker?.sourceEventStart)
      : undefined;
  } else {
    eventMarker = TraceEventTracker.get().createTraceHash(
      params.eventKey,
      currentEventTime
    );
  }

  const eventHash = eventMarker?.sourceEventHash;

  const clientCreatedAt = params.manualOverrides?.clientCreatedAt ?? Date.now();

  return {
    request: IRecordClientEventRequest.build({
      event_id: eventID,
      event_key: params.eventKey,
      client_created_at: clientCreatedAt,
      parameter_tokens: params.parameters
        ? Object.values(params.parameters).map(String)
        : [],
      subdomain,
      pixel_custom_data_parameters: params.pixelCustomDataParams
        ? IPixelCustomDataParameters.build(params.pixelCustomDataParams)
        : undefined,
      source_event_hash: eventHash,
      timing: eventTiming,
      canary_role: getGlobalProperty("canaryRole"),
      pod_template_hash: getGlobalProperty("podTemplateHash"),
    }),
    currentEventTime,
  };
};
