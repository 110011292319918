import { getWindow } from "@faire/web--source/common/getWindow";

import { BAIT_IDS, BAIT_DIV_STYLES } from "./consts";

export const insertAdblockBait = () => {
  const dataContainer = getWindow().document.createElement("div");
  const baitElements: string[] = [];

  BAIT_IDS.forEach((bannerId) => {
    baitElements.push(
      `<div id="${bannerId}" data-test-id="${bannerId}" style="${BAIT_DIV_STYLES}"></div>`
    );
  });

  dataContainer.innerHTML = baitElements.join("");
  getWindow().document.body.appendChild(dataContainer);
};
