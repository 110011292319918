import { request } from '../../..';
import { ConductorSurface } from '../../../indigofair/data/ConductorSurface';
import { IGetConductorComponentResponse } from '../../../indigofair/data/IGetConductorComponentResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';
import qs from 'query-string';

export type QueryParameters = {
  surface: keyof typeof ConductorSurface | undefined;
  cart_token: string | undefined;
  brand_tokens: Array<string> | undefined;
  shipping_address_token: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/conductor/component${queryString}`;
};

export const build = (
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    params: queryParams,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/conductor/component';

/**
 * Product Area: CONDUCTOR
 */
export function execute(
  queryParams?: QueryParameters
): Promise<IGetConductorComponentResponse>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetConductorComponentResponse>>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IGetConductorComponentResponse>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  | IGetConductorComponentResponse
  | SuccessResponse<IGetConductorComponentResponse>
> {
  const { route, ...requestOptions } = build(queryParams, options);
  return request<IGetConductorComponentResponse>(requestOptions);
}
execute[PATH] = 'api/conductor/component/get';
export default execute;
