import { SettingConst } from '../../types';

/**
 * When enabled, users can unsend messages
 */
export const ENABLE_UNSEND_MESSAGE_240913 =
  'ENABLE_UNSEND_MESSAGE_240913' as SettingConst<
    'ENABLE_UNSEND_MESSAGE_240913',
    boolean,
    | 'MAKER'
    | 'BRAND_ANDROID'
    | 'BRAND_IOS'
    | 'RETAILER'
    | 'RETAILER_ANDROID'
    | 'RETAILER_IOS'
  >;
