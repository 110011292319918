"use client";

import {
  LocalizationProvider,
  type StoreProps,
} from "@faire/web--source/common/localization/useLocalization";
import { buildLocale } from "@faire/web--source/common/localization/utils";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";
import React, { useMemo } from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import type { OptionalIntlConfig } from "react-intl/lib/src/components/provider";

import { DEFAULT_LOCALE, DEFAULT_LOCALE_KEY } from "../consts/DEFAULT_LOCALE";

import { LocalizationStore } from "./LocalizationStore";


/**
 * NOTE(paul): Either the source-of-truth is what you're plumbing,
 * or it's pulled from the LocalizationStore, but not a mix.
 */
type OtherProps = Omit<OptionalIntlConfig, "messages" | "locale"> &
  Omit<StoreProps, "localeKey">;
type HasNeither = OtherProps & {
  messages?: never;
  localeKey?: never;
};
type HasBoth = OtherProps & {
  messages: OptionalIntlConfig["messages"];
  localeKey: ILocaleKey;
};
export type BothOrNeither = HasNeither | HasBoth;

export const UnobservedIntlProvider: React.FC<
  React.PropsWithChildren<BothOrNeither>
> = ({ children, ...args }) => {
  const localeKey =
    args.localeKey ?? LocalizationStore.get().localeKey ?? DEFAULT_LOCALE_KEY;
  const locale = useMemo(() => buildLocale(localeKey), [localeKey]);
  return (
    <ReactIntlProvider
      {...args}
      defaultLocale={args.defaultLocale ?? DEFAULT_LOCALE}
      locale={locale}
      messages={args.messages ?? LocalizationStore.get().messages}
    >
      <LocalizationProvider {...args}>{children}</LocalizationProvider>
    </ReactIntlProvider>
  );
};
