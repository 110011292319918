import { localNum } from "@faire/web--source/common/localization";
import { intlDate } from "@faire/web--source/common/localization/date-fns/intlDate";
import {
  RoundingStrategy,
  formatMoney,
  moneyOrZero,
} from "@faire/web--source/common/money";
import { useQuery as useGetFaireDirectMarketplaceDiscountData } from "@faire/web-api--source/api/faire-direct-marketplace-discount/discount-data/get-hooks";
import { IFDMarketplaceDiscountData } from "@faire/web-api--source/indigofair/data/IFDMarketplaceDiscountData";
import differenceInDays from "date-fns/differenceInDays";
import { useMemo } from "react";

import { mapStyleQuizAnswerToStoreType } from "@faire/retailer/hooks/firstOrderIncentive/mapStyleQuizAnswerToStoreType";
import { useIsLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { getFaireDirectMarketplaceDiscountData } from "@faire/retailer/serialized-data/getFaireDirectMarketplaceDiscountData";

import { hasFaireDirectMarketplaceIncentive } from "./shared/hasFaireDirectMarketplaceIncentive";

export const useFaireDirectMarketplaceIncentive = () => {
  const { data: endpointData, refetch } =
    useGetFaireDirectMarketplaceDiscountData({
      /**
       * We don't want this endpoint to ever fetch on its own, only when
       * landing on post checkout page after an order.
       */
      enabled: false,
    });

  const isLoggedInRetailer = useIsLoggedInRetailer();

  const refetchDataAndUpdateStores = refetch;

  const processedData = useMemo(
    () =>
      processFaireDirectMarketplaceDiscountData(
        isLoggedInRetailer,
        endpointData ?? getFaireDirectMarketplaceDiscountData()
      ),
    [isLoggedInRetailer, endpointData]
  );

  return { ...processedData, refetchDataAndUpdateStores };
};

export const processFaireDirectMarketplaceDiscountData = (
  isLoggedInRetailer: boolean,
  data?: IFDMarketplaceDiscountData
) => {
  const expiresAt = data?.expires_at ?? 0;
  const daysRemaining = differenceInDays(expiresAt, Date.now());
  //differenceInDays rounds down, we want to round up so add 1
  const roundedUpDaysRemaining = daysRemaining + 1;
  const lessThanOneDayRemaining = daysRemaining < 1;
  const maxDiscountAmount = data?.max_discount_amount;
  const expiresAtDateTime = intlDate(expiresAt, "PPpx");
  const expiresAtDate = intlDate(expiresAt, "MMM d");
  const expiresAtDateWithYear = intlDate(expiresAt, "PP");

  // localized values for use in strings
  const minimumOneDaysRemainingNumber = localNum(roundedUpDaysRemaining);
  const formattedDiscountPercentNumber = localNum(
    (data?.discount_bps ?? 0) / 10000,
    {
      style: "percent",
    }
  );
  const formattedMaxDiscountMoney = formatMoney({
    money: moneyOrZero(maxDiscountAmount),
    roundingStrategy: RoundingStrategy.DROP_CENTS,
  });
  const ineligibleBrandTokens = data?.in_eligible_brand_tokens ?? [];
  const expiresAtTime = intlDate(data?.expires_at ?? 0, "px");
  const offerName = data?.offer_name;

  const modalImageDesktop = data?.modal_image_desktop;
  const modalImageMobile = data?.modal_image_mobile;

  const isFaireDirectMarketplaceIncentive =
    data?.offer_name === "FAIRE_DIRECT_MARKETPLACE_DISCOUNT";
  const isFaireDirectEvergreenIncentive =
    data?.offer_name === "EVERGREEN_MARKETPLACE_DISCOUNT";

  const storeType = mapStyleQuizAnswerToStoreType(
    data?.store_type_for_modal_text
  );

  const hasIncentive = hasFaireDirectMarketplaceIncentive(
    data,
    isLoggedInRetailer
  );

  return {
    hasIncentive,
    formattedDiscountPercentNumber,
    formattedMaxDiscountMoney,
    lessThanOneDayRemaining,
    minimumOneDaysRemainingNumber,
    daysRemaining: roundedUpDaysRemaining,
    expiresAtDate,
    expiresAtTime,
    expiresAtDateTime,
    ineligibleBrandTokens,
    expiresAt,
    expiresAtDateWithYear,
    offerName,
    modalImageDesktop,
    modalImageMobile,
    isFaireDirectMarketplaceIncentive,
    isFaireDirectEvergreenIncentive,
    storeType,
  };
};
