import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { IntlCache, IntlShape } from "@formatjs/intl/lib/src/types";
import { createIntlCache } from "@formatjs/intl/lib/src/utils";

type CachedIntl = {
  intl: IntlShape<string>;
};

/**
 * @deprecated use `useLocalizationCacheStore` or `withStores` instead
 */
export class LocalizationCacheStore {
  static get = singletonGetter(LocalizationCacheStore, {
    globalSingleton: getGlobalProperty("isMainThread"),
  });

  _cachedIntl: {
    [key: string]: CachedIntl;
  } = {};

  readonly cache: IntlCache = createIntlCache();

  setCachedIntl = (locale: string, intl: IntlShape<string>) => {
    this._cachedIntl[locale] = {
      intl,
    };
  };

  getCachedIntl = (locale: string) => {
    return this._cachedIntl[locale];
  };
}
