export namespace IPerfDarkReadsConfig {
  /**
   * Return an instance of IPerfDarkReadsConfig with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IPerfDarkReadsConfig>
  ): IPerfDarkReadsConfig =>
    ({
      endpoints: [],
      ...(partial ?? {}),
    }) as IPerfDarkReadsConfig;
}

export interface IPerfDarkReadsConfig {
  enabled: boolean | undefined;
  /**
   * likelihood factor p (0.0 ~ 1.0, inclusive) - the likelihood the request will trigger the multiplication.
   * @protobufOption (faire.protobuf.validation) = PROBABILITY
   */
  probability_value: number | undefined;
  /**
   * multiplication factor m - the number (1 - 10, inclusive) of the dark read requests could spawn from a request
   * if the likelihood k chooses the request.
   */
  multiplication_value: number | undefined;
  /**
   * delay in number of seconds when we send out dark reads after the normal request is resolved
   */
  dark_read_burst_delay_in_second: number | undefined;
  /**
   * delay in number of seconds when the next setting fetch would happen
   */
  next_pulling_delay_in_second: number | undefined;
  /**
   * Mobile web will not exceed this multiplication value.
   */
  mobile_web_multiplication_value_cap: number | undefined;
  /**
   * Optional allowlist for endpoints that the configuration is applicable to.
   * If omitted, all @DarkReads endpoints are applicable.
   * @protobufOption (faire.protobuf.validation) = REGEXP
   */
  endpoints: Array<string>;
}
