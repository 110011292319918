import {
  TimingMarker,
  TimingPair,
} from "@faire/web--source/common/timings/TimingMarker";
import { trackClientEventsClientSideLatencyTiming } from "@faire/web-api--source/events/clientEvents/timing/clientSideLatency";
import { RequestOptions } from "@faire/web-api--source/types";
import {
  ResponseInterceptor,
  Response as WebApiResponse,
} from "@faire/web-api--source/types";

import { logError } from "../../logging";
import { CLIENT_SIDE_LATENCY_HEADER } from "../request-interceptors/clientSideLatencyRequestInterceptor";

/**
 * Processes client-side latency timing for tracked requests.
 * Calculates request duration and tracks it if latency tracking is enabled.
 *
 * @param request - The original request options
 * @param response - The API response
 * @returns The unmodified API response
 */
export const clientSideLatencyResponseInterceptor: ResponseInterceptor = <
  T = unknown
>(
  request: RequestOptions,
  response: WebApiResponse<T>
): WebApiResponse<T> => {
  const timing = request?.headers[CLIENT_SIDE_LATENCY_HEADER];

  if (request.trackClientSideLatency && timing) {
    const timingPair: TimingPair = {
      start: new TimingMarker(timing),
      end: new TimingMarker(),
    };

    try {
      trackClientEventsClientSideLatencyTiming(timingPair, request.url);
    } catch (error: unknown) {
      logError(error);
    }
  }
  return response;
};
