import { IRetailer } from "@faire/web-api--source/indigofair/data/IRetailer";

export const insiderMembershipState = (
  retailer: IRetailer | undefined
): keyof typeof IRetailer.InsiderMembershipState => {
  return (
    retailer?.insider_membership_state ??
    IRetailer.InsiderMembershipState.NOT_ELIGIBLE
  );
};

export const isInsider = (retailer: IRetailer | undefined): boolean => {
  return (
    insiderMembershipState(retailer) === IRetailer.InsiderMembershipState.ACTIVE
  );
};
