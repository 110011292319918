"use client";

import { Core } from "@faire/design-tokens";
import { Color, ColorOrKey } from "@faire/web--source/slate/Color";
import {
  highVisSlateComponent,
  HIGH_VIS_FILTER,
} from "@faire/web--source/slate/utils/highVisSlateComponent";
import styled, { css } from "styled-components";

interface IVerticalLineProps {
  $margin?: string;
  $stroke?: string;
  $color?: ColorOrKey;
  $height?: string;
  $skipMargin?: boolean;
}

/**
 * @trackcomponent
 */
export const VerticalLine = styled.div<IVerticalLineProps>`
  height: ${(props) => props.$height ?? "100%"};
  ${(props) => {
    return props.$skipMargin
      ? ""
      : css`
          margin: ${props.$margin ?? "0"};
        `;
  }};
  ${(props) => {
    return css`
      width: ${props.$stroke ?? "1px"};
    `;
  }};
  background: ${({ $color }) =>
    // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
    $color ? (Color[$color] ?? $color) : Core.surface.subdued};
  flex-shrink: 0;
  ${highVisSlateComponent(HIGH_VIS_FILTER.LAYOUT)}
`;
