import { IAppPromotionBannerLinks } from "@faire/web-api--source/indigofair/data/IAppPromotionBannerLinks";
import { APP_PROMOTION_BANNER_LINKS } from "@faire/web-api--source/indigofair/settings/APP_PROMOTION_BANNER_LINKS";

import { useSetting } from "./__internal__/useSetting";

export const useSettingAppPromotionBannerLinks =
  (): IAppPromotionBannerLinks => {
    return useSetting(
      APP_PROMOTION_BANNER_LINKS,
      IAppPromotionBannerLinks.build()
    );
  };
