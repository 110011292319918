import { request } from '../../..';
import { IGetEmailPreferencesResponse } from '../../../com/faire/email/preferences/IGetEmailPreferencesResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../types';
import qs from 'query-string';

export type QueryParameters = {
  outgoing_email: string | undefined;
  requested_email_address_identifier: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/email/preferences${queryString}`;
};

export const build = (
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/email/preferences';

/**
 * Product Area: RETAILER_MARKETING
 */
export function execute(
  queryParams?: QueryParameters
): Promise<IGetEmailPreferencesResponse>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetEmailPreferencesResponse>>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IGetEmailPreferencesResponse>;
export function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  IGetEmailPreferencesResponse | SuccessResponse<IGetEmailPreferencesResponse>
> {
  const { route, ...requestOptions } = build(queryParams, options);
  return request<IGetEmailPreferencesResponse>(requestOptions);
}
execute[PATH] = 'api/email/preferences/get';
export default execute;
