import { HEADER_CLIENT_RELOAD } from "@faire/web--source/common/consts/HEADER_CLIENT_RELOAD";
import { forceReload } from "@faire/web--source/common/forceReload";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import postUserEvent from "@faire/web-api--source/api/event/post";
import { IUserEvent } from "@faire/web-api--source/indigofair/data/IUserEvent";
import {
  ResponseInterceptor,
  Response as WebApiResponse,
} from "@faire/web-api--source/types";
import { RequestOptions } from "https";

const FORCE_VALUE = "force";

export const clientReloadInterceptor: ResponseInterceptor = <T = unknown>(
  _request: RequestOptions,
  response: WebApiResponse<T>
): WebApiResponse<T> => {
  const xIfClientReload =
    response.headers?.[HEADER_CLIENT_RELOAD] ||
    response.headers?.[HEADER_CLIENT_RELOAD.toLowerCase()];
  if (xIfClientReload === FORCE_VALUE) {
    postUserEvent(
      "www",
      IUserEvent.build({
        path: `/force-reload/from-${getGlobalProperty(
          "releaseVersion"
        )}/to-${xIfClientReload}`,
      })
    );
    forceReload();
  }
  return response;
};
