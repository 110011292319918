import { getThreeLetterCountryCode } from "@faire/web--source/common/consts/TWO_TO_THREE_LETTER_COUNTRY_ISO_CODE";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";
import invert from "lodash/invert";

import { THREE_TO_TWO_LETTER_LANGUAGE_CODE } from "./THREE_TO_TWO_LETTER_LANGUAGE_CODE";

export const TWO_TO_THREE_LETTER_LANGUAGE_CODE = invert(
  THREE_TO_TWO_LETTER_LANGUAGE_CODE
);

export const localeStringToLocaleKey = (localeString: string): ILocaleKey => {
  const [languageTwo, countryTwo] = localeString
    .toUpperCase()
    .split(/[^a-zA-Z]/);
  const language = TWO_TO_THREE_LETTER_LANGUAGE_CODE[
    languageTwo ?? ""
  ] as Language;
  const country = countryTwo
    ? getThreeLetterCountryCode(countryTwo)
    : undefined;
  return ILocaleKey.build({
    country,
    language,
  });
};
