import { logError } from "@faire/web--source/common/logging";

export function setIntervalIfPossible(
  handler: TimerHandler,
  timeout?: number,
  // eslint-disable-next-line
  ...argumentsArray: any[]
): number | undefined {
  // eslint-disable-next-line @faire/ssr-no-restricted-globals
  if (typeof window === "undefined") {
    logError(new Error("Next.js doesn't allow setInterval"));
    return undefined;
  } else {
    // eslint-disable-next-line @faire/ssr-no-restricted-globals
    return window.setInterval(handler, timeout, argumentsArray);
  }
}
