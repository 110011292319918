import { v4 as uuidv4 } from "uuid";

export const uuid = () => {
  try {
    return uuidv4();
  } catch (e) {
    // Time-based UUID as fallback. In some contexts, such as vscode, crypto.getRandomValues() is not supported.
    const u =
      Date.now().toString(16) + Math.random().toString(16) + "0".repeat(16);
    return [
      u.substr(0, 8),
      u.substr(8, 4),
      "4000-8" + u.substr(13, 3),
      u.substr(16, 12),
    ].join("-");
  }
};
