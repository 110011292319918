"use client";

import { Core } from "@faire/design-tokens";
import { useStrictLocalization } from "@faire/web--source/common/localization";
import * as React from "react";
import styled, { keyframes } from "styled-components";

import {
  highVisSlateComponent,
  HIGH_VIS_FILTER,
} from "../utils/highVisSlateComponent";

const SpinnerSizes = {
  small: 16,
  medium: 24,
  large: 48,
};

export interface IProps {
  /** Class name applied to the svg tag */
  className?: string;
  /**
   * Size of the loading spinner
   * @default "large"
   */
  size?: keyof typeof SpinnerSizes;
  /**
   * Color of the spinner
   * @default Core.text.default
   * */
  color?: string;
  /** data-test-id applied to the svg tag */
  "data-test-id"?: string;
}

/**
 * @trackcomponent
 */
export const LoadingSpinner: React.FC<IProps> = ({
  color = Core.icon.default,
  size = "large",
  className,
  ["data-test-id"]: dataTestId,
}) => {
  const { strictLocalize } = useStrictLocalization();
  const spinnerSize = SpinnerSizes[size];
  return (
    <SVG
      role="status"
      aria-label={strictLocalize({
        defaultMessage: "Loading",
        description: {
          text: "Message for screen readers when buttons just show a loading spinner with no text",
        },
      })}
      viewBox="25 25 50 50"
      width={spinnerSize}
      height={spinnerSize}
      className={className}
      data-test-id={dataTestId}
    >
      <Circle
        cx="50"
        cy="50"
        r="20"
        stroke={color}
        fill="none"
        strokeWidth={2}
        strokeMiterlimit="10"
      />
    </SVG>
  );
};

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

export const SVG = styled.svg`
  animation: ${rotate} 2s linear infinite;
  transform-origin: center center;
  ${highVisSlateComponent(HIGH_VIS_FILTER.COMPONENT)}
`;

export const Circle = styled.circle`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: ${dash} 1.5s ease-in-out infinite;
  stroke-linecap: round;
`;
