/* eslint-disable @typescript-eslint/ban-types */
import { History } from "history";
import qs from "query-string";

import { proxyQueryParams } from "./__internal__/QueryParamsProxy";
import { QueryParams, QueryParamsOf, RawQueryParams } from "./QueryParams";

export function parseQueryParams<Q extends QueryParamsOf<Q> = {}>(
  search: History.Search
): RawQueryParams<Q>;

export function parseQueryParams<Q extends {} = {}>(
  rawParams: QueryParamsOf<Q>
): QueryParams<Q>;

export function parseQueryParams<Q extends {} = {}>(
  searchOrRawParams: History.Search | QueryParamsOf<Q>
) {
  if (typeof searchOrRawParams === "string") {
    return parseQueryParams(qs.parse(searchOrRawParams) as QueryParamsOf<Q>);
  }
  return proxyQueryParams(searchOrRawParams ?? {});
}
