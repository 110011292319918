import { IRecordClientEventRequest } from "@faire/web-api--source/indigofair/data/IRecordClientEventRequest";
import { IUserEvent } from "@faire/web-api--source/indigofair/data/IUserEvent";

export const BATCH_EVENTS_URL = "api/v2/events";
export const BATCH_PETE_EVENTS_URL = "api/client-events";
export const instanceOfPeteEvent = (
  object: object
): object is IRecordClientEventRequest =>
  "event_key" in object &&
  "event_id" in object &&
  "client_created_at" in object;

export const instanceOfDeprecatedEvent = (
  object: object
): object is IUserEvent => "path" in object && "client_created_at" in object;
