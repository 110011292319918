"use client";

import styled from "styled-components";

interface IFormField {
  $name: string;
}

/**
 * @trackcomponent
 */
export const FormField = styled.div<IFormField>`
  grid-area: ${(props) => props.$name};
`;
