import { SettingConst } from '../../types';

/**
 * The site key for an hCaptcha integration. This value is not secret.
 */
export const HCAPTCHA_SITE_KEY = 'HCAPTCHA_SITE_KEY' as SettingConst<
  'HCAPTCHA_SITE_KEY',
  string,
  'RETAILER'
>;
