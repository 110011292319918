import { RequestOptions } from "@faire/web-api--source/types";
import {
  ResponseInterceptor,
  Response as WebApiResponse,
} from "@faire/web-api--source/types";

import {
  FAIRE_SPAN_ID_TRACKING,
  TelemetryManager,
} from "../../opentelemetry/TelemetryManager";
import { getSettingEnableDetailedSessionTracing } from "../../settings/getSettingEnableDetailedSessionTracing";

export const opentelemetryResponseInterceptor: ResponseInterceptor = <
  T = unknown
>(
  request: RequestOptions,
  response: WebApiResponse<T>
): WebApiResponse<T> => {
  const spanId = request?.headers[FAIRE_SPAN_ID_TRACKING];

  if (!getSettingEnableDetailedSessionTracing() || !spanId) {
    return response;
  }

  // Add Status code to span when ending the span
  TelemetryManager.get().endManagedSpan(spanId, {
    "http.status_code": response.status ?? "unknown",
  });
  return response;
};
