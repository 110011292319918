export function setTimeoutIfPossible(
  handler: TimerHandler,
  timeout?: number,
  // eslint-disable-next-line
  ...argumentsArray: any[]
): number | undefined {
  // eslint-disable-next-line @faire/ssr-no-restricted-globals
  if (typeof window === "undefined") {
    throw new Error("Next.js doesn't allow setTimeout");
  } else {
    // eslint-disable-next-line @faire/ssr-no-restricted-globals
    return window.setTimeout(handler, timeout, argumentsArray);
  }
}
