"use client";

import React from "react";

const getImageSrc = (
  mobileSmallest: boolean | undefined,
  mobileSmall: boolean | undefined,
  simple: boolean | undefined,
  inverse: boolean | undefined
) => {
  if (mobileSmallest) {
    return "https://cdn.faire.com/static/logo-320px.svg";
  } else if (mobileSmall) {
    return "https://cdn.faire.com/static/logo-360px.svg";
  } else if (simple) {
    return "https://cdn.faire.com/static/simpleLogo.svg";
  } else if (inverse) {
    return "https://cdn.faire.com/static/logo-inverse.svg";
  } else {
    return "https://cdn.faire.com/static/logo.svg";
  }
};

export const FaireLogo: React.FC<FaireLogo.IProps> = ({
  simple,
  className,
  inverse,
  size,
  mobileSmallest,
  mobileSmall,
}) => {
  const src = getImageSrc(mobileSmallest, mobileSmall, simple, inverse);

  return <img width={size} className={className} src={src} alt="Faire Logo" />;
};

export namespace FaireLogo {
  export interface IProps {
    className?: string;
    inverse?: boolean;
    simple?: boolean;
    size?: number;
    mobileSmallest?: boolean;
    mobileSmall?: boolean;
  }
}
