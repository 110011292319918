import { Core } from "@faire/design-tokens";
import { ColorOrKey } from "@faire/web--source/slate/Color";
import React, { CSSProperties } from "react";

interface IHorizontalLineProps
  extends Omit<React.HTMLAttributes<HTMLHRElement>, "as" | "color"> {
  ref?: React.Ref<HTMLHRElement>;
  color?: ColorOrKey;
  margin?: string;
  stroke?: string;
  width?: string;
}

/**
 * @trackcomponent
 */
export const HorizontalLine: React.FC<IHorizontalLineProps> = ({
  ref,
  color,
  margin,
  stroke,
  width,
  style: styleProp,
  ...rest
}) => {
  const styles: CSSProperties = {
    borderBlockEnd: 0,
    borderInline: 0,
    ...(color != null
      ? { backgroundColor: color, borderColor: color, borderStyle: "solid" }
      : {}),
    inlineSize: width ?? "100%",
    margin: margin ?? 0,
    blockSize: stroke ?? 1,
    borderBlockStart:
      stroke != null || color != null
        ? `${stroke || "1px"} solid ${color ? color : Core.border.muted}`
        : `1px solid ${Core.border.muted}`,
  };
  return (
    <hr
      {...rest}
      ref={ref}
      style={{
        ...styles,
        ...styleProp,
      }}
    />
  );
};
