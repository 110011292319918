import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { getSettingFactory } from "@faire/web--source/common/SerializedSetting";

const {
  getSettingValueWithoutAssignment,
  getSettingValueAndAssign,
  assignSetting,
} = getSettingFactory(() => getGlobalProperty("settingsValues", {}));

export {
  assignSetting,
  getSettingValueAndAssign,
  getSettingValueWithoutAssignment,
};
