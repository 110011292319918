import NativeNextLink from "next/link";
import React from "react";

import type { ILinkComponentProps } from "./routing/RoutingContext";

export const NextLink: React.FC<ILinkComponentProps> = (
  props: ILinkComponentProps
) => {
  const { innerRef, to, ...otherProps } = props;
  return <NativeNextLink ref={innerRef} href={to} {...otherProps} />;
};
