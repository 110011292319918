import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { getWindowOrThrow } from "@faire/web--source/common/globals/getWindow";
import { trackFrontendApisPrefetchUnusedView } from "@faire/web-api--source/events/frontendApis/view/prefetchUnused";
import {
  NormalizedRequestHandler,
  RequestInterceptor,
  RequestOptions,
  Response as WebApiResponse,
} from "@faire/web-api--source/types";
import clone from "lodash/clone";

import { delay } from "../delay";
import { logError, logWarning } from "../logging";

import { isSameRequest } from "./__internal__/isSameRequest";

export interface PrefetchRequestOptions extends RequestOptions {
  /**
   * Route of the outgoing prefetch. Needed in order to normalize the path.
   */
  route: string;
  /**
   * Performarnce marker to be used before prefetch data fetching starts.
   */
  beforePrefetchStartPerformanceMarker?: string;
  /**
   * Performarnce marker to be used after prefetch data fetching is done.
   */
  afterPrefetchDonePerformanceMarker?: string;
}

export interface IPrefetch {
  sentAtMillis: number;
  request: PrefetchRequestOptions;
  response?: Promise<WebApiResponse<unknown>>;
}

export const PREFETCH_UNUSED_TIMEOUT_MILLIS = 10000;

/**
 * Invoke the given request early, with the expectation that it'll be leveraged in the near future
 * by an outgoing request with identical options.
 *
 * Note that request interceptors are applied to the given config.
 */
export const prefetch = async (requestOptions: PrefetchRequestOptions) => {
  const {
    route,
    beforePrefetchStartPerformanceMarker,
    afterPrefetchDonePerformanceMarker,
    ...otherConfig
  } = requestOptions;
  let config = clone({
    ...otherConfig,
  });
  const interceptors = getGlobalProperty<RequestInterceptor[]>(
    "faire.api.requestInterceptors"
  );
  if (interceptors) {
    for (const interceptor of interceptors) {
      config = await interceptor(config);
    }
  }

  const prefetches = getPrefetches();
  if (prefetches.find((other) => isSameRequest(other.request, config))) {
    return;
  }

  const handler =
    getGlobalProperty<NormalizedRequestHandler>("faire.api.handler");
  if (!handler) {
    logError("Prefetch attempted when no requestHandler exists");
    return;
  }

  const prefetch: IPrefetch = {
    sentAtMillis: Date.now(),
    request: {
      ...config,
      route,
      beforePrefetchStartPerformanceMarker,
      afterPrefetchDonePerformanceMarker,
    },
    response: handler({
      ...config,
      prefetch: true,
    } as RequestOptions),
  };
  prefetches.push(prefetch);

  ensurePrefetchConsumedWithin(prefetch, PREFETCH_UNUSED_TIMEOUT_MILLIS);
};

export interface IGlobals {
  faire?: {
    api?: {
      prefetches?: IPrefetch[];
    };
  };
}

const getPrefetches = () => {
  const globalScope = getWindowOrThrow() as unknown as IGlobals;
  const faire = globalScope.faire || (globalScope.faire = {});
  const api = faire.api || (faire.api = {});
  return api.prefetches || (api.prefetches = []);
};

/**
 * Sets a timeout for any pending prefetches
 */
export const ensurePrefetchesConsumedWithin = (timeoutMillis: number) => {
  for (const prefetch of getPrefetches()) {
    ensurePrefetchConsumedWithin(prefetch, timeoutMillis);
  }
};

const ensurePrefetchConsumedWithin = (
  prefetch: IPrefetch,
  timeoutMillis: number
) => {
  let timeout = prefetch.response?.then(() => delay(timeoutMillis));
  if (!timeout) {
    const limit = Math.max(
      prefetch.sentAtMillis + timeoutMillis - Date.now(),
      0
    );
    timeout = delay(limit);
  }
  timeout.then(() => ensurePrefetchConsumed(prefetch));
};

const ensurePrefetchConsumed = (prefetch: IPrefetch) => {
  const samesiesIndex = getPrefetches().findIndex((other) =>
    isSameRequest(other.request, prefetch.request)
  );
  if (samesiesIndex >= 0) {
    // Remove from prefetches
    const samesies = getPrefetches().splice(samesiesIndex, 1);
    if (samesies) {
      logWarning(
        `Prefetch ${prefetch.request.method} ${prefetch.request.route} was not used within timeout`,
        { data: { prefetch } }
      );
      trackFrontendApisPrefetchUnusedView(prefetch.request.route);
    }
  }
};
