import {
  isNotUndefined,
  protoKeys,
} from "@faire/web--source/common/typescriptUtils";
import { isLocal } from "@faire/web--source/common/user-agent/isLocal";
import { RequestOptions } from "@faire/web-api--source/types";
import differenceWith from "lodash/differenceWith";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";
import isObject from "lodash/isObject";
import omitBy from "lodash/omitBy";
import pickBy from "lodash/pickBy";
import toPairs from "lodash/toPairs";

type PropsToIgnore = Extract<
  keyof RequestOptions & { prefetch?: boolean },
  | "darkRead"
  | "darkReadOverrideLocaleHeader"
  | "ignoreDuringPrerender"
  | "ignoreForRobots"
  | "leveragePreloadFetch"
  | "prefetch"
  | "rawResponse"
  | "signal"
  // FIXME: Header matching doesn't work for /search yet
  | "headers"
>;

type PropsToCompare = {
  [K in keyof Omit<RequestOptions, PropsToIgnore>]: true;
};

const PROPS_TO_COMPARE: Set<string> = (() => {
  const allProps: PropsToCompare = {
    baseUrl: true,
    method: true,
    url: true,
    data: true,
    params: true,
    responseType: true,
    subdomain: true,
    withCredentials: true,
  };
  return new Set(protoKeys(allProps));
})();

const shouldCompareProp = (prop: string, val: unknown) =>
  PROPS_TO_COMPARE.has(prop) && isNotUndefined(val);

const isDeepEqualIgnoringUndefined = (
  a: unknown,
  b: unknown
): boolean | undefined => {
  if (!isObject(a) || !isObject(b) || isArray(a) || isArray(b)) {
    return undefined;
  } else if (
    !includes(a as Record<string, unknown>, undefined) &&
    !includes(b as Record<string, unknown>, undefined)
  ) {
    return undefined;
  }

  // Call recursively, after filtering all undefined properties
  return isEqualWith(
    omitBy(a, (value) => typeof value === "undefined"),
    omitBy(b, (value) => typeof value === "undefined"),
    isDeepEqualIgnoringUndefined
  );
};

export const isSameRequest = (a: RequestOptions, b: RequestOptions) => {
  // Efficient early bail.
  if (a.url !== b.url) {
    return false;
  }

  return isEqualWith(
    pickBy(a, (v, k) => shouldCompareProp(k, v)),
    pickBy(b, (v, k) => shouldCompareProp(k, v)),
    isDeepEqualIgnoringUndefined
  );
};

export const emitDifferences = (
  request: RequestOptions,
  prefetchRequest: RequestOptions
) => {
  if (!isLocal()) {
    return;
  }

  const differences = differenceWith(
    toPairs(prefetchRequest).filter(([k, v]) => shouldCompareProp(k, v)),
    toPairs(request).filter(([k, v]) => shouldCompareProp(k, v)),
    isEqual
  );
  const otherDifferences = differences.filter((d) => d[0] !== "data");
  if (otherDifferences.length) {
    // eslint-disable-next-line no-console
    console.log(`RequestOptions differences`, otherDifferences);
  }

  const dataDifferences = differences.find((d) => d[0] === "data");
  if (dataDifferences) {
    // eslint-disable-next-line no-console
    console.log(
      `Request data differences`,
      differenceWith(
        toPairs(prefetchRequest.data),
        toPairs(request.data),
        isEqual
      )
    );
  }
};
