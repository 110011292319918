import { Core } from "@faire/design-tokens";
import { TypographyProps as ITypography } from "@faire/web--source/slate/Typography/Types";

import type { Color, ColorOrKey } from "../Color";

import { DefaultTheme } from "./DefaultTheme";
import { eventsThemeColors } from "./eventsThemeColors";

/**
 * Styling of the checkboxes, if it deviates from the defaults.
 * Default behaviour is to use the primary color to fill the ticked box
 * for a primary checkbox, and to have a secondary checkboxes use a white fill,
 * with the checkmark icon being the primary color.
 */
export interface ICheckboxes {
  primary: ICheckbox;
  secondary: ICheckbox;
  disabled: ICheckbox;
}

export interface ICheckbox {
  /**
   * Color of the checkmark icon, when the box is selected.
   */
  icon?: ColorOrKey;
  /**
   * Color of the checkbox fill, when the box is selected.
   */
  fill: ColorOrKey;
  /**
   * Optional border color to apply to the checkbox.
   */
  border: ColorOrKey;
  /**
   * Optional border color to apply to the checkbox when selected.
   */
  borderActive: Color;
  /**
   * Optional typography styles for the checkbox's label.
   */
  base: ITypography;
  /**
   * Optional typography styles to apply to the checkbox's label when selected.
   */
  checked: ITypography;
  /**
   * Optional typography styles to apply to the checkbox's label when hovering.
   */
  hover: ITypography;
}

export const checkboxEventsTheme: ICheckboxes = {
  ...DefaultTheme.checkboxes,
  primary: {
    ...DefaultTheme.checkboxes.primary,
    icon: Core.icon.inverse,
    fill: eventsThemeColors.faireMarket.primaryDark,
    border: eventsThemeColors.faireMarket.primaryDark,
    borderActive: Core.border.default,
    base: { variant: "paragraphSansRegular", color: Core.text.subdued },
    checked: {
      variant: "paragraphSansRegular",
      color: eventsThemeColors.faireMarket.primaryLight,
    },
    hover: {
      variant: "paragraphSansRegular",
      color: eventsThemeColors.faireMarket.primaryDark,
    },
  },
};
