import { SettingConst } from '../../types';

/**
 * Seasonal warehouse sale life switch
 */
export const SEASONAL_WAREHOUSE_SALE_ENABLED =
  'SEASONAL_WAREHOUSE_SALE_ENABLED' as SettingConst<
    'SEASONAL_WAREHOUSE_SALE_ENABLED',
    boolean,
    'RETAILER'
  >;
