"use client";

import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import React from "react";

import { eventsThemeColors } from "../Theme/eventsThemeColors";

import { createSVGIcon } from "./util";

/**
 * @trackcomponent
 */
export const FaireFashionWeek = createSVGIcon(
  <path
    d="M17.534 9c-6.4 1.178-7.356 2.133-8.534 8.533C7.822 11.133 6.866 10.178.467 9 6.867 7.822 7.822 6.866 9 .467c1.178 6.4 2.133 7.355 8.534 8.533z"
    fill={
      eventsThemeColors[PromotionalEventType.FAIRE_FASHION_WEEK_V3].badgeIcon
    }
  />,
  "FaireFashionWeek",
  {
    viewBox: "0 0 17.5 17.5",
    titleAccess: "FaireFashionWeek",
  }
);
