"use client";

import { shouldRetryEndpoint } from "@faire/web--source/common/shouldRetryEndpoint";
import {
  QueryClientProvider as ReactQueryClientProvider,
  QueryClient,
  defaultShouldDehydrateQuery,
} from "@tanstack/react-query";
import React, { useState } from "react";

export const QueryClientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: shouldRetryEndpoint,
          },
          dehydrate: {
            shouldDehydrateQuery: (query) =>
              defaultShouldDehydrateQuery(query) ||
              query.state.status === "pending",
          },
        },
      })
  );

  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  );
};
