import {
  enumValue,
  isNotUndefined,
} from "@faire/web--source/common/typescriptUtils";
import { ProductArea } from "@faire/web-api--source/faire/logging/ProductArea";
import { ISettingEntry } from "@faire/web-api--source/indigofair/settings/ISettingEntry";

import { IFrontendSetting } from "./IFrontendSetting";
import {
  FrontendSettingName,
  ISettingSchema,
  NullableSettingType,
} from "./ISettingSchema";
import { notifyAfter } from "./notifyAfter";

export function setting<
  T extends NullableSettingType,
  S extends FrontendSettingName,
  C extends keyof typeof ISettingEntry.SerializeToClient
>(setting: ISettingSchema<T, S, C>): IFrontendSetting<T, S, C> {
  const serializeToPages = (
    Array.isArray(setting.client) ? setting.client : [setting.client]
  )
    .map((page) => enumValue(ISettingEntry.SerializeToClient, page))
    .filter(isNotUndefined) as C[];

  return {
    name: setting.name,
    defaultValue: setting.defaultValue,
    valueValidation: setting.valueValidation,
    entryType: setting.type,
    identifierType: setting.identifier,
    testable: setting.testable ?? false,
    serializeToPages,
    notifyAfter: notifyAfter(setting.notifyAfter),
    monitorClientPerformance: setting.monitorClientPerformance
      ? serializeToPages
      : [],
    productArea:
      enumValue(ProductArea, setting.productArea) ??
      ProductArea.PRODUCT_AREA_UNKNOWN,
  };
}
