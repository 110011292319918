import { IAppPromotionBannerLinks } from '../../indigofair/data/IAppPromotionBannerLinks';
import { SettingConst } from '../../types';

/**
 * TBD
 */
export const APP_PROMOTION_BANNER_LINKS =
  'APP_PROMOTION_BANNER_LINKS' as SettingConst<
    'APP_PROMOTION_BANNER_LINKS',
    IAppPromotionBannerLinks,
    'VISITOR_WEB' | 'RETAILER' | 'RETAILER_HEAD_TAGS'
  >;
