import { BRAND_PORTAL_OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_240422 } from "@faire/web-api--source/indigofair/settings/BRAND_PORTAL_OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_240422";

import {
  assignSetting,
  getSettingValueWithoutAssignment,
} from "./__internal__/getSetting";

export const getSettingOptimizeImageUrlForCacheHitBrand = (): boolean =>
  getSettingValueWithoutAssignment(
    BRAND_PORTAL_OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_240422,
    false
  );

export const assignOptimizeImageUrlForCacheHitBrand = (): Promise<void> =>
  assignSetting(BRAND_PORTAL_OPTIMIZE_IMAGE_URL_FOR_IMPROVED_CACHE_HIT_240422);
