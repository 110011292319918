import { ISettingEntry } from "@faire/web-api--source/indigofair/settings/ISettingEntry";

import { IFrontendSetting } from "./IFrontendSetting";
import { FrontendSettingName, ISettingSchema } from "./ISettingSchema";
import { setting } from "./setting";

export function featureToggle<
  S extends FrontendSettingName,
  C extends keyof typeof ISettingEntry.SerializeToClient
>(
  schema: Omit<ISettingSchema<boolean, S, C>, "type" | "defaultValue"> & {
    defaultValue?: boolean;
  }
): IFrontendSetting<boolean, S, C> {
  return setting({
    type: ISettingEntry.SettingType.FEATURE_TOGGLE,
    defaultValue: schema.defaultValue ?? false,
    ...schema,
  });
}
