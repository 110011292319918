import { COOKIE_CURRENCY_OVERRIDE } from "@faire/web--source/common/consts/COOKIE_CURRENCY_OVERRIDE";
import { removeCookie, setCookies } from "@faire/web--source/common/cookies";
import { CurrencyStore } from "@faire/web--source/common/currency/CurrencyStore";
import { getLocation } from "@faire/web--source/common/globals/getLocation";

export type Currency = "USD" | "CAD" | "GBP" | "EUR" | "AUD";

/**
 * @deprecated Use CurrencyStore.get().effectiveCurrency
 */
const getEffectiveCurrency = () => CurrencyStore.get().effectiveCurrency;

/**
 * @deprecated use CurrencyStore.get().setCurrency
 */
const updateCurrencyConfiguration = (currency?: Currency) => {
  CurrencyStore.get().setCurrency(currency);
};

/**
 * Sets the retailer's explicitly selected currency and reloads the page.
 * @param currency the user's explicit currency selection
 */
export const selectCurrency = (currency?: Currency) => {
  currency
    ? setCookies(COOKIE_CURRENCY_OVERRIDE, currency)
    : removeCookie(COOKIE_CURRENCY_OVERRIDE);
  updateCurrencyConfiguration(currency);
  CurrencyStore.get().configuration.selectedCurrency = getEffectiveCurrency();
  getLocation()?.reload();
};
