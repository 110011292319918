import { useRef } from "react";

/**
 * Use this hook if you wish to `useRef` with a value that is
 * lazily-initialized. The provided callback will be invoked to initialize the
 * ref value's `current` the first time it's called, and will not be invoked on
 * subsequent calls to the hook. This is useful if the initial value for a
 * `useRef` call is a complex structure which you don't want to be constructing
 * and then discarding every time the component re-renders.
 *
 * @example
 *
 * ```
 * import { useRefFrom } from "@faire/web--source/common/hooks/useRefFrom";
 *
 * import * as React from "react";
 *
 * const HelloUser: React.FC = () => {
 *   // The contents of `current` are only populated once with a new
 *   // view-state, but a view-state is still constructed every time this
 *   // line of code is run. It's simply constructed and then discarded on
 *   // every re-render after the first.
 *   const inefficientViewState = useRef(new ComplicatedViewState()).current;
 *
 *   // The contents of `current` are only populated once with a new
 *   // view-state, and on subsequent calls, no new view-states are
 *   // constructed simply to be discarded.
 *   const efficientViewState = useRefFrom(() => new ComplicatedViewState()).current;
 *
 *   return <Typography>{efficientViewState.someField}</Typography>
 * }
 * ```
 *
 * @param constructorCallback A callback which constructs the initial value for this ref
 * @returns An object like that returned by `useRef`, but populated with the return value of the constructor callback
 *
 * @note Only the initial return value of `constructorCallback` is used. Any changes to the callback will be ignored.
 */
export const useRefFrom = <T extends NonNullable<any>>(
  constructorCallback: () => T
): React.MutableRefObject<T> => {
  const ref = useRef<T | null>(null);
  if (ref.current === null) {
    ref.current = constructorCallback();
  }
  return ref as React.MutableRefObject<T>;
};
