import { getTwoLetterCountryCode } from "@faire/web--source/common/consts/THREE_TO_TWO_LETTER_COUNTRY_ISO_CODE";
import { getTwoLetterLanguageCode } from "@faire/web--source/common/consts/THREE_TO_TWO_LETTER_LANGUAGE_CODE";
import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";

import { getPrimaryCountriesIsoToCountryName } from "../consts/PRIMARY_COUNTRIES_ISO_TO_COUNTRY_NAME";
import { getFullLanguageName } from "../consts/THREE_TO_FULL_LANGUAGE_NAME";
import { isNotUndefined } from "../typescriptUtils";

import { StrictLocalizeFunction } from "./localize";

interface IOption {
  label: string;
  value: string;
}

const THREE_LETTER_PRIMARY_COUNTRY_CODES = (
  strictLocalize: StrictLocalizeFunction
) => ({
  ...getPrimaryCountriesIsoToCountryName(strictLocalize),
  [ISO3166Alpha3.DEU]: "Deutschland",
  [ISO3166Alpha3.ITA]: "Italia",
  [ISO3166Alpha3.ESP]: "España",
  [ISO3166Alpha3.PRT]: "Portugal",
  [ISO3166Alpha3.USA]: "United States",
});

const defaultCountryLanguage = {
  [Language.DEU]: ISO3166Alpha3.DEU,
  [Language.ITA]: ISO3166Alpha3.ITA,
  [Language.SPA]: ISO3166Alpha3.ESP,
  [Language.POR]: ISO3166Alpha3.PRT,
  [Language.ENG]: ISO3166Alpha3.USA,
  [Language.FRA]: ISO3166Alpha3.FRA,
  [Language.NLD]: ISO3166Alpha3.NLD,
  [Language.SWE]: ISO3166Alpha3.SWE,
  [Language.DAN]: ISO3166Alpha3.DNK,
};

export const convertLocaleToCountryLocaleString = (
  localeKey: ILocaleKey
): string | undefined => {
  const { language, country: countryCode } = localeKey;
  if (!language) {
    return undefined;
  }

  // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
  if (countryCode && defaultCountryLanguage[language] !== countryCode) {
    return (
      getTwoLetterLanguageCode(language)?.toLowerCase() +
      "-" +
      getTwoLetterCountryCode(countryCode)?.toLowerCase()
    );
  }

  return getTwoLetterLanguageCode(language)?.toLowerCase();
};

export const languageAndCountryText = (
  language: keyof typeof Language,
  strictLocalize: StrictLocalizeFunction,
  countryCode?: string
): string =>
  `${getFullLanguageName(language)} (${
    // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
    THREE_LETTER_PRIMARY_COUNTRY_CODES(strictLocalize)[
      countryCode ?? ISO3166Alpha3.USA
    ]
  })`;

export const languageWithCountryOptions = (
  availableLocale: ILocaleKey[],
  strictLocalize: StrictLocalizeFunction
): IOption[] =>
  availableLocale
    .map((localeKey) => {
      const { language, country: countryCode } = localeKey;
      if (language === undefined) {
        return undefined;
      }

      const convertedLocale = convertLocaleToCountryLocaleString(localeKey);
      if (convertedLocale === undefined) {
        return undefined;
      }
      return {
        label: languageAndCountryText(language, strictLocalize, countryCode),
        value: convertedLocale,
      };
    })
    .filter(isNotUndefined);
