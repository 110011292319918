import { observable } from "mobx";

class Clock {
  @observable
  private diff: number = 0;

  now = (): number => Date.now() + this.diff;

  date = (): Date => new Date(this.now());

  setDay = (time: Date) => {
    const now = new Date(Date.now());
    now.setHours(0, 0, 0, 0);

    time = new Date(time);
    time.setHours(0, 0, 0, 0);

    this.diff = time.valueOf() - now.valueOf();
  };

  reset = () => {
    this.diff = 0;
  };
}

export const clock = new Clock();
