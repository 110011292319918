"use client";

import { getSpacing } from "@faire/web--source/slate/spacing";
import { SpacerValueOrKey } from "@faire/web--source/slate/spacing/getSpacing";
import React, { forwardRef } from "react";
import styled from "styled-components";

import { buildResponsiveCss } from "../Theme/ResponsiveCss";
import { ResponsiveProp } from "../Theme/ResponsiveProp";
import {
  highVisSlateComponent,
  HIGH_VIS_FILTER,
} from "../utils/highVisSlateComponent";

interface IGridProps {
  /**
   * Defaults to 2 columns.
   */
  columns?: ResponsiveProp<number>;
  /**
   * Defaults to "1x" (8px).
   */
  gap?: ResponsiveProp<SpacerValueOrKey>;
  /**
   * Will fallback to `gap` property if not provided.
   */
  rowGap?: ResponsiveProp<SpacerValueOrKey>;
  /**
   * Will fallback to `gap` property if not provided.
   */
  columnGap?: ResponsiveProp<SpacerValueOrKey>;
  /**
   * Function for producing the `grid-template-columns` value for the
   * given number of columns. Defaults to the example.
   *
   * @example ```jsx
   * (columns: number) => `repeat(${columns}, 1fr);`
   * ```
   */
  columnTemplate?: (columns: number) => string;
  children?: React.ReactNode;
}

const defaultColumnTemplate = (columns: number) => `repeat(${columns}, 1fr);`;

/**
 * @trackcomponent
 */

export const Grid = forwardRef<
  HTMLDivElement,
  IGridProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
>(
  (
    { children, columns, gap, rowGap, columnGap, columnTemplate, ...props },
    ref
  ) => {
    return (
      <StyledGrid
        $columns={columns}
        $gap={gap}
        $rowGap={rowGap}
        $columnGap={columnGap}
        $columnTemplate={columnTemplate}
        {...props}
        ref={ref}
      >
        {children}
      </StyledGrid>
    );
  }
);
const StyledGrid = styled.div<{
  $columns?: ResponsiveProp<number>;
  $gap?: ResponsiveProp<SpacerValueOrKey>;
  $rowGap?: ResponsiveProp<SpacerValueOrKey>;
  $columnGap?: ResponsiveProp<SpacerValueOrKey>;
  $columnTemplate?: (columns: number) => string;
}>`
  display: grid;
  ${({ $columns = 2, $columnTemplate = defaultColumnTemplate }) =>
    buildResponsiveCss("grid-template-columns", $columns, $columnTemplate)}
  ${({ $gap = "1x", $rowGap }) =>
    buildResponsiveCss("row-gap", $rowGap ?? $gap, getSpacing)}
  ${({ $gap = "1x", $columnGap }) =>
    buildResponsiveCss("column-gap", $columnGap ?? $gap, getSpacing)}
  overflow: hidden;
  ${highVisSlateComponent(HIGH_VIS_FILTER.LAYOUT)}
`;
