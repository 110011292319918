import type { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";

// https://www23.statcan.gc.ca/imdb/p3VD.pl?Function=getVD&TVD=141329
export const THREE_LETTER_EU_COUNTRY_CODES: {
  [K in Extract<
    keyof typeof ISO3166Alpha3,
    | "AUT"
    | "BEL"
    | "BGR"
    | "CYP"
    | "CZE"
    | "DEU"
    | "DNK"
    | "ESP"
    | "EST"
    | "FIN"
    | "FRA"
    | "GRC"
    | "HRV"
    | "HUN"
    | "IRL"
    | "ITA"
    | "LTU"
    | "LUX"
    | "LVA"
    | "MLT"
    | "NLD"
    | "POL"
    | "PRT"
    | "ROU"
    | "SVK"
    | "SVN"
    | "SWE"
  >]: K;
} = {
  AUT: "AUT",
  BEL: "BEL",
  BGR: "BGR",
  CYP: "CYP",
  CZE: "CZE",
  DEU: "DEU",
  DNK: "DNK",
  ESP: "ESP",
  EST: "EST",
  FIN: "FIN",
  FRA: "FRA",
  GRC: "GRC",
  HRV: "HRV",
  HUN: "HUN",
  IRL: "IRL",
  ITA: "ITA",
  LTU: "LTU",
  LUX: "LUX",
  LVA: "LVA",
  MLT: "MLT",
  NLD: "NLD",
  POL: "POL",
  PRT: "PRT",
  ROU: "ROU",
  SVK: "SVK",
  SVN: "SVN",
  SWE: "SWE",
} as const;
