import { Core } from "@faire/design-tokens";

import { DefaultTheme } from "../Theme";
import { eventsThemeColors } from "../Theme/eventsThemeColors";
import { IPills } from "../Theme/Pill";

export const pillEventsTheme: IPills = {
  ...DefaultTheme.pills,
  filter: {
    primary: {
      color: eventsThemeColors.faireMarket.primaryLight,
      hover: eventsThemeColors.faireMarket.primaryDark,
      contrast: eventsThemeColors.faireMarket.promoText,
    },
    secondary: {
      color: Core.surface.subdued,
      hover: Core.border.muted,
      contrast: Core.surface.default,
    },
    label: { variant: "paragraphSansRegular" },
  },
};
