import type * as CSS from "csstype";
import { css } from "styled-components";

import { responsiveMediaQueries } from "./responsiveMediaQueries";
import { isResponsiveProp, ResponsiveProp } from "./ResponsiveProp";

export const buildResponsiveCss = <T>(
  rule: keyof CSS.PropertiesHyphen,
  prop: ResponsiveProp<T>,
  modifierFunction?: (value: T) => string
) => {
  const buildCssRule = (value: T): string =>
    `${rule}: ${modifierFunction?.(value) ?? value};`;

  return buildResponsiveMediaQueries(prop, buildCssRule);
};

export const buildResponsiveMediaQueries = <T>(
  prop: ResponsiveProp<T>,
  template: (value: T) => string
) => {
  const responsiveValues = isResponsiveProp(prop)
    ? prop
    : { mobileAndAbove: prop };

  return css`
    ${template(responsiveValues.mobileAndAbove)}
    ${responsiveValues.tabletAndAbove !== undefined
      ? css`
          ${responsiveMediaQueries.tabletAndAbove} {
            ${template(responsiveValues.tabletAndAbove)}
          }
        `
      : null}
    ${responsiveValues.desktopAndAbove !== undefined
      ? css`
          ${responsiveMediaQueries.desktopAndAbove} {
            ${template(responsiveValues.desktopAndAbove)}
          }
        `
      : null}
    ${responsiveValues.xLargeAndAbove !== undefined
      ? css`
          ${responsiveMediaQueries.xLargeAndAbove} {
            ${template(responsiveValues.xLargeAndAbove)}
          }
        `
      : null}
    ${responsiveValues.xxLargeDesktop !== undefined
      ? css`
          ${responsiveMediaQueries.xxlarge} {
            ${template(responsiveValues.xxLargeDesktop)}
          }
        `
      : null}
  `;
};
