import {
  bookGotham,
  bookSentinel,
  Family as FontFamily,
  lightSentinel,
  mediumBigCaslon,
  mediumGotham,
  mediumSentinel,
  Weight as FontWeight,
} from "./utils";

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export const Weight = FontWeight;

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export type Weight = FontWeight;

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export const Family = FontFamily;

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export type Family = FontFamily;

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export type WeightKey = keyof typeof Weight;

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export type WeightValue = (typeof Weight)[WeightKey];

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export type WeightOrKey = WeightKey | WeightValue;

/** @deprecated use variants from Typography instead */
export const getWeight = (weight: WeightOrKey): WeightValue => {
  if (weight in Weight) {
    return Weight[weight as WeightKey];
  }
  return weight as WeightValue;
};

/** @deprecated Don't use uppercase or capitalize. We are using sentence case instead */
export const TextTransform = {
  capitalize: "capitalize",
  uppercase: "uppercase",
} as const;

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export type TypographyStyleProps = {
  "font-family": string;
  "font-weight": string;
  "font-size": string;
  "line-height": string;
  "letter-spacing": string;
  color?: string;
  "text-align"?: string;
  "text-transform"?: string;
  "font-style"?: string;
  "white-space"?: string;
  "text-overflow"?: string;
  overflow?: string;
};

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
interface IVariant {
  serif?: boolean;
}

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export type TypographyFactory = (props: IVariant) => TypographyStyleProps;

/**
 * Typography definition factory for producing the variants
 * for a given font configuration.
 * @deprecated Use themes instead.
 */
export const Variants: Record<string, TypographyFactory> = {
  h1: (props: IVariant) => ({
    "font-family": props.serif ? mediumBigCaslon : mediumGotham,
    "font-weight": Weight.medium,
    "font-size": "52px",
    "line-height": "64px",
    "letter-spacing": "2px",
  }),
  h2: (props: IVariant) => ({
    "font-family": props.serif ? mediumSentinel : mediumGotham,
    "font-weight": Weight.medium,
    "font-size": "28px",
    "line-height": "40px",
    "letter-spacing": props.serif ? "1.5px" : "1px",
  }),
  h3: (props: IVariant) => ({
    "font-family": props.serif ? bookSentinel : mediumGotham,
    "font-weight": props.serif ? Weight.book : Weight.medium,
    "font-size": "20px",
    "line-height": "32px",
    "letter-spacing": "1px",
  }),
  h4: (props: IVariant) => ({
    "font-family": props.serif ? lightSentinel : mediumGotham,
    "font-weight": props.serif ? Weight.light : Weight.medium,
    "font-size": "16px",
    "line-height": "28px",
    "letter-spacing": props.serif ? "1px" : "0.5px",
  }),
  h5: (props: IVariant) => ({
    "font-family": props.serif ? bookSentinel : bookGotham,
    "font-weight": Weight.book,
    "font-size": "12px",
    "line-height": "20px",
    "letter-spacing": "0.25px",
  }),
  p: (props: IVariant) => ({
    "font-family": props.serif ? bookSentinel : bookGotham,
    "font-weight": Weight.book,
    "font-size": "12px",
    "line-height": "20px",
    "letter-spacing": "0.25px",
  }),
  small: (props: IVariant) => ({
    "font-family": props.serif ? bookSentinel : bookGotham,
    "font-weight": Weight.book,
    "font-size": "10px",
    "line-height": "14px",
    "letter-spacing": "0.25px",
  }),
};

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export const getVariantFamily = (
  variant: keyof typeof Variants,
  serif: boolean
) => {
  switch (variant) {
    case "h1":
      return serif ? Family.bigCaslon : Family.sansSerif;
    default:
      return serif ? Family.serif : Family.sansSerif;
  }
};

const defaultH1Variant = Variants.h1?.({});

const defaultH2Variant = Variants.h2?.({});

const defaultH3Variant = Variants.h3?.({});

const defaultH4Variant = Variants.h4?.({});

const defaultH5Variant = Variants.h5?.({});

const defaultPVariant = Variants.p?.({});

const defaultSmallVariant = Variants.small?.({});

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export const Size = {
  h1: defaultH1Variant?.["font-size"] ?? "",
  h2: defaultH2Variant?.["font-size"] ?? "",
  h3: defaultH3Variant?.["font-size"] ?? "",
  h4: defaultH4Variant?.["font-size"] ?? "",
  h5: defaultH5Variant?.["font-size"] ?? "",
  p: defaultPVariant?.["font-size"] ?? "",
  small: defaultSmallVariant?.["font-size"] ?? "",
} as const;

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export const LineHeight = {
  h1: defaultH1Variant?.["line-height"] ?? "",
  h2: defaultH2Variant?.["line-height"] ?? "",
  h3: defaultH3Variant?.["line-height"] ?? "",
  h4: defaultH4Variant?.["line-height"] ?? "",
  h5: defaultH5Variant?.["line-height"] ?? "",
  p: defaultPVariant?.["line-height"] ?? "",
  small: defaultSmallVariant?.["line-height"] ?? "",
} as const;

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export const LetterSpacing = {
  h1: defaultH1Variant?.["letter-spacing"] ?? "",
  h2: defaultH2Variant?.["letter-spacing"] ?? "",
  h3: defaultH3Variant?.["letter-spacing"] ?? "",
  h4: defaultH4Variant?.["letter-spacing"] ?? "",
  h5: defaultH5Variant?.["letter-spacing"] ?? "",
  p: defaultPVariant?.["letter-spacing"] ?? "",
  small: defaultSmallVariant?.["letter-spacing"] ?? "",
} as const;

/** @deprecated This is for old fonts. Use Alphabet Soup fonts instead */
export default {
  Family,
  Weight,
  LineHeight,
  Size,
  LetterSpacing,
};
