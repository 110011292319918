import { throttleIfPossible } from "@faire/web--source/common/globals/throttleIfPossible";
import { getOverride } from "@faire/web--source/common/PublicSettings";
import { IFrontendSetting } from "@faire/web--source/common/settings/declarations/IFrontendSetting";
import {
  FrontendSettingName,
  NullableSettingType,
} from "@faire/web--source/common/settings/declarations/ISettingSchema";
import {
  AssignmentAccumulator,
  AssignmentCache,
  BATCH_SETTING_THROTTLE_MS,
  BatchAssignSettingOptions,
  sharedAssignSetting,
  unthrottledBatchAssignSetting,
} from "@faire/web--source/common/sharedAssignSetting";
import { sharedGetSetting } from "@faire/web--source/common/sharedGetSetting";
import { useRefFrom } from "@faire/web--source/ui/hooks/useRefFrom";
import { ISettingEntry } from "@faire/web-api--source/indigofair/settings/ISettingEntry";
import { SettingConst } from "@faire/web-api--source/types";
import React, {
  MutableRefObject,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

export type ClientOverlap<
  Client extends keyof typeof ISettingEntry.SerializeToClient,
  T extends keyof typeof ISettingEntry.SerializeToClient
> = Extract<T, Client> extends never ? never : T;

// Create the context at the top level to ensure that the same context is used across the application.
const TopLevelSettingsContext = createContext(undefined);

/**
 * Creates a new typesafe provider and hooks for the settings context associated with a specific Realm, i.e. Brand, Retailer, Admin, etc.
 */
export const createSettingsContextProvider = <
  Client extends keyof typeof ISettingEntry.SerializeToClient
>() => {
  type SettingsContextValue = {
    settings: { [key: string]: unknown };
    assignmentCache: MutableRefObject<AssignmentCache>;
    assignmentAccumulator: MutableRefObject<AssignmentAccumulator>;
  };

  const SettingsContext = TopLevelSettingsContext as unknown as React.Context<
    SettingsContextValue | undefined
  >;

  const SettingsContextProvider = ({
    settings,
    children,
  }: {
    settings: SettingsContextValue["settings"];
    children: ReactNode;
  }) => {
    const assignmentCache = useRefFrom<AssignmentCache>(() => new Set());
    const assignmentAccumulator = useRefFrom<AssignmentAccumulator>(
      () => new Map()
    );

    useEffect(() => {
      return () => {
        if ("flush" in throttledBatchAssignSetting) {
          throttledBatchAssignSetting.flush();
        }
      };
    }, []);

    const contextValue = useMemo(
      () => ({ settings, assignmentCache, assignmentAccumulator }),
      [settings, assignmentCache, assignmentAccumulator]
    );

    return (
      <SettingsContext.Provider value={contextValue}>
        {children}
      </SettingsContext.Provider>
    );
  };

  /**
   * Retrieves the value for the given setting. This hook does *not* provide setting assignment. For assignment, use useAssignSetting.
   * @param setting The setting name to retrieve the value of.
   * @param defaultValue The default value to return for the setting if it is not found.
   * @returns The value of the setting.
   *
   * @example
   * const mySetting = useSetting("MY_SETTING", false);
   * if (mySetting) {
   *  return ...
   * }
   */
  function useSetting<
    V,
    C extends keyof typeof ISettingEntry.SerializeToClient
  >(key: SettingConst<string, V, ClientOverlap<Client, C>>, defaultValue: V): V;

  function useSetting<
    T extends NullableSettingType,
    C extends keyof typeof ISettingEntry.SerializeToClient
  >(
    setting: IFrontendSetting<T, FrontendSettingName, ClientOverlap<Client, C>>
  ): T;

  function useSetting(
    settingOrKey: IFrontendSetting | SettingConst,
    manualDefaultValue?: unknown
  ) {
    const context = useContext(SettingsContext);

    if (!context) {
      throw new Error(
        "useSetting must be used within a SettingsContextProvider"
      );
    }
    const { settings } = context;

    const settingKey =
      typeof settingOrKey === "string" ? settingOrKey : settingOrKey.name;

    // We read this outside of the memo, to ensure any overrides are picked up
    // properly in unit tests.
    const overriddenValue = getOverride(settingKey);

    return useMemo(
      () =>
        sharedGetSetting(
          settingOrKey,
          settings,
          overriddenValue,
          manualDefaultValue
        ),
      [settingOrKey, settings, overriddenValue, manualDefaultValue]
    );
  }

  /**
   * Assigns a given setting.
   * @param setting The setting name to be assigned.
   * @param options The options for the assignment process.
   * @returns A function that can be called in a compoenent to assign the setting.
   *
   * @example
   * const assignMySetting = useAssignSetting("MY_SETTING");
   * useEffect(() => {
   *  assignMySetting();
   * }, [assignMySetting]);
   * @example
   * const assignMySetting = useAssignSetting("MY_SETTING");
   * useEffect(() => {
   *  assignMySetting({ submitImmediately: true });
   * }, [assignMySetting]);
   */
  function useAssignSetting<
    C extends keyof typeof ISettingEntry.SerializeToClient
  >(
    settingOrKey:
      | SettingConst<string, unknown, ClientOverlap<Client, C>>
      | IFrontendSetting<
          NullableSettingType,
          FrontendSettingName,
          ClientOverlap<Client, C>
        >
  ) {
    const context = useContext(SettingsContext);
    if (!context) {
      throw new Error(
        "useAssignSetting must be used within a SettingsContextProvider"
      );
    }
    const { assignmentAccumulator, assignmentCache } = context;

    return useCallback(
      (options?: BatchAssignSettingOptions) => {
        return sharedAssignSetting(
          settingOrKey,
          options,
          assignmentCache.current,
          assignmentAccumulator.current,
          throttledBatchAssignSetting
        );
      },
      [assignmentAccumulator, assignmentCache, settingOrKey]
    );
  }

  return { useSetting, useAssignSetting, SettingsContextProvider };
};

export const throttledBatchAssignSetting = throttleIfPossible(
  unthrottledBatchAssignSetting,
  BATCH_SETTING_THROTTLE_MS,
  {
    leading: false,
  }
);
