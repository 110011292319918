import { request } from '../../../../..';
import { IGetUserMilestonesResponse } from '../../../../../indigofair/data/IGetUserMilestonesResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../../../../types';

export const path = (userToken: string) => {
  return `/api/user/milestones/${userToken}/list`;
};

export const build = (
  userToken: string,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(userToken),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/user/milestones/:user_token/list';

/**
 * Product Area: MILESTONES
 */
export function execute(userToken: string): Promise<IGetUserMilestonesResponse>;
export function execute(
  userToken: string,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetUserMilestonesResponse>>;
export function execute(
  userToken: string,
  options?: EndpointOptions
): Promise<IGetUserMilestonesResponse>;
export function execute(
  userToken: string,
  options?: EndpointOptions
): Promise<
  IGetUserMilestonesResponse | SuccessResponse<IGetUserMilestonesResponse>
> {
  const { route, ...requestOptions } = build(userToken, options);
  return request<IGetUserMilestonesResponse>(requestOptions);
}
execute[PATH] = 'api/user/milestones/userToken/list/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  userToken: string
): Promise<IGetUserMilestonesResponse>;
export function darkRead(
  userToken: string,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetUserMilestonesResponse>>;
export function darkRead(
  userToken: string,
  options?: EndpointOptions
): Promise<IGetUserMilestonesResponse>;
export function darkRead(
  userToken: string,
  options?: EndpointOptions
): Promise<
  IGetUserMilestonesResponse | SuccessResponse<IGetUserMilestonesResponse>
> {
  const { route, ...requestOptions } = build(userToken, options);
  return request<IGetUserMilestonesResponse>(requestOptions);
}
