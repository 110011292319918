"use client";

import React from "react";
import styled, {
  DefaultTheme as StyledComponentsTheme,
} from "styled-components";

import { ColorOrKey } from "../../slate/Color";
import { Color as Colors } from "../../slate/Color";
import { TypographyStyleProps } from "../../slate/Font";
import { themeOrDefault } from "../../slate/Theme";

import { ITypography, TypographyContext, TypographyStyles } from "./Typography";

export const Span = styled.span<{
  $typography: ITypography;
  $propsFilter?: Array<keyof TypographyStyleProps>;
  $theme?: StyledComponentsTheme;
}>`
  ${({ $typography, $propsFilter, theme }) =>
    TypographyStyles($typography, themeOrDefault(theme), $propsFilter)};
`;

export const Weight: React.FC<{
  children: React.ReactNode;
  weight: ITypography["weight"];
  theme?: StyledComponentsTheme;
}> = ({ weight, children, ...props }) => (
  <TypographyContext.Consumer>
    {(value: ITypography) => (
      <Span
        {...props}
        $typography={{ ...(value || {}), weight }}
        $propsFilter={["font-family", "font-weight"]}
      >
        {children}
      </Span>
    )}
  </TypographyContext.Consumer>
);

/**
 * Span that changes the `font-weight` to Faire's `bold` variant.
 */
export const Bold: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
  ...props
}) => (
  <Weight {...props} weight="bold">
    {children}
  </Weight>
);

/**
 * Span that changes the `font-weight` to Faire's `medium` variant.
 */
export const Medium: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
  ...props
}) => (
  <Weight {...props} weight="medium">
    {children}
  </Weight>
);

/**
 * Span that changes the `font-weight` to Faire's `light` variant.
 */
export const Light: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
  ...props
}) => (
  <Weight {...props} weight="light">
    {children}
  </Weight>
);

/**
 * Span that changes the `font-weight` to Faire's `book` variant.
 */
export const Book: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
  ...props
}) => (
  <Weight {...props} weight="book">
    {children}
  </Weight>
);

/**
 * Span that changes the `font-weight` to Faire's `thin` variant.
 */
export const Thin: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
  ...props
}) => (
  <Weight {...props} weight="thin">
    {children}
  </Weight>
);

export const Color = styled.span`
  color: ${({ color }) => Colors[color as keyof typeof Colors] || color};
`;

/**
 * Generator for a type that emits a span of the given color.
 * Useful when using the color span in many places.
 * @example ```
 * const DarkGold = ColorSpan(Color.darkGold);
 * <DarkGold>this text will be dark gold!</DarkGold>
 * ```
 */
export const ColorSpan = <T extends ColorOrKey>(
  color: T
): React.FC<{
  children: React.ReactNode;
}> =>
  function ({ children }) {
    return <Color color={color}>{children}</Color>;
  };
