import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";

export const DEFAULT_LOCALE = "en";

export const DEFAULT_LOCALE_KEY = ILocaleKey.build({
  language: Language.ENG,
});

export const DEFAULT_LOCALE_BY_LANGUAGE: {
  [K in Exclude<
    Language,
    Language.LANGUAGE_UNKNOWN | Language.NOR
  >]: ILocaleKey;
} = {
  [Language.ENG]: DEFAULT_LOCALE_KEY,
  [Language.FRA]: { language: Language.FRA, country: ISO3166Alpha3.FRA },
  [Language.DEU]: { language: Language.DEU, country: ISO3166Alpha3.DEU },
  [Language.ITA]: { language: Language.ITA, country: ISO3166Alpha3.ITA },
  [Language.SPA]: { language: Language.SPA, country: ISO3166Alpha3.ESP },
  [Language.SWE]: { language: Language.SWE, country: ISO3166Alpha3.SWE },
  [Language.DAN]: { language: Language.DAN, country: ISO3166Alpha3.DNK },
  [Language.POR]: { language: Language.POR, country: ISO3166Alpha3.PRT },
  [Language.NLD]: { language: Language.NLD, country: ISO3166Alpha3.NLD },
  [Language.GIBBERISH]: ILocaleKey.build({ language: Language.GIBBERISH }),
  [Language.STRING_ID]: ILocaleKey.build({ language: Language.STRING_ID }),
} as const;
