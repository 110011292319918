import { request } from '../..';
import { IRecordClientEventsRequest } from '../../indigofair/data/IRecordClientEventsRequest';
import { RecordClientEventsResponse } from '../../indigofair/data/RecordClientEventsResponse';
import {
  EndpointOptions,
  RequestOptions,
  SuccessResponse,
  PATH,
} from '../../types';

export const path = () => {
  return `/api/client-events`;
};

const subdomain = 'events.api';

export const build = (
  data: IRecordClientEventsRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    subdomain,
    ignoreForRobots: true,
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/client-events';

/**
 * @deprecated This endpoint is still being tested. Use /api/client-event to send individual PETE events instead.
 * Product Area: CLIENT_EVENTS
 * Ignored (not called) during bot sessions.
 */
export function execute(
  data: IRecordClientEventsRequest
): Promise<RecordClientEventsResponse>;
export function execute(
  data: IRecordClientEventsRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<RecordClientEventsResponse>>;
export function execute(
  data: IRecordClientEventsRequest,
  options?: EndpointOptions
): Promise<RecordClientEventsResponse>;
export function execute(
  data: IRecordClientEventsRequest,
  options?: EndpointOptions
): Promise<
  RecordClientEventsResponse | SuccessResponse<RecordClientEventsResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<RecordClientEventsResponse>(requestOptions);
}
execute[PATH] = 'api/client-events/post';
export default execute;
