/* eslint-disable complexity */
import { RouteMatcher } from "@faire/web--source/common/routes/RouteMatcher";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { ConductorSurface } from "@faire/web-api--source/indigofair/data/ConductorSurface";
import { route as brandInvoiceTokenRoute } from "@faire/web-api--source/routes/brand-invoice/brandInvoiceToken";
import { route as brandPageRoute } from "@faire/web-api--source/routes/brand/brandToken";
import { route as newProductsRoute } from "@faire/web-api--source/routes/brand/brandToken/new-products";
import { route as cartRoute } from "@faire/web-api--source/routes/cart";
import { route as checkoutRoute } from "@faire/web-api--source/routes/checkout";
import { route as productBasedCollectionPageRoute } from "@faire/web-api--source/routes/product-based-collection/product_based_collection_token";
import { route as productRoute } from "@faire/web-api--source/routes/product/productToken";
import { computed } from "mobx";
import qs from "query-string";
import { useLocation } from "react-router-dom";

import {
  CONDUCTOR_FUNCTIONAL_MODAL,
  DIGEST_ENTRYPOINT_MODAL_QUERY_PARAM,
  FAIRE_DIRECT_CREDIT_FAQ,
  FAIRE_DIRECT_FOI,
  FAIRE_DIRECT_MARKETPLACE_INCENTIVE_MODAL,
  FIRST_ORDER_INCENTIVE_MODAL,
  FREE_RETURNS_MODAL_QUERY_PARAM_VALUE,
  IDENTITY_VERIFICATION_SHOW_MODAL_QUERY_PARAM_VALUE,
  SAMPLE_PROSPECTING_SIGN_IN_QUERY_PARAM_VALUE,
  SAMPLES_REQUEST_MODAL,
  SHOPIFY_PLAN_UPSELL_MODAL,
  SHOW_MODAL_QUERY_PARAM,
  TEAM_MEMBERS_MODAL_QUERY_PARAM,
  RESELLER_CERTIFICATE_MODAL,
  CONDUCTOR_MODAL_QUERY_PARAM,
  REQUEST_UNLISTED_PRODUCTS_MODAL,
} from "@faire/retailer/components/App/AppModals/consts";
import { useConductorComponent } from "@faire/retailer/features/Conductor/useConductorComponent";
import { SignUpType } from "@faire/retailer/lib/popSignUp";
import { AppHistory } from "@faire/retailer/packages/core/services/AppHistory";
import { getSettingEnableUnlistedProductsRequests } from "@faire/retailer/settings/getSettingEnableUnlistedProductsRequests";

export enum ModalType {
  SIGN_UP,
  SIGN_IN,
  PRODUCT,
  COLLECTION,
  PAYMENT_OPTIONS,
  NON_BM_TIER_1,
  NON_BM_TIER_2,
  SIGN_IN_FROM_EMAIL,
  OPENING_ORDER_INCENTIVES_MODAL,
  INSIDER_SIGN_UP,
  IDENTITY_VERIFICATION,
  FAIRE_DIRECT_FOI,
  FAIRE_DIRECT_MARKETPLACE_INCENTIVE_MODAL,
  FIRST_ORDER_INCENTIVE_MODAL,
  FAIRE_DIRECT_CREDIT_FAQ,
  INSIDER_INTERNATIONAL_ENROLMENT,
  INSIDER_ENROLMENT,
  LINK_PLAID_MODAL,
  FREE_RETURNS_MODAL,
  SHOPIFY_PLAN_UPSELL,
  ACH_PROACTIVE_LIMIT_INCREASE_UPSELL,
  ACH_TERMS_PRE_APPROVAL_MODAL,
  SAMPLES_REQUEST_MODAL,
  TERMS_PRE_APPROVAL_MODAL,
  CONDUCTOR_FUNCTIONAL_MODAL,
  INITIAL_LIMIT_ASSIGNMENT_MODAL,
  TEAM_MEMBERS_MODAL,
  PROFILE_PICTURE_UPLOAD_MODAL,
  CONDUCTOR_MODAL,
  DIGEST_PAGE_ENTRYPOINT_MODAL,
  RESELLER_CERTIFICATE_MODAL,
  RESET_PASSWORD_MODAL,
  CONSUMER_MODE_REDIRECT,
  REQUEST_UNLISTED_PRODUCTS_MODAL,
}

/**
 * @deprecated prefer to use `useAppModal`
 */
export class AppModalStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(AppModalStore);

  @computed
  private get showingModalType(): ModalType | undefined {
    return getShowingModalType({
      pathname: AppHistory.get().location?.pathname ?? "",
      search: AppHistory.get().history.location.search,
      // currently hardcoded isConductor, should eventually get rid of MobX so we can properly use useConductorComponent()
      isConductor: false,
    });
  }

  @computed
  get isModalShowing(): boolean {
    return this.showingModalType !== undefined;
  }
}

export const useAppModal = () => {
  const location = useLocation();
  const { conductorBuilderModelName, conductorSduiViewLayout } =
    useConductorComponent({ surface: ConductorSurface.SITE_WIDE_MODAL });

  const showingModalType = getShowingModalType({
    pathname: location.pathname,
    search: location.search,
    isConductor:
      !!conductorBuilderModelName || !!conductorSduiViewLayout?.content?.length,
  });

  return { showingModalType, isModalShowing: showingModalType !== undefined };
};

interface showingModalTypeProps {
  pathname: string;
  search: string;
  isConductor: boolean;
}

const getShowingModalType = (
  params: showingModalTypeProps
): ModalType | undefined => {
  const { pathname, search, isConductor } = params;
  const {
    signUp,
    signIn,
    product,
    collection,
    ["payment-options"]: paymentOptions,
    nonBMTier1,
    nonBMTier2,
    quickLogin,
    openingOrderIncentives,
    insiderSignUp,
    insiderInternationalEnrolment,
    insiderEnrolment,
    [SHOW_MODAL_QUERY_PARAM]: showModal,
    linkPlaid,
    achProactiveLimitIncrease,
    achTermsPreApproval,
    termsPreApproval,
    termsAssignment,
    resetPassword,
    consumerModeRedirect,
  } = qs.parse(search);
  const skipProductModalRouteMatcher = RouteMatcher.of(
    cartRoute,
    brandPageRoute,
    productBasedCollectionPageRoute,
    checkoutRoute,
    newProductsRoute,
    brandInvoiceTokenRoute,
    productRoute
  );
  const skipProductModal = skipProductModalRouteMatcher.match(pathname);

  if (quickLogin) {
    return ModalType.SIGN_IN_FROM_EMAIL;
  } else if (signUp) {
    if (
      signUp !== SAMPLE_PROSPECTING_SIGN_IN_QUERY_PARAM_VALUE &&
      signUp !== SignUpType.FAIRE_DIRECT_REQUIRE_EMAIL
    ) {
      return ModalType.SIGN_UP;
    }
  } else if (signIn) {
    return ModalType.SIGN_IN;
  } else if (resetPassword) {
    return ModalType.RESET_PASSWORD_MODAL;
  } else if (consumerModeRedirect) {
    return ModalType.CONSUMER_MODE_REDIRECT;
  } else if (product && !skipProductModal) {
    return ModalType.PRODUCT;
  } else if (collection) {
    return ModalType.COLLECTION;
  } else if (paymentOptions) {
    return ModalType.PAYMENT_OPTIONS;
  } else if (nonBMTier1) {
    return ModalType.NON_BM_TIER_1;
  } else if (nonBMTier2) {
    return ModalType.NON_BM_TIER_2;
  } else if (openingOrderIncentives) {
    return ModalType.OPENING_ORDER_INCENTIVES_MODAL;
  } else if (insiderSignUp) {
    return ModalType.INSIDER_SIGN_UP;
  } else if (insiderInternationalEnrolment) {
    return ModalType.INSIDER_INTERNATIONAL_ENROLMENT;
  } else if (insiderEnrolment) {
    return ModalType.INSIDER_ENROLMENT;
  } else if (linkPlaid) {
    return ModalType.LINK_PLAID_MODAL;
  } else if (showModal === IDENTITY_VERIFICATION_SHOW_MODAL_QUERY_PARAM_VALUE) {
    return ModalType.IDENTITY_VERIFICATION;
  } else if (showModal === FIRST_ORDER_INCENTIVE_MODAL) {
    return ModalType.FIRST_ORDER_INCENTIVE_MODAL;
  } else if (showModal === FAIRE_DIRECT_FOI) {
    return ModalType.FAIRE_DIRECT_FOI;
  } else if (showModal === FAIRE_DIRECT_MARKETPLACE_INCENTIVE_MODAL) {
    return ModalType.FAIRE_DIRECT_MARKETPLACE_INCENTIVE_MODAL;
  } else if (showModal === FAIRE_DIRECT_CREDIT_FAQ) {
    return ModalType.FAIRE_DIRECT_CREDIT_FAQ;
  } else if (showModal === FREE_RETURNS_MODAL_QUERY_PARAM_VALUE) {
    return ModalType.FREE_RETURNS_MODAL;
  } else if (showModal === SHOPIFY_PLAN_UPSELL_MODAL) {
    return ModalType.SHOPIFY_PLAN_UPSELL;
  } else if (achProactiveLimitIncrease) {
    return ModalType.ACH_PROACTIVE_LIMIT_INCREASE_UPSELL;
  } else if (achTermsPreApproval) {
    return ModalType.ACH_TERMS_PRE_APPROVAL_MODAL;
  } else if (showModal === SAMPLES_REQUEST_MODAL) {
    return ModalType.SAMPLES_REQUEST_MODAL;
  } else if (termsPreApproval) {
    return ModalType.TERMS_PRE_APPROVAL_MODAL;
  } else if (termsAssignment) {
    return ModalType.INITIAL_LIMIT_ASSIGNMENT_MODAL;
  } else if (showModal === CONDUCTOR_FUNCTIONAL_MODAL) {
    return ModalType.CONDUCTOR_FUNCTIONAL_MODAL;
  } else if (showModal === TEAM_MEMBERS_MODAL_QUERY_PARAM) {
    return ModalType.TEAM_MEMBERS_MODAL;
  } else if (showModal === DIGEST_ENTRYPOINT_MODAL_QUERY_PARAM) {
    return ModalType.DIGEST_PAGE_ENTRYPOINT_MODAL;
  } else if (showModal === RESELLER_CERTIFICATE_MODAL) {
    return ModalType.RESELLER_CERTIFICATE_MODAL;
  } else if (
    showModal === REQUEST_UNLISTED_PRODUCTS_MODAL &&
    getSettingEnableUnlistedProductsRequests()
  ) {
    return ModalType.REQUEST_UNLISTED_PRODUCTS_MODAL;
  } else if (showModal === CONDUCTOR_MODAL_QUERY_PARAM) {
    return ModalType.CONDUCTOR_MODAL;
  }

  // for SDUI purposes
  if (isConductor) {
    return ModalType.CONDUCTOR_MODAL;
  }

  return undefined;
};
