import { isWindowUndefined } from "@faire/web--source/common/server-side-rendering/isWindowUndefined";
import { Storage } from "@faire/web--source/common/Storage";

export const OVERRIDE_KEY = "PageSerializedDataOverrides";

export const overrideGlobalProperty = <T>(name: string, value: T) => {
  const allOverriddenPageData = getAllOverriddenGlobalProperties();
  allOverriddenPageData[name] = value;
  const allOverridesPageDataString = JSON.stringify(allOverriddenPageData);
  Storage.local.setItem(OVERRIDE_KEY, allOverridesPageDataString);
};

let _overriddenGlobalProperties: Record<string, any> | undefined;

export const getAllOverriddenGlobalProperties = () => {
  if (!_overriddenGlobalProperties) {
    if (isWindowUndefined()) {
      _overriddenGlobalProperties = {};
    } else {
      _overriddenGlobalProperties = JSON.parse(
        Storage.local.getItem(OVERRIDE_KEY) || "{}"
      );
    }
  }
  return _overriddenGlobalProperties || {};
};

export const removeOverriddenGlobalProperty = (name: string) => {
  const allOverriddenPageData = getAllOverriddenGlobalProperties();
  delete allOverriddenPageData[name];
  const allOverridesPageDataString = JSON.stringify(allOverriddenPageData);
  Storage.local.setItem(OVERRIDE_KEY, allOverridesPageDataString);
};

export const removeAllOverriddenGlobalProperties = () => {
  Storage.local.removeItem(OVERRIDE_KEY);
  _overriddenGlobalProperties = undefined;
};
