import { trackClientEvent } from '../../../../trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'frontend_apis-prefetch_miss-view';

/**
 * Fired when an outgoing request has a matching path to a prefetch, but the bodies of the requests differ
 */
export function trackFrontendApisPrefetchMissView(
  endpoint: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: true,
    parameters: { endpoint },
  });
}
