import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { IFDMarketplaceDiscountData } from "@faire/web-api--source/indigofair/data/IFDMarketplaceDiscountData";

export const getFaireDirectMarketplaceDiscountData = ():
  | IFDMarketplaceDiscountData
  | undefined => {
  return getGlobalProperty(
    "fdMarketplaceDiscountData",
    IFDMarketplaceDiscountData.build({})
  );
};
