import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { getLocation } from "@faire/web--source/common/globals/getLocation";
import { shouldUseAnchorForLink } from "@faire/web--source/ui/lib";
import { removeLocaleFromUrl } from "@faire/web--source/ui/routing/util";
import { route as supportRoute } from "@faire/web-api--source/routes/support";
import * as H from "history";
import { HTMLAttributeAnchorTarget } from "react";
import { LinkProps as RouterLinkProps } from "react-router-dom";

const STAGING_HOSTNAME = "https://www.faire-stage.com";
const localhostRegex = /^http:\/\/localhost:300[0|1]$/;
const EXCLUDED_PATHNAMES = [supportRoute, "/blog"];

export const useMaybeDomainStripping = (
  linkTo?: RouterLinkProps<H.LocationState>["to"] | string,
  target?: HTMLAttributeAnchorTarget
) => {
  const app = getGlobalProperty("faire.app");

  if (
    target === "_blank" ||
    app !== "web-retailer" ||
    !shouldUseAnchorForLink(linkTo)
  ) {
    return {};
  }

  let url: URL;
  try {
    url = new URL(linkTo);
  } catch (_) {
    // we should only perform domain stripping on absolute links
    // this will catch the error throw from `new URL(href)` for href is not an absolute link
    return {};
  }

  const { origin, pathname } = url;

  const pathnameWithoutLocale = removeLocaleFromUrl(pathname);
  if (
    EXCLUDED_PATHNAMES.some((excludedPathname) =>
      pathnameWithoutLocale.startsWith(excludedPathname)
    )
  ) {
    return {};
  }

  const location = getLocation();
  if (!location) {
    return {};
  }

  const currentLocationOrigin = location.origin;
  const isLocalhost = localhostRegex.test(currentLocationOrigin);
  const isEligibleOrigin =
    origin === currentLocationOrigin ||
    (isLocalhost && origin === STAGING_HOSTNAME);

  if (!isEligibleOrigin) {
    return {};
  }

  const potentiallyRelativeLink = linkTo.replace(origin, "");

  return {
    potentiallyRelativeLink:
      potentiallyRelativeLink === "" ? "/" : potentiallyRelativeLink,
  };
};
